import { closeModalIcon } from "../../../dictionary"
import { useState, useEffect } from "react"
import { RankIndicator } from "../../../RankIndicator"
import { LoadingIndicator } from "../../../LoadingIndicator"

const SpecializedRankingModal = ({ collegeData, dataLoaded, modalTitle, latestYear, numberConverter }) => {

  const [tableData, setTableData] = useState<any>([])
  // scroll / scrollTo post modalTitle change

  useEffect(() => {

    const createTableData = (collegeData, valField, rankField) => {

      let res: any = []

      collegeData.forEach((college) => {

        let name = college.name ? college.name : '-'
        let value = college[valField] != null ? college[valField] : '-'
        let rank = college[rankField] ? college[rankField] : '-'

        switch (true) {
          case valField === 'averageRDspendValue':
            // value = (value / 1000).toFixed(1)
            res.push({ name: name, value: value != '-' ? `$${numberConverter((value / 1000).toFixed(1))}M` : '-', rank: rank })
            break;
          case valField === 'median4yrEarnings':
            res.push({ name: name, value: value != '-' ? `$${numberConverter(value)}` : '-', rank: rank })
            break;
          case valField === 'earlyCareerPay':
            res.push({ name: name, value: value != '-' ? `$${numberConverter(value)}` : '-', rank: rank })
            break;
          case valField === 'midCareerPay':
            res.push({ name: name, value: value != '-' ? `$${numberConverter(value)}` : '-', rank: rank })
            break;
          case valField === 'bizLeadersTotalValue':
            res.push({ name: name, value: value != '-' ? `${numberConverter(value)}` : '-', rank: rank })
            break;
          default:
            res.push({ name: name, value: value, rank: rank })

        }

      })

      res = res.sort((a, b) => {
        if (a.rank === '-' && b.rank === '-') {
          return a.name.localeCompare(b.name);
        } else if (a.rank === '-') {
          return 1;
        } else if (b.rank === '-') {
          return -1;
        } else {
          const rankA = parseFloat(a.rank);
          const rankB = parseFloat(b.rank);
          if (rankA === rankB) {
            return a.name.localeCompare(b.name);
          }
          return rankA - rankB;
        }
      });

      // console.log('res', res);
      return res;
    }

    if (collegeData.length > 0) {

      switch (true) {
        case modalTitle === 'Nobel Prizes':
          setTableData(createTableData(collegeData, 'nobelPrizesValue', 'nobelPrizesRank'))
          break;

        case modalTitle === 'Macarthur Fellows':
          setTableData(createTableData(collegeData, 'macarthurValue', 'macarthurRank'))
          break;

        case modalTitle === 'Fields Medals':
          setTableData(createTableData(collegeData, 'fieldsMedalValue', 'fieldsMedalRank'))
          break;

        case modalTitle === 'Average Annual R&D Spend':
          setTableData(createTableData(collegeData, 'averageRDspendValue', 'averageRDspendRank'))
          break;

        //For R&D Spend Page
        case modalTitle === 'Average Annual Total R&D Spend':
          setTableData(createTableData(collegeData, 'averageRDspendValue', 'averageRDspendRank'))
          break;

        case modalTitle === `Patents Issued (${latestYear})`:
          setTableData(createTableData(collegeData, 'patentsIssuedValue', 'patentsIssuedRank'))
          break;

        case modalTitle === 'Founders of VC-backed companies':
          setTableData(createTableData(collegeData, 'foundersVcCosValue', 'foundersVcCosRank'))
          break;

        case modalTitle === 'Fortune 500 CEOs':
          setTableData(createTableData(collegeData, 'fortune500CeoValue', 'fortune500CeoRank'))
          break;

        case modalTitle === 'Business Leaders (Total)':
          setTableData(createTableData(collegeData, 'bizLeadersTotalValue', 'bizLeadersTotalRank'))
          break;

        case modalTitle === 'Business Leaders (Per 1K Students)':
          setTableData(createTableData(collegeData, 'bizLeadersPer1KValue', 'bizLeadersPer1KRank'))
          break;

        case modalTitle === 'US Governors':
          setTableData(createTableData(collegeData, 'governorsValue', 'governorsRank'))
          break;

        case modalTitle === 'US Senators':
          setTableData(createTableData(collegeData, 'senatorsValue', 'senatorsRank'))
          break;

        case modalTitle === 'Rhodes Scholars':
          setTableData(createTableData(collegeData, 'rhodesScholarsValue', 'rhodesScholarsRank'))
          break;

        case modalTitle === 'Marshall Scholars':
          setTableData(createTableData(collegeData, 'marshallScholarsValue', 'marshallScholarsRank'))
          break;

        case modalTitle === 'Median 4-Year Earnings':
          setTableData(createTableData(collegeData, 'median4yrEarnings', 'median4yrEarningsRank'))
          break;

        case modalTitle === 'Early Career Pay':
          setTableData(createTableData(collegeData, 'earlyCareerPay', 'earlyCareerPayRank'))
          break;

        case modalTitle === 'Mid Career Pay':
          setTableData(createTableData(collegeData, 'midCareerPay', 'midCareerPayRank'))
          break;

        case modalTitle === 'US Billionaires':
          setTableData(createTableData(collegeData, 'billionairesValue', 'billionairesRank'))
          break;

        default:
          setTableData(tableData)
      }
    }

  }, [collegeData, modalTitle])

  // Table Content

  const tableContent = (
    <div>
      <table className="table table-borderless table-sm table-responsive ms-5 me-5">
        <thead>
          <tr className="text-center">
            <th className='text-start dca-header-spacing-B' colSpan={3} scope="col">COLLEGE</th>
            <th colSpan={3} scope="col" className="dca-header-spacing-B">VALUE</th>
            <th colSpan={3} scope="col" className="dca-header-spacing-B">RANK</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((res, index) => (
            <tr key={index} className="text-center align-middle">
              <th scope='row' colSpan={3} className="text-start" style={{ minWidth: 'fit-content', whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{res.name}</th>
              <td colSpan={3} style={{ verticalAlign: 'middle' }}>{res.value}</td>
              <td colSpan={3} style={{ verticalAlign: 'middle' }}><div style={{ marginLeft: '8px' }}><RankIndicator rank={res.rank} modal={true} /></div></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )


  return dataLoaded && collegeData.length > 0 ? (
    <div className="modal fade" id="view_specialized_rankings_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ minWidth: 'fit-content', maxWidth: '75vw', minHeight: '85vh', maxHeight: '85vh', padding: '60px 100px 60px 100px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto' }}>

          <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px' }}>

            <div className="d-flex" style={{ margin: '5px 0 40px 0', fontSize: 'calc(1.26rem + 0.12vw)' }}>{modalTitle}</div>
            {closeModalIcon('')}

            {tableContent}

          </div>

        </div>
      </div>
    </div>
  ) : (
    <div className="modal fade" id="view_specialized_rankings_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ minWidth: '75vw', maxWidth: '75vw', minHeight: '85vh', maxHeight: '85vh', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto' }}>

          <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px' }}>

            <div className="d-flex" style={{ margin: '5px 0 80px 0', fontSize: 'calc(1.26rem + 0.12vw)' }}>{modalTitle}</div>
            {closeModalIcon('')}

            <LoadingIndicator text={"Getting Colleges Data ... "} />
          </div>

        </div>
      </div>
    </div>
  )
}

export { SpecializedRankingModal }