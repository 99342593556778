import { useNavigate } from "react-router-dom";
import * as dictionary from '../dictionary'
import { SaveButtonCardNoAuth, SaveButtonMajorCard } from "../SaveButton";
import { PopularityIndex } from "../MajorDetail/PopularityIndex";
import { getAuth } from "../../modules/auth";
import { TooltipComponent } from "../Tooltip";
import { ConnectWithAdvisorButton } from "../ConnectWithAdvisorButton";
import { majorMap } from "../majorMap";

const MajorCard = (props) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const sortKeys = [{ 'Alphabetical': 'name' }, { 'Num Colleges': 'numColleges' }, { 'Popularity': 'popularityIndex' }, { 'Earnings': 'averageEarnings' }]
  const handleSortKeyChange = (event) => {
    const selectedKey = event.target.value
    props.setSortKey(selectedKey)
  }
  const sortKeyReturn = (sortKey) => {
    switch (sortKey) {
      case 'name':
        return 'Alphabetical'
      case 'numColleges':
        return 'Num Colleges'
      case 'popularityIndex':
        return 'Popularity'
      case 'averageEarnings':
        return 'Earnings'
      default:
        return 'Alphabetical'
    }
  }
  const handleSortOrderChange = () => {
    props.sortOrder === 'Ascending' ? props.setSortOrder('Descending') : props.setSortOrder('Ascending')
  }

  const collegesWithMajorCount = (programData, majorId) => {
    if (programData.filter((item) => item.major_id === majorId).length === 0) {
      return '-'
    }

    else {
      return programData.filter((item) => item.major_id === majorId).length
    }

  }

  const collegesWithRelatedMajorsCount = (majorData, major, programData) => {

    // engineering (aerospace)
    //all ciptids 140201  -- confirmed
    // 5 related majors, 
    // related major ids: [2, 3, 143, 397, 559]
    // related majors data 9 [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
    // colleges with related majors length: 7

    if (majorData && major && programData) {

      let ciptIds: any = []

      if (major.cipt_code !== null) {
        ciptIds.push(major.cipt_code.toString());
      }

      if (major.secondaryCIPs !== null) {
        major.secondaryCIPs.map((cip) => {
          ciptIds.push(cip.toString())
        })
      }

      let relatedMajors: any = []
      relatedMajors = majorData.filter((item) =>
        ciptIds.includes(item.cipt_code) ||
        (item.secondaryCIPs && item.secondaryCIPs.some((secondCIP) => ciptIds.includes(secondCIP)))
      );

      let relatedMajorsRes: any = []
      relatedMajors.map((major) => {
        relatedMajorsRes.push(major.id)
      })

      relatedMajors = programData.filter((item) => relatedMajorsRes.includes(item.major_id))

      let colleges: any = []
      relatedMajors.map((major) => {
        colleges.push(major.college_id)
      })
      colleges = [...new Set(colleges)]

      return colleges.length
    }

    else {
      return '-'
    }

  }

  const pullDescription = (description, cipt_code) => {

    if (description !== null) {
      return description
    }

    else if (cipt_code !== null && description === null) {
      let res: any = props.cipTax.filter((item) => item.code === cipt_code)

      if (res && res[0] && res[0]['description']) {
        return res[0]['description']
      }
    }

    else {
      return '-'
    }
  }

  const returnPopTooltip = (popularityIndex) => {
    return popularityIndex ? <TooltipComponent content={'This index indicates the average popularity of this major (on a 0-10 decile scale, 10 being the most popular) across the colleges (within the sample) offering this specific major. Click through to the details page to see popularity data for this major per college.'} /> : <TooltipComponent content={'There is insufficient enrollment data from the college(s) offering this major to compute its Popularity Index.'} />
  }

  const returnEarningsTooltip = (averageEarnings) => {
    return averageEarnings ? <TooltipComponent content={`This figure is the average earnings (at 4 years after program completion) for graduates with this major across the college(s) (within the sample) offering this specific major. Click through to the details page to see earnings data for this major per college.`} /> : <TooltipComponent content={'There is insufficient earnings data to compute this information at this time.'} />
  }

  let navigate = useNavigate();

  const routeChange = (name) => {
    navigate(dictionary.pageRoutes.majorDetailWithId(dictionary.returnHyphenatedString(name)))
  }

  const returnCollegesWithThisMajorPercent = (numCollegesWithThisMajor) => {
    if (numCollegesWithThisMajor) {
      let x = (numCollegesWithThisMajor / 25) * 100

      return x.toFixed(0)
    }

    else {
      return '-'
    }
  }

  const returnAdvisorConnectCardID = (resID, majors) => {
    let majorObj = majors.filter(major => major.id == resID)[0]
    if (majorObj) {
      return `${majorObj.cipt_code}`
    }
    return ""
  }

  return (
    <div className='dca-card-container'>

      {/* Major CARDS HEADER */}
      <div className="d-flex flex-row justify-content-between align-items-end">

        <div className="d-flex flex-row">
          <div className="me-3 align-bottom dca-card-items-found-text">Displaying {props.majors.length} majors</div>
          <div
            onClick={handleSortOrderChange}
            style={{ cursor: 'pointer', fontSize: '15px', lineHeight: '18px', color: '#585555', marginBottom: '2px' }}
            className='text-muted fs-7 fw-bolder align-self-end'
          >by {props.sortOrder} {sortKeyReturn(props.sortKey)} {props.sortOrder === 'Ascending' && (<i className="bi bi-arrow-up"></i>)}
            {props.sortOrder === 'Descending' && (<i className="bi bi-arrow-down"></i>)}
          </div>
        </div>

        <select
          className='form-select w-25 align-bottom'
          data-control="select2"
          value={props.sortKey}
          onChange={handleSortKeyChange}
          style={{ cursor: 'pointer' }}
        >
          {sortKeys.map((res, id) => (
            <option key={id} value={Object.values(res)}>{Object.keys(res)}</option>
          ))}
        </select>
      </div>
      {/* END Major CARD HEADER */}

      <div>{props.majors.map((res) => (

        <div
          key={res.id}
          className="mt-5 hover-elevate-up dca-card-item"
          onClick={() => {
            let matchingMajor = majorMap.find(majObj => parseInt(majObj.id) == res.id)

            navigate(`${dictionary.pageRoutes.ecDetailWithId(matchingMajor?.hyphenated)}`)
          }}
        >

          {props.advisorMajorIDs.some(majorID => majorID == res.id) && <ConnectWithAdvisorButton
            cardID={returnAdvisorConnectCardID(res.id, props.majors)}
            searchPage={dictionary.pageRoutes.majorSearch}
            buttonTextParam={res.name}
          />}

          {apiToken != undefined && props.user ? <SaveButtonMajorCard
            user={props.user}
            majorId={res.id}
            setUser={props.setUser}
          /> : <SaveButtonCardNoAuth
            setPrompt={props.setPrompt}
            setNavigateTo={props.setNavigateTo} />}

          <div className="d-flex justify-content-center" style={{ marginTop: props.advisorMajorIDs.some(majorID => majorID == res.id) ? '70px' : '30px' }}>
            <span className="dca-card-item-nameField">{res.name}</span>
          </div>

          <div className="d-flex justify-content-center">
            <span
              className="align-self-center"
              style={{ width: '90%', marginTop: '20px' }}>{pullDescription(res.description, res.cipt_code)}</span>
          </div>

          {/* INNER CARD CONTENT */}
          <div className="d-flex flex-column justify-content-center" style={{ marginTop: '17px' }}>
            <div className="d-flex flex-row align-self-center justify-content-between" style={{ width: '90%', margin: '20px' }}>

              <div className="d-flex flex-column" style={{ width: '25%', paddingRight: '8px' }}>
                <div className="dca-card-item-header">POPULARITY INDEX <span className="ms-1">

                  {returnPopTooltip(res.popularityIndex)}

                </span></div>
                <div className="dca-card-item-text">{res.popularityIndex ? <PopularityIndex index={res.popularityIndex} /> : '-'}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '25%', paddingRight: '8px' }}>
                <div className="dca-card-item-header">AVERAGE ANNUAL EARNINGS <span className="ms-1">

                  {returnEarningsTooltip(res.averageEarnings)}

                </span></div>
                <div className="dca-card-item-text">{res.averageEarnings ? `$${dictionary.numberConverter(res.averageEarnings)}` : '-'}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '25%', paddingRight: '8px' }}>
                <div className="dca-card-item-header">COLLEGES WITH THIS MAJOR
                  <span className="ms-1">
                    <TooltipComponent content={`Of the 25 top-ranked colleges analyzed, this specific major is offered at ${collegesWithMajorCount(props.programData, res.id)} (${returnCollegesWithThisMajorPercent(collegesWithMajorCount(props.programData, res.id))}% of the sample.`} />
                  </span>

                </div>
                <div className="dca-card-item-text">{collegesWithMajorCount(props.programData, res.id)}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '25%' }}>
                <div className="dca-card-item-header">COLLEGES WITH RELATED MAJORS
                  <span className="ms-1">
                    <TooltipComponent content={`Of the 25 top-ranked colleges analyzed, ${collegesWithRelatedMajorsCount(props.majorData, res, props.programData)} (${returnCollegesWithThisMajorPercent(collegesWithRelatedMajorsCount(props.majorData, res, props.programData))}% of the sample) offer either this specific major or a closely related major.`} />
                  </span>
                </div>
                <div className="dca-card-item-text">{collegesWithRelatedMajorsCount(props.majorData, res, props.programData)}</div>
              </div>

            </div>
          </div>
          {/* END INNER CARD CONTENT */}

        </div>
      ))}

      </div>
    </div>
  )
}

export { MajorCard }
