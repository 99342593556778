import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react"
import axios from "axios";
import Select from 'react-select'

import { URL, getStripePrice, specializedRankingsAccessCode, updateTitleAndDescription, specializedRankingsPriceID, pageRoutes, collegeLogos } from "../../components/dictionary"
import { CollegeReactGAHandlers, ECReactGAHandlers } from "../../components/ReactGAHandlers"
import { PurchaseButton, PurchaseButtonNav } from "../../components/Marketing/PurchaseButton";
import { StickyNavbar } from "../../components/Marketing/StickyNavbar";
import { getAuth } from "../../modules/auth";
import { collegeMap } from '../../components/collegeMap';
import { SpecializedRankings } from '../../components/CollegeDetail/TabComponents/SpecializedRankings';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ConnectEmailCTA } from '../../components/Marketing/ConnectEmailCTA';
import { HoverCards } from '../../components/Marketing/HoverCards';
import { Testimonials } from '../../components/Marketing/Testimonials';
import { FAQ } from '../../components/Marketing/FAQ';
import { MarketingImageWrapper } from '../../components/Marketing/ImageWrappers';
import { CollegeSelectComponent } from '../../components/Marketing/CollegeSelectComponent';

import diamond from '../../../custom_assets/marketing/hovercard-diamond.png'
import photo1 from '../../../custom_assets/marketing/specialized_rankings/photo_1.png'
import photo2 from '../../../custom_assets/marketing/specialized_rankings/photo_2.png'
import photo3 from '../../../custom_assets/marketing/specialized_rankings/photo_3.png'
import photo4 from '../../../custom_assets/marketing/specialized_rankings/photo_4.png'

import mobilePhoto1a from '../../../custom_assets/marketing/specialized_rankings/mobile/photo_1a.png'
import mobilePhoto1b from '../../../custom_assets/marketing/specialized_rankings/mobile/photo_1b.png'

const MarketingSpecializedRankings = ({ user, clientSecret, setClientSecret, setUpgradeUserGroupAccessCode, setSelectedTab, setNavigateTo }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  let specializedRankingsDataLoaded: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const { handleGAEventPurchaseModalPurchase } = CollegeReactGAHandlers()
  const { handleECGAEventPurchaseModalPurchase } = ECReactGAHandlers()

  const navigate = useNavigate()
  const location = useLocation()

  const [nameOptions, setNameOptions] = useState<any>([])
  const [selectedCollege, setSelectedCollege] = useState<any>(undefined);
  const [collegeData, setCollegeData] = useState<any>(undefined)
  const [premiumContentPrice, setPremiumContentPrice] = useState<any>(undefined)

  const handleCollegeSelectChange = (selectedOption) => {
    selectedOption ? navigate(pageRoutes.premiumSpecializedRankingsWithId(selectedOption.hyphenated)) : navigate(pageRoutes.premiumSpecializedRankings)
  };

  const getSelectedCollegeData = async (selectedCollege) => {
    try {
      let res = await axios.get(`${URL}/colleges/${selectedCollege?.value}`)
      setCollegeData(res.data)
    } catch (error) {
      console.log('Could not find college info:', error)
      throw error
    }
  }

  const getPrice = async () => {
    let price = await getStripePrice(specializedRankingsPriceID)
    price ? setPremiumContentPrice(price) : setPremiumContentPrice(undefined)
  }

  useEffect(() => {
    let names: any = []
    collegeMap.forEach((college) => {
      names.push({ value: college.id, label: college.name, hyphenated: college.hyphenated })
    })
    names = names.sort((a, b) => a.label.localeCompare(b.label))
    setNameOptions(names);

    getPrice()
  }, [])


  useEffect(() => {

    let preselectedCollege: string | undefined = undefined
    preselectedCollege = location.pathname.split(`/`)[1]
    preselectedCollege = preselectedCollege.split(`-specialized-rankings`)[0]

    if (preselectedCollege) {
      setSelectedCollege(nameOptions.find(opt => opt.hyphenated == preselectedCollege))
    }

    else {
      setSelectedCollege(undefined)
    }

  }, [location.pathname, nameOptions]);

  useEffect(() => {

    if (selectedCollege) {
      getSelectedCollegeData(selectedCollege);
    }

    else {
      setCollegeData(undefined);
    }

  }, [selectedCollege])

  const returnCategoriesCard = (title, listItems) => {
    return (
      <div className='dca-marketing-specialized-rankings-categories-card'>
        <div className='dca-marketing-specialized-rankings-categories-card-title'>{title}</div>
        <div className='dca-marketing-specialized-rankings-categories-card-list'>
          {listItems.map(item =>
            <div>{item}</div>
          )}
        </div>
      </div>
    )
  }

  // const specializedRankingsCategoriesPreview = (
  //   <div className='dca-marketing-specialized-rankings-categories-wrapper'>
  //     <div className='dca-marketing-specialized-rankings-categories-row'>
  //       {returnCategoriesCard('ALUMNI SUCCESS IN BUSINESS', ['Fortune 500 CEOs', 'Business Leaders (Total)', 'Business Leaders (Per 1K Students)'])}

  //       {returnCategoriesCard('ALUMNI SUCCESS IN POLITICS', ['US Governors', 'US Senators'])}

  //       {returnCategoriesCard('RESEARCH/INNOVATION', ['Average Annual R&D Spend', 'Patents Issued', 'Founders of VC-backed companies'])}
  //     </div>
  //     <div className='dca-marketing-specialized-rankings-categories-row'>
  //       {returnCategoriesCard('ACADEMIC AWARDS/FACULTY RECOGNITION ', ['Nobel Prizes', 'Macarthur Fellows', 'Fields Medals'])}

  //       {returnCategoriesCard('ALUMNI RECEIVING SCHOLARSHIP', ['Rhodes Scholars', 'Marshall Scholars'])}

  //       {returnCategoriesCard('EARNINGS', ['Median 4-Year Earnings', 'Early Career Pay', 'Mid Career Pay', 'US Billionaires'])}
  //     </div>
  //   </div>
  // )

  const faqData = [
    { question: 'How many colleges are covered in this report?', answer: <span>This report provides exclusive access to the Specialized Rankings for all of the top 100 U.S. colleges (according to NextFour's metarank). The full set of covered colleges are listed in the <a className='dca-marketing-college-explorer-link' href={pageRoutes.collegeSearch} target="_blank">College Explorer</a>. Importantly, these 100 colleges are stack-ranked against one another in each dimension so you can quickly see how they stack up in the categories you care most about.</span> },
    { question: 'Is there an alternative way to get this information?', answer: 'Sure, but to get the full breadth of insights, it would require spending hundreds of hours to replicate the research and proprietary analysis underlying this report. NextFour makes it easy by having already crunched and compiled the data (and continuing to keep it updated) and then making it readily available in an intuitive online interface.' },
    { question: `Why isn't this report free?`, answer: 'While nearly all of the content, tools and services on NextFour are made freely available, this report is offered as a premium service (with a small fee) because of the significant investment required by NextFour to complete the proprietary analyses underlying the report.' }
  ]

  const testimonialData = [
    { statement: `“These rankings really resonated with me as it was exceedingly clear what specifically was being measured, as opposed to the other industry rankings which seem quite convoluted.”`, source: 'Alicia', relation: 'Parent of high school senior' },
    { statement: `“As someone who aspires to be involved with innovation and start-ups, I found it super interesting to see, in particular, which colleges produced the most VC-backed companies and ranked for patents issued...”`, source: 'Taylor', relation: 'High school student' }
  ]

  const marketingContent = (
    <>
      <div className="dca-marketing-page-headline">Exclusive college rankings based on tangible success metrics</div>

      <div className="dca-marketing-sub-headline">Go beyond generic rankings. Discover a fresh way to look at colleges based on what matters to you and your aspirations.</div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-block'>
        <MarketingImageWrapper
          altText={'photoSpecializedRankings'}
          photo={photo1} />
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-block d-md-none'>
        <MarketingImageWrapper
          altText={'photoSpecializedRankings'}
          photo={mobilePhoto1a} />

        <div style={{ marginTop: '15px' }}></div>

        <MarketingImageWrapper
          altText={'photoSpecializedRankings'}
          photo={mobilePhoto1b} />
      </div>

      {premiumContentPrice && <PurchaseButton
        user={user}
        priceID={specializedRankingsPriceID}
        groupAccessCode={specializedRankingsAccessCode}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        premiumProductName={'Specialized Rankings'}
        premiumContentPrice={premiumContentPrice}
        topMargin={'50px'}
        bottomMargin={'140px'}
        page={'Specialized Rankings'}
        clickLocation={'Middle'}
        activityID={undefined}
        setNavigateTo={setNavigateTo}
      />}

      <div className='dca-marketing-text-image-block' id='TIB-175'>
        <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '55px' }}>
          <div className="dca-marketing-pt-header">Discover rankings to better understand how colleges stack up</div>
          <div className="dca-marketing-pt-body">Get access to exclusive rankings that show how colleges compare across a bespoke compilation of tangible and meaningful measures of success. Rankings cover 17 attributes across 6 categories enabling you to discover how colleges compare in the dimensions of highest personal relevance.</div>
        </div>

        <MarketingImageWrapper
          altText={'photoSpecializedRankings'}
          photo={photo4} />
      </div>

      <div className='dca-marketing-text-image-block' id='TIB-120'>
        <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '5px' }}>
          <div className="dca-marketing-pt-header">Understand the relative strengths for your schools of interest</div>
          <div className="dca-marketing-pt-body">Explore specialized rankings for each of the top 100 US colleges and intuititvely understand the relative strengths and weaknesses for schools you may be considering</div>
        </div>

        <MarketingImageWrapper
          altText={'photoSpecializedRankings'}
          photo={photo2}
          wrapper='B' />
      </div>

      <div className='dca-marketing-text-image-block' id='TIB-25'>
        <div className="dca-marketing-pt-wrapper" style={{ marginBottom: '25px' }}>
          <div className="dca-marketing-pt-header">Discover which colleges have the most success in the dimensions you care about</div>
          <div className="dca-marketing-pt-body">Access this exclusive feature illustrating how the top 100 colleges rank by outcomes across each of 17 success metrics. Consider this information to help inform your college interest list.</div>
        </div>

        <MarketingImageWrapper
          altText={'photoSpecializedRankings'}
          photo={photo3}
          wrapper='D' />

        <div className='dca-marketing-disclaimer-text'>Note: Images above are meant to be illustrative only and do not represent the actual data for any specific college.</div>
      </div>


      <FAQ faqData={faqData} />

      <ConnectEmailCTA />

      <Testimonials data={testimonialData} />

      {premiumContentPrice && <PurchaseButton
        user={user}
        priceID={specializedRankingsPriceID}
        groupAccessCode={specializedRankingsAccessCode}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        premiumProductName={'Specialized Rankings'}
        premiumContentPrice={premiumContentPrice}
        topMargin={'50px'}
        bottomMargin={'140px'}
        page={'Specialized Rankings'}
        clickLocation={'Bottom'}
        activityID={undefined}
        setNavigateTo={setNavigateTo}
      />}

      <HoverCards cardsOption='SpecializedRankings' />
    </>
  )

  const accessContent = collegeData ? (
    <div className='d-flex flex-column align-items-center'>
      <div className="dca-marketing-page-premium-content-label"><img src={diamond}></img> PREMIUM CONTENT</div>
      <div className="dca-marketing-page-premium-content-headline">Specialized Rankings</div>

      <CollegeSelectComponent
        options={nameOptions}
        value={selectedCollege}
        onChange={handleCollegeSelectChange}
      />
      <div className='dca-marketing-page-college-logo-and-name-wrapper' id='dca-marketing-page-college-logo-and-name-specialized-rankings' style={{ width: '55vw', maxWidth: '65vw', marginLeft: '46px' }}>
        <div className='dca-marketing-page-college-logo-and-name'>
          <img
            alt="collegeLogo"
            className='dca-marketing-page-college-logo-img'
            src={collegeLogos[selectedCollege?.value]} />
          {selectedCollege ? selectedCollege?.label : 'N/A'}
        </div>
      </div>
      <SpecializedRankings college={collegeData} collegeListLength={collegeMap.length} setSelectedTab={setSelectedTab} pageView={true} />

      <div className='dca-marketing-college-hover-cards-spacer'></div>

      <HoverCards cardsOption='SpecializedRankings' hyphenated={selectedCollege?.hyphenated} />
    </div>)
    : (
      <div className='d-flex flex-column align-items-center'>
        <div className="dca-marketing-page-premium-content-label"><img src={diamond}></img> PREMIUM CONTENT</div>
        <div className="dca-marketing-page-premium-content-headline">Specialized Rankings</div>
        <div className='d-flex justify-content-center'>
          <CollegeSelectComponent
            options={nameOptions}
            value={selectedCollege}
            onChange={handleCollegeSelectChange}
          />
        </div>

        <div className='dca-marketing-page-no-select-text'>Select a college to display its Specialized Rankings Report</div>
      </div>
    );

  return permissionGroups?.includes(specializedRankingsAccessCode) ? (
    accessContent
  ) : (
    <div className="d-flex flex-column align-items-center">
      <StickyNavbar user={user} purchaseButton={premiumContentPrice && <PurchaseButtonNav
        user={user}
        priceID={specializedRankingsPriceID}
        groupAccessCode={specializedRankingsAccessCode}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        premiumProductName={'Specialized Rankings'}
        premiumContentPrice={premiumContentPrice}
        page={'Specialized Rankings'}
        clickLocation={'Header'}
        activityID={undefined}
        setNavigateTo={setNavigateTo}
      />} />
      {marketingContent}
    </div>
  );
}

export { MarketingSpecializedRankings }