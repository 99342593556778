// import { useNavigate } from "react-router"
// import { getAuth } from "../modules/auth"
// import { pageRoutes } from "./dictionary"
// import { setTestScoreStrategyModal } from "./Auth/setPrompts"
// import { AuthReactGAHandlers } from "./ReactGAHandlers"

// const Footer = ({ setPrompt, setNavigateTo }) => {

//   const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

//   let apiToken: any = undefined

//   if (getAuth()?.api_token && getAuth()?.groups) {
//     apiToken = getAuth()?.api_token
//   }

//   const footerCompanyList = [
//     { 'ABOUT': pageRoutes.about },
//     { 'BLOG': pageRoutes.blog },
//     { 'FAQ': pageRoutes.faq },
//     { 'CONTACT': pageRoutes.contact },
//     { 'PRIVACY POLICY': pageRoutes.privacyPolicy },
//     { 'TERMS': pageRoutes.termsOfService }
//   ]

//   const footerResourcesList = [
//     { 'OVERVIEW': pageRoutes.overviewIntroduction },
//     { 'EXPLORE COLLEGES': pageRoutes.collegeSearch },
//     { 'COLLEGE SCATTERGRAMS': pageRoutes.collegeDataVisualization },
//     { 'EXPLORE MAJORS': pageRoutes.majorSearch },
//     { 'EXPLORE EXTRACURRICULARS': pageRoutes.ecSearch },
//     { 'TEST SCORE GUIDANCE': pageRoutes.testScoreStrategy },
//     { 'ADVISORS NETWORK': pageRoutes.advisorSearch }
//   ]

//   let navigate = useNavigate()

//   const guestFooter = (
//     <ul className='d-flex flex-column menu menu-gray-600 menu-hover-primary fw-bold order-1'>
//       <li className='menu-item ps-0 pe-2'>Resources</li>
//       {footerResourcesList.map((res, index) => (
//         <li key={index} className='mt-1 mb-1 ps-0 pe-2'>
//           {(Object.keys(res)[0] == 'TEST SCORE GUIDANCE') ? <a
//             data-bs-toggle={apiToken != undefined ? "" : "modal"}
//             href={apiToken != undefined ? "" : "#login_modal"}
//             onClick={() => {
//               setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
//               apiToken == undefined && handleGAEventSignInModalOpen('forced')
//             }}
//             style={{ cursor: 'pointer', color: 'rgb(7,20,55)' }}
//           >{Object.keys(res)[0]}</a> :
//             <span
//               onClick={() => navigate(footerResourcesList[index][Object.keys(res)[0]])}
//               className='link-dark'
//               style={{ cursor: 'pointer' }}
//             >{Object.keys(res)[0]}</span>}
//         </li>
//       ))}
//     </ul>

//   )

//   const authFooter = (
//     <ul className='d-flex flex-column menu menu-gray-600 menu-hover-primary fw-bold order-1'>
//       <li className='menu-item ps-0 pe-2'>Resources</li>
//       {footerResourcesList.map((res, index) => (
//         <li key={index} className='mt-1 mb-1 ps-0 pe-2'>
//           <span
//             onClick={() => navigate(footerResourcesList[index][Object.keys(res)[0]])}
//             className='link-dark'
//             style={{ cursor: 'pointer' }}
//           >{Object.keys(res)[0]}</span>
//         </li>))}
//     </ul>
//   )

//   const returnFooterResources = (apiToken) => {
//     switch (true) {
//       case apiToken === undefined:
//         return guestFooter
//       case apiToken != undefined:
//         return authFooter
//       default:
//         return guestFooter
//     }
//   }

//   return (
//     <div style={{ marginTop: '20vh' }}>

//       <div className='d-flex flex-row-reverse justify-content-between align-items-end'>

//         <div className='text-dark order-2 order-md-1 ms-2'>
//           <span className='fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
//           <span className='fw-bold'>
//             NEXTFOUR.AI
//           </span>
//         </div>

//         <div className='d-flex flex-row align-items-start'>
//           <ul className='me-5 d-flex flex-column menu menu-gray-600 menu-hover-primary fw-bold order-1'>
//             <li className='menu-item ps-0 pe-2'>Company</li>
//             {/* {footerCompanyList.map((res, index) => (
//               <li key={index} className='mt-1 mb-1 ps-0 pe-2'>
//                 {<span
//                   onClick={() => {
//                     navigate(footerCompanyList[index][Object.keys(res)[0]])
//                   }}
//                   className='link-dark'
//                   style={{ cursor: 'pointer' }}
//                 >{Object.keys(res)[0]}
//                 </span>}
//               </li>
//             ))} */}
//             {footerCompanyList.map((res, index) => {
//               const label = Object.keys(res)[0];
//               const link = res[label];

//               return (
//                 <li key={index} className='mt-1 mb-1 ps-0 pe-2'>
//                   {label === 'BLOG' ? (
//                     <a
//                       href={link}
//                       rel="noopener noreferrer"
//                       className='link-dark'
//                       style={{ cursor: 'pointer' }}
//                     >
//                       {label}
//                     </a>
//                   ) : (
//                     <span
//                       onClick={() => {
//                         navigate(link);
//                       }}
//                       className='link-dark'
//                       style={{ cursor: 'pointer' }}
//                     >
//                       {label}
//                     </span>
//                   )}
//                 </li>
//               );
//             })}
//           </ul>

//           {returnFooterResources(apiToken)}

//         </div>

//       </div>
//     </div>
//   )
// }

// export { Footer }








import { useNavigate } from "react-router"
import { getAuth } from "../modules/auth"
import { pageRoutes } from "./dictionary"
import { setTestScoreStrategyModal } from "./Auth/setPrompts"
import { AuthReactGAHandlers } from "./ReactGAHandlers"

const Footer = ({ setPrompt, setNavigateTo }) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  const footerCompanyList = [
    { 'ABOUT': pageRoutes.about },
    { 'BLOG': pageRoutes.blog },
    { 'FAQ': pageRoutes.faq },
    { 'CONTACT': pageRoutes.contact },
    { 'PRIVACY POLICY': pageRoutes.privacyPolicy },
    { 'TERMS': pageRoutes.termsOfService }
  ]

  const footerResourcesList = [
    { 'OVERVIEW': pageRoutes.overviewIntroduction },
    { 'EXPLORE COLLEGES': pageRoutes.collegeSearch },
    { 'COLLEGE SCATTERGRAMS': pageRoutes.collegeDataVisualization },
    { 'EXPLORE MAJORS': pageRoutes.majorSearch },
    { 'EXPLORE EXTRACURRICULARS': pageRoutes.ecSearch },
    { 'TEST SCORE GUIDANCE': pageRoutes.testScoreStrategy },
    { 'ADVISORS NETWORK': pageRoutes.advisorSearch }
  ]

  let navigate = useNavigate()

  const guestFooter = (
    <ul className='d-flex flex-column menu menu-gray-600 menu-hover-primary fw-bold order-1'>
      <li className='menu-item ps-0 pe-2'>Resources</li>
      {footerResourcesList.map((res, index) => (
        <li key={index} className='mt-1 mb-1 ps-0 pe-2'>
          {(Object.keys(res)[0] == 'TEST SCORE GUIDANCE') ? <a
            data-bs-toggle={apiToken != undefined ? "" : "modal"}
            href={apiToken != undefined ? "" : "#login_modal"}
            onClick={() => {
              setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
              apiToken == undefined && handleGAEventSignInModalOpen('forced')
            }}
            style={{ cursor: 'pointer', color: 'rgb(7,20,55)' }}
          >{Object.keys(res)[0]}</a> :
            <span
              onClick={() => navigate(footerResourcesList[index][Object.keys(res)[0]])}
              className='link-dark'
              style={{ cursor: 'pointer' }}
            >{Object.keys(res)[0]}</span>}
        </li>
      ))}
    </ul>

  )

  const authFooter = (
    <ul className='d-flex flex-column menu menu-gray-600 menu-hover-primary fw-bold order-1'>
      <li className='menu-item ps-0 pe-2'>Resources</li>
      {footerResourcesList.map((res, index) => (
        <li key={index} className='mt-1 mb-1 ps-0 pe-2'>
          <span
            onClick={() => navigate(footerResourcesList[index][Object.keys(res)[0]])}
            className='link-dark'
            style={{ cursor: 'pointer' }}
          >{Object.keys(res)[0]}</span>
        </li>))}
    </ul>
  )

  const toolsResources = (
    <div id="dca-footer-tools-resources-wrapper">
      <div className="dca-footer-group-title">TOOLS & RESOURCES</div>
      <div className="dca-footer-tools-resources-links">
        <div className="dca-footer-link-column">
          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.overviewIntroduction)
            }}
          >Overview</div>

          <div className="dca-footer-link-group-header">Colleges</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.collegeSearch)
            }}>Explorer</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.collegeDataVisualization)
            }}
          >Scattergrams</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.premiumSpecializedRankings)
            }}
          >Specialized Rankings</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.premiumRDSpend)
            }}
          >R&D Spend</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.premiumCrimeSafety)
            }}
          >Crime & Safety</div>
        </div>

        <div className="dca-footer-link-column">
          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.majorSearch)
            }}
          >Majors Explorer</div>

          <div className="dca-footer-link-group-header"
          >Extracurriculars</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.ecSearch)
            }}
          >Explorer</div>

          <div
            className="dca-footer-sublink"
            onClick={() => {
              navigate(pageRoutes.ecPremiumInsightsBase)
            }}
          >EC Insights</div>

          {(apiToken != undefined) ? <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.testScoreStrategy)
            }}
          >Test Score Guidance</div> : <a
            className="dca-footer-link"
            style={{ color: 'black' }}
            data-bs-toggle={"modal"}
            href={"#login_modal"}
            onClick={() => {
              setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
              apiToken == undefined && handleGAEventSignInModalOpen('forced')
            }}
          >Test Score Guidance</a>}

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.advisorSearch)
            }}
          >Advisors Network</div>
        </div>
      </div>
    </div>
  )

  const company = (
    <div id="dca-footer-company-wrapper">
      <div className="dca-footer-group-title">COMPANY</div>
      <div className="dca-footer-company-links">
        <div className="d-flex flex-column">
          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.about)
            }}
          >About</div>

          <a
            className="dca-footer-link"
            href={pageRoutes.blog}
          >Blog</a>

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.faq)
            }}
          >FAQ</div>

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.contact)
            }}
          >Contact</div>

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.privacyPolicy)
            }}
          >Privacy Policy</div>

          <div
            className="dca-footer-link"
            onClick={() => {
              navigate(pageRoutes.termsOfService)
            }}
          >Terms</div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="dca-footer-wrapper">

      <div className="dca-footer-links-wrapper">
        {toolsResources}
        {company}
      </div>

      <div className="dca-footer-copyright">{new Date().getFullYear()} &copy; NEXTFOUR.AI</div>
    </div>
  )
}

export { Footer }