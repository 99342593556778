import Chart from 'react-apexcharts'

export const ACTBubbleChart = ({ seriesAName, seriesA, seriesBName, seriesB, yMin, yMax, chartId }) => {
  return (
    <Chart
      options={{
        chart: {
          id: chartId
        },
        xaxis: {
          type: 'numeric',
          decimalsInFloat: 2,
          max: 4.5,
          min: 3.6,
          tickAmount: 9,
          title: {
            text: "Average GPA",
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          min: yMin,
          max: yMax,
          tickAmount: 7,
          labels: {
            show: true,
            formatter: function (val, index) {
              return val.toFixed(0);
            }
          },
          title: {
            text: "Median ACT Composite Score",
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
        },
        colors: ["#C5DEF9", "#98F29B"],
        fill: {
          opacity: 0.6,
          type: 'fill',
        },
        plotOptions: {
          bubble: {
            zScaling: true,
            minBubbleRadius: 5,
            maxBubbleRadius: 12,
          },
        },
        annotations: {
          xaxis: [{
            x: 4.00,
            strokeDashArray: 10,
            borderColor: '#9AA2B6',
            label: {
              text: 'Maximum Unweighted GPA',
              position: 'top',
              orientation: 'horizontal',
              borderColor: '#9AA2B6',
              style: {
                background: '#fff',
                color: '#2D2D2D',
              },
            }
          }],
		  texts: [{
			x: 493,
			y: 50,
			text: 'Tip: Hover on bubbles to see college-specific details',
		  }],
        },
		title: {
		  text: 'Average GPA vs. Median ACT Composite Score',
		  style: {
			fontSize:  '18px',
		  },
		},
        dataLabels: {
          enabled: false
        },
        legend: {
          offsetY: 8,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        tooltip: {
          enabled: true,
          shared: false,
          custom: function ({ seriesIndex, dataPointIndex, w }) {

            if ((seriesIndex != null && seriesIndex != undefined) && (dataPointIndex != null && dataPointIndex != undefined)) {

              let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]

              return (
                '<div class="dca-apexcharts-tooltip">' +
                `<span>${data.name}</span>` +
                '<ul>' +
                `<li>GPA: ${data.x.toFixed(2)} ${seriesIndex == 0 ? '(W)' : '(UW)'}</li>` +
                `<li>Median ACT: ${data.y}</li>` +
                `<li>Admit Rate: ${(data.z * 100).toFixed(1)}%</li>` +
                `<li>Metarank: #${data.metaRank ? data.metaRank : 'metaRank'}</li>` +
                '</ul>' +
                "</div>"
              )
            }

            else {
              return '<div>Error generating tooltip data</div>'
            }
          },
        }
      }}
      series={[
        {
          name: seriesAName,
          data: seriesA
        },
        {
          name: seriesBName,
          data: seriesB
        },
      ]}
      type='bubble'
      height={600}
      width={800}
    />
  )
}

export const ACTBubbleChartClassRank = ({ seriesName, seriesData, yMin, yMax, chartId }) => {
  return (
    <Chart
      options={{
        chart: {
          id: chartId
        },
        xaxis: {
          type: 'numeric',
          decimalsInFloat: 0,
          max: 100,
          min: 0,
          tickAmount: 10,
          title: {
            text: "Class Rank: Percentage of Students In Top 10% Of HS Graduating Class",
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
          labels: {
            formatter: function (val) {
              return val + "%";
            }
          },
        },
        yaxis: {
          min: yMin,
          max: yMax,
          tickAmount: 7,
          labels: {
            show: true,
            formatter: function (val) {
              return val.toFixed(0);
            }
          },
          title: {
            text: "Median ACT Composite Score",
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
        },
        annotations: {
		  texts: [{
			x: 493,
			y: 50,
			text: 'Tip: Hover on bubbles to see college-specific details',
		  }],
        },
		title: {
		  text: 'Class Rank vs. Median ACT Composite Score',
		  style: {
			fontSize:  '18px',
		  },
		},
        colors: ["#98F29B"],
        fill: {
          opacity: 0.6,
          type: 'fill',
        },
        plotOptions: {
          bubble: {
            zScaling: true,
            minBubbleRadius: 5,
            maxBubbleRadius: 12,
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          offsetY: 8,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        tooltip: {
          enabled: true,
          shared: false,
          custom: function ({ seriesIndex, dataPointIndex, w }) {

            if ((seriesIndex != null && seriesIndex != undefined) && (dataPointIndex != null && dataPointIndex != undefined)) {

              let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]

              return (
                '<div class="dca-apexcharts-tooltip">' +
                `<span>${data.name}</span>` +
                '<ul>' +
                `<li>Class Rank (% In Top-Tenth): ${data.x.toFixed(1)}%</li>` +
                `<li>Median ACT: ${data.y}</li>` +
                `<li>Admit Rate: ${(data.z * 100).toFixed(1)}%</li>` +
                `<li>Metarank: #${data.metaRank ? data.metaRank : 'metaRank'}</li>` +
                '</ul>' +
                "</div>"
              )
            }

            else {
              return '<div>Error generating tooltip data</div>'
            }

          },
        }
      }}
      series={[
        {
          name: seriesName,
          data: seriesData
        }
      ]}
      type='bubble'
      height={600}
      width={800}
    />
  )
}
