import { useEffect, useState } from 'react';
import { LoadingSpinner } from "../../LoadingSpinner";
import { URL, pageRoutes, selectedTabGreen } from '../../dictionary';
import { useNavigate } from 'react-router';
import axios from 'axios';

const InsideScoop = ({ advisorECReports }) => {

  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentReport, setCurrentReport] = useState<any>(advisorECReports[currentIndex])
  const [advisorConditional, setAdvisorConditional] = useState(false)

  useEffect(() => {
    setCurrentReport(advisorECReports[currentIndex])

    const returnAssociatedAdvisorState = async (advisor_id) => {
      let res = await axios.get(`${URL}/advisor_profiles/${advisor_id}`)

      if (res && res.data && res.data.displayState == 1) {
        setAdvisorConditional(true)
      }

      else {
        setAdvisorConditional(false)
      }
    }

    returnAssociatedAdvisorState(advisorECReports[currentIndex].advisor_id)

  }, [advisorECReports, currentIndex])

  const returnQA = (currentReport) => {
    let qaArr: any = [];

    for (let i = 1; i <= 15; i++) {
      let question = currentReport[`question${i}`];
      let answer = currentReport[`answer${i}`];

      if (question && answer) {
        qaArr.push({ q: question, a: answer });
      }
    }

    if (qaArr.length > 0) {
      return (
        <div>
          {qaArr.map((res, index) => (
            <div key={index} style={{ marginTop: '20px' }}>
              <div className='fw-bold'>{res.q}</div>
              <div>{res.a}</div>
            </div>
          ))}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  if (!advisorECReports || advisorECReports.length === 0) {
    return <LoadingSpinner title={'Inside Scoop'} />;
  }

  const nextReport = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % advisorECReports.length);
  };

  const prevReport = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + advisorECReports.length) % advisorECReports.length);
  };

  const advisorCTA = (
    <div>
      <a
        type="button"
        className="btn btn-sm dca-connect-with-advisor-inside-scoop"
        onClick={() => {
          navigate(pageRoutes.advisorDetailWithId(currentReport.advisor_id))
        }}
      >Connect With This Advisor
      </a>
    </div>
  )

  const reportIndexArray = advisorECReports.map((_, index) => index)

  const returnFormattedDate = (dateVals) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // array of date values
    const date = dateVals.split('-')
    let monthIndex = date[1] - 1
    const month = months[monthIndex]
    const day = date[2]
    const year = date[0]

    return `${month} ${day}, ${year}`;
  }

  return (
    <div style={{ marginTop: '40px' }}>
      <div className='card shadow'>

        {advisorConditional && <div style={{ margin: '15px 30px', alignSelf: 'flex-end' }}>{advisorCTA}</div>}

        {/* Report Metadata and Conditional Button*/}
        <div className='d-flex flex-column' style={{ padding: advisorConditional ? '0px 40px 0px 40px' : '40px 40px 0px 40px' }}>
          <div className='dca-font-M' style={{ fontSize: '26px', textAlign: 'center' }}>Inside Scoop Report #{currentIndex + 1}</div>
          <div className='fw-bold' style={{ fontSize: '20px', marginTop: '12px' }}>From Participant In {currentReport.activityDate ? currentReport.activityDate : '-'}</div>
          <div className='fw-bold' style={{ fontSize: '20px' }}>Submitted On {currentReport.reportDate ? returnFormattedDate(currentReport.reportDate) : '-'}</div>
          {/* Overall Rating */}
          <div className='fw-bold' style={{ fontSize: '20px', marginTop: '12px', display: 'flex' }}>Overall rating: {currentReport.overallRating ? `${currentReport.overallRating}/10` : '-'}</div>
        </div>


        {/* Question and Answers */}
        <div style={{ margin: '0px 40px 20px 40px' }}>
          {returnQA(currentReport)}
        </div>

        {/* Navigation buttons */}
        {advisorECReports.length > 1 && (
          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>

            <ul className="pagination d-flex justify-content-center align-self-center">
              <li className="page-item previous" style={{ cursor: 'pointer' }}>
                <span
                  onClick={prevReport}
                  className="page-link">
                  <i className='previous'></i>
                </span>
              </li>

              <div className="d-flex" style={{ alignContent: 'end' }}>
                {reportIndexArray.map(index => (
                  <span
                    key={index}
                    style={{
                      fontWeight: currentIndex === index ? 'bold' : 'normal',
                      padding: '5px 12px',
                      cursor: 'pointer',
                      background: currentIndex === index ? selectedTabGreen : ''
                    }}
                    onClick={() => setCurrentIndex(index)}
                  >
                    {index + 1}
                  </span>
                ))}
              </div>

              <li className="page-item next" style={{ cursor: 'pointer' }}>
                <span
                  onClick={nextReport}
                  className="page-link">
                  <i className='next'></i>
                </span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export { InsideScoop }
