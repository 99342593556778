import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react"
import axios from "axios";
import Select from 'react-select'

import { URL, ecPremiumAccessCode, ecPremiumPriceIDs, getStripePrice, pageRoutes, updateTitleAndDescription } from "../../components/dictionary";
import { CollegeReactGAHandlers, ECReactGAHandlers, MarketingReactGAHandlers } from "../../components/ReactGAHandlers";
import { PurchaseButton, PurchaseButtonNav } from "../../components/Marketing/PurchaseButton";
import { StickyNavbar } from "../../components/Marketing/StickyNavbar";
import { extracurricularMap } from "../../components/extracurricularMap";
import { premiumecsMap } from "../../components/premiumECs";
import { getAuth } from "../../modules/auth";
import { HoverCards } from "../../components/Marketing/HoverCards";
import { FAQ } from '../../components/Marketing/FAQ';
import { Testimonials } from '../../components/Marketing/Testimonials';
import { MarketingImageWrapper } from '../../components/Marketing/ImageWrappers';
import { ConnectEmailCTA } from '../../components/Marketing/ConnectEmailCTA';

import diamond from '../../../custom_assets/marketing/hovercard-diamond.png'
import photo1 from '../../../custom_assets/marketing/extracurricular_insights/photo_1.png'
import photo2 from '../../../custom_assets/marketing/extracurricular_insights/photo_2.png'
import photo3 from '../../../custom_assets/marketing/extracurricular_insights/photo_3.png'
import photo4 from '../../../custom_assets/marketing/extracurricular_insights/photo_4.png'
import photo5 from '../../../custom_assets/marketing/extracurricular_insights/photo_5.png'

import mobilePhoto1a from '../../../custom_assets/marketing/extracurricular_insights/mobile/photo_1a.png'
import mobilePhoto1b from '../../../custom_assets/marketing/extracurricular_insights/mobile/photo_1b.png'
import mobilePhoto2a from '../../../custom_assets/marketing/extracurricular_insights/mobile/photo_2a.png'
import mobilePhoto2b from '../../../custom_assets/marketing/extracurricular_insights/mobile/photo_2b.png'
import mobilePhoto4a from '../../../custom_assets/marketing/extracurricular_insights/mobile/photo_4a.png'
import mobilePhoto4b from '../../../custom_assets/marketing/extracurricular_insights/mobile/photo_4b.png'
import mobilePhoto4c from '../../../custom_assets/marketing/extracurricular_insights/mobile/photo_4c.png'
import mobilePhoto4d from '../../../custom_assets/marketing/extracurricular_insights/mobile/photo_4d.png'
import mobilePhoto4e from '../../../custom_assets/marketing/extracurricular_insights/mobile/photo_4e.png'

const MarketingECInsight = ({ user, clientSecret, setClientSecret, setNavigateTo, navigateTo, setUpgradeUserGroupAccessCode, ecID, setECID }) => {

  const { handleGAEventPurchaseModalPurchase } = CollegeReactGAHandlers()
  const { handleECGAEventPurchaseModalPurchase } = ECReactGAHandlers()
  const { handleGAEventECInsightsReportSelectorClick } = MarketingReactGAHandlers()

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const navigate = useNavigate()
  const location = useLocation()

  const [nameOptions, setNameOptions] = useState<any>([])
  const [purchased, setPurchased] = useState<any>([])
  const [selectedEC, setSelectedEC] = useState<any>(undefined);
  const [ecInsight, setECInsight] = useState<any>(undefined)
  const [ecName, setECName] = useState<any>(undefined)
  const [premiumContentPrice, setPremiumContentPrice] = useState<any>(undefined)
  const [priceID, setPriceID] = useState<any>(undefined)


  const handleECSelectChange = (selectedOption) => {
    selectedOption ? navigate(pageRoutes.ecPremiumInsightsWithId(selectedOption.hyphenated)) : navigate(pageRoutes.ecPremiumInsightsBase)
  };

  const returnNumAlumni = (ecInsight) => {
    let numAlumni = ecInsight?.insights['Core Data'][0]?.sampleSize

    return numAlumni ? `(${numAlumni})` : ``
  }

  useEffect(() => {
    let names: any = []
    let purchased: any = []

    premiumecsMap.forEach((ec) => {
      let matchingEC = extracurricularMap.find(match => match.id == ec)

      if (matchingEC) {
        permissionGroups?.includes(`EC${matchingEC?.id}`) ? purchased.push({ name: matchingEC.name, hyphenated: matchingEC.hyphenated }) : names.push({ value: matchingEC.id, label: matchingEC.name, hyphenated: matchingEC.hyphenated })
      }
    })

    if (purchased.length > 0) {
      purchased = purchased.sort((a, b) => a.name.localeCompare(b.name))
    }
    names = names.sort((a, b) => a.label.localeCompare(b.label))

    setNameOptions(names)
    setPurchased(purchased)
  }, [user])

  useEffect(() => {

    let preselectedEC: string | undefined = undefined

    preselectedEC = location.pathname.split(`${pageRoutes.ecPremiumInsightsBase}-`)[1]

    if (preselectedEC) {
      setSelectedEC(nameOptions.find(opt => opt.hyphenated == preselectedEC))
    }

    else {
      setSelectedEC(undefined)
    }

  }, [location.pathname, nameOptions]);

  useEffect(() => {

    const getSelectedInsight = async (selectedEC) => {
      let insight = await axios.get(`${URL}/ec_insights/${selectedEC.value}`)

      setECInsight(insight.data)
    }

    const getPrice = async (selectedEC) => {
      let premiumProductID = ecPremiumPriceIDs.find((obj) => obj.activityID == selectedEC?.value)
      let price = await getStripePrice(premiumProductID?.priceID)

      price ? setPremiumContentPrice(price) : setPremiumContentPrice(undefined);
      premiumProductID?.priceID ? setPriceID(premiumProductID?.priceID) : setPriceID(undefined);
    }

    if (selectedEC) {
      setECID(selectedEC?.value);
      setECName(selectedEC?.label);
      getSelectedInsight(selectedEC);
      getPrice(selectedEC);
      setNavigateTo(`/extracurricular-insights-${selectedEC?.hyphenated}`)
    }

    else {
      setECID(null);
      setECName(undefined);
      setECInsight(undefined);
      setPremiumContentPrice(undefined);
      setPriceID(undefined);
      setNavigateTo('')
    }

  }, [selectedEC, user])

  let purchaseStateTrue = purchased?.length > 0 && !selectedEC

  const faqData = [
    { question: 'Where does all this data come from?', answer: 'This report is based on data that was gathered through rigorous analysis of prior program participants. This proprietary data and the accopmanying insights are exclusive to NextFour.' },
    { question: 'Is the analysis up-to-date?', answer: 'Yes, the reports are updated regularly with the latest information automatically provided to customers throughout the term of access. Moreover, the analysis is based primarily on participants from the past 5 years to help ensure freshness.' },
    { question: `Why isn't this report free?`, answer: 'While nearly all of the content, tools and services on NextFour are made freely available, this report is offered as a premium service (with a small fee) because of the significant investment required by NextFour to complete the proprietary analysis underlying the report.' }
  ]

  const testimonialData = [
    { statement: `“I found these EC Insights incredibly helpful and they were critical for deciding which programs to pursue for my summer after junior year. I really had no idea this kind of data was available and so glad to have found it!”`, source: 'Samantha', relation: 'High School Student' },
    { statement: `“This is amazing! As we've been helping our son think about his extracurriculars and planning ahead for college applications, these reports were very valuable to help ensure he was spending his time (and our money!) on the best programs out there...”`, source: 'David', relation: 'Parent of high school junior and freshman' }
  ]

  const purchasedReports = (
    <div className="dca-marketing-ec-insight-purchased-reports-wrapper">
      <div className="dca-marketing-ec-insight-purchased-reports-header">Purchased Reports</div>
      {purchased.map(purchase => (
        <div
          className="dca-marketing-ec-insight-purchased-reports-card"
          key={purchase.name}
          onClick={() => {
            navigate(pageRoutes.ecPremiumInsightsWithId(purchase.hyphenated))
          }}><span className="dca-marketing-ec-insight-purchased-reports-card-text-span">{purchase.name}&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa-solid fa-arrow-right" style={{ color: 'black', fontSize: '18px' }}></i></span>
          {/* <img src={diamond} className="dca-marketing-ec-insight-purchased-reports-card-diamond"></img> */}
        </div>
      )
      )}
    </div>
  )

  const selectComponent = (
    <div className={(ecInsight || !purchaseStateTrue) ? "dca-marketing-ec-select-with-insight" : "dca-marketing-ec-select"}>
      <div className={purchaseStateTrue ? '' : 'text-center'}>{purchaseStateTrue ? `Explore more extracurricular activity reports` : `Select the report for a specific extracurricular activity`}</div>
      <Select
        className="dca-marketing-select-dropdown"
        classNamePrefix="react-select"
        options={nameOptions}
        value={selectedEC}
        onChange={handleECSelectChange}
        placeholder={"Search for an EC program"}
        isClearable={true}
        onMenuOpen={() => {
          handleGAEventECInsightsReportSelectorClick()
        }}
      />
    </div>
  )

  const insightBulletPoints = (
    <ul className="dca-marketing-bullet-points" style={{ marginTop: '-120px' }}>
      {ecInsight?.insights['Core Data'][0]?.sampleSize && <li>This premium content module provides detailed data about {ecName} based on proprietary analysis of {ecInsight.insights['Core Data'][0].sampleSize} participating alumni.</li>}

      <li>Content includes analysis of Admission Correlation which indicates the college that alumni for this program are now attending (or recently attended.) Data is provided per specific college, as well as as by groups (i.e. Ivy League, top 20 ranked colleges, etc.)</li>

      <li>Module also includes data on the field(s) of study for activity alumni based on their declared college major(s). Data is provided by major area (i.e. electrical engineering, neuroscience, etc.) and by major family (i.e. humanities, engineering, social sciences, etc.)</li>

      <li>This module also indicates the location (U.S. state or international) of alumni as well as their grade level when participating.</li>

      <li>Purchase enables access for one calendar year and includes all updates for this content module published during that term. Content is limited to private use within your individual household per the <a href={pageRoutes.termsOfService} className='dca-marketing-college-explorer-link' target="_blank">Terms Of Service</a>.</li>
    </ul>
  )

  const insightActivityDetails = (
    <div className="dca-marketing-background-info-trigger" onClick={() => {
      navigate(pageRoutes.ecDetailWithId(selectedEC?.hyphenated))
    }}>View Background Info about Activity</div>
  )

  const purchasedTrue = (
    <div className="d-flex flex-column align-items-center">
      <StickyNavbar user={user} />

      <div className="dca-marketing-page-premium-content-label"><img src={diamond}></img> PREMIUM CONTENT</div>

      <div className="dca-marketing-page-premium-content-headline">Extracurriculars Insights</div>

      <div className="dca-marketing-page-ec-insights-purchase-true-wrapper">
        {purchasedReports}

        {selectComponent}

        <div style={{ marginTop: '65px' }}><HoverCards cardsOption="ECInsights" ecInsightTrue={true} /></div>
      </div>

      {/* <div className="dca-marketing-page-ec-insights-cover-story-link">Extracurricular Insights Cover Story</div>
      <div className="dca-marketing-page-email-cta">Have questions about the premium content? Email us at <a className="dca-marketing-page-email-link" href="mailto:email@nextfour.ai">email@nextfour.ai</a></div> */}

    </div>
  )

  const purchasedFalseOrSelectedEC = (<div className="d-flex flex-column align-items-center">
    <StickyNavbar user={user} purchaseButton={priceID && selectedEC?.value && premiumContentPrice && <PurchaseButtonNav
      user={user}
      priceID={priceID}
      groupAccessCode={`${ecPremiumAccessCode}${selectedEC?.value}`}
      clientSecret={clientSecret}
      setClientSecret={setClientSecret}
      setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
      premiumProductName={ecName}
      page={'EC Insights'}
      clickLocation={'Header'}
      activityID={selectedEC?.value}
      setNavigateTo={setNavigateTo}
    />} />

    <div className="dca-marketing-page-headline" id='dca-marketing-page-headline-ec-insights'>Be strategic in planning your extracurriculars</div>

    <div className="dca-marketing-sub-headline-ec">Add clarity as you build your extracurriculars profile by harnessing exclusive data-driven insights on an extracurricular activity's potential fit and college admissions impact.</div>

    {/* Use Bootstrap d-md-block to render while 768px or above */}
    <div className='d-none d-md-block'>
      <MarketingImageWrapper
        altText={'photoSpecializedRankings'}
        photo={photo1} />
    </div>

    {/* Mobile Replacement, only shows at below 768px */}
    <div className='d-block d-md-none'>
      <MarketingImageWrapper
        altText={'photoResearchSpend'}
        photo={mobilePhoto1a} />

      <div style={{ marginTop: '15px' }}></div>

      <MarketingImageWrapper
        altText={'photoResearchSpend'}
        photo={mobilePhoto1b} />
    </div>

    {/* Use Bootstrap d-md-block to render while 768px or above */}
    <div className='d-none d-md-block'>
      <div style={{ marginTop: '75px', marginBottom: ecInsight ? '-50px' : '100px' }}>{selectComponent}</div>
    </div>

    {/* Mobile Replacement, only shows at below 768px */}
    <div className='d-block d-md-none'>
      <div style={{ marginTop: '75px', marginBottom: ecInsight ? '-50px' : '73px' }}>{selectComponent}</div>
    </div>


    {priceID && selectedEC?.value && premiumContentPrice && <PurchaseButton
      user={user}
      priceID={priceID}
      groupAccessCode={`${ecPremiumAccessCode}${selectedEC?.value}`}
      clientSecret={clientSecret}
      setClientSecret={setClientSecret}
      setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
      premiumProductName={ecName}
      premiumContentPrice={premiumContentPrice}
      topMargin={'50px'}
      bottomMargin={'140px'}
      page={'EC Insights'}
      clickLocation={'Middle'}
      activityID={selectedEC?.value}
      setNavigateTo={setNavigateTo}
    />}

    {ecInsight && insightBulletPoints}

    {ecInsight && insightActivityDetails}

    <div className='dca-marketing-text-image-block' id='TIB-120'>
      <div className="dca-marketing-pt-wrapper">
        <div className="dca-marketing-pt-header">Discover data-driven insights on an extracurricular's impact on college admissions</div>
        <div className="dca-marketing-pt-body">Go beyond anecdotal (and potentially incorrect/out-dated) opinions on the impact of a specific EC activity by accessing our exclusive data which objectively indicates the college matriculation data for past program participants. Use this information (including an overall impact score) to help inform your EC strategy.</div>
      </div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-block'>
        <MarketingImageWrapper
          altText={'photoECInsight'}
          photo={photo2} />
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-block d-md-none'>
        <MarketingImageWrapper
          altText={'photoECInsight'}
          photo={mobilePhoto2a} />

        <div style={{ marginTop: '15px' }}></div>

        <MarketingImageWrapper
          altText={'photoECInsight'}
          photo={mobilePhoto2b} />
      </div>
    </div>

    <div className='dca-marketing-text-image-block' id='TIB-140'>
      <div className="dca-marketing-standalone-text" style={{ marginBottom: '30px' }}>Proprietary calculation of Impact Scores provides a single numerical indication of the strength of an EC program on the college admissions.</div>
      <MarketingImageWrapper
        altText={'photoECInsight'}
        photo={photo3}
        wrapper='C' />
    </div>


    <div className='dca-marketing-text-image-block' id='TIB-120'>
      <div className="dca-marketing-pt-wrapper">
        <div className="dca-marketing-pt-header">Gain insights on your potential fit with a program</div>
        <div className="dca-marketing-pt-body">Find programs that are strong fits with your own profile to help focus your (limited!) time on activities where you are more likely to be accepted/succeed</div>
      </div>

      {/* <MarketingImageWrapper
        altText={'photoECInsight'}
        photo={photo4} /> */}

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-block'>
        <MarketingImageWrapper
          altText={'photoECInsight'}
          photo={photo4} />
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-block d-md-none'>
        <MarketingImageWrapper
          altText={'photoECInsight'}
          photo={mobilePhoto4a} />

        <div style={{ marginTop: '15px' }}></div>

        <MarketingImageWrapper
          altText={'photoECInsight'}
          photo={mobilePhoto4b} />

        <div style={{ marginTop: '15px' }}></div>

        <MarketingImageWrapper
          altText={'photoECInsight'}
          photo={mobilePhoto4c} />

        <div style={{ marginTop: '15px' }}></div>

        <MarketingImageWrapper
          altText={'photoECInsight'}
          photo={mobilePhoto4d} />

        <div style={{ marginTop: '15px' }}></div>

        <MarketingImageWrapper
          altText={'photoECInsight'}
          photo={mobilePhoto4e} />
      </div>
    </div>

    <div className='dca-marketing-text-image-block' id='TIB-40'>
      <div className="dca-marketing-pt-wrapper">
        <div className="dca-marketing-pt-header">Insights powered by proprietary analysis of past participants</div>

        {ecName ? <div className="dca-marketing-pt-body">We have done the hard work of analyzing data from a sample of prior participants {returnNumAlumni(ecInsight)} to develop quantified insights about {ecName}. Use this information to help compare prospective EC pursuits and inform your EC strategy towards the goal of strengthening of your college applications.</div> : <div className="dca-marketing-pt-body">We have done the hard work of analyzing data from a sample of prior participants to develop quantified insights about individual programs/activities. Use this information to help compare prospective EC pursuits and inform your EC strategy towards the goal of strengthening of your college applications.</div>}
      </div>

      <MarketingImageWrapper
        altText={'photoECInsight'}
        photo={photo5}
        wrapper='C' />

      <div className='dca-marketing-disclaimer-text'>Note: Images above are meant to be illustrative only and do not represent the actual data for any specific program.</div>
    </div>

    <FAQ faqData={faqData} />

    <ConnectEmailCTA />

    <Testimonials data={testimonialData} />

    <div className='dca-marketing-text-image-block'>
      <div className='dca-marketing-ec-insight-report-text-base' style={{ marginBottom: '20px' }}>EXTRACURRICULAR INSIGHTS PREMIUM REPORT</div>
      <div className='dca-marketing-ec-insight-report-text-base-ecName'>{selectedEC?.label && selectedEC?.label}</div>
      {priceID && selectedEC?.value && premiumContentPrice && <PurchaseButton
        user={user}
        priceID={priceID}
        groupAccessCode={`${ecPremiumAccessCode}${selectedEC?.value}`}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        premiumProductName={ecName}
        premiumContentPrice={premiumContentPrice}
        topMargin={'50px'}
        bottomMargin={'140px'}
        page={'EC Insights'}
        clickLocation={'Bottom'}
        activityID={selectedEC?.value}
        setNavigateTo={setNavigateTo}
      />}
    </div>

    <div className={(ecInsight || !purchaseStateTrue) ? "dca-marketing-ec-select-with-insight" : "dca-marketing-ec-select"} style={{ marginTop: ecInsight ? '-160px' : '', marginBottom: '150px' }}>
      <Select
        className="dca-marketing-select-dropdown"
        classNamePrefix="react-select"
        options={nameOptions}
        value={selectedEC}
        onChange={handleECSelectChange}
        placeholder={"Search for an EC program"}
        isClearable={true}
      />
    </div>

    <HoverCards cardsOption="ECInsights" />

  </div>
  )

  return purchaseStateTrue ? purchasedTrue : purchasedFalseOrSelectedEC
}

export { MarketingECInsight }

{/* Border line */ }
{/* <div style={{ marginTop: '30px', borderBottom: 'solid 1px #ECEDF1' }}></div> */ }