export const fullCollegeMap = [
  {
    "id": "186131",
    "name": "Princeton University"
  },
  {
    "id": "166683",
    "name": "Massachusetts Institute of Technology"
  },
  {
    "id": "130794",
    "name": "Yale University"
  },
  {
    "id": "166027",
    "name": "Harvard University"
  },
  {
    "id": "243744",
    "name": "Stanford University"
  },
  {
    "id": "144050",
    "name": "University of Chicago"
  },
  {
    "id": "162928",
    "name": "Johns Hopkins University"
  },
  {
    "id": "215062",
    "name": "University of Pennsylvania"
  },
  {
    "id": "110404",
    "name": "California Institute of Technology"
  },
  {
    "id": "147767",
    "name": "Northwestern University"
  },
  {
    "id": "198419",
    "name": "Duke University"
  },
  {
    "id": "182670",
    "name": "Dartmouth College"
  },
  {
    "id": "217156",
    "name": "Brown University"
  },
  {
    "id": "221999",
    "name": "Vanderbilt University"
  },
  {
    "id": "179867",
    "name": "Washington University in St Louis"
  },
  {
    "id": "227757",
    "name": "Rice University"
  },
  {
    "id": "190415",
    "name": "Cornell University"
  },
  {
    "id": "152080",
    "name": "University of Notre Dame"
  },
  {
    "id": "190150",
    "name": "Columbia University"
  },
  {
    "id": "110635",
    "name": "University of California-Berkeley"
  },
  {
    "id": "110662",
    "name": "University of California-Los Angeles"
  },
  {
    "id": "131496",
    "name": "Georgetown University"
  },
  {
    "id": "139658",
    "name": "Emory University"
  },
  {
    "id": "211440",
    "name": "Carnegie Mellon University"
  },
  {
    "id": "123961",
    "name": "University of Southern California"
  },
  {
    "id": "170976",
    "name": "University of Michigan"
  },
  {
    "id": "193900",
    "name": "New York University"
  },
  {
    "id": "234076",
    "name": "University of Virginia"
  },
  {
    "id": "134130",
    "name": "University of Florida"
  },
  {
    "id": "199120",
    "name": "University of North Carolina at Chapel Hill"
  },
  {
    "id": "199847",
    "name": "Wake Forest University"
  },
  {
    "id": "110705",
    "name": "University of California-Santa Barbara"
  },
  {
    "id": "168148",
    "name": "Tufts University"
  },
  {
    "id": "110653",
    "name": "University of California-Irvine"
  },
  {
    "id": "110680",
    "name": "University of California-San Diego"
  },
  {
    "id": "164924",
    "name": "Boston College"
  },
  {
    "id": "195030",
    "name": "University of Rochester"
  },
  {
    "id": "110644",
    "name": "University of California-Davis"
  },
  {
    "id": "228778",
    "name": "The University of Texas at Austin"
  },
  {
    "id": "240444",
    "name": "University of Wisconsin-Madison"
  },
  {
    "id": "145637",
    "name": "University of Illinois Urbana-Champaign"
  },
  {
    "id": "164988",
    "name": "Boston University"
  },
  {
    "id": "231624",
    "name": "William & Mary"
  },
  {
    "id": "139755",
    "name": "Georgia Institute of Technology"
  },
  {
    "id": "160755",
    "name": "Tulane University of Louisiana"
  },
  {
    "id": "165015",
    "name": "Brandeis University"
  },
  {
    "id": "167358",
    "name": "Northeastern University"
  },
  {
    "id": "201645",
    "name": "Case Western Reserve University"
  },
  {
    "id": "139959",
    "name": "University of Georgia"
  },
  {
    "id": "204796",
    "name": "Ohio State University"
  },
  {
    "id": "194824",
    "name": "Rensselaer Polytechnic Institute"
  },
  {
    "id": "213543",
    "name": "Lehigh University"
  },
  {
    "id": "216597",
    "name": "Villanova University"
  },
  {
    "id": "243780",
    "name": "Purdue University"
  },
  {
    "id": "121150",
    "name": "Pepperdine University"
  },
  {
    "id": "122931",
    "name": "Santa Clara University"
  },
  {
    "id": "134097",
    "name": "Florida State University"
  },
  {
    "id": "135726",
    "name": "University of Miami"
  },
  {
    "id": "163286",
    "name": "University of Maryland"
  },
  {
    "id": "236948",
    "name": "University of Washington"
  },
  {
    "id": "131469",
    "name": "George Washington University"
  },
  {
    "id": "174066",
    "name": "University of Minnesota-Twin Cities"
  },
  {
    "id": "196413",
    "name": "Syracuse University"
  },
  {
    "id": "215293",
    "name": "University of Pittsburgh-Pittsburgh Campus"
  },
  {
    "id": "233921",
    "name": "Virginia Tech"
  },
  {
    "id": "129020",
    "name": "University of Connecticut"
  },
  {
    "id": "166629",
    "name": "University of Massachusetts-Amherst"
  },
  {
    "id": "168421",
    "name": "Worcester Polytechnic Institute"
  },
  {
    "id": "197708",
    "name": "Yeshiva University"
  },
  {
    "id": "228723",
    "name": "Texas A & M University"
  },
  {
    "id": "131159",
    "name": "American University"
  },
  {
    "id": "151351",
    "name": "Indiana University"
  },
  {
    "id": "191241",
    "name": "Fordham University"
  },
  {
    "id": "199193",
    "name": "North Carolina State University at Raleigh"
  },
  {
    "id": "228246",
    "name": "Southern Methodist University"
  },
  {
    "id": "117946",
    "name": "Loyola Marymount University"
  },
  {
    "id": "171100",
    "name": "Michigan State University"
  },
  {
    "id": "196097",
    "name": "Stony Brook University"
  },
  {
    "id": "217882",
    "name": "Clemson University"
  },
  {
    "id": "223232",
    "name": "Baylor University"
  },
  {
    "id": "495767",
    "name": "The Pennsylvania State University"
  },
  {
    "id": "110714",
    "name": "University of California-Santa Cruz"
  },
  {
    "id": "153658",
    "name": "University of Iowa"
  },
  {
    "id": "186867",
    "name": "Stevens Institute of Technology"
  },
  {
    "id": "196079",
    "name": "Binghamton University"
  },
  {
    "id": "235316",
    "name": "Gonzaga University"
  },
  {
    "id": "239105",
    "name": "Marquette University"
  },
  {
    "id": "110671",
    "name": "University of California-Riverside"
  },
  {
    "id": "126775",
    "name": "Colorado School of Mines"
  },
  {
    "id": "130943",
    "name": "University of Delaware"
  },
  {
    "id": "131520",
    "name": "Howard University"
  },
  {
    "id": "196088",
    "name": "University at Buffalo"
  },
  {
    "id": "198516",
    "name": "Elon University"
  },
  {
    "id": "228875",
    "name": "Texas Christian University"
  },
  {
    "id": "230038",
    "name": "Brigham Young University"
  },
  {
    "id": "100858",
    "name": "Auburn University"
  },
  {
    "id": "122436",
    "name": "University of San Diego"
  },
  {
    "id": "126614",
    "name": "University of Colorado Boulder"
  },
  {
    "id": "137351",
    "name": "University of South Florida"
  },
  {
    "id": "145600",
    "name": "University of Illinois Chicago"
  },
  {
    "id": "165334",
    "name": "Clark University"
  },
  {
    "id": "185828",
    "name": "New Jersey Institute of Technology"
  },
  {
    "id": "445188",
    "name": "University of California-Merced"
  },
  {
    "id": "104179",
    "name": "University of Arizona"
  },
  {
    "id": "122612",
    "name": "University of San Francisco"
  },
  {
    "id": "127060",
    "name": "University of Denver"
  },
  {
    "id": "179159",
    "name": "Saint Louis University"
  },
  {
    "id": "195003",
    "name": "Rochester Institute of Technology"
  },
  {
    "id": "196103",
    "name": "SUNY College of Environmental Science and Forestry"
  },
  {
    "id": "204024",
    "name": "Miami University-Oxford"
  },
  {
    "id": "209551",
    "name": "University of Oregon"
  },
  {
    "id": "212054",
    "name": "Drexel University"
  },
  {
    "id": "230764",
    "name": "University of Utah"
  },
  {
    "id": "129242",
    "name": "Fairfield University"
  },
  {
    "id": "146719",
    "name": "Loyola University Chicago"
  },
  {
    "id": "181002",
    "name": "Creighton University"
  },
  {
    "id": "186399",
    "name": "Rutgers University-Newark"
  },
  {
    "id": "218663",
    "name": "University of South Carolina-Columbia"
  },
  {
    "id": "221759",
    "name": "The University of Tennessee-Knoxville"
  },
  {
    "id": "104151",
    "name": "Arizona State University"
  },
  {
    "id": "111948",
    "name": "Chapman University"
  },
  {
    "id": "155317",
    "name": "University of Kansas"
  },
  {
    "id": "178396",
    "name": "University of Missouri-Columbia"
  },
  {
    "id": "216339",
    "name": "Temple University"
  },
  {
    "id": "231174",
    "name": "University of Vermont"
  },
  {
    "id": "102049",
    "name": "Samford University"
  },
  {
    "id": "131450",
    "name": "Gallaudet University"
  },
  {
    "id": "145725",
    "name": "Illinois Institute of Technology"
  },
  {
    "id": "153603",
    "name": "Iowa State University"
  },
  {
    "id": "186371",
    "name": "Rutgers University-Camden"
  },
  {
    "id": "190044",
    "name": "Clarkson University"
  },
  {
    "id": "193654",
    "name": "The New School"
  },
  {
    "id": "202480",
    "name": "University of Dayton"
  },
  {
    "id": "207500",
    "name": "University of Oklahoma-Norman Campus"
  },
  {
    "id": "216366",
    "name": "Thomas Jefferson University"
  },
  {
    "id": "100663",
    "name": "University of Alabama at Birmingham"
  },
  {
    "id": "100751",
    "name": "The University of Alabama"
  },
  {
    "id": "110583",
    "name": "California State University-Long Beach"
  },
  {
    "id": "132903",
    "name": "University of Central Florida"
  },
  {
    "id": "144740",
    "name": "DePaul University"
  },
  {
    "id": "153269",
    "name": "Drake University"
  },
  {
    "id": "157085",
    "name": "University of Kentucky"
  },
  {
    "id": "163268",
    "name": "University of Maryland-Baltimore County"
  },
  {
    "id": "174914",
    "name": "University of St Thomas"
  },
  {
    "id": "183044",
    "name": "University of New Hampshire-Main Campus"
  },
  {
    "id": "186584",
    "name": "Seton Hall University"
  },
  {
    "id": "207971",
    "name": "University of Tulsa"
  },
  {
    "id": "232186",
    "name": "George Mason University"
  },
  {
    "id": "236595",
    "name": "Seattle University"
  },
  {
    "id": "168342",
    "name": "Williams College"
  },
  {
    "id": "164465",
    "name": "Amherst College"
  },
  {
    "id": "121345",
    "name": "Pomona College"
  },
  {
    "id": "216287",
    "name": "Swarthmore College"
  },
  {
    "id": "168218",
    "name": "Wellesley College"
  },
  {
    "id": "161004",
    "name": "Bowdoin College"
  },
  {
    "id": "164155",
    "name": "United States Naval Academy"
  },
  {
    "id": "173258",
    "name": "Carleton College"
  },
  {
    "id": "112260",
    "name": "Claremont McKenna College"
  },
  {
    "id": "197036",
    "name": "United States Military Academy"
  },
  {
    "id": "230959",
    "name": "Middlebury College"
  },
  {
    "id": "234207",
    "name": "Washington and Lee University"
  },
  {
    "id": "167835",
    "name": "Smith College"
  },
  {
    "id": "197133",
    "name": "Vassar College"
  },
  {
    "id": "153384",
    "name": "Grinnell College"
  },
  {
    "id": "191515",
    "name": "Hamilton College"
  },
  {
    "id": "198385",
    "name": "Davidson College"
  },
  {
    "id": "128328",
    "name": "United States Air Force Academy"
  },
  {
    "id": "130697",
    "name": "Wesleyan University"
  },
  {
    "id": "189097",
    "name": "Barnard College"
  },
  {
    "id": "190099",
    "name": "Colgate University"
  },
  {
    "id": "212911",
    "name": "Haverford College"
  },
  {
    "id": "233374",
    "name": "University of Richmond"
  },
  {
    "id": "161086",
    "name": "Colby College"
  },
  {
    "id": "160977",
    "name": "Bates College"
  },
  {
    "id": "156295",
    "name": "Berea College"
  },
  {
    "id": "126678",
    "name": "Colorado College"
  },
  {
    "id": "173902",
    "name": "Macalester College"
  },
  {
    "id": "115409",
    "name": "Harvey Mudd College"
  },
  {
    "id": "399911",
    "name": "Soka University of America"
  },
  {
    "id": "186380",
    "name": "Rutgers University"
  },
  {
    "id": "100654",
    "name": "Alabama A & M University"
  },
  {
    "id": "100690",
    "name": "Amridge University"
  },
  {
    "id": "100706",
    "name": "University of Alabama in Huntsville"
  },
  {
    "id": "100724",
    "name": "Alabama State University"
  },
  {
    "id": "100760",
    "name": "Central Alabama Community College"
  },
  {
    "id": "100812",
    "name": "Athens State University"
  },
  {
    "id": "100830",
    "name": "Auburn University at Montgomery"
  },
  {
    "id": "100937",
    "name": "Birmingham-Southern College"
  },
  {
    "id": "101028",
    "name": "Chattahoochee Valley Community College"
  },
  {
    "id": "101116",
    "name": "South University-Montgomery"
  },
  {
    "id": "101143",
    "name": "Enterprise State Community College"
  },
  {
    "id": "101161",
    "name": "Coastal Alabama Community College"
  },
  {
    "id": "101189",
    "name": "Faulkner University"
  },
  {
    "id": "101240",
    "name": "Gadsden State Community College"
  },
  {
    "id": "101277",
    "name": "New Beginning College of Cosmetology"
  },
  {
    "id": "101286",
    "name": "George C Wallace Community College-Dothan"
  },
  {
    "id": "101295",
    "name": "George C Wallace State Community College-Hanceville"
  },
  {
    "id": "101301",
    "name": "George C Wallace State Community College-Selma"
  },
  {
    "id": "101365",
    "name": "Herzing University-Birmingham"
  },
  {
    "id": "101435",
    "name": "Huntingdon College"
  },
  {
    "id": "101453",
    "name": "Heritage Christian University"
  },
  {
    "id": "101462",
    "name": "J. F. Drake State Community and Technical College"
  },
  {
    "id": "101471",
    "name": "J F Ingram State Technical College"
  },
  {
    "id": "101480",
    "name": "Jacksonville State University"
  },
  {
    "id": "101505",
    "name": "Jefferson State Community College"
  },
  {
    "id": "101514",
    "name": "John C Calhoun State Community College"
  },
  {
    "id": "101569",
    "name": "Lawson State Community College"
  },
  {
    "id": "101587",
    "name": "University of West Alabama"
  },
  {
    "id": "101602",
    "name": "Lurleen B Wallace Community College"
  },
  {
    "id": "101648",
    "name": "Marion Military Institute"
  },
  {
    "id": "101675",
    "name": "Miles College"
  },
  {
    "id": "101693",
    "name": "University of Mobile"
  },
  {
    "id": "101709",
    "name": "University of Montevallo"
  },
  {
    "id": "101736",
    "name": "Northwest-Shoals Community College"
  },
  {
    "id": "101879",
    "name": "University of North Alabama"
  },
  {
    "id": "101897",
    "name": "Northeast Alabama Community College"
  },
  {
    "id": "101912",
    "name": "Oakwood University"
  },
  {
    "id": "101994",
    "name": "Reid State Technical College"
  },
  {
    "id": "102030",
    "name": "Bishop State Community College"
  },
  {
    "id": "102067",
    "name": "Shelton State Community College"
  },
  {
    "id": "102076",
    "name": "Snead State Community College"
  },
  {
    "id": "102094",
    "name": "University of South Alabama"
  },
  {
    "id": "102234",
    "name": "Spring Hill College"
  },
  {
    "id": "102270",
    "name": "Stillman College"
  },
  {
    "id": "102298",
    "name": "Talladega College"
  },
  {
    "id": "102313",
    "name": "H Councill Trenholm State Community College"
  },
  {
    "id": "102368",
    "name": "Troy University"
  },
  {
    "id": "102377",
    "name": "Tuskegee University"
  },
  {
    "id": "102395",
    "name": "United States Sports Academy"
  },
  {
    "id": "102429",
    "name": "Bevill State Community College"
  },
  {
    "id": "102553",
    "name": "University of Alaska Anchorage"
  },
  {
    "id": "102580",
    "name": "Alaska Bible College"
  },
  {
    "id": "102614",
    "name": "University of Alaska Fairbanks"
  },
  {
    "id": "102632",
    "name": "University of Alaska Southeast"
  },
  {
    "id": "102669",
    "name": "Alaska Pacific University"
  },
  {
    "id": "102711",
    "name": "Alaska Vocational Technical Center"
  },
  {
    "id": "102845",
    "name": "Charter College"
  },
  {
    "id": "103501",
    "name": "Alaska Career College"
  },
  {
    "id": "103741",
    "name": "Empire Beauty School-Tucson"
  },
  {
    "id": "103893",
    "name": "Carrington College-Phoenix North"
  },
  {
    "id": "103909",
    "name": "Carrington College-Mesa"
  },
  {
    "id": "103927",
    "name": "Carrington College-Tucson"
  },
  {
    "id": "103954",
    "name": "Arizona Academy of Beauty"
  },
  {
    "id": "103963",
    "name": "Miller-Motte College-Arizona Automotive Institute"
  },
  {
    "id": "104090",
    "name": "Brookline College-Phoenix"
  },
  {
    "id": "104160",
    "name": "Arizona Western College"
  },
  {
    "id": "104346",
    "name": "Central Arizona College"
  },
  {
    "id": "104391",
    "name": "Charles of Italy Beauty College"
  },
  {
    "id": "104425",
    "name": "Cochise County Community College District"
  },
  {
    "id": "104443",
    "name": "Empire Beauty School-Chandler"
  },
  {
    "id": "104568",
    "name": "Avalon Institute-Mesa"
  },
  {
    "id": "104577",
    "name": "Eastern Arizona College"
  },
  {
    "id": "104586",
    "name": "Embry-Riddle Aeronautical University-Prescott"
  },
  {
    "id": "104665",
    "name": "The School of Architecture"
  },
  {
    "id": "104708",
    "name": "Glendale Community College"
  },
  {
    "id": "104717",
    "name": "Grand Canyon University"
  },
  {
    "id": "105145",
    "name": "GateWay Community College"
  },
  {
    "id": "105154",
    "name": "Mesa Community College"
  },
  {
    "id": "105206",
    "name": "Mohave Community College"
  },
  {
    "id": "105215",
    "name": "Universal Technical Institute of Arizona Inc-Motorcycle Mechanics Institute Division"
  },
  {
    "id": "105297",
    "name": "Dine College"
  },
  {
    "id": "105330",
    "name": "Northern Arizona University"
  },
  {
    "id": "105349",
    "name": "Northland Pioneer College"
  },
  {
    "id": "105367",
    "name": "Ottawa University-Phoenix"
  },
  {
    "id": "105428",
    "name": "Phoenix College"
  },
  {
    "id": "105525",
    "name": "Pima Community College"
  },
  {
    "id": "105534",
    "name": "Pima Medical Institute-Tucson"
  },
  {
    "id": "105543",
    "name": "Pima Medical Institute-Albuquerque"
  },
  {
    "id": "105589",
    "name": "Prescott College"
  },
  {
    "id": "105659",
    "name": "Refrigeration School Inc"
  },
  {
    "id": "105668",
    "name": "Rio Salado College"
  },
  {
    "id": "105677",
    "name": "Roberto-Venn School of Luthiery"
  },
  {
    "id": "105747",
    "name": "Scottsdale Community College"
  },
  {
    "id": "105792",
    "name": "South Mountain Community College"
  },
  {
    "id": "105899",
    "name": "Arizona Christian University"
  },
  {
    "id": "106041",
    "name": "Universal Technical Institute of Arizona Inc"
  },
  {
    "id": "106148",
    "name": "Yavapai College"
  },
  {
    "id": "106245",
    "name": "University of Arkansas at Little Rock"
  },
  {
    "id": "106263",
    "name": "University of Arkansas for Medical Sciences"
  },
  {
    "id": "106306",
    "name": "Arkansas Baptist College"
  },
  {
    "id": "106315",
    "name": "Arkansas Beauty School-Little Rock"
  },
  {
    "id": "106324",
    "name": "Arkansas Beauty College"
  },
  {
    "id": "106342",
    "name": "Lyon College"
  },
  {
    "id": "106351",
    "name": "Arkansas Technical School"
  },
  {
    "id": "106360",
    "name": "Arthur's Beauty College"
  },
  {
    "id": "106397",
    "name": "University of Arkansas"
  },
  {
    "id": "106412",
    "name": "University of Arkansas at Pine Bluff"
  },
  {
    "id": "106449",
    "name": "Arkansas State University-Beebe"
  },
  {
    "id": "106458",
    "name": "Arkansas State University"
  },
  {
    "id": "106467",
    "name": "Arkansas Tech University"
  },
  {
    "id": "106485",
    "name": "University of Arkansas at Monticello"
  },
  {
    "id": "106494",
    "name": "Arthur's Beauty College"
  },
  {
    "id": "106546",
    "name": "Baptist Health College Little Rock"
  },
  {
    "id": "106625",
    "name": "Black River Technical College"
  },
  {
    "id": "106704",
    "name": "University of Central Arkansas"
  },
  {
    "id": "106713",
    "name": "Central Baptist College"
  },
  {
    "id": "106795",
    "name": "Cossatot Community College of the University of Arkansas"
  },
  {
    "id": "106810",
    "name": "Crowley's Ridge College"
  },
  {
    "id": "106883",
    "name": "East Arkansas Community College"
  },
  {
    "id": "106892",
    "name": "Eastern College of Health Vocations-Little Rock"
  },
  {
    "id": "106908",
    "name": "Paul Mitchell the School-Little Rock"
  },
  {
    "id": "106980",
    "name": "National Park College"
  },
  {
    "id": "106999",
    "name": "University of Arkansas Community College-Batesville"
  },
  {
    "id": "107044",
    "name": "Harding University"
  },
  {
    "id": "107071",
    "name": "Henderson State University"
  },
  {
    "id": "107080",
    "name": "Hendrix College"
  },
  {
    "id": "107099",
    "name": "Hot Springs Beauty College"
  },
  {
    "id": "107123",
    "name": "Jefferson Regional School of Nursing"
  },
  {
    "id": "107141",
    "name": "John Brown University"
  },
  {
    "id": "107220",
    "name": "Career Academy of Hair Design"
  },
  {
    "id": "107293",
    "name": "Academy of Salon and Spa"
  },
  {
    "id": "107318",
    "name": "Arkansas State University Mid-South"
  },
  {
    "id": "107327",
    "name": "Arkansas Northeastern College"
  },
  {
    "id": "107442",
    "name": "New Tyler Barber College Inc"
  },
  {
    "id": "107460",
    "name": "North Arkansas College"
  },
  {
    "id": "107488",
    "name": "Northwest Technical Institute"
  },
  {
    "id": "107512",
    "name": "Ouachita Baptist University"
  },
  {
    "id": "107521",
    "name": "Arkansas State University Three Rivers"
  },
  {
    "id": "107549",
    "name": "Ozarka College"
  },
  {
    "id": "107558",
    "name": "University of the Ozarks"
  },
  {
    "id": "107585",
    "name": "University of Arkansas Community College-Morrilton"
  },
  {
    "id": "107600",
    "name": "Philander Smith College"
  },
  {
    "id": "107619",
    "name": "Phillips Community College of the University of Arkansas"
  },
  {
    "id": "107637",
    "name": "Southeast Arkansas College"
  },
  {
    "id": "107655",
    "name": "Professional Cosmetology Education Center"
  },
  {
    "id": "107664",
    "name": "University of Arkansas-Pulaski Technical College"
  },
  {
    "id": "107725",
    "name": "University of Arkansas Hope-Texarkana"
  },
  {
    "id": "107743",
    "name": "University of Arkansas Community College Rich Mountain"
  },
  {
    "id": "107789",
    "name": "Paul Mitchell the School-Arkansas"
  },
  {
    "id": "107831",
    "name": "Searcy Beauty College"
  },
  {
    "id": "107840",
    "name": "Shorter College"
  },
  {
    "id": "107877",
    "name": "Williams Baptist University"
  },
  {
    "id": "107974",
    "name": "South Arkansas Community College"
  },
  {
    "id": "107983",
    "name": "Southern Arkansas University Main Campus"
  },
  {
    "id": "107992",
    "name": "Southern Arkansas University Tech"
  },
  {
    "id": "108065",
    "name": "Velvatex College of Beauty Culture"
  },
  {
    "id": "108092",
    "name": "University of Arkansas-Fort Smith"
  },
  {
    "id": "108232",
    "name": "Academy of Art University"
  },
  {
    "id": "108269",
    "name": "Academy of Chinese Culture and Health Sciences"
  },
  {
    "id": "108667",
    "name": "College of Alameda"
  },
  {
    "id": "108807",
    "name": "Allan Hancock College"
  },
  {
    "id": "108852",
    "name": "American Academy of Dramatic Arts-Los Angeles"
  },
  {
    "id": "108861",
    "name": "Berkeley School of Theology"
  },
  {
    "id": "108870",
    "name": "American Film Institute Conservatory"
  },
  {
    "id": "108977",
    "name": "American Beauty College"
  },
  {
    "id": "109040",
    "name": "American Career College-Los Angeles"
  },
  {
    "id": "109086",
    "name": "American Conservatory Theater"
  },
  {
    "id": "109208",
    "name": "American River College"
  },
  {
    "id": "109350",
    "name": "Antelope Valley Community College District"
  },
  {
    "id": "109651",
    "name": "Art Center College of Design"
  },
  {
    "id": "109721",
    "name": "Associated Technical College-Los Angeles"
  },
  {
    "id": "109730",
    "name": "Associated Technical College-San Diego"
  },
  {
    "id": "109785",
    "name": "Azusa Pacific University"
  },
  {
    "id": "109819",
    "name": "Bakersfield College"
  },
  {
    "id": "109907",
    "name": "Barstow Community College"
  },
  {
    "id": "109934",
    "name": "Bellus Academy-Chula Vista"
  },
  {
    "id": "110060",
    "name": "Bethesda University"
  },
  {
    "id": "110097",
    "name": "Biola University"
  },
  {
    "id": "110194",
    "name": "Brownson Technical School"
  },
  {
    "id": "110219",
    "name": "Bryan University"
  },
  {
    "id": "110246",
    "name": "Butte College"
  },
  {
    "id": "110316",
    "name": "California Institute of Integral Studies"
  },
  {
    "id": "110334",
    "name": "Cabrillo College"
  },
  {
    "id": "110361",
    "name": "California Baptist University"
  },
  {
    "id": "110370",
    "name": "California College of the Arts"
  },
  {
    "id": "110398",
    "name": "University of California-Hastings College of Law"
  },
  {
    "id": "110413",
    "name": "California Lutheran University"
  },
  {
    "id": "110422",
    "name": "California Polytechnic State University-San Luis Obispo"
  },
  {
    "id": "110468",
    "name": "Alliant International University-San Diego"
  },
  {
    "id": "110486",
    "name": "California State University-Bakersfield"
  },
  {
    "id": "110495",
    "name": "California State University-Stanislaus"
  },
  {
    "id": "110510",
    "name": "California State University-San Bernardino"
  },
  {
    "id": "110529",
    "name": "California State Polytechnic University-Pomona"
  },
  {
    "id": "110538",
    "name": "California State University-Chico"
  },
  {
    "id": "110547",
    "name": "California State University-Dominguez Hills"
  },
  {
    "id": "110556",
    "name": "California State University-Fresno"
  },
  {
    "id": "110565",
    "name": "California State University-Fullerton"
  },
  {
    "id": "110574",
    "name": "California State University-East Bay"
  },
  {
    "id": "110592",
    "name": "California State University-Los Angeles"
  },
  {
    "id": "110608",
    "name": "California State University-Northridge"
  },
  {
    "id": "110617",
    "name": "California State University-Sacramento"
  },
  {
    "id": "110699",
    "name": "University of California-San Francisco"
  },
  {
    "id": "110778",
    "name": "Sofia University"
  },
  {
    "id": "110875",
    "name": "California Beauty School"
  },
  {
    "id": "110918",
    "name": "California Christian College"
  },
  {
    "id": "111045",
    "name": "Haven University"
  },
  {
    "id": "111054",
    "name": "California Hair Design Academy"
  },
  {
    "id": "111081",
    "name": "California Institute of the Arts"
  },
  {
    "id": "111188",
    "name": "California State University Maritime Academy"
  },
  {
    "id": "111391",
    "name": "California Western School of Law"
  },
  {
    "id": "111434",
    "name": "Canada College"
  },
  {
    "id": "111461",
    "name": "College of the Canyons"
  },
  {
    "id": "111513",
    "name": "Career Academy of Beauty"
  },
  {
    "id": "111638",
    "name": "Casa Loma College-Los Angeles"
  },
  {
    "id": "111708",
    "name": "CET-San Diego"
  },
  {
    "id": "111717",
    "name": "CET-San Jose"
  },
  {
    "id": "111726",
    "name": "CET-El Centro"
  },
  {
    "id": "111744",
    "name": "CET-Colton"
  },
  {
    "id": "111762",
    "name": "CET-Watsonville"
  },
  {
    "id": "111799",
    "name": "CET-Salinas"
  },
  {
    "id": "111887",
    "name": "Cerritos College"
  },
  {
    "id": "111896",
    "name": "Cerro Coso Community College"
  },
  {
    "id": "111920",
    "name": "Chabot College"
  },
  {
    "id": "111939",
    "name": "Chaffey College"
  },
  {
    "id": "111966",
    "name": "Charles R Drew University of Medicine and Science"
  },
  {
    "id": "112075",
    "name": "Concordia University-Irvine"
  },
  {
    "id": "112084",
    "name": "San Diego Christian College"
  },
  {
    "id": "112127",
    "name": "Church Divinity School of the Pacific"
  },
  {
    "id": "112172",
    "name": "Citrus College"
  },
  {
    "id": "112181",
    "name": "Citrus Heights Beauty College"
  },
  {
    "id": "112190",
    "name": "City College of San Francisco"
  },
  {
    "id": "112251",
    "name": "Claremont Graduate University"
  },
  {
    "id": "112288",
    "name": "Milan Institute-Visalia"
  },
  {
    "id": "112367",
    "name": "Clovis Adult Education"
  },
  {
    "id": "112385",
    "name": "Coastline Community College"
  },
  {
    "id": "112394",
    "name": "University of Silicon Valley"
  },
  {
    "id": "112455",
    "name": "Coba Academy"
  },
  {
    "id": "112525",
    "name": "Western University of Health Sciences"
  },
  {
    "id": "112561",
    "name": "Columbia College"
  },
  {
    "id": "112570",
    "name": "Columbia College Hollywood"
  },
  {
    "id": "112686",
    "name": "Compton College"
  },
  {
    "id": "112826",
    "name": "Contra Costa College"
  },
  {
    "id": "113096",
    "name": "Cosumnes River College"
  },
  {
    "id": "113111",
    "name": "Crafton Hills College"
  },
  {
    "id": "113193",
    "name": "Cuesta College"
  },
  {
    "id": "113218",
    "name": "Cuyamaca College"
  },
  {
    "id": "113236",
    "name": "Cypress College"
  },
  {
    "id": "113333",
    "name": "De Anza College"
  },
  {
    "id": "113449",
    "name": "International School of Beauty Inc"
  },
  {
    "id": "113537",
    "name": "Dell'Arte International School of Physical Theatre"
  },
  {
    "id": "113573",
    "name": "College of the Desert"
  },
  {
    "id": "113582",
    "name": "Design Institute of San Diego"
  },
  {
    "id": "113634",
    "name": "Diablo Valley College"
  },
  {
    "id": "113698",
    "name": "Dominican University of California"
  },
  {
    "id": "113704",
    "name": "Dominican School of Philosophy & Theology"
  },
  {
    "id": "113856",
    "name": "East Los Angeles College"
  },
  {
    "id": "113980",
    "name": "El Camino Community College District"
  },
  {
    "id": "114114",
    "name": "Emperor's College of Traditional Oriental Medicine"
  },
  {
    "id": "114123",
    "name": "Empire College"
  },
  {
    "id": "114266",
    "name": "Evergreen Valley College"
  },
  {
    "id": "114354",
    "name": "FIDM-Fashion Institute of Design & Merchandising"
  },
  {
    "id": "114433",
    "name": "Feather River Community College District"
  },
  {
    "id": "114460",
    "name": "Federico Beauty Institute"
  },
  {
    "id": "114549",
    "name": "Fielding Graduate University"
  },
  {
    "id": "114585",
    "name": "Five Branches University"
  },
  {
    "id": "114637",
    "name": "Redondo Beach Beauty College"
  },
  {
    "id": "114716",
    "name": "Foothill College"
  },
  {
    "id": "114734",
    "name": "Franciscan School of Theology"
  },
  {
    "id": "114761",
    "name": "Fredrick and Charles Beauty College"
  },
  {
    "id": "114789",
    "name": "Fresno City College"
  },
  {
    "id": "114813",
    "name": "Fresno Pacific University"
  },
  {
    "id": "114840",
    "name": "Fuller Theological Seminary"
  },
  {
    "id": "114859",
    "name": "Fullerton College"
  },
  {
    "id": "114938",
    "name": "Gavilan College"
  },
  {
    "id": "114947",
    "name": "Gemological Institute of America-Carlsbad"
  },
  {
    "id": "115001",
    "name": "Glendale Community College"
  },
  {
    "id": "115010",
    "name": "Glendale Career College"
  },
  {
    "id": "115083",
    "name": "Golden Gate University"
  },
  {
    "id": "115126",
    "name": "Golden West College"
  },
  {
    "id": "115214",
    "name": "Graduate Theological Union"
  },
  {
    "id": "115296",
    "name": "Grossmont College"
  },
  {
    "id": "115357",
    "name": "Salon Success Academy-Riverside"
  },
  {
    "id": "115393",
    "name": "Hartnell College"
  },
  {
    "id": "115658",
    "name": "Hilltop Beauty School"
  },
  {
    "id": "115728",
    "name": "Holy Names University"
  },
  {
    "id": "115746",
    "name": "Pacifica Graduate Institute"
  },
  {
    "id": "115755",
    "name": "California State Polytechnic University-Humboldt"
  },
  {
    "id": "115773",
    "name": "Humphreys University-Stockton and Modesto Campuses"
  },
  {
    "id": "115861",
    "name": "Imperial Valley College"
  },
  {
    "id": "115931",
    "name": "Institute for Business and Technology"
  },
  {
    "id": "116226",
    "name": "Interior Designers Institute"
  },
  {
    "id": "116350",
    "name": "International School of Cosmetology"
  },
  {
    "id": "116439",
    "name": "Irvine Valley College"
  },
  {
    "id": "116563",
    "name": "Bellus Academy-El Cajon"
  },
  {
    "id": "116846",
    "name": "American Jewish University"
  },
  {
    "id": "117052",
    "name": "Reedley College"
  },
  {
    "id": "117104",
    "name": "Life Pacific University"
  },
  {
    "id": "117140",
    "name": "University of La Verne"
  },
  {
    "id": "117168",
    "name": "Laguna College of Art and Design"
  },
  {
    "id": "117195",
    "name": "Lake Tahoe Community College"
  },
  {
    "id": "117238",
    "name": "Lancaster Beauty School"
  },
  {
    "id": "117247",
    "name": "Laney College"
  },
  {
    "id": "117274",
    "name": "Lassen Community College"
  },
  {
    "id": "117283",
    "name": "Latin American Bible Institute"
  },
  {
    "id": "117520",
    "name": "Life Chiropractic College West"
  },
  {
    "id": "117557",
    "name": "Lincoln University"
  },
  {
    "id": "117575",
    "name": "Southern California Seminary"
  },
  {
    "id": "117627",
    "name": "La Sierra University"
  },
  {
    "id": "117636",
    "name": "Loma Linda University"
  },
  {
    "id": "117645",
    "name": "Long Beach City College"
  },
  {
    "id": "117672",
    "name": "Southern California University of Health Sciences"
  },
  {
    "id": "117690",
    "name": "Los Angeles Harbor College"
  },
  {
    "id": "117706",
    "name": "Los Angeles Pierce College"
  },
  {
    "id": "117715",
    "name": "Los Angeles Southwest College"
  },
  {
    "id": "117724",
    "name": "Los Angeles Trade Technical College"
  },
  {
    "id": "117733",
    "name": "Los Angeles Valley College"
  },
  {
    "id": "117751",
    "name": "The Master's University and Seminary"
  },
  {
    "id": "117788",
    "name": "Los Angeles City College"
  },
  {
    "id": "117803",
    "name": "Los Angeles County College of Nursing and Allied Health"
  },
  {
    "id": "117867",
    "name": "Los Angeles Mission College"
  },
  {
    "id": "117894",
    "name": "Los Medanos College"
  },
  {
    "id": "117955",
    "name": "Lu Ross Academy"
  },
  {
    "id": "118134",
    "name": "Lyle's College of Beauty"
  },
  {
    "id": "118143",
    "name": "Lyle's College of Beauty"
  },
  {
    "id": "118161",
    "name": "Lytles Redwood Empire Beauty College"
  },
  {
    "id": "118198",
    "name": "MTI College"
  },
  {
    "id": "118347",
    "name": "College of Marin"
  },
  {
    "id": "118541",
    "name": "Marymount California University"
  },
  {
    "id": "118684",
    "name": "Mendocino College"
  },
  {
    "id": "118693",
    "name": "Menlo College"
  },
  {
    "id": "118718",
    "name": "Merced College"
  },
  {
    "id": "118772",
    "name": "Merritt College"
  },
  {
    "id": "118888",
    "name": "Mills College"
  },
  {
    "id": "118912",
    "name": "MiraCosta College"
  },
  {
    "id": "118930",
    "name": "Mission College"
  },
  {
    "id": "118958",
    "name": "Modern Beauty Academy"
  },
  {
    "id": "118976",
    "name": "Modesto Junior College"
  },
  {
    "id": "118994",
    "name": "Moler Barber College"
  },
  {
    "id": "119058",
    "name": "Middlebury Institute of International Studies at Monterey"
  },
  {
    "id": "119067",
    "name": "Monterey Peninsula College"
  },
  {
    "id": "119137",
    "name": "Moorpark College"
  },
  {
    "id": "119164",
    "name": "Mt San Antonio College"
  },
  {
    "id": "119173",
    "name": "Mount Saint Mary's University"
  },
  {
    "id": "119216",
    "name": "Mt San Jacinto Community College District"
  },
  {
    "id": "119270",
    "name": "Musicians Institute"
  },
  {
    "id": "119331",
    "name": "Napa Valley College"
  },
  {
    "id": "119553",
    "name": "National Holistic Institute"
  },
  {
    "id": "119605",
    "name": "National University"
  },
  {
    "id": "119678",
    "name": "Naval Postgraduate School"
  },
  {
    "id": "119775",
    "name": "Newschool of Architecture and Design"
  },
  {
    "id": "119951",
    "name": "North Adrian's College of Beauty Inc"
  },
  {
    "id": "120069",
    "name": "North-West College-Pomona"
  },
  {
    "id": "120078",
    "name": "North-West College-West Covina"
  },
  {
    "id": "120087",
    "name": "North-West College-Van Nuys"
  },
  {
    "id": "120166",
    "name": "San Francisco Bay University"
  },
  {
    "id": "120184",
    "name": "Notre Dame de Namur University"
  },
  {
    "id": "120254",
    "name": "Occidental College"
  },
  {
    "id": "120290",
    "name": "Ohlone College"
  },
  {
    "id": "120342",
    "name": "Orange Coast College"
  },
  {
    "id": "120403",
    "name": "Otis College of Art and Design"
  },
  {
    "id": "120421",
    "name": "Oxnard College"
  },
  {
    "id": "120537",
    "name": "Hope International University"
  },
  {
    "id": "120661",
    "name": "Concorde Career College-San Diego"
  },
  {
    "id": "120698",
    "name": "Palo Alto University"
  },
  {
    "id": "120768",
    "name": "Pacific Oaks College"
  },
  {
    "id": "120795",
    "name": "Pacific School of Religion"
  },
  {
    "id": "120838",
    "name": "Pacific States University"
  },
  {
    "id": "120865",
    "name": "Pacific Union College"
  },
  {
    "id": "120883",
    "name": "University of the Pacific"
  },
  {
    "id": "120953",
    "name": "Palo Verde College"
  },
  {
    "id": "120971",
    "name": "Palomar College"
  },
  {
    "id": "121044",
    "name": "Pasadena City College"
  },
  {
    "id": "121257",
    "name": "Pitzer College"
  },
  {
    "id": "121275",
    "name": "Platt College-San Diego"
  },
  {
    "id": "121309",
    "name": "Point Loma Nazarene University"
  },
  {
    "id": "121363",
    "name": "Porterville College"
  },
  {
    "id": "121433",
    "name": "Professional Institute of Beauty"
  },
  {
    "id": "121619",
    "name": "Santa Ana College"
  },
  {
    "id": "121628",
    "name": "Pardee RAND Graduate School"
  },
  {
    "id": "121637",
    "name": "Shasta School of Cosmetology"
  },
  {
    "id": "121691",
    "name": "University of Redlands"
  },
  {
    "id": "121707",
    "name": "College of the Redwoods"
  },
  {
    "id": "121859",
    "name": "Salon Success Academy-Upland"
  },
  {
    "id": "121868",
    "name": "Salon Success Academy-Corona"
  },
  {
    "id": "121886",
    "name": "Rio Hondo College"
  },
  {
    "id": "121901",
    "name": "Riverside City College"
  },
  {
    "id": "122117",
    "name": "Dongguk University Los Angeles"
  },
  {
    "id": "122180",
    "name": "Sacramento City College"
  },
  {
    "id": "122205",
    "name": "Saddleback College"
  },
  {
    "id": "122296",
    "name": "Samuel Merritt University"
  },
  {
    "id": "122339",
    "name": "San Diego City College"
  },
  {
    "id": "122375",
    "name": "San Diego Mesa College"
  },
  {
    "id": "122384",
    "name": "San Diego Miramar College"
  },
  {
    "id": "122409",
    "name": "San Diego State University"
  },
  {
    "id": "122454",
    "name": "San Francisco Art Institute"
  },
  {
    "id": "122506",
    "name": "San Francisco Conservatory of Music"
  },
  {
    "id": "122597",
    "name": "San Francisco State University"
  },
  {
    "id": "122649",
    "name": "San Joaquin College of Law"
  },
  {
    "id": "122658",
    "name": "San Joaquin Delta College"
  },
  {
    "id": "122685",
    "name": "San Joaquin Valley College-Visalia"
  },
  {
    "id": "122694",
    "name": "San Joaquin Valley College-Bakersfield"
  },
  {
    "id": "122728",
    "name": "William Jessup University"
  },
  {
    "id": "122746",
    "name": "San Jose City College"
  },
  {
    "id": "122755",
    "name": "San Jose State University"
  },
  {
    "id": "122791",
    "name": "College of San Mateo"
  },
  {
    "id": "122834",
    "name": "Santa Barbara Business College-Bakersfield"
  },
  {
    "id": "122889",
    "name": "Santa Barbara City College"
  },
  {
    "id": "122977",
    "name": "Santa Monica College"
  },
  {
    "id": "123013",
    "name": "Santa Rosa Junior College"
  },
  {
    "id": "123095",
    "name": "Saybrook University"
  },
  {
    "id": "123165",
    "name": "Scripps College"
  },
  {
    "id": "123217",
    "name": "College of the Sequoias"
  },
  {
    "id": "123280",
    "name": "Shasta Bible College and Graduate School"
  },
  {
    "id": "123299",
    "name": "Shasta College"
  },
  {
    "id": "123341",
    "name": "Sierra College"
  },
  {
    "id": "123350",
    "name": "Sierra College of Beauty"
  },
  {
    "id": "123448",
    "name": "Trinity Law School"
  },
  {
    "id": "123457",
    "name": "Simpson University"
  },
  {
    "id": "123484",
    "name": "College of the Siskiyous"
  },
  {
    "id": "123493",
    "name": "Charles A Jones Career and Education Center"
  },
  {
    "id": "123509",
    "name": "Skyline College"
  },
  {
    "id": "123527",
    "name": "San Bernardino Valley College"
  },
  {
    "id": "123554",
    "name": "Saint Mary's College of California"
  },
  {
    "id": "123563",
    "name": "Solano Community College"
  },
  {
    "id": "123572",
    "name": "Sonoma State University"
  },
  {
    "id": "123633",
    "name": "South Baylo University"
  },
  {
    "id": "123642",
    "name": "South Coast College"
  },
  {
    "id": "123651",
    "name": "Vanguard University of Southern California"
  },
  {
    "id": "123679",
    "name": "Concorde Career College-Garden Grove"
  },
  {
    "id": "123800",
    "name": "Southwestern College"
  },
  {
    "id": "123916",
    "name": "Starr King School for the Ministry"
  },
  {
    "id": "123943",
    "name": "Marshall B Ketchum University"
  },
  {
    "id": "123952",
    "name": "Southern California Institute of Architecture"
  },
  {
    "id": "123970",
    "name": "Southwestern Law School"
  },
  {
    "id": "124113",
    "name": "Taft College"
  },
  {
    "id": "124283",
    "name": "Claremont School of Theology"
  },
  {
    "id": "124292",
    "name": "Thomas Aquinas College"
  },
  {
    "id": "124487",
    "name": "Epic Bible College & Graduate School"
  },
  {
    "id": "124681",
    "name": "UEI College-Huntington Park"
  },
  {
    "id": "124706",
    "name": "Concorde Career College-San Bernardino"
  },
  {
    "id": "124937",
    "name": "Concorde Career College-North Hollywood"
  },
  {
    "id": "125028",
    "name": "Ventura College"
  },
  {
    "id": "125037",
    "name": "The Santa Barbara and Ventura Colleges of Law at Ventura"
  },
  {
    "id": "125082",
    "name": "Bridges Beauty College"
  },
  {
    "id": "125091",
    "name": "Victor Valley College"
  },
  {
    "id": "125170",
    "name": "Berkeley City College"
  },
  {
    "id": "125231",
    "name": "Walden University"
  },
  {
    "id": "125310",
    "name": "Waynes College of Beauty"
  },
  {
    "id": "125462",
    "name": "West Hills College-Coalinga"
  },
  {
    "id": "125471",
    "name": "West Los Angeles College"
  },
  {
    "id": "125499",
    "name": "West Valley College"
  },
  {
    "id": "125532",
    "name": "Carrington College-Sacramento"
  },
  {
    "id": "125718",
    "name": "Westminster Theological Seminary in California"
  },
  {
    "id": "125727",
    "name": "Westmont College"
  },
  {
    "id": "125763",
    "name": "Whittier College"
  },
  {
    "id": "125897",
    "name": "Woodbury University"
  },
  {
    "id": "126012",
    "name": "The Wright Institute"
  },
  {
    "id": "126049",
    "name": "Thomas Jefferson School of Law"
  },
  {
    "id": "126076",
    "name": "Yeshiva Ohr Elchonon Chabad West Coast Talmudical Seminary"
  },
  {
    "id": "126119",
    "name": "Yuba College"
  },
  {
    "id": "126164",
    "name": "The Salon Professional Academy-Grand Junction"
  },
  {
    "id": "126182",
    "name": "Adams State University"
  },
  {
    "id": "126207",
    "name": "Aims Community College"
  },
  {
    "id": "126289",
    "name": "Arapahoe Community College"
  },
  {
    "id": "126359",
    "name": "Bel-Rea Institute of Animal Technology"
  },
  {
    "id": "126562",
    "name": "University of Colorado Denver/Anschutz Medical Campus"
  },
  {
    "id": "126580",
    "name": "University of Colorado Colorado Springs"
  },
  {
    "id": "126605",
    "name": "Spartan College of Aeronautics and Technology"
  },
  {
    "id": "126669",
    "name": "Colorado Christian University"
  },
  {
    "id": "126687",
    "name": "Concorde Career College-Aurora"
  },
  {
    "id": "126711",
    "name": "Colorado Mountain College"
  },
  {
    "id": "126748",
    "name": "Colorado Northwestern Community College"
  },
  {
    "id": "126784",
    "name": "Colorado School of Trades"
  },
  {
    "id": "126818",
    "name": "Colorado State University-Fort Collins"
  },
  {
    "id": "126827",
    "name": "Colorado Technical University-Colorado Springs"
  },
  {
    "id": "126863",
    "name": "Community College of Aurora"
  },
  {
    "id": "126915",
    "name": "Technical College of the Rockies"
  },
  {
    "id": "126942",
    "name": "Community College of Denver"
  },
  {
    "id": "126951",
    "name": "Lincoln College of Technology-Denver"
  },
  {
    "id": "126979",
    "name": "Denver Seminary"
  },
  {
    "id": "127158",
    "name": "Emily Griffith Technical College"
  },
  {
    "id": "127185",
    "name": "Fort Lewis College"
  },
  {
    "id": "127200",
    "name": "Front Range Community College"
  },
  {
    "id": "127273",
    "name": "Iliff School of Theology"
  },
  {
    "id": "127316",
    "name": "International Salon and Spa Academy"
  },
  {
    "id": "127389",
    "name": "Lamar Community College"
  },
  {
    "id": "127556",
    "name": "Colorado Mesa University"
  },
  {
    "id": "127565",
    "name": "Metropolitan State University of Denver"
  },
  {
    "id": "127617",
    "name": "Morgan Community College"
  },
  {
    "id": "127653",
    "name": "Naropa University"
  },
  {
    "id": "127714",
    "name": "Nazarene Bible College"
  },
  {
    "id": "127732",
    "name": "Northeastern Junior College"
  },
  {
    "id": "127741",
    "name": "University of Northern Colorado"
  },
  {
    "id": "127778",
    "name": "Otero College"
  },
  {
    "id": "127820",
    "name": "Pikes Peak State College"
  },
  {
    "id": "127884",
    "name": "Pueblo Community College"
  },
  {
    "id": "127909",
    "name": "Red Rocks Community College"
  },
  {
    "id": "127918",
    "name": "Regis University"
  },
  {
    "id": "127945",
    "name": "Rocky Mountain College of Art and Design"
  },
  {
    "id": "127954",
    "name": "Montessori Education Center of the Rockies"
  },
  {
    "id": "128106",
    "name": "Colorado State University Pueblo"
  },
  {
    "id": "128151",
    "name": "Pickens Technical College"
  },
  {
    "id": "128179",
    "name": "Intellitec College-Colorado Springs"
  },
  {
    "id": "128188",
    "name": "Intellitec College-Grand Junction"
  },
  {
    "id": "128258",
    "name": "Trinidad State College"
  },
  {
    "id": "128337",
    "name": "Empire Beauty School-Thornton"
  },
  {
    "id": "128391",
    "name": "Western Colorado University"
  },
  {
    "id": "128498",
    "name": "Albertus Magnus College"
  },
  {
    "id": "128577",
    "name": "Asnuntuck Community College"
  },
  {
    "id": "128586",
    "name": "Bais Binyomin Academy"
  },
  {
    "id": "128744",
    "name": "University of Bridgeport"
  },
  {
    "id": "128771",
    "name": "Central Connecticut State University"
  },
  {
    "id": "128780",
    "name": "Charter Oak State College"
  },
  {
    "id": "128896",
    "name": "Cortiva Institute"
  },
  {
    "id": "128902",
    "name": "Connecticut College"
  },
  {
    "id": "129002",
    "name": "Porter & Chester Institute of Hamden"
  },
  {
    "id": "129154",
    "name": "Goodwin University"
  },
  {
    "id": "129181",
    "name": "Lincoln Technical Institute-East Windsor"
  },
  {
    "id": "129215",
    "name": "Eastern Connecticut State University"
  },
  {
    "id": "129349",
    "name": "Paul Mitchell the School-North Haven"
  },
  {
    "id": "129367",
    "name": "Capital Community College"
  },
  {
    "id": "129428",
    "name": "Rensselaer at Hartford"
  },
  {
    "id": "129482",
    "name": "American Institute-West Hartford"
  },
  {
    "id": "129491",
    "name": "Hartford International University for Religion and Peace"
  },
  {
    "id": "129525",
    "name": "University of Hartford"
  },
  {
    "id": "129534",
    "name": "Holy Apostles College and Seminary"
  },
  {
    "id": "129543",
    "name": "Housatonic Community College"
  },
  {
    "id": "129695",
    "name": "Manchester Community College"
  },
  {
    "id": "129729",
    "name": "Naugatuck Valley Community College"
  },
  {
    "id": "129756",
    "name": "Middlesex Community College"
  },
  {
    "id": "129774",
    "name": "Mitchell College"
  },
  {
    "id": "129808",
    "name": "Three Rivers Community College"
  },
  {
    "id": "129923",
    "name": "Lincoln Technical Institute-New Britain"
  },
  {
    "id": "129932",
    "name": "New England Tractor Trailer Training School of Connecticut"
  },
  {
    "id": "129941",
    "name": "University of New Haven"
  },
  {
    "id": "130004",
    "name": "Norwalk Community College"
  },
  {
    "id": "130040",
    "name": "Northwestern Connecticut Community College"
  },
  {
    "id": "130110",
    "name": "Paier College"
  },
  {
    "id": "130174",
    "name": "Porter & Chester Institute"
  },
  {
    "id": "130183",
    "name": "Post University"
  },
  {
    "id": "130217",
    "name": "Quinebaug Valley Community College"
  },
  {
    "id": "130226",
    "name": "Quinnipiac University"
  },
  {
    "id": "130253",
    "name": "Sacred Heart University"
  },
  {
    "id": "130314",
    "name": "University of Saint Joseph"
  },
  {
    "id": "130396",
    "name": "Gateway Community College"
  },
  {
    "id": "130493",
    "name": "Southern Connecticut State University"
  },
  {
    "id": "130509",
    "name": "Stone Academy-West Haven"
  },
  {
    "id": "130590",
    "name": "Trinity College"
  },
  {
    "id": "130606",
    "name": "Tunxis Community College"
  },
  {
    "id": "130624",
    "name": "United States Coast Guard Academy"
  },
  {
    "id": "130776",
    "name": "Western Connecticut State University"
  },
  {
    "id": "130837",
    "name": "Margaret H Rollins School of Nursing at Beebe Medical Center"
  },
  {
    "id": "130873",
    "name": "Dawn Career Institute LLC"
  },
  {
    "id": "130907",
    "name": "Delaware Technical Community College-Terry"
  },
  {
    "id": "130934",
    "name": "Delaware State University"
  },
  {
    "id": "130989",
    "name": "Goldey-Beacom College"
  },
  {
    "id": "131061",
    "name": "Schilling-Douglas School of Hair Design LLC"
  },
  {
    "id": "131113",
    "name": "Wilmington University"
  },
  {
    "id": "131283",
    "name": "The Catholic University of America"
  },
  {
    "id": "131399",
    "name": "University of the District of Columbia"
  },
  {
    "id": "131405",
    "name": "Pontifical Faculty of the Immaculate Conception at the Dominican House of Studies"
  },
  {
    "id": "131803",
    "name": "Strayer University-District of Columbia"
  },
  {
    "id": "131830",
    "name": "National Conservatory of Dramatic Arts"
  },
  {
    "id": "131876",
    "name": "Trinity Washington University"
  },
  {
    "id": "131973",
    "name": "Wesley Theological Seminary"
  },
  {
    "id": "132374",
    "name": "Atlantic Technical College"
  },
  {
    "id": "132408",
    "name": "The Baptist College of Florida"
  },
  {
    "id": "132471",
    "name": "Barry University"
  },
  {
    "id": "132602",
    "name": "Bethune-Cookman University"
  },
  {
    "id": "132657",
    "name": "Lynn University"
  },
  {
    "id": "132675",
    "name": "North Florida Technical College"
  },
  {
    "id": "132693",
    "name": "Eastern Florida State College"
  },
  {
    "id": "132709",
    "name": "Broward College"
  },
  {
    "id": "132842",
    "name": "Albizu University-Miami"
  },
  {
    "id": "132851",
    "name": "College of Central Florida"
  },
  {
    "id": "132879",
    "name": "Johnson University Florida"
  },
  {
    "id": "132976",
    "name": "Charlotte Technical College"
  },
  {
    "id": "133021",
    "name": "Chipola College"
  },
  {
    "id": "133155",
    "name": "Lorenzo Walker Technical College"
  },
  {
    "id": "133386",
    "name": "Daytona State College"
  },
  {
    "id": "133465",
    "name": "South University-West Palm Beach"
  },
  {
    "id": "133492",
    "name": "Eckerd College"
  },
  {
    "id": "133508",
    "name": "Florida SouthWestern State College"
  },
  {
    "id": "133526",
    "name": "Edward Waters University"
  },
  {
    "id": "133553",
    "name": "Embry-Riddle Aeronautical University-Daytona Beach"
  },
  {
    "id": "133650",
    "name": "Florida Agricultural and Mechanical University"
  },
  {
    "id": "133669",
    "name": "Florida Atlantic University"
  },
  {
    "id": "133702",
    "name": "Florida State College at Jacksonville"
  },
  {
    "id": "133711",
    "name": "Flagler College"
  },
  {
    "id": "133809",
    "name": "Florida College"
  },
  {
    "id": "133845",
    "name": "Concorde Career Institute-Jacksonville"
  },
  {
    "id": "133854",
    "name": "Concorde Career Institute-Miramar"
  },
  {
    "id": "133863",
    "name": "Concorde Career Institute-Tampa"
  },
  {
    "id": "133872",
    "name": "AdventHealth University"
  },
  {
    "id": "133881",
    "name": "Florida Institute of Technology"
  },
  {
    "id": "133942",
    "name": "Florida Institute of Ultrasound Inc"
  },
  {
    "id": "133951",
    "name": "Florida International University"
  },
  {
    "id": "133960",
    "name": "The College of the Florida Keys"
  },
  {
    "id": "133979",
    "name": "Florida Memorial University"
  },
  {
    "id": "133997",
    "name": "Florida Career College-Miami"
  },
  {
    "id": "134024",
    "name": "Florida School of Massage"
  },
  {
    "id": "134079",
    "name": "Florida Southern College"
  },
  {
    "id": "134228",
    "name": "Fort Pierce Beauty Academy"
  },
  {
    "id": "134237",
    "name": "Full Sail University"
  },
  {
    "id": "134291",
    "name": "George Stone Technical College"
  },
  {
    "id": "134307",
    "name": "George T Baker Aviation Technical College"
  },
  {
    "id": "134343",
    "name": "Gulf Coast State College"
  },
  {
    "id": "134422",
    "name": "Key College"
  },
  {
    "id": "134495",
    "name": "Hillsborough Community College"
  },
  {
    "id": "134510",
    "name": "Hobe Sound Bible College"
  },
  {
    "id": "134529",
    "name": "Hollywood Institute of Beauty Careers"
  },
  {
    "id": "134574",
    "name": "Cortiva Institute"
  },
  {
    "id": "134608",
    "name": "Indian River State College"
  },
  {
    "id": "134714",
    "name": "International Academy"
  },
  {
    "id": "134811",
    "name": "AI Miami International University of Art and Design"
  },
  {
    "id": "134945",
    "name": "Jacksonville University"
  },
  {
    "id": "135081",
    "name": "Keiser University-Ft Lauderdale"
  },
  {
    "id": "135142",
    "name": "La Belle Beauty School"
  },
  {
    "id": "135160",
    "name": "Florida Gateway College"
  },
  {
    "id": "135179",
    "name": "Lake Technical College"
  },
  {
    "id": "135188",
    "name": "Lake-Sumter State College"
  },
  {
    "id": "135267",
    "name": "Fort Myers Technical College"
  },
  {
    "id": "135276",
    "name": "Lively Technical College"
  },
  {
    "id": "135294",
    "name": "Lindsey Hopkins Technical College"
  },
  {
    "id": "135337",
    "name": "Loraines Academy & Spa"
  },
  {
    "id": "135364",
    "name": "Luther Rice College & Seminary"
  },
  {
    "id": "135391",
    "name": "State College of Florida-Manatee-Sarasota"
  },
  {
    "id": "135407",
    "name": "Manatee Technical College"
  },
  {
    "id": "135522",
    "name": "Traviss Technical College"
  },
  {
    "id": "135559",
    "name": "Merryfield School of Pet Grooming"
  },
  {
    "id": "135610",
    "name": "Trinity International University-Florida"
  },
  {
    "id": "135647",
    "name": "Miami Lakes Educational Center and Technical College"
  },
  {
    "id": "135717",
    "name": "Miami Dade College"
  },
  {
    "id": "135735",
    "name": "Orange Technical College-Mid Florida Campus"
  },
  {
    "id": "136109",
    "name": "Paul Mitchell the School-Jacksonville"
  },
  {
    "id": "136145",
    "name": "North Florida College"
  },
  {
    "id": "136172",
    "name": "University of North Florida"
  },
  {
    "id": "136215",
    "name": "Nova Southeastern University"
  },
  {
    "id": "136233",
    "name": "Northwest Florida State College"
  },
  {
    "id": "136303",
    "name": "Orange Technical College"
  },
  {
    "id": "136330",
    "name": "Palm Beach Atlantic University"
  },
  {
    "id": "136358",
    "name": "Palm Beach State College"
  },
  {
    "id": "136400",
    "name": "Pasco-Hernando State College"
  },
  {
    "id": "136473",
    "name": "Pensacola State College"
  },
  {
    "id": "136491",
    "name": "Pinellas Technical College-Clearwater"
  },
  {
    "id": "136516",
    "name": "Polk State College"
  },
  {
    "id": "136659",
    "name": "Radford M Locklin Technical College"
  },
  {
    "id": "136701",
    "name": "Saint Vincent de Paul Regional Seminary"
  },
  {
    "id": "136765",
    "name": "Ridge Technical College"
  },
  {
    "id": "136774",
    "name": "Ringling College of Art and Design"
  },
  {
    "id": "136826",
    "name": "Robert Morgan Educational Center and Technical College"
  },
  {
    "id": "136950",
    "name": "Rollins College"
  },
  {
    "id": "137023",
    "name": "First Coast Technical College"
  },
  {
    "id": "137032",
    "name": "Saint Leo University"
  },
  {
    "id": "137078",
    "name": "St Petersburg College"
  },
  {
    "id": "137087",
    "name": "Pinellas Technical College-St. Petersburg"
  },
  {
    "id": "137096",
    "name": "Santa Fe College"
  },
  {
    "id": "137120",
    "name": "Suncoast Technical College"
  },
  {
    "id": "137209",
    "name": "Seminole State College of Florida"
  },
  {
    "id": "137245",
    "name": "Sheridan Technical College"
  },
  {
    "id": "137272",
    "name": "St. John Vianney College Seminary"
  },
  {
    "id": "137281",
    "name": "Saint Johns River State College"
  },
  {
    "id": "137315",
    "name": "South Florida State College"
  },
  {
    "id": "137476",
    "name": "St. Thomas University"
  },
  {
    "id": "137546",
    "name": "Stetson University"
  },
  {
    "id": "137564",
    "name": "Southeastern University"
  },
  {
    "id": "137591",
    "name": "Bene's Career Academy"
  },
  {
    "id": "137713",
    "name": "Riveroak Technical College"
  },
  {
    "id": "137759",
    "name": "Tallahassee Community College"
  },
  {
    "id": "137777",
    "name": "Talmudic College of Florida"
  },
  {
    "id": "137801",
    "name": "Altierus Career College-Tampa"
  },
  {
    "id": "137847",
    "name": "The University of Tampa"
  },
  {
    "id": "137856",
    "name": "Big Bend Technical College"
  },
  {
    "id": "137865",
    "name": "Tom P. Haney Technical College"
  },
  {
    "id": "137953",
    "name": "Trinity Baptist College"
  },
  {
    "id": "137962",
    "name": "Trinity College of Florida"
  },
  {
    "id": "138187",
    "name": "Valencia College"
  },
  {
    "id": "138275",
    "name": "Warner University"
  },
  {
    "id": "138284",
    "name": "Florida Panhandle Technical College"
  },
  {
    "id": "138293",
    "name": "Webber International University"
  },
  {
    "id": "138309",
    "name": "Rasmussen University-Florida"
  },
  {
    "id": "138354",
    "name": "The University of West Florida"
  },
  {
    "id": "138372",
    "name": "Orange Technical College-Westside Campus"
  },
  {
    "id": "138479",
    "name": "William T McFatter Technical College"
  },
  {
    "id": "138488",
    "name": "Orange Technical College-East Campus"
  },
  {
    "id": "138497",
    "name": "Withlacoochee Technical College"
  },
  {
    "id": "138558",
    "name": "Abraham Baldwin Agricultural College"
  },
  {
    "id": "138600",
    "name": "Agnes Scott College"
  },
  {
    "id": "138655",
    "name": "Interactive College of Technology-Chamblee"
  },
  {
    "id": "138664",
    "name": "Interactive College of Technology-Morrow"
  },
  {
    "id": "138682",
    "name": "Albany Technical College"
  },
  {
    "id": "138716",
    "name": "Albany State University"
  },
  {
    "id": "138761",
    "name": "Andrew College"
  },
  {
    "id": "138813",
    "name": "The Art Institute of Atlanta"
  },
  {
    "id": "138822",
    "name": "Georgia Career Institute"
  },
  {
    "id": "138840",
    "name": "Atlanta Technical College"
  },
  {
    "id": "138868",
    "name": "Point University"
  },
  {
    "id": "138901",
    "name": "Atlanta Metropolitan State College"
  },
  {
    "id": "138929",
    "name": "Atlanta's John Marshall Law School"
  },
  {
    "id": "138938",
    "name": "Atlanta School of Massage"
  },
  {
    "id": "138947",
    "name": "Clark Atlanta University"
  },
  {
    "id": "138956",
    "name": "Augusta Technical College"
  },
  {
    "id": "139144",
    "name": "Berry College"
  },
  {
    "id": "139153",
    "name": "Beulah Heights University"
  },
  {
    "id": "139199",
    "name": "Brenau University"
  },
  {
    "id": "139205",
    "name": "Brewton-Parker College"
  },
  {
    "id": "139250",
    "name": "College of Coastal Georgia"
  },
  {
    "id": "139278",
    "name": "West Georgia Technical College"
  },
  {
    "id": "139311",
    "name": "Clayton State University"
  },
  {
    "id": "139348",
    "name": "Columbia Theological Seminary"
  },
  {
    "id": "139357",
    "name": "Columbus Technical College"
  },
  {
    "id": "139366",
    "name": "Columbus State University"
  },
  {
    "id": "139384",
    "name": "Georgia Northwestern Technical College"
  },
  {
    "id": "139393",
    "name": "Covenant College"
  },
  {
    "id": "139463",
    "name": "Dalton State College"
  },
  {
    "id": "139506",
    "name": "Pro Way Hair School"
  },
  {
    "id": "139579",
    "name": "South University-Savannah"
  },
  {
    "id": "139621",
    "name": "East Georgia State College"
  },
  {
    "id": "139630",
    "name": "Emmanuel College"
  },
  {
    "id": "139700",
    "name": "Georgia Highlands College"
  },
  {
    "id": "139719",
    "name": "Fort Valley State University"
  },
  {
    "id": "139746",
    "name": "Grady Health System Professional Schools"
  },
  {
    "id": "139764",
    "name": "Georgia Southwestern State University"
  },
  {
    "id": "139861",
    "name": "Georgia College & State University"
  },
  {
    "id": "139931",
    "name": "Georgia Southern University"
  },
  {
    "id": "139940",
    "name": "Georgia State University"
  },
  {
    "id": "139968",
    "name": "Gordon State College"
  },
  {
    "id": "139986",
    "name": "Southern Crescent Technical College"
  },
  {
    "id": "139995",
    "name": "Gupton Jones College of Funeral Service"
  },
  {
    "id": "140003",
    "name": "Gwinnett College-Lilburn"
  },
  {
    "id": "140012",
    "name": "Gwinnett Technical College"
  },
  {
    "id": "140146",
    "name": "Interdenominational Theological Center"
  },
  {
    "id": "140234",
    "name": "LaGrange College"
  },
  {
    "id": "140243",
    "name": "Lanier Technical College"
  },
  {
    "id": "140252",
    "name": "Life University"
  },
  {
    "id": "140331",
    "name": "Chattahoochee Technical College"
  },
  {
    "id": "140340",
    "name": "Herzing University-Atlanta"
  },
  {
    "id": "140447",
    "name": "Mercer University"
  },
  {
    "id": "140553",
    "name": "Morehouse College"
  },
  {
    "id": "140562",
    "name": "Morehouse School of Medicine"
  },
  {
    "id": "140678",
    "name": "North Georgia Technical College"
  },
  {
    "id": "140696",
    "name": "Oglethorpe University"
  },
  {
    "id": "140720",
    "name": "Paine College"
  },
  {
    "id": "140818",
    "name": "Piedmont University"
  },
  {
    "id": "140827",
    "name": "Miami Ad School-Atlanta"
  },
  {
    "id": "140872",
    "name": "Reinhardt University"
  },
  {
    "id": "140942",
    "name": "Savannah Technical College"
  },
  {
    "id": "140951",
    "name": "Savannah College of Art and Design"
  },
  {
    "id": "140960",
    "name": "Savannah State University"
  },
  {
    "id": "140988",
    "name": "Shorter University"
  },
  {
    "id": "141006",
    "name": "South Georgia Technical College"
  },
  {
    "id": "141015",
    "name": "The Creative Circus"
  },
  {
    "id": "141060",
    "name": "Spelman College"
  },
  {
    "id": "141167",
    "name": "Thomas University"
  },
  {
    "id": "141185",
    "name": "Toccoa Falls College"
  },
  {
    "id": "141237",
    "name": "Truett McConnell University"
  },
  {
    "id": "141255",
    "name": "Wiregrass Georgia Technical College"
  },
  {
    "id": "141264",
    "name": "Valdosta State University"
  },
  {
    "id": "141325",
    "name": "Wesleyan College"
  },
  {
    "id": "141334",
    "name": "University of West Georgia"
  },
  {
    "id": "141361",
    "name": "Young Harris College"
  },
  {
    "id": "141486",
    "name": "Chaminade University of Honolulu"
  },
  {
    "id": "141565",
    "name": "University of Hawaii at Hilo"
  },
  {
    "id": "141574",
    "name": "University of Hawaii at Manoa"
  },
  {
    "id": "141608",
    "name": "Hawaii Institute of Hair Design"
  },
  {
    "id": "141644",
    "name": "Hawaii Pacific University"
  },
  {
    "id": "141680",
    "name": "Honolulu Community College"
  },
  {
    "id": "141796",
    "name": "Kapiolani Community College"
  },
  {
    "id": "141802",
    "name": "Kauai Community College"
  },
  {
    "id": "141811",
    "name": "Leeward Community College"
  },
  {
    "id": "141839",
    "name": "University of Hawaii Maui College"
  },
  {
    "id": "141981",
    "name": "University of Hawaii-West Oahu"
  },
  {
    "id": "141990",
    "name": "Windward Community College"
  },
  {
    "id": "142054",
    "name": "Carrington College-Boise"
  },
  {
    "id": "142090",
    "name": "Boise Bible College"
  },
  {
    "id": "142115",
    "name": "Boise State University"
  },
  {
    "id": "142179",
    "name": "College of Eastern Idaho"
  },
  {
    "id": "142276",
    "name": "Idaho State University"
  },
  {
    "id": "142285",
    "name": "University of Idaho"
  },
  {
    "id": "142294",
    "name": "The College of Idaho"
  },
  {
    "id": "142328",
    "name": "Lewis-Clark State College"
  },
  {
    "id": "142407",
    "name": "Aveda Institute-Twin Falls"
  },
  {
    "id": "142416",
    "name": "Mr Leon's School of Hair Design-Moscow"
  },
  {
    "id": "142443",
    "name": "North Idaho College"
  },
  {
    "id": "142461",
    "name": "Northwest Nazarene University"
  },
  {
    "id": "142489",
    "name": "Nathan Layne Institute of Cosmetology"
  },
  {
    "id": "142522",
    "name": "Brigham Young University-Idaho"
  },
  {
    "id": "142559",
    "name": "College of Southern Idaho"
  },
  {
    "id": "142832",
    "name": "Adler University"
  },
  {
    "id": "142887",
    "name": "American Academy of Art College"
  },
  {
    "id": "142957",
    "name": "American Islamic College"
  },
  {
    "id": "143048",
    "name": "School of the Art Institute of Chicago"
  },
  {
    "id": "143084",
    "name": "Augustana College"
  },
  {
    "id": "143118",
    "name": "Aurora University"
  },
  {
    "id": "143215",
    "name": "Southwestern Illinois College"
  },
  {
    "id": "143233",
    "name": "Bethany Theological Seminary"
  },
  {
    "id": "143279",
    "name": "Black Hawk College"
  },
  {
    "id": "143288",
    "name": "Blackburn College"
  },
  {
    "id": "143297",
    "name": "Blessing Rieman College of Nursing and Health Sciences"
  },
  {
    "id": "143358",
    "name": "Bradley University"
  },
  {
    "id": "143376",
    "name": "Paul Mitchell The School Tinley Park"
  },
  {
    "id": "143464",
    "name": "Cameo Beauty Academy"
  },
  {
    "id": "143473",
    "name": "Cannella School of Hair Design-Villa Park"
  },
  {
    "id": "143482",
    "name": "Cannella School of Hair Design-Chicago"
  },
  {
    "id": "143491",
    "name": "Cannella School of Hair Design-Chicago"
  },
  {
    "id": "143552",
    "name": "Capri Beauty College"
  },
  {
    "id": "143613",
    "name": "Carl Sandburg College"
  },
  {
    "id": "143659",
    "name": "Catholic Theological Union at Chicago"
  },
  {
    "id": "143853",
    "name": "Midwestern University-Downers Grove"
  },
  {
    "id": "143978",
    "name": "The Chicago School of Professional Psychology at Chicago"
  },
  {
    "id": "144005",
    "name": "Chicago State University"
  },
  {
    "id": "144014",
    "name": "Chicago Theological Seminary"
  },
  {
    "id": "144157",
    "name": "City Colleges of Chicago-Kennedy-King College"
  },
  {
    "id": "144166",
    "name": "City Colleges of Chicago-Malcolm X College"
  },
  {
    "id": "144175",
    "name": "City Colleges of Chicago-Olive-Harvey College"
  },
  {
    "id": "144184",
    "name": "City Colleges of Chicago-Harry S Truman College"
  },
  {
    "id": "144193",
    "name": "City Colleges of Chicago-Richard J Daley College"
  },
  {
    "id": "144209",
    "name": "City Colleges of Chicago-Harold Washington College"
  },
  {
    "id": "144218",
    "name": "City Colleges of Chicago-Wilbur Wright College"
  },
  {
    "id": "144281",
    "name": "Columbia College Chicago"
  },
  {
    "id": "144351",
    "name": "Concordia University-Chicago"
  },
  {
    "id": "144564",
    "name": "Danville Area Community College"
  },
  {
    "id": "144573",
    "name": "Tricoci University of Beauty Culture"
  },
  {
    "id": "144795",
    "name": "Cosmetology & Spa Academy"
  },
  {
    "id": "144865",
    "name": "College of DuPage"
  },
  {
    "id": "144883",
    "name": "East-West University"
  },
  {
    "id": "144892",
    "name": "Eastern Illinois University"
  },
  {
    "id": "144944",
    "name": "Elgin Community College"
  },
  {
    "id": "144962",
    "name": "Elmhurst University"
  },
  {
    "id": "144971",
    "name": "Eureka College"
  },
  {
    "id": "145099",
    "name": "First Institute of Travel Inc."
  },
  {
    "id": "145239",
    "name": "Fox College"
  },
  {
    "id": "145275",
    "name": "Garrett-Evangelical Theological Seminary"
  },
  {
    "id": "145309",
    "name": "Gem City College"
  },
  {
    "id": "145336",
    "name": "Governors State University"
  },
  {
    "id": "145354",
    "name": "Graham Hospital School of Nursing"
  },
  {
    "id": "145372",
    "name": "Greenville University"
  },
  {
    "id": "145406",
    "name": "G Skin & Beauty Institute"
  },
  {
    "id": "145424",
    "name": "Hair Professionals Career College"
  },
  {
    "id": "145433",
    "name": "Hair Professionals School of Cosmetology"
  },
  {
    "id": "145497",
    "name": "Hebrew Theological College"
  },
  {
    "id": "145521",
    "name": "Highland Community College"
  },
  {
    "id": "145558",
    "name": "Rosalind Franklin University of Medicine and Science"
  },
  {
    "id": "145619",
    "name": "Benedictine University"
  },
  {
    "id": "145628",
    "name": "Illinois College of Optometry"
  },
  {
    "id": "145646",
    "name": "Illinois Wesleyan University"
  },
  {
    "id": "145682",
    "name": "Illinois Central College"
  },
  {
    "id": "145691",
    "name": "Illinois College"
  },
  {
    "id": "145707",
    "name": "Olney Central College"
  },
  {
    "id": "145813",
    "name": "Illinois State University"
  },
  {
    "id": "145831",
    "name": "Illinois Valley Community College"
  },
  {
    "id": "145886",
    "name": "Institute for Clinical Social Work"
  },
  {
    "id": "146205",
    "name": "John A Logan College"
  },
  {
    "id": "146278",
    "name": "John Wood Community College"
  },
  {
    "id": "146296",
    "name": "Joliet Junior College"
  },
  {
    "id": "146339",
    "name": "Judson University"
  },
  {
    "id": "146348",
    "name": "Kankakee Community College"
  },
  {
    "id": "146366",
    "name": "Kaskaskia College"
  },
  {
    "id": "146418",
    "name": "Kishwaukee College"
  },
  {
    "id": "146427",
    "name": "Knox College"
  },
  {
    "id": "146454",
    "name": "Educators of Beauty College of Cosmetology-Peru"
  },
  {
    "id": "146472",
    "name": "College of Lake County"
  },
  {
    "id": "146481",
    "name": "Lake Forest College"
  },
  {
    "id": "146490",
    "name": "Lake Forest Graduate School of Management"
  },
  {
    "id": "146506",
    "name": "Lake Land College"
  },
  {
    "id": "146533",
    "name": "Lakeview College of Nursing"
  },
  {
    "id": "146603",
    "name": "Lewis and Clark Community College"
  },
  {
    "id": "146612",
    "name": "Lewis University"
  },
  {
    "id": "146667",
    "name": "Lincoln Christian University"
  },
  {
    "id": "146676",
    "name": "Lincoln College"
  },
  {
    "id": "146685",
    "name": "Lincoln Land Community College"
  },
  {
    "id": "146700",
    "name": "Lincoln College of Technology-Melrose Park"
  },
  {
    "id": "146728",
    "name": "Lutheran School of Theology at Chicago"
  },
  {
    "id": "146755",
    "name": "Trinity College of Nursing & Health Sciences"
  },
  {
    "id": "146807",
    "name": "Steven Papageorge Hair Academy"
  },
  {
    "id": "146816",
    "name": "Generations College"
  },
  {
    "id": "146977",
    "name": "McCormick Theological Seminary"
  },
  {
    "id": "147004",
    "name": "McHenry County College"
  },
  {
    "id": "147013",
    "name": "McKendree University"
  },
  {
    "id": "147031",
    "name": "Meadville Theological School of Lombard College"
  },
  {
    "id": "147129",
    "name": "Methodist College"
  },
  {
    "id": "147244",
    "name": "Millikin University"
  },
  {
    "id": "147341",
    "name": "Monmouth College"
  },
  {
    "id": "147369",
    "name": "Moody Bible Institute"
  },
  {
    "id": "147378",
    "name": "Moraine Valley Community College"
  },
  {
    "id": "147396",
    "name": "Morrison Institute of Technology"
  },
  {
    "id": "147411",
    "name": "Morton College"
  },
  {
    "id": "147536",
    "name": "National Louis University"
  },
  {
    "id": "147590",
    "name": "National University of Health Sciences"
  },
  {
    "id": "147642",
    "name": "Cosmetology Concepts Niles"
  },
  {
    "id": "147660",
    "name": "North Central College"
  },
  {
    "id": "147679",
    "name": "North Park University"
  },
  {
    "id": "147697",
    "name": "Northern Baptist Theological Seminary"
  },
  {
    "id": "147703",
    "name": "Northern Illinois University"
  },
  {
    "id": "147776",
    "name": "Northeastern Illinois University"
  },
  {
    "id": "147800",
    "name": "Oakton Community College"
  },
  {
    "id": "147819",
    "name": "Oehrlein School of Cosmetology"
  },
  {
    "id": "147828",
    "name": "Olivet Nazarene University"
  },
  {
    "id": "147916",
    "name": "Parkland College"
  },
  {
    "id": "148007",
    "name": "Prairie State College"
  },
  {
    "id": "148016",
    "name": "Principia College"
  },
  {
    "id": "148098",
    "name": "Professional's Choice Hair Design Academy"
  },
  {
    "id": "148131",
    "name": "Quincy University"
  },
  {
    "id": "148256",
    "name": "Rend Lake College"
  },
  {
    "id": "148292",
    "name": "Richland Community College"
  },
  {
    "id": "148380",
    "name": "Rock Valley College"
  },
  {
    "id": "148405",
    "name": "Rockford University"
  },
  {
    "id": "148487",
    "name": "Roosevelt University"
  },
  {
    "id": "148496",
    "name": "Dominican University"
  },
  {
    "id": "148511",
    "name": "Rush University"
  },
  {
    "id": "148575",
    "name": "Saint Francis Medical Center College of Nursing"
  },
  {
    "id": "148584",
    "name": "University of St Francis"
  },
  {
    "id": "148593",
    "name": "St. John's College-Department of Nursing"
  },
  {
    "id": "148627",
    "name": "Saint Xavier University"
  },
  {
    "id": "148654",
    "name": "University of Illinois Springfield"
  },
  {
    "id": "148672",
    "name": "Sauk Valley Community College"
  },
  {
    "id": "148821",
    "name": "Shawnee Community College"
  },
  {
    "id": "148876",
    "name": "St. Augustine College"
  },
  {
    "id": "148885",
    "name": "University of Saint Mary of the Lake"
  },
  {
    "id": "148937",
    "name": "Southeastern Illinois College"
  },
  {
    "id": "148955",
    "name": "National Latino Education Institute"
  },
  {
    "id": "148982",
    "name": "Spertus College"
  },
  {
    "id": "148991",
    "name": "Spoon River College"
  },
  {
    "id": "149028",
    "name": "Saint Anthony College of Nursing"
  },
  {
    "id": "149204",
    "name": "Educators of Beauty College of Cosmetology-Sterling"
  },
  {
    "id": "149222",
    "name": "Southern Illinois University-Carbondale"
  },
  {
    "id": "149231",
    "name": "Southern Illinois University-Edwardsville"
  },
  {
    "id": "149310",
    "name": "Taylor Business Institute"
  },
  {
    "id": "149329",
    "name": "Telshe Yeshiva-Chicago"
  },
  {
    "id": "149365",
    "name": "South Suburban College"
  },
  {
    "id": "149499",
    "name": "Tri-County Beauty Academy"
  },
  {
    "id": "149505",
    "name": "Trinity Christian College"
  },
  {
    "id": "149514",
    "name": "Trinity International University-Illinois"
  },
  {
    "id": "149532",
    "name": "Triton College"
  },
  {
    "id": "149550",
    "name": "University of Spa & Cosmetology Arts"
  },
  {
    "id": "149639",
    "name": "VanderCook College of Music"
  },
  {
    "id": "149727",
    "name": "Waubonsee Community College"
  },
  {
    "id": "149763",
    "name": "Oak Point University"
  },
  {
    "id": "149772",
    "name": "Western Illinois University"
  },
  {
    "id": "149781",
    "name": "Wheaton College"
  },
  {
    "id": "149842",
    "name": "William Rainey Harper College"
  },
  {
    "id": "150066",
    "name": "Anderson University"
  },
  {
    "id": "150136",
    "name": "Ball State University"
  },
  {
    "id": "150145",
    "name": "Bethel University"
  },
  {
    "id": "150163",
    "name": "Butler University"
  },
  {
    "id": "150172",
    "name": "Calumet College of Saint Joseph"
  },
  {
    "id": "150215",
    "name": "Christian Theological Seminary"
  },
  {
    "id": "150251",
    "name": "College of Court Reporting Inc"
  },
  {
    "id": "150288",
    "name": "Concordia Theological Seminary"
  },
  {
    "id": "150303",
    "name": "Tricoci University of Beauty Culture-Highland"
  },
  {
    "id": "150400",
    "name": "DePauw University"
  },
  {
    "id": "150428",
    "name": "Don Roberts School of Hair Design"
  },
  {
    "id": "150455",
    "name": "Earlham College"
  },
  {
    "id": "150534",
    "name": "University of Evansville"
  },
  {
    "id": "150604",
    "name": "Franklin College"
  },
  {
    "id": "150668",
    "name": "Goshen College"
  },
  {
    "id": "150677",
    "name": "Grace College and Theological Seminary"
  },
  {
    "id": "150756",
    "name": "Hanover College"
  },
  {
    "id": "150765",
    "name": "J Michael Harrold Beauty Academy"
  },
  {
    "id": "150774",
    "name": "Holy Cross College"
  },
  {
    "id": "150853",
    "name": "PJ's College of Cosmetology-Clarksville"
  },
  {
    "id": "150941",
    "name": "Huntington University"
  },
  {
    "id": "150987",
    "name": "Ivy Tech Community College"
  },
  {
    "id": "151102",
    "name": "Purdue University Fort Wayne"
  },
  {
    "id": "151111",
    "name": "Indiana University-Purdue University-Indianapolis"
  },
  {
    "id": "151263",
    "name": "University of Indianapolis"
  },
  {
    "id": "151290",
    "name": "Indiana Institute of Technology"
  },
  {
    "id": "151306",
    "name": "University of Southern Indiana"
  },
  {
    "id": "151324",
    "name": "Indiana State University"
  },
  {
    "id": "151333",
    "name": "Indiana University-Kokomo"
  },
  {
    "id": "151342",
    "name": "Indiana University-South Bend"
  },
  {
    "id": "151360",
    "name": "Indiana University-Northwest"
  },
  {
    "id": "151379",
    "name": "Indiana University-Southeast"
  },
  {
    "id": "151388",
    "name": "Indiana University-East"
  },
  {
    "id": "151467",
    "name": "International Business College-Indianapolis"
  },
  {
    "id": "151564",
    "name": "Taylor Institute of Cosmetology II"
  },
  {
    "id": "151607",
    "name": "Tricoci University of Beauty Culture-Lafayette"
  },
  {
    "id": "151661",
    "name": "Lincoln College of Technology-Indianapolis"
  },
  {
    "id": "151777",
    "name": "Manchester University"
  },
  {
    "id": "151786",
    "name": "Marian University"
  },
  {
    "id": "151801",
    "name": "Indiana Wesleyan University-Marion"
  },
  {
    "id": "151810",
    "name": "Martin University"
  },
  {
    "id": "151865",
    "name": "Anabaptist Mennonite Biblical Seminary"
  },
  {
    "id": "151962",
    "name": "Mid-America College of Funeral Service"
  },
  {
    "id": "152044",
    "name": "West Michigan College of Barbering and Beauty"
  },
  {
    "id": "152099",
    "name": "Oakland City University"
  },
  {
    "id": "152150",
    "name": "PJ's College of Cosmetology-Richmond"
  },
  {
    "id": "152275",
    "name": "Ravenscroft Beauty College"
  },
  {
    "id": "152309",
    "name": "Rogers Academy of Hair Design"
  },
  {
    "id": "152318",
    "name": "Rose-Hulman Institute of Technology"
  },
  {
    "id": "152336",
    "name": "University of Saint Francis-Fort Wayne"
  },
  {
    "id": "152381",
    "name": "Saint Mary-of-the-Woods College"
  },
  {
    "id": "152390",
    "name": "Saint Mary's College"
  },
  {
    "id": "152451",
    "name": "Saint Meinrad School of Theology"
  },
  {
    "id": "152497",
    "name": "Saint Elizabeth School of Nursing"
  },
  {
    "id": "152530",
    "name": "Taylor University"
  },
  {
    "id": "152567",
    "name": "Trine University"
  },
  {
    "id": "152600",
    "name": "Valparaiso University"
  },
  {
    "id": "152628",
    "name": "Vincennes Beauty College"
  },
  {
    "id": "152637",
    "name": "Vincennes University"
  },
  {
    "id": "152673",
    "name": "Wabash College"
  },
  {
    "id": "152798",
    "name": "Allen College"
  },
  {
    "id": "152992",
    "name": "Briar Cliff University"
  },
  {
    "id": "153001",
    "name": "Buena Vista University"
  },
  {
    "id": "153074",
    "name": "Capri College-Dubuque"
  },
  {
    "id": "153083",
    "name": "Capri College-Cedar Rapids"
  },
  {
    "id": "153108",
    "name": "Central College"
  },
  {
    "id": "153126",
    "name": "Clarke University"
  },
  {
    "id": "153144",
    "name": "Coe College"
  },
  {
    "id": "153162",
    "name": "Cornell College"
  },
  {
    "id": "153214",
    "name": "Des Moines Area Community College"
  },
  {
    "id": "153241",
    "name": "Divine Word College"
  },
  {
    "id": "153250",
    "name": "Dordt University"
  },
  {
    "id": "153278",
    "name": "University of Dubuque"
  },
  {
    "id": "153296",
    "name": "Ellsworth Community College"
  },
  {
    "id": "153302",
    "name": "Emmaus Bible College"
  },
  {
    "id": "153311",
    "name": "Eastern Iowa Community College District"
  },
  {
    "id": "153320",
    "name": "Faith Baptist Bible College and Theological Seminary"
  },
  {
    "id": "153366",
    "name": "Graceland University-Lamoni"
  },
  {
    "id": "153375",
    "name": "Grand View University"
  },
  {
    "id": "153427",
    "name": "Orion Technical College"
  },
  {
    "id": "153445",
    "name": "Hawkeye Community College"
  },
  {
    "id": "153463",
    "name": "American Hair Academy"
  },
  {
    "id": "153472",
    "name": "Indian Hills Community College"
  },
  {
    "id": "153524",
    "name": "Iowa Central Community College"
  },
  {
    "id": "153533",
    "name": "Iowa Lakes Community College"
  },
  {
    "id": "153542",
    "name": "UnityPoint Health-Des Moines School of Radiologic Technology"
  },
  {
    "id": "153597",
    "name": "Iowa School of Beauty-Des Moines"
  },
  {
    "id": "153621",
    "name": "Iowa Wesleyan University"
  },
  {
    "id": "153630",
    "name": "Iowa Western Community College"
  },
  {
    "id": "153737",
    "name": "Kirkwood Community College"
  },
  {
    "id": "153746",
    "name": "La James International College-Cedar Falls"
  },
  {
    "id": "153764",
    "name": "La James International College-Johnston"
  },
  {
    "id": "153773",
    "name": "La James College of Hairstyling and Cosmetology"
  },
  {
    "id": "153782",
    "name": "La James International College-Ft Dodge"
  },
  {
    "id": "153825",
    "name": "Loras College"
  },
  {
    "id": "153834",
    "name": "Luther College"
  },
  {
    "id": "153861",
    "name": "Maharishi International University"
  },
  {
    "id": "153922",
    "name": "Marshalltown Community College"
  },
  {
    "id": "153977",
    "name": "Mercy College of Health Sciences"
  },
  {
    "id": "154004",
    "name": "Morningside University"
  },
  {
    "id": "154013",
    "name": "Mount Mercy University"
  },
  {
    "id": "154022",
    "name": "Ashford University"
  },
  {
    "id": "154059",
    "name": "North Iowa Area Community College"
  },
  {
    "id": "154095",
    "name": "University of Northern Iowa"
  },
  {
    "id": "154101",
    "name": "Northwestern College"
  },
  {
    "id": "154110",
    "name": "Northeast Iowa Community College"
  },
  {
    "id": "154129",
    "name": "Northwest Iowa Community College"
  },
  {
    "id": "154156",
    "name": "Des Moines University-Osteopathic Medical Center"
  },
  {
    "id": "154165",
    "name": "Iowa School of Beauty-Ottumwa"
  },
  {
    "id": "154174",
    "name": "Palmer College of Chiropractic"
  },
  {
    "id": "154208",
    "name": "PCI Academy-Ames"
  },
  {
    "id": "154235",
    "name": "Saint Ambrose University"
  },
  {
    "id": "154262",
    "name": "St Luke's College"
  },
  {
    "id": "154332",
    "name": "La James International College-Davenport"
  },
  {
    "id": "154350",
    "name": "Simpson College"
  },
  {
    "id": "154378",
    "name": "Southeastern Community College"
  },
  {
    "id": "154396",
    "name": "Southwestern Community College"
  },
  {
    "id": "154448",
    "name": "Capri College-Davenport"
  },
  {
    "id": "154457",
    "name": "Iowa School of Beauty-Sioux City"
  },
  {
    "id": "154466",
    "name": "E Q School of Hair Design"
  },
  {
    "id": "154493",
    "name": "Upper Iowa University"
  },
  {
    "id": "154518",
    "name": "Waldorf University"
  },
  {
    "id": "154527",
    "name": "Wartburg College"
  },
  {
    "id": "154536",
    "name": "Wartburg Theological Seminary"
  },
  {
    "id": "154545",
    "name": "The Salon Professional Academy-Cedar Falls"
  },
  {
    "id": "154572",
    "name": "Western Iowa Tech Community College"
  },
  {
    "id": "154590",
    "name": "William Penn University"
  },
  {
    "id": "154642",
    "name": "Allen County Community College"
  },
  {
    "id": "154688",
    "name": "Baker University"
  },
  {
    "id": "154697",
    "name": "Barton County Community College"
  },
  {
    "id": "154712",
    "name": "Benedictine College"
  },
  {
    "id": "154721",
    "name": "Bethany College"
  },
  {
    "id": "154749",
    "name": "Bethel College-North Newton"
  },
  {
    "id": "154800",
    "name": "Butler Community College"
  },
  {
    "id": "154855",
    "name": "Central Christian College of Kansas"
  },
  {
    "id": "154907",
    "name": "Cloud County Community College"
  },
  {
    "id": "154925",
    "name": "Coffeyville Community College"
  },
  {
    "id": "154934",
    "name": "Colby Community College"
  },
  {
    "id": "154952",
    "name": "Cowley County Community College"
  },
  {
    "id": "154998",
    "name": "Dodge City Community College"
  },
  {
    "id": "155007",
    "name": "Donnelly College"
  },
  {
    "id": "155025",
    "name": "Emporia State University"
  },
  {
    "id": "155052",
    "name": "Flint Hills Technical College"
  },
  {
    "id": "155061",
    "name": "Fort Hays State University"
  },
  {
    "id": "155070",
    "name": "Barclay College"
  },
  {
    "id": "155089",
    "name": "Friends University"
  },
  {
    "id": "155098",
    "name": "Fort Scott Community College"
  },
  {
    "id": "155104",
    "name": "Garden City Community College"
  },
  {
    "id": "155140",
    "name": "Haskell Indian Nations University"
  },
  {
    "id": "155159",
    "name": "Hays Academy of Hair Design"
  },
  {
    "id": "155177",
    "name": "Hesston College"
  },
  {
    "id": "155186",
    "name": "Highland Community College"
  },
  {
    "id": "155195",
    "name": "Hutchinson Community College"
  },
  {
    "id": "155201",
    "name": "Independence Community College"
  },
  {
    "id": "155210",
    "name": "Johnson County Community College"
  },
  {
    "id": "155283",
    "name": "Concorde Career College-Kansas City"
  },
  {
    "id": "155292",
    "name": "Kansas City Kansas Community College"
  },
  {
    "id": "155308",
    "name": "Kansas Christian College"
  },
  {
    "id": "155335",
    "name": "Newman University"
  },
  {
    "id": "155353",
    "name": "Old Town Barber College-Wichita"
  },
  {
    "id": "155399",
    "name": "Kansas State University"
  },
  {
    "id": "155414",
    "name": "Kansas Wesleyan University"
  },
  {
    "id": "155423",
    "name": "Washburn Institute of Technology"
  },
  {
    "id": "155450",
    "name": "Labette Community College"
  },
  {
    "id": "155487",
    "name": "Manhattan Area Technical College"
  },
  {
    "id": "155496",
    "name": "Manhattan Christian College"
  },
  {
    "id": "155511",
    "name": "McPherson College"
  },
  {
    "id": "155520",
    "name": "MidAmerica Nazarene University"
  },
  {
    "id": "155566",
    "name": "Neosho County Community College"
  },
  {
    "id": "155593",
    "name": "North Central Kansas Technical College"
  },
  {
    "id": "155618",
    "name": "Northwest Kansas Technical College"
  },
  {
    "id": "155627",
    "name": "Ottawa University-Ottawa"
  },
  {
    "id": "155636",
    "name": "Ottawa University-Kansas City"
  },
  {
    "id": "155681",
    "name": "Pittsburg State University"
  },
  {
    "id": "155715",
    "name": "Pratt Community College"
  },
  {
    "id": "155812",
    "name": "University of Saint Mary"
  },
  {
    "id": "155830",
    "name": "Salina Area Technical College"
  },
  {
    "id": "155858",
    "name": "Seward County Community College"
  },
  {
    "id": "155900",
    "name": "Southwestern College"
  },
  {
    "id": "155937",
    "name": "Sterling College"
  },
  {
    "id": "155973",
    "name": "Tabor College"
  },
  {
    "id": "156082",
    "name": "Washburn University"
  },
  {
    "id": "156107",
    "name": "Wichita State University-Campus of Applied Sciences and Technology"
  },
  {
    "id": "156125",
    "name": "Wichita State University"
  },
  {
    "id": "156134",
    "name": "Wichita Technical Institute"
  },
  {
    "id": "156189",
    "name": "Alice Lloyd College"
  },
  {
    "id": "156213",
    "name": "Asbury University"
  },
  {
    "id": "156222",
    "name": "Asbury Theological Seminary"
  },
  {
    "id": "156231",
    "name": "Ashland Community and Technical College"
  },
  {
    "id": "156277",
    "name": "Barrett and Company School of Hair Design"
  },
  {
    "id": "156286",
    "name": "Bellarmine University"
  },
  {
    "id": "156310",
    "name": "PJ's College of Cosmetology-Bowling Green"
  },
  {
    "id": "156338",
    "name": "Southcentral Kentucky Community and Technical College"
  },
  {
    "id": "156356",
    "name": "Brescia University"
  },
  {
    "id": "156365",
    "name": "Campbellsville University"
  },
  {
    "id": "156392",
    "name": "Bluegrass Community and Technical College"
  },
  {
    "id": "156408",
    "name": "Centre College"
  },
  {
    "id": "156417",
    "name": "Clear Creek Baptist Bible College"
  },
  {
    "id": "156426",
    "name": "Paul Mitchell the School-Lexington"
  },
  {
    "id": "156471",
    "name": "Galen College of Nursing-Louisville"
  },
  {
    "id": "156541",
    "name": "University of the Cumberlands"
  },
  {
    "id": "156620",
    "name": "Eastern Kentucky University"
  },
  {
    "id": "156648",
    "name": "Elizabethtown Community and Technical College"
  },
  {
    "id": "156727",
    "name": "Frontier Nursing University"
  },
  {
    "id": "156745",
    "name": "Georgetown College"
  },
  {
    "id": "156754",
    "name": "PJ's College of Cosmetology-Glasgow"
  },
  {
    "id": "156790",
    "name": "Hazard Community and Technical College"
  },
  {
    "id": "156842",
    "name": "Paul Mitchell the School-Louisville"
  },
  {
    "id": "156851",
    "name": "Henderson Community College"
  },
  {
    "id": "156860",
    "name": "Hopkinsville Community College"
  },
  {
    "id": "156921",
    "name": "Jefferson Community and Technical College"
  },
  {
    "id": "156967",
    "name": "Summit Salon Academy-Lexington"
  },
  {
    "id": "157021",
    "name": "American National University-Pikeville"
  },
  {
    "id": "157030",
    "name": "Kentucky Mountain Bible College"
  },
  {
    "id": "157058",
    "name": "Kentucky State University"
  },
  {
    "id": "157076",
    "name": "Kentucky Wesleyan College"
  },
  {
    "id": "157100",
    "name": "Kentucky Christian University"
  },
  {
    "id": "157207",
    "name": "Lexington Theological Seminary"
  },
  {
    "id": "157216",
    "name": "Lindsey Wilson College"
  },
  {
    "id": "157289",
    "name": "University of Louisville"
  },
  {
    "id": "157298",
    "name": "Louisville Presbyterian Theological Seminary"
  },
  {
    "id": "157304",
    "name": "Madisonville Community College"
  },
  {
    "id": "157331",
    "name": "Maysville Community and Technical College"
  },
  {
    "id": "157377",
    "name": "Midway University"
  },
  {
    "id": "157386",
    "name": "Morehead State University"
  },
  {
    "id": "157401",
    "name": "Murray State University"
  },
  {
    "id": "157438",
    "name": "Gateway Community and Technical College"
  },
  {
    "id": "157447",
    "name": "Northern Kentucky University"
  },
  {
    "id": "157483",
    "name": "West Kentucky Community and Technical College"
  },
  {
    "id": "157535",
    "name": "University of Pikeville"
  },
  {
    "id": "157553",
    "name": "Big Sandy Community and Technical College"
  },
  {
    "id": "157614",
    "name": "Empire Beauty School-Chenoweth"
  },
  {
    "id": "157650",
    "name": "Empire Beauty School-Elizabethtown"
  },
  {
    "id": "157669",
    "name": "Empire Beauty School-Dixie"
  },
  {
    "id": "157678",
    "name": "Empire Beauty School-Florence"
  },
  {
    "id": "157711",
    "name": "Somerset Community College"
  },
  {
    "id": "157739",
    "name": "Southeast Kentucky Community & Technical College"
  },
  {
    "id": "157748",
    "name": "The Southern Baptist Theological Seminary"
  },
  {
    "id": "157757",
    "name": "Spalding University"
  },
  {
    "id": "157793",
    "name": "Sullivan University"
  },
  {
    "id": "157809",
    "name": "Thomas More University"
  },
  {
    "id": "157818",
    "name": "Transylvania University"
  },
  {
    "id": "157863",
    "name": "Union College"
  },
  {
    "id": "157951",
    "name": "Western Kentucky University"
  },
  {
    "id": "158088",
    "name": "Central Louisiana Technical Community College"
  },
  {
    "id": "158246",
    "name": "Ayers Career College"
  },
  {
    "id": "158325",
    "name": "Baton Rouge General Medical Center School of Nursing & School of Radiologic Technology"
  },
  {
    "id": "158343",
    "name": "Baton Rouge School of Computers"
  },
  {
    "id": "158431",
    "name": "Bossier Parish Community College"
  },
  {
    "id": "158477",
    "name": "Centenary College of Louisiana"
  },
  {
    "id": "158538",
    "name": "Cloyd's Beauty School 1 Inc"
  },
  {
    "id": "158617",
    "name": "Crescent City Bartending School"
  },
  {
    "id": "158662",
    "name": "Delgado Community College"
  },
  {
    "id": "158778",
    "name": "Avery James College"
  },
  {
    "id": "158787",
    "name": "Denham Springs Beauty School"
  },
  {
    "id": "158802",
    "name": "Dillard University"
  },
  {
    "id": "158884",
    "name": "Nunez Community College"
  },
  {
    "id": "159009",
    "name": "Grambling State University"
  },
  {
    "id": "159027",
    "name": "Guy's Shreveport Academy of Cosmetology Inc"
  },
  {
    "id": "159197",
    "name": "ITI Technical College"
  },
  {
    "id": "159328",
    "name": "John Jay Beauty College"
  },
  {
    "id": "159373",
    "name": "Louisiana State University Health Sciences Center-New Orleans"
  },
  {
    "id": "159382",
    "name": "Louisiana State University-Alexandria"
  },
  {
    "id": "159391",
    "name": "Louisiana State University and Agricultural & Mechanical College"
  },
  {
    "id": "159407",
    "name": "Louisiana State University-Eunice"
  },
  {
    "id": "159416",
    "name": "Louisiana State University-Shreveport"
  },
  {
    "id": "159513",
    "name": "Aveda Arts & Sciences Institute-Baton Rouge"
  },
  {
    "id": "159522",
    "name": "Aveda Arts & Sciences Institute-Lafayette"
  },
  {
    "id": "159531",
    "name": "Louisiana Academy of Beauty"
  },
  {
    "id": "159568",
    "name": "Louisiana Christian University"
  },
  {
    "id": "159601",
    "name": "Opelousas School of Cosmetology"
  },
  {
    "id": "159647",
    "name": "Louisiana Tech University"
  },
  {
    "id": "159656",
    "name": "Loyola University New Orleans"
  },
  {
    "id": "159717",
    "name": "McNeese State University"
  },
  {
    "id": "159939",
    "name": "University of New Orleans"
  },
  {
    "id": "159948",
    "name": "New Orleans Baptist Theological Seminary"
  },
  {
    "id": "159966",
    "name": "Nicholls State University"
  },
  {
    "id": "159993",
    "name": "University of Louisiana at Monroe"
  },
  {
    "id": "160010",
    "name": "Northwest Louisiana Technical Community College"
  },
  {
    "id": "160038",
    "name": "Northwestern State University of Louisiana"
  },
  {
    "id": "160065",
    "name": "University of Holy Cross"
  },
  {
    "id": "160074",
    "name": "Franciscan Missionaries of Our Lady University"
  },
  {
    "id": "160117",
    "name": "Pat Goins Benton Road Beauty School"
  },
  {
    "id": "160126",
    "name": "Celebrity Stylist Beauty School"
  },
  {
    "id": "160199",
    "name": "Pineville Beauty School"
  },
  {
    "id": "160320",
    "name": "Aveda Arts & Sciences Institute-Covington"
  },
  {
    "id": "160409",
    "name": "Saint Joseph Seminary College"
  },
  {
    "id": "160472",
    "name": "LaSalle Tech"
  },
  {
    "id": "160481",
    "name": "Fletcher Technical Community College"
  },
  {
    "id": "160524",
    "name": "Remington College-Lafayette Campus"
  },
  {
    "id": "160579",
    "name": "SOWELA Technical Community College"
  },
  {
    "id": "160597",
    "name": "Stevensons Academy of Hair Design"
  },
  {
    "id": "160612",
    "name": "Southeastern Louisiana University"
  },
  {
    "id": "160621",
    "name": "Southern University and A & M College"
  },
  {
    "id": "160630",
    "name": "Southern University at New Orleans"
  },
  {
    "id": "160649",
    "name": "Southern University at Shreveport"
  },
  {
    "id": "160658",
    "name": "University of Louisiana at Lafayette"
  },
  {
    "id": "160667",
    "name": "Northshore Technical Community College"
  },
  {
    "id": "160904",
    "name": "Xavier University of Louisiana"
  },
  {
    "id": "160959",
    "name": "College of the Atlantic"
  },
  {
    "id": "160995",
    "name": "Beal University"
  },
  {
    "id": "161022",
    "name": "Maine College of Health Professions"
  },
  {
    "id": "161077",
    "name": "Central Maine Community College"
  },
  {
    "id": "161138",
    "name": "Eastern Maine Community College"
  },
  {
    "id": "161192",
    "name": "Kennebec Valley Community College"
  },
  {
    "id": "161208",
    "name": "The Landing School"
  },
  {
    "id": "161217",
    "name": "University of Maine at Augusta"
  },
  {
    "id": "161226",
    "name": "University of Maine at Farmington"
  },
  {
    "id": "161235",
    "name": "University of Maine at Fort Kent"
  },
  {
    "id": "161253",
    "name": "University of Maine"
  },
  {
    "id": "161299",
    "name": "Maine Maritime Academy"
  },
  {
    "id": "161341",
    "name": "University of Maine at Presque Isle"
  },
  {
    "id": "161457",
    "name": "University of New England"
  },
  {
    "id": "161484",
    "name": "Northern Maine Community College"
  },
  {
    "id": "161493",
    "name": "Empire Beauty School-Maine"
  },
  {
    "id": "161509",
    "name": "Maine College of Art & Design"
  },
  {
    "id": "161518",
    "name": "Saint Joseph's College of Maine"
  },
  {
    "id": "161527",
    "name": "Spa Tech Institute-Westbrook"
  },
  {
    "id": "161545",
    "name": "Southern Maine Community College"
  },
  {
    "id": "161554",
    "name": "University of Southern Maine"
  },
  {
    "id": "161563",
    "name": "Thomas College"
  },
  {
    "id": "161572",
    "name": "Unity College"
  },
  {
    "id": "161581",
    "name": "Washington County Community College"
  },
  {
    "id": "161688",
    "name": "Allegany College of Maryland"
  },
  {
    "id": "161767",
    "name": "Anne Arundel Community College"
  },
  {
    "id": "161800",
    "name": "Award Beauty School"
  },
  {
    "id": "161855",
    "name": "Baltimore Studio of Hair Design"
  },
  {
    "id": "161864",
    "name": "Baltimore City Community College"
  },
  {
    "id": "161873",
    "name": "University of Baltimore"
  },
  {
    "id": "162007",
    "name": "Bowie State University"
  },
  {
    "id": "162061",
    "name": "Capitol Technology University"
  },
  {
    "id": "162104",
    "name": "Cecil College"
  },
  {
    "id": "162122",
    "name": "College of Southern Maryland"
  },
  {
    "id": "162168",
    "name": "Chesapeake College"
  },
  {
    "id": "162210",
    "name": "Washington Adventist University"
  },
  {
    "id": "162283",
    "name": "Coppin State University"
  },
  {
    "id": "162335",
    "name": "Del-Mar-Va Beauty Academy"
  },
  {
    "id": "162371",
    "name": "North American Trade Schools"
  },
  {
    "id": "162557",
    "name": "Frederick Community College"
  },
  {
    "id": "162584",
    "name": "Frostburg State University"
  },
  {
    "id": "162609",
    "name": "Garrett College"
  },
  {
    "id": "162654",
    "name": "Goucher College"
  },
  {
    "id": "162690",
    "name": "Hagerstown Community College"
  },
  {
    "id": "162706",
    "name": "Harford Community College"
  },
  {
    "id": "162760",
    "name": "Hood College"
  },
  {
    "id": "162779",
    "name": "Howard Community College"
  },
  {
    "id": "162830",
    "name": "Aveda Institute-Maryland"
  },
  {
    "id": "163028",
    "name": "Lincoln College of Technology-Columbia"
  },
  {
    "id": "163046",
    "name": "Loyola University Maryland"
  },
  {
    "id": "163204",
    "name": "University of Maryland Global Campus"
  },
  {
    "id": "163259",
    "name": "University of Maryland Baltimore"
  },
  {
    "id": "163295",
    "name": "Maryland Institute College of Art"
  },
  {
    "id": "163338",
    "name": "University of Maryland Eastern Shore"
  },
  {
    "id": "163347",
    "name": "Fortis Institute-Towson"
  },
  {
    "id": "163408",
    "name": "Montgomery Beauty School"
  },
  {
    "id": "163426",
    "name": "Montgomery College"
  },
  {
    "id": "163453",
    "name": "Morgan State University"
  },
  {
    "id": "163462",
    "name": "Mount St. Mary's University"
  },
  {
    "id": "163532",
    "name": "Ner Israel Rabbinical College"
  },
  {
    "id": "163541",
    "name": "Hair Academy"
  },
  {
    "id": "163578",
    "name": "Notre Dame of Maryland University"
  },
  {
    "id": "163657",
    "name": "Prince George's Community College"
  },
  {
    "id": "163815",
    "name": "Maryland Beauty Academy of Essex"
  },
  {
    "id": "163851",
    "name": "Salisbury University"
  },
  {
    "id": "163912",
    "name": "St. Mary's College of Maryland"
  },
  {
    "id": "163976",
    "name": "St. John's College"
  },
  {
    "id": "164076",
    "name": "Towson University"
  },
  {
    "id": "164085",
    "name": "Maryland University of Integrative Health"
  },
  {
    "id": "164173",
    "name": "Stevenson University"
  },
  {
    "id": "164216",
    "name": "Washington College"
  },
  {
    "id": "164270",
    "name": "McDaniel College"
  },
  {
    "id": "164313",
    "name": "Wor-Wic Community College"
  },
  {
    "id": "164368",
    "name": "Hult International Business School"
  },
  {
    "id": "164447",
    "name": "American International College"
  },
  {
    "id": "164492",
    "name": "Anna Maria College"
  },
  {
    "id": "164535",
    "name": "Assabet Valley Regional Technical School"
  },
  {
    "id": "164562",
    "name": "Assumption University"
  },
  {
    "id": "164580",
    "name": "Babson College"
  },
  {
    "id": "164599",
    "name": "Bancroft School of Massage Therapy"
  },
  {
    "id": "164614",
    "name": "Boston Baptist College"
  },
  {
    "id": "164632",
    "name": "Bay Path University"
  },
  {
    "id": "164641",
    "name": "Bay State College"
  },
  {
    "id": "164739",
    "name": "Bentley University"
  },
  {
    "id": "164748",
    "name": "Berklee College of Music"
  },
  {
    "id": "164775",
    "name": "Berkshire Community College"
  },
  {
    "id": "164845",
    "name": "Empire Beauty School-Boston"
  },
  {
    "id": "164872",
    "name": "Boston Architectural College"
  },
  {
    "id": "164915",
    "name": "Boston Graduate School of Psychoanalysis Inc"
  },
  {
    "id": "165024",
    "name": "Bridgewater State University"
  },
  {
    "id": "165033",
    "name": "Bristol Community College"
  },
  {
    "id": "165060",
    "name": "Signature Healthcare Brockton Hospital School of Nursing"
  },
  {
    "id": "165112",
    "name": "Bunker Hill Community College"
  },
  {
    "id": "165167",
    "name": "Cambridge College"
  },
  {
    "id": "165194",
    "name": "Cape Cod Community College"
  },
  {
    "id": "165255",
    "name": "Catherine Hinds Institute of Esthetics"
  },
  {
    "id": "165264",
    "name": "Laboure College of Healthcare"
  },
  {
    "id": "165495",
    "name": "Conway School of Landscape Design"
  },
  {
    "id": "165529",
    "name": "Curry College"
  },
  {
    "id": "165556",
    "name": "Lincoln Technical Institute-Somerville"
  },
  {
    "id": "165574",
    "name": "Dean College"
  },
  {
    "id": "165608",
    "name": "Diman Regional Technical Institute"
  },
  {
    "id": "165635",
    "name": "National Aviation Academy of New England"
  },
  {
    "id": "165644",
    "name": "Eastern Nazarene College"
  },
  {
    "id": "165662",
    "name": "Emerson College"
  },
  {
    "id": "165671",
    "name": "Emmanuel College"
  },
  {
    "id": "165699",
    "name": "Endicott College"
  },
  {
    "id": "165750",
    "name": "Rob Roy Academy-Fall River"
  },
  {
    "id": "165802",
    "name": "Fisher College"
  },
  {
    "id": "165820",
    "name": "Fitchburg State University"
  },
  {
    "id": "165866",
    "name": "Framingham State University"
  },
  {
    "id": "165884",
    "name": "Benjamin Franklin Cummings Institute of Technology"
  },
  {
    "id": "165936",
    "name": "Gordon College"
  },
  {
    "id": "165945",
    "name": "Gordon-Conwell Theological Seminary"
  },
  {
    "id": "165981",
    "name": "Greenfield Community College"
  },
  {
    "id": "166018",
    "name": "Hampshire College"
  },
  {
    "id": "166045",
    "name": "Hebrew College"
  },
  {
    "id": "166054",
    "name": "Hellenic College-Holy Cross Greek Orthodox School of Theology"
  },
  {
    "id": "166124",
    "name": "College of the Holy Cross"
  },
  {
    "id": "166133",
    "name": "Holyoke Community College"
  },
  {
    "id": "166391",
    "name": "Lasell University"
  },
  {
    "id": "166407",
    "name": "Lawrence Memorial Hospital School of Nursing"
  },
  {
    "id": "166452",
    "name": "Lesley University"
  },
  {
    "id": "166489",
    "name": "Longy School of Music of Bard College"
  },
  {
    "id": "166498",
    "name": "Lowell Academy Hairstyling Institute"
  },
  {
    "id": "166513",
    "name": "University of Massachusetts-Lowell"
  },
  {
    "id": "166638",
    "name": "University of Massachusetts-Boston"
  },
  {
    "id": "166647",
    "name": "Massachusetts Bay Community College"
  },
  {
    "id": "166656",
    "name": "MCPHS University"
  },
  {
    "id": "166674",
    "name": "Massachusetts College of Art and Design"
  },
  {
    "id": "166692",
    "name": "Massachusetts Maritime Academy"
  },
  {
    "id": "166708",
    "name": "University of Massachusetts Chan Medical School"
  },
  {
    "id": "166717",
    "name": "William James College"
  },
  {
    "id": "166805",
    "name": "Massachusetts School of Barbering"
  },
  {
    "id": "166823",
    "name": "Massasoit Community College"
  },
  {
    "id": "166850",
    "name": "Merrimack College"
  },
  {
    "id": "166869",
    "name": "MGH Institute of Health Professions"
  },
  {
    "id": "166887",
    "name": "Middlesex Community College"
  },
  {
    "id": "166911",
    "name": "Montserrat College of Art"
  },
  {
    "id": "166939",
    "name": "Mount Holyoke College"
  },
  {
    "id": "166957",
    "name": "Mount Wachusett Community College"
  },
  {
    "id": "167039",
    "name": "Rob Roy Academy-New Bedford"
  },
  {
    "id": "167057",
    "name": "The New England Conservatory of Music"
  },
  {
    "id": "167093",
    "name": "New England College of Optometry"
  },
  {
    "id": "167215",
    "name": "New England Law-Boston"
  },
  {
    "id": "167260",
    "name": "Nichols College"
  },
  {
    "id": "167288",
    "name": "Massachusetts College of Liberal Arts"
  },
  {
    "id": "167297",
    "name": "North Bennet Street School"
  },
  {
    "id": "167312",
    "name": "North Shore Community College"
  },
  {
    "id": "167376",
    "name": "Northern Essex Community College"
  },
  {
    "id": "167394",
    "name": "College of Our Lady of the Elms"
  },
  {
    "id": "167455",
    "name": "Pine Manor College"
  },
  {
    "id": "167464",
    "name": "Pope St John XXIII National Seminary"
  },
  {
    "id": "167525",
    "name": "Quincy College"
  },
  {
    "id": "167534",
    "name": "Quinsigamond Community College"
  },
  {
    "id": "167598",
    "name": "Regis College"
  },
  {
    "id": "167631",
    "name": "Roxbury Community College"
  },
  {
    "id": "167677",
    "name": "Saint John's Seminary"
  },
  {
    "id": "167729",
    "name": "Salem State University"
  },
  {
    "id": "167783",
    "name": "Simmons University"
  },
  {
    "id": "167792",
    "name": "Bard College at Simon's Rock"
  },
  {
    "id": "167871",
    "name": "Southeastern Technical Institute"
  },
  {
    "id": "167899",
    "name": "Springfield College"
  },
  {
    "id": "167905",
    "name": "Springfield Technical Community College"
  },
  {
    "id": "167987",
    "name": "University of Massachusetts-Dartmouth"
  },
  {
    "id": "167996",
    "name": "Stonehill College"
  },
  {
    "id": "168005",
    "name": "Suffolk University"
  },
  {
    "id": "168032",
    "name": "Rob Roy Academy-Taunton"
  },
  {
    "id": "168227",
    "name": "Wentworth Institute of Technology"
  },
  {
    "id": "168254",
    "name": "Western New England University"
  },
  {
    "id": "168263",
    "name": "Westfield State University"
  },
  {
    "id": "168281",
    "name": "Wheaton College (Massachusetts)"
  },
  {
    "id": "168430",
    "name": "Worcester State University"
  },
  {
    "id": "168528",
    "name": "Adrian College"
  },
  {
    "id": "168546",
    "name": "Albion College"
  },
  {
    "id": "168555",
    "name": "Hillsdale Beauty College"
  },
  {
    "id": "168573",
    "name": "Northwestern Technological Institute"
  },
  {
    "id": "168591",
    "name": "Alma College"
  },
  {
    "id": "168607",
    "name": "Alpena Community College"
  },
  {
    "id": "168740",
    "name": "Andrews University"
  },
  {
    "id": "168786",
    "name": "Aquinas College"
  },
  {
    "id": "168847",
    "name": "Baker College"
  },
  {
    "id": "168883",
    "name": "Bay de Noc Community College"
  },
  {
    "id": "168892",
    "name": "Bayshire Academy of Beauty Craft Inc"
  },
  {
    "id": "169080",
    "name": "Calvin University"
  },
  {
    "id": "169099",
    "name": "Calvin Theological Seminary"
  },
  {
    "id": "169187",
    "name": "Carnegie Institute"
  },
  {
    "id": "169220",
    "name": "Michigan School of Psychology"
  },
  {
    "id": "169248",
    "name": "Central Michigan University"
  },
  {
    "id": "169275",
    "name": "Mott Community College"
  },
  {
    "id": "169284",
    "name": "Empire Beauty School-Michigan"
  },
  {
    "id": "169327",
    "name": "Cleary University"
  },
  {
    "id": "169363",
    "name": "Concordia University Ann Arbor"
  },
  {
    "id": "169424",
    "name": "Cranbrook Academy of Art"
  },
  {
    "id": "169442",
    "name": "College for Creative Studies"
  },
  {
    "id": "169479",
    "name": "Davenport University"
  },
  {
    "id": "169503",
    "name": "David Pressley School of Cosmetology"
  },
  {
    "id": "169521",
    "name": "Delta College"
  },
  {
    "id": "169576",
    "name": "Detroit Business Institute-Downriver"
  },
  {
    "id": "169655",
    "name": "MIAT College of Technology"
  },
  {
    "id": "169716",
    "name": "University of Detroit Mercy"
  },
  {
    "id": "169761",
    "name": "Dorsey College"
  },
  {
    "id": "169798",
    "name": "Eastern Michigan University"
  },
  {
    "id": "169813",
    "name": "Creative Hair School of Cosmetology"
  },
  {
    "id": "169910",
    "name": "Ferris State University"
  },
  {
    "id": "169974",
    "name": "Glen Oaks Community College"
  },
  {
    "id": "169983",
    "name": "Kettering University"
  },
  {
    "id": "169992",
    "name": "Gogebic Community College"
  },
  {
    "id": "170000",
    "name": "Grace Christian University"
  },
  {
    "id": "170037",
    "name": "Cornerstone University"
  },
  {
    "id": "170055",
    "name": "Grand Rapids Community College"
  },
  {
    "id": "170082",
    "name": "Grand Valley State University"
  },
  {
    "id": "170091",
    "name": "Great Lakes Christian College"
  },
  {
    "id": "170240",
    "name": "Henry Ford College"
  },
  {
    "id": "170286",
    "name": "Hillsdale College"
  },
  {
    "id": "170301",
    "name": "Hope College"
  },
  {
    "id": "170444",
    "name": "Jackson College"
  },
  {
    "id": "170532",
    "name": "Kalamazoo College"
  },
  {
    "id": "170541",
    "name": "Kalamazoo Valley Community College"
  },
  {
    "id": "170550",
    "name": "Kellogg Community College"
  },
  {
    "id": "170587",
    "name": "Kirtland Community College"
  },
  {
    "id": "170611",
    "name": "Michigan College of Beauty-Monroe"
  },
  {
    "id": "170620",
    "name": "Lake Michigan College"
  },
  {
    "id": "170639",
    "name": "Lake Superior State University"
  },
  {
    "id": "170657",
    "name": "Lansing Community College"
  },
  {
    "id": "170675",
    "name": "Lawrence Technological University"
  },
  {
    "id": "170736",
    "name": "M J Murphy Beauty College of Mount Pleasant"
  },
  {
    "id": "170790",
    "name": "Macomb Community College"
  },
  {
    "id": "170806",
    "name": "Madonna University"
  },
  {
    "id": "170967",
    "name": "Rochester University"
  },
  {
    "id": "171012",
    "name": "The Michigan Barber School"
  },
  {
    "id": "171128",
    "name": "Michigan Technological University"
  },
  {
    "id": "171137",
    "name": "University of Michigan-Dearborn"
  },
  {
    "id": "171146",
    "name": "University of Michigan-Flint"
  },
  {
    "id": "171155",
    "name": "Mid Michigan College"
  },
  {
    "id": "171225",
    "name": "Monroe County Community College"
  },
  {
    "id": "171234",
    "name": "Montcalm Community College"
  },
  {
    "id": "171304",
    "name": "Muskegon Community College"
  },
  {
    "id": "171395",
    "name": "North Central Michigan College"
  },
  {
    "id": "171456",
    "name": "Northern Michigan University"
  },
  {
    "id": "171483",
    "name": "Northwestern Michigan College"
  },
  {
    "id": "171492",
    "name": "Northwood University"
  },
  {
    "id": "171535",
    "name": "Oakland Community College"
  },
  {
    "id": "171571",
    "name": "Oakland University"
  },
  {
    "id": "171599",
    "name": "Olivet College"
  },
  {
    "id": "171775",
    "name": "Port Huron Cosmetology College"
  },
  {
    "id": "171881",
    "name": "Kuyper College"
  },
  {
    "id": "171988",
    "name": "Ross Medical Education Center-Lansing"
  },
  {
    "id": "171997",
    "name": "Ross Medical Education Center-Warren"
  },
  {
    "id": "172015",
    "name": "Ross Medical Education Center-Flint"
  },
  {
    "id": "172033",
    "name": "Sacred Heart Major Seminary"
  },
  {
    "id": "172051",
    "name": "Saginaw Valley State University"
  },
  {
    "id": "172200",
    "name": "Schoolcraft Community College District"
  },
  {
    "id": "172264",
    "name": "Siena Heights University"
  },
  {
    "id": "172291",
    "name": "St Clair County Community College"
  },
  {
    "id": "172307",
    "name": "Southwestern Michigan College"
  },
  {
    "id": "172334",
    "name": "Spring Arbor University"
  },
  {
    "id": "172422",
    "name": "Michigan Career and Technical Institute"
  },
  {
    "id": "172440",
    "name": "Finlandia University"
  },
  {
    "id": "172477",
    "name": "Western Michigan University-Thomas M. Cooley Law School"
  },
  {
    "id": "172495",
    "name": "Twin City Beauty College"
  },
  {
    "id": "172608",
    "name": "Walsh College"
  },
  {
    "id": "172617",
    "name": "Washtenaw Community College"
  },
  {
    "id": "172635",
    "name": "Wayne County Community College District"
  },
  {
    "id": "172644",
    "name": "Wayne State University"
  },
  {
    "id": "172671",
    "name": "West Shore Community College"
  },
  {
    "id": "172680",
    "name": "Paul Mitchell the School-Grand Rapids"
  },
  {
    "id": "172699",
    "name": "Western Michigan University"
  },
  {
    "id": "172705",
    "name": "Western Theological Seminary"
  },
  {
    "id": "172866",
    "name": "Academy College"
  },
  {
    "id": "172918",
    "name": "Alexandria Technical & Community College"
  },
  {
    "id": "172954",
    "name": "Anoka Technical College"
  },
  {
    "id": "172963",
    "name": "Anoka-Ramsey Community College"
  },
  {
    "id": "173045",
    "name": "Augsburg University"
  },
  {
    "id": "173063",
    "name": "Riverland Community College"
  },
  {
    "id": "173115",
    "name": "Northwest Technical College"
  },
  {
    "id": "173124",
    "name": "Bemidji State University"
  },
  {
    "id": "173142",
    "name": "Bethany Lutheran College"
  },
  {
    "id": "173160",
    "name": "Bethel University"
  },
  {
    "id": "173203",
    "name": "Central Lakes College-Brainerd"
  },
  {
    "id": "173300",
    "name": "Concordia College at Moorhead"
  },
  {
    "id": "173328",
    "name": "Concordia University-Saint Paul"
  },
  {
    "id": "173416",
    "name": "Dakota County Technical College"
  },
  {
    "id": "173452",
    "name": "Martin Luther College"
  },
  {
    "id": "173461",
    "name": "Lake Superior College"
  },
  {
    "id": "173470",
    "name": "Cosmetology Careers Unlimited College of Hair Skin and Nails"
  },
  {
    "id": "173559",
    "name": "Minnesota State Community and Technical College"
  },
  {
    "id": "173638",
    "name": "Minnesota West Community and Technical College"
  },
  {
    "id": "173647",
    "name": "Gustavus Adolphus College"
  },
  {
    "id": "173665",
    "name": "Hamline University"
  },
  {
    "id": "173683",
    "name": "Hazelden Betty Ford Graduate School of Addiction Studies"
  },
  {
    "id": "173708",
    "name": "Hennepin Technical College"
  },
  {
    "id": "173735",
    "name": "Minnesota North College"
  },
  {
    "id": "173744",
    "name": "Aveda Arts & Sciences Institute Minneapolis"
  },
  {
    "id": "173799",
    "name": "Inver Hills Community College"
  },
  {
    "id": "173805",
    "name": "Minnesota North College - Itasca"
  },
  {
    "id": "173896",
    "name": "Luther Seminary"
  },
  {
    "id": "173911",
    "name": "South Central College"
  },
  {
    "id": "173920",
    "name": "Minnesota State University-Mankato"
  },
  {
    "id": "173957",
    "name": "Mayo Clinic College of Medicine and Science"
  },
  {
    "id": "173993",
    "name": "Minnesota North College - Mesabi Range Virginia"
  },
  {
    "id": "174020",
    "name": "Metropolitan State University"
  },
  {
    "id": "174075",
    "name": "University of Minnesota-Crookston"
  },
  {
    "id": "174127",
    "name": "Minneapolis College of Art and Design"
  },
  {
    "id": "174136",
    "name": "Minneapolis Community and Technical College"
  },
  {
    "id": "174154",
    "name": "Herzing University-Minneapolis"
  },
  {
    "id": "174190",
    "name": "Minnesota School of Cosmetology-Woodbury Campus"
  },
  {
    "id": "174233",
    "name": "University of Minnesota-Duluth"
  },
  {
    "id": "174251",
    "name": "University of Minnesota-Morris"
  },
  {
    "id": "174321",
    "name": "Model College of Hair Design"
  },
  {
    "id": "174358",
    "name": "Minnesota State University Moorhead"
  },
  {
    "id": "174376",
    "name": "North Hennepin Community College"
  },
  {
    "id": "174428",
    "name": "Normandale Community College"
  },
  {
    "id": "174437",
    "name": "North Central University"
  },
  {
    "id": "174473",
    "name": "Northland Community and Technical College"
  },
  {
    "id": "174491",
    "name": "University of Northwestern-St Paul"
  },
  {
    "id": "174507",
    "name": "Northwestern Health Sciences University"
  },
  {
    "id": "174525",
    "name": "Oak Hills Christian College"
  },
  {
    "id": "174570",
    "name": "Pine Technical & Community College"
  },
  {
    "id": "174604",
    "name": "Minnesota North College - Rainy River"
  },
  {
    "id": "174738",
    "name": "Rochester Community and Technical College"
  },
  {
    "id": "174747",
    "name": "College of Saint Benedict"
  },
  {
    "id": "174756",
    "name": "St Cloud Technical and Community College"
  },
  {
    "id": "174783",
    "name": "Saint Cloud State University"
  },
  {
    "id": "174792",
    "name": "Saint Johns University"
  },
  {
    "id": "174817",
    "name": "Saint Mary's University of Minnesota"
  },
  {
    "id": "174844",
    "name": "St Olaf College"
  },
  {
    "id": "174862",
    "name": "Crown College"
  },
  {
    "id": "174899",
    "name": "The College of Saint Scholastica"
  },
  {
    "id": "174978",
    "name": "Empire Beauty School-Bloomington"
  },
  {
    "id": "175005",
    "name": "St Catherine University"
  },
  {
    "id": "175014",
    "name": "Rasmussen University-Minnesota"
  },
  {
    "id": "175041",
    "name": "Saint Paul College"
  },
  {
    "id": "175078",
    "name": "Southwest Minnesota State University"
  },
  {
    "id": "175120",
    "name": "Summit Academy Opportunities Industrialization Center"
  },
  {
    "id": "175139",
    "name": "United Theological Seminary of the Twin Cities"
  },
  {
    "id": "175157",
    "name": "Minnesota North College - Vermilion"
  },
  {
    "id": "175227",
    "name": "Dunwoody College of Technology"
  },
  {
    "id": "175236",
    "name": "Ridgewater College"
  },
  {
    "id": "175263",
    "name": "Minnesota State College Southeast"
  },
  {
    "id": "175272",
    "name": "Winona State University"
  },
  {
    "id": "175281",
    "name": "Mitchell Hamline School of Law"
  },
  {
    "id": "175315",
    "name": "Century College"
  },
  {
    "id": "175342",
    "name": "Alcorn State University"
  },
  {
    "id": "175421",
    "name": "Belhaven University"
  },
  {
    "id": "175430",
    "name": "Blue Mountain Christian University"
  },
  {
    "id": "175494",
    "name": "Chris Beauty College"
  },
  {
    "id": "175519",
    "name": "Coahoma Community College"
  },
  {
    "id": "175573",
    "name": "Copiah-Lincoln Community College"
  },
  {
    "id": "175616",
    "name": "Delta State University"
  },
  {
    "id": "175643",
    "name": "East Central Community College"
  },
  {
    "id": "175652",
    "name": "East Mississippi Community College"
  },
  {
    "id": "175698",
    "name": "Fosters Cosmetology College"
  },
  {
    "id": "175722",
    "name": "Academy of Hair Design-Grenada"
  },
  {
    "id": "175786",
    "name": "Hinds Community College"
  },
  {
    "id": "175810",
    "name": "Holmes Community College"
  },
  {
    "id": "175829",
    "name": "Itawamba Community College"
  },
  {
    "id": "175856",
    "name": "Jackson State University"
  },
  {
    "id": "175883",
    "name": "Jones County Junior College"
  },
  {
    "id": "175935",
    "name": "Meridian Community College"
  },
  {
    "id": "175980",
    "name": "Millsaps College"
  },
  {
    "id": "176008",
    "name": "Mississippi Delta Community College"
  },
  {
    "id": "176017",
    "name": "University of Mississippi"
  },
  {
    "id": "176035",
    "name": "Mississippi University for Women"
  },
  {
    "id": "176044",
    "name": "Mississippi Valley State University"
  },
  {
    "id": "176053",
    "name": "Mississippi College"
  },
  {
    "id": "176062",
    "name": "Hatfield's Mississippi College of Beauty Culture"
  },
  {
    "id": "176071",
    "name": "Mississippi Gulf Coast Community College"
  },
  {
    "id": "176080",
    "name": "Mississippi State University"
  },
  {
    "id": "176169",
    "name": "Northeast Mississippi Community College"
  },
  {
    "id": "176178",
    "name": "Northwest Mississippi Community College"
  },
  {
    "id": "176239",
    "name": "Pearl River Community College"
  },
  {
    "id": "176318",
    "name": "Rust College"
  },
  {
    "id": "176336",
    "name": "Southeastern Baptist College"
  },
  {
    "id": "176354",
    "name": "Southwest Mississippi Community College"
  },
  {
    "id": "176372",
    "name": "University of Southern Mississippi"
  },
  {
    "id": "176406",
    "name": "Tougaloo College"
  },
  {
    "id": "176433",
    "name": "Vaughn Beauty College"
  },
  {
    "id": "176451",
    "name": "Wesley Biblical Seminary"
  },
  {
    "id": "176479",
    "name": "William Carey University"
  },
  {
    "id": "176600",
    "name": "Aquinas Institute of Theology"
  },
  {
    "id": "176628",
    "name": "Avila University"
  },
  {
    "id": "176664",
    "name": "Baptist Bible College"
  },
  {
    "id": "176716",
    "name": "House of Heavilin Beauty College-Blue Springs"
  },
  {
    "id": "176770",
    "name": "Cox College"
  },
  {
    "id": "176789",
    "name": "Calvary University"
  },
  {
    "id": "176798",
    "name": "Cape Girardeau Career and Technology Center"
  },
  {
    "id": "176910",
    "name": "Central Christian College of the Bible"
  },
  {
    "id": "176947",
    "name": "Central Methodist University-College of Liberal Arts and Sciences"
  },
  {
    "id": "176965",
    "name": "University of Central Missouri"
  },
  {
    "id": "176983",
    "name": "Grand River Technical School"
  },
  {
    "id": "177038",
    "name": "Cleveland University-Kansas City"
  },
  {
    "id": "177065",
    "name": "Columbia College"
  },
  {
    "id": "177083",
    "name": "Conception Seminary College"
  },
  {
    "id": "177092",
    "name": "Concordia Seminary"
  },
  {
    "id": "177117",
    "name": "Cottey College"
  },
  {
    "id": "177126",
    "name": "Covenant Theological Seminary"
  },
  {
    "id": "177135",
    "name": "Crowder College"
  },
  {
    "id": "177144",
    "name": "Culver-Stockton College"
  },
  {
    "id": "177214",
    "name": "Drury University"
  },
  {
    "id": "177250",
    "name": "East Central College"
  },
  {
    "id": "177278",
    "name": "Eden Theological Seminary"
  },
  {
    "id": "177302",
    "name": "Pinnacle Career Institute"
  },
  {
    "id": "177339",
    "name": "Evangel University"
  },
  {
    "id": "177418",
    "name": "Fontbonne University"
  },
  {
    "id": "177436",
    "name": "Four Rivers Career Center"
  },
  {
    "id": "177472",
    "name": "Ozarks Technical Community College"
  },
  {
    "id": "177542",
    "name": "Hannibal-LaGrange University"
  },
  {
    "id": "177551",
    "name": "Harris-Stowe State University"
  },
  {
    "id": "177588",
    "name": "House of Heavilin Beauty College-Kansas City"
  },
  {
    "id": "177603",
    "name": "Summit Salon Academy Kansas City"
  },
  {
    "id": "177676",
    "name": "Jefferson College"
  },
  {
    "id": "177685",
    "name": "Paul Mitchell the School-Missouri Columbia"
  },
  {
    "id": "177719",
    "name": "Barnes-Jewish College Goldfarb School of Nursing"
  },
  {
    "id": "177746",
    "name": "Kansas City Art Institute"
  },
  {
    "id": "177816",
    "name": "Kenrick Glennon Seminary"
  },
  {
    "id": "177834",
    "name": "A T Still University of Health Sciences"
  },
  {
    "id": "177870",
    "name": "Lake Career and Technical Center"
  },
  {
    "id": "177931",
    "name": "Lex La-Ray Technical Center"
  },
  {
    "id": "177940",
    "name": "Lincoln University"
  },
  {
    "id": "177968",
    "name": "Lindenwood University"
  },
  {
    "id": "177977",
    "name": "State Technical College of Missouri"
  },
  {
    "id": "177986",
    "name": "Logan University"
  },
  {
    "id": "177995",
    "name": "Metropolitan Community College-Kansas City"
  },
  {
    "id": "178004",
    "name": "Lutheran School of Nursing"
  },
  {
    "id": "178059",
    "name": "Maryville University of Saint Louis"
  },
  {
    "id": "178077",
    "name": "Missouri College of Cosmetology and Esthetics"
  },
  {
    "id": "178183",
    "name": "Midwest Institute"
  },
  {
    "id": "178208",
    "name": "Midwestern Baptist Theological Seminary"
  },
  {
    "id": "178217",
    "name": "Mineral Area College"
  },
  {
    "id": "178244",
    "name": "Missouri Baptist University"
  },
  {
    "id": "178341",
    "name": "Missouri Southern State University"
  },
  {
    "id": "178369",
    "name": "Missouri Valley College"
  },
  {
    "id": "178387",
    "name": "Missouri Western State University"
  },
  {
    "id": "178402",
    "name": "University of Missouri-Kansas City"
  },
  {
    "id": "178411",
    "name": "Missouri University of Science and Technology"
  },
  {
    "id": "178420",
    "name": "University of Missouri-St Louis"
  },
  {
    "id": "178448",
    "name": "Moberly Area Community College"
  },
  {
    "id": "178518",
    "name": "Nazarene Theological Seminary"
  },
  {
    "id": "178527",
    "name": "Neosho Beauty College"
  },
  {
    "id": "178615",
    "name": "Truman State University"
  },
  {
    "id": "178624",
    "name": "Northwest Missouri State University"
  },
  {
    "id": "178679",
    "name": "Ozark Christian College"
  },
  {
    "id": "178697",
    "name": "College of the Ozarks"
  },
  {
    "id": "178721",
    "name": "Park University"
  },
  {
    "id": "178767",
    "name": "Stevens-The Institute of Business & Arts"
  },
  {
    "id": "178828",
    "name": "Poplar Bluff Technical Career Center"
  },
  {
    "id": "178891",
    "name": "Ranken Technical College"
  },
  {
    "id": "178989",
    "name": "Research College of Nursing"
  },
  {
    "id": "179043",
    "name": "Rockhurst University"
  },
  {
    "id": "179052",
    "name": "Rolla Technical Institute/Center"
  },
  {
    "id": "179195",
    "name": "Salem College of Hairstyling"
  },
  {
    "id": "179229",
    "name": "American College of Hair Design Inc"
  },
  {
    "id": "179256",
    "name": "Saint Louis Christian College"
  },
  {
    "id": "179265",
    "name": "University of Health Sciences and Pharmacy in St. Louis"
  },
  {
    "id": "179308",
    "name": "Saint Louis Community College"
  },
  {
    "id": "179317",
    "name": "Saint Paul School of Theology"
  },
  {
    "id": "179326",
    "name": "Southwest Baptist University"
  },
  {
    "id": "179344",
    "name": "Missouri State University-West Plains"
  },
  {
    "id": "179511",
    "name": "St Louis College of Health Careers-St Louis"
  },
  {
    "id": "179539",
    "name": "State Fair Community College"
  },
  {
    "id": "179548",
    "name": "Stephens College"
  },
  {
    "id": "179557",
    "name": "Southeast Missouri State University"
  },
  {
    "id": "179566",
    "name": "Missouri State University-Springfield"
  },
  {
    "id": "179645",
    "name": "Three Rivers College"
  },
  {
    "id": "179715",
    "name": "North Central Missouri College"
  },
  {
    "id": "179812",
    "name": "Kansas City University"
  },
  {
    "id": "179894",
    "name": "Webster University"
  },
  {
    "id": "179946",
    "name": "Westminster College"
  },
  {
    "id": "179955",
    "name": "William Jewell College"
  },
  {
    "id": "179964",
    "name": "William Woods University"
  },
  {
    "id": "179991",
    "name": "Academy of Cosmetology Inc"
  },
  {
    "id": "180054",
    "name": "Blackfeet Community College"
  },
  {
    "id": "180063",
    "name": "Butte Academy of Beauty Culture"
  },
  {
    "id": "180081",
    "name": "Highlands College of Montana Tech"
  },
  {
    "id": "180090",
    "name": "Crevier's Academy of Cosmetology Arts"
  },
  {
    "id": "180106",
    "name": "Carroll College"
  },
  {
    "id": "180151",
    "name": "Dawson Community College"
  },
  {
    "id": "180160",
    "name": "Chief Dull Knife College"
  },
  {
    "id": "180179",
    "name": "Montana State University Billings"
  },
  {
    "id": "180197",
    "name": "Flathead Valley Community College"
  },
  {
    "id": "180203",
    "name": "Aaniiih Nakoda College"
  },
  {
    "id": "180212",
    "name": "Fort Peck Community College"
  },
  {
    "id": "180249",
    "name": "Great Falls College Montana State University"
  },
  {
    "id": "180258",
    "name": "University of Providence"
  },
  {
    "id": "180276",
    "name": "Helena College University of Montana"
  },
  {
    "id": "180328",
    "name": "Little Big Horn College"
  },
  {
    "id": "180373",
    "name": "Miles Community College"
  },
  {
    "id": "180416",
    "name": "Montana Technological University"
  },
  {
    "id": "180461",
    "name": "Montana State University"
  },
  {
    "id": "180489",
    "name": "The University of Montana"
  },
  {
    "id": "180522",
    "name": "Montana State University-Northern"
  },
  {
    "id": "180595",
    "name": "Rocky Mountain College"
  },
  {
    "id": "180647",
    "name": "Salish Kootenai College"
  },
  {
    "id": "180692",
    "name": "The University of Montana-Western"
  },
  {
    "id": "180717",
    "name": "Yellowstone Christian College"
  },
  {
    "id": "180814",
    "name": "Bellevue University"
  },
  {
    "id": "180832",
    "name": "Clarkson College"
  },
  {
    "id": "180878",
    "name": "Bryan College of Health Sciences"
  },
  {
    "id": "180902",
    "name": "Central Community College"
  },
  {
    "id": "180948",
    "name": "Chadron State College"
  },
  {
    "id": "180957",
    "name": "College of Hair Design-Downtown"
  },
  {
    "id": "180984",
    "name": "Concordia University-Nebraska"
  },
  {
    "id": "181020",
    "name": "Doane University"
  },
  {
    "id": "181127",
    "name": "Hastings College"
  },
  {
    "id": "181145",
    "name": "CHI Health School of Radiologic Technology"
  },
  {
    "id": "181190",
    "name": "Joseph's College Cosmetology"
  },
  {
    "id": "181215",
    "name": "University of Nebraska at Kearney"
  },
  {
    "id": "181297",
    "name": "Nebraska Methodist College of Nursing & Allied Health"
  },
  {
    "id": "181303",
    "name": "Metropolitan Community College Area"
  },
  {
    "id": "181312",
    "name": "Mid-Plains Community College"
  },
  {
    "id": "181330",
    "name": "Midland University"
  },
  {
    "id": "181394",
    "name": "University of Nebraska at Omaha"
  },
  {
    "id": "181419",
    "name": "Nebraska Indian Community College"
  },
  {
    "id": "181428",
    "name": "University of Nebraska Medical Center"
  },
  {
    "id": "181446",
    "name": "Nebraska Wesleyan University"
  },
  {
    "id": "181464",
    "name": "University of Nebraska-Lincoln"
  },
  {
    "id": "181491",
    "name": "Northeast Community College"
  },
  {
    "id": "181534",
    "name": "Peru State College"
  },
  {
    "id": "181543",
    "name": "Summit Christian College"
  },
  {
    "id": "181604",
    "name": "College of Saint Mary"
  },
  {
    "id": "181640",
    "name": "Southeast Community College Area"
  },
  {
    "id": "181738",
    "name": "Union College"
  },
  {
    "id": "181765",
    "name": "Nebraska College of Technical Agriculture"
  },
  {
    "id": "181783",
    "name": "Wayne State College"
  },
  {
    "id": "181817",
    "name": "Western Nebraska Community College"
  },
  {
    "id": "181853",
    "name": "York University"
  },
  {
    "id": "181880",
    "name": "Academy of Hair Design-Las Vegas"
  },
  {
    "id": "181941",
    "name": "Career College of Northern Nevada"
  },
  {
    "id": "182005",
    "name": "College of Southern Nevada"
  },
  {
    "id": "182148",
    "name": "Las Vegas College"
  },
  {
    "id": "182281",
    "name": "University of Nevada-Las Vegas"
  },
  {
    "id": "182290",
    "name": "University of Nevada-Reno"
  },
  {
    "id": "182306",
    "name": "Great Basin College"
  },
  {
    "id": "182458",
    "name": "Sierra Nevada University"
  },
  {
    "id": "182500",
    "name": "Truckee Meadows Community College"
  },
  {
    "id": "182564",
    "name": "Western Nevada College"
  },
  {
    "id": "182634",
    "name": "Colby-Sawyer College"
  },
  {
    "id": "182704",
    "name": "Empire Beauty School-Laconia"
  },
  {
    "id": "182722",
    "name": "Empire Beauty School-Somersworth"
  },
  {
    "id": "182795",
    "name": "Franklin Pierce University"
  },
  {
    "id": "182829",
    "name": "University of New Hampshire-Franklin Pierce School of Law"
  },
  {
    "id": "182892",
    "name": "Keene Beauty Academy"
  },
  {
    "id": "182917",
    "name": "Magdalen College"
  },
  {
    "id": "182953",
    "name": "Michael's School of Hair Design & Esthetics"
  },
  {
    "id": "182980",
    "name": "New England College"
  },
  {
    "id": "182999",
    "name": "New England School of Hair Design"
  },
  {
    "id": "183026",
    "name": "Southern New Hampshire University"
  },
  {
    "id": "183035",
    "name": "New Hampshire Institute for Therapeutic Arts"
  },
  {
    "id": "183062",
    "name": "Keene State College"
  },
  {
    "id": "183071",
    "name": "University of New Hampshire at Manchester"
  },
  {
    "id": "183080",
    "name": "Plymouth State University"
  },
  {
    "id": "183099",
    "name": "NHTI-Concord's Community College"
  },
  {
    "id": "183105",
    "name": "White Mountains Community College"
  },
  {
    "id": "183114",
    "name": "River Valley Community College"
  },
  {
    "id": "183123",
    "name": "Lakes Region Community College"
  },
  {
    "id": "183132",
    "name": "Manchester Community College"
  },
  {
    "id": "183141",
    "name": "Nashua Community College"
  },
  {
    "id": "183150",
    "name": "Great Bay Community College"
  },
  {
    "id": "183202",
    "name": "Paul Mitchell the School-Portsmouth"
  },
  {
    "id": "183211",
    "name": "Rivier University"
  },
  {
    "id": "183239",
    "name": "Saint Anselm College"
  },
  {
    "id": "183248",
    "name": "St Joseph School of Nursing"
  },
  {
    "id": "183257",
    "name": "Granite State College"
  },
  {
    "id": "183275",
    "name": "Thomas More College of Liberal Arts"
  },
  {
    "id": "183488",
    "name": "Eastwick College-Hackensack"
  },
  {
    "id": "183600",
    "name": "Assumption College for Sisters"
  },
  {
    "id": "183655",
    "name": "Atlantic Cape Community College"
  },
  {
    "id": "183725",
    "name": "Fortis Institute-Wayne"
  },
  {
    "id": "183743",
    "name": "Bergen Community College"
  },
  {
    "id": "183789",
    "name": "Berkeley College-Woodland Park"
  },
  {
    "id": "183804",
    "name": "Beth Medrash Govoha"
  },
  {
    "id": "183822",
    "name": "Bloomfield College"
  },
  {
    "id": "183859",
    "name": "Brookdale Community College"
  },
  {
    "id": "183877",
    "name": "Rowan College at Burlington County"
  },
  {
    "id": "183910",
    "name": "Caldwell University"
  },
  {
    "id": "183938",
    "name": "Camden County College"
  },
  {
    "id": "183974",
    "name": "Centenary University"
  },
  {
    "id": "183983",
    "name": "Adult and Continuing Education-BCTS"
  },
  {
    "id": "184056",
    "name": "Lincoln Technical Institute-Iselin"
  },
  {
    "id": "184092",
    "name": "American Institute-Clifton"
  },
  {
    "id": "184180",
    "name": "County College of Morris"
  },
  {
    "id": "184205",
    "name": "Rowan College of South Jersey-Cumberland Campus"
  },
  {
    "id": "184348",
    "name": "Drew University"
  },
  {
    "id": "184427",
    "name": "Lincoln Technical Institute-South Plainfield"
  },
  {
    "id": "184481",
    "name": "Essex County College"
  },
  {
    "id": "184506",
    "name": "Empire Beauty School-Union"
  },
  {
    "id": "184603",
    "name": "Fairleigh Dickinson University-Metropolitan Campus"
  },
  {
    "id": "184612",
    "name": "Felician University"
  },
  {
    "id": "184694",
    "name": "Fairleigh Dickinson University-Florham Campus"
  },
  {
    "id": "184746",
    "name": "Hohokus School of Trade and Technical Sciences"
  },
  {
    "id": "184773",
    "name": "Georgian Court University"
  },
  {
    "id": "184782",
    "name": "Rowan University"
  },
  {
    "id": "184791",
    "name": "Rowan College of South Jersey-Gloucester Campus"
  },
  {
    "id": "184816",
    "name": "Empire Beauty School-Cherry Hill"
  },
  {
    "id": "184959",
    "name": "Eastwick College-Ramsey"
  },
  {
    "id": "184968",
    "name": "Holy Name Medical Center-Sister Claire Tynan School of Nursing"
  },
  {
    "id": "184995",
    "name": "Hudson County Community College"
  },
  {
    "id": "185129",
    "name": "New Jersey City University"
  },
  {
    "id": "185174",
    "name": "Joe Kubert School of Cartoon and Graphic Art"
  },
  {
    "id": "185262",
    "name": "Kean University"
  },
  {
    "id": "185396",
    "name": "Lincoln Technical Institute-Union"
  },
  {
    "id": "185466",
    "name": "Rizzieri Aveda School for Beauty and Wellness"
  },
  {
    "id": "185509",
    "name": "Mercer County Community College"
  },
  {
    "id": "185536",
    "name": "Middlesex College"
  },
  {
    "id": "185572",
    "name": "Monmouth University"
  },
  {
    "id": "185590",
    "name": "Montclair State University"
  },
  {
    "id": "185679",
    "name": "JFK Muhlenberg Harold B. and Dorothy A. Snyder Schools"
  },
  {
    "id": "185721",
    "name": "Eastwick College-Nutley"
  },
  {
    "id": "185758",
    "name": "New Brunswick Theological Seminary"
  },
  {
    "id": "185767",
    "name": "Robert Fiance Beauty Schools-West New York"
  },
  {
    "id": "185873",
    "name": "Ocean County College"
  },
  {
    "id": "185970",
    "name": "P B Cosmetology Education Center"
  },
  {
    "id": "186016",
    "name": "Parisian Beauty School"
  },
  {
    "id": "186034",
    "name": "Passaic County Community College"
  },
  {
    "id": "186052",
    "name": "Pennco Tech-Blackwood"
  },
  {
    "id": "186122",
    "name": "Princeton Theological Seminary"
  },
  {
    "id": "186186",
    "name": "Rabbinical College of America"
  },
  {
    "id": "186201",
    "name": "Ramapo College of New Jersey"
  },
  {
    "id": "186274",
    "name": "Robert Fiance Beauty Schools"
  },
  {
    "id": "186283",
    "name": "Rider University"
  },
  {
    "id": "186405",
    "name": "St Francis Medical Center-School of Radiologic Technology"
  },
  {
    "id": "186423",
    "name": "Saint Francis Medical Center School of Nursing"
  },
  {
    "id": "186432",
    "name": "Saint Peter's University"
  },
  {
    "id": "186469",
    "name": "Salem Community College"
  },
  {
    "id": "186593",
    "name": "Shore Beauty School"
  },
  {
    "id": "186618",
    "name": "Saint Elizabeth University"
  },
  {
    "id": "186645",
    "name": "Raritan Valley Community College"
  },
  {
    "id": "186876",
    "name": "Stockton University"
  },
  {
    "id": "186900",
    "name": "Talmudical Academy-New Jersey"
  },
  {
    "id": "187000",
    "name": "Aviation Institute of Maintenance-Teterboro"
  },
  {
    "id": "187046",
    "name": "Thomas Edison State University"
  },
  {
    "id": "187134",
    "name": "The College of New Jersey"
  },
  {
    "id": "187198",
    "name": "Union County College"
  },
  {
    "id": "187444",
    "name": "William Paterson University of New Jersey"
  },
  {
    "id": "187505",
    "name": "Olympian Academy of Cosmetology"
  },
  {
    "id": "187532",
    "name": "Central New Mexico Community College"
  },
  {
    "id": "187596",
    "name": "Navajo Technical University"
  },
  {
    "id": "187620",
    "name": "New Mexico State University-Dona Ana"
  },
  {
    "id": "187639",
    "name": "Clovis Community College"
  },
  {
    "id": "187648",
    "name": "Eastern New Mexico University-Main Campus"
  },
  {
    "id": "187666",
    "name": "Eastern New Mexico University-Roswell Campus"
  },
  {
    "id": "187745",
    "name": "Institute of American Indian and Alaska Native Culture and Arts Development"
  },
  {
    "id": "187897",
    "name": "New Mexico Highlands University"
  },
  {
    "id": "187903",
    "name": "New Mexico Junior College"
  },
  {
    "id": "187912",
    "name": "New Mexico Military Institute"
  },
  {
    "id": "187958",
    "name": "University of New Mexico-Gallup Campus"
  },
  {
    "id": "187967",
    "name": "New Mexico Institute of Mining and Technology"
  },
  {
    "id": "187976",
    "name": "University of New Mexico-Los Alamos Campus"
  },
  {
    "id": "187985",
    "name": "University of New Mexico-Main Campus"
  },
  {
    "id": "187994",
    "name": "New Mexico State University-Alamogordo"
  },
  {
    "id": "188003",
    "name": "Southeast New Mexico College"
  },
  {
    "id": "188021",
    "name": "New Mexico State University-Grants"
  },
  {
    "id": "188030",
    "name": "New Mexico State University-Main Campus"
  },
  {
    "id": "188049",
    "name": "University of New Mexico-Valencia County Campus"
  },
  {
    "id": "188058",
    "name": "Northern New Mexico College"
  },
  {
    "id": "188100",
    "name": "San Juan College"
  },
  {
    "id": "188137",
    "name": "Santa Fe Community College"
  },
  {
    "id": "188182",
    "name": "University of the Southwest"
  },
  {
    "id": "188207",
    "name": "Southwestern College"
  },
  {
    "id": "188216",
    "name": "Southwestern Indian Polytechnic Institute"
  },
  {
    "id": "188225",
    "name": "University of New Mexico-Taos Campus"
  },
  {
    "id": "188261",
    "name": "Mesalands Community College"
  },
  {
    "id": "188289",
    "name": "Vogue College of Cosmetology-Santa Fe"
  },
  {
    "id": "188304",
    "name": "Western New Mexico University"
  },
  {
    "id": "188340",
    "name": "Vaughn College of Aeronautics and Technology"
  },
  {
    "id": "188429",
    "name": "Adelphi University"
  },
  {
    "id": "188438",
    "name": "SUNY Adirondack"
  },
  {
    "id": "188517",
    "name": "Bryant & Stratton College-Albany"
  },
  {
    "id": "188526",
    "name": "Albany College of Pharmacy and Health Sciences"
  },
  {
    "id": "188535",
    "name": "Albany Law School"
  },
  {
    "id": "188580",
    "name": "Albany Medical College"
  },
  {
    "id": "188641",
    "name": "Alfred University"
  },
  {
    "id": "188650",
    "name": "Allen School-Brooklyn"
  },
  {
    "id": "188669",
    "name": "The Ailey School"
  },
  {
    "id": "188678",
    "name": "American Academy of Dramatic Arts-New York"
  },
  {
    "id": "188687",
    "name": "American Academy McAllister Institute of Funeral Service"
  },
  {
    "id": "188696",
    "name": "Joffrey Ballet School"
  },
  {
    "id": "188854",
    "name": "American Musical and Dramatic Academy"
  },
  {
    "id": "188890",
    "name": "Apex Technical School"
  },
  {
    "id": "188915",
    "name": "Arnot Ogden Medical Center"
  },
  {
    "id": "188942",
    "name": "Associated Beth Rivkah Schools"
  },
  {
    "id": "189015",
    "name": "Bank Street College of Education"
  },
  {
    "id": "189088",
    "name": "Bard College"
  },
  {
    "id": "189219",
    "name": "Berk Trade and Business School"
  },
  {
    "id": "189228",
    "name": "Berkeley College-New York"
  },
  {
    "id": "189255",
    "name": "Berkowits School of Electrolysis"
  },
  {
    "id": "189273",
    "name": "Beth Hamedrash Shaarei Yosher Institute"
  },
  {
    "id": "189282",
    "name": "Phillips School of Nursing at Mount Sinai Beth Israel"
  },
  {
    "id": "189404",
    "name": "Brittany Beauty Academy"
  },
  {
    "id": "189413",
    "name": "Boricua College"
  },
  {
    "id": "189468",
    "name": "Brittany Beauty Academy"
  },
  {
    "id": "189477",
    "name": "American Beauty School"
  },
  {
    "id": "189501",
    "name": "Brooklyn Law School"
  },
  {
    "id": "189547",
    "name": "SUNY Broome Community College"
  },
  {
    "id": "189565",
    "name": "Bryant & Stratton College-Syracuse North"
  },
  {
    "id": "189583",
    "name": "Bryant & Stratton College-Buffalo"
  },
  {
    "id": "189592",
    "name": "Bryant & Stratton College-Greece"
  },
  {
    "id": "189705",
    "name": "Canisius College"
  },
  {
    "id": "189714",
    "name": "Capri Cosmetology Learning Centers"
  },
  {
    "id": "189811",
    "name": "St Paul's School of Nursing-Queens"
  },
  {
    "id": "189839",
    "name": "Cayuga County Community College"
  },
  {
    "id": "189848",
    "name": "Cazenovia College"
  },
  {
    "id": "189857",
    "name": "Central Yeshiva Tomchei Tmimim Lubavitz"
  },
  {
    "id": "189990",
    "name": "Christine Valmy International School for Esthetics Skin Care & Makeup"
  },
  {
    "id": "190008",
    "name": "Circle in the Square Theatre School"
  },
  {
    "id": "190053",
    "name": "Clinton Community College"
  },
  {
    "id": "190071",
    "name": "Cochran School of Nursing"
  },
  {
    "id": "190080",
    "name": "Colgate Rochester Crozer Divinity School"
  },
  {
    "id": "190114",
    "name": "Metropolitan College of New York"
  },
  {
    "id": "190169",
    "name": "Columbia-Greene Community College"
  },
  {
    "id": "190239",
    "name": "Empire Beauty School-Queens"
  },
  {
    "id": "190318",
    "name": "Continental School of Beauty Culture-Rochester"
  },
  {
    "id": "190372",
    "name": "The Cooper Union for the Advancement of Science and Art"
  },
  {
    "id": "190424",
    "name": "Weill Medical College of Cornell University"
  },
  {
    "id": "190442",
    "name": "SUNY Corning Community College"
  },
  {
    "id": "190451",
    "name": "Pomeroy College of Nursing at Crouse Hospital"
  },
  {
    "id": "190503",
    "name": "Culinary Institute of America"
  },
  {
    "id": "190512",
    "name": "CUNY Bernard M Baruch College"
  },
  {
    "id": "190521",
    "name": "CUNY Borough of Manhattan Community College"
  },
  {
    "id": "190530",
    "name": "CUNY Bronx Community College"
  },
  {
    "id": "190549",
    "name": "CUNY Brooklyn College"
  },
  {
    "id": "190558",
    "name": "College of Staten Island CUNY"
  },
  {
    "id": "190567",
    "name": "CUNY City College"
  },
  {
    "id": "190576",
    "name": "CUNY Graduate School and University Center"
  },
  {
    "id": "190585",
    "name": "CUNY Hostos Community College"
  },
  {
    "id": "190594",
    "name": "CUNY Hunter College"
  },
  {
    "id": "190600",
    "name": "CUNY John Jay College of Criminal Justice"
  },
  {
    "id": "190619",
    "name": "CUNY Kingsborough Community College"
  },
  {
    "id": "190628",
    "name": "CUNY LaGuardia Community College"
  },
  {
    "id": "190637",
    "name": "CUNY Lehman College"
  },
  {
    "id": "190646",
    "name": "CUNY Medgar Evers College"
  },
  {
    "id": "190655",
    "name": "CUNY New York City College of Technology"
  },
  {
    "id": "190664",
    "name": "CUNY Queens College"
  },
  {
    "id": "190673",
    "name": "CUNY Queensborough Community College"
  },
  {
    "id": "190682",
    "name": "CUNY School of Law"
  },
  {
    "id": "190691",
    "name": "CUNY York College"
  },
  {
    "id": "190707",
    "name": "CVPH Medical Center School of Radiologic Technology"
  },
  {
    "id": "190716",
    "name": "D'Youville University"
  },
  {
    "id": "190725",
    "name": "Daemen University"
  },
  {
    "id": "190752",
    "name": "Yeshiva of Far Rockaway Derech Ayson Rabbinical Seminary"
  },
  {
    "id": "190761",
    "name": "Dominican University New York"
  },
  {
    "id": "190840",
    "name": "Dutchess Community College"
  },
  {
    "id": "190956",
    "name": "Belanger School of Nursing"
  },
  {
    "id": "190983",
    "name": "Elmira College"
  },
  {
    "id": "191083",
    "name": "Erie Community College"
  },
  {
    "id": "191126",
    "name": "Fashion Institute of Technology"
  },
  {
    "id": "191199",
    "name": "Finger Lakes Community College"
  },
  {
    "id": "191205",
    "name": "Five Towns College"
  },
  {
    "id": "191302",
    "name": "Fulton-Montgomery Community College"
  },
  {
    "id": "191311",
    "name": "Gemological Institute of America-New York"
  },
  {
    "id": "191320",
    "name": "The General Theological Seminary"
  },
  {
    "id": "191339",
    "name": "Genesee Community College"
  },
  {
    "id": "191533",
    "name": "Hartwick College"
  },
  {
    "id": "191597",
    "name": "Helene Fuld College of Nursing"
  },
  {
    "id": "191612",
    "name": "Herkimer County Community College"
  },
  {
    "id": "191621",
    "name": "Hilbert College"
  },
  {
    "id": "191630",
    "name": "Hobart William Smith Colleges"
  },
  {
    "id": "191649",
    "name": "Hofstra University"
  },
  {
    "id": "191676",
    "name": "Houghton University"
  },
  {
    "id": "191719",
    "name": "Hudson Valley Community College"
  },
  {
    "id": "191728",
    "name": "Hunter Business School"
  },
  {
    "id": "191931",
    "name": "Iona University"
  },
  {
    "id": "191940",
    "name": "Isabella Graham Hart School of Practical Nursing"
  },
  {
    "id": "191959",
    "name": "Island Drafting and Technical Institute"
  },
  {
    "id": "191968",
    "name": "Ithaca College"
  },
  {
    "id": "191986",
    "name": "Jamestown Community College"
  },
  {
    "id": "192004",
    "name": "Jamestown Business College"
  },
  {
    "id": "192022",
    "name": "Jefferson Community College"
  },
  {
    "id": "192040",
    "name": "Jewish Theological Seminary of America"
  },
  {
    "id": "192110",
    "name": "The Juilliard School"
  },
  {
    "id": "192165",
    "name": "Kehilath Yakov Rabbinical Seminary"
  },
  {
    "id": "192192",
    "name": "Keuka College"
  },
  {
    "id": "192271",
    "name": "LIM College"
  },
  {
    "id": "192323",
    "name": "Le Moyne College"
  },
  {
    "id": "192448",
    "name": "Long Island University"
  },
  {
    "id": "192484",
    "name": "Long Island Beauty School-Hempstead"
  },
  {
    "id": "192509",
    "name": "Long Island Business Institute"
  },
  {
    "id": "192624",
    "name": "Machzikei Hadath Rabbinical College"
  },
  {
    "id": "192688",
    "name": "Mandl School-The College of Allied Health"
  },
  {
    "id": "192703",
    "name": "Manhattan College"
  },
  {
    "id": "192712",
    "name": "Manhattan School of Music"
  },
  {
    "id": "192749",
    "name": "Manhattanville College"
  },
  {
    "id": "192785",
    "name": "Maria College of Albany"
  },
  {
    "id": "192800",
    "name": "Marion S Whelan School of Nursing of Geneva General Hospital"
  },
  {
    "id": "192819",
    "name": "Marist College"
  },
  {
    "id": "192864",
    "name": "Marymount Manhattan College"
  },
  {
    "id": "192925",
    "name": "Medaille University"
  },
  {
    "id": "192961",
    "name": "St. Peter's Hospital College of Nursing"
  },
  {
    "id": "192970",
    "name": "Memorial Hospital School of Radiation Therapy Technology"
  },
  {
    "id": "193016",
    "name": "Mercy College"
  },
  {
    "id": "193052",
    "name": "Mesivta Torah Vodaath Rabbinical Seminary"
  },
  {
    "id": "193061",
    "name": "Mesivta of Eastern Parkway-Yeshiva Zichron Meilech"
  },
  {
    "id": "193070",
    "name": "Mesivtha Tifereth Jerusalem of America"
  },
  {
    "id": "193186",
    "name": "Midway Paris Beauty School"
  },
  {
    "id": "193201",
    "name": "Mildred Elley School-Albany Campus"
  },
  {
    "id": "193247",
    "name": "Mirrer Yeshiva Cent Institute"
  },
  {
    "id": "193265",
    "name": "Modern Welding School"
  },
  {
    "id": "193283",
    "name": "Mohawk Valley Community College"
  },
  {
    "id": "193292",
    "name": "Molloy College"
  },
  {
    "id": "193308",
    "name": "Monroe College"
  },
  {
    "id": "193326",
    "name": "Monroe Community College"
  },
  {
    "id": "193353",
    "name": "Mount Saint Mary College"
  },
  {
    "id": "193380",
    "name": "Montefiore School of Nursing"
  },
  {
    "id": "193399",
    "name": "College of Mount Saint Vincent"
  },
  {
    "id": "193405",
    "name": "Icahn School of Medicine at Mount Sinai"
  },
  {
    "id": "193478",
    "name": "Nassau Community College"
  },
  {
    "id": "193584",
    "name": "Nazareth College"
  },
  {
    "id": "193609",
    "name": "Neighborhood Playhouse School of the Theater"
  },
  {
    "id": "193672",
    "name": "The New School Center for Media"
  },
  {
    "id": "193751",
    "name": "Northeast College of Health Sciences"
  },
  {
    "id": "193821",
    "name": "New York Law School"
  },
  {
    "id": "193830",
    "name": "New York Medical College"
  },
  {
    "id": "193858",
    "name": "New York School for Medical and Dental Assistants"
  },
  {
    "id": "193894",
    "name": "New York Theological Seminary"
  },
  {
    "id": "193946",
    "name": "Niagara County Community College"
  },
  {
    "id": "193973",
    "name": "Niagara University"
  },
  {
    "id": "193991",
    "name": "Empire Beauty School-Peekskill"
  },
  {
    "id": "194028",
    "name": "North Country Community College"
  },
  {
    "id": "194073",
    "name": "New York College of Podiatric Medicine"
  },
  {
    "id": "194091",
    "name": "New York Institute of Technology"
  },
  {
    "id": "194116",
    "name": "New York School of Interior Design"
  },
  {
    "id": "194161",
    "name": "Alliance University"
  },
  {
    "id": "194189",
    "name": "Ohr Hameir Theological Seminary"
  },
  {
    "id": "194222",
    "name": "Onondaga Community College"
  },
  {
    "id": "194240",
    "name": "Orange County Community College"
  },
  {
    "id": "194259",
    "name": "Otsego Area BOCES-Practical Nursing Program"
  },
  {
    "id": "194310",
    "name": "Pace University"
  },
  {
    "id": "194392",
    "name": "Paul Smiths College of Arts and Science"
  },
  {
    "id": "194435",
    "name": "Continental School of Beauty Culture-West Seneca"
  },
  {
    "id": "194499",
    "name": "Plaza College"
  },
  {
    "id": "194569",
    "name": "Davis College"
  },
  {
    "id": "194578",
    "name": "Pratt Institute-Main"
  },
  {
    "id": "194657",
    "name": "Rabbinical Academy Mesivta Rabbi Chaim Berlin"
  },
  {
    "id": "194666",
    "name": "Rabbinical College Bobover Yeshiva Bnei Zion"
  },
  {
    "id": "194693",
    "name": "Rabbinical College Beth Shraga"
  },
  {
    "id": "194718",
    "name": "Rabbinical Seminary Mkor Chaim"
  },
  {
    "id": "194736",
    "name": "Rabbinical College of Long Island"
  },
  {
    "id": "194763",
    "name": "Rabbinical Seminary of America"
  },
  {
    "id": "194958",
    "name": "Roberts Wesleyan University"
  },
  {
    "id": "195049",
    "name": "The Rockefeller University"
  },
  {
    "id": "195058",
    "name": "Rockland Community College"
  },
  {
    "id": "195067",
    "name": "Empire Beauty School-Manhattan"
  },
  {
    "id": "195128",
    "name": "Russell Sage College"
  },
  {
    "id": "195155",
    "name": "St Bernard's School of Theology and Ministry"
  },
  {
    "id": "195164",
    "name": "St Bonaventure University"
  },
  {
    "id": "195173",
    "name": "St. Francis College"
  },
  {
    "id": "195191",
    "name": "St. Joseph's College of Nursing"
  },
  {
    "id": "195216",
    "name": "St Lawrence University"
  },
  {
    "id": "195234",
    "name": "The College of Saint Rose"
  },
  {
    "id": "195243",
    "name": "St. Thomas Aquinas College"
  },
  {
    "id": "195289",
    "name": "Samaritan Hospital School of Nursing"
  },
  {
    "id": "195304",
    "name": "Sarah Lawrence College"
  },
  {
    "id": "195322",
    "name": "Schenectady County Community College"
  },
  {
    "id": "195438",
    "name": "Sh'or Yoshuv Rabbinical College"
  },
  {
    "id": "195474",
    "name": "Siena College"
  },
  {
    "id": "195526",
    "name": "Skidmore College"
  },
  {
    "id": "195544",
    "name": "St. Joseph's University-New York"
  },
  {
    "id": "195580",
    "name": "Saint Vladimirs Orthodox Theological Seminary"
  },
  {
    "id": "195702",
    "name": "Saint Elizabeth College of Nursing"
  },
  {
    "id": "195720",
    "name": "St. John Fisher University"
  },
  {
    "id": "195784",
    "name": "St Paul's School of Nursing-Staten Island"
  },
  {
    "id": "195809",
    "name": "St. John's University-New York"
  },
  {
    "id": "195988",
    "name": "Sullivan County Community College"
  },
  {
    "id": "196006",
    "name": "SUNY College of Technology at Alfred"
  },
  {
    "id": "196015",
    "name": "SUNY College of Technology at Canton"
  },
  {
    "id": "196024",
    "name": "SUNY College of Technology at Delhi"
  },
  {
    "id": "196033",
    "name": "SUNY College of Agriculture and Technology at Cobleskill"
  },
  {
    "id": "196042",
    "name": "Farmingdale State College"
  },
  {
    "id": "196051",
    "name": "SUNY Morrisville"
  },
  {
    "id": "196060",
    "name": "SUNY at Albany"
  },
  {
    "id": "196112",
    "name": "SUNY Polytechnic Institute"
  },
  {
    "id": "196121",
    "name": "SUNY Brockport"
  },
  {
    "id": "196130",
    "name": "SUNY Buffalo State University"
  },
  {
    "id": "196149",
    "name": "SUNY Cortland"
  },
  {
    "id": "196158",
    "name": "SUNY at Fredonia"
  },
  {
    "id": "196167",
    "name": "SUNY College at Geneseo"
  },
  {
    "id": "196176",
    "name": "State University of New York at New Paltz"
  },
  {
    "id": "196185",
    "name": "SUNY Oneonta"
  },
  {
    "id": "196194",
    "name": "State University of New York at Oswego"
  },
  {
    "id": "196200",
    "name": "SUNY College at Potsdam"
  },
  {
    "id": "196219",
    "name": "SUNY at Purchase College"
  },
  {
    "id": "196228",
    "name": "SUNY College of Optometry"
  },
  {
    "id": "196237",
    "name": "SUNY College at Old Westbury"
  },
  {
    "id": "196246",
    "name": "SUNY College at Plattsburgh"
  },
  {
    "id": "196255",
    "name": "SUNY Downstate Health Sciences University"
  },
  {
    "id": "196264",
    "name": "SUNY Empire State College"
  },
  {
    "id": "196291",
    "name": "SUNY Maritime College"
  },
  {
    "id": "196307",
    "name": "Upstate Medical University"
  },
  {
    "id": "196389",
    "name": "Swedish Institute a College of Health Sciences"
  },
  {
    "id": "196431",
    "name": "Talmudical Seminary Oholei Torah"
  },
  {
    "id": "196440",
    "name": "Talmudical Institute of Upstate New York"
  },
  {
    "id": "196468",
    "name": "Teachers College at Columbia University"
  },
  {
    "id": "196565",
    "name": "Tompkins Cortland Community College"
  },
  {
    "id": "196583",
    "name": "Torah Temimah Talmudical Seminary"
  },
  {
    "id": "196592",
    "name": "Touro University"
  },
  {
    "id": "196653",
    "name": "Trocaire College"
  },
  {
    "id": "196680",
    "name": "Excelsior University"
  },
  {
    "id": "196699",
    "name": "Ulster County Community College"
  },
  {
    "id": "196866",
    "name": "Union College"
  },
  {
    "id": "196884",
    "name": "Union Theological Seminary in the City of New York"
  },
  {
    "id": "197018",
    "name": "United Talmudical Seminary"
  },
  {
    "id": "197027",
    "name": "United States Merchant Marine Academy"
  },
  {
    "id": "197045",
    "name": "Utica University"
  },
  {
    "id": "197142",
    "name": "Villa Maria College"
  },
  {
    "id": "197151",
    "name": "School of Visual Arts"
  },
  {
    "id": "197197",
    "name": "Wagner College"
  },
  {
    "id": "197221",
    "name": "Webb Institute"
  },
  {
    "id": "197230",
    "name": "Wells College"
  },
  {
    "id": "197285",
    "name": "The College of Westchester"
  },
  {
    "id": "197294",
    "name": "SUNY Westchester Community College"
  },
  {
    "id": "197337",
    "name": "Westchester School of Beauty Culture"
  },
  {
    "id": "197531",
    "name": "Word of Life Bible Institute"
  },
  {
    "id": "197601",
    "name": "Yeshiva Karlin Stolin"
  },
  {
    "id": "197647",
    "name": "Yeshiva Derech Chaim"
  },
  {
    "id": "197674",
    "name": "Yeshiva of Nitra Rabbinical College"
  },
  {
    "id": "197692",
    "name": "Yeshivath Shaar Hatorah"
  },
  {
    "id": "197735",
    "name": "Yeshivath Viznitz"
  },
  {
    "id": "197744",
    "name": "Yeshivath Zichron Moshe"
  },
  {
    "id": "197814",
    "name": "College of the Albemarle"
  },
  {
    "id": "197850",
    "name": "South Piedmont Community College"
  },
  {
    "id": "197869",
    "name": "Appalachian State University"
  },
  {
    "id": "197887",
    "name": "Asheville-Buncombe Technical Community College"
  },
  {
    "id": "197911",
    "name": "Barton College"
  },
  {
    "id": "197966",
    "name": "Beaufort County Community College"
  },
  {
    "id": "197984",
    "name": "Belmont Abbey College"
  },
  {
    "id": "197993",
    "name": "Bennett College"
  },
  {
    "id": "198011",
    "name": "Bladen Community College"
  },
  {
    "id": "198039",
    "name": "Blue Ridge Community College"
  },
  {
    "id": "198066",
    "name": "Brevard College"
  },
  {
    "id": "198084",
    "name": "Brunswick Community College"
  },
  {
    "id": "198109",
    "name": "Cabarrus College of Health Sciences"
  },
  {
    "id": "198118",
    "name": "Caldwell Community College and Technical Institute"
  },
  {
    "id": "198136",
    "name": "Campbell University"
  },
  {
    "id": "198154",
    "name": "Cape Fear Community College"
  },
  {
    "id": "198206",
    "name": "Carteret Community College"
  },
  {
    "id": "198215",
    "name": "Catawba College"
  },
  {
    "id": "198233",
    "name": "Catawba Valley Community College"
  },
  {
    "id": "198251",
    "name": "Central Carolina Community College"
  },
  {
    "id": "198260",
    "name": "Central Piedmont Community College"
  },
  {
    "id": "198303",
    "name": "Chowan University"
  },
  {
    "id": "198321",
    "name": "Cleveland Community College"
  },
  {
    "id": "198330",
    "name": "Coastal Carolina Community College"
  },
  {
    "id": "198367",
    "name": "Craven Community College"
  },
  {
    "id": "198376",
    "name": "Davidson-Davie Community College"
  },
  {
    "id": "198455",
    "name": "Durham Technical Community College"
  },
  {
    "id": "198464",
    "name": "East Carolina University"
  },
  {
    "id": "198491",
    "name": "Edgecombe Community College"
  },
  {
    "id": "198507",
    "name": "Elizabeth City State University"
  },
  {
    "id": "198534",
    "name": "Fayetteville Technical Community College"
  },
  {
    "id": "198543",
    "name": "Fayetteville State University"
  },
  {
    "id": "198552",
    "name": "Forsyth Technical Community College"
  },
  {
    "id": "198561",
    "name": "Gardner-Webb University"
  },
  {
    "id": "198570",
    "name": "Gaston College"
  },
  {
    "id": "198598",
    "name": "Greensboro College"
  },
  {
    "id": "198613",
    "name": "Guilford College"
  },
  {
    "id": "198622",
    "name": "Guilford Technical Community College"
  },
  {
    "id": "198640",
    "name": "Halifax Community College"
  },
  {
    "id": "198668",
    "name": "Haywood Community College"
  },
  {
    "id": "198677",
    "name": "Heritage Bible College"
  },
  {
    "id": "198695",
    "name": "High Point University"
  },
  {
    "id": "198710",
    "name": "Isothermal Community College"
  },
  {
    "id": "198729",
    "name": "James Sprunt Community College"
  },
  {
    "id": "198756",
    "name": "Johnson C Smith University"
  },
  {
    "id": "198774",
    "name": "Johnston Community College"
  },
  {
    "id": "198808",
    "name": "Lees-McRae College"
  },
  {
    "id": "198817",
    "name": "Lenoir Community College"
  },
  {
    "id": "198835",
    "name": "Lenoir-Rhyne University"
  },
  {
    "id": "198844",
    "name": "Leons Beauty School Inc"
  },
  {
    "id": "198862",
    "name": "Livingstone College"
  },
  {
    "id": "198871",
    "name": "Louisburg College"
  },
  {
    "id": "198899",
    "name": "Mars Hill University"
  },
  {
    "id": "198905",
    "name": "Martin Community College"
  },
  {
    "id": "198914",
    "name": "Mayland Community College"
  },
  {
    "id": "198923",
    "name": "McDowell Technical Community College"
  },
  {
    "id": "198950",
    "name": "Meredith College"
  },
  {
    "id": "198969",
    "name": "Methodist University"
  },
  {
    "id": "198978",
    "name": "Miller-Motte College-Wilmington"
  },
  {
    "id": "198987",
    "name": "Mitchell Community College"
  },
  {
    "id": "199005",
    "name": "Mitchells Academy"
  },
  {
    "id": "199023",
    "name": "Montgomery Community College"
  },
  {
    "id": "199032",
    "name": "Montreat College"
  },
  {
    "id": "199069",
    "name": "University of Mount Olive"
  },
  {
    "id": "199078",
    "name": "College of Wilmington"
  },
  {
    "id": "199087",
    "name": "Nash Community College"
  },
  {
    "id": "199102",
    "name": "North Carolina A & T State University"
  },
  {
    "id": "199111",
    "name": "University of North Carolina at Asheville"
  },
  {
    "id": "199139",
    "name": "University of North Carolina at Charlotte"
  },
  {
    "id": "199148",
    "name": "University of North Carolina at Greensboro"
  },
  {
    "id": "199157",
    "name": "North Carolina Central University"
  },
  {
    "id": "199184",
    "name": "University of North Carolina School of the Arts"
  },
  {
    "id": "199209",
    "name": "North Carolina Wesleyan University"
  },
  {
    "id": "199218",
    "name": "University of North Carolina Wilmington"
  },
  {
    "id": "199263",
    "name": "Pamlico Community College"
  },
  {
    "id": "199272",
    "name": "William Peace University"
  },
  {
    "id": "199281",
    "name": "University of North Carolina at Pembroke"
  },
  {
    "id": "199306",
    "name": "Pfeiffer University"
  },
  {
    "id": "199324",
    "name": "Piedmont Community College"
  },
  {
    "id": "199333",
    "name": "Pitt Community College"
  },
  {
    "id": "199412",
    "name": "Queens University of Charlotte"
  },
  {
    "id": "199421",
    "name": "Randolph Community College"
  },
  {
    "id": "199449",
    "name": "Richmond Community College"
  },
  {
    "id": "199458",
    "name": "Mid-Atlantic Christian University"
  },
  {
    "id": "199467",
    "name": "Roanoke-Chowan Community College"
  },
  {
    "id": "199476",
    "name": "Robeson Community College"
  },
  {
    "id": "199485",
    "name": "Rockingham Community College"
  },
  {
    "id": "199494",
    "name": "Rowan-Cabarrus Community College"
  },
  {
    "id": "199582",
    "name": "Saint Augustine's University"
  },
  {
    "id": "199607",
    "name": "Salem College"
  },
  {
    "id": "199625",
    "name": "Sampson Community College"
  },
  {
    "id": "199634",
    "name": "Sandhills Community College"
  },
  {
    "id": "199643",
    "name": "Shaw University"
  },
  {
    "id": "199689",
    "name": "Paul Mitchell the School-Fayetteville"
  },
  {
    "id": "199698",
    "name": "St. Andrews University"
  },
  {
    "id": "199722",
    "name": "Southeastern Community College"
  },
  {
    "id": "199731",
    "name": "Southwestern Community College"
  },
  {
    "id": "199740",
    "name": "Stanly Community College"
  },
  {
    "id": "199759",
    "name": "Southeastern Baptist Theological Seminary"
  },
  {
    "id": "199768",
    "name": "Surry Community College"
  },
  {
    "id": "199786",
    "name": "Alamance Community College"
  },
  {
    "id": "199795",
    "name": "Tri-County Community College"
  },
  {
    "id": "199838",
    "name": "Vance-Granville Community College"
  },
  {
    "id": "199856",
    "name": "Wake Technical Community College"
  },
  {
    "id": "199865",
    "name": "Warren Wilson College"
  },
  {
    "id": "199883",
    "name": "Watts School of Nursing"
  },
  {
    "id": "199892",
    "name": "Wayne Community College"
  },
  {
    "id": "199908",
    "name": "Western Piedmont Community College"
  },
  {
    "id": "199926",
    "name": "Wilkes Community College"
  },
  {
    "id": "199953",
    "name": "Wilson Community College"
  },
  {
    "id": "199962",
    "name": "Wingate University"
  },
  {
    "id": "199971",
    "name": "Carolina Christian College"
  },
  {
    "id": "199980",
    "name": "Winston Salem Barber School"
  },
  {
    "id": "199999",
    "name": "Winston-Salem State University"
  },
  {
    "id": "200004",
    "name": "Western Carolina University"
  },
  {
    "id": "200013",
    "name": "Rasmussen University-North Dakota"
  },
  {
    "id": "200022",
    "name": "Bismarck State College"
  },
  {
    "id": "200059",
    "name": "Dickinson State University"
  },
  {
    "id": "200086",
    "name": "Nueta Hidatsa Sahnish College"
  },
  {
    "id": "200110",
    "name": "Josef's School of Hair Skin & Body-Grand Forks"
  },
  {
    "id": "200156",
    "name": "University of Jamestown"
  },
  {
    "id": "200192",
    "name": "Lake Region State College"
  },
  {
    "id": "200208",
    "name": "Cankdeska Cikana Community College"
  },
  {
    "id": "200217",
    "name": "University of Mary"
  },
  {
    "id": "200226",
    "name": "Mayville State University"
  },
  {
    "id": "200253",
    "name": "Minot State University"
  },
  {
    "id": "200280",
    "name": "University of North Dakota"
  },
  {
    "id": "200305",
    "name": "North Dakota State College of Science"
  },
  {
    "id": "200314",
    "name": "Dakota College at Bottineau"
  },
  {
    "id": "200332",
    "name": "North Dakota State University-Main Campus"
  },
  {
    "id": "200341",
    "name": "Williston State College"
  },
  {
    "id": "200466",
    "name": "Sitting Bull College"
  },
  {
    "id": "200484",
    "name": "Trinity Bible College and Graduate School"
  },
  {
    "id": "200527",
    "name": "Turtle Mountain Community College"
  },
  {
    "id": "200554",
    "name": "United Tribes Technical College"
  },
  {
    "id": "200572",
    "name": "Valley City State University"
  },
  {
    "id": "200590",
    "name": "ETI Technical College"
  },
  {
    "id": "200697",
    "name": "Air Force Institute of Technology-Graduate School of Engineering & Management"
  },
  {
    "id": "200785",
    "name": "Herzing University-Akron"
  },
  {
    "id": "200800",
    "name": "University of Akron Main Campus"
  },
  {
    "id": "200846",
    "name": "University of Akron Wayne College"
  },
  {
    "id": "200873",
    "name": "Allegheny Wesleyan College"
  },
  {
    "id": "201034",
    "name": "Apollo Career Center"
  },
  {
    "id": "201061",
    "name": "Art Academy of Cincinnati"
  },
  {
    "id": "201098",
    "name": "Ashland County-West Holmes Career Center"
  },
  {
    "id": "201104",
    "name": "Ashland University"
  },
  {
    "id": "201131",
    "name": "Ashtabula County Technical and Career Campus"
  },
  {
    "id": "201140",
    "name": "Athenaeum of Ohio"
  },
  {
    "id": "201159",
    "name": "Auburn Career Center"
  },
  {
    "id": "201177",
    "name": "Aultman College of Nursing and Health Sciences"
  },
  {
    "id": "201195",
    "name": "Baldwin Wallace University"
  },
  {
    "id": "201283",
    "name": "Belmont College"
  },
  {
    "id": "201371",
    "name": "Bluffton University"
  },
  {
    "id": "201432",
    "name": "Bowling Green State University-Firelands"
  },
  {
    "id": "201441",
    "name": "Bowling Green State University-Main Campus"
  },
  {
    "id": "201469",
    "name": "Bryant & Stratton College-Parma"
  },
  {
    "id": "201478",
    "name": "Buckeye Hills Career Center"
  },
  {
    "id": "201496",
    "name": "Butler Technology and Career Development Schools"
  },
  {
    "id": "201539",
    "name": "Canton City Schools Adult Career and Technical Education"
  },
  {
    "id": "201548",
    "name": "Capital University"
  },
  {
    "id": "201654",
    "name": "Cedarville University"
  },
  {
    "id": "201672",
    "name": "Central Ohio Technical College"
  },
  {
    "id": "201681",
    "name": "Central School of Practical Nursing"
  },
  {
    "id": "201690",
    "name": "Central State University"
  },
  {
    "id": "201751",
    "name": "Chatfield College"
  },
  {
    "id": "201803",
    "name": "Choffin Career and Technical Center"
  },
  {
    "id": "201821",
    "name": "The Christ College of Nursing and Health Sciences"
  },
  {
    "id": "201867",
    "name": "Cincinnati College of Mortuary Science"
  },
  {
    "id": "201885",
    "name": "University of Cincinnati-Main Campus"
  },
  {
    "id": "201928",
    "name": "Cincinnati State Technical and Community College"
  },
  {
    "id": "201946",
    "name": "University of Cincinnati-Clermont College"
  },
  {
    "id": "201955",
    "name": "University of Cincinnati-Blue Ash College"
  },
  {
    "id": "201964",
    "name": "Ohio Christian University"
  },
  {
    "id": "201973",
    "name": "Clark State College"
  },
  {
    "id": "202046",
    "name": "Cleveland Institute of Art"
  },
  {
    "id": "202073",
    "name": "Cleveland Institute of Music"
  },
  {
    "id": "202134",
    "name": "Cleveland State University"
  },
  {
    "id": "202152",
    "name": "Columbiana County Career and Technical Center"
  },
  {
    "id": "202170",
    "name": "Columbus College of Art and Design"
  },
  {
    "id": "202222",
    "name": "Columbus State Community College"
  },
  {
    "id": "202356",
    "name": "Cuyahoga Community College District"
  },
  {
    "id": "202435",
    "name": "Davis College"
  },
  {
    "id": "202453",
    "name": "Dayton Barber College"
  },
  {
    "id": "202514",
    "name": "Defiance College"
  },
  {
    "id": "202523",
    "name": "Denison University"
  },
  {
    "id": "202648",
    "name": "Edison State Community College"
  },
  {
    "id": "202657",
    "name": "EHOVE Career Center"
  },
  {
    "id": "202763",
    "name": "The University of Findlay"
  },
  {
    "id": "202806",
    "name": "Franklin University"
  },
  {
    "id": "202888",
    "name": "Gerbers Akron Beauty School"
  },
  {
    "id": "202903",
    "name": "Gods Bible School and College"
  },
  {
    "id": "202912",
    "name": "Good Samaritan College of Nursing and Health Science"
  },
  {
    "id": "202949",
    "name": "Great Oaks Career Campuses"
  },
  {
    "id": "202985",
    "name": "Hannah E Mullins School of Practical Nursing"
  },
  {
    "id": "203030",
    "name": "Adult and Community Education-Hudson"
  },
  {
    "id": "203067",
    "name": "Hebrew Union College-Jewish Institute of Religion"
  },
  {
    "id": "203085",
    "name": "Heidelberg University"
  },
  {
    "id": "203128",
    "name": "Hiram College"
  },
  {
    "id": "203146",
    "name": "Hobart Institute of Welding Technology"
  },
  {
    "id": "203155",
    "name": "Hocking College"
  },
  {
    "id": "203234",
    "name": "Ross Medical Education Center-Cincinnati"
  },
  {
    "id": "203289",
    "name": "International College of Broadcasting"
  },
  {
    "id": "203331",
    "name": "Eastern Gateway Community College"
  },
  {
    "id": "203368",
    "name": "John Carroll University"
  },
  {
    "id": "203386",
    "name": "Hondros College of Nursing"
  },
  {
    "id": "203438",
    "name": "Casal Aveda Institute"
  },
  {
    "id": "203447",
    "name": "Kent State University at Ashtabula"
  },
  {
    "id": "203456",
    "name": "Kent State University at East Liverpool"
  },
  {
    "id": "203465",
    "name": "Kent State University at Stark"
  },
  {
    "id": "203474",
    "name": "Kent State University at Trumbull"
  },
  {
    "id": "203483",
    "name": "Kent State University at Tuscarawas"
  },
  {
    "id": "203492",
    "name": "Kent State University at Salem"
  },
  {
    "id": "203517",
    "name": "Kent State University at Kent"
  },
  {
    "id": "203526",
    "name": "Kent State University at Geauga"
  },
  {
    "id": "203535",
    "name": "Kenyon College"
  },
  {
    "id": "203544",
    "name": "Kettering College"
  },
  {
    "id": "203580",
    "name": "Lake Erie College"
  },
  {
    "id": "203599",
    "name": "Lakeland Community College"
  },
  {
    "id": "203614",
    "name": "O C Collins Career Center"
  },
  {
    "id": "203678",
    "name": "James A. Rhodes State College"
  },
  {
    "id": "203720",
    "name": "Ohio Business College-Sheffield"
  },
  {
    "id": "203739",
    "name": "Ohio Business College-Sandusky"
  },
  {
    "id": "203748",
    "name": "Lorain County Community College"
  },
  {
    "id": "203757",
    "name": "Lourdes University"
  },
  {
    "id": "203775",
    "name": "Malone University"
  },
  {
    "id": "203845",
    "name": "Marietta College"
  },
  {
    "id": "203881",
    "name": "Marion Technical College"
  },
  {
    "id": "203942",
    "name": "Medina County Career Center"
  },
  {
    "id": "203960",
    "name": "Mercy College of Ohio"
  },
  {
    "id": "203997",
    "name": "Methodist Theological School in Ohio"
  },
  {
    "id": "204006",
    "name": "Miami University-Hamilton"
  },
  {
    "id": "204015",
    "name": "Miami University-Middletown"
  },
  {
    "id": "204121",
    "name": "Moler Hollywood Beauty Academy"
  },
  {
    "id": "204158",
    "name": "Miami Valley Career Technology Center"
  },
  {
    "id": "204176",
    "name": "Mount Carmel College of Nursing"
  },
  {
    "id": "204185",
    "name": "University of Mount Union"
  },
  {
    "id": "204194",
    "name": "Mount Vernon Nazarene University"
  },
  {
    "id": "204200",
    "name": "Mount Saint Joseph University"
  },
  {
    "id": "204255",
    "name": "Zane State College"
  },
  {
    "id": "204264",
    "name": "Muskingum University"
  },
  {
    "id": "204334",
    "name": "National Beauty College"
  },
  {
    "id": "204422",
    "name": "North Central State College"
  },
  {
    "id": "204431",
    "name": "Northern Institute of Cosmetology"
  },
  {
    "id": "204440",
    "name": "Northwest State Community College"
  },
  {
    "id": "204459",
    "name": "Toledo Public Schools Adult and Continuing Education"
  },
  {
    "id": "204468",
    "name": "Notre Dame College"
  },
  {
    "id": "204477",
    "name": "Northeast Ohio Medical University"
  },
  {
    "id": "204486",
    "name": "University of Northwestern Ohio"
  },
  {
    "id": "204501",
    "name": "Oberlin College"
  },
  {
    "id": "204608",
    "name": "Ohio Technical College"
  },
  {
    "id": "204617",
    "name": "Ohio Dominican University"
  },
  {
    "id": "204635",
    "name": "Ohio Northern University"
  },
  {
    "id": "204662",
    "name": "Ohio State University Agricultural Technical Institute"
  },
  {
    "id": "204671",
    "name": "Ohio State University-Lima Campus"
  },
  {
    "id": "204680",
    "name": "Ohio State University-Mansfield Campus"
  },
  {
    "id": "204699",
    "name": "Ohio State University-Marion Campus"
  },
  {
    "id": "204705",
    "name": "Ohio State University-Newark Campus"
  },
  {
    "id": "204714",
    "name": "Ohio State Beauty Academy"
  },
  {
    "id": "204723",
    "name": "Ohio State College of Barber Styling"
  },
  {
    "id": "204769",
    "name": "The Spa School"
  },
  {
    "id": "204778",
    "name": "Ohio State School of Cosmetology-Canal Winchester"
  },
  {
    "id": "204802",
    "name": "Ohio University-Eastern Campus"
  },
  {
    "id": "204820",
    "name": "Ohio University-Chillicothe Campus"
  },
  {
    "id": "204839",
    "name": "Ohio University-Southern Campus"
  },
  {
    "id": "204848",
    "name": "Ohio University-Lancaster Campus"
  },
  {
    "id": "204857",
    "name": "Ohio University-Main Campus"
  },
  {
    "id": "204866",
    "name": "Ohio University-Zanesville Campus"
  },
  {
    "id": "204884",
    "name": "East Ohio College"
  },
  {
    "id": "204893",
    "name": "Trinity Health System School of Nursing"
  },
  {
    "id": "204909",
    "name": "Ohio Wesleyan University"
  },
  {
    "id": "204936",
    "name": "Otterbein University"
  },
  {
    "id": "204945",
    "name": "Owens Community College"
  },
  {
    "id": "204963",
    "name": "Paramount Beauty Academy"
  },
  {
    "id": "204981",
    "name": "Cuyahoga Valley Career Center"
  },
  {
    "id": "204990",
    "name": "Payne Theological Seminary"
  },
  {
    "id": "205027",
    "name": "Pontifical College Josephinum"
  },
  {
    "id": "205054",
    "name": "Professional Skills Institute"
  },
  {
    "id": "205090",
    "name": "Firelands Regional Medical Center School of Nursing"
  },
  {
    "id": "205124",
    "name": "Rabbinical College Telshe"
  },
  {
    "id": "205142",
    "name": "Raphael's School of Beauty Culture Inc-Niles"
  },
  {
    "id": "205179",
    "name": "Fortis College-Centerville"
  },
  {
    "id": "205203",
    "name": "University of Rio Grande"
  },
  {
    "id": "205391",
    "name": "The Modern College of Design"
  },
  {
    "id": "205443",
    "name": "Shawnee State University"
  },
  {
    "id": "205470",
    "name": "Sinclair Community College"
  },
  {
    "id": "205559",
    "name": "Hussian College-Daymar College Columbus"
  },
  {
    "id": "205841",
    "name": "Stark State College"
  },
  {
    "id": "205887",
    "name": "Stautzenberger College-Maumee"
  },
  {
    "id": "205957",
    "name": "Franciscan University of Steubenville"
  },
  {
    "id": "205966",
    "name": "Southern State Community College"
  },
  {
    "id": "206011",
    "name": "Terra State Community College"
  },
  {
    "id": "206039",
    "name": "Tiffin Academy of Hair Design"
  },
  {
    "id": "206048",
    "name": "Tiffin University"
  },
  {
    "id": "206084",
    "name": "University of Toledo"
  },
  {
    "id": "206154",
    "name": "Tri-State Bible College"
  },
  {
    "id": "206172",
    "name": "Tri-County Adult Career Center"
  },
  {
    "id": "206181",
    "name": "Tri-Rivers Career Center"
  },
  {
    "id": "206279",
    "name": "Union Institute & University"
  },
  {
    "id": "206288",
    "name": "United Theological Seminary"
  },
  {
    "id": "206321",
    "name": "Upper Valley Career Center"
  },
  {
    "id": "206349",
    "name": "Ursuline College"
  },
  {
    "id": "206394",
    "name": "The North Coast College"
  },
  {
    "id": "206437",
    "name": "Walsh University"
  },
  {
    "id": "206446",
    "name": "Washington State Community College"
  },
  {
    "id": "206491",
    "name": "Wilberforce University"
  },
  {
    "id": "206507",
    "name": "Wilmington College"
  },
  {
    "id": "206516",
    "name": "Winebrenner Theological Seminary"
  },
  {
    "id": "206525",
    "name": "Wittenberg University"
  },
  {
    "id": "206589",
    "name": "The College of Wooster"
  },
  {
    "id": "206604",
    "name": "Wright State University-Main Campus"
  },
  {
    "id": "206613",
    "name": "Wright State University-Lake Campus"
  },
  {
    "id": "206622",
    "name": "Xavier University"
  },
  {
    "id": "206695",
    "name": "Youngstown State University"
  },
  {
    "id": "206817",
    "name": "Bacone College"
  },
  {
    "id": "206835",
    "name": "Oklahoma Wesleyan University"
  },
  {
    "id": "206862",
    "name": "Southern Nazarene University"
  },
  {
    "id": "206905",
    "name": "Pontotoc Technology Center"
  },
  {
    "id": "206914",
    "name": "Cameron University"
  },
  {
    "id": "206923",
    "name": "Carl Albert State College"
  },
  {
    "id": "206932",
    "name": "Central Oklahoma College"
  },
  {
    "id": "206941",
    "name": "University of Central Oklahoma"
  },
  {
    "id": "206996",
    "name": "Connors State College"
  },
  {
    "id": "207041",
    "name": "East Central University"
  },
  {
    "id": "207050",
    "name": "Eastern Oklahoma State College"
  },
  {
    "id": "207069",
    "name": "Redlands Community College"
  },
  {
    "id": "207087",
    "name": "Formations Institute"
  },
  {
    "id": "207102",
    "name": "Eves College of Hairstyling"
  },
  {
    "id": "207157",
    "name": "Randall University"
  },
  {
    "id": "207209",
    "name": "Langston University"
  },
  {
    "id": "207236",
    "name": "Murray State College"
  },
  {
    "id": "207254",
    "name": "Spartan College of Aeronautics and Technology"
  },
  {
    "id": "207263",
    "name": "Northeastern State University"
  },
  {
    "id": "207281",
    "name": "Northern Oklahoma College"
  },
  {
    "id": "207290",
    "name": "Northeastern Oklahoma A&M College"
  },
  {
    "id": "207306",
    "name": "Northwestern Oklahoma State University"
  },
  {
    "id": "207315",
    "name": "Oklahoma State University Center for Health Sciences"
  },
  {
    "id": "207324",
    "name": "Oklahoma Christian University"
  },
  {
    "id": "207342",
    "name": "University of Oklahoma-Health Sciences Center"
  },
  {
    "id": "207351",
    "name": "Oklahoma Panhandle State University"
  },
  {
    "id": "207388",
    "name": "Oklahoma State University-Main Campus"
  },
  {
    "id": "207397",
    "name": "Oklahoma State University-Oklahoma City"
  },
  {
    "id": "207403",
    "name": "Oklahoma Baptist University"
  },
  {
    "id": "207449",
    "name": "Oklahoma City Community College"
  },
  {
    "id": "207458",
    "name": "Oklahoma City University"
  },
  {
    "id": "207564",
    "name": "Oklahoma State University Institute of Technology"
  },
  {
    "id": "207582",
    "name": "Oral Roberts University"
  },
  {
    "id": "207661",
    "name": "Rogers State University"
  },
  {
    "id": "207670",
    "name": "Rose State College"
  },
  {
    "id": "207704",
    "name": "Jenks Beauty College"
  },
  {
    "id": "207722",
    "name": "University of Science and Arts of Oklahoma"
  },
  {
    "id": "207740",
    "name": "Seminole State College"
  },
  {
    "id": "207786",
    "name": "Southern School of Beauty Inc"
  },
  {
    "id": "207847",
    "name": "Southeastern Oklahoma State University"
  },
  {
    "id": "207856",
    "name": "Southwestern Christian University"
  },
  {
    "id": "207865",
    "name": "Southwestern Oklahoma State University"
  },
  {
    "id": "207935",
    "name": "Tulsa Community College"
  },
  {
    "id": "207962",
    "name": "Tulsa Welding School-Tulsa"
  },
  {
    "id": "208035",
    "name": "Western Oklahoma State College"
  },
  {
    "id": "208044",
    "name": "Formations Institute of Cosmetology & Barbering"
  },
  {
    "id": "208053",
    "name": "High Plains Technology Center"
  },
  {
    "id": "208275",
    "name": "Blue Mountain Community College"
  },
  {
    "id": "208318",
    "name": "Central Oregon Community College"
  },
  {
    "id": "208390",
    "name": "Chemeketa Community College"
  },
  {
    "id": "208406",
    "name": "Clackamas Community College"
  },
  {
    "id": "208415",
    "name": "Clatsop Community College"
  },
  {
    "id": "208433",
    "name": "College of Hair Design Careers"
  },
  {
    "id": "208479",
    "name": "Concorde Career College-Portland"
  },
  {
    "id": "208512",
    "name": "Sumner College"
  },
  {
    "id": "208637",
    "name": "East West College of the Healing Arts"
  },
  {
    "id": "208646",
    "name": "Eastern Oregon University"
  },
  {
    "id": "208725",
    "name": "New Hope Christian College-Eugene"
  },
  {
    "id": "208822",
    "name": "George Fox University"
  },
  {
    "id": "208859",
    "name": "Phagans Grants Pass College of Beauty"
  },
  {
    "id": "209038",
    "name": "Lane Community College"
  },
  {
    "id": "209056",
    "name": "Lewis & Clark College"
  },
  {
    "id": "209065",
    "name": "Linfield University"
  },
  {
    "id": "209074",
    "name": "Linn-Benton Community College"
  },
  {
    "id": "209083",
    "name": "Northwest College-Beaverton"
  },
  {
    "id": "209153",
    "name": "Phagans Medford Beauty School"
  },
  {
    "id": "209205",
    "name": "Northwest College-Clackamas"
  },
  {
    "id": "209241",
    "name": "Mount Angel Seminary"
  },
  {
    "id": "209250",
    "name": "Mt Hood Community College"
  },
  {
    "id": "209287",
    "name": "Multnomah University"
  },
  {
    "id": "209296",
    "name": "National University of Natural Medicine"
  },
  {
    "id": "209409",
    "name": "Bushnell University"
  },
  {
    "id": "209490",
    "name": "Oregon Health & Science University"
  },
  {
    "id": "209506",
    "name": "Oregon Institute of Technology"
  },
  {
    "id": "209542",
    "name": "Oregon State University"
  },
  {
    "id": "209603",
    "name": "Pacific Northwest College of Art"
  },
  {
    "id": "209612",
    "name": "Pacific University"
  },
  {
    "id": "209667",
    "name": "Phagans Beauty College"
  },
  {
    "id": "209676",
    "name": "Phagans Central Oregon Beauty College"
  },
  {
    "id": "209694",
    "name": "Phagans School of Beauty"
  },
  {
    "id": "209700",
    "name": "Phagans School of Hair Design"
  },
  {
    "id": "209719",
    "name": "Summit Salon Academy-Portland"
  },
  {
    "id": "209746",
    "name": "Portland Community College"
  },
  {
    "id": "209807",
    "name": "Portland State University"
  },
  {
    "id": "209825",
    "name": "University of Portland"
  },
  {
    "id": "209922",
    "name": "Reed College"
  },
  {
    "id": "209940",
    "name": "Rogue Community College"
  },
  {
    "id": "210146",
    "name": "Southern Oregon University"
  },
  {
    "id": "210155",
    "name": "Southwestern Oregon Community College"
  },
  {
    "id": "210234",
    "name": "Treasure Valley Community College"
  },
  {
    "id": "210270",
    "name": "Umpqua Community College"
  },
  {
    "id": "210304",
    "name": "Warner Pacific University"
  },
  {
    "id": "210331",
    "name": "Corban University"
  },
  {
    "id": "210340",
    "name": "College of Cosmetology"
  },
  {
    "id": "210368",
    "name": "Western Seminary"
  },
  {
    "id": "210401",
    "name": "Willamette University"
  },
  {
    "id": "210429",
    "name": "Western Oregon University"
  },
  {
    "id": "210438",
    "name": "University of Western States"
  },
  {
    "id": "210492",
    "name": "Bryn Athyn College of the New Church"
  },
  {
    "id": "210508",
    "name": "Academy of Vocal Arts"
  },
  {
    "id": "210571",
    "name": "Albright College"
  },
  {
    "id": "210599",
    "name": "All-State Career School"
  },
  {
    "id": "210605",
    "name": "Community College of Allegheny County"
  },
  {
    "id": "210669",
    "name": "Allegheny College"
  },
  {
    "id": "210711",
    "name": "The Beauty Institute"
  },
  {
    "id": "210739",
    "name": "DeSales University"
  },
  {
    "id": "210748",
    "name": "Altoona Beauty School Inc"
  },
  {
    "id": "210775",
    "name": "Alvernia University"
  },
  {
    "id": "210784",
    "name": "The Beauty Institute-Ambler"
  },
  {
    "id": "210809",
    "name": "American College of Financial Services"
  },
  {
    "id": "211006",
    "name": "Automotive Training Center-Exton"
  },
  {
    "id": "211024",
    "name": "Clarks Summit University"
  },
  {
    "id": "211079",
    "name": "Community College of Beaver County"
  },
  {
    "id": "211088",
    "name": "Arcadia University"
  },
  {
    "id": "211130",
    "name": "Missio Theological Seminary"
  },
  {
    "id": "211158",
    "name": "Bloomsburg University of Pennsylvania"
  },
  {
    "id": "211273",
    "name": "Bryn Mawr College"
  },
  {
    "id": "211291",
    "name": "Bucknell University"
  },
  {
    "id": "211307",
    "name": "Bucks County Community College"
  },
  {
    "id": "211316",
    "name": "Bucks County School of Beauty Culture Inc"
  },
  {
    "id": "211334",
    "name": "Butler Beauty Academy"
  },
  {
    "id": "211343",
    "name": "Butler County Community College"
  },
  {
    "id": "211352",
    "name": "Cabrini University"
  },
  {
    "id": "211361",
    "name": "California University of Pennsylvania"
  },
  {
    "id": "211431",
    "name": "Carlow University"
  },
  {
    "id": "211468",
    "name": "Cedar Crest College"
  },
  {
    "id": "211477",
    "name": "Central Penn College"
  },
  {
    "id": "211556",
    "name": "Chatham University"
  },
  {
    "id": "211583",
    "name": "Chestnut Hill College"
  },
  {
    "id": "211608",
    "name": "Cheyney University of Pennsylvania"
  },
  {
    "id": "211635",
    "name": "Citizens School of Nursing"
  },
  {
    "id": "211644",
    "name": "Clarion University of Pennsylvania"
  },
  {
    "id": "211802",
    "name": "DLP Conemaugh Memorial Medical Center"
  },
  {
    "id": "211893",
    "name": "Curtis Institute of Music"
  },
  {
    "id": "211927",
    "name": "Delaware County Community College"
  },
  {
    "id": "211981",
    "name": "Delaware Valley University"
  },
  {
    "id": "212009",
    "name": "Dickinson College"
  },
  {
    "id": "212045",
    "name": "Douglas Education Center"
  },
  {
    "id": "212106",
    "name": "Duquesne University"
  },
  {
    "id": "212115",
    "name": "East Stroudsburg University of Pennsylvania"
  },
  {
    "id": "212133",
    "name": "Eastern University"
  },
  {
    "id": "212160",
    "name": "Edinboro University of Pennsylvania"
  },
  {
    "id": "212197",
    "name": "Elizabethtown College"
  },
  {
    "id": "212230",
    "name": "Empire Beauty School-Lebanon"
  },
  {
    "id": "212267",
    "name": "Empire Beauty School-York"
  },
  {
    "id": "212276",
    "name": "Empire Beauty School-Lancaster"
  },
  {
    "id": "212294",
    "name": "Empire Beauty School-Shamokin Dam"
  },
  {
    "id": "212300",
    "name": "Empire Beauty School-Pottsville"
  },
  {
    "id": "212328",
    "name": "Empire Beauty School-Lehigh Valley"
  },
  {
    "id": "212337",
    "name": "Empire Beauty School-Gwinnett"
  },
  {
    "id": "212355",
    "name": "Empire Beauty School-Wyoming Valley"
  },
  {
    "id": "212382",
    "name": "Empire Beauty School-Harrisburg"
  },
  {
    "id": "212391",
    "name": "Empire Beauty School-Reading"
  },
  {
    "id": "212434",
    "name": "Erie Institute of Technology Inc"
  },
  {
    "id": "212443",
    "name": "Evangelical Theological Seminary"
  },
  {
    "id": "212577",
    "name": "Franklin and Marshall College"
  },
  {
    "id": "212601",
    "name": "Gannon University"
  },
  {
    "id": "212656",
    "name": "Geneva College"
  },
  {
    "id": "212674",
    "name": "Gettysburg College"
  },
  {
    "id": "212753",
    "name": "Empire Beauty School-NE Philadelphia"
  },
  {
    "id": "212771",
    "name": "Gratz College"
  },
  {
    "id": "212805",
    "name": "Grove City College"
  },
  {
    "id": "212832",
    "name": "Gwynedd Mercy University"
  },
  {
    "id": "212869",
    "name": "Harcum College"
  },
  {
    "id": "212878",
    "name": "Harrisburg Area Community College"
  },
  {
    "id": "212975",
    "name": "Commonwealth Technical Institute"
  },
  {
    "id": "212984",
    "name": "Holy Family University"
  },
  {
    "id": "212993",
    "name": "Hussian College-Philadelphia"
  },
  {
    "id": "213011",
    "name": "Immaculata University"
  },
  {
    "id": "213020",
    "name": "Indiana University of Pennsylvania-Main Campus"
  },
  {
    "id": "213163",
    "name": "UPMC Jameson School of Nursing"
  },
  {
    "id": "213181",
    "name": "Great Lakes Institute of Technology"
  },
  {
    "id": "213233",
    "name": "Johnson College"
  },
  {
    "id": "213251",
    "name": "Juniata College"
  },
  {
    "id": "213303",
    "name": "Keystone College"
  },
  {
    "id": "213321",
    "name": "King's College"
  },
  {
    "id": "213330",
    "name": "Butler Beauty Academy-Kittanning Beauty Academy"
  },
  {
    "id": "213349",
    "name": "Kutztown University of Pennsylvania"
  },
  {
    "id": "213358",
    "name": "La Roche University"
  },
  {
    "id": "213367",
    "name": "La Salle University"
  },
  {
    "id": "213376",
    "name": "Lackawanna College"
  },
  {
    "id": "213385",
    "name": "Lafayette College"
  },
  {
    "id": "213400",
    "name": "Lancaster Bible College"
  },
  {
    "id": "213446",
    "name": "Lancaster Theological Seminary"
  },
  {
    "id": "213455",
    "name": "Lancaster School of Cosmetology & Therapeutic Bodywork"
  },
  {
    "id": "213473",
    "name": "Lansdale School of Business"
  },
  {
    "id": "213491",
    "name": "Lansdale School of Cosmetology Inc"
  },
  {
    "id": "213507",
    "name": "Lebanon Valley College"
  },
  {
    "id": "213525",
    "name": "Lehigh Carbon Community College"
  },
  {
    "id": "213534",
    "name": "Miller-Motte College-Berks Technical Institute"
  },
  {
    "id": "213570",
    "name": "Lincoln Technical Institute-Allentown"
  },
  {
    "id": "213589",
    "name": "Lincoln Technical Institute-Philadelphia"
  },
  {
    "id": "213598",
    "name": "Lincoln University"
  },
  {
    "id": "213613",
    "name": "Lock Haven University"
  },
  {
    "id": "213622",
    "name": "UPMC St. Margaret School of Nursing"
  },
  {
    "id": "213631",
    "name": "United Lutheran Seminary"
  },
  {
    "id": "213659",
    "name": "Luzerne County Community College"
  },
  {
    "id": "213668",
    "name": "Lycoming College"
  },
  {
    "id": "213774",
    "name": "Manor College"
  },
  {
    "id": "213783",
    "name": "Mansfield University of Pennsylvania"
  },
  {
    "id": "213826",
    "name": "Marywood University"
  },
  {
    "id": "213914",
    "name": "Vet Tech Institute"
  },
  {
    "id": "213932",
    "name": "Roxborough Memorial Hospital School of Nursing"
  },
  {
    "id": "213987",
    "name": "Mercyhurst University"
  },
  {
    "id": "213996",
    "name": "Messiah University"
  },
  {
    "id": "214023",
    "name": "ASPIRA City College"
  },
  {
    "id": "214041",
    "name": "Millersville University of Pennsylvania"
  },
  {
    "id": "214069",
    "name": "Misericordia University"
  },
  {
    "id": "214111",
    "name": "Montgomery County Community College"
  },
  {
    "id": "214148",
    "name": "Moore College of Art and Design"
  },
  {
    "id": "214157",
    "name": "Moravian University"
  },
  {
    "id": "214166",
    "name": "Mount Aloysius College"
  },
  {
    "id": "214175",
    "name": "Muhlenberg College"
  },
  {
    "id": "214272",
    "name": "Neumann University"
  },
  {
    "id": "214290",
    "name": "New Castle School of Trades"
  },
  {
    "id": "214379",
    "name": "Northampton County Area Community College"
  },
  {
    "id": "214476",
    "name": "Heritage Valley Kennedy School of Nursing"
  },
  {
    "id": "214528",
    "name": "Orleans Technical College"
  },
  {
    "id": "214564",
    "name": "Salus University"
  },
  {
    "id": "214582",
    "name": "Pennsylvania Institute of Technology"
  },
  {
    "id": "214883",
    "name": "Peirce College"
  },
  {
    "id": "214892",
    "name": "Penn Commercial Business/Technical School"
  },
  {
    "id": "214944",
    "name": "Pennco Tech-Bristol"
  },
  {
    "id": "214971",
    "name": "Pennsylvania Academy of the Fine Arts"
  },
  {
    "id": "215008",
    "name": "Pennsylvania Gunsmith School"
  },
  {
    "id": "215044",
    "name": "Cortiva Institute"
  },
  {
    "id": "215053",
    "name": "Pennsylvania College of Art and Design"
  },
  {
    "id": "215105",
    "name": "The University of the Arts"
  },
  {
    "id": "215114",
    "name": "Cairn University-Langhorne"
  },
  {
    "id": "215123",
    "name": "Philadelphia College of Osteopathic Medicine"
  },
  {
    "id": "215132",
    "name": "University of the Sciences"
  },
  {
    "id": "215239",
    "name": "Community College of Philadelphia"
  },
  {
    "id": "215266",
    "name": "University of Pittsburgh-Bradford"
  },
  {
    "id": "215275",
    "name": "University of Pittsburgh-Greensburg"
  },
  {
    "id": "215284",
    "name": "University of Pittsburgh-Johnstown"
  },
  {
    "id": "215309",
    "name": "University of Pittsburgh-Titusville"
  },
  {
    "id": "215336",
    "name": "Beaver Falls Beauty Academy"
  },
  {
    "id": "215381",
    "name": "Pittsburgh Institute of Aeronautics"
  },
  {
    "id": "215390",
    "name": "Pittsburgh Institute of Mortuary Science Inc"
  },
  {
    "id": "215415",
    "name": "Pittsburgh Technical College"
  },
  {
    "id": "215424",
    "name": "Pittsburgh Theological Seminary"
  },
  {
    "id": "215442",
    "name": "Point Park University"
  },
  {
    "id": "215479",
    "name": "Joseph F McCloskey School of Nursing"
  },
  {
    "id": "215530",
    "name": "The Salon Professional Academy-Altoona"
  },
  {
    "id": "215585",
    "name": "Reading Area Community College"
  },
  {
    "id": "215594",
    "name": "Reading Hospital School of Health Sciences"
  },
  {
    "id": "215619",
    "name": "Reconstructionist Rabbinical College"
  },
  {
    "id": "215637",
    "name": "Walnut Hill College"
  },
  {
    "id": "215655",
    "name": "Robert Morris University"
  },
  {
    "id": "215682",
    "name": "Rosedale Technical College"
  },
  {
    "id": "215691",
    "name": "Rosemont College"
  },
  {
    "id": "215743",
    "name": "Saint Francis University"
  },
  {
    "id": "215770",
    "name": "Saint Joseph's University"
  },
  {
    "id": "215798",
    "name": "Saint Vincent College"
  },
  {
    "id": "215813",
    "name": "Saint Vincent Seminary"
  },
  {
    "id": "215886",
    "name": "Schuylkill Technology Center"
  },
  {
    "id": "215929",
    "name": "University of Scranton"
  },
  {
    "id": "215947",
    "name": "Seton Hill University"
  },
  {
    "id": "215974",
    "name": "UPMC Shadyside School of Nursing"
  },
  {
    "id": "215983",
    "name": "Sharon Regional School of Nursing"
  },
  {
    "id": "215992",
    "name": "Laurel Technical Institute"
  },
  {
    "id": "216010",
    "name": "Shippensburg University of Pennsylvania"
  },
  {
    "id": "216038",
    "name": "Slippery Rock University of Pennsylvania"
  },
  {
    "id": "216047",
    "name": "Saint Charles Borromeo Seminary-Overbrook"
  },
  {
    "id": "216074",
    "name": "South Hills Beauty Academy"
  },
  {
    "id": "216083",
    "name": "South Hills School of Business & Technology"
  },
  {
    "id": "216162",
    "name": "St Lukes Hospital School of Nursing"
  },
  {
    "id": "216223",
    "name": "The Beauty Institute"
  },
  {
    "id": "216278",
    "name": "Susquehanna University"
  },
  {
    "id": "216296",
    "name": "Thaddeus Stevens College of Technology"
  },
  {
    "id": "216311",
    "name": "Talmudical Yeshiva of Philadelphia"
  },
  {
    "id": "216348",
    "name": "Theological Seminary of the Reformed Episcopal Church"
  },
  {
    "id": "216357",
    "name": "Thiel College"
  },
  {
    "id": "216436",
    "name": "Triangle Tech Inc-Pittsburgh"
  },
  {
    "id": "216445",
    "name": "Triangle Tech Inc-Greensburg"
  },
  {
    "id": "216454",
    "name": "Triangle Tech Inc-Dubois"
  },
  {
    "id": "216463",
    "name": "Trinity Episcopal School for Ministry"
  },
  {
    "id": "216524",
    "name": "Ursinus College"
  },
  {
    "id": "216542",
    "name": "University of Valley Forge"
  },
  {
    "id": "216551",
    "name": "Valley Forge Military College"
  },
  {
    "id": "216579",
    "name": "Venus Beauty Academy"
  },
  {
    "id": "216630",
    "name": "Washington Health System School of Nursing"
  },
  {
    "id": "216667",
    "name": "Washington & Jefferson College"
  },
  {
    "id": "216694",
    "name": "Waynesburg University"
  },
  {
    "id": "216746",
    "name": "Welder Training and Testing Institute"
  },
  {
    "id": "216764",
    "name": "West Chester University of Pennsylvania"
  },
  {
    "id": "216773",
    "name": "Western Pennsylvania Hospital School of Nursing"
  },
  {
    "id": "216782",
    "name": "Pittsburgh Career Institute"
  },
  {
    "id": "216807",
    "name": "Westminster College"
  },
  {
    "id": "216816",
    "name": "Westminster Theological Seminary"
  },
  {
    "id": "216825",
    "name": "Westmoreland County Community College"
  },
  {
    "id": "216852",
    "name": "Widener University"
  },
  {
    "id": "216931",
    "name": "Wilkes University"
  },
  {
    "id": "216940",
    "name": "Williamson College of the Trades"
  },
  {
    "id": "217013",
    "name": "Wilson College"
  },
  {
    "id": "217040",
    "name": "Yeshivath Beth Moshe"
  },
  {
    "id": "217059",
    "name": "York College of Pennsylvania"
  },
  {
    "id": "217077",
    "name": "YTI Career Institute-York"
  },
  {
    "id": "217165",
    "name": "Bryant University"
  },
  {
    "id": "217235",
    "name": "Johnson & Wales University-Providence"
  },
  {
    "id": "217305",
    "name": "New England Institute of Technology"
  },
  {
    "id": "217323",
    "name": "New England Tractor Trailer Training School of Rhode Island"
  },
  {
    "id": "217402",
    "name": "Providence College"
  },
  {
    "id": "217420",
    "name": "Rhode Island College"
  },
  {
    "id": "217475",
    "name": "Community College of Rhode Island"
  },
  {
    "id": "217484",
    "name": "University of Rhode Island"
  },
  {
    "id": "217493",
    "name": "Rhode Island School of Design"
  },
  {
    "id": "217518",
    "name": "Roger Williams University"
  },
  {
    "id": "217536",
    "name": "Salve Regina University"
  },
  {
    "id": "217581",
    "name": "Empire Beauty School-Warwick"
  },
  {
    "id": "217606",
    "name": "Northpoint Bible College"
  },
  {
    "id": "217615",
    "name": "Aiken Technical College"
  },
  {
    "id": "217624",
    "name": "Allen University"
  },
  {
    "id": "217633",
    "name": "Anderson University"
  },
  {
    "id": "217688",
    "name": "Charleston Southern University"
  },
  {
    "id": "217712",
    "name": "Technical College of the Lowcountry"
  },
  {
    "id": "217721",
    "name": "Benedict College"
  },
  {
    "id": "217749",
    "name": "Bob Jones University"
  },
  {
    "id": "217776",
    "name": "Southern Wesleyan University"
  },
  {
    "id": "217794",
    "name": "Charleston Cosmetology Institute"
  },
  {
    "id": "217819",
    "name": "College of Charleston"
  },
  {
    "id": "217828",
    "name": "Charzanne Beauty College"
  },
  {
    "id": "217837",
    "name": "Northeastern Technical College"
  },
  {
    "id": "217864",
    "name": "Citadel Military College of South Carolina"
  },
  {
    "id": "217873",
    "name": "Claflin University"
  },
  {
    "id": "217891",
    "name": "Clinton College"
  },
  {
    "id": "217907",
    "name": "Coker University"
  },
  {
    "id": "217925",
    "name": "Columbia International University"
  },
  {
    "id": "217934",
    "name": "Columbia College"
  },
  {
    "id": "217961",
    "name": "Converse University"
  },
  {
    "id": "217989",
    "name": "Denmark Technical College"
  },
  {
    "id": "217998",
    "name": "Erskine College"
  },
  {
    "id": "218025",
    "name": "Florence-Darlington Technical College"
  },
  {
    "id": "218061",
    "name": "Francis Marion University"
  },
  {
    "id": "218070",
    "name": "Furman University"
  },
  {
    "id": "218113",
    "name": "Greenville Technical College"
  },
  {
    "id": "218140",
    "name": "Horry-Georgetown Technical College"
  },
  {
    "id": "218159",
    "name": "Kenneth Shuler School of Cosmetology-North Augusta"
  },
  {
    "id": "218195",
    "name": "Kenneth Shuler School of Cosmetology and Nails-Columbia"
  },
  {
    "id": "218229",
    "name": "Lander University"
  },
  {
    "id": "218238",
    "name": "Limestone University"
  },
  {
    "id": "218335",
    "name": "Medical University of South Carolina"
  },
  {
    "id": "218353",
    "name": "Midlands Technical College"
  },
  {
    "id": "218399",
    "name": "Morris College"
  },
  {
    "id": "218414",
    "name": "Newberry College"
  },
  {
    "id": "218441",
    "name": "North Greenville University"
  },
  {
    "id": "218487",
    "name": "Orangeburg Calhoun Technical College"
  },
  {
    "id": "218520",
    "name": "Piedmont Technical College"
  },
  {
    "id": "218539",
    "name": "Presbyterian College"
  },
  {
    "id": "218645",
    "name": "University of South Carolina Aiken"
  },
  {
    "id": "218654",
    "name": "University of South Carolina Beaufort"
  },
  {
    "id": "218672",
    "name": "University of South Carolina-Lancaster"
  },
  {
    "id": "218681",
    "name": "University of South Carolina-Salkehatchie"
  },
  {
    "id": "218690",
    "name": "University of South Carolina-Sumter"
  },
  {
    "id": "218706",
    "name": "University of South Carolina-Union"
  },
  {
    "id": "218724",
    "name": "Coastal Carolina University"
  },
  {
    "id": "218733",
    "name": "South Carolina State University"
  },
  {
    "id": "218742",
    "name": "University of South Carolina-Upstate"
  },
  {
    "id": "218751",
    "name": "Sherman College of Chiropractic"
  },
  {
    "id": "218821",
    "name": "Spartanburg Methodist College"
  },
  {
    "id": "218830",
    "name": "Spartanburg Community College"
  },
  {
    "id": "218858",
    "name": "Central Carolina Technical College"
  },
  {
    "id": "218885",
    "name": "Tri-County Technical College"
  },
  {
    "id": "218894",
    "name": "Trident Technical College"
  },
  {
    "id": "218919",
    "name": "Voorhees University"
  },
  {
    "id": "218955",
    "name": "Williamsburg Technical College"
  },
  {
    "id": "218964",
    "name": "Winthrop University"
  },
  {
    "id": "218973",
    "name": "Wofford College"
  },
  {
    "id": "218991",
    "name": "York Technical College"
  },
  {
    "id": "219000",
    "name": "Augustana University"
  },
  {
    "id": "219037",
    "name": "The Salon Professional Academy"
  },
  {
    "id": "219046",
    "name": "Black Hills State University"
  },
  {
    "id": "219082",
    "name": "Dakota State University"
  },
  {
    "id": "219091",
    "name": "Dakota Wesleyan University"
  },
  {
    "id": "219125",
    "name": "Paul Mitchell the School-Rapid City"
  },
  {
    "id": "219143",
    "name": "Lake Area Technical College"
  },
  {
    "id": "219170",
    "name": "Avera McKennan Hospital School of Radiologic Technology"
  },
  {
    "id": "219189",
    "name": "Mitchell Technical College"
  },
  {
    "id": "219198",
    "name": "Mount Marty University"
  },
  {
    "id": "219204",
    "name": "National American University-Rapid City"
  },
  {
    "id": "219240",
    "name": "Kairos University"
  },
  {
    "id": "219259",
    "name": "Northern State University"
  },
  {
    "id": "219277",
    "name": "Oglala Lakota College"
  },
  {
    "id": "219295",
    "name": "Presentation College"
  },
  {
    "id": "219338",
    "name": "Avera Sacred Heart Hospital"
  },
  {
    "id": "219347",
    "name": "South Dakota School of Mines and Technology"
  },
  {
    "id": "219356",
    "name": "South Dakota State University"
  },
  {
    "id": "219374",
    "name": "Sinte Gleska University"
  },
  {
    "id": "219383",
    "name": "University of Sioux Falls"
  },
  {
    "id": "219392",
    "name": "Sanford Medical Center"
  },
  {
    "id": "219408",
    "name": "Sisseton Wahpeton College"
  },
  {
    "id": "219426",
    "name": "Southeast Technical College"
  },
  {
    "id": "219453",
    "name": "Stewart School"
  },
  {
    "id": "219471",
    "name": "University of South Dakota"
  },
  {
    "id": "219480",
    "name": "Western Dakota Technical College"
  },
  {
    "id": "219505",
    "name": "American Baptist College"
  },
  {
    "id": "219587",
    "name": "Arnolds Beauty School"
  },
  {
    "id": "219596",
    "name": "Tennessee College of Applied Technology-Athens"
  },
  {
    "id": "219602",
    "name": "Austin Peay State University"
  },
  {
    "id": "219639",
    "name": "Baptist Health Sciences University"
  },
  {
    "id": "219709",
    "name": "Belmont University"
  },
  {
    "id": "219718",
    "name": "Bethel University"
  },
  {
    "id": "219790",
    "name": "Bryan College-Dayton"
  },
  {
    "id": "219806",
    "name": "Carson-Newman University"
  },
  {
    "id": "219824",
    "name": "Chattanooga State Community College"
  },
  {
    "id": "219833",
    "name": "Christian Brothers University"
  },
  {
    "id": "219842",
    "name": "Pentecostal Theological Seminary"
  },
  {
    "id": "219851",
    "name": "Austin's Beauty College Inc"
  },
  {
    "id": "219879",
    "name": "Cleveland State Community College"
  },
  {
    "id": "219888",
    "name": "Columbia State Community College"
  },
  {
    "id": "219903",
    "name": "Concorde Career College-Memphis"
  },
  {
    "id": "219921",
    "name": "Tennessee College of Applied Technology-Covington"
  },
  {
    "id": "219949",
    "name": "Cumberland University"
  },
  {
    "id": "219976",
    "name": "Lipscomb University"
  },
  {
    "id": "219994",
    "name": "Tennessee College of Applied Technology-Dickson"
  },
  {
    "id": "220002",
    "name": "Hussian College-Daymar College Nashville"
  },
  {
    "id": "220057",
    "name": "Dyersburg State Community College"
  },
  {
    "id": "220075",
    "name": "East Tennessee State University"
  },
  {
    "id": "220118",
    "name": "Chattanooga College Medical Dental and Technical Careers"
  },
  {
    "id": "220127",
    "name": "Tennessee College of Applied Technology-Elizabethton"
  },
  {
    "id": "220163",
    "name": "The Hair Academy LLC"
  },
  {
    "id": "220181",
    "name": "Fisk University"
  },
  {
    "id": "220206",
    "name": "Welch College"
  },
  {
    "id": "220215",
    "name": "Freed-Hardeman University"
  },
  {
    "id": "220251",
    "name": "Tennessee College of Applied Technology-Harriman"
  },
  {
    "id": "220279",
    "name": "Tennessee College of Applied Technology-Hartsville"
  },
  {
    "id": "220321",
    "name": "Tennessee College of Applied Technology-Hohenwald"
  },
  {
    "id": "220394",
    "name": "Tennessee College of Applied Technology-Jacksboro"
  },
  {
    "id": "220400",
    "name": "Jackson State Community College"
  },
  {
    "id": "220464",
    "name": "John A Gupton College"
  },
  {
    "id": "220473",
    "name": "Johnson University"
  },
  {
    "id": "220491",
    "name": "Paul Mitchell the School-Murfreesboro"
  },
  {
    "id": "220516",
    "name": "King University"
  },
  {
    "id": "220552",
    "name": "South College"
  },
  {
    "id": "220598",
    "name": "Lane College"
  },
  {
    "id": "220604",
    "name": "Le Moyne-Owen College"
  },
  {
    "id": "220613",
    "name": "Lee University"
  },
  {
    "id": "220631",
    "name": "Lincoln Memorial University"
  },
  {
    "id": "220640",
    "name": "Tennessee College of Applied Technology-Livingston"
  },
  {
    "id": "220701",
    "name": "The University of Tennessee Southern"
  },
  {
    "id": "220710",
    "name": "Maryville College"
  },
  {
    "id": "220756",
    "name": "Tennessee College of Applied Technology-McKenzie"
  },
  {
    "id": "220765",
    "name": "Genesis Career College-Cookeville"
  },
  {
    "id": "220792",
    "name": "Meharry Medical College"
  },
  {
    "id": "220853",
    "name": "Tennessee College of Applied Technology-Memphis"
  },
  {
    "id": "220862",
    "name": "University of Memphis"
  },
  {
    "id": "220871",
    "name": "Memphis Theological Seminary"
  },
  {
    "id": "220978",
    "name": "Middle Tennessee State University"
  },
  {
    "id": "220996",
    "name": "Middle Tennessee School of Anesthesia Inc"
  },
  {
    "id": "221050",
    "name": "Tennessee College of Applied Technology-Morristown"
  },
  {
    "id": "221096",
    "name": "Motlow State Community College"
  },
  {
    "id": "221102",
    "name": "Tennessee College of Applied Technology-Murfreesboro"
  },
  {
    "id": "221148",
    "name": "Lincoln College of Technology-Nashville"
  },
  {
    "id": "221184",
    "name": "Nashville State Community College"
  },
  {
    "id": "221236",
    "name": "Tennessee College of Applied Technology Northwest"
  },
  {
    "id": "221281",
    "name": "Tennessee College of Applied Technology-Paris"
  },
  {
    "id": "221333",
    "name": "Tennessee College of Applied Technology-Pulaski"
  },
  {
    "id": "221351",
    "name": "Rhodes College"
  },
  {
    "id": "221388",
    "name": "Tennessee College of Applied Technology-Ripley"
  },
  {
    "id": "221397",
    "name": "Roane State Community College"
  },
  {
    "id": "221430",
    "name": "Tennessee College of Applied Technology-Crump"
  },
  {
    "id": "221485",
    "name": "Southwest Tennessee Community College"
  },
  {
    "id": "221494",
    "name": "Tennessee College of Applied Technology-Shelbyville"
  },
  {
    "id": "221519",
    "name": "The University of the South"
  },
  {
    "id": "221582",
    "name": "Tennessee College of Applied Technology-Oneida-Huntsville"
  },
  {
    "id": "221591",
    "name": "Tennessee College of Applied Technology-Crossville"
  },
  {
    "id": "221607",
    "name": "Tennessee College of Applied Technology-McMinnville"
  },
  {
    "id": "221616",
    "name": "Tennessee College of Applied Technology-Jackson"
  },
  {
    "id": "221625",
    "name": "Tennessee College of Applied Technology-Knoxville"
  },
  {
    "id": "221643",
    "name": "Pellissippi State Community College"
  },
  {
    "id": "221661",
    "name": "Southern Adventist University"
  },
  {
    "id": "221670",
    "name": "Southern College of Optometry"
  },
  {
    "id": "221731",
    "name": "Tennessee Wesleyan University"
  },
  {
    "id": "221740",
    "name": "The University of Tennessee-Chattanooga"
  },
  {
    "id": "221768",
    "name": "The University of Tennessee-Martin"
  },
  {
    "id": "221829",
    "name": "Tennessee School of Beauty of Knoxville Inc"
  },
  {
    "id": "221838",
    "name": "Tennessee State University"
  },
  {
    "id": "221847",
    "name": "Tennessee Technological University"
  },
  {
    "id": "221892",
    "name": "Trevecca Nazarene University"
  },
  {
    "id": "221908",
    "name": "Northeast State Community College"
  },
  {
    "id": "221953",
    "name": "Tusculum University"
  },
  {
    "id": "221971",
    "name": "Union University"
  },
  {
    "id": "222053",
    "name": "Volunteer State Community College"
  },
  {
    "id": "222062",
    "name": "Walters State Community College"
  },
  {
    "id": "222105",
    "name": "William R Moore College of Technology"
  },
  {
    "id": "222178",
    "name": "Abilene Christian University"
  },
  {
    "id": "222567",
    "name": "Alvin Community College"
  },
  {
    "id": "222576",
    "name": "Amarillo College"
  },
  {
    "id": "222628",
    "name": "Amberton University"
  },
  {
    "id": "222822",
    "name": "Angelina College"
  },
  {
    "id": "222831",
    "name": "Angelo State University"
  },
  {
    "id": "222877",
    "name": "Arlington Baptist University"
  },
  {
    "id": "222886",
    "name": "Arlington Career Institute"
  },
  {
    "id": "222938",
    "name": "The Art Institute of Houston"
  },
  {
    "id": "222983",
    "name": "Austin College"
  },
  {
    "id": "222992",
    "name": "Austin Community College District"
  },
  {
    "id": "223001",
    "name": "Austin Presbyterian Theological Seminary"
  },
  {
    "id": "223056",
    "name": "Baldwin Beauty School-South Austin"
  },
  {
    "id": "223083",
    "name": "Baptist Health System School of Health Professions"
  },
  {
    "id": "223117",
    "name": "Baptist Missionary Association Theological Seminary"
  },
  {
    "id": "223223",
    "name": "Baylor College of Medicine"
  },
  {
    "id": "223320",
    "name": "Coastal Bend College"
  },
  {
    "id": "223427",
    "name": "Blinn College District"
  },
  {
    "id": "223463",
    "name": "Remington College-Dallas Campus"
  },
  {
    "id": "223472",
    "name": "Vet Tech Institute of Houston"
  },
  {
    "id": "223506",
    "name": "Brazosport College"
  },
  {
    "id": "223816",
    "name": "Central Texas College"
  },
  {
    "id": "223852",
    "name": "Charles and Sues School of Hair Design"
  },
  {
    "id": "223898",
    "name": "Cisco College"
  },
  {
    "id": "223922",
    "name": "Clarendon College"
  },
  {
    "id": "224004",
    "name": "Concordia University Texas"
  },
  {
    "id": "224013",
    "name": "BK Cosmo College of Cosmetology"
  },
  {
    "id": "224110",
    "name": "North Central Texas College"
  },
  {
    "id": "224147",
    "name": "Texas A & M University-Corpus Christi"
  },
  {
    "id": "224156",
    "name": "Paul Mitchell the School-Dallas"
  },
  {
    "id": "224226",
    "name": "Dallas Baptist University"
  },
  {
    "id": "224244",
    "name": "Dallas Christian College"
  },
  {
    "id": "224271",
    "name": "Dallas Institute of Funeral Service"
  },
  {
    "id": "224305",
    "name": "Dallas Theological Seminary"
  },
  {
    "id": "224323",
    "name": "University of Dallas"
  },
  {
    "id": "224350",
    "name": "Del Mar College"
  },
  {
    "id": "224527",
    "name": "East Texas Baptist University"
  },
  {
    "id": "224545",
    "name": "Texas A&M University-Texarkana"
  },
  {
    "id": "224554",
    "name": "Texas A & M University-Commerce"
  },
  {
    "id": "224615",
    "name": "Dallas College"
  },
  {
    "id": "224642",
    "name": "El Paso Community College"
  },
  {
    "id": "224660",
    "name": "Western Technical College"
  },
  {
    "id": "224679",
    "name": "Western Technical College"
  },
  {
    "id": "224712",
    "name": "Episcopal Theological Seminary of the Southwest"
  },
  {
    "id": "224758",
    "name": "Exposito School of Hair Design"
  },
  {
    "id": "224776",
    "name": "Miami International University of Art & Design-Art Institute Dallas"
  },
  {
    "id": "224828",
    "name": "Fort Worth Beauty School"
  },
  {
    "id": "224891",
    "name": "Frank Phillips College"
  },
  {
    "id": "224961",
    "name": "Galveston College"
  },
  {
    "id": "225070",
    "name": "Grayson College"
  },
  {
    "id": "225201",
    "name": "Hallmark University"
  },
  {
    "id": "225247",
    "name": "Hardin-Simmons University"
  },
  {
    "id": "225308",
    "name": "Trinity Valley Community College"
  },
  {
    "id": "225371",
    "name": "Hill College"
  },
  {
    "id": "225399",
    "name": "Houston Baptist University"
  },
  {
    "id": "225414",
    "name": "University of Houston-Clear Lake"
  },
  {
    "id": "225423",
    "name": "Houston Community College"
  },
  {
    "id": "225432",
    "name": "University of Houston-Downtown"
  },
  {
    "id": "225502",
    "name": "University of Houston-Victoria"
  },
  {
    "id": "225511",
    "name": "University of Houston"
  },
  {
    "id": "225520",
    "name": "Howard College"
  },
  {
    "id": "225548",
    "name": "Howard Payne University"
  },
  {
    "id": "225575",
    "name": "Huston-Tillotson University"
  },
  {
    "id": "225627",
    "name": "University of the Incarnate Word"
  },
  {
    "id": "225876",
    "name": "Jacksonville College-Main Campus"
  },
  {
    "id": "225885",
    "name": "Jarvis Christian University"
  },
  {
    "id": "225991",
    "name": "KD Conservatory College of Film and Dramatic Arts"
  },
  {
    "id": "226019",
    "name": "Kilgore College"
  },
  {
    "id": "226091",
    "name": "Lamar University"
  },
  {
    "id": "226107",
    "name": "Lamar State College-Orange"
  },
  {
    "id": "226116",
    "name": "Lamar State College-Port Arthur"
  },
  {
    "id": "226125",
    "name": "Laredo Beauty College Inc"
  },
  {
    "id": "226134",
    "name": "Laredo College"
  },
  {
    "id": "226152",
    "name": "Texas A & M International University"
  },
  {
    "id": "226204",
    "name": "Lee College"
  },
  {
    "id": "226231",
    "name": "LeTourneau University"
  },
  {
    "id": "226277",
    "name": "Lincoln College of Technology-Grand Prairie"
  },
  {
    "id": "226383",
    "name": "Lubbock Christian University"
  },
  {
    "id": "226408",
    "name": "College of the Mainland"
  },
  {
    "id": "226471",
    "name": "University of Mary Hardin-Baylor"
  },
  {
    "id": "226578",
    "name": "McLennan Community College"
  },
  {
    "id": "226587",
    "name": "McMurry University"
  },
  {
    "id": "226675",
    "name": "Covenant School of Nursing and Allied Health"
  },
  {
    "id": "226806",
    "name": "Midland College"
  },
  {
    "id": "226833",
    "name": "Midwestern State University"
  },
  {
    "id": "226860",
    "name": "William Edge Institute"
  },
  {
    "id": "226879",
    "name": "Wade College"
  },
  {
    "id": "226903",
    "name": "Southern Careers Institute-Austin"
  },
  {
    "id": "227058",
    "name": "Tint School of Makeup & Cosmetology"
  },
  {
    "id": "227146",
    "name": "Navarro College"
  },
  {
    "id": "227182",
    "name": "Lone Star College System"
  },
  {
    "id": "227216",
    "name": "University of North Texas"
  },
  {
    "id": "227225",
    "name": "Northeast Texas Community College"
  },
  {
    "id": "227289",
    "name": "Oblate School of Theology"
  },
  {
    "id": "227298",
    "name": "Ocean Corporation"
  },
  {
    "id": "227304",
    "name": "Odessa College"
  },
  {
    "id": "227313",
    "name": "Ogle School Hair Skin Nails-Arlington"
  },
  {
    "id": "227322",
    "name": "Ogle School Hair Skin Nails-Ft Worth"
  },
  {
    "id": "227331",
    "name": "Our Lady of the Lake University"
  },
  {
    "id": "227368",
    "name": "The University of Texas Rio Grande Valley"
  },
  {
    "id": "227377",
    "name": "Texas Southmost College"
  },
  {
    "id": "227386",
    "name": "Panola College"
  },
  {
    "id": "227401",
    "name": "Paris Junior College"
  },
  {
    "id": "227429",
    "name": "Paul Quinn College"
  },
  {
    "id": "227526",
    "name": "Prairie View A & M University"
  },
  {
    "id": "227687",
    "name": "Ranger College"
  },
  {
    "id": "227748",
    "name": "Aviation Institute of Maintenance-Houston"
  },
  {
    "id": "227845",
    "name": "Saint Edward's University"
  },
  {
    "id": "227854",
    "name": "St Philip's College"
  },
  {
    "id": "227863",
    "name": "University of St Thomas"
  },
  {
    "id": "227881",
    "name": "Sam Houston State University"
  },
  {
    "id": "227924",
    "name": "San Antonio College"
  },
  {
    "id": "227979",
    "name": "San Jacinto Community College"
  },
  {
    "id": "228042",
    "name": "Schreiner University"
  },
  {
    "id": "228088",
    "name": "Seguin Beauty School-Seguin"
  },
  {
    "id": "228149",
    "name": "St. Mary's University"
  },
  {
    "id": "228158",
    "name": "South Plains College"
  },
  {
    "id": "228185",
    "name": "South Texas Barber College Inc"
  },
  {
    "id": "228194",
    "name": "South Texas College of Law Houston"
  },
  {
    "id": "228200",
    "name": "South Texas Vocational Technical Institute-Weslaco"
  },
  {
    "id": "228219",
    "name": "Miller-Motte College-STVT-McAllen"
  },
  {
    "id": "228282",
    "name": "Southwest School of Business and Technical Careers-San Antonio"
  },
  {
    "id": "228316",
    "name": "Southwest Texas Junior College"
  },
  {
    "id": "228325",
    "name": "Southwestern Assemblies of God University"
  },
  {
    "id": "228343",
    "name": "Southwestern University"
  },
  {
    "id": "228431",
    "name": "Stephen F Austin State University"
  },
  {
    "id": "228459",
    "name": "Texas State University"
  },
  {
    "id": "228468",
    "name": "Southwestern Adventist University"
  },
  {
    "id": "228486",
    "name": "Southwestern Christian College"
  },
  {
    "id": "228501",
    "name": "Sul Ross State University"
  },
  {
    "id": "228529",
    "name": "Tarleton State University"
  },
  {
    "id": "228547",
    "name": "Tarrant County College District"
  },
  {
    "id": "228608",
    "name": "Temple College"
  },
  {
    "id": "228635",
    "name": "University of Texas Southwestern Medical Center"
  },
  {
    "id": "228644",
    "name": "The University of Texas Health Science Center at San Antonio"
  },
  {
    "id": "228653",
    "name": "The University of Texas Medical Branch at Galveston"
  },
  {
    "id": "228699",
    "name": "Texarkana College"
  },
  {
    "id": "228705",
    "name": "Texas A & M University-Kingsville"
  },
  {
    "id": "228769",
    "name": "The University of Texas at Arlington"
  },
  {
    "id": "228787",
    "name": "The University of Texas at Dallas"
  },
  {
    "id": "228796",
    "name": "The University of Texas at El Paso"
  },
  {
    "id": "228802",
    "name": "The University of Texas at Tyler"
  },
  {
    "id": "228866",
    "name": "Texas Chiropractic College Foundation Inc"
  },
  {
    "id": "228884",
    "name": "Texas College"
  },
  {
    "id": "228909",
    "name": "University of North Texas Health Science Center"
  },
  {
    "id": "228981",
    "name": "Texas Lutheran University"
  },
  {
    "id": "229018",
    "name": "The University of Texas Permian Basin"
  },
  {
    "id": "229027",
    "name": "The University of Texas at San Antonio"
  },
  {
    "id": "229063",
    "name": "Texas Southern University"
  },
  {
    "id": "229115",
    "name": "Texas Tech University"
  },
  {
    "id": "229160",
    "name": "Texas Wesleyan University"
  },
  {
    "id": "229179",
    "name": "Texas Woman's University"
  },
  {
    "id": "229267",
    "name": "Trinity University"
  },
  {
    "id": "229300",
    "name": "The University of Texas Health Science Center at Houston"
  },
  {
    "id": "229337",
    "name": "Texas Tech University Health Sciences Center"
  },
  {
    "id": "229355",
    "name": "Tyler Junior College"
  },
  {
    "id": "229416",
    "name": "Universal Technical Institute of Texas Inc."
  },
  {
    "id": "229425",
    "name": "UCAS University of Cosmetology Arts & Sciences-McAllen"
  },
  {
    "id": "229504",
    "name": "Vernon College"
  },
  {
    "id": "229522",
    "name": "Victoria Beauty & Barber College"
  },
  {
    "id": "229540",
    "name": "Victoria College"
  },
  {
    "id": "229656",
    "name": "Vogue College of Cosmetology-McAllen"
  },
  {
    "id": "229780",
    "name": "Wayland Baptist University"
  },
  {
    "id": "229799",
    "name": "Weatherford College"
  },
  {
    "id": "229814",
    "name": "West Texas A & M University"
  },
  {
    "id": "229832",
    "name": "Western Texas College"
  },
  {
    "id": "229841",
    "name": "Wharton County Junior College"
  },
  {
    "id": "229887",
    "name": "Wiley College"
  },
  {
    "id": "230010",
    "name": "Bridgerland Technical College"
  },
  {
    "id": "230047",
    "name": "Brigham Young University-Hawaii"
  },
  {
    "id": "230056",
    "name": "Broadview College"
  },
  {
    "id": "230065",
    "name": "Cameo College of Essential Beauty"
  },
  {
    "id": "230144",
    "name": "Collectiv Academy"
  },
  {
    "id": "230162",
    "name": "Davis Technical College"
  },
  {
    "id": "230171",
    "name": "Utah Tech University"
  },
  {
    "id": "230199",
    "name": "Evans Hairstyling College-Cedar City"
  },
  {
    "id": "230205",
    "name": "Evans Hairstyling College-St George"
  },
  {
    "id": "230214",
    "name": "Paul Mitchell the School-Salt Lake City"
  },
  {
    "id": "230366",
    "name": "Eagle Gate College-Murray"
  },
  {
    "id": "230418",
    "name": "Ensign College"
  },
  {
    "id": "230490",
    "name": "Ogden-Weber Technical College"
  },
  {
    "id": "230597",
    "name": "Snow College"
  },
  {
    "id": "230603",
    "name": "Southern Utah University"
  },
  {
    "id": "230676",
    "name": "Uintah Basin Technical College"
  },
  {
    "id": "230728",
    "name": "Utah State University"
  },
  {
    "id": "230737",
    "name": "Utah Valley University"
  },
  {
    "id": "230746",
    "name": "Salt Lake Community College"
  },
  {
    "id": "230782",
    "name": "Weber State University"
  },
  {
    "id": "230807",
    "name": "Westminster College"
  },
  {
    "id": "230816",
    "name": "Bennington College"
  },
  {
    "id": "230834",
    "name": "Castleton University"
  },
  {
    "id": "230852",
    "name": "Champlain College"
  },
  {
    "id": "230861",
    "name": "Community College of Vermont"
  },
  {
    "id": "230889",
    "name": "Goddard College"
  },
  {
    "id": "230913",
    "name": "Northern Vermont University"
  },
  {
    "id": "230995",
    "name": "Norwich University"
  },
  {
    "id": "231004",
    "name": "O'Briens Aveda Institute"
  },
  {
    "id": "231059",
    "name": "Saint Michael's College"
  },
  {
    "id": "231068",
    "name": "SIT Graduate Institute"
  },
  {
    "id": "231095",
    "name": "Sterling College"
  },
  {
    "id": "231147",
    "name": "Vermont Law and Graduate School"
  },
  {
    "id": "231165",
    "name": "Vermont Technical College"
  },
  {
    "id": "231280",
    "name": "Paul Mitchell the School-Roanoke"
  },
  {
    "id": "231411",
    "name": "Advanced Technology Institute"
  },
  {
    "id": "231420",
    "name": "Averett University"
  },
  {
    "id": "231536",
    "name": "Blue Ridge Community College"
  },
  {
    "id": "231554",
    "name": "Bluefield University"
  },
  {
    "id": "231581",
    "name": "Bridgewater College"
  },
  {
    "id": "231651",
    "name": "Regent University"
  },
  {
    "id": "231688",
    "name": "Central School of Practical Nursing"
  },
  {
    "id": "231697",
    "name": "Central Virginia Community College"
  },
  {
    "id": "231712",
    "name": "Christopher Newport University"
  },
  {
    "id": "231721",
    "name": "Allegiance Beauty School"
  },
  {
    "id": "231785",
    "name": "Bryant & Stratton College-Virginia Beach"
  },
  {
    "id": "231873",
    "name": "Mountain Gateway Community College"
  },
  {
    "id": "231882",
    "name": "Danville Community College"
  },
  {
    "id": "231970",
    "name": "Eastern Virginia Medical School"
  },
  {
    "id": "232016",
    "name": "Centura College-Virginia Beach"
  },
  {
    "id": "232025",
    "name": "Emory & Henry College"
  },
  {
    "id": "232043",
    "name": "Eastern Mennonite University"
  },
  {
    "id": "232052",
    "name": "Eastern Shore Community College"
  },
  {
    "id": "232089",
    "name": "Ferrum College"
  },
  {
    "id": "232195",
    "name": "Germanna Community College"
  },
  {
    "id": "232256",
    "name": "Hampden-Sydney College"
  },
  {
    "id": "232265",
    "name": "Hampton University"
  },
  {
    "id": "232308",
    "name": "Hollins University"
  },
  {
    "id": "232414",
    "name": "J Sargeant Reynolds Community College"
  },
  {
    "id": "232423",
    "name": "James Madison University"
  },
  {
    "id": "232450",
    "name": "Brightpoint Community College"
  },
  {
    "id": "232557",
    "name": "Liberty University"
  },
  {
    "id": "232566",
    "name": "Longwood University"
  },
  {
    "id": "232575",
    "name": "Laurel Ridge Community College"
  },
  {
    "id": "232609",
    "name": "University of Lynchburg"
  },
  {
    "id": "232618",
    "name": "Centra College"
  },
  {
    "id": "232672",
    "name": "Mary Baldwin University"
  },
  {
    "id": "232681",
    "name": "University of Mary Washington"
  },
  {
    "id": "232706",
    "name": "Marymount University"
  },
  {
    "id": "232724",
    "name": "Sovah School of Health Professions"
  },
  {
    "id": "232788",
    "name": "Mountain Empire Community College"
  },
  {
    "id": "232797",
    "name": "American National University"
  },
  {
    "id": "232867",
    "name": "New River Community College"
  },
  {
    "id": "232885",
    "name": "Sentara College of Health Sciences"
  },
  {
    "id": "232919",
    "name": "Tidewater Tech-Trades"
  },
  {
    "id": "232937",
    "name": "Norfolk State University"
  },
  {
    "id": "232946",
    "name": "Northern Virginia Community College"
  },
  {
    "id": "232982",
    "name": "Old Dominion University"
  },
  {
    "id": "233019",
    "name": "Patrick & Henry Community College"
  },
  {
    "id": "233037",
    "name": "Paul D Camp Community College"
  },
  {
    "id": "233082",
    "name": "Southside College of Health Sciences"
  },
  {
    "id": "233116",
    "name": "Piedmont Virginia Community College"
  },
  {
    "id": "233277",
    "name": "Radford University"
  },
  {
    "id": "233286",
    "name": "Sylvain Melloul International Hair Academy"
  },
  {
    "id": "233295",
    "name": "Randolph-Macon College"
  },
  {
    "id": "233301",
    "name": "Randolph College"
  },
  {
    "id": "233310",
    "name": "Rappahannock Community College"
  },
  {
    "id": "233329",
    "name": "Fortis College-Norfolk"
  },
  {
    "id": "233338",
    "name": "Richard Bland College"
  },
  {
    "id": "233356",
    "name": "Bon Secours Memorial College of Nursing"
  },
  {
    "id": "233408",
    "name": "Riverside College of Health Careers"
  },
  {
    "id": "233426",
    "name": "Roanoke College"
  },
  {
    "id": "233480",
    "name": "Bon Secours St Mary's Hospital School of Medical Imaging"
  },
  {
    "id": "233541",
    "name": "Shenandoah University"
  },
  {
    "id": "233602",
    "name": "Southeastern Free Will Baptist Bible College"
  },
  {
    "id": "233611",
    "name": "Southern Virginia University"
  },
  {
    "id": "233639",
    "name": "Southside Virginia Community College"
  },
  {
    "id": "233648",
    "name": "Southwest Virginia Community College"
  },
  {
    "id": "233666",
    "name": "Staunton School of Cosmetology"
  },
  {
    "id": "233684",
    "name": "Strayer University-Virginia"
  },
  {
    "id": "233718",
    "name": "Sweet Briar College"
  },
  {
    "id": "233754",
    "name": "Virginia Peninsula Community College"
  },
  {
    "id": "233772",
    "name": "Tidewater Community College"
  },
  {
    "id": "233842",
    "name": "Union Presbyterian Seminary"
  },
  {
    "id": "233897",
    "name": "University of Virginia's College at Wise"
  },
  {
    "id": "233903",
    "name": "Virginia Highlands Community College"
  },
  {
    "id": "233949",
    "name": "Virginia Western Community College"
  },
  {
    "id": "234030",
    "name": "Virginia Commonwealth University"
  },
  {
    "id": "234085",
    "name": "Virginia Military Institute"
  },
  {
    "id": "234119",
    "name": "Asher Institute of Hampton"
  },
  {
    "id": "234137",
    "name": "Virginia University of Lynchburg"
  },
  {
    "id": "234155",
    "name": "Virginia State University"
  },
  {
    "id": "234164",
    "name": "Virginia Union University"
  },
  {
    "id": "234173",
    "name": "Virginia Wesleyan University"
  },
  {
    "id": "234377",
    "name": "Wytheville Community College"
  },
  {
    "id": "234669",
    "name": "Bellevue College"
  },
  {
    "id": "234696",
    "name": "Bellingham Technical College"
  },
  {
    "id": "234711",
    "name": "Big Bend Community College"
  },
  {
    "id": "234827",
    "name": "Central Washington University"
  },
  {
    "id": "234845",
    "name": "Centralia College"
  },
  {
    "id": "234915",
    "name": "City University of Seattle"
  },
  {
    "id": "234924",
    "name": "Paul Mitchell the School-Richland"
  },
  {
    "id": "234933",
    "name": "Clark College"
  },
  {
    "id": "234951",
    "name": "Clover Park Technical College"
  },
  {
    "id": "234979",
    "name": "Columbia Basin College"
  },
  {
    "id": "235024",
    "name": "Cornish College of the Arts"
  },
  {
    "id": "235088",
    "name": "Divers Institute of Technology"
  },
  {
    "id": "235097",
    "name": "Eastern Washington University"
  },
  {
    "id": "235103",
    "name": "Edmonds College"
  },
  {
    "id": "235149",
    "name": "Everett Community College"
  },
  {
    "id": "235158",
    "name": "Paroba College of Cosmetology"
  },
  {
    "id": "235167",
    "name": "The Evergreen State College"
  },
  {
    "id": "235237",
    "name": "Pierce College District"
  },
  {
    "id": "235307",
    "name": "Glen Dow Academy of Hair Design"
  },
  {
    "id": "235334",
    "name": "Grays Harbor College"
  },
  {
    "id": "235343",
    "name": "Green River College"
  },
  {
    "id": "235352",
    "name": "Toni & Guy Hairdressing Academy-Shoreline"
  },
  {
    "id": "235422",
    "name": "Heritage University"
  },
  {
    "id": "235431",
    "name": "Highline College"
  },
  {
    "id": "235501",
    "name": "International Air and Hospitality Academy"
  },
  {
    "id": "235547",
    "name": "Bastyr University"
  },
  {
    "id": "235583",
    "name": "Highlights Beauty Schools"
  },
  {
    "id": "235671",
    "name": "Bates Technical College"
  },
  {
    "id": "235699",
    "name": "Lake Washington Institute of Technology"
  },
  {
    "id": "235750",
    "name": "Lower Columbia College"
  },
  {
    "id": "236018",
    "name": "BJ's Beauty & Barber College"
  },
  {
    "id": "236072",
    "name": "North Seattle College"
  },
  {
    "id": "236133",
    "name": "Northwest University"
  },
  {
    "id": "236188",
    "name": "Olympic College"
  },
  {
    "id": "236212",
    "name": "Perry Technical Institute"
  },
  {
    "id": "236230",
    "name": "Pacific Lutheran University"
  },
  {
    "id": "236258",
    "name": "Peninsula College"
  },
  {
    "id": "236328",
    "name": "University of Puget Sound"
  },
  {
    "id": "236382",
    "name": "Renton Technical College"
  },
  {
    "id": "236452",
    "name": "Saint Martin's University"
  },
  {
    "id": "236504",
    "name": "South Seattle College"
  },
  {
    "id": "236513",
    "name": "Seattle Central College"
  },
  {
    "id": "236577",
    "name": "Seattle Pacific University"
  },
  {
    "id": "236610",
    "name": "Shoreline Community College"
  },
  {
    "id": "236638",
    "name": "Skagit Valley College"
  },
  {
    "id": "236656",
    "name": "South Puget Sound Community College"
  },
  {
    "id": "236692",
    "name": "Spokane Community College"
  },
  {
    "id": "236708",
    "name": "Spokane Falls Community College"
  },
  {
    "id": "236744",
    "name": "Stylemaster College of Hair Design"
  },
  {
    "id": "236753",
    "name": "Tacoma Community College"
  },
  {
    "id": "236887",
    "name": "Walla Walla Community College"
  },
  {
    "id": "236896",
    "name": "Walla Walla University"
  },
  {
    "id": "236939",
    "name": "Washington State University"
  },
  {
    "id": "236975",
    "name": "Wenatchee Valley College"
  },
  {
    "id": "237011",
    "name": "Western Washington University"
  },
  {
    "id": "237039",
    "name": "Whatcom Community College"
  },
  {
    "id": "237057",
    "name": "Whitman College"
  },
  {
    "id": "237066",
    "name": "Whitworth University"
  },
  {
    "id": "237109",
    "name": "Yakima Valley College"
  },
  {
    "id": "237118",
    "name": "Alderson Broaddus University"
  },
  {
    "id": "237127",
    "name": "Appalachian Beauty School"
  },
  {
    "id": "237136",
    "name": "Appalachian Bible College"
  },
  {
    "id": "237145",
    "name": "B M Spurr School of Practical Nursing"
  },
  {
    "id": "237172",
    "name": "Ben Franklin Career Center"
  },
  {
    "id": "237181",
    "name": "Bethany College"
  },
  {
    "id": "237215",
    "name": "Bluefield State University"
  },
  {
    "id": "237242",
    "name": "Cabell County Career Technology Center"
  },
  {
    "id": "237303",
    "name": "Charleston School of Beauty Culture"
  },
  {
    "id": "237312",
    "name": "University of Charleston"
  },
  {
    "id": "237321",
    "name": "Clarksburg Beauty Academy and School of Massage Therapy"
  },
  {
    "id": "237330",
    "name": "Concord University"
  },
  {
    "id": "237358",
    "name": "Davis & Elkins College"
  },
  {
    "id": "237367",
    "name": "Fairmont State University"
  },
  {
    "id": "237385",
    "name": "Glenville State University"
  },
  {
    "id": "237437",
    "name": "Huntington Junior College"
  },
  {
    "id": "237473",
    "name": "James Rumsey Technical Institute"
  },
  {
    "id": "237491",
    "name": "Ralph R Willis Career and Technical Center"
  },
  {
    "id": "237525",
    "name": "Marshall University"
  },
  {
    "id": "237543",
    "name": "Mercer County Technical Education Center"
  },
  {
    "id": "237561",
    "name": "Monongalia County Technical Education Center"
  },
  {
    "id": "237570",
    "name": "Morgantown Beauty College Inc"
  },
  {
    "id": "237598",
    "name": "Mountain State College"
  },
  {
    "id": "237686",
    "name": "West Virginia University at Parkersburg"
  },
  {
    "id": "237701",
    "name": "Potomac State College of West Virginia University"
  },
  {
    "id": "237729",
    "name": "Academy of Careers and Technology"
  },
  {
    "id": "237783",
    "name": "Salem University"
  },
  {
    "id": "237792",
    "name": "Shepherd University"
  },
  {
    "id": "237817",
    "name": "Southern West Virginia Community and Technical College"
  },
  {
    "id": "237844",
    "name": "Fred W Eberle Technical Center"
  },
  {
    "id": "237880",
    "name": "West Virginia School of Osteopathic Medicine"
  },
  {
    "id": "237899",
    "name": "West Virginia State University"
  },
  {
    "id": "237905",
    "name": "West Virginia University Hospital Departments of Rad Tech and Nutrition"
  },
  {
    "id": "237932",
    "name": "West Liberty University"
  },
  {
    "id": "237950",
    "name": "West Virginia University Institute of Technology"
  },
  {
    "id": "237969",
    "name": "West Virginia Wesleyan College"
  },
  {
    "id": "237987",
    "name": "West Virginia Junior College-Charleston"
  },
  {
    "id": "237996",
    "name": "West Virginia Junior College-Morgantown"
  },
  {
    "id": "238005",
    "name": "International Beauty School 4"
  },
  {
    "id": "238014",
    "name": "West Virginia Northern Community College"
  },
  {
    "id": "238032",
    "name": "West Virginia University"
  },
  {
    "id": "238078",
    "name": "Wheeling University"
  },
  {
    "id": "238096",
    "name": "Wood County School of Practical Nursing"
  },
  {
    "id": "238175",
    "name": "VICI Beauty School"
  },
  {
    "id": "238193",
    "name": "Alverno College"
  },
  {
    "id": "238263",
    "name": "Madison Area Technical College"
  },
  {
    "id": "238324",
    "name": "Bellin College"
  },
  {
    "id": "238333",
    "name": "Beloit College"
  },
  {
    "id": "238397",
    "name": "Blackhawk Technical College"
  },
  {
    "id": "238430",
    "name": "Cardinal Stritch University"
  },
  {
    "id": "238458",
    "name": "Carroll University"
  },
  {
    "id": "238476",
    "name": "Carthage College"
  },
  {
    "id": "238616",
    "name": "Concordia University-Wisconsin"
  },
  {
    "id": "238661",
    "name": "Edgewood College"
  },
  {
    "id": "238722",
    "name": "Fox Valley Technical College"
  },
  {
    "id": "238759",
    "name": "Gateway Technical College"
  },
  {
    "id": "238810",
    "name": "The Professional Hair Design Academy"
  },
  {
    "id": "238980",
    "name": "Lakeland University"
  },
  {
    "id": "239008",
    "name": "Lakeshore Technical College"
  },
  {
    "id": "239017",
    "name": "Lawrence University"
  },
  {
    "id": "239071",
    "name": "Maranatha Baptist University"
  },
  {
    "id": "239080",
    "name": "Marian University"
  },
  {
    "id": "239169",
    "name": "Medical College of Wisconsin"
  },
  {
    "id": "239220",
    "name": "Mid-State Technical College"
  },
  {
    "id": "239248",
    "name": "Milwaukee Area Technical College"
  },
  {
    "id": "239309",
    "name": "Milwaukee Institute of Art & Design"
  },
  {
    "id": "239318",
    "name": "Milwaukee School of Engineering"
  },
  {
    "id": "239372",
    "name": "Moraine Park Technical College"
  },
  {
    "id": "239390",
    "name": "Mount Mary University"
  },
  {
    "id": "239424",
    "name": "Nashotah House"
  },
  {
    "id": "239442",
    "name": "Nicolet Area Technical College"
  },
  {
    "id": "239460",
    "name": "Northcentral Technical College"
  },
  {
    "id": "239488",
    "name": "Northeast Wisconsin Technical College"
  },
  {
    "id": "239512",
    "name": "Northland College"
  },
  {
    "id": "239628",
    "name": "Ripon College"
  },
  {
    "id": "239637",
    "name": "Sacred Heart Seminary and School of Theology"
  },
  {
    "id": "239716",
    "name": "Saint Norbert College"
  },
  {
    "id": "239877",
    "name": "State College of Beauty Culture Inc"
  },
  {
    "id": "239910",
    "name": "Southwest Wisconsin Technical College"
  },
  {
    "id": "240107",
    "name": "Viterbo University"
  },
  {
    "id": "240116",
    "name": "Chippewa Valley Technical College"
  },
  {
    "id": "240125",
    "name": "Waukesha County Technical College"
  },
  {
    "id": "240170",
    "name": "Western Technical College"
  },
  {
    "id": "240189",
    "name": "University of Wisconsin-Whitewater"
  },
  {
    "id": "240198",
    "name": "Northwood Technical College"
  },
  {
    "id": "240213",
    "name": "Wisconsin School of Professional Psychology"
  },
  {
    "id": "240268",
    "name": "University of Wisconsin-Eau Claire"
  },
  {
    "id": "240277",
    "name": "University of Wisconsin-Green Bay"
  },
  {
    "id": "240329",
    "name": "University of Wisconsin-La Crosse"
  },
  {
    "id": "240338",
    "name": "Wisconsin Lutheran College"
  },
  {
    "id": "240365",
    "name": "University of Wisconsin-Oshkosh"
  },
  {
    "id": "240374",
    "name": "University of Wisconsin-Parkside"
  },
  {
    "id": "240392",
    "name": "Herzing University-Madison"
  },
  {
    "id": "240417",
    "name": "University of Wisconsin-Stout"
  },
  {
    "id": "240426",
    "name": "University of Wisconsin-Superior"
  },
  {
    "id": "240453",
    "name": "University of Wisconsin-Milwaukee"
  },
  {
    "id": "240462",
    "name": "University of Wisconsin-Platteville"
  },
  {
    "id": "240471",
    "name": "University of Wisconsin-River Falls"
  },
  {
    "id": "240480",
    "name": "University of Wisconsin-Stevens Point"
  },
  {
    "id": "240505",
    "name": "Casper College"
  },
  {
    "id": "240514",
    "name": "Central Wyoming College"
  },
  {
    "id": "240596",
    "name": "Eastern Wyoming College"
  },
  {
    "id": "240620",
    "name": "Laramie County Community College"
  },
  {
    "id": "240657",
    "name": "Northwest College"
  },
  {
    "id": "240666",
    "name": "Northern Wyoming Community College District"
  },
  {
    "id": "240693",
    "name": "Western Wyoming Community College"
  },
  {
    "id": "240709",
    "name": "Cheeks Beauty Academy"
  },
  {
    "id": "240718",
    "name": "WyoTech"
  },
  {
    "id": "240727",
    "name": "University of Wyoming"
  },
  {
    "id": "240736",
    "name": "American Samoa Community College"
  },
  {
    "id": "240745",
    "name": "Guam Community College"
  },
  {
    "id": "240754",
    "name": "University of Guam"
  },
  {
    "id": "240790",
    "name": "Northern Marianas College"
  },
  {
    "id": "240879",
    "name": "Institute of Beauty Careers"
  },
  {
    "id": "241100",
    "name": "American University of Puerto Rico"
  },
  {
    "id": "241128",
    "name": "American University of Puerto Rico"
  },
  {
    "id": "241146",
    "name": "American Educational College"
  },
  {
    "id": "241182",
    "name": "Antilles School of Technical Careers"
  },
  {
    "id": "241191",
    "name": "Universidad Adventista de las Antillas"
  },
  {
    "id": "241216",
    "name": "Atlantic University College"
  },
  {
    "id": "241225",
    "name": "Universidad Central de Bayamon"
  },
  {
    "id": "241304",
    "name": "Columbia Central University-Caguas"
  },
  {
    "id": "241331",
    "name": "Albizu University-San Juan"
  },
  {
    "id": "241377",
    "name": "Caribbean University-Bayamon"
  },
  {
    "id": "241386",
    "name": "Caribbean University-Carolina"
  },
  {
    "id": "241395",
    "name": "Pontifical Catholic University of Puerto Rico-Arecibo"
  },
  {
    "id": "241410",
    "name": "Pontifical Catholic University of Puerto Rico-Ponce"
  },
  {
    "id": "241517",
    "name": "CEM College-San Juan"
  },
  {
    "id": "241614",
    "name": "Universidad Teologica del Caribe"
  },
  {
    "id": "241720",
    "name": "Colegio Universitario de San Juan"
  },
  {
    "id": "241739",
    "name": "Universidad Ana G. Mendez-Cupey Campus"
  },
  {
    "id": "241766",
    "name": "Puerto Rico Conservatory of Music"
  },
  {
    "id": "241793",
    "name": "Center for Advanced Studies On Puerto Rico and the Caribbean"
  },
  {
    "id": "241836",
    "name": "EDP University of Puerto Rico Inc-San Sebastian"
  },
  {
    "id": "241906",
    "name": "Emma's Beauty Academy-Mayaguez"
  },
  {
    "id": "241951",
    "name": "Escuela de Artes Plasticas y Diseno de Puerto Rico"
  },
  {
    "id": "242042",
    "name": "Instituto Tecnologico de Puerto Rico-Recinto de Manati"
  },
  {
    "id": "242112",
    "name": "Huertas College"
  },
  {
    "id": "242121",
    "name": "Humacao Community College"
  },
  {
    "id": "242130",
    "name": "ICPR Junior College-Arecibo"
  },
  {
    "id": "242149",
    "name": "ICPR Junior College-Mayaguez"
  },
  {
    "id": "242413",
    "name": "Richport Technical College"
  },
  {
    "id": "242556",
    "name": "Instituto Tecnologico de Puerto Rico-Recinto de Guayama"
  },
  {
    "id": "242565",
    "name": "Instituto Tecnologico de Puerto Rico-Recinto de Ponce"
  },
  {
    "id": "242583",
    "name": "Instituto Tecnologico de Puerto Rico-Recinto de San Juan"
  },
  {
    "id": "242617",
    "name": "Inter American University of Puerto Rico-San German"
  },
  {
    "id": "242626",
    "name": "Inter American University of Puerto Rico-Aguadilla"
  },
  {
    "id": "242635",
    "name": "Inter American University of Puerto Rico-Arecibo"
  },
  {
    "id": "242644",
    "name": "Inter American University of Puerto Rico-Barranquitas"
  },
  {
    "id": "242653",
    "name": "Inter American University of Puerto Rico-Metro"
  },
  {
    "id": "242662",
    "name": "Inter American University of Puerto Rico-Ponce"
  },
  {
    "id": "242680",
    "name": "Inter American University of Puerto Rico-Fajardo"
  },
  {
    "id": "242699",
    "name": "Inter American University of Puerto Rico-Guayama"
  },
  {
    "id": "242705",
    "name": "Inter American University of Puerto Rico-Bayamon"
  },
  {
    "id": "242723",
    "name": "Inter American University of Puerto Rico-School of Law"
  },
  {
    "id": "242750",
    "name": "International Technical College"
  },
  {
    "id": "242820",
    "name": "Liceo de Arte y Tecnologia"
  },
  {
    "id": "242945",
    "name": "Modern Hairstyling Institute-Bayamon"
  },
  {
    "id": "242954",
    "name": "Modern Hairstyling Institute-Arecibo"
  },
  {
    "id": "242972",
    "name": "NUC University"
  },
  {
    "id": "243081",
    "name": "Ponce Health Sciences University"
  },
  {
    "id": "243106",
    "name": "University of Puerto Rico-Aguadilla"
  },
  {
    "id": "243115",
    "name": "University of Puerto Rico-Arecibo"
  },
  {
    "id": "243133",
    "name": "University of Puerto Rico-Bayamon"
  },
  {
    "id": "243142",
    "name": "University of Puerto Rico-Carolina"
  },
  {
    "id": "243151",
    "name": "University of Puerto Rico-Cayey"
  },
  {
    "id": "243179",
    "name": "University of Puerto Rico-Humacao"
  },
  {
    "id": "243188",
    "name": "University of Puerto Rico-Utuado"
  },
  {
    "id": "243197",
    "name": "University of Puerto Rico-Mayaguez"
  },
  {
    "id": "243203",
    "name": "University of Puerto Rico-Medical Sciences"
  },
  {
    "id": "243212",
    "name": "University of Puerto Rico at Ponce"
  },
  {
    "id": "243221",
    "name": "University of Puerto Rico-Rio Piedras"
  },
  {
    "id": "243346",
    "name": "Universidad Ana G. Mendez-Carolina Campus"
  },
  {
    "id": "243443",
    "name": "Universidad del Sagrado Corazon"
  },
  {
    "id": "243498",
    "name": "Seminario Evangelico de Puerto Rico"
  },
  {
    "id": "243568",
    "name": "Universidad Central Del Caribe"
  },
  {
    "id": "243577",
    "name": "Universidad Politecnica de Puerto Rico"
  },
  {
    "id": "243586",
    "name": "Pontifical Catholic University of Puerto Rico-Mayaguez"
  },
  {
    "id": "243601",
    "name": "Universidad Ana G. Mendez-Gurabo Campus"
  },
  {
    "id": "243638",
    "name": "College of Micronesia-FSM"
  },
  {
    "id": "243647",
    "name": "Palau Community College"
  },
  {
    "id": "243665",
    "name": "University of the Virgin Islands"
  },
  {
    "id": "243799",
    "name": "Blue Hills Regional Technical School"
  },
  {
    "id": "243823",
    "name": "Parker University"
  },
  {
    "id": "243832",
    "name": "EDP University of Puerto Rico Inc-San Juan"
  },
  {
    "id": "243841",
    "name": "ICPR Junior College"
  },
  {
    "id": "244233",
    "name": "City College-Fort Lauderdale"
  },
  {
    "id": "244279",
    "name": "Meridian College"
  },
  {
    "id": "244437",
    "name": "Georgia State University-Perimeter College"
  },
  {
    "id": "244446",
    "name": "Georgia Piedmont Technical College"
  },
  {
    "id": "244613",
    "name": "ETI School of Skilled Trades"
  },
  {
    "id": "245281",
    "name": "Traxlers School of Hair"
  },
  {
    "id": "245625",
    "name": "Warren County Community College"
  },
  {
    "id": "245652",
    "name": "St. John's College"
  },
  {
    "id": "245777",
    "name": "Bais Medrash Elyon"
  },
  {
    "id": "245838",
    "name": "Antioch University-Los Angeles"
  },
  {
    "id": "245847",
    "name": "Antioch University-Santa Barbara"
  },
  {
    "id": "245865",
    "name": "Antioch University-New England"
  },
  {
    "id": "245883",
    "name": "Antioch University-Seattle"
  },
  {
    "id": "245892",
    "name": "Antioch University"
  },
  {
    "id": "245953",
    "name": "Mid-America Christian University"
  },
  {
    "id": "245962",
    "name": "Miller-Motte College-Tulsa"
  },
  {
    "id": "245980",
    "name": "Ponca City Beauty College"
  },
  {
    "id": "245999",
    "name": "Francis Tuttle Technology Center"
  },
  {
    "id": "246017",
    "name": "Central Technology Center"
  },
  {
    "id": "246035",
    "name": "Carrington College-Portland"
  },
  {
    "id": "246071",
    "name": "Career Technology Center of Lackawanna County"
  },
  {
    "id": "246345",
    "name": "Houston Graduate School of Theology"
  },
  {
    "id": "246354",
    "name": "Palo Alto College"
  },
  {
    "id": "246406",
    "name": "Sebring Career Schools-Huntsville"
  },
  {
    "id": "246415",
    "name": "UCAS University of Cosmetology Arts & Sciences-San Antonio 410"
  },
  {
    "id": "246460",
    "name": "Central Texas Beauty College-Round Rock"
  },
  {
    "id": "246789",
    "name": "Unification Theological Seminary"
  },
  {
    "id": "246813",
    "name": "Athens Technical College"
  },
  {
    "id": "246895",
    "name": "GateWay Community College-Central City"
  },
  {
    "id": "246974",
    "name": "Carrington College-San Leandro Campus"
  },
  {
    "id": "247010",
    "name": "Headmasters School of Hair Design"
  },
  {
    "id": "247047",
    "name": "The Salon Professional Academy-Nampa"
  },
  {
    "id": "247065",
    "name": "Beckfield College-Florence"
  },
  {
    "id": "247153",
    "name": "Dorsey College-Wayne"
  },
  {
    "id": "247162",
    "name": "Ecumenical Theological Seminary"
  },
  {
    "id": "247409",
    "name": "Ross Medical Education Center-Saginaw"
  },
  {
    "id": "247603",
    "name": "Sussex County Community College"
  },
  {
    "id": "247649",
    "name": "Landmark College"
  },
  {
    "id": "247773",
    "name": "Yeshiva Gedolah of Greater Detroit"
  },
  {
    "id": "247825",
    "name": "Austin Graduate School of Theology"
  },
  {
    "id": "247834",
    "name": "Collin County Community College District"
  },
  {
    "id": "247940",
    "name": "Owensboro Community and Technical College"
  },
  {
    "id": "248192",
    "name": "Allure Beauty College"
  },
  {
    "id": "248527",
    "name": "Cloyd's Beauty School 3 Inc"
  },
  {
    "id": "248606",
    "name": "Moore Norman Technology Center"
  },
  {
    "id": "248624",
    "name": "Fayette County Career & Technical Institute Practical Nursing Program"
  },
  {
    "id": "248633",
    "name": "Franklin County Career and Technology Center"
  },
  {
    "id": "248660",
    "name": "Paul Mitchell the School-Knoxville"
  },
  {
    "id": "248703",
    "name": "Ogle School Hair Skin Nails-Hurst"
  },
  {
    "id": "248925",
    "name": "Tennessee College of Applied Technology Nashville"
  },
  {
    "id": "248934",
    "name": "ECPI University"
  },
  {
    "id": "249061",
    "name": "Ms Roberts Academy of Beauty Culture"
  },
  {
    "id": "249238",
    "name": "Paul Mitchell the School-Houston"
  },
  {
    "id": "249609",
    "name": "Fortis Institute-Forty Fort"
  },
  {
    "id": "250027",
    "name": "Laurel Business Institute"
  },
  {
    "id": "250285",
    "name": "Aviation Institute of Maintenance-Philadelphia"
  },
  {
    "id": "250744",
    "name": "Dorsey College-Roseville"
  },
  {
    "id": "250902",
    "name": "Moler-Pickens Beauty Academy"
  },
  {
    "id": "250993",
    "name": "Red River Technology Center"
  },
  {
    "id": "251260",
    "name": "Southern Union State Community College"
  },
  {
    "id": "251312",
    "name": "South University-Columbia"
  },
  {
    "id": "260336",
    "name": "Clinton Technical School"
  },
  {
    "id": "260363",
    "name": "Greene County Career and Technology Center"
  },
  {
    "id": "260372",
    "name": "Lac Courte Oreilles Ojibwe University"
  },
  {
    "id": "260664",
    "name": "Diesel Driving Academy-Shreveport"
  },
  {
    "id": "260691",
    "name": "Pima Medical Institute-Mesa"
  },
  {
    "id": "260789",
    "name": "Platt College-Los Angeles"
  },
  {
    "id": "260813",
    "name": "Platt College-Aurora"
  },
  {
    "id": "260929",
    "name": "Paul Mitchell the School-Boise"
  },
  {
    "id": "260965",
    "name": "Educators of Beauty College of Cosmetology-Rockford"
  },
  {
    "id": "260992",
    "name": "Northwestern College"
  },
  {
    "id": "261436",
    "name": "Douglas J Aveda Institute"
  },
  {
    "id": "261676",
    "name": "Ross Medical Education Center-Brighton"
  },
  {
    "id": "261685",
    "name": "Ross Medical Education Center-Ann Arbor"
  },
  {
    "id": "261719",
    "name": "Taylortown School of Beauty Inc"
  },
  {
    "id": "261773",
    "name": "Pike-Lincoln Technical Center"
  },
  {
    "id": "261861",
    "name": "West Virginia Junior College-United Career Institute"
  },
  {
    "id": "262022",
    "name": "Bethel Seminary-San Diego"
  },
  {
    "id": "262031",
    "name": "St Charles Community College"
  },
  {
    "id": "262086",
    "name": "University of Massachusetts Global"
  },
  {
    "id": "262129",
    "name": "New College of Florida"
  },
  {
    "id": "262165",
    "name": "Montana Bible College"
  },
  {
    "id": "262341",
    "name": "Conservatory of Recording Arts and Sciences"
  },
  {
    "id": "262369",
    "name": "Adrian's Beauty College of Turlock"
  },
  {
    "id": "262457",
    "name": "San Joaquin Valley College-Fresno"
  },
  {
    "id": "262466",
    "name": "Dr. Ida Rolf Institute"
  },
  {
    "id": "362700",
    "name": "Aveda Institute-Fort Myers"
  },
  {
    "id": "362782",
    "name": "Elizabeth Grady School of Esthetics and Massage Therapy"
  },
  {
    "id": "363013",
    "name": "Ross Medical Education Center-Port Huron"
  },
  {
    "id": "363077",
    "name": "Capri Institute of Hair Design-Clifton"
  },
  {
    "id": "363147",
    "name": "Raphael's School of Beauty Culture Inc-Alliance"
  },
  {
    "id": "363165",
    "name": "Metro Technology Centers"
  },
  {
    "id": "363633",
    "name": "Luna Community College"
  },
  {
    "id": "363679",
    "name": "Grabber School of Hair Design"
  },
  {
    "id": "363712",
    "name": "Yeshivah Gedolah Rabbinical College"
  },
  {
    "id": "363882",
    "name": "Raphael's School of Beauty Culture Inc-Brunswick"
  },
  {
    "id": "363907",
    "name": "Caribbean University-Ponce"
  },
  {
    "id": "363916",
    "name": "Caribbean University-Vega Baja"
  },
  {
    "id": "363934",
    "name": "University of Advancing Technology"
  },
  {
    "id": "364016",
    "name": "Paradise Valley Community College"
  },
  {
    "id": "364025",
    "name": "Chandler-Gilbert Community College"
  },
  {
    "id": "364548",
    "name": "Great Plains Technology Center"
  },
  {
    "id": "364575",
    "name": "Roane-Jackson Technical Center"
  },
  {
    "id": "364627",
    "name": "Pioneer Technology Center"
  },
  {
    "id": "364636",
    "name": "Portage Lakes Career Center"
  },
  {
    "id": "364751",
    "name": "Eastern College of Health Vocations-New Orleans"
  },
  {
    "id": "364760",
    "name": "Bell Mar Beauty College"
  },
  {
    "id": "364946",
    "name": "Caddo Kiowa Technology Center"
  },
  {
    "id": "364964",
    "name": "Center for Instruction Technology & Innovation"
  },
  {
    "id": "365091",
    "name": "International School of Skin Nailcare & Massage Therapy"
  },
  {
    "id": "365125",
    "name": "Palomar Institute of Cosmetology"
  },
  {
    "id": "365198",
    "name": "Southern Oklahoma Technology Center"
  },
  {
    "id": "365213",
    "name": "Autry Technology Center"
  },
  {
    "id": "365240",
    "name": "Greene County Career Center"
  },
  {
    "id": "365374",
    "name": "Canadian Valley Technology Center"
  },
  {
    "id": "365383",
    "name": "Wilkes-Barre Area Career and Technical Center Practical Nursing"
  },
  {
    "id": "365480",
    "name": "Meridian Technology Center"
  },
  {
    "id": "365505",
    "name": "Waynesville Career Center"
  },
  {
    "id": "365693",
    "name": "Capitol Beauty School"
  },
  {
    "id": "365763",
    "name": "Morris County Vocational School District"
  },
  {
    "id": "365824",
    "name": "Ross Medical Education Center-Roosevelt Park"
  },
  {
    "id": "365833",
    "name": "Ross Medical Education Center-Kentwood"
  },
  {
    "id": "366003",
    "name": "South Florida Bible College and Theological Seminary"
  },
  {
    "id": "366155",
    "name": "Cloyd's Barber School 2 Inc"
  },
  {
    "id": "366191",
    "name": "Universal Technical Institute of Illinois Inc"
  },
  {
    "id": "366252",
    "name": "Pennsylvania College of Technology"
  },
  {
    "id": "366261",
    "name": "Commonwealth Institute of Funeral Service"
  },
  {
    "id": "366270",
    "name": "Delta College of Arts & Technology"
  },
  {
    "id": "366289",
    "name": "InterCoast Colleges-Santa Ana"
  },
  {
    "id": "366340",
    "name": "Stone Child College"
  },
  {
    "id": "366368",
    "name": "New York Academy of Art"
  },
  {
    "id": "366395",
    "name": "Suffolk County Community College"
  },
  {
    "id": "366401",
    "name": "Las Positas College"
  },
  {
    "id": "366438",
    "name": "Blades School of Hair Design"
  },
  {
    "id": "366465",
    "name": "Ogeechee Technical College"
  },
  {
    "id": "366535",
    "name": "Remington College-Mobile Campus"
  },
  {
    "id": "366553",
    "name": "Southern Technical College"
  },
  {
    "id": "366571",
    "name": "Mercy-St Luke's School of Radiologic Technology"
  },
  {
    "id": "366605",
    "name": "Southwest Acupuncture College-Santa Fe"
  },
  {
    "id": "366623",
    "name": "Northwest Technology Center-Alva"
  },
  {
    "id": "366632",
    "name": "Mercer County Career Center"
  },
  {
    "id": "366711",
    "name": "California State University-San Marcos"
  },
  {
    "id": "367051",
    "name": "Hairmasters Institute of Cosmetology"
  },
  {
    "id": "367088",
    "name": "Tricoci University of Beauty Culture LLC-Bloomington"
  },
  {
    "id": "367103",
    "name": "Interactive College of Technology-Newport"
  },
  {
    "id": "367112",
    "name": "Miller-Motte College-McCann-Monroe"
  },
  {
    "id": "367158",
    "name": "Ross Medical Education Center-Taylor"
  },
  {
    "id": "367334",
    "name": "National Tractor Trailer School Inc-Liverpool"
  },
  {
    "id": "367361",
    "name": "Interactive College of Technology"
  },
  {
    "id": "367431",
    "name": "Butler Beauty Academy-New Castle Beauty Academy"
  },
  {
    "id": "367459",
    "name": "NorthWest Arkansas Community College"
  },
  {
    "id": "367875",
    "name": "Brewster Technical College"
  },
  {
    "id": "367884",
    "name": "Hodges University"
  },
  {
    "id": "367954",
    "name": "University of St. Augustine for Health Sciences"
  },
  {
    "id": "367972",
    "name": "Capital Area School of Practical Nursing"
  },
  {
    "id": "367981",
    "name": "Hair Professionals Career College"
  },
  {
    "id": "368230",
    "name": "Empire Beauty School-Bloomfield"
  },
  {
    "id": "368364",
    "name": "Southwest Technology Center"
  },
  {
    "id": "368407",
    "name": "Western Area Career & Technology Center"
  },
  {
    "id": "368443",
    "name": "Hussian College-Daymar College Clarksville"
  },
  {
    "id": "368452",
    "name": "Nossi College of Art"
  },
  {
    "id": "368540",
    "name": "Seguin Beauty School-New Braunfels"
  },
  {
    "id": "368629",
    "name": "Pima Medical Institute-Seattle"
  },
  {
    "id": "368647",
    "name": "Mineral County Vocational Technical Center"
  },
  {
    "id": "368771",
    "name": "Hypnosis Motivation Institute"
  },
  {
    "id": "368832",
    "name": "Thanh Le College School of Cosmetology"
  },
  {
    "id": "368911",
    "name": "Southeastern Technical College"
  },
  {
    "id": "369002",
    "name": "Massachusetts School of Law"
  },
  {
    "id": "369109",
    "name": "Alliance Career Center"
  },
  {
    "id": "369400",
    "name": "Universal Technical Institute-Auto Motorcycle & Marine Mechanics Institute Division-Orlando"
  },
  {
    "id": "369419",
    "name": "Erwin Technical College"
  },
  {
    "id": "369446",
    "name": "Rosel School of Cosmetology"
  },
  {
    "id": "369455",
    "name": "Worsham College of Mortuary Science"
  },
  {
    "id": "369516",
    "name": "Bryan University"
  },
  {
    "id": "369659",
    "name": "Oregon College of Oriental Medicine"
  },
  {
    "id": "369668",
    "name": "Central Pennsylvania Institute of Science and Technology"
  },
  {
    "id": "369686",
    "name": "Northwest Educational Center"
  },
  {
    "id": "369695",
    "name": "Sebring Career Schools-Houston"
  },
  {
    "id": "369783",
    "name": "PCI Health Training Center"
  },
  {
    "id": "369950",
    "name": "Hamrick School"
  },
  {
    "id": "371052",
    "name": "Fortis College"
  },
  {
    "id": "371690",
    "name": "Downey Adult School"
  },
  {
    "id": "371830",
    "name": "CRU Institute of Cosmetology and Barbering"
  },
  {
    "id": "371928",
    "name": "Modern Technology School"
  },
  {
    "id": "371964",
    "name": "National Career Education"
  },
  {
    "id": "372028",
    "name": "North-West College-Glendale"
  },
  {
    "id": "372073",
    "name": "Fremont University"
  },
  {
    "id": "372082",
    "name": "Pomona Unified School District Adult and Career Education"
  },
  {
    "id": "372240",
    "name": "Universal College of Beauty Inc-Los Angeles 2"
  },
  {
    "id": "372329",
    "name": "IBMC College"
  },
  {
    "id": "372356",
    "name": "Avalon Institute-Aurora"
  },
  {
    "id": "372462",
    "name": "Academy of Cosmetology"
  },
  {
    "id": "372718",
    "name": "Sunstate Academy"
  },
  {
    "id": "372921",
    "name": "Fortis College-Smyrna"
  },
  {
    "id": "373085",
    "name": "Ross College-Davenport"
  },
  {
    "id": "373216",
    "name": "Pivot Point Academy"
  },
  {
    "id": "373456",
    "name": "Blalock's Professional Beauty College"
  },
  {
    "id": "373526",
    "name": "Diesel Driving Academy-Baton Rouge"
  },
  {
    "id": "373678",
    "name": "New England Hair Academy"
  },
  {
    "id": "373696",
    "name": "Rob Roy Academy-Worcester"
  },
  {
    "id": "373711",
    "name": "Upper Cape Cod Regional Technical School"
  },
  {
    "id": "373784",
    "name": "All-State Career-Baltimore"
  },
  {
    "id": "374024",
    "name": "Adler Graduate School"
  },
  {
    "id": "374316",
    "name": "Academy of Hair Design-Jackson"
  },
  {
    "id": "374343",
    "name": "KC's School of Hair Design"
  },
  {
    "id": "374574",
    "name": "Lincoln Technical Institute-Moorestown"
  },
  {
    "id": "374592",
    "name": "MCVSD"
  },
  {
    "id": "375106",
    "name": "National Tractor Trailer School Inc-Buffalo"
  },
  {
    "id": "375230",
    "name": "Yeshiva Gedolah Imrei Yosef D'spinka"
  },
  {
    "id": "375373",
    "name": "Knox County Career Center"
  },
  {
    "id": "375407",
    "name": "Madison Adult Career Center"
  },
  {
    "id": "375416",
    "name": "Remington College-Cleveland Campus"
  },
  {
    "id": "375540",
    "name": "Toledo Academy of Beauty Culture-East"
  },
  {
    "id": "375568",
    "name": "Brown & Clermont Adult Career Campuses"
  },
  {
    "id": "375656",
    "name": "Chisholm Trail Technology Center"
  },
  {
    "id": "375683",
    "name": "Gordon Cooper Technology Center"
  },
  {
    "id": "375726",
    "name": "Kiamichi Technology Center-McAlester"
  },
  {
    "id": "375939",
    "name": "YTI Career Institute-Altoona"
  },
  {
    "id": "375966",
    "name": "Eastern Center for Arts and Technology"
  },
  {
    "id": "375984",
    "name": "Empire Beauty School-Center City Philadelphia"
  },
  {
    "id": "376224",
    "name": "CEM College-Humacao"
  },
  {
    "id": "376242",
    "name": "Colegio Mayor de Tecnologia Inc"
  },
  {
    "id": "376288",
    "name": "Institucion Chaviano de Mayaguez"
  },
  {
    "id": "376330",
    "name": "Liceo de Arte-Dise-O y Comercio"
  },
  {
    "id": "376385",
    "name": "Universal Technology College of Puerto Rico"
  },
  {
    "id": "376446",
    "name": "Academy of Hair Technology"
  },
  {
    "id": "376631",
    "name": "Mr Wayne's School of Unisex Hair Design"
  },
  {
    "id": "376677",
    "name": "Queen City College"
  },
  {
    "id": "376695",
    "name": "College of the Marshall Islands"
  },
  {
    "id": "377111",
    "name": "Remington College-Fort Worth Campus"
  },
  {
    "id": "377193",
    "name": "UCAS University of Cosmetology Arts & Sciences"
  },
  {
    "id": "377218",
    "name": "School of Automotive Machinists & Technology"
  },
  {
    "id": "377272",
    "name": "Nuvani Institute"
  },
  {
    "id": "377281",
    "name": "Nuvani Institute"
  },
  {
    "id": "377315",
    "name": "Texas College of Cosmetology-Abilene"
  },
  {
    "id": "377449",
    "name": "Centura College-Newport News"
  },
  {
    "id": "377458",
    "name": "Centura College-Norfolk"
  },
  {
    "id": "377485",
    "name": "Virginia Beach City Public Schools School of Practical Nursing"
  },
  {
    "id": "377546",
    "name": "Northwest College of Art & Design"
  },
  {
    "id": "377555",
    "name": "University of Washington-Bothell Campus"
  },
  {
    "id": "377564",
    "name": "University of Washington-Tacoma Campus"
  },
  {
    "id": "377643",
    "name": "Huntington School of Beauty Culture"
  },
  {
    "id": "377652",
    "name": "Valley College-Beckley"
  },
  {
    "id": "377661",
    "name": "Valley College-Martinsburg"
  },
  {
    "id": "377722",
    "name": "Bellus Academy-Poway"
  },
  {
    "id": "377759",
    "name": "Capstone College"
  },
  {
    "id": "378105",
    "name": "Central Coast College"
  },
  {
    "id": "378576",
    "name": "Pacific College of Health and Science"
  },
  {
    "id": "378886",
    "name": "Artistic Nails and Beauty Academy-Tampa"
  },
  {
    "id": "378956",
    "name": "National Aviation Academy of Tampa Bay"
  },
  {
    "id": "379621",
    "name": "Madison Oneida BOCES-Practical Nursing Program"
  },
  {
    "id": "379746",
    "name": "College of Health Care Professions"
  },
  {
    "id": "379782",
    "name": "Center for Advanced Legal Studies"
  },
  {
    "id": "380067",
    "name": "Houston Training School-Main Campus"
  },
  {
    "id": "380359",
    "name": "Bay Mills Community College"
  },
  {
    "id": "380368",
    "name": "Fond du Lac Tribal and Community College"
  },
  {
    "id": "380377",
    "name": "Northwest Indian College"
  },
  {
    "id": "380438",
    "name": "Provo College"
  },
  {
    "id": "381255",
    "name": "CET-Coachella"
  },
  {
    "id": "381282",
    "name": "CET-Oxnard"
  },
  {
    "id": "381325",
    "name": "CET-Santa Maria"
  },
  {
    "id": "381352",
    "name": "Colorado School of Traditional Chinese Medicine"
  },
  {
    "id": "381370",
    "name": "Michigan College of Beauty-Troy"
  },
  {
    "id": "381404",
    "name": "Ogle School Hair Skin Nails-Dallas"
  },
  {
    "id": "381459",
    "name": "Phoenix Seminary"
  },
  {
    "id": "381486",
    "name": "Faust Institute of Cosmetology-Spirit Lake"
  },
  {
    "id": "381699",
    "name": "Cheeks Beauty Academy"
  },
  {
    "id": "381732",
    "name": "Colorado School of Healing Arts"
  },
  {
    "id": "382258",
    "name": "Mt. Diablo Adult Education-Mt. Diablo USD"
  },
  {
    "id": "382416",
    "name": "Greater Lowell Technical School"
  },
  {
    "id": "382461",
    "name": "Academy of Hair Design-Pearl"
  },
  {
    "id": "382780",
    "name": "Genesis Career College-Lebanon"
  },
  {
    "id": "382911",
    "name": "Southwest College for the Deaf"
  },
  {
    "id": "382957",
    "name": "Fortis College-Richmond"
  },
  {
    "id": "383020",
    "name": "Midwest College of Oriental Medicine-Racine"
  },
  {
    "id": "383084",
    "name": "Hacienda La Puente Adult Education"
  },
  {
    "id": "383163",
    "name": "La Belle Beauty Academy"
  },
  {
    "id": "383190",
    "name": "Hawaii Community College"
  },
  {
    "id": "383279",
    "name": "Robert Fiance Beauty Schools-North Plainfield"
  },
  {
    "id": "383297",
    "name": "Long Island Beauty School-Hauppauge"
  },
  {
    "id": "383312",
    "name": "Buckeye Joint Vocational School"
  },
  {
    "id": "383330",
    "name": "Sandusky Career Center"
  },
  {
    "id": "383367",
    "name": "Greater Altoona Career & Technology Center"
  },
  {
    "id": "383464",
    "name": "Central Texas Beauty College-Temple"
  },
  {
    "id": "383525",
    "name": "Erie 2 Chautauqua Cattaraugus BOCES-Practical Nursing Program"
  },
  {
    "id": "383765",
    "name": "Empire Beauty School-Brooklyn"
  },
  {
    "id": "383996",
    "name": "Eastern New Mexico University Ruidoso Branch Community College"
  },
  {
    "id": "384236",
    "name": "UEI College-Ontario"
  },
  {
    "id": "384245",
    "name": "UEI College-Reseda"
  },
  {
    "id": "384254",
    "name": "Beacon College"
  },
  {
    "id": "384306",
    "name": "Acupuncture and Integrative Medicine College-Berkeley"
  },
  {
    "id": "384333",
    "name": "Estrella Mountain Community College"
  },
  {
    "id": "384342",
    "name": "Heartland Community College"
  },
  {
    "id": "384412",
    "name": "University of the Potomac-Washington DC Campus"
  },
  {
    "id": "384421",
    "name": "Rabbi Jacob Joseph School"
  },
  {
    "id": "385132",
    "name": "The Salon Professional Academy-Melbourne"
  },
  {
    "id": "385415",
    "name": "Albert Einstein College of Medicine"
  },
  {
    "id": "385503",
    "name": "Fortis Institute-Scranton"
  },
  {
    "id": "385619",
    "name": "Everglades University"
  },
  {
    "id": "386153",
    "name": "Seminar L'moros Bais Yaakov"
  },
  {
    "id": "386472",
    "name": "Herzing University-Orlando"
  },
  {
    "id": "387925",
    "name": "Cortiva Institute"
  },
  {
    "id": "388520",
    "name": "College of Biblical Studies-Houston"
  },
  {
    "id": "388663",
    "name": "Inner State Beauty School"
  },
  {
    "id": "389860",
    "name": "Mid-EastCTC-Adult Education"
  },
  {
    "id": "390905",
    "name": "Polaris Career Center"
  },
  {
    "id": "391005",
    "name": "P&A Scholars Beauty School"
  },
  {
    "id": "391759",
    "name": "Sharp's Academy of Hairstyling"
  },
  {
    "id": "392257",
    "name": "The College of Health Care Professions-Northwest"
  },
  {
    "id": "392354",
    "name": "School of Professional Horticulture New York Botanical Garden"
  },
  {
    "id": "392415",
    "name": "Fortis College"
  },
  {
    "id": "393524",
    "name": "Alhambra Beauty College"
  },
  {
    "id": "393649",
    "name": "UEI College-Gardena"
  },
  {
    "id": "393861",
    "name": "Asian-American International Beauty College"
  },
  {
    "id": "395362",
    "name": "Copper Mountain Community College"
  },
  {
    "id": "395690",
    "name": "Diversified Vocational College"
  },
  {
    "id": "397942",
    "name": "UEI College-Fresno"
  },
  {
    "id": "399212",
    "name": "Santiago Canyon College"
  },
  {
    "id": "399869",
    "name": "Southern California Institute of Technology"
  },
  {
    "id": "400080",
    "name": "Summit College"
  },
  {
    "id": "400187",
    "name": "Advance Beauty College"
  },
  {
    "id": "400558",
    "name": "Los Angeles Pacific College"
  },
  {
    "id": "401223",
    "name": "World Mission University"
  },
  {
    "id": "401250",
    "name": "Yo San University of Traditional Chinese Medicine"
  },
  {
    "id": "401764",
    "name": "John Amico School of Hair Design"
  },
  {
    "id": "402563",
    "name": "Delta College Inc"
  },
  {
    "id": "402800",
    "name": "Tri-State Cosmetology Institute"
  },
  {
    "id": "403469",
    "name": "Frontier Community College"
  },
  {
    "id": "403478",
    "name": "Lincoln Trail College"
  },
  {
    "id": "403487",
    "name": "Wabash Valley College"
  },
  {
    "id": "404055",
    "name": "Brookline College-Tempe"
  },
  {
    "id": "404222",
    "name": "Inter American University of Puerto Rico-School of Optometry"
  },
  {
    "id": "404338",
    "name": "Schiller International University"
  },
  {
    "id": "404426",
    "name": "Coconino Community College"
  },
  {
    "id": "404684",
    "name": "Emma's Beauty Academy-Juana Diaz"
  },
  {
    "id": "404718",
    "name": "Brillare Beauty Institute"
  },
  {
    "id": "404912",
    "name": "Pima Medical Institute-Denver"
  },
  {
    "id": "404994",
    "name": "ASA College"
  },
  {
    "id": "405012",
    "name": "Ohio State School of Cosmetology-Heath"
  },
  {
    "id": "405058",
    "name": "Yeshivas Novominsk"
  },
  {
    "id": "405076",
    "name": "Southern Careers Institute-Corpus Christi"
  },
  {
    "id": "405854",
    "name": "Rabbinical College of Ohr Shimon Yisroel"
  },
  {
    "id": "405872",
    "name": "Carroll Community College"
  },
  {
    "id": "406024",
    "name": "Galen College of Nursing-Tampa Bay"
  },
  {
    "id": "406033",
    "name": "Galen College of Nursing-San Antonio"
  },
  {
    "id": "406149",
    "name": "Sarasota School of Massage Therapy"
  },
  {
    "id": "406325",
    "name": "Schuyler Steuben Chemung Tioga Allegany BOCES"
  },
  {
    "id": "406361",
    "name": "Delaware Chenango Madison Otsego BOCES-Practical Nursing Program"
  },
  {
    "id": "406422",
    "name": "Central Susquehanna Intermediate Unit LPN Career"
  },
  {
    "id": "406486",
    "name": "Myotherapy College of Utah"
  },
  {
    "id": "406547",
    "name": "City College-Gainesville"
  },
  {
    "id": "407090",
    "name": "G Skin & Beauty Institute"
  },
  {
    "id": "407142",
    "name": "Cosmetology Training Center"
  },
  {
    "id": "407151",
    "name": "Stage One-The Hair School"
  },
  {
    "id": "407179",
    "name": "Empire Beauty School-Malden"
  },
  {
    "id": "407355",
    "name": "Xenon International Academy-Omaha"
  },
  {
    "id": "407391",
    "name": "Capri Institute of Hair Design-Brick"
  },
  {
    "id": "407407",
    "name": "Capri Institute of Hair Design-Kenilworth"
  },
  {
    "id": "407425",
    "name": "Capri Institute of Hair Design-Paramus"
  },
  {
    "id": "407434",
    "name": "Orlo School of Hair Design and Cosmetology"
  },
  {
    "id": "407461",
    "name": "Eastland-Fairfield Career and Technical Schools"
  },
  {
    "id": "407470",
    "name": "Empire Beauty School-Cincinnati"
  },
  {
    "id": "407489",
    "name": "Mahoning County Career and Technical Center"
  },
  {
    "id": "407513",
    "name": "Pickaway Ross Joint Vocational School District"
  },
  {
    "id": "407522",
    "name": "Pioneer Career and Technology Center"
  },
  {
    "id": "407568",
    "name": "Raphael's School of Beauty Culture Inc-Boardman"
  },
  {
    "id": "407577",
    "name": "Scioto County Career Technical Center"
  },
  {
    "id": "407595",
    "name": "Trumbull Career & Technical Center"
  },
  {
    "id": "407610",
    "name": "Pacific Bible College"
  },
  {
    "id": "407629",
    "name": "Lake Erie College of Osteopathic Medicine"
  },
  {
    "id": "407683",
    "name": "UCAS University of Cosmetology Arts & Sciences-Harlingen"
  },
  {
    "id": "407708",
    "name": "Highlights Beauty Schools"
  },
  {
    "id": "407735",
    "name": "Empire Beauty School-Green Bay"
  },
  {
    "id": "407832",
    "name": "Warren County Career Center"
  },
  {
    "id": "408066",
    "name": "Paul Mitchell the School-Wichita"
  },
  {
    "id": "408163",
    "name": "Pinnacle Institute of Cosmetology"
  },
  {
    "id": "408385",
    "name": "Southern Careers Institute-Pharr"
  },
  {
    "id": "408729",
    "name": "Laurel Technical Institute"
  },
  {
    "id": "408844",
    "name": "Florida National University-Main Campus"
  },
  {
    "id": "408862",
    "name": "Georgia Institute of Cosmetology"
  },
  {
    "id": "409032",
    "name": "Nouvelle Institute"
  },
  {
    "id": "409120",
    "name": "Franklin Technology Center Adult Education"
  },
  {
    "id": "409193",
    "name": "Professional Technical Institution"
  },
  {
    "id": "409254",
    "name": "Erikson Institute"
  },
  {
    "id": "409315",
    "name": "South Texas College"
  },
  {
    "id": "409537",
    "name": "Pike County Joint Vocational School District"
  },
  {
    "id": "409591",
    "name": "Lorain County Joint Vocational School District"
  },
  {
    "id": "409616",
    "name": "Roger Williams University School of Law"
  },
  {
    "id": "409698",
    "name": "California State University-Monterey Bay"
  },
  {
    "id": "410797",
    "name": "Ohio Media School-Valley View"
  },
  {
    "id": "410964",
    "name": "EINE Inc"
  },
  {
    "id": "411684",
    "name": "Marian Health Careers Center-Los Angeles Campus"
  },
  {
    "id": "411754",
    "name": "Cleveland Clinic Health System-School of Diagnostic Imaging"
  },
  {
    "id": "412173",
    "name": "Academy for Nursing and Health Occupations"
  },
  {
    "id": "412535",
    "name": "Shawsheen Valley School of Practical Nursing"
  },
  {
    "id": "412544",
    "name": "Shear Ego International School of Hair Design"
  },
  {
    "id": "412599",
    "name": "Remington College-Memphis Campus"
  },
  {
    "id": "413176",
    "name": "Fayette Institute of Technology"
  },
  {
    "id": "413413",
    "name": "Capella University"
  },
  {
    "id": "413617",
    "name": "College of Menominee Nation"
  },
  {
    "id": "413626",
    "name": "Leech Lake Tribal College"
  },
  {
    "id": "413680",
    "name": "Spartan College of Aeronautics & Technology"
  },
  {
    "id": "413778",
    "name": "Stone Academy-Waterbury"
  },
  {
    "id": "413820",
    "name": "Yukon Beauty College Inc"
  },
  {
    "id": "413893",
    "name": "United Education Institute-Morrow"
  },
  {
    "id": "413945",
    "name": "Toni & Guy Hairdressing Academy-Atlanta"
  },
  {
    "id": "413972",
    "name": "Mitchells Academy"
  },
  {
    "id": "414063",
    "name": "Artistic Academy of Hair Design"
  },
  {
    "id": "414124",
    "name": "Motoring Technical Training Institute"
  },
  {
    "id": "414461",
    "name": "Mech-Tech College"
  },
  {
    "id": "414489",
    "name": "Baldwin Beauty School-North Austin"
  },
  {
    "id": "414540",
    "name": "Jean Madeline Aveda Institute"
  },
  {
    "id": "414595",
    "name": "Pacific College of Health and Science"
  },
  {
    "id": "414869",
    "name": "Tricoci University of Beauty Culture-Indianapolis"
  },
  {
    "id": "414878",
    "name": "Trine University-Regional/Non-Traditional Campuses"
  },
  {
    "id": "414911",
    "name": "Pennsylvania Highlands Community College"
  },
  {
    "id": "414939",
    "name": "Nevada Career Institute"
  },
  {
    "id": "414966",
    "name": "Phillips Theological Seminary"
  },
  {
    "id": "415039",
    "name": "Empire Beauty School-Hanover"
  },
  {
    "id": "415057",
    "name": "Industrial Management Training Institute"
  },
  {
    "id": "415987",
    "name": "Allen School-Jamaica"
  },
  {
    "id": "416458",
    "name": "Premiere Career College"
  },
  {
    "id": "416801",
    "name": "The University of Texas MD Anderson Cancer Center"
  },
  {
    "id": "417008",
    "name": "Paul Mitchell the School-Huntsville"
  },
  {
    "id": "417053",
    "name": "Cedars Sinai Medical Center"
  },
  {
    "id": "417062",
    "name": "Estes Institute of Cosmetology Arts and Science"
  },
  {
    "id": "417105",
    "name": "Baldwin Park Adult & Community Education"
  },
  {
    "id": "417123",
    "name": "Ventura Adult and Continuing Education"
  },
  {
    "id": "417248",
    "name": "CT Aerotech"
  },
  {
    "id": "417275",
    "name": "Stratford School for Aviation Maintenance Technicians"
  },
  {
    "id": "417327",
    "name": "City College-Altamonte Springs"
  },
  {
    "id": "417442",
    "name": "Career Center of Southern Illinois"
  },
  {
    "id": "417503",
    "name": "Crave Beauty Academy"
  },
  {
    "id": "417600",
    "name": "Hastings Beauty School"
  },
  {
    "id": "417628",
    "name": "Kirksville Area Technical Center"
  },
  {
    "id": "417637",
    "name": "South Central Career Center"
  },
  {
    "id": "417646",
    "name": "Saline County Career Center"
  },
  {
    "id": "417655",
    "name": "Warrensburg Area Career Center"
  },
  {
    "id": "417682",
    "name": "Cass Career Center"
  },
  {
    "id": "417716",
    "name": "Applied Technology Services"
  },
  {
    "id": "417725",
    "name": "Central College of Cosmetology"
  },
  {
    "id": "417734",
    "name": "Southeast Missouri Hospital College of Nursing and Health Sciences"
  },
  {
    "id": "417752",
    "name": "Messenger College"
  },
  {
    "id": "417831",
    "name": "Union County Vocational Technical School"
  },
  {
    "id": "417840",
    "name": "Prism Career Institute-Philadelphia"
  },
  {
    "id": "417868",
    "name": "Clinton Essex Warren Washington BOCES"
  },
  {
    "id": "417886",
    "name": "Jefferson Lewis BOCES-Practical Nursing Program"
  },
  {
    "id": "417910",
    "name": "Veeb Nassau County School of Practical Nursing"
  },
  {
    "id": "417983",
    "name": "Cattaraugus Allegany BOCES-Practical Nursing Program"
  },
  {
    "id": "417992",
    "name": "Orange Ulster BOCES-Practical Nursing Program"
  },
  {
    "id": "418001",
    "name": "Wayne Finger Lakes BOCES-Practical Nursing Program"
  },
  {
    "id": "418010",
    "name": "Broome Delaware Tioga BOCES-Practical Nursing Program"
  },
  {
    "id": "418029",
    "name": "Washington Saratoga Warren Hamilton Essex BOCES-Practical Nursing Program"
  },
  {
    "id": "418038",
    "name": "Albany BOCES-Adult Practical Nursing Program"
  },
  {
    "id": "418056",
    "name": "Ulster BOCES-School of Practical Nursing"
  },
  {
    "id": "418065",
    "name": "Eastern Suffolk BOCES"
  },
  {
    "id": "418083",
    "name": "Rockland County BOCES-Practical Nursing Program"
  },
  {
    "id": "418126",
    "name": "New York College of Health Professions"
  },
  {
    "id": "418171",
    "name": "Charles Stuart School of Diamond Setting"
  },
  {
    "id": "418199",
    "name": "Penta County Joint Vocational School"
  },
  {
    "id": "418205",
    "name": "Career and Technology Education Centers of Licking County"
  },
  {
    "id": "418223",
    "name": "Northern Career Institute"
  },
  {
    "id": "418250",
    "name": "Washington County Career Center-Adult Technical Training"
  },
  {
    "id": "418269",
    "name": "Ohio Media School-Cincinnati"
  },
  {
    "id": "418278",
    "name": "Vantage Career Center"
  },
  {
    "id": "418296",
    "name": "Indian Capital Technology Center-Muskogee"
  },
  {
    "id": "418302",
    "name": "Western Technology Center"
  },
  {
    "id": "418320",
    "name": "Mid-America Technology Center"
  },
  {
    "id": "418348",
    "name": "Eastern Oklahoma County Technology Center"
  },
  {
    "id": "418357",
    "name": "Wes Watkins Technology Center"
  },
  {
    "id": "418409",
    "name": "York County School of Technology-Adult & Continuing Education"
  },
  {
    "id": "418427",
    "name": "Chester County Intermediate Unit"
  },
  {
    "id": "418445",
    "name": "Jefferson County Dubois Area Vocational Technical Practical Nursing Program"
  },
  {
    "id": "418454",
    "name": "Greater Johnstown Career and Technology Center"
  },
  {
    "id": "418481",
    "name": "Mifflin County Academy of Science and Technology"
  },
  {
    "id": "418515",
    "name": "Lenape Technical School Practical Nursing Program"
  },
  {
    "id": "418524",
    "name": "Venango County Area Vocational Technical School"
  },
  {
    "id": "418533",
    "name": "Lancaster County Career and Technology Center"
  },
  {
    "id": "418542",
    "name": "Lebanon County Area Vocational Technical School"
  },
  {
    "id": "418560",
    "name": "Indiana County Technology Center"
  },
  {
    "id": "418588",
    "name": "Northern Tier Career Center"
  },
  {
    "id": "418612",
    "name": "Hazleton Area Career Center"
  },
  {
    "id": "418658",
    "name": "All-State Career School-Pittsburgh"
  },
  {
    "id": "418764",
    "name": "Quality Technical and Beauty College"
  },
  {
    "id": "418773",
    "name": "D'Mart Institute"
  },
  {
    "id": "418782",
    "name": "Automeca Technical College-Aguadilla"
  },
  {
    "id": "418889",
    "name": "North Central Institute"
  },
  {
    "id": "418913",
    "name": "Pipo Academy of Hair Design"
  },
  {
    "id": "418968",
    "name": "Tint School of Makeup and Cosmetology-Dallas"
  },
  {
    "id": "419031",
    "name": "United Technical Center"
  },
  {
    "id": "419095",
    "name": "Charles H McCann Technical School"
  },
  {
    "id": "419183",
    "name": "Central California School of Continuing Education"
  },
  {
    "id": "419217",
    "name": "Mercy Hospital School of Practical Nursing"
  },
  {
    "id": "419226",
    "name": "Marion Technical College"
  },
  {
    "id": "419235",
    "name": "Rivertown School of Beauty Barber Skin Care and Nails"
  },
  {
    "id": "419244",
    "name": "Atlanta Institute of Music and Media"
  },
  {
    "id": "419253",
    "name": "Illinois Media School"
  },
  {
    "id": "419280",
    "name": "New Dimensions School of Hair Design"
  },
  {
    "id": "419332",
    "name": "Forbes Road Career and Technology Center"
  },
  {
    "id": "419341",
    "name": "Jna Institute of Culinary Arts"
  },
  {
    "id": "419420",
    "name": "Putnam Career and Technical Center"
  },
  {
    "id": "419484",
    "name": "Crawford County Career and Technical Center Practical Nursing Program"
  },
  {
    "id": "419633",
    "name": "Texas College of Cosmetology-San Angelo"
  },
  {
    "id": "419703",
    "name": "Automeca Technical College-Bayamon"
  },
  {
    "id": "419712",
    "name": "Automeca Technical College-Caguas"
  },
  {
    "id": "419721",
    "name": "Automeca Technical College-Ponce"
  },
  {
    "id": "420024",
    "name": "Centura College-Chesapeake"
  },
  {
    "id": "420042",
    "name": "University of Phoenix-Hawaii"
  },
  {
    "id": "420130",
    "name": "Prism Career Institute-Cherry Hill"
  },
  {
    "id": "420246",
    "name": "Sonoran University of Health Sciences"
  },
  {
    "id": "420255",
    "name": "Escuela de Peritos Electricistas de Isabela Inc"
  },
  {
    "id": "420325",
    "name": "Yeshiva D'monsey Rabbinical College"
  },
  {
    "id": "420343",
    "name": "Lincoln Technical Institute-Mahwah"
  },
  {
    "id": "420370",
    "name": "Career Technical Institute"
  },
  {
    "id": "420398",
    "name": "Northwest Vista College"
  },
  {
    "id": "420431",
    "name": "Oconee Fall Line Technical College"
  },
  {
    "id": "420440",
    "name": "York County Community College"
  },
  {
    "id": "420468",
    "name": "Western Suffolk BOCES"
  },
  {
    "id": "420538",
    "name": "Arkansas State University-Mountain Home"
  },
  {
    "id": "420556",
    "name": "Columbia Gorge Community College"
  },
  {
    "id": "420705",
    "name": "Bakke Graduate University"
  },
  {
    "id": "420723",
    "name": "Tillamook Bay Community College"
  },
  {
    "id": "420981",
    "name": "Manhattan School of Computer Technology"
  },
  {
    "id": "421513",
    "name": "Ross College-Hopkinsville"
  },
  {
    "id": "421610",
    "name": "Paul Mitchell the School-St. George"
  },
  {
    "id": "421665",
    "name": "Cadillac Institute of Cosmetology"
  },
  {
    "id": "421708",
    "name": "Arizona College of Nursing-Tempe"
  },
  {
    "id": "421717",
    "name": "Vanguard-Sentinel Adult Career and Technology Center"
  },
  {
    "id": "421771",
    "name": "Academy of Beauty Professionals"
  },
  {
    "id": "421832",
    "name": "Living Arts College"
  },
  {
    "id": "421841",
    "name": "New York Conservatory for Dramatic Arts"
  },
  {
    "id": "421878",
    "name": "Eastern International College-Jersey City"
  },
  {
    "id": "421896",
    "name": "Academia Serrant Inc"
  },
  {
    "id": "422020",
    "name": "San Joaquin Valley College-Trades Education Center"
  },
  {
    "id": "422695",
    "name": "Pacific College"
  },
  {
    "id": "422835",
    "name": "American College of Healthcare and Technology"
  },
  {
    "id": "423120",
    "name": "Compu-Med Vocational Careers Corp"
  },
  {
    "id": "423397",
    "name": "Advantage Technical College"
  },
  {
    "id": "423412",
    "name": "Mid Cities Barber College"
  },
  {
    "id": "423643",
    "name": "Midwestern University-Glendale"
  },
  {
    "id": "423652",
    "name": "Oregon Coast Community College"
  },
  {
    "id": "425250",
    "name": "Gwinnett College-Sandy Springs"
  },
  {
    "id": "425986",
    "name": "Mildred Elley-Pittsfield Campus"
  },
  {
    "id": "426314",
    "name": "Embry-Riddle Aeronautical University-Worldwide"
  },
  {
    "id": "427308",
    "name": "Merkaz Bnos-Business School"
  },
  {
    "id": "427335",
    "name": "Lia Schorr Institute of Cosmetic Skin Care Training"
  },
  {
    "id": "427894",
    "name": "CET-Alexandria"
  },
  {
    "id": "427973",
    "name": "Aviation Institute of Maintenance-Norfolk"
  },
  {
    "id": "427982",
    "name": "Centura College-Richmond Main"
  },
  {
    "id": "427991",
    "name": "Osceola Technical College"
  },
  {
    "id": "428000",
    "name": "Miami Ad School"
  },
  {
    "id": "428019",
    "name": "Green Country Technology Center"
  },
  {
    "id": "428055",
    "name": "Nova College de Puerto Rico"
  },
  {
    "id": "428125",
    "name": "John Wesley International Barber and Beauty College"
  },
  {
    "id": "428170",
    "name": "Southeastern College-West Palm Beach"
  },
  {
    "id": "428259",
    "name": "Ottawa University-Milwaukee"
  },
  {
    "id": "428392",
    "name": "Klamath Community College"
  },
  {
    "id": "429012",
    "name": "L3Harris Flight Academy"
  },
  {
    "id": "429085",
    "name": "American College of Acupuncture and Oriental Med"
  },
  {
    "id": "429094",
    "name": "AOMA Graduate School of Integrative Medicine"
  },
  {
    "id": "429128",
    "name": "Urban College of Boston"
  },
  {
    "id": "430184",
    "name": "Strayer University-Maryland"
  },
  {
    "id": "430227",
    "name": "Empire Beauty School-Monroeville"
  },
  {
    "id": "430245",
    "name": "Southern Careers Institute-San Antonio"
  },
  {
    "id": "430306",
    "name": "Cayce/Reilly School of Massage"
  },
  {
    "id": "430403",
    "name": "Prism Career Institute-West Atlantic City"
  },
  {
    "id": "430564",
    "name": "Studio Jewelers"
  },
  {
    "id": "430582",
    "name": "Praxis Institute"
  },
  {
    "id": "430670",
    "name": "San Juan Bautista School of Medicine"
  },
  {
    "id": "430704",
    "name": "Texas Health and Science University"
  },
  {
    "id": "430795",
    "name": "Carver Career Center"
  },
  {
    "id": "430883",
    "name": "Cayuga Onondaga BOCES-Practical Nursing Program"
  },
  {
    "id": "430908",
    "name": "Clearfield County Career and Technology Center"
  },
  {
    "id": "430935",
    "name": "Colegio de Cinematografia Artes y Television"
  },
  {
    "id": "430971",
    "name": "Delaware County Technical School-Practical Nursing Program"
  },
  {
    "id": "431017",
    "name": "Mid-Del Technology Center"
  },
  {
    "id": "431071",
    "name": "New York Institute of Massage Inc"
  },
  {
    "id": "431105",
    "name": "Dutchess BOCES-Practical Nursing Program"
  },
  {
    "id": "431123",
    "name": "Escuela Tecnica de Electricidad"
  },
  {
    "id": "431141",
    "name": "Institute of Technology"
  },
  {
    "id": "431169",
    "name": "Garnet Career Center"
  },
  {
    "id": "431187",
    "name": "Bristol Technical Education Center"
  },
  {
    "id": "431196",
    "name": "Genesee Valley BOCES-Practical Nursing Program"
  },
  {
    "id": "431266",
    "name": "Henrico County-Saint Marys Hospital School of Practical Nursing"
  },
  {
    "id": "431275",
    "name": "Herkimer County BOCES-Practical Nursing Program"
  },
  {
    "id": "431284",
    "name": "International Beauty College"
  },
  {
    "id": "431558",
    "name": "Cape Coral Technical College"
  },
  {
    "id": "431600",
    "name": "UPMC Mercy School of Nursing"
  },
  {
    "id": "431716",
    "name": "Ocean County Vocational-Technical School"
  },
  {
    "id": "431983",
    "name": "Yeshiva of the Telshe Alumni"
  },
  {
    "id": "432117",
    "name": "Kenneth Shuler School of Cosmetology-Rock Hill"
  },
  {
    "id": "432269",
    "name": "CET-El Paso"
  },
  {
    "id": "432348",
    "name": "Appalachian School of Law"
  },
  {
    "id": "432384",
    "name": "Platt College-Ontario"
  },
  {
    "id": "432524",
    "name": "Delaware College of Art and Design"
  },
  {
    "id": "433004",
    "name": "American University of Health Sciences"
  },
  {
    "id": "433013",
    "name": "Career Networks Institute"
  },
  {
    "id": "433040",
    "name": "Monty Tech"
  },
  {
    "id": "433068",
    "name": "Okaloosa Technical College"
  },
  {
    "id": "433101",
    "name": "Lincoln Technical Institute-Lincoln"
  },
  {
    "id": "433138",
    "name": "Wayne County Schools Career Center"
  },
  {
    "id": "433174",
    "name": "Carolinas College of Health Sciences"
  },
  {
    "id": "433387",
    "name": "Western Governors University"
  },
  {
    "id": "433420",
    "name": "California Aeronautical University"
  },
  {
    "id": "433536",
    "name": "Herzing University-New Orleans"
  },
  {
    "id": "433660",
    "name": "Florida Gulf Coast University"
  },
  {
    "id": "434016",
    "name": "Little Priest Tribal College"
  },
  {
    "id": "434061",
    "name": "South Louisiana Community College"
  },
  {
    "id": "434140",
    "name": "Pima Medical Institute-Chula Vista"
  },
  {
    "id": "434159",
    "name": "Lincoln College of Technology-Marietta"
  },
  {
    "id": "434274",
    "name": "Academy of Hair Design-Lufkin"
  },
  {
    "id": "434283",
    "name": "Pathway Vocational Academy"
  },
  {
    "id": "434308",
    "name": "Cortiva Institute"
  },
  {
    "id": "434344",
    "name": "Toni & Guy Hairdressing Academy-Worcester"
  },
  {
    "id": "434362",
    "name": "Northwest HVAC/R Training Center"
  },
  {
    "id": "434432",
    "name": "Myotherapy Institute"
  },
  {
    "id": "434441",
    "name": "Florida College of Integrative Medicine"
  },
  {
    "id": "434469",
    "name": "New Professions Technical Institute"
  },
  {
    "id": "434496",
    "name": "Strand College of Hair Design"
  },
  {
    "id": "434539",
    "name": "City College-Miami"
  },
  {
    "id": "434584",
    "name": "Ilisagvik College"
  },
  {
    "id": "434672",
    "name": "Community College of Baltimore County"
  },
  {
    "id": "434751",
    "name": "White Earth Tribal and Community College"
  },
  {
    "id": "434821",
    "name": "Blue Cliff College-Metairie"
  },
  {
    "id": "434830",
    "name": "Creative Images Institute of Cosmetology-North Dayton"
  },
  {
    "id": "434900",
    "name": "Dewey University-Carolina"
  },
  {
    "id": "434937",
    "name": "Yeshiva College of the Nations Capital"
  },
  {
    "id": "435000",
    "name": "Louisiana State University Health Sciences Center-Shreveport"
  },
  {
    "id": "436003",
    "name": "Central Career Institute LLC"
  },
  {
    "id": "436030",
    "name": "Hair Academy II"
  },
  {
    "id": "436261",
    "name": "Southwest Acupuncture College-Boulder"
  },
  {
    "id": "436304",
    "name": "River Parishes Community College"
  },
  {
    "id": "436429",
    "name": "Los Angeles Film School"
  },
  {
    "id": "436553",
    "name": "Century College"
  },
  {
    "id": "436599",
    "name": "FINE Mortuary College"
  },
  {
    "id": "436614",
    "name": "International Baptist College and Seminary"
  },
  {
    "id": "436632",
    "name": "Gwinnett College"
  },
  {
    "id": "436641",
    "name": "Fortis College-Foley"
  },
  {
    "id": "436650",
    "name": "Medical Training College"
  },
  {
    "id": "436775",
    "name": "Toni & Guy Hairdressing Academy-Plano"
  },
  {
    "id": "436818",
    "name": "University of Connecticut-Waterbury Campus"
  },
  {
    "id": "436827",
    "name": "University of Connecticut-Avery Point"
  },
  {
    "id": "436836",
    "name": "University of Connecticut-Stamford"
  },
  {
    "id": "437097",
    "name": "University of Management and Technology"
  },
  {
    "id": "437103",
    "name": "Baton Rouge Community College"
  },
  {
    "id": "437237",
    "name": "IYRS School of Technology & Trades"
  },
  {
    "id": "437316",
    "name": "Linfield University-School of Nursing"
  },
  {
    "id": "437325",
    "name": "Linfield University-Online and Continuing Education"
  },
  {
    "id": "437556",
    "name": "Midwest Technical Institute-Illinois"
  },
  {
    "id": "437608",
    "name": "Advanced Barber College and Hair Design"
  },
  {
    "id": "437635",
    "name": "The College of Health Care Professions-Austin"
  },
  {
    "id": "437723",
    "name": "New Community Career & Technical Institute"
  },
  {
    "id": "437732",
    "name": "Chicago School of Professional Psychology-College of Nursing"
  },
  {
    "id": "437750",
    "name": "Professional Golfers Career College"
  },
  {
    "id": "437769",
    "name": "Chester Career College"
  },
  {
    "id": "437778",
    "name": "Milan Institute-San Antonio Ingram"
  },
  {
    "id": "437857",
    "name": "Rudae's School of Beauty Culture-Ft Wayne"
  },
  {
    "id": "437936",
    "name": "Carrington College-San Jose"
  },
  {
    "id": "438179",
    "name": "Brookline College-Tucson"
  },
  {
    "id": "438221",
    "name": "Miller-Motte College-McCann-Lewisburg"
  },
  {
    "id": "438258",
    "name": "Carrington College-Pleasant Hill Campus"
  },
  {
    "id": "438285",
    "name": "Cortiva Institute"
  },
  {
    "id": "438337",
    "name": "Modern Hairstyling Institute-Carolina"
  },
  {
    "id": "438498",
    "name": "Stratford University"
  },
  {
    "id": "438504",
    "name": "Universal Technical Institute of California Inc"
  },
  {
    "id": "438586",
    "name": "American InterContinental University-Atlanta"
  },
  {
    "id": "438674",
    "name": "The Academy of Hair Design Six"
  },
  {
    "id": "438708",
    "name": "Eastern West Virginia Community and Technical College"
  },
  {
    "id": "438735",
    "name": "Aviation Institute of Maintenance-Indianapolis"
  },
  {
    "id": "438984",
    "name": "IVAEM College"
  },
  {
    "id": "439020",
    "name": "Midwest College of Oriental Medicine-Skokie"
  },
  {
    "id": "439118",
    "name": "Carrington College-Spokane"
  },
  {
    "id": "439190",
    "name": "Cascadia College"
  },
  {
    "id": "439367",
    "name": "CBD College"
  },
  {
    "id": "439394",
    "name": "East West College of Natural Medicine"
  },
  {
    "id": "439446",
    "name": "Atlantic Institute of Oriental Medicine"
  },
  {
    "id": "439455",
    "name": "MDT College of Health Sciences"
  },
  {
    "id": "439473",
    "name": "Bennett Career Institute"
  },
  {
    "id": "439491",
    "name": "Blue Cliff College-Lafayette"
  },
  {
    "id": "439507",
    "name": "Quest College"
  },
  {
    "id": "439570",
    "name": "Community Care College"
  },
  {
    "id": "439622",
    "name": "Erie 1 BOCES"
  },
  {
    "id": "439668",
    "name": "HDS Truck Driving Institute"
  },
  {
    "id": "439686",
    "name": "International Training Careers"
  },
  {
    "id": "439701",
    "name": "The King's University"
  },
  {
    "id": "439738",
    "name": "Fortis College-Baton Rouge"
  },
  {
    "id": "439747",
    "name": "My Le's Beauty College"
  },
  {
    "id": "439774",
    "name": "New Concept Massage and Beauty School"
  },
  {
    "id": "439783",
    "name": "New York College of Traditional Chinese Medicine"
  },
  {
    "id": "439792",
    "name": "Fortis College-Orange Park"
  },
  {
    "id": "439817",
    "name": "Northeastern Seminary"
  },
  {
    "id": "439844",
    "name": "Omega Institute of Cosmetology"
  },
  {
    "id": "439862",
    "name": "Pacific Islands University"
  },
  {
    "id": "439871",
    "name": "PCI College"
  },
  {
    "id": "439899",
    "name": "Rosedale Bible College"
  },
  {
    "id": "439914",
    "name": "Seattle Institute of East Asian Medicine"
  },
  {
    "id": "439932",
    "name": "Shawnee Beauty College"
  },
  {
    "id": "439969",
    "name": "Acupuncture and Massage College"
  },
  {
    "id": "440004",
    "name": "Upper Valley Educators Institute"
  },
  {
    "id": "440031",
    "name": "Keck Graduate Institute"
  },
  {
    "id": "440059",
    "name": "Aviation Institute of Maintenance-Atlanta"
  },
  {
    "id": "440101",
    "name": "Empire Beauty School-Kennesaw"
  },
  {
    "id": "440226",
    "name": "Missouri College of Cosmetology North"
  },
  {
    "id": "440262",
    "name": "New York Automotive and Diesel Institute"
  },
  {
    "id": "440271",
    "name": "Remington College-Baton Rouge Campus"
  },
  {
    "id": "440396",
    "name": "New Saint Andrews College"
  },
  {
    "id": "440402",
    "name": "Arkansas State University-Newport"
  },
  {
    "id": "440651",
    "name": "Atenas College"
  },
  {
    "id": "440730",
    "name": "Onondaga Cortland Madison BOCES"
  },
  {
    "id": "440749",
    "name": "National American University-Ellsworth"
  },
  {
    "id": "440776",
    "name": "Interactive College of Technology"
  },
  {
    "id": "440794",
    "name": "Pillar College"
  },
  {
    "id": "440819",
    "name": "Miami Ad School-San Francisco"
  },
  {
    "id": "440828",
    "name": "Oregon State University-Cascades Campus"
  },
  {
    "id": "440855",
    "name": "Milan Institute-Amarillo"
  },
  {
    "id": "440916",
    "name": "Southern University Law Center"
  },
  {
    "id": "440989",
    "name": "Texas Barber College"
  },
  {
    "id": "441025",
    "name": "Miller-Motte College-Charleston"
  },
  {
    "id": "441052",
    "name": "American Career College-Anaheim"
  },
  {
    "id": "441070",
    "name": "Saginaw Chippewa Tribal College"
  },
  {
    "id": "441104",
    "name": "Richmont Graduate University"
  },
  {
    "id": "441131",
    "name": "The Seattle School of Theology & Psychology"
  },
  {
    "id": "441168",
    "name": "Northwest College-Hillsboro"
  },
  {
    "id": "441210",
    "name": "East Valley Institute of Technology"
  },
  {
    "id": "441229",
    "name": "Smith Chason College"
  },
  {
    "id": "441238",
    "name": "Irell & Manella Graduate School of Biological Sciences at City of Hope"
  },
  {
    "id": "441256",
    "name": "Design's School of Cosmetology"
  },
  {
    "id": "441371",
    "name": "Ultimate Medical Academy"
  },
  {
    "id": "441380",
    "name": "Florida Barber Academy"
  },
  {
    "id": "441414",
    "name": "M-DCPS The English Center"
  },
  {
    "id": "441423",
    "name": "Florida Education Institute"
  },
  {
    "id": "441487",
    "name": "Texas County Technical College"
  },
  {
    "id": "441496",
    "name": "Aviation Institute of Maintenance-Kansas City"
  },
  {
    "id": "441502",
    "name": "Blue Cliff College-Gulfport"
  },
  {
    "id": "441548",
    "name": "Institute for Therapeutic Massage"
  },
  {
    "id": "441575",
    "name": "Metropolitan Learning Institute"
  },
  {
    "id": "441593",
    "name": "Orleans Niagara BOCES-Practical Nursing Program"
  },
  {
    "id": "441609",
    "name": "Yeshiva Shaarei Torah of Rockland"
  },
  {
    "id": "441618",
    "name": "Leon Studio One School of Beauty Knowledge"
  },
  {
    "id": "441636",
    "name": "American Institute of Alternative Medicine"
  },
  {
    "id": "441645",
    "name": "Brown Aveda Institute-Mentor"
  },
  {
    "id": "441663",
    "name": "Institute of Technology"
  },
  {
    "id": "441672",
    "name": "Susquehanna County Career and Technology Center"
  },
  {
    "id": "441690",
    "name": "Universidad Pentecostal Mizpa"
  },
  {
    "id": "441706",
    "name": "Caribbean Forensic and Technical College"
  },
  {
    "id": "441742",
    "name": "Concorde Career College-Grand Prairie"
  },
  {
    "id": "441760",
    "name": "Lamar Institute of Technology"
  },
  {
    "id": "441788",
    "name": "Aviation Institute of Maintenance-Dallas"
  },
  {
    "id": "441830",
    "name": "Healing Mountain Massage School"
  },
  {
    "id": "441858",
    "name": "Eastern Virginia Career College"
  },
  {
    "id": "441876",
    "name": "Rudy & Kelly Academy A Paul Mitchell Partner School"
  },
  {
    "id": "441900",
    "name": "Nevada State College"
  },
  {
    "id": "441937",
    "name": "California State University-Channel Islands"
  },
  {
    "id": "441982",
    "name": "Franklin W Olin College of Engineering"
  },
  {
    "id": "442064",
    "name": "Won Institute of Graduate Studies"
  },
  {
    "id": "442073",
    "name": "Lincoln Technical Institute-Paramus"
  },
  {
    "id": "442134",
    "name": "Pima Medical Institute-Colorado Springs"
  },
  {
    "id": "442213",
    "name": "Birthwise Midwifery School"
  },
  {
    "id": "442240",
    "name": "Paul Mitchell the School-Costa Mesa"
  },
  {
    "id": "442295",
    "name": "Ave Maria School of Law"
  },
  {
    "id": "442329",
    "name": "Tulsa Welding School-Jacksonville"
  },
  {
    "id": "442356",
    "name": "Pennsylvania College of Health Sciences"
  },
  {
    "id": "442383",
    "name": "West Virginia Junior College-Bridgeport"
  },
  {
    "id": "442426",
    "name": "St Louis College of Health Careers-Fenton"
  },
  {
    "id": "442435",
    "name": "Interactive College of Technology-Gainesville"
  },
  {
    "id": "442444",
    "name": "San Joaquin Valley College-Ontario"
  },
  {
    "id": "442523",
    "name": "Alaska Christian College"
  },
  {
    "id": "442569",
    "name": "University of Arkansas Grantham"
  },
  {
    "id": "442602",
    "name": "Carrington College-Albuquerque"
  },
  {
    "id": "442611",
    "name": "NASCAR Technical Institute"
  },
  {
    "id": "442639",
    "name": "University of the Potomac-VA Campus"
  },
  {
    "id": "442657",
    "name": "Milan Institute-Palm Desert"
  },
  {
    "id": "442781",
    "name": "Tohono O'odham Community College"
  },
  {
    "id": "442806",
    "name": "Edward Via College of Osteopathic Medicine"
  },
  {
    "id": "442842",
    "name": "Pacific College of Health and Science"
  },
  {
    "id": "442879",
    "name": "Southwest Institute of Healing Arts"
  },
  {
    "id": "442888",
    "name": "InterCoast Colleges-Riverside"
  },
  {
    "id": "442930",
    "name": "University of Antelope Valley"
  },
  {
    "id": "442949",
    "name": "Birthingway College of Midwifery"
  },
  {
    "id": "442976",
    "name": "Brighton Center's Center for Employment Training"
  },
  {
    "id": "442985",
    "name": "California Healing Arts College"
  },
  {
    "id": "443021",
    "name": "Dallas Barber & Stylist College"
  },
  {
    "id": "443030",
    "name": "NorthShore University HealthSystem School of Nurse Anesthesia"
  },
  {
    "id": "443049",
    "name": "Faith International University"
  },
  {
    "id": "443058",
    "name": "Family of Faith Christian University"
  },
  {
    "id": "443076",
    "name": "Hood Theological Seminary"
  },
  {
    "id": "443100",
    "name": "Colegio Educativo Tecnologico Industrial Inc"
  },
  {
    "id": "443128",
    "name": "International Technological University"
  },
  {
    "id": "443137",
    "name": "Irene's Myomassology Institute"
  },
  {
    "id": "443146",
    "name": "Jay's Technical Institute"
  },
  {
    "id": "443207",
    "name": "NTMA Training Centers of Southern California"
  },
  {
    "id": "443216",
    "name": "P C Age-Jersey City"
  },
  {
    "id": "443225",
    "name": "PC AGE-Metropark"
  },
  {
    "id": "443234",
    "name": "Hollywood Institute"
  },
  {
    "id": "443252",
    "name": "South Florida Institute of Technology"
  },
  {
    "id": "443261",
    "name": "Southeastern College-Charleston"
  },
  {
    "id": "443289",
    "name": "Tigi Hairdressing Academy-Colorado Springs"
  },
  {
    "id": "443298",
    "name": "Trend Barber College"
  },
  {
    "id": "443322",
    "name": "Valley Grande Institute for Academic Studies"
  },
  {
    "id": "443331",
    "name": "West Coast University-Los Angeles"
  },
  {
    "id": "443340",
    "name": "Williamson Christian College"
  },
  {
    "id": "443377",
    "name": "Triangle Tech Inc-Sunbury"
  },
  {
    "id": "443410",
    "name": "DigiPen Institute of Technology"
  },
  {
    "id": "443492",
    "name": "Pierpont Community and Technical College"
  },
  {
    "id": "443562",
    "name": "Dewey University-Hato Rey"
  },
  {
    "id": "443571",
    "name": "InterCoast Colleges-West Covina"
  },
  {
    "id": "443599",
    "name": "American College of Healthcare Sciences"
  },
  {
    "id": "443632",
    "name": "Colorado Media School"
  },
  {
    "id": "443641",
    "name": "Paul Mitchell the School-Rhode Island"
  },
  {
    "id": "443650",
    "name": "Miller-Motte College-Chattanooga"
  },
  {
    "id": "443669",
    "name": "Milan Institute-Sparks"
  },
  {
    "id": "443696",
    "name": "Interactive College of Technology"
  },
  {
    "id": "443702",
    "name": "Bexley Hall Seabury Western Theological Seminary Federation Inc."
  },
  {
    "id": "443748",
    "name": "Altierus Career College-Norcross"
  },
  {
    "id": "443766",
    "name": "Strayer University-Tennessee"
  },
  {
    "id": "443784",
    "name": "Strayer University-Pennsylvania"
  },
  {
    "id": "443951",
    "name": "Charlie's Guard-Detective Bureau and Academy Inc"
  },
  {
    "id": "443979",
    "name": "PCI Academy-Plymouth"
  },
  {
    "id": "444051",
    "name": "Creative Images Institute of Cosmetology-South Dayton"
  },
  {
    "id": "444088",
    "name": "Brookline College-Albuquerque"
  },
  {
    "id": "444103",
    "name": "Byzantine Catholic Seminary of Saints Cyril and Methodius"
  },
  {
    "id": "444130",
    "name": "Northcentral University"
  },
  {
    "id": "444194",
    "name": "Bayamon Community College"
  },
  {
    "id": "444219",
    "name": "Folsom Lake College"
  },
  {
    "id": "444228",
    "name": "LeGrand Institute of Cosmetology Inc"
  },
  {
    "id": "444255",
    "name": "Hussian College-Daymar College Murfreesboro"
  },
  {
    "id": "444264",
    "name": "Cambridge Technical Institute"
  },
  {
    "id": "444282",
    "name": "Empire Beauty School-Midlothian"
  },
  {
    "id": "444291",
    "name": "Empire Beauty School-Owings Mills"
  },
  {
    "id": "444307",
    "name": "Empire Beauty School-West Mifflin"
  },
  {
    "id": "444325",
    "name": "ATI College-Whittier"
  },
  {
    "id": "444334",
    "name": "Advance Science International College"
  },
  {
    "id": "444361",
    "name": "ATA College"
  },
  {
    "id": "444370",
    "name": "American Advanced Technicians Institute"
  },
  {
    "id": "444398",
    "name": "Baptist University of the Americas"
  },
  {
    "id": "444404",
    "name": "The Beauty Institute"
  },
  {
    "id": "444413",
    "name": "Beis Medrash Heichal Dovid"
  },
  {
    "id": "444440",
    "name": "Blue Cliff Career College"
  },
  {
    "id": "444468",
    "name": "Career Beauty College"
  },
  {
    "id": "444486",
    "name": "Paul Mitchell the School-Gastonia"
  },
  {
    "id": "444510",
    "name": "CES College"
  },
  {
    "id": "444529",
    "name": "Stellar Career College"
  },
  {
    "id": "444556",
    "name": "Auguste Escoffier School of Culinary Arts-Austin"
  },
  {
    "id": "444565",
    "name": "Culinary Institute Inc"
  },
  {
    "id": "444583",
    "name": "Escuela Hotelera de San Juan"
  },
  {
    "id": "444635",
    "name": "Orion Institute"
  },
  {
    "id": "444662",
    "name": "Moore Career College"
  },
  {
    "id": "444671",
    "name": "Industrial Technical College"
  },
  {
    "id": "444699",
    "name": "Institute of Clinical Acupuncture & Oriental Med"
  },
  {
    "id": "444714",
    "name": "Gwinnett College-Marietta Campus"
  },
  {
    "id": "444732",
    "name": "Leston College"
  },
  {
    "id": "444778",
    "name": "Charlotte Christian College and Theological Seminary"
  },
  {
    "id": "444787",
    "name": "Nightingale College"
  },
  {
    "id": "444796",
    "name": "Omnitech Institute"
  },
  {
    "id": "444811",
    "name": "PITC Institute"
  },
  {
    "id": "444857",
    "name": "Skinworks School of Advanced Skincare"
  },
  {
    "id": "444866",
    "name": "Southeastern College-Columbia"
  },
  {
    "id": "444893",
    "name": "Technical Learning Centers Inc"
  },
  {
    "id": "444936",
    "name": "Turning Point Beauty College"
  },
  {
    "id": "444945",
    "name": "Unitech Training Academy-Lafayette"
  },
  {
    "id": "444954",
    "name": "Mountwest Community and Technical College"
  },
  {
    "id": "445027",
    "name": "American InterContinental University"
  },
  {
    "id": "445054",
    "name": "Toyota Technological Institute at Chicago"
  },
  {
    "id": "445133",
    "name": "American InterContinental University-Houston"
  },
  {
    "id": "445203",
    "name": "Remington College-North Houston Campus"
  },
  {
    "id": "445212",
    "name": "Paul Mitchell the School-Orlando"
  },
  {
    "id": "445230",
    "name": "Pima Medical Institute-Las Vegas"
  },
  {
    "id": "445249",
    "name": "Remington College-Nashville Campus"
  },
  {
    "id": "445267",
    "name": "Central Methodist University-College of Graduate and Extended Studies"
  },
  {
    "id": "445364",
    "name": "North-West College-Riverside"
  },
  {
    "id": "445461",
    "name": "Altierus Career College-Bissonnet"
  },
  {
    "id": "445498",
    "name": "Colegio Tecnico de Electricidad Galloza"
  },
  {
    "id": "445540",
    "name": "Arthur's Beauty College"
  },
  {
    "id": "445647",
    "name": "Spa Tech Institute-Westboro"
  },
  {
    "id": "445656",
    "name": "Spa Tech Institute-Plymouth"
  },
  {
    "id": "445692",
    "name": "Neumont College of Computer Science"
  },
  {
    "id": "445708",
    "name": "Johnson & Wales University-Charlotte"
  },
  {
    "id": "445735",
    "name": "Roseman University of Health Sciences"
  },
  {
    "id": "445744",
    "name": "Fortis Institute-Port Saint Lucie"
  },
  {
    "id": "445762",
    "name": "Aviation Institute of Maintenance-Manassas"
  },
  {
    "id": "445780",
    "name": "Cosmetology School of Arts & Sciences"
  },
  {
    "id": "445799",
    "name": "Universal Technical Institute of Pennsylvania Inc"
  },
  {
    "id": "445841",
    "name": "Kenneth Shuler School of Cosmetology-Spartanburg"
  },
  {
    "id": "445850",
    "name": "Kenneth Shuler School of Cosmetology-Columbia"
  },
  {
    "id": "445869",
    "name": "Divine Mercy University"
  },
  {
    "id": "445911",
    "name": "Lincoln Technical Institute-Shelton"
  },
  {
    "id": "445948",
    "name": "Northwest Career College"
  },
  {
    "id": "445957",
    "name": "Soma Institute-The National School of Clinical Massage Therapy"
  },
  {
    "id": "446002",
    "name": "American Academy of Health and Wellness"
  },
  {
    "id": "446039",
    "name": "Arizona School of Acupuncture and Oriental Medicine"
  },
  {
    "id": "446048",
    "name": "Ave Maria University"
  },
  {
    "id": "446084",
    "name": "CALC Institute of Technology"
  },
  {
    "id": "446093",
    "name": "Sutter County Career Training Center"
  },
  {
    "id": "446109",
    "name": "Cambridge College of Healthcare & Technology"
  },
  {
    "id": "446118",
    "name": "Career Care Institute"
  },
  {
    "id": "446127",
    "name": "ATA Career Education"
  },
  {
    "id": "446163",
    "name": "Community Christian College"
  },
  {
    "id": "446206",
    "name": "Culpeper Cosmetology Training Center"
  },
  {
    "id": "446224",
    "name": "Eastern School of Acupuncture and Traditional Medicine"
  },
  {
    "id": "446233",
    "name": "Ecclesia College"
  },
  {
    "id": "446242",
    "name": "Employment Solutions-College for Technical Education"
  },
  {
    "id": "446251",
    "name": "Estelle Medical Academy"
  },
  {
    "id": "446260",
    "name": "Evergreen Beauty and Barber College-Everett"
  },
  {
    "id": "446288",
    "name": "Paul Mitchell the School-Great Lakes"
  },
  {
    "id": "446303",
    "name": "Healthcare Training Institute"
  },
  {
    "id": "446349",
    "name": "John D Rockefeller IV Career Center"
  },
  {
    "id": "446385",
    "name": "Los Angeles College of Music"
  },
  {
    "id": "446394",
    "name": "Maple Springs Baptist Bible College and Seminary"
  },
  {
    "id": "446455",
    "name": "Precision Manufacturing Institute"
  },
  {
    "id": "446516",
    "name": "Rosslyn Training Academy of Cosmetology"
  },
  {
    "id": "446525",
    "name": "SAE Institute of Technology-Nashville"
  },
  {
    "id": "446552",
    "name": "Southern Technical College"
  },
  {
    "id": "446561",
    "name": "Stanbridge University"
  },
  {
    "id": "446589",
    "name": "Universal Career School"
  },
  {
    "id": "446598",
    "name": "Universal College of Healing Arts"
  },
  {
    "id": "446604",
    "name": "Uta Mesivta of Kiryas Joel"
  },
  {
    "id": "446640",
    "name": "Harrisburg University of Science and Technology"
  },
  {
    "id": "446774",
    "name": "Blue Ridge Community and Technical College"
  },
  {
    "id": "447014",
    "name": "Daytona College"
  },
  {
    "id": "447023",
    "name": "Delta Technical College-Mississippi"
  },
  {
    "id": "447050",
    "name": "United States University"
  },
  {
    "id": "447148",
    "name": "Empire Beauty School-Avondale"
  },
  {
    "id": "447166",
    "name": "Empire Beauty School-Littleton"
  },
  {
    "id": "447175",
    "name": "Empire Beauty School-Aurora"
  },
  {
    "id": "447263",
    "name": "Joyce University of Nursing and Health Sciences"
  },
  {
    "id": "447351",
    "name": "San Joaquin Valley College-Modesto"
  },
  {
    "id": "447421",
    "name": "Eagle Gate College-Layton"
  },
  {
    "id": "447449",
    "name": "Academy of Hair Design-Jasper"
  },
  {
    "id": "447458",
    "name": "SAE Expression College"
  },
  {
    "id": "447467",
    "name": "Unitech Training Academy-West Monroe"
  },
  {
    "id": "447582",
    "name": "New River Community and Technical College"
  },
  {
    "id": "447591",
    "name": "The College of Health Care Professions-Southwest Houston"
  },
  {
    "id": "447607",
    "name": "UEI College-Chula Vista"
  },
  {
    "id": "447616",
    "name": "UEI College-West Covina"
  },
  {
    "id": "447689",
    "name": "Georgia Gwinnett College"
  },
  {
    "id": "447698",
    "name": "Phoenix Institute of Herbal Medicine & Acupuncture"
  },
  {
    "id": "447704",
    "name": "Tucson College of Beauty"
  },
  {
    "id": "447713",
    "name": "California Career College"
  },
  {
    "id": "447722",
    "name": "Palladium Technical Academy Inc"
  },
  {
    "id": "447731",
    "name": "Los Angeles College of Aesthetics"
  },
  {
    "id": "447759",
    "name": "National Polytechnic College"
  },
  {
    "id": "447768",
    "name": "American Career College-Ontario"
  },
  {
    "id": "447777",
    "name": "Asher College"
  },
  {
    "id": "447795",
    "name": "American Institute of Massage Therapy"
  },
  {
    "id": "447801",
    "name": "University of East-West Medicine"
  },
  {
    "id": "447810",
    "name": "Blake Austin College"
  },
  {
    "id": "447847",
    "name": "Aviator College of Aeronautical Science and Technology"
  },
  {
    "id": "447865",
    "name": "Trendsetters School of Beauty & Barbering"
  },
  {
    "id": "447874",
    "name": "CDA Technical Institute"
  },
  {
    "id": "447883",
    "name": "American Institute of Beauty"
  },
  {
    "id": "447892",
    "name": "North Florida Cosmetology Institute"
  },
  {
    "id": "447908",
    "name": "Florida Academy of Health & Beauty"
  },
  {
    "id": "447917",
    "name": "Augusta School of Massage"
  },
  {
    "id": "447935",
    "name": "ATA College"
  },
  {
    "id": "447953",
    "name": "SUM Bible College and Theological Seminary"
  },
  {
    "id": "447962",
    "name": "Compass Career College"
  },
  {
    "id": "447971",
    "name": "Hair Expressions Academy"
  },
  {
    "id": "447980",
    "name": "Nuvo College of Cosmetology"
  },
  {
    "id": "447999",
    "name": "WellSpring School of Allied Health-Kansas City"
  },
  {
    "id": "448026",
    "name": "The Salon Professional Academy-Fargo"
  },
  {
    "id": "448044",
    "name": "Institute of Professional Careers"
  },
  {
    "id": "448071",
    "name": "Aveda Arts & Sciences Institute-New York"
  },
  {
    "id": "448123",
    "name": "MyrAngel Beauty Institute"
  },
  {
    "id": "448196",
    "name": "Texas Health School"
  },
  {
    "id": "448220",
    "name": "Paul Mitchell the School-Logan"
  },
  {
    "id": "448239",
    "name": "Careers Unlimited"
  },
  {
    "id": "448248",
    "name": "Mountainland Technical College"
  },
  {
    "id": "448257",
    "name": "Avi Career Training"
  },
  {
    "id": "448275",
    "name": "Mountain State School of Massage"
  },
  {
    "id": "448354",
    "name": "Eastern International College-Belleville"
  },
  {
    "id": "448372",
    "name": "San Joaquin Valley College-Rancho Cordova"
  },
  {
    "id": "448424",
    "name": "Broken Arrow Beauty College"
  },
  {
    "id": "448433",
    "name": "Universal Technical Institute of Northern California Inc"
  },
  {
    "id": "448594",
    "name": "West Hills College-Lemoore"
  },
  {
    "id": "448600",
    "name": "Stautzenberger College-Brecksville"
  },
  {
    "id": "448619",
    "name": "Stone Academy-East Hartford"
  },
  {
    "id": "448646",
    "name": "Unitech Training Academy-Houma"
  },
  {
    "id": "448673",
    "name": "Rasmussen University-Illinois"
  },
  {
    "id": "448691",
    "name": "International Institute for Restorative Practices"
  },
  {
    "id": "448770",
    "name": "Automotive Training Center-Warminster"
  },
  {
    "id": "448798",
    "name": "Empire Beauty School-Milwaukee"
  },
  {
    "id": "448859",
    "name": "Triangle Tech Inc-Bethlehem"
  },
  {
    "id": "448895",
    "name": "Milan Institute-Clovis"
  },
  {
    "id": "449038",
    "name": "Strayer University-Florida"
  },
  {
    "id": "449074",
    "name": "Pima Medical Institute-Renton"
  },
  {
    "id": "449083",
    "name": "CBT Technology Institute-Main Campus"
  },
  {
    "id": "449092",
    "name": "CBT Technology Institute-Hialeah"
  },
  {
    "id": "449135",
    "name": "Dewey University-Juana Diaz"
  },
  {
    "id": "449250",
    "name": "The College of Health Care Professions-San Antonio"
  },
  {
    "id": "449311",
    "name": "Brittany Beauty Academy"
  },
  {
    "id": "449320",
    "name": "Lincoln Technical Institute-Whitestone"
  },
  {
    "id": "449339",
    "name": "American Public University System"
  },
  {
    "id": "449348",
    "name": "Huntsville Bible College"
  },
  {
    "id": "449357",
    "name": "Beaumont Adult School"
  },
  {
    "id": "449366",
    "name": "Make-up Designory"
  },
  {
    "id": "449384",
    "name": "Gnomon"
  },
  {
    "id": "449393",
    "name": "Coachella Valley Beauty College"
  },
  {
    "id": "449445",
    "name": "Valley College of Medical Careers"
  },
  {
    "id": "449454",
    "name": "Academy of Natural Therapy Inc"
  },
  {
    "id": "449463",
    "name": "Academy Di Capelli-School of Cosmetology"
  },
  {
    "id": "449472",
    "name": "Delaware Learning Institute of Cosmetology"
  },
  {
    "id": "449481",
    "name": "Dragon Rises College of Oriental Medicine"
  },
  {
    "id": "449490",
    "name": "Palm Beach Academy of Health & Beauty"
  },
  {
    "id": "449506",
    "name": "SABER College"
  },
  {
    "id": "449524",
    "name": "Taylor College"
  },
  {
    "id": "449533",
    "name": "Pensacola School of Massage Therapy & Health Careers"
  },
  {
    "id": "449597",
    "name": "Ideal Beauty Academy"
  },
  {
    "id": "449603",
    "name": "Denmark College"
  },
  {
    "id": "449612",
    "name": "Louisiana Culinary Institute"
  },
  {
    "id": "449658",
    "name": "Bais Medrash Toras Chesed"
  },
  {
    "id": "449676",
    "name": "Ace Institute of Technology"
  },
  {
    "id": "449685",
    "name": "New Age Training"
  },
  {
    "id": "449700",
    "name": "Dayton School of Medical Massage"
  },
  {
    "id": "449719",
    "name": "Northcoast Medical Training Academy"
  },
  {
    "id": "449728",
    "name": "ATA College"
  },
  {
    "id": "449764",
    "name": "Visible Music College"
  },
  {
    "id": "449773",
    "name": "CCI Training Center-Arlington"
  },
  {
    "id": "449782",
    "name": "Champion Beauty College"
  },
  {
    "id": "449807",
    "name": "Southeast Texas Career Institute"
  },
  {
    "id": "449816",
    "name": "Southwest Technical College"
  },
  {
    "id": "449834",
    "name": "Virginia Beach Theological Seminary"
  },
  {
    "id": "449861",
    "name": "Milwaukee Career College"
  },
  {
    "id": "449870",
    "name": "University of the West"
  },
  {
    "id": "449889",
    "name": "American College of Education"
  },
  {
    "id": "449898",
    "name": "South University-Tampa"
  },
  {
    "id": "449904",
    "name": "Milan Institute of Cosmetology-San Antonio Military"
  },
  {
    "id": "449922",
    "name": "Appalachian College of Pharmacy"
  },
  {
    "id": "449959",
    "name": "Paul Mitchell the School-Tampa"
  },
  {
    "id": "449968",
    "name": "Paul Mitchell the School-San Diego"
  },
  {
    "id": "449977",
    "name": "Paul Mitchell the School-Michigan"
  },
  {
    "id": "449986",
    "name": "Paul Mitchell the School-Sherman Oaks"
  },
  {
    "id": "449995",
    "name": "Paul Mitchell the School-Tysons Corner"
  },
  {
    "id": "450021",
    "name": "Blue Cliff College-Houma"
  },
  {
    "id": "450058",
    "name": "Fortis College-Columbus"
  },
  {
    "id": "450067",
    "name": "Fortis College-Cincinnati"
  },
  {
    "id": "450119",
    "name": "Blue Cliff College-Fayetteville"
  },
  {
    "id": "450128",
    "name": "Blue Cliff College-Alexandria"
  },
  {
    "id": "450298",
    "name": "Strayer University-Delaware"
  },
  {
    "id": "450304",
    "name": "Brite Divinity School"
  },
  {
    "id": "450377",
    "name": "Strayer University-Alabama"
  },
  {
    "id": "450395",
    "name": "Brown Aveda Institute-Strongsville"
  },
  {
    "id": "450401",
    "name": "Clary Sage College"
  },
  {
    "id": "450571",
    "name": "Rasmussen University-Wisconsin"
  },
  {
    "id": "450599",
    "name": "Empire Beauty School-Richmond"
  },
  {
    "id": "450605",
    "name": "Empire Beauty School-North Hills"
  },
  {
    "id": "450614",
    "name": "Empire Beauty School-Concord"
  },
  {
    "id": "450632",
    "name": "Lexington Healing Arts Academy"
  },
  {
    "id": "450641",
    "name": "Empire Beauty School-Hooksett"
  },
  {
    "id": "450650",
    "name": "The Institute of Beauty and Wellness"
  },
  {
    "id": "450696",
    "name": "Carrington College-Stockton"
  },
  {
    "id": "450702",
    "name": "Carrington College-Citrus Heights"
  },
  {
    "id": "450711",
    "name": "Trend Barber College"
  },
  {
    "id": "450720",
    "name": "Euphoria Institute of Beauty Arts & Sciences-Summerlin"
  },
  {
    "id": "450933",
    "name": "Columbia Southern University"
  },
  {
    "id": "450951",
    "name": "Arizona Culinary Institute"
  },
  {
    "id": "450960",
    "name": "Healthcare Career College"
  },
  {
    "id": "450988",
    "name": "Coastline Beauty College"
  },
  {
    "id": "451042",
    "name": "Branford Academy of Hair and Cosmetology"
  },
  {
    "id": "451051",
    "name": "Academy of Massage and Bodywork"
  },
  {
    "id": "451079",
    "name": "Academy for Five Element Acupuncture"
  },
  {
    "id": "451097",
    "name": "Academy of Career Training"
  },
  {
    "id": "451103",
    "name": "Miami Regional University"
  },
  {
    "id": "451121",
    "name": "Paul Mitchell the School-Miami"
  },
  {
    "id": "451149",
    "name": "Aveda Institute-South Florida"
  },
  {
    "id": "451158",
    "name": "Aveda Institute-Tallahassee"
  },
  {
    "id": "451167",
    "name": "Shear Excellence Hair Academy"
  },
  {
    "id": "451194",
    "name": "Carlson College of Massage Therapy"
  },
  {
    "id": "451228",
    "name": "MyComputerCareer at Indianapolis"
  },
  {
    "id": "451237",
    "name": "Z Hair Academy"
  },
  {
    "id": "451255",
    "name": "DiGrigoli School of Cosmetology"
  },
  {
    "id": "451264",
    "name": "Millennium Training Institute"
  },
  {
    "id": "451282",
    "name": "Elevate Salon Institute"
  },
  {
    "id": "451291",
    "name": "Lakewood School of Therapeutic Massage"
  },
  {
    "id": "451307",
    "name": "The Salon Professional Academy-Battle Creek"
  },
  {
    "id": "451316",
    "name": "Trend Setters School"
  },
  {
    "id": "451334",
    "name": "Academy of Hair Design-Springfield"
  },
  {
    "id": "451343",
    "name": "Corinth Academy of Cosmetology"
  },
  {
    "id": "451352",
    "name": "Southeastern College-Charlotte"
  },
  {
    "id": "451370",
    "name": "Yeshivas Be'er Yitzchok"
  },
  {
    "id": "451398",
    "name": "Yeshiva Toras Chaim"
  },
  {
    "id": "451404",
    "name": "Talmudical Seminary of Bobov"
  },
  {
    "id": "451413",
    "name": "Center for Allied Health Education"
  },
  {
    "id": "451459",
    "name": "Monroe 2 Orleans BOCES-Center for Workforce Development"
  },
  {
    "id": "451468",
    "name": "Aveda Fredric's Institute-Cincinnati"
  },
  {
    "id": "451477",
    "name": "MyComputerCareer at Columbus"
  },
  {
    "id": "451495",
    "name": "CDE Career Institute"
  },
  {
    "id": "451501",
    "name": "Instituto Educativo Premier"
  },
  {
    "id": "451510",
    "name": "Charleston School of Law"
  },
  {
    "id": "451529",
    "name": "Elite College of Cosmetology"
  },
  {
    "id": "451547",
    "name": "Manuel and Theresa's School of Hair Design-Bryan"
  },
  {
    "id": "451556",
    "name": "Southwest University at El Paso"
  },
  {
    "id": "451565",
    "name": "Paul Mitchell the School-Austin"
  },
  {
    "id": "451574",
    "name": "Aveda Institute-Provo"
  },
  {
    "id": "451583",
    "name": "Dixie Technical College"
  },
  {
    "id": "451626",
    "name": "Victoria's Academy of Cosmetology"
  },
  {
    "id": "451714",
    "name": "Paul Mitchell the School-San Antonio"
  },
  {
    "id": "451741",
    "name": "CEM College-Bayamon"
  },
  {
    "id": "451750",
    "name": "Bryant & Stratton College-Wauwatosa"
  },
  {
    "id": "451820",
    "name": "The Art Institute of Austin"
  },
  {
    "id": "451857",
    "name": "Remington College-Houston Southeast Campus"
  },
  {
    "id": "451866",
    "name": "Remington College-Shreveport Campus"
  },
  {
    "id": "451918",
    "name": "Jenny Lea Academy of Cosmetology"
  },
  {
    "id": "451927",
    "name": "Patrick Henry College"
  },
  {
    "id": "452009",
    "name": "The Hair Academy"
  },
  {
    "id": "452072",
    "name": "Ross Medical Education Center-New Baltimore"
  },
  {
    "id": "452081",
    "name": "Ross College-Sylvania"
  },
  {
    "id": "452106",
    "name": "Escuela De Troqueleria Y Herramentaje"
  },
  {
    "id": "452124",
    "name": "Fortis Institute-Nashville"
  },
  {
    "id": "452373",
    "name": "ATA College-Cincinnati"
  },
  {
    "id": "452771",
    "name": "Dental Assistant Pro LLC-Columbus"
  },
  {
    "id": "452780",
    "name": "Dental Assistant Pro-Lebanon"
  },
  {
    "id": "452887",
    "name": "Elite Welding Academy LLC"
  },
  {
    "id": "452948",
    "name": "Galen College of Nursing-Cincinnati"
  },
  {
    "id": "453163",
    "name": "Strayer University-North Carolina"
  },
  {
    "id": "453215",
    "name": "Strayer University-New Jersey"
  },
  {
    "id": "453297",
    "name": "International Culinary Arts and Sciences Institute"
  },
  {
    "id": "453756",
    "name": "Ohio Media School-Columbus"
  },
  {
    "id": "453792",
    "name": "Ohio Medical Career College"
  },
  {
    "id": "454184",
    "name": "The King's College"
  },
  {
    "id": "454227",
    "name": "Chamberlain University-Illinois"
  },
  {
    "id": "454236",
    "name": "Chamberlain University-Ohio"
  },
  {
    "id": "454245",
    "name": "Chamberlain University-Arizona"
  },
  {
    "id": "454458",
    "name": "Bryan University"
  },
  {
    "id": "454537",
    "name": "The Salon Professional Academy-Appleton"
  },
  {
    "id": "454546",
    "name": "Milan Institute of Cosmetology-Vacaville"
  },
  {
    "id": "454555",
    "name": "Milan Institute of Cosmetology-Reno"
  },
  {
    "id": "454582",
    "name": "Ottawa University-Online"
  },
  {
    "id": "454607",
    "name": "Paul Mitchell the School-Esani"
  },
  {
    "id": "454616",
    "name": "Institute of Production and Recording"
  },
  {
    "id": "454652",
    "name": "Paul Mitchell the School-Birmingham"
  },
  {
    "id": "454689",
    "name": "Taft University System"
  },
  {
    "id": "454698",
    "name": "Mayfield College"
  },
  {
    "id": "454722",
    "name": "Academy of Esthetics and Cosmetology"
  },
  {
    "id": "454759",
    "name": "Borner's Barber College"
  },
  {
    "id": "454768",
    "name": "Paul Mitchell the School-Pasadena"
  },
  {
    "id": "454777",
    "name": "San Francisco Institute of Esthetics & Cosmetology Inc"
  },
  {
    "id": "454786",
    "name": "Laurus College"
  },
  {
    "id": "454795",
    "name": "Healing Hands School of Holistic Health"
  },
  {
    "id": "454801",
    "name": "Career Development Institute Inc"
  },
  {
    "id": "454810",
    "name": "Auguste Escoffier School of Culinary Arts-Boulder"
  },
  {
    "id": "454829",
    "name": "Aspen University"
  },
  {
    "id": "454838",
    "name": "Institute of Taoist Education and Acupuncture"
  },
  {
    "id": "454856",
    "name": "Denver College of Nursing"
  },
  {
    "id": "454865",
    "name": "Cambridge College of Healthcare & Technology"
  },
  {
    "id": "454874",
    "name": "Cozmo Beauty School"
  },
  {
    "id": "454917",
    "name": "Celebrity School of Beauty"
  },
  {
    "id": "454926",
    "name": "Paul Mitchell the School-Atlanta"
  },
  {
    "id": "454935",
    "name": "Evans Hairstyling College-Rexburg"
  },
  {
    "id": "454944",
    "name": "Paul Mitchell the School-Rexburg"
  },
  {
    "id": "454953",
    "name": "University of Aesthetics & Cosmetology"
  },
  {
    "id": "454962",
    "name": "The University of Aesthetics & Cosmetology"
  },
  {
    "id": "454971",
    "name": "Tricoci University of Beauty Culture-Chicago NW"
  },
  {
    "id": "454980",
    "name": "Tricoci University of Beauty Culture-Glendale Heights"
  },
  {
    "id": "454999",
    "name": "Tricoci University of Beauty Culture-Peoria"
  },
  {
    "id": "455008",
    "name": "Tricoci University of Beauty Culture-Rockford"
  },
  {
    "id": "455026",
    "name": "Omega Studios' School of Applied Recording Arts & Sciences"
  },
  {
    "id": "455062",
    "name": "French Academy of Cosmetology"
  },
  {
    "id": "455071",
    "name": "Healing Arts Center"
  },
  {
    "id": "455080",
    "name": "Paul Mitchell the School-Springfield"
  },
  {
    "id": "455099",
    "name": "Urshan Graduate School of Theology"
  },
  {
    "id": "455105",
    "name": "Mississippi Institute of Aesthetics Nails & Cosmetology"
  },
  {
    "id": "455114",
    "name": "College of Western Idaho"
  },
  {
    "id": "455141",
    "name": "Aveda Institute-Chapel Hill"
  },
  {
    "id": "455178",
    "name": "Daoist Traditions College of Chinese Medical Arts"
  },
  {
    "id": "455187",
    "name": "Total Image Beauty Academy"
  },
  {
    "id": "455196",
    "name": "Jersey College"
  },
  {
    "id": "455202",
    "name": "American Institute of Medical Sciences & Education"
  },
  {
    "id": "455211",
    "name": "Casal Institute of Nevada"
  },
  {
    "id": "455220",
    "name": "Career School of NY"
  },
  {
    "id": "455257",
    "name": "Yeshiva of Machzikai Hadas"
  },
  {
    "id": "455275",
    "name": "Aveda Institute-Columbus"
  },
  {
    "id": "455284",
    "name": "Paul Mitchell the School-Cincinnati"
  },
  {
    "id": "455327",
    "name": "Hands on Therapy"
  },
  {
    "id": "455336",
    "name": "MediaTech Institute-Dallas"
  },
  {
    "id": "455354",
    "name": "Aveda Arts & Sciences Institute-San Antonio"
  },
  {
    "id": "455381",
    "name": "Skin Science Institute"
  },
  {
    "id": "455406",
    "name": "Pacific Northwest University of Health Sciences"
  },
  {
    "id": "455433",
    "name": "The Chicago School of Professional Psychology at Anaheim"
  },
  {
    "id": "455460",
    "name": "Fortis College-Dothan"
  },
  {
    "id": "455479",
    "name": "Fortis Institute-Pensacola"
  },
  {
    "id": "455488",
    "name": "Fortis College-Montgomery"
  },
  {
    "id": "455512",
    "name": "Woodland Community College"
  },
  {
    "id": "455558",
    "name": "Dorsey College-Roseville"
  },
  {
    "id": "455628",
    "name": "Fortis Institute-Birmingham"
  },
  {
    "id": "455664",
    "name": "The Chicago School of Professional Psychology at Los Angeles"
  },
  {
    "id": "455707",
    "name": "Cardiac and Vascular Institute of Ultrasound"
  },
  {
    "id": "455725",
    "name": "Studio Academy of Beauty"
  },
  {
    "id": "455734",
    "name": "California Nurses Educational Institute"
  },
  {
    "id": "455761",
    "name": "Paul Mitchell the School-Sacramento"
  },
  {
    "id": "455770",
    "name": "Providence Christian College"
  },
  {
    "id": "455798",
    "name": "Oxford Academy of Hair Design Inc"
  },
  {
    "id": "455804",
    "name": "Institute of World Politics"
  },
  {
    "id": "455813",
    "name": "Pontifical John Paul II Institute for Studies on Marriage and Family"
  },
  {
    "id": "455831",
    "name": "Medical Institute of Palm Beach"
  },
  {
    "id": "455859",
    "name": "Oliver Finley Academy of Cosmetology"
  },
  {
    "id": "455868",
    "name": "National Career College"
  },
  {
    "id": "455877",
    "name": "Aveda Institute-Chicago"
  },
  {
    "id": "455886",
    "name": "Tricoci University of Beauty Culture-Libertyville"
  },
  {
    "id": "455895",
    "name": "Tricoci University of Beauty Culture-Bridgeview"
  },
  {
    "id": "455901",
    "name": "The Temple-A Paul Mitchell Partner School"
  },
  {
    "id": "455910",
    "name": "Center for Massage"
  },
  {
    "id": "455929",
    "name": "The Academy of Hair Design LLC"
  },
  {
    "id": "455965",
    "name": "Toni & Guy Hairdressing Academy-Cranston"
  },
  {
    "id": "455974",
    "name": "South Texas Training Center"
  },
  {
    "id": "455983",
    "name": "Columbia College"
  },
  {
    "id": "455992",
    "name": "Vermont College of Fine Arts"
  },
  {
    "id": "456001",
    "name": "Advanced Welding Institute"
  },
  {
    "id": "456029",
    "name": "Paul Mitchell the School-Memphis"
  },
  {
    "id": "456038",
    "name": "Pima Medical Institute-East Valley"
  },
  {
    "id": "456065",
    "name": "Paul Mitchell the School-Cleveland"
  },
  {
    "id": "456074",
    "name": "Paul Mitchell the School-Columbus"
  },
  {
    "id": "456135",
    "name": "Empire Beauty School-Spring Lake Park"
  },
  {
    "id": "456153",
    "name": "MediaTech Institute-Houston"
  },
  {
    "id": "456205",
    "name": "Miller-Motte College-Raleigh"
  },
  {
    "id": "456214",
    "name": "Lamson Institute"
  },
  {
    "id": "456287",
    "name": "College of Hair Design-East Campus"
  },
  {
    "id": "456454",
    "name": "Fortis College-Salt Lake City"
  },
  {
    "id": "456481",
    "name": "Polytechnic University of Puerto Rico-Miami"
  },
  {
    "id": "456490",
    "name": "Polytechnic University of Puerto Rico-Orlando"
  },
  {
    "id": "456506",
    "name": "Ross Medical Education Center-Fort Wayne"
  },
  {
    "id": "456515",
    "name": "Ross Medical Education Center-Battle Creek"
  },
  {
    "id": "456542",
    "name": "Geisinger Commonwealth School of Medicine"
  },
  {
    "id": "456551",
    "name": "Josef's School of Hair Skin & Body-Fargo"
  },
  {
    "id": "456579",
    "name": "Miller-Motte College-McCann-Allentown"
  },
  {
    "id": "456588",
    "name": "Salon Success Academy-Fontana"
  },
  {
    "id": "456597",
    "name": "Salon Success Academy-Redlands"
  },
  {
    "id": "456621",
    "name": "Strayer University-West Virginia"
  },
  {
    "id": "456825",
    "name": "Paul Mitchell the School-Baton Rouge"
  },
  {
    "id": "456852",
    "name": "San Joaquin Valley College-Hesperia"
  },
  {
    "id": "456922",
    "name": "South Texas Vocational Technical Institute-Brownsville"
  },
  {
    "id": "456931",
    "name": "Miller-Motte College-STVT-Corpus Christi"
  },
  {
    "id": "456959",
    "name": "University of Minnesota-Rochester"
  },
  {
    "id": "456968",
    "name": "Health And Style Institute"
  },
  {
    "id": "456977",
    "name": "CBT Technology Institute-Cutler Bay"
  },
  {
    "id": "457031",
    "name": "Capri Beauty College"
  },
  {
    "id": "457077",
    "name": "Milan Institute-Bakersfield"
  },
  {
    "id": "457086",
    "name": "Homestead Schools"
  },
  {
    "id": "457101",
    "name": "Carrington College-Las Vegas"
  },
  {
    "id": "457110",
    "name": "Carrington College-Reno"
  },
  {
    "id": "457129",
    "name": "Chamberlain University-Florida"
  },
  {
    "id": "457192",
    "name": "Washington Barber College Inc"
  },
  {
    "id": "457208",
    "name": "InterCoast Colleges-Rancho Cordova"
  },
  {
    "id": "457226",
    "name": "Horizon University"
  },
  {
    "id": "457253",
    "name": "Beyond 21st Century Beauty Academy"
  },
  {
    "id": "457271",
    "name": "Academy for Jewish Religion-California"
  },
  {
    "id": "457299",
    "name": "Angeles College"
  },
  {
    "id": "457314",
    "name": "Angeles Institute"
  },
  {
    "id": "457323",
    "name": "Paul Mitchell the School-Temecula"
  },
  {
    "id": "457332",
    "name": "TIGI Hairdressing Academy Guilford"
  },
  {
    "id": "457341",
    "name": "International Institute of Cosmetology"
  },
  {
    "id": "457350",
    "name": "Florida School of Traditional Midwifery"
  },
  {
    "id": "457378",
    "name": "Emerald Coast Technical College"
  },
  {
    "id": "457387",
    "name": "Immokalee Technical College"
  },
  {
    "id": "457396",
    "name": "American Academy of Cosmetology"
  },
  {
    "id": "457402",
    "name": "University of Fort Lauderdale"
  },
  {
    "id": "457411",
    "name": "Aviation Institute of Maintenance-Orlando"
  },
  {
    "id": "457420",
    "name": "Summit Salon Academy"
  },
  {
    "id": "457439",
    "name": "Summit Salon Academy-Gainesville"
  },
  {
    "id": "457448",
    "name": "Tenaj Salon Institute"
  },
  {
    "id": "457466",
    "name": "Profile Institute of Barber-Styling"
  },
  {
    "id": "457475",
    "name": "Keune Academy by 124"
  },
  {
    "id": "457484",
    "name": "Pacific Rim Christian University"
  },
  {
    "id": "457493",
    "name": "Paul Mitchell the School-Twin Falls"
  },
  {
    "id": "457527",
    "name": "Ambria College of Nursing"
  },
  {
    "id": "457536",
    "name": "Midwestern Career College"
  },
  {
    "id": "457545",
    "name": "Paul Mitchell the School-Normal"
  },
  {
    "id": "457554",
    "name": "Innovations Design Academy"
  },
  {
    "id": "457563",
    "name": "Aveda Fredric's Institute-Indianapolis"
  },
  {
    "id": "457572",
    "name": "Summit Salon Academy"
  },
  {
    "id": "457581",
    "name": "Eric Fisher Academy"
  },
  {
    "id": "457606",
    "name": "My Le's Beauty College"
  },
  {
    "id": "457615",
    "name": "Blackstone Valley Vocational Regional School District"
  },
  {
    "id": "457624",
    "name": "Southern Worcester County Regional Vocational School District"
  },
  {
    "id": "457633",
    "name": "L'esprit Academy"
  },
  {
    "id": "457642",
    "name": "Marketti Academy of Cosmetology"
  },
  {
    "id": "457679",
    "name": "Avalon School of Cosmetology"
  },
  {
    "id": "457688",
    "name": "American Business and Technology University"
  },
  {
    "id": "457697",
    "name": "City Vision University"
  },
  {
    "id": "457721",
    "name": "Bitterroot School of Cosmetology"
  },
  {
    "id": "457749",
    "name": "Montana Academy of Salons"
  },
  {
    "id": "457767",
    "name": "National Career Institute"
  },
  {
    "id": "457776",
    "name": "Paul Mitchell the School-Albuquerque"
  },
  {
    "id": "457785",
    "name": "International Academy of Style"
  },
  {
    "id": "457794",
    "name": "EDP School"
  },
  {
    "id": "457800",
    "name": "New York Medical Career Training Center"
  },
  {
    "id": "457819",
    "name": "Institute of Culinary Education"
  },
  {
    "id": "457837",
    "name": "The Salon Professional Academy-Tonawanda"
  },
  {
    "id": "457855",
    "name": "Paul Mitchell the School-NYC"
  },
  {
    "id": "457873",
    "name": "Four County Career Center"
  },
  {
    "id": "457916",
    "name": "Summit Salon Academy-Perrysburg"
  },
  {
    "id": "457925",
    "name": "Portland Actors Conservatory"
  },
  {
    "id": "457943",
    "name": "Somerset County Technology Center"
  },
  {
    "id": "457952",
    "name": "Metro Beauty Academy"
  },
  {
    "id": "457989",
    "name": "Academy for Careers and Technology"
  },
  {
    "id": "457998",
    "name": "Aiken School of Cosmetology and Barbering"
  },
  {
    "id": "458007",
    "name": "Love Beauty School Inc"
  },
  {
    "id": "458034",
    "name": "Houston International College Cardiotech Ultrasound School"
  },
  {
    "id": "458043",
    "name": "DuVall's School of Cosmetology"
  },
  {
    "id": "458061",
    "name": "Texas Beauty College"
  },
  {
    "id": "458070",
    "name": "Avenue Five Institute"
  },
  {
    "id": "458098",
    "name": "The Salon Professional Academy-Lewisville"
  },
  {
    "id": "458104",
    "name": "Renaissance Academie"
  },
  {
    "id": "458113",
    "name": "Ascent College"
  },
  {
    "id": "458122",
    "name": "Institute of Advanced Medical Esthetics"
  },
  {
    "id": "458131",
    "name": "Aveda Arts & Sciences Institute Seattle"
  },
  {
    "id": "458140",
    "name": "Northwest School of Wooden Boat Building"
  },
  {
    "id": "458168",
    "name": "The Salon Professional Academy-Onalaska"
  },
  {
    "id": "458210",
    "name": "West Coast University-Orange County"
  },
  {
    "id": "458229",
    "name": "West Coast University-Ontario"
  },
  {
    "id": "458274",
    "name": "Paul Mitchell the School-Chicago"
  },
  {
    "id": "458405",
    "name": "Miller-Motte College-Fayetteville"
  },
  {
    "id": "458423",
    "name": "European Massage Therapy School-Las Vegas"
  },
  {
    "id": "458441",
    "name": "Miller-Motte College-Columbus"
  },
  {
    "id": "458496",
    "name": "The Art Institute of Virginia Beach"
  },
  {
    "id": "458681",
    "name": "Fortis College-Indianapolis"
  },
  {
    "id": "458803",
    "name": "Empire Beauty School-E Memphis"
  },
  {
    "id": "458812",
    "name": "Empire Beauty School-Nashville"
  },
  {
    "id": "458821",
    "name": "Empire Beauty School-Jackson"
  },
  {
    "id": "458830",
    "name": "Empire Beauty School-Springfield"
  },
  {
    "id": "458858",
    "name": "Empire Beauty School-Speedway"
  },
  {
    "id": "458867",
    "name": "Empire Beauty School-Morrow"
  },
  {
    "id": "458885",
    "name": "Strayer University-Arkansas"
  },
  {
    "id": "458919",
    "name": "Strayer University-Georgia"
  },
  {
    "id": "458955",
    "name": "Strayer University-Mississippi"
  },
  {
    "id": "458964",
    "name": "Strayer University-South Carolina"
  },
  {
    "id": "458973",
    "name": "Strayer University-Texas"
  },
  {
    "id": "458982",
    "name": "The Art Institute of San Antonio"
  },
  {
    "id": "459019",
    "name": "Empire Beauty School-Charlotte"
  },
  {
    "id": "459046",
    "name": "Empire Beauty School-Winston-Salem"
  },
  {
    "id": "459055",
    "name": "Empire Beauty School-West Palm"
  },
  {
    "id": "459064",
    "name": "Empire Beauty School-Pineville"
  },
  {
    "id": "459116",
    "name": "Paul Mitchell the School-Phoenix"
  },
  {
    "id": "459125",
    "name": "All Beauty College"
  },
  {
    "id": "459170",
    "name": "Paul Mitchell the School-Modesto"
  },
  {
    "id": "459189",
    "name": "Cosmo Beauty Academy"
  },
  {
    "id": "459198",
    "name": "Paul Mitchell the School-Fresno"
  },
  {
    "id": "459204",
    "name": "Unitek College"
  },
  {
    "id": "459213",
    "name": "Gurnick Academy of Medical Arts"
  },
  {
    "id": "459231",
    "name": "Paul Mitchell the School-East Bay"
  },
  {
    "id": "459259",
    "name": "South University-Richmond"
  },
  {
    "id": "459268",
    "name": "South University-Virginia Beach"
  },
  {
    "id": "459277",
    "name": "Aveda Institute-Denver"
  },
  {
    "id": "459286",
    "name": "Paul Mitchell the School-Colorado Springs"
  },
  {
    "id": "459295",
    "name": "The Salon Professional Academy-Colorado Springs"
  },
  {
    "id": "459301",
    "name": "Paul Mitchell the School-Delaware"
  },
  {
    "id": "459310",
    "name": "Future-Tech Institute"
  },
  {
    "id": "459329",
    "name": "Fred K Marchman Technical College"
  },
  {
    "id": "459347",
    "name": "The Salon Professional Academy-Ft Myers"
  },
  {
    "id": "459374",
    "name": "Universal Spa Training Academy"
  },
  {
    "id": "459392",
    "name": "The Salon Professional Academy-Evansville"
  },
  {
    "id": "459408",
    "name": "Tri County Regional Vocational Technical High School"
  },
  {
    "id": "459417",
    "name": "Compass College of Film and Media"
  },
  {
    "id": "459426",
    "name": "Nova Academy of Cosmetology"
  },
  {
    "id": "459462",
    "name": "SAE Institute of Technology-New York"
  },
  {
    "id": "459499",
    "name": "Sage School of Massage & Esthetics"
  },
  {
    "id": "459514",
    "name": "Peloton College"
  },
  {
    "id": "459523",
    "name": "ABC Beauty Academy"
  },
  {
    "id": "459532",
    "name": "Salon & Spa Institute"
  },
  {
    "id": "459541",
    "name": "American Beauty Academy"
  },
  {
    "id": "459550",
    "name": "Summit Salon Academy"
  },
  {
    "id": "459578",
    "name": "Paul Mitchell the School-Milwaukee"
  },
  {
    "id": "459727",
    "name": "Touro University Worldwide"
  },
  {
    "id": "459736",
    "name": "Touro University California"
  },
  {
    "id": "459745",
    "name": "The Chicago School of Professional Psychology at Washington DC"
  },
  {
    "id": "459824",
    "name": "Touro University Nevada"
  },
  {
    "id": "459842",
    "name": "Herzing University-Kenosha"
  },
  {
    "id": "459851",
    "name": "Herzing University-Brookfield"
  },
  {
    "id": "459949",
    "name": "Texas A&M University-San Antonio"
  },
  {
    "id": "459958",
    "name": "Fortis Institute"
  },
  {
    "id": "459967",
    "name": "Southern Careers Institute-Brownsville"
  },
  {
    "id": "459985",
    "name": "Southern Careers Institute-Harlingen"
  },
  {
    "id": "459994",
    "name": "Strayer University-Global Region"
  },
  {
    "id": "460011",
    "name": "Kenneth Shuler School of Cosmetology-Florence"
  },
  {
    "id": "460020",
    "name": "Fortis College-Columbia"
  },
  {
    "id": "460093",
    "name": "Ross Medical Education Center-Davison"
  },
  {
    "id": "460109",
    "name": "Ross Medical Education Center-Granger"
  },
  {
    "id": "460118",
    "name": "Ross Medical Education Center-Niles"
  },
  {
    "id": "460127",
    "name": "Ross Medical Education Center-Canton"
  },
  {
    "id": "460136",
    "name": "Pima Medical Institute-Houston"
  },
  {
    "id": "460145",
    "name": "Illinois Media School-Chicago Campus"
  },
  {
    "id": "460172",
    "name": "Miller-Motte College-STVT-San Antonio"
  },
  {
    "id": "460181",
    "name": "Concorde Career College-Dallas"
  },
  {
    "id": "460190",
    "name": "Concorde Career Institute-Orlando"
  },
  {
    "id": "460206",
    "name": "Concorde Career College-San Antonio"
  },
  {
    "id": "460349",
    "name": "Johnson & Wales University-Online"
  },
  {
    "id": "460376",
    "name": "Fairfax University of America"
  },
  {
    "id": "460385",
    "name": "Geisinger-Lewistown Hospital School of Nursing"
  },
  {
    "id": "460394",
    "name": "Moreno Valley College"
  },
  {
    "id": "460464",
    "name": "Norco College"
  },
  {
    "id": "460482",
    "name": "Dorsey College-Saginaw"
  },
  {
    "id": "460516",
    "name": "Milan Institute of Cosmetology-La Quinta"
  },
  {
    "id": "460525",
    "name": "Milan Institute-Boise"
  },
  {
    "id": "460534",
    "name": "Milan Institute of Cosmetology-El Paso"
  },
  {
    "id": "460543",
    "name": "Universal Technical Institute-Dallas Fort Worth"
  },
  {
    "id": "460677",
    "name": "ICPR Junior College"
  },
  {
    "id": "460701",
    "name": "Artistic Nails and Beauty Academy-Lakeland"
  },
  {
    "id": "460738",
    "name": "American Sentinel College of Nursing and Health Sciences"
  },
  {
    "id": "460756",
    "name": "Hawaii Medical College"
  },
  {
    "id": "460765",
    "name": "Carolina College of Hair Design"
  },
  {
    "id": "460808",
    "name": "College of Massage Therapy"
  },
  {
    "id": "460817",
    "name": "Miller-Motte College-Jacksonville"
  },
  {
    "id": "460826",
    "name": "Miller-Motte College-Augusta"
  },
  {
    "id": "460835",
    "name": "Miller-Motte College-Conway"
  },
  {
    "id": "460862",
    "name": "Aveda Institute-Portland"
  },
  {
    "id": "460871",
    "name": "Chamberlain University-Virginia"
  },
  {
    "id": "460914",
    "name": "Penrose Academy"
  },
  {
    "id": "460932",
    "name": "Skin Institute"
  },
  {
    "id": "460969",
    "name": "Beauty Academy of South Florida"
  },
  {
    "id": "460978",
    "name": "Ogle School Hair Skin Nails-North Dallas"
  },
  {
    "id": "460987",
    "name": "The Salon Professional Academy"
  },
  {
    "id": "460996",
    "name": "The Salon Professional Academy-St Charles"
  },
  {
    "id": "461014",
    "name": "Mildred Elley-New York Campus"
  },
  {
    "id": "461023",
    "name": "National Paralegal College"
  },
  {
    "id": "461032",
    "name": "Carolina College of Biblical Studies"
  },
  {
    "id": "461087",
    "name": "Northeast Technology Center"
  },
  {
    "id": "461111",
    "name": "Allstate Hairstyling & Barber College"
  },
  {
    "id": "461120",
    "name": "Omega Graduate School"
  },
  {
    "id": "461139",
    "name": "Jung Tao School of Classical Chinese Medicine"
  },
  {
    "id": "461148",
    "name": "New York Film Academy"
  },
  {
    "id": "461175",
    "name": "International College of Cosmetology"
  },
  {
    "id": "461193",
    "name": "Bella Capelli Academy"
  },
  {
    "id": "461218",
    "name": "Institute of Medical Careers"
  },
  {
    "id": "461245",
    "name": "Flagler Technical College"
  },
  {
    "id": "461254",
    "name": "Galaxy Medical College"
  },
  {
    "id": "461263",
    "name": "American Medical Sciences Center"
  },
  {
    "id": "461272",
    "name": "Mandalyn Academy"
  },
  {
    "id": "461281",
    "name": "Jose Maria Vargas University"
  },
  {
    "id": "461306",
    "name": "D A Dorsey Technical College"
  },
  {
    "id": "461315",
    "name": "Keweenaw Bay Ojibwa Community College"
  },
  {
    "id": "461324",
    "name": "Meridian Institute of Surgical Assisting"
  },
  {
    "id": "461333",
    "name": "Mauna Loa Helicopters"
  },
  {
    "id": "461342",
    "name": "Acaydia School of Aesthetics"
  },
  {
    "id": "461379",
    "name": "Holistic Massage Training Institute"
  },
  {
    "id": "461388",
    "name": "Diamonds College"
  },
  {
    "id": "461412",
    "name": "Northern Virginia School of Therapeutic Massage"
  },
  {
    "id": "461421",
    "name": "Paul Mitchell the School-St Louis"
  },
  {
    "id": "461430",
    "name": "Advanced Training Institute"
  },
  {
    "id": "461485",
    "name": "Shepherds Theological Seminary"
  },
  {
    "id": "461494",
    "name": "Taylor Andrews Academy of Hair Design-West Jordan"
  },
  {
    "id": "461500",
    "name": "Santa Ana Beauty Academy"
  },
  {
    "id": "461528",
    "name": "Manna University"
  },
  {
    "id": "461537",
    "name": "Evolve Beauty Academy"
  },
  {
    "id": "461555",
    "name": "Aveda Institute-New Mexico"
  },
  {
    "id": "461573",
    "name": "American Trade School"
  },
  {
    "id": "461582",
    "name": "Estelle Skin Care and Spa Institute"
  },
  {
    "id": "461591",
    "name": "Paul Mitchell the School-Honolulu"
  },
  {
    "id": "461607",
    "name": "Elite Cosmetology School"
  },
  {
    "id": "461625",
    "name": "American Technical Institute"
  },
  {
    "id": "461643",
    "name": "Bergin University of Canine Studies"
  },
  {
    "id": "461652",
    "name": "Aveda Institute-Boise"
  },
  {
    "id": "461689",
    "name": "Pima Medical Institute-Aurora"
  },
  {
    "id": "461704",
    "name": "Capilo School of Hair Design"
  },
  {
    "id": "461713",
    "name": "Pure Aesthetics Natural Skincare School"
  },
  {
    "id": "461722",
    "name": "Lindsey Institute of Cosmetology"
  },
  {
    "id": "461740",
    "name": "Buckner Barber School"
  },
  {
    "id": "461759",
    "name": "Simmons College of Kentucky"
  },
  {
    "id": "461768",
    "name": "Atelier Esthetique Institute of Esthetics"
  },
  {
    "id": "461786",
    "name": "Advanced College of Cosmetology"
  },
  {
    "id": "461795",
    "name": "North American University"
  },
  {
    "id": "461810",
    "name": "United Beauty College"
  },
  {
    "id": "461829",
    "name": "Cinta Aveda Institute"
  },
  {
    "id": "461838",
    "name": "Long Island Nail Skin & Hair Institute"
  },
  {
    "id": "461847",
    "name": "Keser Torah-Mayan Hatalmud"
  },
  {
    "id": "461856",
    "name": "Southern California Health Institute"
  },
  {
    "id": "461865",
    "name": "Best Care College"
  },
  {
    "id": "461874",
    "name": "First Class Cosmetology School"
  },
  {
    "id": "461883",
    "name": "Millennia Atlantic University"
  },
  {
    "id": "461892",
    "name": "Abcott Institute"
  },
  {
    "id": "461908",
    "name": "The Salon Professional Academy-Kenosha"
  },
  {
    "id": "461917",
    "name": "Paul Mitchell the School-Indianapolis"
  },
  {
    "id": "461926",
    "name": "Taylor Andrews Academy-St George"
  },
  {
    "id": "461944",
    "name": "Protege Academy"
  },
  {
    "id": "461953",
    "name": "Colorado Academy of Veterinary Technology"
  },
  {
    "id": "461962",
    "name": "Northeast Technical Institute"
  },
  {
    "id": "461999",
    "name": "Elite School of Cosmetology"
  },
  {
    "id": "462008",
    "name": "Paul Mitchell the School-Las Vegas"
  },
  {
    "id": "462017",
    "name": "Summit Salon Academy-Kokomo"
  },
  {
    "id": "462035",
    "name": "Florida Academy"
  },
  {
    "id": "462044",
    "name": "Institute for Doctoral Studies in the Visual Arts"
  },
  {
    "id": "462053",
    "name": "State Career College"
  },
  {
    "id": "462062",
    "name": "Academy for Salon Professionals"
  },
  {
    "id": "462071",
    "name": "Paul Mitchell the School-Spokane"
  },
  {
    "id": "462336",
    "name": "International College of Beauty Arts & Sciences"
  },
  {
    "id": "462345",
    "name": "The Salon Professional Academy"
  },
  {
    "id": "462354",
    "name": "John Paul the Great Catholic University"
  },
  {
    "id": "463056",
    "name": "University of Connecticut-Hartford Campus"
  },
  {
    "id": "464226",
    "name": "Ottawa University-Surprise"
  },
  {
    "id": "466921",
    "name": "Chamberlain University-Missouri"
  },
  {
    "id": "466930",
    "name": "Chamberlain University-Texas"
  },
  {
    "id": "467094",
    "name": "Continental School of Beauty Culture-Mattydale"
  },
  {
    "id": "467368",
    "name": "Carrington College-Ontario"
  },
  {
    "id": "467863",
    "name": "Paul Mitchell the School-Columbia"
  },
  {
    "id": "467872",
    "name": "Paul Mitchell the School-Charleston"
  },
  {
    "id": "467906",
    "name": "Kenneth Shuler School of Cosmetology-Greenville"
  },
  {
    "id": "468246",
    "name": "Northwest College-Eugene"
  },
  {
    "id": "468255",
    "name": "Northwest College-Tualatin"
  },
  {
    "id": "468291",
    "name": "San Joaquin Valley College-Hanford Classroom"
  },
  {
    "id": "468769",
    "name": "CET-Soledad"
  },
  {
    "id": "468848",
    "name": "UEI College-Oceanside"
  },
  {
    "id": "468893",
    "name": "Paul Mitchell the School-New Orleans"
  },
  {
    "id": "468909",
    "name": "UEI College-Garden Grove"
  },
  {
    "id": "468963",
    "name": "New England Tractor Trailer Training School of Massachusetts"
  },
  {
    "id": "469416",
    "name": "Dewey University-Manati"
  },
  {
    "id": "469610",
    "name": "Allen School-Phoenix"
  },
  {
    "id": "469629",
    "name": "Oklahoma Technical College"
  },
  {
    "id": "469911",
    "name": "Unitech Training Academy-Alexandria"
  },
  {
    "id": "469957",
    "name": "Aveda Institute-Los Angeles"
  },
  {
    "id": "470038",
    "name": "UEI College-Riverside"
  },
  {
    "id": "470047",
    "name": "WellSpring School of Allied Health-Lawrence"
  },
  {
    "id": "470296",
    "name": "Manuel and Theresa's School of Hair Design-Victoria"
  },
  {
    "id": "470393",
    "name": "Tricoci University of Beauty Culture-Chicago NE"
  },
  {
    "id": "474863",
    "name": "Los Angeles Pacific University"
  },
  {
    "id": "474915",
    "name": "Avalon Institute-Phoenix"
  },
  {
    "id": "474924",
    "name": "Avalon Institute-Layton"
  },
  {
    "id": "475015",
    "name": "Aveda Institute-Des Moines"
  },
  {
    "id": "475024",
    "name": "Fortis Institute-Lawrenceville"
  },
  {
    "id": "475033",
    "name": "Relay Graduate School of Education"
  },
  {
    "id": "475060",
    "name": "Miller-Motte College-Macon"
  },
  {
    "id": "475121",
    "name": "South University-Savannah Online"
  },
  {
    "id": "475176",
    "name": "San Joaquin Valley College-Temecula"
  },
  {
    "id": "475200",
    "name": "Whitworth University-Adult Degree Programs"
  },
  {
    "id": "475228",
    "name": "The Santa Barbara and Ventura Colleges of Law at Santa Barbara"
  },
  {
    "id": "475273",
    "name": "Springfield College-Regional Online and Continuing Education"
  },
  {
    "id": "475282",
    "name": "Marian Health Careers Center-Van Nuys Campus"
  },
  {
    "id": "475325",
    "name": "Milan Institute-Las Vegas"
  },
  {
    "id": "475398",
    "name": "Catholic Distance University"
  },
  {
    "id": "475404",
    "name": "New York School of Esthetics & Day Spa"
  },
  {
    "id": "475413",
    "name": "Panache Academy of Beauty"
  },
  {
    "id": "475422",
    "name": "Finger Lakes Health College of Nursing & Health Sciences"
  },
  {
    "id": "475431",
    "name": "ASI Career Institute"
  },
  {
    "id": "475468",
    "name": "Christine Valmy International School of Esthetics & Cosmetology"
  },
  {
    "id": "475477",
    "name": "City College-Hollywood"
  },
  {
    "id": "475486",
    "name": "Paul Mitchell the School-Arlington"
  },
  {
    "id": "475495",
    "name": "Rocky Mountain University of Health Professions"
  },
  {
    "id": "475547",
    "name": "Paul Mitchell the School-Fort Myers"
  },
  {
    "id": "475556",
    "name": "Paul Mitchell the School-Raleigh"
  },
  {
    "id": "475565",
    "name": "CUNY Stella and Charles Guttman Community College"
  },
  {
    "id": "475574",
    "name": "Lil Lou's Beauty and Barber College"
  },
  {
    "id": "475608",
    "name": "Criswell College"
  },
  {
    "id": "475635",
    "name": "Beverly Hills Design Institute"
  },
  {
    "id": "475653",
    "name": "Reflections Academy of Beauty"
  },
  {
    "id": "475662",
    "name": "UCAS University of Cosmetology Arts & Sciences-La Joya"
  },
  {
    "id": "475705",
    "name": "Annenberg School of Nursing"
  },
  {
    "id": "475714",
    "name": "American Medical Academy"
  },
  {
    "id": "475723",
    "name": "Tooele Technical College"
  },
  {
    "id": "475732",
    "name": "Chamberlain University-Georgia"
  },
  {
    "id": "475741",
    "name": "Chamberlain University-Indiana"
  },
  {
    "id": "475839",
    "name": "Sessions College for Professional Design"
  },
  {
    "id": "476063",
    "name": "Tulsa Welding School-Jacksonville"
  },
  {
    "id": "476179",
    "name": "Empire Beauty School-Virginia Beach"
  },
  {
    "id": "476230",
    "name": "Ross Medical Education Center-Morgantown"
  },
  {
    "id": "476294",
    "name": "Paul Mitchell the School-Ogden"
  },
  {
    "id": "476355",
    "name": "Miami Ad School-New York"
  },
  {
    "id": "476489",
    "name": "Larry's Barber College"
  },
  {
    "id": "476498",
    "name": "Futura Career Institute"
  },
  {
    "id": "476513",
    "name": "Flair Beauty College"
  },
  {
    "id": "476540",
    "name": "Vogue College of Cosmetology-San Antonio Fredericksburg"
  },
  {
    "id": "476559",
    "name": "Vogue College of Cosmetology"
  },
  {
    "id": "476568",
    "name": "Tomorrow's Image Barber And Beauty Academy of Virginia"
  },
  {
    "id": "476595",
    "name": "Medical Allied Career Center"
  },
  {
    "id": "476601",
    "name": "Bais HaMedrash and Mesivta of Baltimore"
  },
  {
    "id": "476610",
    "name": "Barber & Beauty Institute of New York"
  },
  {
    "id": "476629",
    "name": "Salon Institute-Toledo Campus"
  },
  {
    "id": "476683",
    "name": "Athena Career Academy"
  },
  {
    "id": "476692",
    "name": "Yeshiva Gedolah Zichron Leyma"
  },
  {
    "id": "476708",
    "name": "The Barber School"
  },
  {
    "id": "476717",
    "name": "Be'er Yaakov Talmudic Seminary"
  },
  {
    "id": "476726",
    "name": "Rio Grande Valley College"
  },
  {
    "id": "476735",
    "name": "Alexander Academy"
  },
  {
    "id": "476753",
    "name": "Aveda Institute-Tucson"
  },
  {
    "id": "476780",
    "name": "Diamond Beauty College"
  },
  {
    "id": "476799",
    "name": "Unitek College"
  },
  {
    "id": "476805",
    "name": "University Academy of Hair Design"
  },
  {
    "id": "476814",
    "name": "Florida Institute of Recording Sound and Technology"
  },
  {
    "id": "476841",
    "name": "Paul Mitchell the School-Reno"
  },
  {
    "id": "476850",
    "name": "Boise Barber College"
  },
  {
    "id": "476869",
    "name": "Austin Kade Academy"
  },
  {
    "id": "476878",
    "name": "Aesthetic Science Institute"
  },
  {
    "id": "476887",
    "name": "Elaine Sterling Institute"
  },
  {
    "id": "476896",
    "name": "Wade Gordon Hairdressing Academy"
  },
  {
    "id": "476902",
    "name": "Mitsu Sato Hair Academy"
  },
  {
    "id": "476939",
    "name": "FVI School of Nursing and Technology"
  },
  {
    "id": "476948",
    "name": "SAE Institute of Technology-Atlanta"
  },
  {
    "id": "476957",
    "name": "Academy di Firenze"
  },
  {
    "id": "476966",
    "name": "SAE Institute of Technology-Miami"
  },
  {
    "id": "476975",
    "name": "Colorado State University Global"
  },
  {
    "id": "476984",
    "name": "Paul Mitchell the School-Overland Park"
  },
  {
    "id": "476993",
    "name": "Medspa Academies"
  },
  {
    "id": "477002",
    "name": "Brighton Institute of Cosmetology"
  },
  {
    "id": "477039",
    "name": "West Coast University-Dallas"
  },
  {
    "id": "478582",
    "name": "Empire Beauty School-Savannah"
  },
  {
    "id": "478591",
    "name": "Ogle School Hair Skin Nails-Denton"
  },
  {
    "id": "478616",
    "name": "Empire Beauty School-West Greensboro"
  },
  {
    "id": "478917",
    "name": "Hays Academy of Hair Design"
  },
  {
    "id": "478953",
    "name": "Bellus Academy"
  },
  {
    "id": "479062",
    "name": "MotoRing Technical Training Institute"
  },
  {
    "id": "479424",
    "name": "Unitek College"
  },
  {
    "id": "479965",
    "name": "Medical Career Institute"
  },
  {
    "id": "479974",
    "name": "Advance Beauty Techs Academy"
  },
  {
    "id": "479983",
    "name": "Elevate Salon Institute-Westminster"
  },
  {
    "id": "479992",
    "name": "Brand College"
  },
  {
    "id": "480000",
    "name": "Hinton Barber and Beauty College"
  },
  {
    "id": "480019",
    "name": "Advanced Career Institute"
  },
  {
    "id": "480028",
    "name": "Digital Film Academy"
  },
  {
    "id": "480037",
    "name": "MyComputerCareer at Raleigh"
  },
  {
    "id": "480073",
    "name": "South University-Austin"
  },
  {
    "id": "480091",
    "name": "Bryant & Stratton College-Online"
  },
  {
    "id": "480125",
    "name": "Ogle School Hair Skin Nails-San Antonio"
  },
  {
    "id": "480161",
    "name": "Ideal Beauty Academy"
  },
  {
    "id": "480198",
    "name": "Warner Pacific University Professional and Graduate Studies"
  },
  {
    "id": "480204",
    "name": "Platt College-Riverside"
  },
  {
    "id": "480310",
    "name": "Empire Beauty School-Glen Burnie"
  },
  {
    "id": "480347",
    "name": "International School of Cosmetology"
  },
  {
    "id": "480471",
    "name": "UEI College-Stockton"
  },
  {
    "id": "480523",
    "name": "Ross Medical Education Center-Dayton"
  },
  {
    "id": "480532",
    "name": "Ross Medical Education Center-Ontario"
  },
  {
    "id": "480550",
    "name": "Ross Medical Education Center-Bowling Green"
  },
  {
    "id": "480569",
    "name": "Florida Institute of Technology-Online"
  },
  {
    "id": "480657",
    "name": "Rasmussen University-Kansas"
  },
  {
    "id": "480693",
    "name": "Columbia Institute"
  },
  {
    "id": "480727",
    "name": "School of Missionary Aviation Technology"
  },
  {
    "id": "480781",
    "name": "California Miramar University"
  },
  {
    "id": "480790",
    "name": "Rocky Vista University"
  },
  {
    "id": "480824",
    "name": "The College of Health Care Professions-Dallas"
  },
  {
    "id": "480833",
    "name": "The College of Health Care Professions-Fort Worth"
  },
  {
    "id": "480842",
    "name": "Shear Finesse Beauty Academy"
  },
  {
    "id": "480851",
    "name": "Miami Media School"
  },
  {
    "id": "480879",
    "name": "Academy of Salon Professionals"
  },
  {
    "id": "480888",
    "name": "CyberTex Institute of Technology"
  },
  {
    "id": "480903",
    "name": "New England Tractor Trailer Training School of CT-Bridgeport"
  },
  {
    "id": "480912",
    "name": "Gemini School of Visual Arts & Communication"
  },
  {
    "id": "480921",
    "name": "Designer Barber & Stylist School"
  },
  {
    "id": "480930",
    "name": "Paul Mitchell the School-Woodbridge"
  },
  {
    "id": "480967",
    "name": "College of the Muscogee Nation"
  },
  {
    "id": "480976",
    "name": "The Salon Professional Academy-Huntsville"
  },
  {
    "id": "480985",
    "name": "Midwives College of Utah"
  },
  {
    "id": "480994",
    "name": "Aspen Beauty Academy of Laurel"
  },
  {
    "id": "481003",
    "name": "M T Training Center"
  },
  {
    "id": "481021",
    "name": "Berks Career & Technology Center"
  },
  {
    "id": "481030",
    "name": "Future Generations University"
  },
  {
    "id": "481049",
    "name": "Carthage R9 School District-Carthage Technical Center"
  },
  {
    "id": "481058",
    "name": "Grace Mission University"
  },
  {
    "id": "481085",
    "name": "Verve College"
  },
  {
    "id": "481094",
    "name": "Sotheby's Institute of Art-NY"
  },
  {
    "id": "481100",
    "name": "Top Nails & Hair Beauty School"
  },
  {
    "id": "481128",
    "name": "Santa Ana Beauty College"
  },
  {
    "id": "481137",
    "name": "Shepherds College"
  },
  {
    "id": "481146",
    "name": "Woodruff Medical and Wellness Training"
  },
  {
    "id": "481155",
    "name": "Helms College"
  },
  {
    "id": "481182",
    "name": "Ukiah Adult School"
  },
  {
    "id": "481191",
    "name": "Riverside County Office of Education-School of Career Education"
  },
  {
    "id": "481207",
    "name": "Institute of Health Sciences"
  },
  {
    "id": "481225",
    "name": "Mid-South Christian College"
  },
  {
    "id": "481243",
    "name": "New York Institute of Beauty"
  },
  {
    "id": "481252",
    "name": "Ultrasound Medical Institute"
  },
  {
    "id": "481289",
    "name": "Xavier College School of Nursing"
  },
  {
    "id": "481298",
    "name": "Lawrence & Company College of Cosmetology"
  },
  {
    "id": "481313",
    "name": "Kaizen Beauty Academy"
  },
  {
    "id": "481322",
    "name": "Southern Texas Careers Academy"
  },
  {
    "id": "481331",
    "name": "Sharp Edgez Barber Institute"
  },
  {
    "id": "481340",
    "name": "The Salon Professional Academy-Nashville"
  },
  {
    "id": "481368",
    "name": "Prestige Health & Beauty Sciences Academy"
  },
  {
    "id": "481386",
    "name": "California Career Institute"
  },
  {
    "id": "481401",
    "name": "Grace School of Theology"
  },
  {
    "id": "481410",
    "name": "Yeshiva Gedolah Kesser Torah"
  },
  {
    "id": "481429",
    "name": "Universal Training Institute"
  },
  {
    "id": "481438",
    "name": "Yeshiva Yesodei Hatorah"
  },
  {
    "id": "481447",
    "name": "Center for the Healing Arts"
  },
  {
    "id": "481456",
    "name": "Bonnie Joseph Academy of Cosmetology & Barbering"
  },
  {
    "id": "481465",
    "name": "Healthcare Training Institute"
  },
  {
    "id": "481474",
    "name": "J D Academy of Salon and Spa"
  },
  {
    "id": "481483",
    "name": "Boca Beauty Academy"
  },
  {
    "id": "481508",
    "name": "Paul Mitchell the School-Jersey Shore"
  },
  {
    "id": "481517",
    "name": "City Pointe Beauty Academy"
  },
  {
    "id": "481526",
    "name": "The Chrysm Institute of Esthetics"
  },
  {
    "id": "481535",
    "name": "Sanford Burnham Prebys Medical Discovery Institute"
  },
  {
    "id": "481571",
    "name": "Belle Academy of Cosmetology LLC"
  },
  {
    "id": "481678",
    "name": "Empire Beauty School-Augusta"
  },
  {
    "id": "481720",
    "name": "South University-High Point"
  },
  {
    "id": "481739",
    "name": "Empire Beauty School-Vernon Hills"
  },
  {
    "id": "481748",
    "name": "Empire Beauty School-Stone Park"
  },
  {
    "id": "481775",
    "name": "San Joaquin Valley College-Lancaster"
  },
  {
    "id": "481845",
    "name": "Empire Beauty School-Rochester"
  },
  {
    "id": "481863",
    "name": "Ross Medical Education Center-Kokomo"
  },
  {
    "id": "481890",
    "name": "Ross Medical Education Center-Erlanger"
  },
  {
    "id": "481906",
    "name": "Ross Medical Education Center-Charleston"
  },
  {
    "id": "481960",
    "name": "InterCoast Colleges-Fairfield"
  },
  {
    "id": "482015",
    "name": "Southern Careers Institute-San Antonio"
  },
  {
    "id": "482060",
    "name": "Milan Institute-Merced"
  },
  {
    "id": "482097",
    "name": "Midwest Technical Institute-Missouri"
  },
  {
    "id": "482149",
    "name": "Augusta University"
  },
  {
    "id": "482158",
    "name": "Middle Georgia State University"
  },
  {
    "id": "482167",
    "name": "Trenz Beauty Academy"
  },
  {
    "id": "482176",
    "name": "Paul Mitchell the School-Denver"
  },
  {
    "id": "482185",
    "name": "Academy of Interactive Entertainment"
  },
  {
    "id": "482194",
    "name": "Arrojo Cosmetology School"
  },
  {
    "id": "482200",
    "name": "Cosmetology Academy of Texarkana"
  },
  {
    "id": "482228",
    "name": "Veritas Baptist College"
  },
  {
    "id": "482246",
    "name": "Lynnes Welding Training"
  },
  {
    "id": "482255",
    "name": "Aviation Institute of Maintenance-Las Vegas"
  },
  {
    "id": "482291",
    "name": "UEI College-Bakersfield"
  },
  {
    "id": "482413",
    "name": "DeVry College of New York"
  },
  {
    "id": "482422",
    "name": "DeVry University-Arizona"
  },
  {
    "id": "482431",
    "name": "DeVry University-California"
  },
  {
    "id": "482440",
    "name": "DeVry University-Colorado"
  },
  {
    "id": "482459",
    "name": "DeVry University-Florida"
  },
  {
    "id": "482468",
    "name": "DeVry University-Georgia"
  },
  {
    "id": "482477",
    "name": "DeVry University-Illinois"
  },
  {
    "id": "482538",
    "name": "DeVry University-Missouri"
  },
  {
    "id": "482547",
    "name": "DeVry University-Nevada"
  },
  {
    "id": "482556",
    "name": "DeVry University-New Jersey"
  },
  {
    "id": "482565",
    "name": "DeVry University-North Carolina"
  },
  {
    "id": "482574",
    "name": "DeVry University-Ohio"
  },
  {
    "id": "482608",
    "name": "DeVry University-Pennsylvania"
  },
  {
    "id": "482617",
    "name": "DeVry University-Tennessee"
  },
  {
    "id": "482635",
    "name": "DeVry University-Texas"
  },
  {
    "id": "482653",
    "name": "DeVry University-Virginia"
  },
  {
    "id": "482680",
    "name": "University of North Georgia"
  },
  {
    "id": "482699",
    "name": "South Georgia State College"
  },
  {
    "id": "482705",
    "name": "Northeastern University Professional Programs"
  },
  {
    "id": "482936",
    "name": "Florida Polytechnic University"
  },
  {
    "id": "482963",
    "name": "American Institute-Toms River"
  },
  {
    "id": "482981",
    "name": "Alamo City Barber College"
  },
  {
    "id": "482990",
    "name": "Arizona College-Mesa"
  },
  {
    "id": "483009",
    "name": "American College of Healthcare and Technology"
  },
  {
    "id": "483018",
    "name": "Antioch College"
  },
  {
    "id": "483036",
    "name": "Texas A&M University-Central Texas"
  },
  {
    "id": "483045",
    "name": "Central Georgia Technical College"
  },
  {
    "id": "483054",
    "name": "Barber School of Pittsburgh"
  },
  {
    "id": "483106",
    "name": "Innovate Salon Academy"
  },
  {
    "id": "483124",
    "name": "Arizona State University Digital Immersion"
  },
  {
    "id": "483212",
    "name": "Louisiana Delta Community College"
  },
  {
    "id": "483230",
    "name": "The Fab School"
  },
  {
    "id": "483258",
    "name": "Networks Barber College"
  },
  {
    "id": "483276",
    "name": "JB's Hair Design and Barber College"
  },
  {
    "id": "483328",
    "name": "Paul Mitchell the School-Lombard"
  },
  {
    "id": "483337",
    "name": "Paul Mitchell the School-Greenville"
  },
  {
    "id": "483346",
    "name": "Barber and Beauty Academy of Pennsylvania"
  },
  {
    "id": "483355",
    "name": "Delta College of Arts & Technology-Lafayette Campus"
  },
  {
    "id": "483364",
    "name": "Delta College-Slidell Campus"
  },
  {
    "id": "483373",
    "name": "Integrity College of Health"
  },
  {
    "id": "483382",
    "name": "Electrical and HVAC/R Training Center"
  },
  {
    "id": "483425",
    "name": "Alexander Paul Institute of Hair Design"
  },
  {
    "id": "483443",
    "name": "California Technical Academy"
  },
  {
    "id": "483470",
    "name": "More Tech Institute"
  },
  {
    "id": "483504",
    "name": "Dorsey College-Dearborn"
  },
  {
    "id": "483513",
    "name": "Neecee's Barber College"
  },
  {
    "id": "483559",
    "name": "Bella Cosmetology and Barber College"
  },
  {
    "id": "483577",
    "name": "Franklin Hair Academy School of Cosmetology"
  },
  {
    "id": "483595",
    "name": "Ana G. Mendez University"
  },
  {
    "id": "483647",
    "name": "Ohio Institute of Allied Health"
  },
  {
    "id": "483708",
    "name": "Access Careers"
  },
  {
    "id": "483726",
    "name": "Luckes Beauty Academy LLC"
  },
  {
    "id": "483735",
    "name": "Total Transformation Institute of Cosmetology"
  },
  {
    "id": "483744",
    "name": "Vibe Barber College"
  },
  {
    "id": "483753",
    "name": "Champ's Barber School"
  },
  {
    "id": "483780",
    "name": "IGlobal University"
  },
  {
    "id": "483799",
    "name": "East-West Healing Arts Institute"
  },
  {
    "id": "483814",
    "name": "Standard Healthcare Services-College of Nursing"
  },
  {
    "id": "483823",
    "name": "Philadelphia Technician Training"
  },
  {
    "id": "483841",
    "name": "Grace International Beauty School"
  },
  {
    "id": "483850",
    "name": "Alliance Computing Solutions"
  },
  {
    "id": "483869",
    "name": "InterAmerican Technical Institute"
  },
  {
    "id": "483878",
    "name": "Bay Area Medical Academy"
  },
  {
    "id": "483887",
    "name": "Mind Body Institute"
  },
  {
    "id": "483896",
    "name": "Parisian Spa Institute"
  },
  {
    "id": "483911",
    "name": "Creative Touch Cosmetology School"
  },
  {
    "id": "483920",
    "name": "LaBarberia Institute of Hair"
  },
  {
    "id": "483948",
    "name": "Bos-Man's Barber College"
  },
  {
    "id": "483957",
    "name": "Lawrence & Company College of Cosmetology"
  },
  {
    "id": "483975",
    "name": "Alabama College of Osteopathic Medicine"
  },
  {
    "id": "484002",
    "name": "High Desert Medical College"
  },
  {
    "id": "484011",
    "name": "Paul Mitchell the School-Schenectady"
  },
  {
    "id": "484020",
    "name": "Beyond Measure Barbering Institute"
  },
  {
    "id": "484039",
    "name": "McDougle Technical Institute"
  },
  {
    "id": "484048",
    "name": "Barber Institute of Texas"
  },
  {
    "id": "484057",
    "name": "Jupiter Beauty Academy"
  },
  {
    "id": "484066",
    "name": "MedQuest College"
  },
  {
    "id": "484075",
    "name": "First Coast Barber Academy"
  },
  {
    "id": "484084",
    "name": "Debutantes School of Cosmetology and Nail Technology"
  },
  {
    "id": "484093",
    "name": "Paul Mitchell the School-Farmington Hills"
  },
  {
    "id": "484109",
    "name": "Paul Mitchell the School-Merrillville"
  },
  {
    "id": "484127",
    "name": "Ricci's Toni & Guy Hairdressing Academy/TIGI Creative School"
  },
  {
    "id": "484136",
    "name": "Yahweh Beauty Academy"
  },
  {
    "id": "484163",
    "name": "Arizona School of Integrative Studies"
  },
  {
    "id": "484172",
    "name": "Lee Professional Institute"
  },
  {
    "id": "484190",
    "name": "Park Place Premier Barber School"
  },
  {
    "id": "484206",
    "name": "The Salon Professional Academy-San Jose"
  },
  {
    "id": "484233",
    "name": "Tri-Community Adult Education"
  },
  {
    "id": "484330",
    "name": "Ross Medical Education Center-Owensboro"
  },
  {
    "id": "484349",
    "name": "Ross Medical Education Center-Evansville"
  },
  {
    "id": "484358",
    "name": "Ross Medical Education Center-Johnson City"
  },
  {
    "id": "484367",
    "name": "North-West College-Anaheim"
  },
  {
    "id": "484376",
    "name": "North-West College-Long Beach"
  },
  {
    "id": "484394",
    "name": "Taylor Andrews Academy of Hair Design-Provo"
  },
  {
    "id": "484473",
    "name": "University of Florida-Online"
  },
  {
    "id": "484604",
    "name": "Ogle School Hair Skin Nails-Stafford"
  },
  {
    "id": "484613",
    "name": "University of Phoenix-Arizona"
  },
  {
    "id": "484631",
    "name": "University of Phoenix-California"
  },
  {
    "id": "484710",
    "name": "University of Phoenix-Nevada"
  },
  {
    "id": "484756",
    "name": "University of Phoenix-Texas"
  },
  {
    "id": "484835",
    "name": "CEM College-Mayaguez"
  },
  {
    "id": "484844",
    "name": "Minerva University"
  },
  {
    "id": "484862",
    "name": "University of West Los Angeles"
  },
  {
    "id": "484871",
    "name": "Rabbinical College Ohr Yisroel"
  },
  {
    "id": "484899",
    "name": "Aveda Institute-Phoenix"
  },
  {
    "id": "484905",
    "name": "University of North Texas at Dallas"
  },
  {
    "id": "484923",
    "name": "Dermal Science International Aesthetics and Nail Academy"
  },
  {
    "id": "484932",
    "name": "BridgeValley Community & Technical College"
  },
  {
    "id": "484950",
    "name": "Northwest College-Medford"
  },
  {
    "id": "484996",
    "name": "Houston Training Schools-Gessner"
  },
  {
    "id": "485014",
    "name": "Empire Beauty School-Buffalo"
  },
  {
    "id": "485032",
    "name": "Paul Mitchell the School-Toledo"
  },
  {
    "id": "485111",
    "name": "Georgia Military College"
  },
  {
    "id": "485139",
    "name": "SAE Institute of Technology-Chicago"
  },
  {
    "id": "485166",
    "name": "Beau Monde Academy of Barbering and Cosmetology"
  },
  {
    "id": "485236",
    "name": "Fountain of Youth Academy of Cosmetology"
  },
  {
    "id": "485272",
    "name": "West Coast University-Miami"
  },
  {
    "id": "485306",
    "name": "All Beauty College"
  },
  {
    "id": "485342",
    "name": "Aparicio-Levy Technical College"
  },
  {
    "id": "485360",
    "name": "The Vocational Nursing Institute Inc"
  },
  {
    "id": "485379",
    "name": "Hollywood Institute of Beauty Careers-West Palm Beach"
  },
  {
    "id": "485388",
    "name": "Hollywood Institute of Beauty Careers-Casselberry"
  },
  {
    "id": "485397",
    "name": "Empire Beauty School-Cheltenham"
  },
  {
    "id": "485403",
    "name": "High Tech High Graduate School of Education"
  },
  {
    "id": "485412",
    "name": "Coachella Valley Beauty College-Hemet"
  },
  {
    "id": "485458",
    "name": "Coastal Pines Technical College"
  },
  {
    "id": "485476",
    "name": "Studio Beauty School"
  },
  {
    "id": "485485",
    "name": "Latin Beauty Academy"
  },
  {
    "id": "485494",
    "name": "Associated Barber College of San Diego"
  },
  {
    "id": "485500",
    "name": "ABCO Technology"
  },
  {
    "id": "485519",
    "name": "Lionel University"
  },
  {
    "id": "485546",
    "name": "California Intercontinental University"
  },
  {
    "id": "485564",
    "name": "Infinity College"
  },
  {
    "id": "485573",
    "name": "Universal Healthcare Careers College"
  },
  {
    "id": "485591",
    "name": "New Beginnings Beauty Academy"
  },
  {
    "id": "485607",
    "name": "Dolce The Academy"
  },
  {
    "id": "485698",
    "name": "American College of the Building Arts"
  },
  {
    "id": "485704",
    "name": "River Valley School of Massage"
  },
  {
    "id": "485768",
    "name": "Atlantis University"
  },
  {
    "id": "485801",
    "name": "Westchester School for Medical & Dental Assistants"
  },
  {
    "id": "485856",
    "name": "Studio Incamminati"
  },
  {
    "id": "485874",
    "name": "Salon Boutique Academy"
  },
  {
    "id": "485926",
    "name": "GA Beauty & Barber School"
  },
  {
    "id": "485953",
    "name": "The Recording Conservatory of Austin"
  },
  {
    "id": "485962",
    "name": "VH Barber & Styling Academy"
  },
  {
    "id": "485999",
    "name": "Bet Medrash Gadol Ateret Torah"
  },
  {
    "id": "486017",
    "name": "Yeshiva Ohr Yisrael"
  },
  {
    "id": "486026",
    "name": "Yeshiva Sholom Shachna"
  },
  {
    "id": "486053",
    "name": "Bethlehem College & Seminary"
  },
  {
    "id": "486080",
    "name": "Elmezzi Graduate School of Molecular Medicine"
  },
  {
    "id": "486105",
    "name": "Professional Academy of Cosmetology"
  },
  {
    "id": "486123",
    "name": "Theatre of Arts"
  },
  {
    "id": "486141",
    "name": "Westchester College of Nursing & Allied Health"
  },
  {
    "id": "486150",
    "name": "Treasure Coast Technical College"
  },
  {
    "id": "486169",
    "name": "American College of Barbering"
  },
  {
    "id": "486196",
    "name": "Beth Medrash Meor Yitzchok"
  },
  {
    "id": "486202",
    "name": "Mingo Extended Learning Center"
  },
  {
    "id": "486239",
    "name": "San Ignacio University"
  },
  {
    "id": "486248",
    "name": "ZMS The Academy"
  },
  {
    "id": "486257",
    "name": "Valor Christian College"
  },
  {
    "id": "486284",
    "name": "Bethany Global University"
  },
  {
    "id": "486309",
    "name": "Bella Academy of Cosmetology"
  },
  {
    "id": "486345",
    "name": "HVAC Technical Institute"
  },
  {
    "id": "486354",
    "name": "United International College"
  },
  {
    "id": "486372",
    "name": "San Francisco Film School"
  },
  {
    "id": "486381",
    "name": "Center for Ultrasound Research & Education"
  },
  {
    "id": "486390",
    "name": "Culinary Tech Center"
  },
  {
    "id": "486406",
    "name": "Randolph Technical Center"
  },
  {
    "id": "486415",
    "name": "AMG School of Nursing"
  },
  {
    "id": "486424",
    "name": "Saint Michael College of Allied Health"
  },
  {
    "id": "486433",
    "name": "Presidio Graduate School"
  },
  {
    "id": "486442",
    "name": "Sandra Academy of Salon Services"
  },
  {
    "id": "486460",
    "name": "Wright Graduate University for the Realization of Human Potential"
  },
  {
    "id": "486488",
    "name": "California Jazz Conservatory"
  },
  {
    "id": "486497",
    "name": "California Barber and Beauty College"
  },
  {
    "id": "486503",
    "name": "Laredo CHI Academy Beauty School"
  },
  {
    "id": "486512",
    "name": "Florida International Training Institute"
  },
  {
    "id": "486530",
    "name": "Gould's Academy"
  },
  {
    "id": "486558",
    "name": "Aviation Institute of Maintenance-Fremont"
  },
  {
    "id": "486567",
    "name": "Paul Mitchell the School-Tulsa"
  },
  {
    "id": "486576",
    "name": "PPG Technical College"
  },
  {
    "id": "486594",
    "name": "Wave Leadership College"
  },
  {
    "id": "486619",
    "name": "Academy of Professional Cosmetology"
  },
  {
    "id": "486691",
    "name": "Empire Beauty School-Northlake"
  },
  {
    "id": "486707",
    "name": "Ross Medical Education Center-Huntsville"
  },
  {
    "id": "486716",
    "name": "Ross Medical Education Center-Knoxville"
  },
  {
    "id": "486752",
    "name": "Ross Medical Education Center-Muncie"
  },
  {
    "id": "486770",
    "name": "Cortiva Institute-Arlington"
  },
  {
    "id": "486798",
    "name": "Tulsa Welding School-Houston"
  },
  {
    "id": "486813",
    "name": "Pima Medical Institute-El Paso"
  },
  {
    "id": "486822",
    "name": "Pima Medical Institute-Phoenix"
  },
  {
    "id": "486840",
    "name": "Kennesaw State University"
  },
  {
    "id": "486859",
    "name": "Davines Professional Academy of Beauty and Business"
  },
  {
    "id": "486868",
    "name": "All Beauty College"
  },
  {
    "id": "486877",
    "name": "Eastern Suffolk BOCES"
  },
  {
    "id": "486886",
    "name": "Eastern Suffolk BOCES"
  },
  {
    "id": "486901",
    "name": "Milligan University"
  },
  {
    "id": "486938",
    "name": "Chamberlain University-Nevada"
  },
  {
    "id": "486947",
    "name": "Chamberlain University-Michigan"
  },
  {
    "id": "486956",
    "name": "Chamberlain University-New Jersey"
  },
  {
    "id": "486965",
    "name": "Hussian College-Los Angeles"
  },
  {
    "id": "486983",
    "name": "Paul Mitchell the School-Madison"
  },
  {
    "id": "487010",
    "name": "The University of Tennessee Health Science Center"
  },
  {
    "id": "487047",
    "name": "Unitech Training Academy-New Orleans"
  },
  {
    "id": "487065",
    "name": "Wade Gordon Hairdressing Academy-Lubbock"
  },
  {
    "id": "487083",
    "name": "Protege Academy"
  },
  {
    "id": "487092",
    "name": "Emory University-Oxford College"
  },
  {
    "id": "487153",
    "name": "The Chicago School of Professional Psychology at Xavier University of Louisiana"
  },
  {
    "id": "487162",
    "name": "Southern Regional Technical College"
  },
  {
    "id": "487232",
    "name": "Texas College of Cosmetology-Lubbock"
  },
  {
    "id": "487302",
    "name": "Fortis College-Cuyahoga Falls"
  },
  {
    "id": "487311",
    "name": "Jones Technical Institute"
  },
  {
    "id": "487320",
    "name": "Texas State Technical College"
  },
  {
    "id": "487348",
    "name": "Aveda Institute Portland-Vancouver Campus"
  },
  {
    "id": "487375",
    "name": "Arizona College of Nursing-Las Vegas"
  },
  {
    "id": "487384",
    "name": "Florida Career College-Pembroke Pines"
  },
  {
    "id": "487393",
    "name": "Florida Career College-West Palm Beach"
  },
  {
    "id": "487409",
    "name": "Florida Career College-Hialeah"
  },
  {
    "id": "487418",
    "name": "Florida Career College-Lauderdale Lakes"
  },
  {
    "id": "487427",
    "name": "Florida Career College-Tampa"
  },
  {
    "id": "487436",
    "name": "Florida Career College-Jacksonville"
  },
  {
    "id": "487445",
    "name": "Florida Career College-Boynton Beach"
  },
  {
    "id": "487454",
    "name": "Florida Career College-Margate"
  },
  {
    "id": "487472",
    "name": "Florida Career College-Orlando"
  },
  {
    "id": "487481",
    "name": "UEI College-Phoenix"
  },
  {
    "id": "487490",
    "name": "Florida Career College-Houston"
  },
  {
    "id": "487506",
    "name": "Aveda Institute-Madison"
  },
  {
    "id": "487524",
    "name": "Husson University"
  },
  {
    "id": "487533",
    "name": "Tricoci University of Beauty Culture-Elgin"
  },
  {
    "id": "487588",
    "name": "Concorde Career College-Southaven"
  },
  {
    "id": "487597",
    "name": "Universal Technical Institute-Southern California"
  },
  {
    "id": "487603",
    "name": "Northwest University-Center for Online and Extended Education"
  },
  {
    "id": "487621",
    "name": "Celebrity Barber School"
  },
  {
    "id": "487649",
    "name": "California Institute of Advanced Management"
  },
  {
    "id": "487658",
    "name": "Academy of Interactive Entertainment"
  },
  {
    "id": "487676",
    "name": "The Temple Annapolis-A Paul Mitchell Partner School"
  },
  {
    "id": "487746",
    "name": "Yeshiva Zichron Aryeh"
  },
  {
    "id": "487773",
    "name": "Butte County Regional Occupational Program"
  },
  {
    "id": "487791",
    "name": "Arclabs"
  },
  {
    "id": "487807",
    "name": "Top of the Line Barber College"
  },
  {
    "id": "487816",
    "name": "Midfield Institute of Cosmetology"
  },
  {
    "id": "487852",
    "name": "American Institute of Alternative Medicine"
  },
  {
    "id": "487861",
    "name": "Felbry College"
  },
  {
    "id": "487889",
    "name": "Regional Center for Border Health"
  },
  {
    "id": "487898",
    "name": "B-Unique Beauty and Barber Academy"
  },
  {
    "id": "487904",
    "name": "Kentucky Horseshoeing School"
  },
  {
    "id": "487922",
    "name": "Image Maker Beauty Institute"
  },
  {
    "id": "487959",
    "name": "Dalton Institute of Esthetics and Cosmetology"
  },
  {
    "id": "487968",
    "name": "CAAN Academy of Nursing"
  },
  {
    "id": "487977",
    "name": "Martinsburg College"
  },
  {
    "id": "487995",
    "name": "Alhambra Medical University"
  },
  {
    "id": "488004",
    "name": "Central Yeshiva Beth Joseph"
  },
  {
    "id": "488013",
    "name": "Garden State Science and Technology Institute"
  },
  {
    "id": "488022",
    "name": "Barber Tech Academy"
  },
  {
    "id": "488031",
    "name": "Abraham Lincoln University"
  },
  {
    "id": "488059",
    "name": "Med Academy"
  },
  {
    "id": "488068",
    "name": "Huntington University of Health Sciences"
  },
  {
    "id": "488077",
    "name": "Sonoran Desert Institute"
  },
  {
    "id": "488101",
    "name": "Yeshivas Maharit D'Satmar"
  },
  {
    "id": "488110",
    "name": "Natural Images Beauty College"
  },
  {
    "id": "488129",
    "name": "Textures Institute of Cosmetology"
  },
  {
    "id": "488138",
    "name": "Learning Bridge Career Institute"
  },
  {
    "id": "488147",
    "name": "PiBerry Institute"
  },
  {
    "id": "488174",
    "name": "Ea La Mar's Cosmetology & Barber College"
  },
  {
    "id": "488183",
    "name": "Regina Webb Academy"
  },
  {
    "id": "488192",
    "name": "DeHart Technical School"
  },
  {
    "id": "488217",
    "name": "Medical Career & Technical College"
  },
  {
    "id": "488226",
    "name": "Center for Neurosomatic Studies"
  },
  {
    "id": "488253",
    "name": "Focus Personal Training Institute"
  },
  {
    "id": "488262",
    "name": "Hair Academy School of Barbering & Beauty"
  },
  {
    "id": "488271",
    "name": "Fosbre Academy of Hair Design"
  },
  {
    "id": "488280",
    "name": "National Personal Training Institute"
  },
  {
    "id": "488299",
    "name": "Mission Beauty Institute"
  },
  {
    "id": "488305",
    "name": "Elim Bible Institute and College"
  },
  {
    "id": "488314",
    "name": "Beth Medrash of Asbury Park"
  },
  {
    "id": "488332",
    "name": "Hope College of Arts and Sciences"
  },
  {
    "id": "488350",
    "name": "Yeshiva Gedolah Shaarei Shmuel"
  },
  {
    "id": "488369",
    "name": "Premier Barber Institute"
  },
  {
    "id": "488378",
    "name": "International Beauty Education Center"
  },
  {
    "id": "488387",
    "name": "Claremont Lincoln University"
  },
  {
    "id": "488396",
    "name": "Phipps Academy of Barbering"
  },
  {
    "id": "488411",
    "name": "Hoss Lee Academy"
  },
  {
    "id": "488420",
    "name": "Indiana Wellness College"
  },
  {
    "id": "488439",
    "name": "Kenny's Academy of Barbering"
  },
  {
    "id": "488448",
    "name": "Seattle Film Institute"
  },
  {
    "id": "488527",
    "name": "Arkansas Colleges of Health Education"
  },
  {
    "id": "488554",
    "name": "Burrell College of Osteopathic Medicine"
  },
  {
    "id": "488563",
    "name": "California College of Music"
  },
  {
    "id": "488572",
    "name": "California Health Sciences University"
  },
  {
    "id": "488590",
    "name": "Christian Culinary Academy"
  },
  {
    "id": "488651",
    "name": "Glitz School of Cosmetology"
  },
  {
    "id": "488679",
    "name": "Indiana Wesleyan University-National & Global"
  },
  {
    "id": "488730",
    "name": "Northeast Lakeview College"
  },
  {
    "id": "488749",
    "name": "Paul Mitchell the School-Jessup"
  },
  {
    "id": "488776",
    "name": "John Patrick University of Health and Applied Sciences"
  },
  {
    "id": "488785",
    "name": "University of Saint Katherine"
  },
  {
    "id": "488800",
    "name": "Teachers College of San Joaquin"
  },
  {
    "id": "488828",
    "name": "Totally Cosmo School of Modern Cosmetology"
  },
  {
    "id": "488837",
    "name": "U.S. Truck Driver Training School"
  },
  {
    "id": "488846",
    "name": "University of the People"
  },
  {
    "id": "488891",
    "name": "South Dade Technical College-South Dade Skills Center Campus"
  },
  {
    "id": "488907",
    "name": "Wongu University of Oriental Medicine"
  },
  {
    "id": "488916",
    "name": "Alabama School of Nail Technology & Cosmetology"
  },
  {
    "id": "488934",
    "name": "Tulsa Technology Center"
  },
  {
    "id": "488952",
    "name": "Ross Medical Education Center-Elyria"
  },
  {
    "id": "488961",
    "name": "Ross Medical Education Center-Lafayette"
  },
  {
    "id": "488970",
    "name": "Ross Medical Education Center-Midland"
  },
  {
    "id": "488989",
    "name": "Paul Mitchell the School-Clear Lake"
  },
  {
    "id": "488998",
    "name": "Paul Mitchell the School-Nampa"
  },
  {
    "id": "489122",
    "name": "Academy of Beauty Professionals"
  },
  {
    "id": "489131",
    "name": "Academy of Beauty Professionals"
  },
  {
    "id": "489140",
    "name": "Academy of Beauty Professionals"
  },
  {
    "id": "489159",
    "name": "Kenneth Shuler School of Cosmetology-Goose Creek"
  },
  {
    "id": "489201",
    "name": "Clovis Community College"
  },
  {
    "id": "489238",
    "name": "Boca Beauty Academy-Parkland"
  },
  {
    "id": "489247",
    "name": "American Beauty Academy-West Valley Campus"
  },
  {
    "id": "489256",
    "name": "Caribbean Aviation Training Institute Inc"
  },
  {
    "id": "489283",
    "name": "The College of Health Care Professions-McAllen Campus"
  },
  {
    "id": "489335",
    "name": "Institute of Buddhist Studies"
  },
  {
    "id": "489344",
    "name": "Chamberlain University-North Carolina"
  },
  {
    "id": "489353",
    "name": "Chamberlain University-California"
  },
  {
    "id": "489371",
    "name": "Spartan College of Aeronautics and Technology"
  },
  {
    "id": "489779",
    "name": "Purdue University Global"
  },
  {
    "id": "489812",
    "name": "Pima Medical Institute-Dillon"
  },
  {
    "id": "489830",
    "name": "Arthur's Beauty College"
  },
  {
    "id": "489858",
    "name": "West Coast University-Center for Graduate Studies"
  },
  {
    "id": "489937",
    "name": "Carolina University"
  },
  {
    "id": "490018",
    "name": "National Personal Training Institute-Tampa"
  },
  {
    "id": "490045",
    "name": "Presbyterian Theological Seminary in America"
  },
  {
    "id": "490054",
    "name": "HCI College"
  },
  {
    "id": "490063",
    "name": "Southern States University"
  },
  {
    "id": "490081",
    "name": "America Evangelical University"
  },
  {
    "id": "490090",
    "name": "Gadsden Technical College"
  },
  {
    "id": "490106",
    "name": "Virginia University of Integrative Medicine"
  },
  {
    "id": "490115",
    "name": "Nashville Film Institute"
  },
  {
    "id": "490124",
    "name": "Los Angeles Academy of Figurative Art"
  },
  {
    "id": "490133",
    "name": "Westcliff University"
  },
  {
    "id": "490142",
    "name": "World Class Academy of Beauty Careers"
  },
  {
    "id": "490151",
    "name": "Allied Health Careers Institute"
  },
  {
    "id": "490160",
    "name": "Sacramento Ultrasound Institute"
  },
  {
    "id": "490197",
    "name": "Regan Career Institute"
  },
  {
    "id": "490203",
    "name": "Bolivar Technical College"
  },
  {
    "id": "490212",
    "name": "Baldy View Regional Occupational Program"
  },
  {
    "id": "490221",
    "name": "MAK Beauty Institute"
  },
  {
    "id": "490230",
    "name": "Reformed University"
  },
  {
    "id": "490249",
    "name": "Mobile Technical Training"
  },
  {
    "id": "490258",
    "name": "American Barber and Beauty Academy"
  },
  {
    "id": "490267",
    "name": "Master's Barber & Styling College"
  },
  {
    "id": "490276",
    "name": "Yeshiva Shaar Ephraim"
  },
  {
    "id": "490285",
    "name": "California Institute of Arts & Technology"
  },
  {
    "id": "490294",
    "name": "Elite Cosmetology Barber & Spa Academy"
  },
  {
    "id": "490300",
    "name": "Cutting Edge Academy"
  },
  {
    "id": "490319",
    "name": "Yeshiva Bais Aharon"
  },
  {
    "id": "490328",
    "name": "Mechon L'hoyroa"
  },
  {
    "id": "490337",
    "name": "My Beauty & Barber College"
  },
  {
    "id": "490346",
    "name": "Elyon College"
  },
  {
    "id": "490373",
    "name": "Western Michigan University Homer Stryker M.D. School of Medicine"
  },
  {
    "id": "490382",
    "name": "Harmony Health Care Institute"
  },
  {
    "id": "490391",
    "name": "American Beauty Schools"
  },
  {
    "id": "490407",
    "name": "Hogan Institute of Cosmetology and Esthetics"
  },
  {
    "id": "490416",
    "name": "J's Barber College"
  },
  {
    "id": "490425",
    "name": "Ann Webb Skin Institute"
  },
  {
    "id": "490434",
    "name": "Apex Academy Hair Skin Nails School of Cosmetology"
  },
  {
    "id": "490443",
    "name": "The Esthetic Institute"
  },
  {
    "id": "490452",
    "name": "Kor Beauty Academy"
  },
  {
    "id": "490461",
    "name": "EduMed Partners"
  },
  {
    "id": "490470",
    "name": "Massage Institute of Memphis"
  },
  {
    "id": "490489",
    "name": "Metro Detroit Barber College"
  },
  {
    "id": "490498",
    "name": "Digital Media Institute"
  },
  {
    "id": "490504",
    "name": "Yeshiva Ohr Naftoli"
  },
  {
    "id": "490513",
    "name": "Bais Medrash Mayan Hatorah"
  },
  {
    "id": "490522",
    "name": "Allgood Beauty Institute"
  },
  {
    "id": "490531",
    "name": "Ray J's College of Hair"
  },
  {
    "id": "490540",
    "name": "Delta Designs Cosmetology School"
  },
  {
    "id": "490559",
    "name": "Gwinnett Institute"
  },
  {
    "id": "490601",
    "name": "Kansas City University"
  },
  {
    "id": "490674",
    "name": "Manuel and Theresa's School of Hair Design"
  },
  {
    "id": "490692",
    "name": "Cincinnati School of Barbering & Hair Design"
  },
  {
    "id": "490717",
    "name": "L Makeup Institute"
  },
  {
    "id": "490735",
    "name": "Peloton College"
  },
  {
    "id": "490744",
    "name": "Northern Technical College"
  },
  {
    "id": "490753",
    "name": "Maine Media College"
  },
  {
    "id": "490805",
    "name": "Purdue University Northwest"
  },
  {
    "id": "490814",
    "name": "Ross Medical Education Center - Kalamazoo"
  },
  {
    "id": "490832",
    "name": "Ross College-Canton"
  },
  {
    "id": "490841",
    "name": "Ross College-Grand Rapids North"
  },
  {
    "id": "490869",
    "name": "American Institute-Somerset"
  },
  {
    "id": "490878",
    "name": "Platt College-Anaheim"
  },
  {
    "id": "490896",
    "name": "Advantage Career Institute"
  },
  {
    "id": "490948",
    "name": "Bull City Durham Beauty and Barber College"
  },
  {
    "id": "490975",
    "name": "Fortis College-Cutler Bay"
  },
  {
    "id": "491057",
    "name": "Yeshiva Kollel Tifereth Elizer"
  },
  {
    "id": "491066",
    "name": "Strand Institute of Beauty & Esthetics"
  },
  {
    "id": "491075",
    "name": "Arkansas Welding Academy"
  },
  {
    "id": "491084",
    "name": "Unitech Training Academy-Baton Rouge"
  },
  {
    "id": "491136",
    "name": "Empire Beauty School-Tampa"
  },
  {
    "id": "491145",
    "name": "Empire Beauty School-Lakeland"
  },
  {
    "id": "491181",
    "name": "Galen College of Nursing-ARH"
  },
  {
    "id": "491190",
    "name": "The Chicago School of Professional Psychology at San Diego"
  },
  {
    "id": "491215",
    "name": "Commercial Divers International"
  },
  {
    "id": "491224",
    "name": "Tricoci University of Beauty Culture-Janesville"
  },
  {
    "id": "491233",
    "name": "Unlimited Cosmetology School"
  },
  {
    "id": "491242",
    "name": "Johnny Matthew's Hairdressing Training School"
  },
  {
    "id": "491251",
    "name": "The Culinary School of Fort Worth"
  },
  {
    "id": "491260",
    "name": "Centralia Beauty College"
  },
  {
    "id": "491288",
    "name": "University of Wisconsin-Milwaukee Flex"
  },
  {
    "id": "491297",
    "name": "University of Wisconsin-Parkside Flex"
  },
  {
    "id": "491394",
    "name": "Miller-Motte College-Edge Tech Academy"
  },
  {
    "id": "491446",
    "name": "Bnos Zion Of Bobov Seminary"
  },
  {
    "id": "491464",
    "name": "ABC Adult School"
  },
  {
    "id": "491473",
    "name": "Polytech Adult Education"
  },
  {
    "id": "491507",
    "name": "California Institute of Medical Science"
  },
  {
    "id": "491525",
    "name": "Union Bible College"
  },
  {
    "id": "491534",
    "name": "Pets Playground Grooming School"
  },
  {
    "id": "491543",
    "name": "San Bernardino Beauty College"
  },
  {
    "id": "491552",
    "name": "Christina and Company Education Center"
  },
  {
    "id": "491570",
    "name": "Institute of Medical Ultrasound"
  },
  {
    "id": "491589",
    "name": "Mystros Barber Academy"
  },
  {
    "id": "491598",
    "name": "European Medical School of Massage"
  },
  {
    "id": "491613",
    "name": "Yeshiva Gedolah Tiferes Boruch"
  },
  {
    "id": "491622",
    "name": "Yeshiva Chemdas Hatorah"
  },
  {
    "id": "491631",
    "name": "Women's Institute of Torah Seminary and College"
  },
  {
    "id": "491640",
    "name": "Yeshiva Gedolah Keren Hatorah"
  },
  {
    "id": "491659",
    "name": "ICOHS College"
  },
  {
    "id": "491668",
    "name": "Neo-Esthetique European Institute"
  },
  {
    "id": "491677",
    "name": "Lehigh Valley Barber School"
  },
  {
    "id": "491686",
    "name": "International Barber College"
  },
  {
    "id": "491695",
    "name": "Brown Beauty Barber School"
  },
  {
    "id": "491701",
    "name": "Rexburg College of Massage Therapy"
  },
  {
    "id": "491710",
    "name": "Yeshiva Gedolah of Cliffwood"
  },
  {
    "id": "491729",
    "name": "Goshen School of Cosmetology"
  },
  {
    "id": "491738",
    "name": "IBS School of Cosmetology and Massage"
  },
  {
    "id": "491747",
    "name": "UR Beauty & Barber Academy"
  },
  {
    "id": "491756",
    "name": "Urban Barber College"
  },
  {
    "id": "491765",
    "name": "Yeshivas Emek Hatorah"
  },
  {
    "id": "491774",
    "name": "Transitions Career Institute School of Nursing"
  },
  {
    "id": "491783",
    "name": "Wilton Simpson Technical College"
  },
  {
    "id": "491792",
    "name": "Stacey James Institute"
  },
  {
    "id": "491808",
    "name": "Meridian University"
  },
  {
    "id": "491817",
    "name": "Seminary Bnos Chaim"
  },
  {
    "id": "491826",
    "name": "Avenue Academy A Cosmetology Institute"
  },
  {
    "id": "491835",
    "name": "Salon Professional Academy of San Antonio"
  },
  {
    "id": "491844",
    "name": "Red Lake Nation College"
  },
  {
    "id": "491853",
    "name": "Princess Institute of Beauty"
  },
  {
    "id": "491871",
    "name": "Vogue International Academy"
  },
  {
    "id": "491880",
    "name": "Independent Training & Apprenticeship Program"
  },
  {
    "id": "491899",
    "name": "Victory Career College"
  },
  {
    "id": "491905",
    "name": "Winonah's International School of Cosmetology"
  },
  {
    "id": "491914",
    "name": "Yeshiva Gedola Tiferes Yerachmiel"
  },
  {
    "id": "491923",
    "name": "Southeastern Esthetics Institute"
  },
  {
    "id": "491932",
    "name": "Installer Institute"
  },
  {
    "id": "491941",
    "name": "Caris College"
  },
  {
    "id": "491950",
    "name": "Contra Costa Medical Career College"
  },
  {
    "id": "491969",
    "name": "Anousheh School of Hair"
  },
  {
    "id": "491978",
    "name": "Another Level Barbering and Cosmetology School"
  },
  {
    "id": "491996",
    "name": "The Salon Professional Academy-Georgetown"
  },
  {
    "id": "492005",
    "name": "Elite Academy of Hair Design"
  },
  {
    "id": "492032",
    "name": "Amslee Institute"
  },
  {
    "id": "492069",
    "name": "Champion Christian College"
  },
  {
    "id": "492078",
    "name": "Chicago Professional Center"
  },
  {
    "id": "492096",
    "name": "D&S School of Cosmetology"
  },
  {
    "id": "492139",
    "name": "DSDT"
  },
  {
    "id": "492157",
    "name": "Houston School of Carpentry"
  },
  {
    "id": "492209",
    "name": "Reiss-Davis Graduate School"
  },
  {
    "id": "492281",
    "name": "GoodFellas Barber College"
  },
  {
    "id": "492306",
    "name": "Escondido Adult School"
  },
  {
    "id": "492315",
    "name": "Poway Adult School"
  },
  {
    "id": "492324",
    "name": "Vista Adult School"
  },
  {
    "id": "492360",
    "name": "The Salon Professional Academy-Delray Beach"
  },
  {
    "id": "492379",
    "name": "Lynnes Welding Training-Bismarck"
  },
  {
    "id": "492421",
    "name": "No Grease Barber School"
  },
  {
    "id": "492449",
    "name": "Pima Medical Institute-San Marcos"
  },
  {
    "id": "492476",
    "name": "EDP University of Puerto Rico-Villalba"
  },
  {
    "id": "492607",
    "name": "Chicago School of Professional Psychology at Dallas"
  },
  {
    "id": "492643",
    "name": "American Massage & Bodywork Institute"
  },
  {
    "id": "492652",
    "name": "KCK Beauty & Barber Academy"
  },
  {
    "id": "492661",
    "name": "California College of Barbering and Cosmetology"
  },
  {
    "id": "492689",
    "name": "Texas Tech University Health Sciences Center-El Paso"
  },
  {
    "id": "492704",
    "name": "Ponce Health Sciences University-Centro Universitario de San Juan"
  },
  {
    "id": "492722",
    "name": "The College of Health Care Professions-South San Antonio"
  },
  {
    "id": "492731",
    "name": "Innovate Salon Academy"
  },
  {
    "id": "492759",
    "name": "Chamberlain University-Louisiana"
  },
  {
    "id": "492801",
    "name": "Drury University-College of Continuing Professional Studies"
  },
  {
    "id": "492935",
    "name": "Global Medical & Technical Training Institute"
  },
  {
    "id": "492953",
    "name": "Salon Success Academy-West Covina"
  },
  {
    "id": "492962",
    "name": "Indiana Institute of Technology-College of Professional Studies"
  },
  {
    "id": "493071",
    "name": "Advantage Technical College"
  },
  {
    "id": "493080",
    "name": "Institute of Culinary Education"
  },
  {
    "id": "493132",
    "name": "Universal Technical Institute-Bloomfield"
  },
  {
    "id": "493150",
    "name": "Gould's Academy"
  },
  {
    "id": "493372",
    "name": "Urban Academy of Beauty"
  },
  {
    "id": "493451",
    "name": "WellSpring School of Allied Health-Springfield"
  },
  {
    "id": "493479",
    "name": "Medical Career College of Northern California"
  },
  {
    "id": "493488",
    "name": "Premiere International College"
  },
  {
    "id": "493503",
    "name": "American Fitness and Nutrition Academy"
  },
  {
    "id": "493512",
    "name": "San Diego Global Knowledge University"
  },
  {
    "id": "493521",
    "name": "Global Tech College"
  },
  {
    "id": "493530",
    "name": "Crown Cutz Academy Bristol"
  },
  {
    "id": "493549",
    "name": "MCI"
  },
  {
    "id": "493558",
    "name": "Central Pennsylvania Diesel Institute"
  },
  {
    "id": "493567",
    "name": "Bellasa Professional Institute"
  },
  {
    "id": "493576",
    "name": "PMCA Pittsburgh Multicultural Cosmetology Academy"
  },
  {
    "id": "493585",
    "name": "Health and Technology Training Institute"
  },
  {
    "id": "493594",
    "name": "Yeshiva of Ocean"
  },
  {
    "id": "493600",
    "name": "Congregation Talmidei Mesivta Tiferes Shmiel Aleksander"
  },
  {
    "id": "493619",
    "name": "Young Americans College of the Performing Arts"
  },
  {
    "id": "493628",
    "name": "Pro Beauty Academy"
  },
  {
    "id": "493637",
    "name": "South Eastern Beauty Academy"
  },
  {
    "id": "493646",
    "name": "Ohel Margulia Seminary"
  },
  {
    "id": "493655",
    "name": "Montana Barber Institute"
  },
  {
    "id": "493664",
    "name": "Yeshivat Hechal Shemuel"
  },
  {
    "id": "493673",
    "name": "Professional University Dr. Carlos J. Borrero Rios"
  },
  {
    "id": "493682",
    "name": "American Institute of Healthcare & Technology"
  },
  {
    "id": "493691",
    "name": "Premier Academy of Cosmetology"
  },
  {
    "id": "493707",
    "name": "Yeshiva Gedolah of Woodlake Village"
  },
  {
    "id": "493716",
    "name": "Yeshiva Gedola Tiferes Yaakov Yitzchok"
  },
  {
    "id": "493725",
    "name": "University of Arkansas System eVersity"
  },
  {
    "id": "493734",
    "name": "Careers Institute of America"
  },
  {
    "id": "493743",
    "name": "Houston Barber School"
  },
  {
    "id": "493752",
    "name": "Health-Tech Institute of Memphis"
  },
  {
    "id": "493761",
    "name": "MIXED Institute of Cosmetology & Barber"
  },
  {
    "id": "493770",
    "name": "Vski Cosmetology School"
  },
  {
    "id": "493789",
    "name": "Shear Perfection Academy of Cosmetology"
  },
  {
    "id": "493798",
    "name": "New York Seminary"
  },
  {
    "id": "493804",
    "name": "Deluxe Barber College"
  },
  {
    "id": "493813",
    "name": "Falcon Institute of Health and Science"
  },
  {
    "id": "493822",
    "name": "College Unbound"
  },
  {
    "id": "493831",
    "name": "Midwest Barber College"
  },
  {
    "id": "493868",
    "name": "A Better U Beauty Barber Academy"
  },
  {
    "id": "493895",
    "name": "SANS Technology Institute"
  },
  {
    "id": "493910",
    "name": "Tuana European Beauty Academy"
  },
  {
    "id": "493947",
    "name": "Idaho College of Osteopathic Medicine"
  },
  {
    "id": "493992",
    "name": "Southern Careers Institute-Waco"
  },
  {
    "id": "494001",
    "name": "Elevate Salon Institute"
  },
  {
    "id": "494010",
    "name": "Ponce Health Sciences University-St Louis"
  },
  {
    "id": "494029",
    "name": "Lil Lou's Beauty and Barber College-Hammond"
  },
  {
    "id": "494162",
    "name": "Arizona College of Nursing-Dallas"
  },
  {
    "id": "494232",
    "name": "The Salon Professional Academy-Washington DC"
  },
  {
    "id": "494250",
    "name": "Valley College-Cleveland"
  },
  {
    "id": "494269",
    "name": "National American University-Kings Bay"
  },
  {
    "id": "494278",
    "name": "EDP University of Puerto Rico-Humacao"
  },
  {
    "id": "494287",
    "name": "EDP University of Puerto Rico-Manati"
  },
  {
    "id": "494357",
    "name": "UEI College-Sacramento"
  },
  {
    "id": "494436",
    "name": "Fortis Institute-Cookeville"
  },
  {
    "id": "494463",
    "name": "Derech Hachaim Seminary"
  },
  {
    "id": "494472",
    "name": "Evvaylois Academy School of Beauty"
  },
  {
    "id": "494524",
    "name": "Avenue Five Institute-South Austin Campus"
  },
  {
    "id": "494551",
    "name": "Glasgow Caledonian New York College"
  },
  {
    "id": "494588",
    "name": "Pima Medical Institute-San Antonio"
  },
  {
    "id": "494597",
    "name": "Faith Theological Seminary and Christian College"
  },
  {
    "id": "494603",
    "name": "The Southwestern Baptist Theological Seminary"
  },
  {
    "id": "494621",
    "name": "Lotus Professional College"
  },
  {
    "id": "494630",
    "name": "Christ Mission College"
  },
  {
    "id": "494649",
    "name": "Esteem Academy of Beauty"
  },
  {
    "id": "494658",
    "name": "KC Beauty Academy"
  },
  {
    "id": "494685",
    "name": "Urshan College"
  },
  {
    "id": "494694",
    "name": "Texas Healthtech Institute"
  },
  {
    "id": "494700",
    "name": "Xtylo Beauty College"
  },
  {
    "id": "494719",
    "name": "Tonsorial Arts Barber College"
  },
  {
    "id": "494728",
    "name": "Michael's Barber & Hair Stylist Academy"
  },
  {
    "id": "494737",
    "name": "Yeshiva Yesoda Hatorah Vetz Chaim"
  },
  {
    "id": "494746",
    "name": "North-West College-San Diego"
  },
  {
    "id": "494755",
    "name": "West Virginia Junior College-United Career Institute"
  },
  {
    "id": "494764",
    "name": "Aviation Institute of Maintenance-Charlotte"
  },
  {
    "id": "494807",
    "name": "Ponce Health Sciences University-East"
  },
  {
    "id": "494843",
    "name": "Fortis College-Landover"
  },
  {
    "id": "494852",
    "name": "Stautzenberger College-Rockford Career College"
  },
  {
    "id": "494898",
    "name": "WellSpring School of Allied Health-Wichita"
  },
  {
    "id": "494904",
    "name": "Access Careers-Islandia"
  },
  {
    "id": "494940",
    "name": "California Arts University"
  },
  {
    "id": "494959",
    "name": "Northern Pennsylvania Regional College"
  },
  {
    "id": "494977",
    "name": "American Medical Institute Inc."
  },
  {
    "id": "494986",
    "name": "Apex College of Veterinary Technology"
  },
  {
    "id": "494995",
    "name": "Meryma'at Barber College"
  },
  {
    "id": "495013",
    "name": "Western Maricopa Education Center"
  },
  {
    "id": "495031",
    "name": "Bais Medrash of Dexter Park"
  },
  {
    "id": "495040",
    "name": "Makana Esthetics Wellness Academy"
  },
  {
    "id": "495059",
    "name": "Oak Valley College"
  },
  {
    "id": "495077",
    "name": "Paul Mitchell the School-San Jose"
  },
  {
    "id": "495101",
    "name": "Zion Massage College"
  },
  {
    "id": "495110",
    "name": "Great Lakes Boat Building School"
  },
  {
    "id": "495129",
    "name": "Austin Career Institute"
  },
  {
    "id": "495138",
    "name": "Professional Culinary Academy"
  },
  {
    "id": "495147",
    "name": "Pacific Northwest Christian College"
  },
  {
    "id": "495165",
    "name": "Royal Learning Institute"
  },
  {
    "id": "495174",
    "name": "Hollywood Cultural College"
  },
  {
    "id": "495183",
    "name": "Mariano Moreno Culinary Institute"
  },
  {
    "id": "495192",
    "name": "Alder Graduate School of Education"
  },
  {
    "id": "495208",
    "name": "Webb's Barber School of Arts"
  },
  {
    "id": "495217",
    "name": "Adrian H. Wallace Barber Academy"
  },
  {
    "id": "495226",
    "name": "St. Louis Med Tech"
  },
  {
    "id": "495235",
    "name": "Total Beauty Institute"
  },
  {
    "id": "495244",
    "name": "Pearlands Innovative School of Beauty"
  },
  {
    "id": "495253",
    "name": "Durant Institute of Hair Design"
  },
  {
    "id": "495262",
    "name": "Premiere Aesthetics Institute"
  },
  {
    "id": "495271",
    "name": "California Indian Nations College"
  },
  {
    "id": "495280",
    "name": "Indian Bible College"
  },
  {
    "id": "495299",
    "name": "Florida Professional Institute"
  },
  {
    "id": "495305",
    "name": "The Professional Cosmetology Academy"
  },
  {
    "id": "495314",
    "name": "Hackensack Meridian School of Medicine"
  },
  {
    "id": "495341",
    "name": "Taylor Andrews Academy of Hair Design-Hair Lab Detroit Barber School"
  },
  {
    "id": "495350",
    "name": "Paul Mitchell the School-North Tahoe"
  },
  {
    "id": "495369",
    "name": "Paul Mitchell the School-Fort Lauderdale"
  },
  {
    "id": "495378",
    "name": "PCI Academy-Iowa City"
  },
  {
    "id": "495387",
    "name": "Larry's Barber College"
  },
  {
    "id": "495396",
    "name": "Royal Learning Institute"
  },
  {
    "id": "495411",
    "name": "Paul Mitchell the School-Provo"
  },
  {
    "id": "495420",
    "name": "Arizona College of Nursing-Tampa"
  },
  {
    "id": "495439",
    "name": "Arizona College of Nursing-Fort Lauderdale"
  },
  {
    "id": "495448",
    "name": "Arizona College of Nursing-Tucson"
  },
  {
    "id": "495457",
    "name": "Arizona College of Nursing-Phoenix"
  },
  {
    "id": "495518",
    "name": "Empire Beauty School-Newport News"
  },
  {
    "id": "495730",
    "name": "Tricoci University of Beauty Culture-Normal"
  },
  {
    "id": "495776",
    "name": "Elite Welding Academy"
  },
  {
    "id": "495794",
    "name": "Paul Mitchell the School-Murfreesboro-Nashville"
  },
  {
    "id": "495837",
    "name": "Dorsey College-Woodhaven"
  },
  {
    "id": "495916",
    "name": "Pathways College"
  },
  {
    "id": "495925",
    "name": "San Joaquin Valley College-Madera"
  },
  {
    "id": "495934",
    "name": "San Joaquin Valley College-Delano"
  },
  {
    "id": "495943",
    "name": "San Joaquin Valley College-Atascadero"
  },
  {
    "id": "495952",
    "name": "San Joaquin Valley College-Rancho Mirage"
  },
  {
    "id": "495961",
    "name": "San Joaquin Valley College-Santa Maria"
  },
  {
    "id": "495970",
    "name": "Advantage Technical College-Aguadilla"
  },
  {
    "id": "495998",
    "name": "San Joaquin Valley College-Porterville"
  },
  {
    "id": "496043",
    "name": "California University of Science and Medicine"
  },
  {
    "id": "496052",
    "name": "Ruben's Five Star Academy"
  },
  {
    "id": "496061",
    "name": "Mason Anthony School of Cosmetology Arts & Sciences"
  },
  {
    "id": "496186",
    "name": "California Institute of Arts & Technology-National City"
  },
  {
    "id": "496265",
    "name": "Triangle Tech-Chambersburg"
  },
  {
    "id": "496283",
    "name": "Provo College-Idaho Falls Campus"
  },
  {
    "id": "496292",
    "name": "Miller-Motte College-Chattanooga 2"
  },
  {
    "id": "496326",
    "name": "Eagle Gate College-Boise Campus"
  },
  {
    "id": "496371",
    "name": "Elite Welding Academy South Point"
  },
  {
    "id": "496399",
    "name": "Agape College of Business and Science"
  },
  {
    "id": "496469",
    "name": "Daybreak University"
  },
  {
    "id": "496478",
    "name": "Motion Picture Institute"
  },
  {
    "id": "496511",
    "name": "United College of Health and Beauty"
  },
  {
    "id": "496520",
    "name": "Zen Shiatsu Chicago"
  },
  {
    "id": "496539",
    "name": "The Salon Professional Academy of Holland"
  },
  {
    "id": "496593",
    "name": "Turing School of Software & Design"
  },
  {
    "id": "496618",
    "name": "National Institute for Medical Assistant Advancement (NIMAA)"
  },
  {
    "id": "496627",
    "name": "California Northstate University"
  },
  {
    "id": "496636",
    "name": "Antigua College International"
  },
  {
    "id": "496645",
    "name": "Burlington County Institute of Technology-Adult Education"
  },
  {
    "id": "496654",
    "name": "Sri Sai Krish Institute"
  },
  {
    "id": "496663",
    "name": "Riggins Urban Barber College"
  },
  {
    "id": "496672",
    "name": "Hair Arts Institute"
  },
  {
    "id": "496681",
    "name": "Florida Academy of Nursing"
  },
  {
    "id": "496690",
    "name": "Body Wisdom Massage Therapy School"
  },
  {
    "id": "496706",
    "name": "Lakewood University"
  },
  {
    "id": "496715",
    "name": "CTK Healthcare & Career Institute"
  },
  {
    "id": "496724",
    "name": "Aesthetics Institute"
  },
  {
    "id": "496733",
    "name": "Sierra Academy of Style"
  },
  {
    "id": "496742",
    "name": "Jolie Hair Academy"
  },
  {
    "id": "496751",
    "name": "Institute of Allied Healthcare"
  },
  {
    "id": "496894",
    "name": "Branford Academy of Hair & Cosmetology-Bridgeport"
  },
  {
    "id": "496919",
    "name": "United Education Institute-Las Vegas"
  },
  {
    "id": "496955",
    "name": "UEI College-Mesa"
  },
  {
    "id": "496964",
    "name": "Larry's Barber College-Joliet"
  },
  {
    "id": "496973",
    "name": "Herzing University-Tampa"
  },
  {
    "id": "496991",
    "name": "Galen Health Institutes-Miami Campus"
  },
  {
    "id": "497000",
    "name": "Remington College-Knoxville"
  },
  {
    "id": "497037",
    "name": "Abilene Christian University-Undergraduate Online"
  },
  {
    "id": "497046",
    "name": "Madera Community College"
  },
  {
    "id": "497073",
    "name": "Ogle School Hair Skin Nails-Willowbrook"
  },
  {
    "id": "497116",
    "name": "Galen Health Institutes-Austin Campus"
  },
  {
    "id": "497125",
    "name": "Galen Health Institutes-Nashville Campus"
  },
  {
    "id": "497161",
    "name": "Ohio Business College-Dayton-Driving Academy"
  },
  {
    "id": "497189",
    "name": "The Rapha School"
  },
  {
    "id": "497198",
    "name": "Academy of Allied Health Careers"
  },
  {
    "id": "497204",
    "name": "Mei Barber School"
  },
  {
    "id": "497213",
    "name": "Great Northern University"
  },
  {
    "id": "497222",
    "name": "Salon Professional Academy-Elevate Salon Institute"
  },
  {
    "id": "497268",
    "name": "Arizona College of Nursing-Salt Lake City"
  },
  {
    "id": "497277",
    "name": "Arizona College-Glendale"
  },
  {
    "id": "497286",
    "name": "Universal Technical Institute-West Texas"
  },
  {
    "id": "497301",
    "name": "Avalon Institute-Las Vegas"
  },
  {
    "id": "497310",
    "name": "Medspa Academies-National Institute of Modern Aesthetics"
  },
  {
    "id": "497329",
    "name": "American Institute-Cherry Hill"
  },
  {
    "id": "497338",
    "name": "Glendale Career College-North-West College-Bakersfield"
  },
  {
    "id": "10236801",
    "name": "Troy University-Phenix City Campus"
  },
  {
    "id": "10236802",
    "name": "Troy University-Montgomery Campus"
  },
  {
    "id": "10236803",
    "name": "Troy University-Dothan Campus"
  },
  {
    "id": "10236808",
    "name": "Troy University-Online"
  },
  {
    "id": "10236809",
    "name": "Troy University-Support Sites"
  },
  {
    "id": "10415101",
    "name": "Arizona State University-West"
  },
  {
    "id": "10415102",
    "name": "Arizona State University-Polytechnic"
  },
  {
    "id": "10415103",
    "name": "Arizona State University-Downtown Phoenix"
  },
  {
    "id": "10415104",
    "name": "Arizona State University - Tucson"
  },
  {
    "id": "10415105",
    "name": "Arizona State University - Lake Havasu"
  },
  {
    "id": "10415106",
    "name": "Arizona State University - The Gila Valley"
  },
  {
    "id": "10415107",
    "name": "Arizona State University - Yuma"
  },
  {
    "id": "10415108",
    "name": "Arizona State University - Pinal"
  },
  {
    "id": "10415109",
    "name": "Arizona State University - Cochise"
  },
  {
    "id": "10415110",
    "name": "Arizona State University - Yavapai"
  },
  {
    "id": "10415111",
    "name": "Arizona State University - Pima"
  },
  {
    "id": "10415112",
    "name": "Arizona State University - Washington D.C."
  },
  {
    "id": "10635101",
    "name": "Arkansas College of Barbering and Hair Design"
  },
  {
    "id": "10704401",
    "name": "Harding School of Theology"
  },
  {
    "id": "10722001",
    "name": "Career Academy of Hair Design-Siloam Springs"
  },
  {
    "id": "10722002",
    "name": "Career Academy of Hair Design-Rogers"
  },
  {
    "id": "10722003",
    "name": "Career Academy of Hair Design-Fayetteville"
  },
  {
    "id": "10722004",
    "name": "Career Academy of Hair Design - Fort Smith"
  },
  {
    "id": "10772501",
    "name": "University of Arkansas Community College at Hope - Texarkana"
  },
  {
    "id": "11046801",
    "name": "Alliant International University-Fresno"
  },
  {
    "id": "11046802",
    "name": "Alliant International University-Los Angeles"
  },
  {
    "id": "11046803",
    "name": "Alliant International University-San Francisco"
  },
  {
    "id": "11046804",
    "name": "Alliant International University-Irvine"
  },
  {
    "id": "11046805",
    "name": "Alliant International University-Sacramento"
  },
  {
    "id": "11257001",
    "name": "Flashpoint Chicago A Campus of Columbia College Hollywood"
  },
  {
    "id": "11458501",
    "name": "San Jose Campus"
  },
  {
    "id": "11508305",
    "name": "Golden Gate University-Seattle"
  },
  {
    "id": "11508306",
    "name": "Golden Gate University-Silicon Valley"
  },
  {
    "id": "11577301",
    "name": "Humphreys College-Modesto"
  },
  {
    "id": "11635001",
    "name": "TONI&GUY Hairdressing Academy - Costa Mesa"
  },
  {
    "id": "11643901",
    "name": "IDEA at ATEP"
  },
  {
    "id": "11710401",
    "name": "Life Pacific University-Virginia"
  },
  {
    "id": "11891201",
    "name": "MiraCosta College - San Elijo Campus"
  },
  {
    "id": "11917301",
    "name": "Mount Saint Mary's University - Doheny Campus"
  },
  {
    "id": "12240901",
    "name": "San Diego State University-Imperial Valley Campus"
  },
  {
    "id": "12265801",
    "name": "San Joaquin General Hospital School of Radiation Technology"
  },
  {
    "id": "12293101",
    "name": "Jesuit School of Theology of Santa Clara University"
  },
  {
    "id": "12429201",
    "name": "Thomas Aquinas College - New England"
  },
  {
    "id": "12509101",
    "name": "Victor Valley Community College - Aviation Technology"
  },
  {
    "id": "12601201",
    "name": "Wright Institute (The) -"
  },
  {
    "id": "12682703",
    "name": "Colorado Technical University-Denver South"
  },
  {
    "id": "12697901",
    "name": "Denver Seminary - Washington DC"
  },
  {
    "id": "12777801",
    "name": "Otero College - La Junta High School"
  },
  {
    "id": "12980801",
    "name": "Three Rivers Community College - Corrigan-Radgowski Correctional Center"
  },
  {
    "id": "12980802",
    "name": "Three Rivers Community College - York Correctional Institution"
  },
  {
    "id": "13017401",
    "name": "Porter and Chester Institute of Rocky Hill"
  },
  {
    "id": "13017402",
    "name": "Porter and Chester Institute of Enfield"
  },
  {
    "id": "13017403",
    "name": "Porter and Chester Institute of Waterbury"
  },
  {
    "id": "13017405",
    "name": "Porter and Chester Institute of Worcester"
  },
  {
    "id": "13017407",
    "name": "Porter and Chester Institute - New London"
  },
  {
    "id": "13017408",
    "name": "Porter and Chester Institute - Brockton"
  },
  {
    "id": "13017409",
    "name": "Porter and Chester Institute - Chicopee"
  },
  {
    "id": "13090701",
    "name": "Delaware Technical Community College-Owens"
  },
  {
    "id": "13090702",
    "name": "Delaware Technical Community College-Stanton/Wilmington"
  },
  {
    "id": "13247101",
    "name": "Barry University Law School"
  },
  {
    "id": "13508101",
    "name": "Keiser University-Melbourne"
  },
  {
    "id": "13508102",
    "name": "Keiser University-Tallahassee"
  },
  {
    "id": "13508103",
    "name": "Keiser University-Daytona"
  },
  {
    "id": "13508104",
    "name": "Keiser University-Lakeland"
  },
  {
    "id": "13508105",
    "name": "Keiser University- Miami"
  },
  {
    "id": "13508106",
    "name": "Keiser University-Orlando"
  },
  {
    "id": "13508107",
    "name": "Keiser University-Sarasota"
  },
  {
    "id": "13508108",
    "name": "Keiser University-Jacksonville"
  },
  {
    "id": "13508109",
    "name": "Keiser University-Tampa"
  },
  {
    "id": "13508110",
    "name": "Keiser University-Pembroke Pines"
  },
  {
    "id": "13508111",
    "name": "Keiser University-Port St Lucie"
  },
  {
    "id": "13508112",
    "name": "Keiser University-West Palm Beach"
  },
  {
    "id": "13508113",
    "name": "Keiser University-Ft Myers"
  },
  {
    "id": "13508114",
    "name": "Keiser University - Clearwater"
  },
  {
    "id": "13508115",
    "name": "Keiser University - New Port Richey"
  },
  {
    "id": "13508116",
    "name": "Keiser University - Residential (Flagship)"
  },
  {
    "id": "13552201",
    "name": "Maynard A. Traviss Technical College - Central Florida Aerospace Academy"
  },
  {
    "id": "13682601",
    "name": "Miami Lakes Educational Center and Technical College -"
  },
  {
    "id": "13830901",
    "name": "Rasmussen University-Central Pasco"
  },
  {
    "id": "13830902",
    "name": "Rasmussen University-Fort Myers"
  },
  {
    "id": "13830903",
    "name": "Rasmussen University-Tampa/Brandon"
  },
  {
    "id": "13830905",
    "name": "Rasmussen University - Orlando"
  },
  {
    "id": "13962101",
    "name": "East Georgia State College - Statesboro"
  },
  {
    "id": "13962102",
    "name": "East Georgia State College - Augusta"
  },
  {
    "id": "14736901",
    "name": "Moody Theological Seminary and Graduate School--Michigan"
  },
  {
    "id": "14736903",
    "name": "Moody Bible Institute - Spokane"
  },
  {
    "id": "14953201",
    "name": "Triton College - Intl Union of Operating Engr Local 399 Trning Fac."
  },
  {
    "id": "15085301",
    "name": "PJ's College of Cosmetology- Brownsburg"
  },
  {
    "id": "15085303",
    "name": "PJ's College of Cosmetology- Jeffersonville"
  },
  {
    "id": "15098701",
    "name": "Ivy Tech Community College-South Bend/Elkhart"
  },
  {
    "id": "15098702",
    "name": "Ivy Tech Community College-Columbus"
  },
  {
    "id": "15098703",
    "name": "Ivy Tech Community College-Muncie"
  },
  {
    "id": "15098704",
    "name": "Ivy Tech Community College-Kokomo"
  },
  {
    "id": "15098705",
    "name": "Ivy Tech Community College-Lafayette"
  },
  {
    "id": "15098706",
    "name": "Ivy Tech Community College-Fort Wayne"
  },
  {
    "id": "15098707",
    "name": "Ivy Tech Community College-Sellersburg"
  },
  {
    "id": "15098708",
    "name": "Ivy Tech Community College-Evansville"
  },
  {
    "id": "15098709",
    "name": "Ivy Tech Community College-Terre Haute"
  },
  {
    "id": "15098710",
    "name": "Ivy Tech Community College-Richmond"
  },
  {
    "id": "15098711",
    "name": "Ivy Tech Community College-Lake County"
  },
  {
    "id": "15098712",
    "name": "Ivy Tech Community College-Madison"
  },
  {
    "id": "15098713",
    "name": "Ivy Tech Community College-Bloomington"
  },
  {
    "id": "15098714",
    "name": "Ivy Tech Community College-Indianapolis"
  },
  {
    "id": "15208001",
    "name": "University of Notre Dame -"
  },
  {
    "id": "15336601",
    "name": "Graceland University - Independence"
  },
  {
    "id": "15468801",
    "name": "Baker University School of Nursing"
  },
  {
    "id": "15492501",
    "name": "Coffeyville Technical Campus"
  },
  {
    "id": "15492502",
    "name": "Columbus Technical Campus"
  },
  {
    "id": "15535301",
    "name": "Old Town Barber College - KC"
  },
  {
    "id": "15631001",
    "name": "PJ's College of Cosmetology- Greenfield"
  },
  {
    "id": "15631002",
    "name": "PJ's College of Cosmetology- Muncie"
  },
  {
    "id": "15631003",
    "name": "PJ's College of Cosmetology- Indianapolis"
  },
  {
    "id": "15631004",
    "name": "PJ's College of Cosmetology- Plainfield"
  },
  {
    "id": "15631005",
    "name": "PJ's College of Cosmetology- Louisville"
  },
  {
    "id": "16061201",
    "name": "Southeastern Louisiana University - EBR Parish School System Prof Dev"
  },
  {
    "id": "16061202",
    "name": "Southeastern Louisiana University - St Amant High School"
  },
  {
    "id": "16061203",
    "name": "Southeastern Louisiana University - Destrahan High School"
  },
  {
    "id": "16061204",
    "name": "Southeastern Louisiana University - Albert Cammon Middle School"
  },
  {
    "id": "16125301",
    "name": "University of Maine - Machias"
  },
  {
    "id": "16149303",
    "name": "Empire Beauty School-Bangor"
  },
  {
    "id": "16210401",
    "name": "Cecil College - Elkton Station"
  },
  {
    "id": "16855501",
    "name": "Hillsdale Beauty College"
  },
  {
    "id": "16884703",
    "name": "Baker College of Auburn Hills"
  },
  {
    "id": "16884704",
    "name": "Baker College of Cadillac"
  },
  {
    "id": "16884706",
    "name": "Baker College of Jackson"
  },
  {
    "id": "16884707",
    "name": "Baker College of Muskegon"
  },
  {
    "id": "16908001",
    "name": "Calvin University - Handlon Campus"
  },
  {
    "id": "16947903",
    "name": "Davenport University-Warren Location"
  },
  {
    "id": "16947904",
    "name": "Davenport University-Kalamazoo Location"
  },
  {
    "id": "16947905",
    "name": "Davenport University-Lansing Location"
  },
  {
    "id": "16947907",
    "name": "Davenport University-Midland Location"
  },
  {
    "id": "16947909",
    "name": "Davenport University-Holland Location"
  },
  {
    "id": "16947912",
    "name": "Davenport University-Traverse City Location"
  },
  {
    "id": "16947922",
    "name": "Davenport University - Wayne CCCD"
  },
  {
    "id": "16947925",
    "name": "Davenport University - Detroit"
  },
  {
    "id": "17296301",
    "name": "Anoka-Ramsey Community College-Cambridge Campus"
  },
  {
    "id": "17320301",
    "name": "Central Lakes College-Staples Campus"
  },
  {
    "id": "17355901",
    "name": "M State - Detroit Lakes Campus"
  },
  {
    "id": "17355902",
    "name": "M State - Moorhead Campus"
  },
  {
    "id": "17355903",
    "name": "M State - Wadena Campus"
  },
  {
    "id": "17391101",
    "name": "South Central College-Faribault"
  },
  {
    "id": "17395701",
    "name": "Mayo Clinic College of Medicine and Science - Florida"
  },
  {
    "id": "17395702",
    "name": "Mayo Clinic College of Medicine and Science - Arizona"
  },
  {
    "id": "17399301",
    "name": "Mesabi Range College"
  },
  {
    "id": "17447301",
    "name": "Northland Community and Technical College - East Grand Forks"
  },
  {
    "id": "17501401",
    "name": "Rasmussen University-Bloomington"
  },
  {
    "id": "17501402",
    "name": "Rasmussen University-Eagan"
  },
  {
    "id": "17501403",
    "name": "Rasmussen University-Mankato"
  },
  {
    "id": "17501404",
    "name": "Rasmussen University-Brooklyn Park"
  },
  {
    "id": "17501405",
    "name": "Rasmussen University-Lake Elmo/Woodbury"
  },
  {
    "id": "17501406",
    "name": "Rasmussen University-Blaine"
  },
  {
    "id": "17526301",
    "name": "Minnesota State CollegeSoutheast-Red Wing Campus"
  },
  {
    "id": "17557301",
    "name": "Copiah-Lincoln Community College Simpson County Center"
  },
  {
    "id": "17557302",
    "name": "Copiah-Lincoln Community College-Natchez Campus"
  },
  {
    "id": "17601701",
    "name": "University of Mississippi Medical Center"
  },
  {
    "id": "17623901",
    "name": "Pearl River Community College-Forrest County Campus"
  },
  {
    "id": "17706501",
    "name": "Columbia College - Redstone Arsenal"
  },
  {
    "id": "17706503",
    "name": "Columbia College - Denver"
  },
  {
    "id": "17706504",
    "name": "Columbia College - NAS Jacksonville"
  },
  {
    "id": "17706505",
    "name": "Columbia College - Orlando"
  },
  {
    "id": "17706506",
    "name": "Columbia College - Crystal Lake Campus"
  },
  {
    "id": "17706507",
    "name": "Columbia College - Kansas City"
  },
  {
    "id": "17706508",
    "name": "Columbia College - Fort Leonard Wood"
  },
  {
    "id": "17706509",
    "name": "Columbia College - Jefferson City"
  },
  {
    "id": "17706510",
    "name": "Columbia College - Lake Ozark"
  },
  {
    "id": "17706511",
    "name": "Columbia College - Saint Louis"
  },
  {
    "id": "17706512",
    "name": "Columbia College - Fort Worth"
  },
  {
    "id": "17706513",
    "name": "Columbia College - Salt Lake"
  },
  {
    "id": "17706514",
    "name": "Columbia College - Lake County"
  },
  {
    "id": "17706515",
    "name": "Columbia College - Naval Station Everett/Marysville"
  },
  {
    "id": "17706516",
    "name": "Columbia College - Freeport"
  },
  {
    "id": "17706517",
    "name": "Columbia College - Springfield"
  },
  {
    "id": "17706518",
    "name": "Columbia College - Rolla"
  },
  {
    "id": "17706519",
    "name": "Columbia College - Los Alamitos"
  },
  {
    "id": "17706521",
    "name": "Columbia College - Lemoore"
  },
  {
    "id": "17706522",
    "name": "Columbia College - San Luis Obispo"
  },
  {
    "id": "17706523",
    "name": "Columbia College - Fort Stewart"
  },
  {
    "id": "17706524",
    "name": "Columbia College - Hunter Army Airfield"
  },
  {
    "id": "17706525",
    "name": "Columbia College - Elgin"
  },
  {
    "id": "17706527",
    "name": "Columbia College - Whidbey Island"
  },
  {
    "id": "17706529",
    "name": "Columbia College - Jacksonville"
  },
  {
    "id": "17706530",
    "name": "Columbia College - Fort Sill"
  },
  {
    "id": "17706532",
    "name": "Columbia College - San Diego"
  },
  {
    "id": "17706533",
    "name": "Columbia College - Mesquite"
  },
  {
    "id": "17706534",
    "name": "Columbia College - Waynesville"
  },
  {
    "id": "17706535",
    "name": "Columbia College - Naval Base San Diego"
  },
  {
    "id": "17706536",
    "name": "Columbia College - Imperial"
  },
  {
    "id": "17706537",
    "name": "Columbia College - NSB Kings Bay"
  },
  {
    "id": "17706539",
    "name": "Columbia College - Minot AFB"
  },
  {
    "id": "17706540",
    "name": "Columbia College - Springfield TMobile"
  },
  {
    "id": "17706541",
    "name": "Columbia College - Eastfield Pleasant Grove"
  },
  {
    "id": "17706542",
    "name": "Columbia College - Fort Leonard Wood 58th Trans BN"
  },
  {
    "id": "17706543",
    "name": "Columbia College - USCG Base Honolulu"
  },
  {
    "id": "17706544",
    "name": "Columbia College - Tavares PD"
  },
  {
    "id": "17733901",
    "name": "Evangel University - Assemblies of God Theological Seminary"
  },
  {
    "id": "18017901",
    "name": "City College Montana State University Billings"
  },
  {
    "id": "18303501",
    "name": "New Hampshire Institute for Therapeutic Arts"
  },
  {
    "id": "18409201",
    "name": "American Institute"
  },
  {
    "id": "18557201",
    "name": "Monmouth University - Monmouth Park Corporate Center"
  },
  {
    "id": "18908801",
    "name": "Bard College - Holyoke Microcollege"
  },
  {
    "id": "18908802",
    "name": "Bard College - Coxsackie Correctional Facility"
  },
  {
    "id": "18908803",
    "name": "Bard College - Eastern NY Correctional Facility"
  },
  {
    "id": "18908804",
    "name": "Bard College - Fishkill Correctional Facility"
  },
  {
    "id": "18908805",
    "name": "Bard College - Green Haven Correctional Facility"
  },
  {
    "id": "18908806",
    "name": "Bard College - Taconic Correctional Facility"
  },
  {
    "id": "18908807",
    "name": "Bard College - Woodbourne Correctional Facility"
  },
  {
    "id": "18908808",
    "name": "Bard College - BHSEC Newark"
  },
  {
    "id": "18908809",
    "name": "Bard College - Brooklyn Public Library"
  },
  {
    "id": "18956501",
    "name": "Bryant & Stratton College-Syracuse"
  },
  {
    "id": "18958301",
    "name": "Bryant & Stratton College-Amherst"
  },
  {
    "id": "18958302",
    "name": "Bryant & Stratton College-Southtowns"
  },
  {
    "id": "18959201",
    "name": "Bryant & Stratton College-Henrietta"
  },
  {
    "id": "19004401",
    "name": "Clarkson University Capital Region Campus"
  },
  {
    "id": "19050301",
    "name": "Culinary Institute of America at Greystone"
  },
  {
    "id": "19050302",
    "name": "Culinary Institute of America San Antonio"
  },
  {
    "id": "19054901",
    "name": "CUNY Brooklyn College - Feirstein Graduate School of Cinema"
  },
  {
    "id": "19200401",
    "name": "Jamestown Business College -"
  },
  {
    "id": "19385801",
    "name": "NYSMedical&Dental Assistants - American Institute of Clinical Massage"
  },
  {
    "id": "19457801",
    "name": "Pratt Manhattan-A Division of Pratt Institute"
  },
  {
    "id": "19476301",
    "name": "Rabbinical Seminary of America-Ma'yan HaTorah"
  },
  {
    "id": "19580901",
    "name": "Saint John's University - Staten Island Campus"
  },
  {
    "id": "19857001",
    "name": "Gaston College"
  },
  {
    "id": "19998001",
    "name": "Winston - Salem Barber School - Satellite"
  },
  {
    "id": "20146901",
    "name": "Bryant & Stratton College-Akron"
  },
  {
    "id": "20146902",
    "name": "Bryant & Stratton College-Solon"
  },
  {
    "id": "20146903",
    "name": "Bryant & Stratton College-Cleveland"
  },
  {
    "id": "20306701",
    "name": "Hebrew Union College-Jewish Institute of Religion-Cincinnati"
  },
  {
    "id": "20306702",
    "name": "Hebrew Union College-Jewish Institute of Religion-Los Angeles"
  },
  {
    "id": "20354401",
    "name": "Kettering College - Ollie Davis Medical Arts and Education Center"
  },
  {
    "id": "20417601",
    "name": "Mount Carmel College of Nursing - Fairfield"
  },
  {
    "id": "20627901",
    "name": "Los Angeles Center"
  },
  {
    "id": "20627902",
    "name": "Florida Center"
  },
  {
    "id": "20627903",
    "name": "Sacramento Center"
  },
  {
    "id": "21036801",
    "name": "Western Seminary-San Jose"
  },
  {
    "id": "21036802",
    "name": "Western Seminary-Sacramento"
  },
  {
    "id": "21078401",
    "name": "The Beauty Institute"
  },
  {
    "id": "21130701",
    "name": "Bucks County Community College-Upper Bucks Campus"
  },
  {
    "id": "21130702",
    "name": "Bucks County Community College-Gene and Marlene Epstein Campus at Lower Bucks"
  },
  {
    "id": "21287801",
    "name": "Harrisburg Area Community College-Lancaster"
  },
  {
    "id": "21287802",
    "name": "Harrisburg Area Community College-Gettysburg"
  },
  {
    "id": "21287803",
    "name": "Harrisburg Area Community College-Lebanon"
  },
  {
    "id": "21287804",
    "name": "Harrisburg Area Community College-York"
  },
  {
    "id": "21316301",
    "name": "UPMC Jameson School of Nursing - UPMC Hamot"
  },
  {
    "id": "21398701",
    "name": "Mercyhurst Municipal Police Training Academy"
  },
  {
    "id": "21429001",
    "name": "New Castle School of Trades"
  },
  {
    "id": "21429002",
    "name": "New Castle School of Trades - East Liverpool Campus"
  },
  {
    "id": "21437901",
    "name": "Northampton County Area Community College-Monroe"
  },
  {
    "id": "21608301",
    "name": "South Hills School of Business and Technology-Altoona"
  },
  {
    "id": "22055202",
    "name": "South College-Asheville"
  },
  {
    "id": "22055203",
    "name": "South College-Nashville"
  },
  {
    "id": "22055204",
    "name": "South College-Atlanta"
  },
  {
    "id": "22300101",
    "name": "Austin Presbyterian Theological Seminary- YMCA Outdoor Center"
  },
  {
    "id": "22797901",
    "name": "San Jacinto College-North Campus"
  },
  {
    "id": "22797902",
    "name": "San Jacinto College-South Campus"
  },
  {
    "id": "22797903",
    "name": "San Jacinto Community College District - Generation Park Campus"
  },
  {
    "id": "22872301",
    "name": "Texas A & M University Health Science Center"
  },
  {
    "id": "23059701",
    "name": "Snow College-Richfield Campus"
  },
  {
    "id": "23091302",
    "name": "Northern Vermont University - Lyndon"
  },
  {
    "id": "23155401",
    "name": "Bluefield College - Edward Via College of Osteopathic Medicine"
  },
  {
    "id": "23178501",
    "name": "Bryant & Stratton College-Richmond"
  },
  {
    "id": "23178502",
    "name": "Bryant & Stratton College-Hampton"
  },
  {
    "id": "23368401",
    "name": "Strayer University-Alexandria Campus"
  },
  {
    "id": "23368402",
    "name": "Strayer University-Woodbridge Campus"
  },
  {
    "id": "23368404",
    "name": "Strayer University-Loudoun Campus"
  },
  {
    "id": "23368405",
    "name": "Strayer University-Fredericksburg Campus"
  },
  {
    "id": "23368408",
    "name": "Strayer University-Chesterfield Campus"
  },
  {
    "id": "23368410",
    "name": "Strayer University-Newport News Campus"
  },
  {
    "id": "23368411",
    "name": "Strayer University-Virginia Beach Campus"
  },
  {
    "id": "23384201",
    "name": "Union Presbyterian Seminary-Charlotte"
  },
  {
    "id": "23661001",
    "name": "Shoreline Community College - CNC Machinists Program Georgetown SSC"
  },
  {
    "id": "23661002",
    "name": "Shoreline Community College - Dental Hygiene"
  },
  {
    "id": "23693901",
    "name": "Washington State University-Vancouver"
  },
  {
    "id": "23693902",
    "name": "Washington State University-Spokane"
  },
  {
    "id": "23693903",
    "name": "Washington State University-Tri Cities"
  },
  {
    "id": "23693904",
    "name": "Washington State University - Everett Campus"
  },
  {
    "id": "24297201",
    "name": "NUC University - Arecibo"
  },
  {
    "id": "24297202",
    "name": "NUC University - Rio Grande"
  },
  {
    "id": "24297203",
    "name": "NUC University - Ponce"
  },
  {
    "id": "24297204",
    "name": "NUC University - Caguas"
  },
  {
    "id": "24297205",
    "name": "NUC University - Mayaguez"
  },
  {
    "id": "24297206",
    "name": "NUC University - South Florida"
  },
  {
    "id": "24297207",
    "name": "NUC University - Florida Technical College - Lakeland"
  },
  {
    "id": "24297208",
    "name": "NUC University - Florida Technical College - Deland"
  },
  {
    "id": "24297209",
    "name": "NUC University - Florida Technical College - Kissimmee"
  },
  {
    "id": "24297210",
    "name": "NUC University - Florida Technical College - Pembroke Pines"
  },
  {
    "id": "24297211",
    "name": "NUC University - Florida Technical College - Cutler Bay"
  },
  {
    "id": "24297212",
    "name": "NUC University - IBC Technical Division - Ponce"
  },
  {
    "id": "24297213",
    "name": "NUC University - IBC Technical Division - Mayaguez"
  },
  {
    "id": "24297214",
    "name": "NUC University - IBC Technical Division - Guayama"
  },
  {
    "id": "24297215",
    "name": "NUC University - IBC Technical Division - Fajardo"
  },
  {
    "id": "24297216",
    "name": "NUC University - IBC Technical Division - Manati"
  },
  {
    "id": "24297217",
    "name": "NUC University - IBC Technical Division - Los Colobos"
  },
  {
    "id": "24297218",
    "name": "NUC University - IBC Technical Division - Moca"
  },
  {
    "id": "24297219",
    "name": "NUC University - IBC Technical Division - Aguadilla"
  },
  {
    "id": "24297220",
    "name": "NUC University - IBC Technical Division - Caguas"
  },
  {
    "id": "24297221",
    "name": "NUC University - IBC Technical Division - Arecibo"
  },
  {
    "id": "24297222",
    "name": "NUC University - IBC Technical Division - Escorial"
  },
  {
    "id": "24297223",
    "name": "NUC University - IBC Technical Division - Bayamon"
  },
  {
    "id": "24297224",
    "name": "NUC University - IBC Technical Division - Yauco"
  },
  {
    "id": "24297225",
    "name": "NUC University - Florida Technical College - Orlando"
  },
  {
    "id": "24297226",
    "name": "NUC University - Florida Technical College - Tampa"
  },
  {
    "id": "24366501",
    "name": "University of the Virgin Islands-Albert A. Sheen"
  },
  {
    "id": "24378001",
    "name": "Purdue University - Purdue Polytechnic Anderson"
  },
  {
    "id": "24378002",
    "name": "Purdue University - Purdue Polytechnic Indianapolis"
  },
  {
    "id": "24378003",
    "name": "Purdue University - Purdue Polytechnic South Bend"
  },
  {
    "id": "24378004",
    "name": "Purdue University - Purdue Polytechnic New Albany"
  },
  {
    "id": "24378006",
    "name": "Purdue University - Purdue Polytechnic Columbus"
  },
  {
    "id": "24378007",
    "name": "Purdue University - Purdue Polytechnic Kokomo"
  },
  {
    "id": "24378008",
    "name": "Purdue University - Purdue Polytechnic Richmond"
  },
  {
    "id": "24378009",
    "name": "Purdue University - Purdue Polytechnic Lafayette"
  },
  {
    "id": "24378010",
    "name": "Purdue University - Purdue Polytechnic Vincennes"
  },
  {
    "id": "24862401",
    "name": "Fayette County Career & Technical Institute -"
  },
  {
    "id": "24893401",
    "name": "ECPI University-Newport News"
  },
  {
    "id": "24893402",
    "name": "ECPI University-Greensboro"
  },
  {
    "id": "24893403",
    "name": "ECPI University-Charlotte"
  },
  {
    "id": "24893404",
    "name": "ECPI University-Greenville"
  },
  {
    "id": "24893405",
    "name": "ECPI University-Manassas"
  },
  {
    "id": "24893406",
    "name": "ECPI University-Charleston"
  },
  {
    "id": "24893407",
    "name": "ECPI University-Raleigh"
  },
  {
    "id": "24893408",
    "name": "ECPI University-Columbia"
  },
  {
    "id": "24893410",
    "name": "ECPI University-Culinary Institute of Virginia"
  },
  {
    "id": "24893411",
    "name": "ECPI University-Virginia Beach Health Sciences"
  },
  {
    "id": "24893412",
    "name": "ECPI University-Richmond South"
  },
  {
    "id": "24893413",
    "name": "ECPI University-Innsbrook"
  },
  {
    "id": "24893414",
    "name": "ECPI University-Richmond West"
  },
  {
    "id": "24893415",
    "name": "ECPI University - Roanoke"
  },
  {
    "id": "24893416",
    "name": "ECPI University - Orlando"
  },
  {
    "id": "24893417",
    "name": "ECPI University - Culinary Institute of Virginia - Newport News"
  },
  {
    "id": "24893418",
    "name": "ECPI University - San Antonio"
  },
  {
    "id": "36662301",
    "name": "Northwest Technology Center-Fairview"
  },
  {
    "id": "37369601",
    "name": "Rob Roy Academy - Woonsocket"
  },
  {
    "id": "37727201",
    "name": "Nuvani Institute-Uvalde"
  },
  {
    "id": "37728101",
    "name": "Nuvani Institute-Del Rio"
  },
  {
    "id": "37728103",
    "name": "Nuvani Institute-South Campus"
  },
  {
    "id": "38561901",
    "name": "Everglades University-Orlando"
  },
  {
    "id": "38561902",
    "name": "Everglades University-Sarasota"
  },
  {
    "id": "38561903",
    "name": "Everglades University - Tampa"
  },
  {
    "id": "38561904",
    "name": "Everglades University - Miami"
  },
  {
    "id": "40884401",
    "name": "Florida National University-South Campus"
  },
  {
    "id": "40884402",
    "name": "Florida National University Training Center"
  },
  {
    "id": "41724801",
    "name": "Connecticut Aero Tech School"
  },
  {
    "id": "41829601",
    "name": "Indian Capital Technology Center-Sallisaw"
  },
  {
    "id": "41829602",
    "name": "Indian Capital Technology Center-Stilwell"
  },
  {
    "id": "41829603",
    "name": "Indian Capital Technology Center-Tahlequah"
  },
  {
    "id": "41918301",
    "name": "Central California School of Continuing Education -"
  },
  {
    "id": "42312001",
    "name": "Compu-Med Vocational Careers Corp"
  },
  {
    "id": "42799101",
    "name": "Osceola Technical College - St. Cloud Campus"
  },
  {
    "id": "42799102",
    "name": "Osceola Technical College - Poinciana Campus"
  },
  {
    "id": "42817001",
    "name": "Southeastern College-Miami Lakes"
  },
  {
    "id": "43018401",
    "name": "Strayer University-Rockville Campus"
  },
  {
    "id": "43018402",
    "name": "Strayer University-Anne Arundel Campus"
  },
  {
    "id": "43018403",
    "name": "Strayer University-White Marsh Campus"
  },
  {
    "id": "43018404",
    "name": "Strayer University-Owings Mills Campus"
  },
  {
    "id": "43342002",
    "name": "Santa Barbara Business College - Ventura"
  },
  {
    "id": "43482101",
    "name": "Blue Cliff College"
  },
  {
    "id": "44098901",
    "name": "Texas Barber College - Branch Campus #1"
  },
  {
    "id": "44098902",
    "name": "Texas Barber College - Branch Campus #2"
  },
  {
    "id": "44098904",
    "name": "Texas Barber College - Branch Campus #5"
  },
  {
    "id": "44376601",
    "name": "Strayer University-Nashville Campus"
  },
  {
    "id": "44376602",
    "name": "Strayer University-Shelby Oaks Campus"
  },
  {
    "id": "44376603",
    "name": "Strayer University-Knoxville Campus"
  },
  {
    "id": "44378401",
    "name": "Strayer University-Delaware County Campus"
  },
  {
    "id": "44378403",
    "name": "Strayer University-Center City Campus"
  },
  {
    "id": "44378406",
    "name": "Strayer University-Allentown Campus"
  },
  {
    "id": "44452901",
    "name": "Stellar Career College - Chicago IL"
  },
  {
    "id": "44473201",
    "name": "Leston College -"
  },
  {
    "id": "44784701",
    "name": "Aviator College of Aeronautical Science & Technology - Kissimmee"
  },
  {
    "id": "44867301",
    "name": "Rasmussen University-Aurora"
  },
  {
    "id": "44867302",
    "name": "Rasmussen University-Romeoville/Joliet"
  },
  {
    "id": "44867303",
    "name": "Rasmussen University-Mokena/Tinley Park"
  },
  {
    "id": "44903803",
    "name": "Strayer University-Orlando East Campus"
  },
  {
    "id": "44903804",
    "name": "Strayer University-Sand Lake Campus"
  },
  {
    "id": "44903805",
    "name": "Strayer University-Baymeadows Campus"
  },
  {
    "id": "44903809",
    "name": "Strayer University-Miramar Campus"
  },
  {
    "id": "44946301",
    "name": "Academy Di Capelli -"
  },
  {
    "id": "44970001",
    "name": "Dayton School of Medical Massage-Lima"
  },
  {
    "id": "44970002",
    "name": "Cincinnati School of Medical Massage"
  },
  {
    "id": "44970003",
    "name": "Cleveland Institute of Medical Massage"
  },
  {
    "id": "44970004",
    "name": "Columbus School of Medical Massage"
  },
  {
    "id": "44977302",
    "name": "CCI Training Center"
  },
  {
    "id": "45037701",
    "name": "Strayer University-Huntsville Campus"
  },
  {
    "id": "45037702",
    "name": "Strayer University - Montgomery Campus"
  },
  {
    "id": "45057102",
    "name": "Rasmussen University-Wausau"
  },
  {
    "id": "45098801",
    "name": "Coastline Beauty College - Hemet"
  },
  {
    "id": "45133401",
    "name": "Academy of Hair Design - Austin"
  },
  {
    "id": "45175001",
    "name": "Bryant & Stratton College-Bayshore"
  },
  {
    "id": "45175002",
    "name": "Bryant & Stratton College-Racine"
  },
  {
    "id": "45316301",
    "name": "Strayer University-North Charlotte"
  },
  {
    "id": "45316302",
    "name": "Strayer University-South Charlotte"
  },
  {
    "id": "45316303",
    "name": "Strayer University-North Raleigh Campus"
  },
  {
    "id": "45316306",
    "name": "Strayer University-South Raleigh Campus"
  },
  {
    "id": "45486501",
    "name": "Cambridge College of Healthcare & Technology"
  },
  {
    "id": "45491701",
    "name": "Celebrity School of Beauty - Hialeah"
  },
  {
    "id": "45519601",
    "name": "Jersey College"
  },
  {
    "id": "45519602",
    "name": "Jersey College"
  },
  {
    "id": "45519603",
    "name": "Jersey College"
  },
  {
    "id": "45519604",
    "name": "Jersey College"
  },
  {
    "id": "45519605",
    "name": "Jersey College"
  },
  {
    "id": "45519606",
    "name": "Jersey College"
  },
  {
    "id": "45519607",
    "name": "Jersey College - Fort Wayne"
  },
  {
    "id": "45538101",
    "name": "Utah County Campus"
  },
  {
    "id": "45763301",
    "name": "L'esprit Academy - Royal Oak"
  },
  {
    "id": "45891901",
    "name": "Strayer University-Cobb Campus"
  },
  {
    "id": "45891902",
    "name": "Strayer University-Morrow Campus"
  },
  {
    "id": "45891905",
    "name": "Strayer University-Lithonia Campus"
  },
  {
    "id": "45891906",
    "name": "Strayer University-Savannah Campus"
  },
  {
    "id": "45891907",
    "name": "Strayer University-Augusta Campus"
  },
  {
    "id": "45891908",
    "name": "Strayer University-Columbus"
  },
  {
    "id": "45891909",
    "name": "Strayer University - Macon Campus"
  },
  {
    "id": "45896401",
    "name": "Strayer University-Columbia Campus"
  },
  {
    "id": "45896402",
    "name": "Strayer University-Charleston Campus"
  },
  {
    "id": "45897305",
    "name": "Strayer University-Cedar Hill"
  },
  {
    "id": "45897307",
    "name": "Strayer University-San Antonio"
  },
  {
    "id": "45897308",
    "name": "Strayer University-Stafford"
  },
  {
    "id": "45897309",
    "name": "Strayer University - Northwest Houston Campus"
  },
  {
    "id": "45920401",
    "name": "Unitek College"
  },
  {
    "id": "46131501",
    "name": "Keweenaw Bay Ojibwa Community College - Wabanung Campus"
  },
  {
    "id": "46182901",
    "name": "Cinta Aveda Institute - San Jose"
  },
  {
    "id": "46185601",
    "name": "Southern California Health Institute (SOCHI) - North Hollywood"
  },
  {
    "id": "47501501",
    "name": "Aveda Institute Rochester"
  },
  {
    "id": "47503301",
    "name": "Relay Graduate School of Education - Newark"
  },
  {
    "id": "47503302",
    "name": "Relay Graduate School of Education - New Orleans"
  },
  {
    "id": "47503303",
    "name": "Relay Graduate School of Education - Delaware"
  },
  {
    "id": "47503304",
    "name": "Relay Graduate School of Education - Memphis"
  },
  {
    "id": "47503305",
    "name": "Relay Graduate School of Education - Houston"
  },
  {
    "id": "47503306",
    "name": "Relay Graduate School of Education - Chicago"
  },
  {
    "id": "47503307",
    "name": "Relay Graduate School of Education - Baton Rouge"
  },
  {
    "id": "47503308",
    "name": "Relay Graduate School of Education - Philadelphia & Camden"
  },
  {
    "id": "47503309",
    "name": "Relay Graduate School of Education - Denver"
  },
  {
    "id": "47503310",
    "name": "Relay Graduate School of Education - Nashville"
  },
  {
    "id": "47503311",
    "name": "Relay Graduate School of Education - San Antonio"
  },
  {
    "id": "47503312",
    "name": "Relay Graduate School of Education - Dallas-Fort Worth"
  },
  {
    "id": "47503313",
    "name": "Relay Graduate School of Education - Washington D.C"
  },
  {
    "id": "47503314",
    "name": "Relay Graduate School of Education - Atlanta"
  },
  {
    "id": "47503315",
    "name": "Relay Graduate School of Education - California"
  },
  {
    "id": "47503316",
    "name": "Relay Graduate School of Education - Indiana"
  },
  {
    "id": "47503317",
    "name": "Relay Graduate School of Education - Connecticut"
  },
  {
    "id": "47662901",
    "name": "Salon Institute - Columbus Campus"
  },
  {
    "id": "48002801",
    "name": "Digital Film Academy - Atlanta"
  },
  {
    "id": "48065701",
    "name": "Rasmussen University - Overland Park"
  },
  {
    "id": "48079001",
    "name": "Rocky Vista University - Southern Utah"
  },
  {
    "id": "48092101",
    "name": "Designer Barber & Stylist School -"
  },
  {
    "id": "48133101",
    "name": "Sharp Edgez Barber Institute - Buffalo"
  },
  {
    "id": "48325801",
    "name": "Networks Barber College"
  },
  {
    "id": "48325802",
    "name": "Networks Barber College"
  },
  {
    "id": "48379901",
    "name": "East-West Healing Arts Institute - Milwaukee"
  },
  {
    "id": "48385001",
    "name": "Alliance Computing Solutions - NYC"
  },
  {
    "id": "48387801",
    "name": "Bay Area Medical Academy - San Jose Satellite Location"
  },
  {
    "id": "48400201",
    "name": "High Desert Medical College - Bakerfield"
  },
  {
    "id": "48400202",
    "name": "High Desert Medical College - Temecula"
  },
  {
    "id": "48486201",
    "name": "University of West Los Angeles - Chatsworth"
  },
  {
    "id": "48511101",
    "name": "Georgia Military College - Zebulon"
  },
  {
    "id": "48511103",
    "name": "Georgia Military College - Valdosta"
  },
  {
    "id": "48511104",
    "name": "Georgia Military College - Augusta"
  },
  {
    "id": "48511105",
    "name": "Georgia Military College - Fairburn"
  },
  {
    "id": "48511106",
    "name": "Georgia Military College - Robins"
  },
  {
    "id": "48511107",
    "name": "Georgia Military College - Columbus"
  },
  {
    "id": "48511108",
    "name": "Georgia Military College - Sandersville"
  },
  {
    "id": "48511109",
    "name": "Georgia Military College - Madison"
  },
  {
    "id": "48511110",
    "name": "Georgia Military College - Stone Mountain"
  },
  {
    "id": "48511111",
    "name": "Georgia Military College - Fayetteville"
  },
  {
    "id": "48511112",
    "name": "Georgia Military College - Dublin"
  },
  {
    "id": "48511113",
    "name": "Georgia Military College - Eastman"
  },
  {
    "id": "48616901",
    "name": "American College of Barbering - Florence"
  },
  {
    "id": "49005401",
    "name": "HCI College - Fort Lauderdale Campus"
  },
  {
    "id": "49010601",
    "name": "Virginia University of Integrative Medicine - New Jersey"
  },
  {
    "id": "49022101",
    "name": "MAK Beauty Institute - Duluth"
  },
  {
    "id": "49146401",
    "name": "ABC Adult School - Cabrillo Lane"
  },
  {
    "id": "49175601",
    "name": "Urban Barber College - San Jose"
  },
  {
    "id": "49178301",
    "name": "Wilton Simpson Technical College"
  },
  {
    "id": "49425001",
    "name": "Valley College - Fairlawn - School of Nursing"
  },
  {
    "id": "49501301",
    "name": "Western Maricopa Education Center - Southwest Campus"
  },
  {
    "id": "49501302",
    "name": "Western Maricopa Education Center - Northeast Campus"
  },
  {
    "id": "49576701",
    "name": "Pennsylvania State University-Dickinson Law"
  },
  {
    "id": "49576702",
    "name": "Pennsylvania State University-World Campus"
  },
  {
    "id": "49576703",
    "name": "Pennsylvania State University-College of Medicine"
  },
  {
    "id": "49576704",
    "name": "Pennsylvania State University-Penn State Lehigh Valley"
  },
  {
    "id": "49576705",
    "name": "Pennsylvania State University-Penn State Altoona"
  },
  {
    "id": "49576706",
    "name": "Pennsylvania State University-Penn State Beaver"
  },
  {
    "id": "49576707",
    "name": "Pennsylvania State University-Penn State Erie-Behrend College"
  },
  {
    "id": "49576708",
    "name": "Pennsylvania State University-Penn State Berks"
  },
  {
    "id": "49576709",
    "name": "Pennsylvania State University-Penn State DuBois"
  },
  {
    "id": "49576710",
    "name": "Pennsylvania State University-Penn State Fayette- Eberly"
  },
  {
    "id": "49576711",
    "name": "Pennsylvania State University-Penn State Hazleton"
  },
  {
    "id": "49576712",
    "name": "Pennsylvania State University-Penn State Greater Allegheny"
  },
  {
    "id": "49576713",
    "name": "Pennsylvania State University-Penn State Mont Alto"
  },
  {
    "id": "49576714",
    "name": "Pennsylvania State University-Penn State New Kensington"
  },
  {
    "id": "49576715",
    "name": "Pennsylvania State University-Penn State Abington"
  },
  {
    "id": "49576716",
    "name": "Pennsylvania State University-Penn State Schuylkill"
  },
  {
    "id": "49576717",
    "name": "Pennsylvania State University-Penn State Scranton"
  },
  {
    "id": "49576718",
    "name": "Pennsylvania State University-Penn State Shenango"
  },
  {
    "id": "49576719",
    "name": "Pennsylvania State University-Penn State Wilkes-Barre"
  },
  {
    "id": "49576720",
    "name": "Pennsylvania State University-Penn State York"
  },
  {
    "id": "49576721",
    "name": "Pennsylvania State University-Penn State Great Valley"
  },
  {
    "id": "49576722",
    "name": "Pennsylvania State University-Penn State Harrisburg"
  },
  {
    "id": "49576723",
    "name": "Pennsylvania State University-Penn State Brandywine"
  },
  {
    "id": "49664501",
    "name": "Burlington County Institute of Technology - Adult Education -"
  }
]