import { LoadingSpinner } from "../LoadingSpinner"
import { titleSpace, returnBulletListFromHyphenSeparatedString } from "../dictionary"

const AdvisoryServices = ({ advisor }) => {

  if (!advisor) {
    return <LoadingSpinner title={'Advisory Services'} />
  }

  // const returnDiscussTopics = (res) => {
  //   if (res) {
  //     let resBullets = res.split('- ').filter((str) => str != '')

  //     return resBullets.map((res, index) =>
  //       <ul>
  //         <li key={index}>{res}</li>
  //       </ul>
  //     )
  //   }

  //   else {
  //     return '-'
  //   }
  // }

  return (
    <div className="" style={{ marginTop: '30px' }}>
      <div style={{ fontSize: '23px' }}>Advisory Services</div>

      <div className="card shadow" style={{ marginTop: '15px' }}>
        <div className="d-flex flex-row" style={{ marginBottom: '40px' }}>

          <div className="d-flex flex-column" style={{ width: '45%', paddingLeft: '40px', paddingTop: "40px" }}>
            <div className="dca-card-item-text" style={{ letterSpacing: titleSpace, marginBottom: '5px' }}>DISCUSSION TOPICS</div>
            <div>{returnBulletListFromHyphenSeparatedString(advisor.discussTopics)}</div>
          </div>


          <div className="d-flex flex-column" style={{ width: '45%', paddingLeft: '40px', paddingTop: "40px" }}>
            <div className="dca-card-item-text" style={{ letterSpacing: titleSpace, marginBottom: '5px' }}>FEES</div>
            <div>${advisor.sessionFee} ({advisor.sessionDuration} minutes)</div>
          </div>

        </div>
      </div>
    </div>
  )
}

export { AdvisoryServices }