import { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import { pageRoutes } from "../dictionary"

const StickyNavbar = (props) => {

  const navigate = useNavigate()
  const [showStickyNav, setShowStickyNav] = useState<any>(false)

  useEffect(() => {
    const triggerStickyNav = () => {
      if (window.scrollY > 80) {
        setShowStickyNav(true)
      } else {
        setShowStickyNav(false)
      }
    }

    window.addEventListener('scroll', triggerStickyNav)

    return () => {
      window.removeEventListener('scroll', triggerStickyNav)
    }
  }, [])

  // return showStickyNav && (
  //   <div className='dca-stickyHeader align-items-stretch flex-shrink-0'>
  //     <div className='dca-header-container'>
  //       <div className='dca-marketing-stickynav-container'>
  //         <div
  //           className='dca-marketing-stickynav-logo'
  //           onClick={() => navigate(pageRoutes.home)}>
  //           NEXTFOUR
  //         </div>

  //         {props.purchaseButton && props.purchaseButton}
  //       </div>
  //     </div>
  //   </div>
  // )

  return showStickyNav && (
    <div className='dca-stickyHeader'>
      <div
        className='dca-header-logo'
        onClick={() => navigate(pageRoutes.home)}>
        NEXTFOUR
      </div>

      {props.purchaseButton && props.purchaseButton}

    </div>
  )
}

export { StickyNavbar }