const Testimonials = ({ data }) => {

  // Format example
  // const testimonialData = [
  //   { statement: `“Among the virtually endless EC possibilities, the report helped me harness data-driven insights to choose what I believe are the best EC programs for me.”`, source: 'JULIET BARNES', relation: 'Stanford University Admit' },
  //   { statement: `“Among the virtually endless EC possibilities, the report helped me harness data-driven insights to choose what I believe are the best EC programs for me.”`, source: 'JULIET BARNES', relation: 'Stanford University Admit' }
  // ]

  return (
    <div className="dca-marketing-testimonial-wrapper">
      {data.map((res, index) =>
        <div key={index} className="dca-marketing-testimonial-wrapper-B">
          <div className="dca-marketing-testimonial-text">
            {res.statement}
          </div>
          <div className="dca-marketing-testimonial-source">{res.source},</div>
          <div className="dca-marketing-testimonial-source">{res.relation}</div>
        </div>)}
    </div>
  )
}

export { Testimonials }