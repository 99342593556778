import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react"
import axios from "axios";
import Select from 'react-select'

import { URL, rdSpendAccessCode, getStripePrice, pageRoutes, updateTitleAndDescription, rdSpendPriceID, collegeLogos } from "../../components/dictionary";
import { CollegeReactGAHandlers, ECReactGAHandlers } from "../../components/ReactGAHandlers";
import { getAuth } from '../../modules/auth';
import { PurchaseButton, PurchaseButtonNav } from "../../components/Marketing/PurchaseButton";
import { StickyNavbar } from "../../components/Marketing/StickyNavbar";
import { collegeMap } from '../../components/collegeMap';
import { RnDSpend } from '../../components/CollegeDetail/TabComponents/R&DSpend';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { FAQ } from '../../components/Marketing/FAQ';
import { ConnectEmailCTA } from '../../components/Marketing/ConnectEmailCTA';
import { Testimonials } from '../../components/Marketing/Testimonials';
import { HoverCards } from '../../components/Marketing/HoverCards';
import { MarketingImageWrapper } from '../../components/Marketing/ImageWrappers';
import { CollegeSelectComponent } from '../../components/Marketing/CollegeSelectComponent';

import diamond from '../../../custom_assets/marketing/hovercard-diamond.png'
import photo1 from '../../../custom_assets/marketing/research_spend/photo_1.png'
import photo2 from '../../../custom_assets/marketing/research_spend/photo_2.png'
import photo3 from '../../../custom_assets/marketing/research_spend/photo_3.png'
import photo4 from '../../../custom_assets/marketing/research_spend/photo_4.png'

import mobilePhoto1a from '../../../custom_assets/marketing/research_spend/mobile/photo_1a.png'
import mobilePhoto1b from '../../../custom_assets/marketing/research_spend/mobile/photo_1b.png'

const MarketingRDSpend = ({ user, clientSecret, setClientSecret, setUpgradeUserGroupAccessCode, setSelectedTab, setNavigateTo }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const { handleGAEventPurchaseModalPurchase } = CollegeReactGAHandlers()
  const { handleECGAEventPurchaseModalPurchase } = ECReactGAHandlers()

  const navigate = useNavigate()
  const location = useLocation()

  const [nameOptions, setNameOptions] = useState<any>([])
  const [selectedCollege, setSelectedCollege] = useState<any>(undefined);
  const [collegeData, setCollegeData] = useState<any>(undefined)
  const [rndAnnual, setRndAnnual] = useState<any>(undefined)
  const [premiumContentPrice, setPremiumContentPrice] = useState<any>(undefined)

  const handleCollegeSelectChange = (selectedOption) => {
    selectedOption ? navigate(pageRoutes.premiumRDSpendWithId(selectedOption.hyphenated)) : navigate(pageRoutes.premiumRDSpend)
  };

  const getSelectedCollegeData = async (selectedCollege) => {
    let college: any = undefined
    try {
      let res = await axios.get(`${URL}/colleges/${selectedCollege?.value}`)
      college = res.data
      setCollegeData(res.data)
    } catch (error) {
      console.log('Could not find college info:', error)
      throw error
    }

    try {
      let rndArr: any[] = []
      if (college && college.rnd_annual) {
        college.rnd_annual.forEach((res, index) => {
          if (index < 3) {
            rndArr.push(res);
            setRndAnnual(rndArr)
          }
        })
      };
    } catch (error) {
      console.log('Error generating the rndAnnual data for college')
    }
  }

  const getPrice = async () => {
    let price = await getStripePrice(rdSpendPriceID);
    price ? setPremiumContentPrice(price) : setPremiumContentPrice(undefined)
  }

  useEffect(() => {
    let names: any = []
    collegeMap.forEach((college) => {
      names.push({ value: college.id, label: college.name, hyphenated: college.hyphenated })
    })
    names = names.sort((a, b) => a.label.localeCompare(b.label))
    setNameOptions(names)

    getPrice()
  }, [])

  useEffect(() => {

    let preselectedCollege: string | undefined = undefined
    preselectedCollege = location.pathname.split(`/`)[1]
    preselectedCollege = preselectedCollege.split(`-research-spend-report`)[0]

    if (preselectedCollege) {
      setSelectedCollege(nameOptions.find(opt => opt.hyphenated == preselectedCollege))
    }

    else {
      setSelectedCollege(undefined)
    }

  }, [location.pathname, nameOptions]);

  useEffect(() => {

    if (selectedCollege) {
      getSelectedCollegeData(selectedCollege);
    }

    else {
      setCollegeData(undefined);
    }

  }, [selectedCollege])

  const faqData = [
    { question: 'How many colleges are covered in this report?', answer: <span>This report provides access to the research spend (broken out by 47 academic fields) for all of the top 100 U.S. colleges (according to NextFour's metarank). The full set of included colleges are listed in the <a href={pageRoutes.collegeSearch} className='dca-marketing-college-explorer-link' target="_blank">College Explorer</a>. Importantly, these 100 colleges are stack-ranked against one another (by individual field of research) so you can quickly see how they stack up in the subjects you're most interested in.</span> },
    { question: 'Where is this data sourced from?', answer: 'The raw data feeding into this report is sourced from a survey of higher-education institutional research spending sponsored by the National Science Foundation (NSF). Building upon that authorative foundation, NextFour has compiled and analyzed the data to provide exclusive access to relative rankings and actionalbe insights via an intuitive, easy-to-use display.  ' },
    { question: `Why isn't this report free?`, answer: 'While nearly all of the content, tools and services on NextFour are made freely available, this report is offered as a premium service (with a small fee) because of the significant investment required by NextFour to complete the proprietary analyses underlying the report.' }
  ]

  const testimonialData = [
    { statement: `“There is a lot of noise out there about which colleges are strong in which fields, and therefore hard to make much sense of it. On the other hand this data is very objective, providing a quantified and reliable way to measure the relative strength of research programs by college.”`, source: 'Jasprit', relation: 'Parent of high school junior' },
    { statement: `“I'm planning to major in biomedical engineering and would love to get hands-on research experience as an undergrad. So I used these rankings to help decide which colleges I should be considering to maximize opportunities in my field.”`, source: 'Kevin', relation: 'High school student' }
  ]

  const marketingContent = (
    <>
      <div className="dca-marketing-page-headline">The best way to understand colleges' research spend</div>

      <div className="dca-marketing-sub-headline">Get exclusive access to college research and development (R&D) spend insights which indicate the overall strength of research by field, and signal academic prestige and opportunities for students to participate in research.</div>

      {/* Use Bootstrap d-md-block to render while 768px or above */}
      <div className='d-none d-md-block'>
        <MarketingImageWrapper
          altText={'photoResearchSpend'}
          photo={photo1} />
      </div>

      {/* Mobile Replacement, only shows at below 768px */}
      <div className='d-block d-md-none'>
        <MarketingImageWrapper
          altText={'photoResearchSpend'}
          photo={mobilePhoto1a} />

        <div style={{ marginTop: '15px' }}></div>

        <MarketingImageWrapper
          altText={'photoResearchSpend'}
          photo={mobilePhoto1b} />
      </div>

      {premiumContentPrice && <PurchaseButton
        user={user}
        priceID={rdSpendPriceID}
        groupAccessCode={rdSpendAccessCode}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        premiumProductName={'Research Spend'}
        premiumContentPrice={premiumContentPrice}
        topMargin={'50px'}
        bottomMargin={'140px'}
        page={'RD Spend'}
        clickLocation={'Middle'}
        activityID={undefined}
        setNavigateTo={setNavigateTo}
      />}

      <div className='dca-marketing-text-image-block' id='TIB-150'>
        <div className="dca-marketing-pt-wrapper">
          <div className="dca-marketing-pt-header">Discover the research strengths for your schools of interest</div>
          <div className="dca-marketing-pt-body">Explore R&D annual spend figures for each of the top 100 US colleges, including aggregate R&D spend and breakdown by 47 academic fields. Get exclusive access to each school's ranking by field of research to intuititvely understand relative strengths and weaknesses.</div>
        </div>

        <MarketingImageWrapper
          altText={'photoResearchSpend'}
          photo={photo2} />
      </div>

      <div className='dca-marketing-text-image-block' id='TIB-150'>
        <div className="dca-marketing-pt-wrapper">
          <div className="dca-marketing-pt-header">Discover which colleges have the strongest research programs in your fields of interest</div>
          <div className="dca-marketing-pt-body">Access this exclusive feature illustrating how the top 100 colleges rank by R&D spend across each of 47 academic fields. Consider this information to help inform your college interest list.</div>
        </div>

        <MarketingImageWrapper
          altText={'photoResearchSpend'}
          photo={photo3}
          wrapper='B' />
      </div>

      <div className='dca-marketing-text-image-block' id='TIB-35'>
        <div className="dca-marketing-pt-wrapper">
          <div className="dca-marketing-pt-header">R&D data made easy to explore and intuitive to understand</div>
          <div className="dca-marketing-pt-body">We crunched the underlying spreadsheets so you don't have to! Benefit from having all the data consolidated into a single webpage for convenient access. Plus our exclusive color-coded display helps to quickly convey trends and key insights from the numerical data.</div>
        </div>

        <MarketingImageWrapper
          altText={'photoResearchSpend'}
          photo={photo4}
          wrapper='B' />
        <div className='dca-marketing-disclaimer-text'>Note: Images above are meant to be illustrative only and do not represent the actual data for any specific college.</div>
      </div>


      <FAQ faqData={faqData} />

      <ConnectEmailCTA />

      <Testimonials data={testimonialData} />

      {premiumContentPrice && <PurchaseButton
        user={user}
        priceID={rdSpendPriceID}
        groupAccessCode={rdSpendAccessCode}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        premiumProductName={'Research Spend'}
        premiumContentPrice={premiumContentPrice}
        topMargin={'50px'}
        bottomMargin={'140px'}
        page={'RD Spend'}
        clickLocation={'Bottom'}
        activityID={undefined}
        setNavigateTo={setNavigateTo}
      />}

      <HoverCards cardsOption='RDSpend' />
    </>
  )

  const accessContent = (rndAnnual && collegeData) ? (
    <div className='d-flex flex-column align-items-center'>
      <div className="dca-marketing-page-premium-content-label"><img src={diamond}></img> PREMIUM CONTENT</div>
      <div className="dca-marketing-page-premium-content-headline">Research Spend</div>
      <CollegeSelectComponent options={nameOptions}
        value={selectedCollege}
        onChange={handleCollegeSelectChange} />
      <div style={{ width: '90vw' }}
      >
        <div className='dca-marketing-page-college-logo-and-name'>
          <img
            alt="collegeLogo"
            className='dca-marketing-page-college-logo-img'
            src={collegeLogos[selectedCollege?.value]} />
          {selectedCollege ? selectedCollege?.label : 'N/A'}
        </div>
      </div>
      <RnDSpend college={collegeData} rndAnnual={rndAnnual} collegeListLength={collegeMap.length} setSelectedTab={setSelectedTab} pageView={true} />

      <div className='dca-marketing-college-hover-cards-spacer'></div>

      <HoverCards cardsOption='RDSpend' hyphenated={selectedCollege?.hyphenated} />
    </div>
  ) : (
    <div className='d-flex flex-column align-items-center'>
      <div className="dca-marketing-page-premium-content-label"><img src={diamond}></img> PREMIUM CONTENT</div>
      <div className="dca-marketing-page-premium-content-headline">Research Spend</div>
      <div className='d-flex justify-content-center'><CollegeSelectComponent options={nameOptions}
        value={selectedCollege}
        onChange={handleCollegeSelectChange} />
      </div>

      <div className='dca-marketing-page-no-select-text'>Select a college to display its R&D Spend Report</div>
    </div>
  )

  return permissionGroups?.includes(rdSpendAccessCode) ? (
    accessContent
  ) : (
    <div className="d-flex flex-column align-items-center">
      <StickyNavbar user={user} purchaseButton={premiumContentPrice && <PurchaseButtonNav
        user={user}
        priceID={rdSpendPriceID}
        groupAccessCode={rdSpendAccessCode}
        clientSecret={clientSecret}
        setClientSecret={setClientSecret}
        setUpgradeUserGroupAccessCode={setUpgradeUserGroupAccessCode}
        premiumProductName={'Research Spend'}
        premiumContentPrice={premiumContentPrice}
        page={'RD Spend'}
        clickLocation={'Header'}
        activityID={undefined}
        setNavigateTo={setNavigateTo}
      />} />
      {marketingContent}
    </div>
  )
}
export { MarketingRDSpend }