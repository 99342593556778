import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getUserByToken, registerUser, createNewUserProfile, getUserProfile, login } from '../../modules/auth/core/_requests'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../_metronic/assets/ts/components'
import { useAuth } from '../../modules/auth/core/Auth'
import { URL, closeModalIcon, closeModalFunction, specializedRankingsAccessCode, rdSpendAccessCode, safetyAccessCode, pageRoutes } from '../../components/dictionary'
import axios from 'axios'
import { AuthReactGAHandlers } from '../ReactGAHandlers'
import { GoogleAuthButton } from './GoogleAuthButton'
import { collegeMap } from '../collegeMap'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password1: '',
  password2: '',
  acceptTerms: false,
}

const checkEmailAvailability = async (email) => {

  let res = await axios.get(`${URL}/user_emails/`)

  let emailFound = res.data.find((item) => item.email == email)

  return emailFound ? false : true

}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required('First name is required'),
  lastname: Yup.string()
    .required('Last name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
    .test('email', 'This email has already been registered, please proceed to Sign In instead.', async function (email) {
      try {
        // const emailAvailable = await checkEmailAvailability(email);
        // console.log('check email avail-', await checkEmailAvailability(email));
        return await checkEmailAvailability(email);
      } catch (error) {
        console.log('Error checking for email availability', error)
        return false
      }
    }),
  password1: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .required('Password is required'),
  password2: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .required('Password is required')
    .oneOf([Yup.ref('password1')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export const SignUpModal = ({ prompt, navigateTo, setPrompt, setNavigateTo, setCollegeDetailSelectedTab }: { prompt: string; navigateTo: string, setPrompt: Function, setNavigateTo: Function, setCollegeDetailSelectedTab: Function }) => {

  const modalId = 'signup'
  const location = useLocation();
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser, requestUser } = useAuth()
  const [eye, setEye] = useState(false)
  const navigate = useNavigate()

  const {
    handleGAEventAlreadyHaveAccount, handleGAEventSuccessfulRegistration
  } = AuthReactGAHandlers()

  const eyeTrue = (<i className="bi bi-eye"></i>)
  const eyeFalse = (<i className="bi bi-eye-slash"></i>)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setStatus(null);

      try {

        await registerUser(values.email, values.password1, values.password2);

        try {

          const { data: authToken } = await login(values.email, values.password1);
          // console.log('Login successful');

          handleGAEventSuccessfulRegistration();

          const { data: user } = await getUserByToken(authToken.key);
          localStorage.setItem('apiToken', authToken.key);
          // console.log('User retrieved: ', user);

          await createNewUserProfile(user.pk, values.firstname, values.lastname, values.email);

          const { data: userprofile } = await getUserProfile(user.pk, authToken.key);
          // console.log('UserProfile: ', userprofile);

          setCurrentUser(userprofile);

          let path = location.pathname.split('/')[1]
          const groupIds = user.groups.map((group) => group.id);
          const groupNames = user.groups.map((group) => group.name);

          saveAuth({ api_token: authToken.key, groups: groupIds });
          requestUser(authToken.key);

          if (prompt !== '') {
            setPrompt('');
          }

          if (collegeMap.some(collegeObj => path === collegeObj.hyphenated)) {
            const restrictedTabOptions = ['Specialized Rankings', 'R&D Spend', 'Crime & Safety'];

            switch (true) {
              case navigateTo === restrictedTabOptions[0] && groupNames.includes(specializedRankingsAccessCode):
                setCollegeDetailSelectedTab(navigateTo);
                break;
              case navigateTo === restrictedTabOptions[1] && groupNames.includes(rdSpendAccessCode):
                setCollegeDetailSelectedTab(navigateTo);
                break;
              case navigateTo === restrictedTabOptions[2] && groupNames.includes(safetyAccessCode):
                setCollegeDetailSelectedTab(navigateTo);
                break;
              default:
                setCollegeDetailSelectedTab('Admission Info');
            }
          }

          closeModalFunction(modalId);

          if (navigateTo !== '' && !(collegeMap.some(collegeObj => path === collegeObj.hyphenated))) {
            navigate(navigateTo);
          }
        } catch (loginError) {
          console.error(loginError);
          saveAuth(undefined);
          setStatus('An error occurred during login');
          setSubmitting(false);
        }

      } catch (registerError) {
        console.error(registerError);
        setStatus('An error occurred during registration');
        setSubmitting(false);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  useEffect(() => {
    const handleModalHidden = () => {
      formik.resetForm();
    };

    const modalElement = document.getElementById(`${modalId}_modal`);
    if (modalElement) {
      modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
      }
    };
  }, [formik]);

  const signUpForm = (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      // id='signup_modal'
      id={'signup_modal_form_testModal'}
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {prompt != '' ? <div
        style={{
          marginTop: '15px',
          marginBottom: '25px',
          color: 'rgb(192, 0, 0)'
        }}>{prompt}</div> : <></>}

      <div className='row g-3 mb-9 justify-content-center'>
        <div className='col-md-6'>
          {/* <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a> */}
          <GoogleAuthButton
            modalId={modalId}
            prompt={prompt}
            setPrompt={setPrompt}
            navigateTo={navigateTo}
            setCollegeDetailSelectedTab={setCollegeDetailSelectedTab}
            signup={true}
            buttonTextSignUp={true}
          />
        </div>
        {/* <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        </div> */}
      </div>

      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div>

      <div className='fv-row mb-8'>
        <input
          placeholder='First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <input
          placeholder='Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lastname && formik.errors.lastname,
            },
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname,
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password1')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password1 && formik.errors.password1,
                },
                {
                  'is-valid': formik.touched.password1 && !formik.errors.password1,
                }
              )}
            />
            {formik.touched.password1 && formik.errors.password1 && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password1}</span>
                </div>
              </div>
            )}
          </div>

          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>

        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters and at least one number.
        </div>
      </div>

      <div className='fv-row mb-5'>

        <input
          type='password'
          placeholder='Repeat Password'
          autoComplete='off'
          {...formik.getFieldProps('password2')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password2 && formik.errors.password2,
            },
            {
              'is-valid': formik.touched.password2 && !formik.errors.password2,
            }
          )}
        />
        {formik.touched.password2 && formik.errors.password2 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password2}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            checked={formik.values.acceptTerms}
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I accept the{' '}
            <a className="dca-card-item-text" href={pageRoutes.termsOfService} target="_blank" onClick={(e) => e.stopPropagation()}>Terms of Service</a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg w-100 mb-5'
          style={{ background: '#DAF8B9' }}
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='signin_signup-submit-bttn-text'>Sign Up</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
        </Link>
      </div>

      <div className='d-flex flex-row justify-content-center text-gray-500 text-center fw-semibold fs-6'>
        Already have an Account?&nbsp;

        <div
          className='link-primary'
          data-bs-target="#login_modal"
          data-bs-toggle="modal"
          onClick={() => handleGAEventAlreadyHaveAccount()}
          style={{ cursor: 'pointer' }}
        >Sign in</div>

      </div>
    </form>
  )

  return (
    <div className="modal fade" id={`${modalId}_modal`}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{
          minWidth: '75vw',
          maxWidth: '75vw',
          padding: '60px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          maxHeight: '85vh',
          transform: 'translate(-50%, -50%)',
          overflowY: 'auto'
        }}>
          {closeModalIcon(modalId)}

          {signUpForm}
        </div>
      </div>
    </div>
  )
}