import { useNavigate } from "react-router-dom";
import avatar from "../../../custom_assets/avatar.png"
// import * as dictionary from '../dictionary'
import { advisorJPGs, advisorConnectAccessCode } from "../dictionary";
import { ConnectButtonInCardNoAuth } from "../SaveButton";
import { getAuth } from "../../modules/auth";
import { LoadingSpinner } from "../LoadingSpinner";
import { AdvisorsReactGAHandlers } from "../ReactGAHandlers";

//Testing User image
import { toAbsoluteUrl } from "../../../_metronic/helpers";

const Card = ({ user, results, sortKey, setSortKey, sortOrder, setSortOrder, collegesId, majors, setPrompt, setNavigateTo, setPurchaseID, setAdvisorID, collegeMap, filtersActive }) => {

  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  let navigate = useNavigate();
  const routeChange = (id) => {
    navigate(`/advisor-detail/${id}`)
  }

  const {
    handleGAEventPurchaseModalOpen,
    handleAdvisorGAEventCardConnectClick } = AdvisorsReactGAHandlers();

  // if (!majors || !collegesId || !results || majors.length == 0) {
  //   return <LoadingSpinner title={'Advisors'} />;
  // }

  if (!majors || !collegeMap || !results || majors.length == 0) {
    return <LoadingSpinner title={'Advisors'} />;
  }

  const sortKeys = [{ 'Date Joined': 'joinDate' }, { 'College': 'college' }]

  const handleSortKeyChange = (event) => {
    const selectedKey = event.target.value
    setSortKey(selectedKey)
  }

  const sortKeyReturn = (sortKey) => {
    switch (sortKey) {
      case 'joinDate':
        return 'Date Joined'
      case 'college':
        return 'College'
      default:
        return 'Date Joined'
    }
  }

  const handleSortOrderChange = () => {
    sortOrder === 'Ascending' ? setSortOrder('Descending') : setSortOrder('Ascending')
  }

  const collegeReturn = (id, arr) => {

    if (!id || !arr) {
      return '-'
    }

    const college = arr.find((item) => item["id"] === `${id}`);

    return college ? college.name : '-';
  }

  const majorReturn = (id, arr, res) => {

    if (!id || !arr) {
      return (res && res.majors_text) ? res.majors_text : '-'
    }

    let majorRes: any = []

    id.map((majorId, index) => {

      const major = arr.find((item) => item.id == majorId)

      if (index === id.length - 1) {
        majorRes.push(`${major.name}`)
      }

      else {
        majorRes.push(`${major.name}; `)
      }

    })

    return majorRes
  }

  const dateReturn = (input) => {
    if (!input) {
      return '-'
    }

    const date = new Date(input)

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    let year = date.getFullYear();
    let month = months[date.getMonth()]

    return `${month} ${year}`
  }

  const customSmallerCardIcon = (
    <div className="d-flex flex-row" style={{ width: '10px', height: '10px' }}>
      <div id="cardIconRow1">
        <i className="bi bi-square" style={{ width: '40%' }}></i>
        <i className="bi bi-square" style={{ width: '40%' }}></i>
      </div>
      <div id="cardIconRow2">
        <i className="bi bi-square" style={{ width: '40%' }}></i>
        +
      </div>
    </div>
  )

  const returnFiltersActiveCountText = (filtersActive) => {

  }

  return (
    <div style={{ width: '1129px', maxWidth: '1129px', marginLeft: '40px', marginRight: '40px' }}>

      <div className="d-flex flex-row justify-content-between align-items-end">
        <div className="d-flex flex-row">
          <div
            className="me-3 align-bottom dca-card-items-found-text">Displaying {results.length} advisor{results?.length == 1 ? '' : 's'} {filtersActive > 0 && `matching active filter${filtersActive == 1 ? '' : 's'}`}</div>
          <p
            onClick={handleSortOrderChange}
            style={{ cursor: 'pointer', fontSize: '15px', lineHeight: '18px', color: '#585555', marginBottom: '2px' }}
            className='text-muted fs-7 fw-bolder align-self-end'
          >by {sortOrder} {sortKeyReturn(sortKey)} {sortOrder === 'Ascending' && (<i className="bi bi-arrow-up"></i>)}
            {sortOrder === 'Descending' && (<i className="bi bi-arrow-down"></i>)}
          </p>
        </div>

        <div className="d-flex flex-row">
          {/* {customSmallerCardIcon} */}
          <div></div>

          <select
            className='form-select'
            data-control="select2"
            value={sortKey}
            onChange={handleSortKeyChange}
          >
            {sortKeys.map((res, id) => (
              <option key={id} value={Object.values(res)}>{Object.keys(res)}</option>
            ))}
          </select>
        </div>
      </div>
      {/* END EC CARD HEADER */}

      <div className="d-flex flex-wrap justify-content-between">{results.map((res) => (

        <div
          key={res.id}
          className="dca-advisor-card hover-elevate-up"
          onClick={() => routeChange(res.id)}
        >

          <div className="dca-advisor-search-card-image-wrapper">

            <img
              src={advisorJPGs[res.id] != null ? advisorJPGs[res.id] as string : avatar}
              alt='avatar' className="dca-advisor-search-card-image" />

          </div>

          {apiToken != undefined && user?.emailAddress ? <button
            type="button"
            className="btn btn-sm dca-connect-button-card"
            data-bs-toggle="modal"
            data-bs-target="#purchase_modal"
            onClick={(e) => {
              e.stopPropagation();
              setPurchaseID(advisorConnectAccessCode)
              setAdvisorID(res.id)
              handleGAEventPurchaseModalOpen(res.id);
              handleAdvisorGAEventCardConnectClick(res.id)
            }}
          >
            Connect
          </button> : <ConnectButtonInCardNoAuth setPrompt={setPrompt} setNavigateTo={setNavigateTo} biLock={false} advisorID={res.id} />}

          <div className="d-flex justify-content-center">
            <div className="mt-5 dca-advisor-search-card-nameField">{res.firstName}</div>
          </div>

          {/* INNER CARD CONTENT */}
          <div style={{ margin: '45px 0px 63px 63px' }}>
            <div className="d-flex flex-row justify-content-evenly">
              <div className="d-flex flex-column" style={{ width: '40%' }}>
                <div className="dca-card-item-header">COLLEGE</div>
                <div className="dca-card-item-text">{collegeReturn(res.college_id, collegeMap)}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '40%' }}>
                <div className="dca-card-item-header">CLASS</div>
                <div className="dca-card-item-text">{res.collegeGradYear}</div>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-evenly">
              <div className="d-flex flex-column" style={{ width: '40%' }}>
                <div className="dca-card-item-header" style={{ marginTop: '15px' }}>MAJOR(S)</div>
                <div className="dca-card-item-text">{majorReturn(res.major_id, majors, res)}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '40%' }}>
                <div className="dca-card-item-header align-text-start" style={{ marginTop: '15px' }}>JOINED</div>
                <div className="dca-card-item-text">{dateReturn(res.joinDate)}</div>
              </div>
            </div>
          </div>

          {/* <div className="d-flex flex-row" style={{ margin: '45px 0px 63px 63px' }}>

            <div className="d-flex flex-column" style={{ width: '45%', height: 'fit-content' }}>
              <div className="d-flex flex-column" style={{ height: '50%' }}>
                <div className="dca-card-item-header">COLLEGE</div>
                <div className="dca-card-item-text">{collegeReturn(res.college_id, collegeMap)}</div>
              </div>

              <div className="d-flex flex-column" style={{ height: '50%' }}>
                <div className="dca-card-item-header">MAJOR(S)</div>
                <div className="dca-card-item-text">{majorReturn(res.major_id, majors, res)}</div>
              </div>
            </div>

            <div className="d-flex flex-column" style={{ width: '45%', height: 'fit-content', marginLeft: '30px' }}>

              <div className="d-flex flex-column" style={{ height: '75px' }}>
                <div className="dca-card-item-header">CLASS</div>
                <div className="dca-card-item-text">{res.collegeGradYear}</div>
              </div>

              <div className="d-flex flex-column">
                <div className="dca-card-item-header">JOINED</div>
                <div className="dca-card-item-text">{dateReturn(res.joinDate)}</div>
              </div>

            </div>
          </div> */}


          {/* END INNER CARD CONTENT */}

        </div>
      ))}

      </div>
    </div>
  )
}

export { Card }
