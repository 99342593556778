import { useState, useEffect } from 'react'
import axios from 'axios'
import * as dictionary from '../components/dictionary'
import avatar from "../../custom_assets/avatar.png"
import { TabContent } from '../components/UserProfile/TabContent'
import { getUserByToken, getUserProfile } from '../modules/auth/core/_requests'
import { getAuth, useAuth } from '../modules/auth'
import { handleUpdateUserTransactions } from './Auth/UpdateUserTransaction'
import { UserProfileReactGAHandlers } from '../components/ReactGAHandlers'

const handleUpdateUserData = (user, formValues, setUserData) => {

  const patchRequest = async () => {
    const res = await axios.patch(`${dictionary.URL}/user_profiles/${user.user_id}`, formValues)
    setUserData(res.data)
    console.log('Patch successful', res.data)
  }

  try {
    patchRequest();

  } catch (error) {
    console.log(error);
    throw (error)
  }

}

const handleUpdateUserData2 = (user, formValues) => {

  const patchRequest = async () => {
    const res = await axios.patch(`${dictionary.URL}/user_profiles/${user.user_id}`, formValues)
    console.log('Patch successful', res.data)
  }

  try {
    patchRequest();

  } catch (error) {
    console.log(error);
    throw (error)
  }

}

const handleUpdateUserProfile = async (setUser, apiToken) => {

  const { data: user } = await getUserByToken(apiToken)
  console.log('Django User:', user)

  const { data: userprofile } = await getUserProfile(user.pk, apiToken)
  setUser(userprofile)
  console.log('Current User:', userprofile)
}

const UserProfile = ({ user, setUser, userCollegeInterest, setUserCollegeInterest, setPrompt, setNavigateTo, setCollegeDetailSelectedTab }) => {

  const { handleGAEventTabClick } = UserProfileReactGAHandlers()

  const { currentUser } = useAuth()
  const [collegesId, setCollegesID] = useState<any>([])
  const [userCollegelist, setUserCollegelist] = useState<any>([])
  const [ecActivities, setECActivities] = useState<any>([])
  const [majors, setMajors] = useState<any>([])
  const [loadingComplete, setLoadingComplete] = useState(false)
  const [userData, setUserData] = useState<any>({})
  const [socialAuthUser, setSocialAuthUser] = useState(true)

  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  const tabOptions = ['Profile Info', 'Colleges of Interest', 'Majors of Interest', 'EC Activities of Interest', 'Account Management']
  const [selectedTab, setSelectedTab] = useState(tabOptions[0])

  const handleSelectedTab = (val) => {
    setSelectedTab(val);
    handleGAEventTabClick(val)
  }

  useEffect(() => {

    dictionary.updateTitleAndDescription()

    if (getAuth()?.api_token && getAuth()?.groups) {
      apiToken = getAuth()?.api_token
    }

    const fetchData = async () => {

      try {
        if (loadingComplete === true) {
          setLoadingComplete(false)
        }

        const userDataAxios = axios.get(`${dictionary.URL}/user_profiles/${currentUser?.user_id}`)
        const userCollegeInterestAxios = axios.get(`${dictionary.URL}/user_college_interest`, {
          headers: {
            'Authorization': `Token ${apiToken}`
          }
        })
        const collegesNameIDAxios = axios.get(`${dictionary.URL}/colleges-id-name/`)
        const ecActivitiesAxios = axios.get(`${dictionary.URL}/ec_activity/`)
        const majorsAxios = axios.get(`${dictionary.URL}/majors/`);

        const [userDataRes, userCollegeInterestRes, collegesNameIDRes, ecActivitiesRes, majorsRes] = await Promise.all([
          userDataAxios,
          userCollegeInterestAxios,
          collegesNameIDAxios,
          ecActivitiesAxios,
          majorsAxios
        ])

        // userDataRes
        console.log('user data from call:', userDataRes.data)

        let userCollegeInterestSet = userCollegeInterestRes.data.filter((item) => item.user_profile_id == user?.user_id)

        await Promise.all([
          setUserData(userDataRes.data),
          setUserCollegelist(userCollegeInterestSet),
          setCollegesID(collegesNameIDRes.data),
          setECActivities(ecActivitiesRes.data),
          setMajors(majorsRes.data)
        ])

        setLoadingComplete(true)

      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()

    const getUserData = async (apiToken) => {
      const { data: user } = await getUserByToken(apiToken)
      console.log('Django User:', user)

      if (user.contains_password) {
        setSocialAuthUser(false)
      }
    }

    getUserData(apiToken)

  }, [])

  return loadingComplete ? (
    <div className='me-5' style={{ marginLeft: '35px' }}>

      <div className='card shadow d-flex flex-column' style={{ width: '100%', marginTop: '30px' }}>

        <div className='d-flex flex-row'>
          <img src={avatar} alt='avatar' style={{ height: '150px', padding: '30px', borderRadius: '40px' }} />

          <div className='d-flex flex-column' style={{ marginTop: '30px' }}>
            <p style={{ fontSize: '20px' }}> {user?.firstName} {user?.lastName}</p>
          </div>
        </div>

        <div className='d-flex flex-row' style={{ marginLeft: '30px' }}>

          {tabOptions.map((tab, index) => (
            <div
              key={tab}
              // className={`d-flex flex-column rounded rounded-3 justify-content-center align-items-center dca-userProfile-tabs ${selectedTab === tabOptions[index] ? 'fw-bold' : ''}`}
              // style={{
              //   marginLeft: 0 === index ? '' : '10px'
              // }}

              className={`d-flex justify-content-center align-items-center dca-userProfile-tabs ${selectedTab === tabOptions[index] ? 'fw-bold' : ''}`}
              style={{
                backgroundColor: selectedTab === tabOptions[index] ? dictionary.selectedTabGreen : dictionary.unselectedTabGray,
                marginLeft: 0 === index ? '' : '10px'
              }}
              onClick={() => handleSelectedTab(tabOptions[index])}
            >

              <div className='text-nowrap' style={{ padding: '10px' }}>
                {tab}
              </div>
              <div
                style={{
                  color: selectedTab === tabOptions[index] ? '#98F29B' : '',
                  borderBottom: selectedTab === tabOptions[index] ? '3px solid' : '',
                  width: '80%',

                }}></div>
            </div>))}

        </div>



      </div>

      <TabContent
        selectedTab={selectedTab}
        tabOptions={tabOptions}
        userData={userData}
        user={user}
        setUser={setUser}
        apiToken={apiToken}
        userCollegeInterest={userCollegeInterest}
        setUserCollegeInterest={setUserCollegeInterest}
        collegesId={collegesId}
        ecActivities={ecActivities}
        majors={majors}
        setUserData={setUserData}
        setCollegeDetailSelectedTab={setCollegeDetailSelectedTab}
        socialAuthUser={socialAuthUser} />

    </div>
  ) : (
    <div className='me-5' style={{ marginLeft: '35px' }}>

      <div className='card shadow d-flex flex-column' style={{ width: '100%', marginTop: '30px' }}>

        <div className='d-flex flex-row'>
          <img src={avatar} alt='avatar' style={{ height: '150px', padding: '30px', borderRadius: '40px' }} />

          <div className='d-flex flex-column' style={{ marginTop: '30px' }}>
            <p style={{ fontSize: '20px' }}> {user?.firstName} {user?.lastName}</p>
          </div>
        </div>

        <div className='d-flex flex-row' style={{ marginLeft: '30px' }}>

          {tabOptions.map((tab, index) => (
            <div
              key={tab}
              // className="d-flex flex-column rounded rounded-3 fw-bold justify-content-center align-items-center"
              // style={{
              //   color: selectedTab === tabOptions[index] ? '#98F29B' : '',
              //   height: '30px',
              //   cursor: 'pointer',
              //   borderRadius: '50%',
              //   display: 'inline-block',
              //   marginLeft: 0 === index ? '' : '10px',
              //   marginBottom: '5px'
              // }}
              className={`d-flex justify-content-center align-items-center dca-userProfile-tabs ${selectedTab === tabOptions[index] ? 'fw-bold' : ''}`}
              style={{
                backgroundColor: selectedTab === tabOptions[index] ? dictionary.selectedTabGreen : dictionary.unselectedTabGray,
                marginLeft: 0 === index ? '' : '10px'
              }}
              onClick={() => handleSelectedTab(tabOptions[index])}
            >
              <div className='text-nowrap' style={{ padding: '10px' }}>
                {tab}
              </div>
              <div
                style={{
                  color: selectedTab === tabOptions[index] ? '#98F29B' : '',
                  borderBottom: selectedTab === tabOptions[index] ? '3px solid' : '',
                  width: '80%',

                }}></div>
            </div>))}

        </div>
      </div>

    </div>
  )
}

export { UserProfile, handleUpdateUserData, handleUpdateUserData2, handleUpdateUserProfile }