import React from 'react'
import diamond from '../../../custom_assets/marketing/hovercard-diamond.png'
import { useNavigate } from 'react-router'
import { pageRoutes } from '../dictionary'

interface HoverCardsProps {
  cardsOption: string,
  hyphenated?: string,
  ecInsightTrue?: boolean
}

const HoverCards: React.FC<HoverCardsProps> = ({ cardsOption, hyphenated = undefined, ecInsightTrue = false }) => {

  const navigate = useNavigate()

  const returnCard = (header: string, body: string, navigateTo: string) => {
    return (
      <div
        className="dca-marketing-hover-card"
        onClick={() => {
          navigate(navigateTo)
        }}>

        <div className='dca-marketing-hover-card-inner-wrapper'>
          <img src={diamond} className="dca-marketing-hover-card-diamond"></img>

          <div className="dca-marketing-hover-card-header">{header}</div>

          <div className="dca-marketing-hover-card-body">{body}&nbsp;&nbsp;<i className="fa-solid fa-arrow-right" style={{ color: 'black', fontSize: '16px' }}></i></div>

        </div>

      </div>
    )
  }

  const specializedRankingCard = returnCard('SPECIALIZED RANKINGS', 'Exclusive college rankings based on tangible success metrics', hyphenated ? pageRoutes.premiumSpecializedRankingsWithId(hyphenated) : pageRoutes.premiumSpecializedRankings)

  const rdSpendCard = returnCard('R&D SPEND', `The best way to understand colleges' research spend`, hyphenated ? pageRoutes.premiumRDSpendWithId(hyphenated) : pageRoutes.premiumRDSpend)

  const crimeSafetyCard = returnCard('CRIME & SAFETY', 'The only place to easily understand comprehensive college safety data', hyphenated ? pageRoutes.premiumCrimeSafetyWithId(hyphenated) : pageRoutes.premiumCrimeSafety)

  const ecInsightCard = returnCard('EXTRACURRICULAR INSIGHTS', 'Be strategic in planning your extracurriculars', pageRoutes.ecPremiumInsightsBase)

  const ECInsights = (
    <div className="dca-marketing-hover-cards-wrapper">
      {specializedRankingCard}

      {rdSpendCard}

      {crimeSafetyCard}
    </div>
  )

  const CrimeSafety = (
    <div className="dca-marketing-hover-cards-wrapper">
      {ecInsightCard}

      {rdSpendCard}

      {specializedRankingCard}
    </div>
  )

  const RDSpend = (
    <div className="dca-marketing-hover-cards-wrapper">
      {ecInsightCard}

      {specializedRankingCard}

      {crimeSafetyCard}
    </div>
  )

  const SpecializedRankings = (
    <div className="dca-marketing-hover-cards-wrapper">
      {ecInsightCard}

      {rdSpendCard}

      {crimeSafetyCard}
    </div>
  )

  const returnCards = (cardsOption) => {
    switch (cardsOption) {
      case 'ECInsights':
        return ECInsights
      case 'CrimeSafety':
        return CrimeSafety
      case 'RDSpend':
        return RDSpend
      case 'SpecializedRankings':
        return SpecializedRankings
    }
  }

  return (
    <div className={ecInsightTrue ? 'dca-marketing-hover-cards-component-wrapper-ecInsightTrue' : "dca-marketing-hover-cards-component-wrapper"}>
      <div className="dca-marketing-hover-cards-title">Explore more premium data</div>

      {returnCards(cardsOption)}
    </div>
  )
}

export { HoverCards }