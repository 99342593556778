const FAQ = ({ faqData }) => {

  // Format
  // const faqData = [
  //   {question: '', answer: ''},
  //   ...
  // ]

  const returnQA = (question, answer) => {
    return (
      <div className="dca-marketing-faq-wrapper">
        <span className="dca-marketing-faq-question">{question}</span>
        <span className="dca-marketing-faq-answer">{answer}</span>
      </div>
    )
  }

  return (
    <div className="d-flex flex-column">
      {faqData.map((qa, index) => (
        <div key={index} style={{ marginBottom: index < faqData.length - 1 ? '46px' : '' }}>{returnQA(qa.question, qa.answer)}</div>
      ))}
    </div>
  );
}

export { FAQ }