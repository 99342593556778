import { useParams, useLocation, useNavigate, Link } from "react-router-dom"
import { Introduction } from "../components/Overview/Introduction"
import { CollegeAppWalkthrough } from "../components/Overview/CollegeAppWalkthrough"
import { AdviceForNavigatingAdmissions } from "../components/Overview/AdviceForNavigatingAdmissions"
import { useState, useEffect } from "react"
import { updateTitleAndDescription } from "../components/dictionary"

const OverviewPage = ({ setPrompt, setNavigateTo }) => {

  const navigate = useNavigate();
  const location = useLocation();
  let { hashPath } = useParams()
  const path = location.pathname;

  useEffect(() => {
    updateTitleAndDescription()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let urlPath = path
  let cA = '/college-application-walkthrough'
  let aA = '/admissions-advice'

  const paginationList = [
    '/introduction',
    '/college-application-walkthrough',
    `${cA}/academics`,
    `${cA}/standardized-test-scores`,
    `${cA}/extracurricular-activities`,
    `${cA}/honors-and-awards`,
    `${cA}/letters-of-recommendation`,
    `${cA}/personal-statement-and-supplemental-essays`,
    `${cA}/intended-field-of-study`,
    `/admissions-advice`,
    `${aA}/identifying-core-application-theme`,
    `${aA}/selecting-intended-major(s)`,
    `${aA}/crafting-an-extracurriculars-profile`,
    `${aA}/determining-a-standardized-testing-strategy`,
    `${aA}/maximizing-impact-from-letters-of-recommendation`,
    `${aA}/creating-a-college-interest-list`,
    `${aA}/applying-early-vs-regular`,
    `${aA}/overall-reference-timeline`
  ]

  const returnVerticalTabName = (tab) => {
    switch (true) {
      case tab == paginationList[0]:
        return 'Introduction'
      case tab == paginationList[1]:
        return 'College Application Walkthrough'
      case tab == paginationList[2]:
        return 'Academics'
      case tab == paginationList[3]:
        return 'Standardized Test Scores'
      case tab == paginationList[4]:
        return 'Extracurricular Activities'
      case tab == paginationList[5]:
        return 'Honors and Awards'
      case tab == paginationList[6]:
        return 'Letters of Recommendation'
      case tab == paginationList[7]:
        return 'Personal Statement and Supplemental Essays'
      case tab == paginationList[8]:
        return 'Intended Field of Study'
      case tab == paginationList[9]:
        return 'Advice For Navigating Admissions Process'
      case tab == paginationList[10]:
        return 'Identifying Core Application Theme'
      case tab == paginationList[11]:
        return 'Selecting Your Intended Major(s)'
      case tab == paginationList[12]:
        return 'Crafting an Extracurriculars Profile'
      case tab == paginationList[13]:
        return 'Determining a strategy for SAT/ACT standardized testing'
      case tab == paginationList[14]:
        return 'Maximizing Impact From Letters Of Recommendation'
      case tab == paginationList[15]:
        return 'Creating a College Interest List'
      case tab == paginationList[16]:
        return 'Should You Apply Early or Regular'
      case tab == paginationList[17]:
        return 'Overall Reference Timeline'
    }
  }

  const handlePreviousPagination = (urlPath) => {

    if (urlPath === paginationList[0]) {
      return null
    }

    else {
      navigate(`${paginationList[paginationList.indexOf(urlPath) - 1]}`)
    }

  }

  const handleNextPagination = (urlPath) => {

    if (urlPath === paginationList[paginationList.length - 1]) {
      return null
    }

    else {
      navigate(`${paginationList[paginationList.indexOf(urlPath) + 1]}`)
    }

  }

  const verticalTabs = (
    <div className="d-flex flex-column">
      {paginationList.map((tab, index) =>
        <div
          key={index}
          className={urlPath == tab ? "dca-overview-vertical-tab-active" : "dca-overview-vertical-tab"}
          onClick={() => {
            navigate(`${tab}`);
          }}
        >{['/introduction', '/college-application-walkthrough', '/admissions-advice'].includes(tab) ? <span className="dca-font-M">{returnVerticalTabName(tab)}</span> : returnVerticalTabName(tab)}</div>
      )}
    </div>
  )

  const verticalTabsForSidebar = (
    <div className="d-flex flex-column">
      {paginationList.map((tab, index) =>
        <div
          key={index}
          className={urlPath == tab ? "dca-overview-vertical-tab-active" : "dca-overview-vertical-tab"}
          onClick={() => {
            setOverviewSidebarActive(!overviewSidebarActive);
            navigate(`${tab}`);
          }}
        >{['/introduction', '/college-application-walkthrough', '/admissions-advice'].includes(tab) ? <span className="dca-font-M">{returnVerticalTabName(tab)}</span> : returnVerticalTabName(tab)}</div>
      )}
    </div>
  )

  const [overviewSidebarActive, setOverviewSidebarActive] = useState(false)
  const handleClickOffcanvas = () => {
    setOverviewSidebarActive(!overviewSidebarActive)
  }

  const sidebar = (
    <div className={`offcanvas offcanvas-start ${overviewSidebarActive ? 'show' : ''}`} tabIndex={-1} id="offcanvas" aria-labelledby="offcanvasLabel">
      <div className="offcanvas-header dca-header-sidebar-title">
        <div className="offcanvas-title dca-header-item" id="offcanvasLabel">OVERVIEW NAVIGATION</div>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => setOverviewSidebarActive(!overviewSidebarActive)}></button>
      </div>
      <div className="offcanvas-body">

        {verticalTabsForSidebar}

      </div>
    </div>
  )

  const pagination = (
    <ul className="pagination d-flex justify-content-center align-self-center" style={{ marginTop: '8vh' }}>
      <li className="page-item previous" style={{ cursor: 'pointer' }}>
        <span
          onClick={() => handlePreviousPagination(urlPath)}
          className="page-link">
          <i className='previous'></i>
        </span>
      </li>

      {/* {paginationList.map((res, index) =>
        <li
          key={index}
          className={`page-item ${urlPath === `${res}` ? 'active' : ''}`}
          style={{ cursor: 'pointer' }}
        >
          <span onClick={() => {
            navigate(`/home/overview/${res}`);
          }} className="page-link">
            {index + 1}
          </span>
        </li>
      )} */}

      <li className="page-item next" style={{ cursor: 'pointer' }}>
        <span
          onClick={() => handleNextPagination(urlPath)}
          className="page-link">
          <i className='next'></i>
        </span>
      </li>
    </ul>
  )

  const returnOverviewContent = (path) => {

    let pathRes = path
    if (pathRes.split('/')[1]) {
      pathRes = path.split('/')[1]
    }

    switch (true) {
      case pathRes === 'introduction':
        return <Introduction
          verticalTabs={verticalTabs}
          overviewSidebarActive={overviewSidebarActive}
          sidebar={sidebar}
          handleClickOffcanvas={handleClickOffcanvas}
          pagination={pagination}
        />
      case pathRes === 'college-application-walkthrough':
        return <CollegeAppWalkthrough
          hashPath={hashPath}
          verticalTabs={verticalTabs}
          overviewSidebarActive={overviewSidebarActive}
          sidebar={sidebar}
          handleClickOffcanvas={handleClickOffcanvas}
          pagination={pagination}
        />
      case pathRes === 'admissions-advice':
        return <AdviceForNavigatingAdmissions
          hashPath={hashPath}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          verticalTabs={verticalTabs}
          overviewSidebarActive={overviewSidebarActive}
          sidebar={sidebar}
          handleClickOffcanvas={handleClickOffcanvas}
          pagination={pagination}
        />
      default:
        return <Introduction
          verticalTabs={verticalTabs}
          overviewSidebarActive={overviewSidebarActive}
          sidebar={sidebar}
          handleClickOffcanvas={handleClickOffcanvas}
          pagination={pagination}
        />
    }

  }

  return (
    <div className="d-flex flex-column" style={{ marginTop: '4vh' }}>

      {returnOverviewContent(path)}

    </div>
  )
}

export { OverviewPage }