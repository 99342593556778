import { AuthReactGAHandlers, CollegeReactGAHandlers, MarketingReactGAHandlers } from "../ReactGAHandlers"
import { URL } from "../dictionary"

const PurchaseButton = (props) => {

  const {
    user,
    priceID,
    groupAccessCode,
    clientSecret,
    setClientSecret,
    setUpgradeUserGroupAccessCode,
    premiumProductName,
    premiumContentPrice,
    page,
    clickLocation,
    activityID,
    setNavigateTo
  } = props;

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()
  const { handleGAEventPurchaseModalPurchase } = CollegeReactGAHandlers()
  const { handleGAEventPurchaseButtonClick,
    handleGAEventECInsightsPurchaseClick
  } = MarketingReactGAHandlers()

  const handlePurchaseButtonGAEvent = (page, clickLocation, activityID) => {
    switch (page) {
      case 'Specialized Rankings':
        return handleGAEventPurchaseButtonClick('Specialized Rankings', clickLocation)
      case 'RD Spend':
        return handleGAEventPurchaseButtonClick('RD Spend', clickLocation)
      case 'Crime':
        return handleGAEventPurchaseButtonClick('Crime', clickLocation)
      case 'EC Insights':
        return handleGAEventECInsightsPurchaseClick(activityID, clickLocation)
    }
  }

  const guest = (
    <div className="d-flex flex-column">
      <a
        className="dca-marketing-access-duration-purchase-button"
        data-bs-toggle={"modal"}
        href={"#login_modal"}
        onClick={() => {
          page != 'EC Insights' && setNavigateTo('');

          handleGAEventSignInModalOpen('forced');
          handlePurchaseButtonGAEvent(page, clickLocation, activityID)
        }}
      ><span className="dca-marketing-access-duration-purchase-button-text">Purchase</span>
      </a>
      <div className="dca-marketing-access-duration-purchase-button-sign-in-text">Already purchased? <a
        className="dca-marketing-access-duration-purchase-button-sign-in-text-modal-trigger"
        data-bs-toggle="modal"
        href="#login_modal"
        onClick={(e) => {
          e.stopPropagation();
          handleGAEventSignInModalOpen('forced');
          handlePurchaseButtonGAEvent(page, clickLocation, activityID)
        }}>Sign In</a>
      </div>
    </div>
  )

  const auth = user?.validatedEmail ? (
    <button
      className='dca-marketing-access-duration-purchase-button'
      data-bs-target={`#add_payment_method_modal`}
      data-bs-toggle="modal"
      onClick={async () => {

        handlePurchaseButtonGAEvent(page, clickLocation, activityID);

        if (clientSecret != '') {
          await setClientSecret('')
        }

        await setUpgradeUserGroupAccessCode(groupAccessCode);

        handleGAEventPurchaseModalPurchase(premiumProductName);

        await fetch(`${URL}/create-checkout-session/${priceID}`, {
          method: "POST",
        }).then((res) => res.json())
          .then((data) => setClientSecret(data.clientSecret)).catch((error) => {
            console.error('Error:', error);
          });
      }}

    ><span className="dca-marketing-access-duration-purchase-button-text">Purchase</span></button>
  ) : (
    <button
      className='dca-marketing-access-duration-purchase-button'
      data-bs-target={`#email_verification_request_modal`}
      data-bs-toggle="modal"
      onClick={async () => {
        handlePurchaseButtonGAEvent(page, clickLocation, activityID);
      }}
    ><span className="dca-marketing-access-duration-purchase-button-text">Purchase</span></button>
  )

  return (
    <div className="dca-marketing-access-duration-and-purchase-button" style={{ marginTop: props.topMargin, marginBottom: props.bottomMargin }}>
      <div className="dca-marketing-access-duration-and-price-wrapper">
        <div className="dca-marketing-access-duration-text">1 YEAR ACCESS</div>
        <div className="dca-marketing-access-duration-price">{premiumContentPrice ? `$${premiumContentPrice}` : 'N/A'}</div>
      </div>

      <div className="dca-marketing-access-duration-and-purchase-button-spacer"></div>

      {user ? auth : guest}
    </div>
  )
}

const PurchaseButtonNav = (props) => {
  const {
    user,
    priceID,
    groupAccessCode,
    clientSecret,
    setClientSecret,
    setUpgradeUserGroupAccessCode,
    premiumProductName,
    page,
    clickLocation,
    activityID,
    setNavigateTo
  } = props;

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()
  const { handleGAEventPurchaseModalPurchase } = CollegeReactGAHandlers()
  const {
    handleGAEventPurchaseButtonClick,
    handleGAEventECInsightsPurchaseClick
  } = MarketingReactGAHandlers()

  const handlePurchaseButtonGAEvent = (page, clickLocation, activityID) => {
    switch (page) {
      case 'Specialized Rankings':
        return handleGAEventPurchaseButtonClick('Specialized Rankings', clickLocation)
      case 'RD Spend':
        return handleGAEventPurchaseButtonClick('RD Spend', clickLocation)
      case 'Crime':
        return handleGAEventPurchaseButtonClick('Crime', clickLocation)
      case 'EC Insights':
        return handleGAEventECInsightsPurchaseClick(activityID, clickLocation)
    }
  }

  const guest = (
    <a
      className="dca-marketing-access-duration-purchase-button-nav"
      data-bs-toggle={"modal"}
      href={"#login_modal"}
      onClick={() => {
        page != 'EC Insights' && setNavigateTo('');

        handleGAEventSignInModalOpen('forced');
        handlePurchaseButtonGAEvent(page, clickLocation, activityID)
      }}
    ><span className="dca-marketing-access-duration-purchase-button-nav-text">Purchase</span>
    </a>
  )

  const auth = user?.validatedEmail ? (
    <button
      className='dca-marketing-access-duration-purchase-button-nav'
      data-bs-target={`#add_payment_method_modal`}
      data-bs-toggle="modal"
      onClick={async () => {

        handlePurchaseButtonGAEvent(page, clickLocation, activityID);

        if (clientSecret != '') {
          await setClientSecret('')
        }

        await setUpgradeUserGroupAccessCode(groupAccessCode);

        handleGAEventPurchaseModalPurchase(premiumProductName);

        await fetch(`${URL}/create-checkout-session/${priceID}`, {
          method: "POST",
        }).then((res) => res.json())
          .then((data) => setClientSecret(data.clientSecret)).catch((error) => {
            console.error('Error:', error);
          });
      }}

    ><span className="dca-marketing-access-duration-purchase-button-nav-text">Purchase</span></button>
  ) : (
    <button
      className='dca-marketing-access-duration-purchase-button-nav'
      data-bs-target={`#email_verification_request_modal`}
      data-bs-toggle="modal"
      onClick={async () => {
        handlePurchaseButtonGAEvent(page, clickLocation, activityID);
      }}

    ><span className="dca-marketing-access-duration-purchase-button-nav-text">Purchase</span></button>
  )

  return (
    <div className="dca-marketing-access-duration-and-purchase-button-navbar">
      {user ? auth : guest}
    </div>
  )
}

export {
  PurchaseButton,
  PurchaseButtonNav
}