import Select from 'react-select'
import { titleSpace } from '../../dictionary'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import { AdvisorsReactGAHandlers } from '../../ReactGAHandlers'

const College = ({ advisors, setCollegeSelect, collegeMap }) => {

  const location = useLocation()
  const [selectedColleges, setSelectedColleges] = useState<any>([]);
  const [options, setOptions] = useState<any>([])

  const { handleAdvisorGAEventCollegeFilter } = AdvisorsReactGAHandlers()

  const handleCollegeSelectChange = (collegeSelect) => {
    setSelectedColleges(collegeSelect)
    setCollegeSelect(collegeSelect)
  }

  useEffect(() => {
    let optionsArr: any = []
    let advisorColleges: any = []
    if (advisors && collegeMap && collegeMap.length > 0) {
      advisors.map((advisor) => {
        if (!advisorColleges.includes(advisor.college_id)) {
          advisorColleges.push(advisor.college_id)
        }

        if (advisorColleges && advisorColleges?.length > 0) {
          advisorColleges.map((collegeID) => {
            if (!advisorColleges.includes(collegeID)) {
              advisorColleges.push(collegeID)
            }
          })
        }
      })

      collegeMap.map((res) => {
        if (advisorColleges.includes(parseInt(res.id))) {
          optionsArr.push({ value: res.id, label: res.name })
        }

        if (optionsArr.length > 1) {
          optionsArr = optionsArr.sort((a, b) => {
            return a.label.localeCompare(b.label)
          })
        }
      })
      setOptions(optionsArr)
    }
  }, [collegeMap, advisors])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let collegesParam = params.get('college');
    let res: any[] = []

    if (collegesParam) {
      const colleges = collegesParam.split(',')
      colleges.map((val) => {
        let collegeObj = options.find((obj => parseInt(obj.value) === (parseInt(val))))
        if (collegeObj) {
          res.push(collegeObj)
        }
      })
      handleCollegeSelectChange(res)
    }

    else {
      setCollegeSelect([])
    }

  }, [location.search, options]);

  if (!collegeMap) {
    return <div>Loading ...</div>
  }

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='fw-bold' style={{ letterSpacing: titleSpace }}>COLLEGE</div>
      <div onClick={() => {
        handleAdvisorGAEventCollegeFilter()
      }}>
        <Select
          options={options}
          isMulti
          value={selectedColleges}
          onChange={handleCollegeSelectChange}
        />
      </div>
    </div>
  )
}

export { College }