import { useEffect, useState } from "react"
import { getUserByToken, validateUserEmail } from "../../modules/auth/core/_requests"
import { closeModalFunction, closeModalIcon } from "../dictionary"
import LoadingIndicatorGIF from '../../../custom_assets/dca-loading-indicator.gif'
import { getUserProfile } from "../../modules/auth/core/_requests"
import { getAuth } from "../../modules/auth"

const ValidateEmailModal = ({ emailAddress, validateEmailTrigger, setValidateEmailTrigger, validationComplete, setValidationComplete, setCurrentUser }) => {

  useEffect(() => {
    const validateEmail = async (emailAddress) => {
      try {
        await validateUserEmail(emailAddress);
        setValidationComplete(true);
      } catch (error) {
        console.log('Error validating email');
      }
    };

    validateEmailTrigger && validateEmail(emailAddress);
  }, [emailAddress, validateEmailTrigger]);

  useEffect(() => {
    if (validationComplete) {

      try {

        let apiToken: any = undefined

        if (getAuth()?.api_token) {
          apiToken = getAuth()?.api_token
        }

        const updateUserProfileData = async (apiToken) => {
          const { data: user } = await getUserByToken(apiToken);
          const { data: userProfile } = await getUserProfile(user.pk, apiToken);
          setCurrentUser(userProfile);
        }

        apiToken && updateUserProfileData(apiToken)

      } catch (error) {
        console.log('No token available to get current user. Login to refresh user.')
      }

      const modalTimer = setTimeout(() => {
        setValidateEmailTrigger(false);
        closeModalFunction('validate_email_modal')
      }, 5000);

      return () => clearTimeout(modalTimer);
    }
  }, [validationComplete]);


  const validationCompleteComponent = (
    <div className='d-flex flex-column justify-content-center' style={{ textAlign: 'center' }}>Your email {emailAddress} has been validated! You will be redirected to the homepage shortly...</div>
  )

  const validationInProgressComponent = (
    <div className='d-flex flex-column justify-content-center'>
      <div
        style={{
          textAlign: 'center',
        }}>Validating Email...</div>
      <img
        src={LoadingIndicatorGIF}
        alt='LoadingIndicator'
        style={{ width: '20%', alignSelf: 'center', marginTop: '5%' }} />
    </div>
  )

  return (
    <div
      className="modal fade"
      id={'validate_email_modal'}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{
          minWidth: '75vw',
          maxWidth: '75vw',
          padding: '60px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflowY: 'auto'
        }}>
          {closeModalIcon('validate_email_modal')}

          {validationComplete ? validationCompleteComponent : validationInProgressComponent}

        </div>
      </div>
    </div>
  )
}

export { ValidateEmailModal }