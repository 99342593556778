import Chart from 'react-apexcharts'

const SATScoreData = ({ modalData }) => {

  let cdsArr: any = []

  // Chart 1
  let xAxisComposite: any = []
  let composite25: any = []
  let composite50: any = []
  let composite75: any = []

  // Chart 2
  let latestYear: any = null
  let xAxisLatest: any = ['SAT Reading Scores', 'SAT Math Scores']
  let latest25: any = []
  let latest50: any = []
  let latest75: any = []

  if (modalData && typeof modalData === 'object' && modalData.college_cds) {

    modalData.college_cds.forEach((res, index) => {
      if (index < 3) {
        cdsArr.push(res);
        cdsArr.sort((a, b) => a.year - b.year);
      }
    })

    cdsArr.map((cds, index) => {
      xAxisComposite.push(`${cds.year} - ${cds.year + 1}`);

      composite25.push(cds.satComposite25 ? cds.satComposite25 : null);

      composite50.push(cds.satComposite50 ? cds.satComposite50 : null);

      composite75.push(cds.satComposite75 ? cds.satComposite75 : null);


      if (index === cdsArr.length - 1) {
        const returnVal = (val) => {
          if (val) {
            return val
          }

          else {
            return null
          }
        }

        latest25 = [returnVal(cds.satEvidence25), returnVal(cds.satMath25)]
        latest50 = [returnVal(cds.satEvidence50), returnVal(cds.satMath50)]
        latest75 = [returnVal(cds.satEvidence75), returnVal(cds.satMath75)]

        // console.log('latest25', latest25)
        // console.log('latest50', latest50)
        // console.log('latest75', latest75)
      }
    })

  }

  return (
    <div className="card card-custom shadow p-4" style={{ marginTop: '40px' }}>

      <div style={{ padding: '30px' }}>
        <div className='fw-bold' style={{ fontSize: '22px' }}>SAT Composite Scores</div>

        <Chart
          options={{
            chart: {
              id: "SAT Composite Scores"
            },
            xaxis: {
              categories: xAxisComposite,
              labels: {
                show: true,
                style: {
                  colors: '#9AA2B6',
                },
              }
            },
            tooltip: {
              enabled: false,
            },
            yaxis: {
              min: 1000,//0.00,
              max: 1600,//composite75[0] * 1.5,
              forceNiceScale: true,
              labels: {
                style: {
                  colors: ['#9AA2B6'],
                },
                formatter: function (val) {
                  if (val != null && val != undefined) {
                    return val.toFixed(0);
                  }

                  else {
                    return ""
                  }
                }
              }
            },
            colors: ["#90F892", "#FFEF68", "#97E6FF"],
            legend: {
              show: true,
              onItemClick: {
                toggleDataSeries: false
              },
              onItemHover: {
                highlightDataSeries: true
              },
            },
            dataLabels: {
              enabled: true,
              background: {
                borderWidth: 0,
                borderRadius: 5,
                foreColor: '#000',
              },
            },
            fill: {
              type: "solid",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.6,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              }
            },
            stroke: {
              curve: 'smooth'
            }
          }}
          series={[
            {
              name: "25th percentile score",
              data: composite25
            },
            {
              name: "50th percentile score",
              data: composite50
            },
            {
              name: "75th percentile score",
              data: composite75
            }
          ]}
          type='line'
          width={'600'}
          height={'350'}
        />

        <div className='fw-bold' style={{ fontSize: '22px' }}>SAT Section Scores ({modalData.college_cds[0].year ? `${modalData.college_cds[0].year} - ${modalData.college_cds[0].year + 1}` : null})</div>

        <Chart
          series={[
            {
              name: '25th Percentile Score',
              type: 'column',
              data: latest25
            },
            {
              name: '50th Percentile Score',
              type: 'column',
              data: latest50
            },
            {
              name: '75th Percentile Score',
              type: 'column',
              data: latest75
            }
          ]}
          options={{
            chart: {
              id: "SAT Scores (latest year)",
              type: 'area',
              stacked: false
            },
            xaxis: {
              type: 'category',
              categories: xAxisLatest,
              tickPlacement: 'on',
              labels: {
                show: true,
                style: {
                  colors: '#9AA2B6',
                },
              }
            },
            yaxis: {
              min: 400,//0.00,
              max: 800,//composite75[0] * 1.5,
              forceNiceScale: false,
              labels: {
                style: {
                  colors: ['#9AA2B6'],
                },
                formatter: function (val) {
                  if (val != null && val != undefined) {
                    return val.toFixed(0);
                  }
                  else {
                    return ''
                  }
                }
              }
            },
            tooltip: {
              enabled: false,
            },
            colors: ["#90F892", "#FFEF68", "#97E6FF"],
            legend: {
              show: true,
              onItemClick: {
                toggleDataSeries: false
              },
              onItemHover: {
                highlightDataSeries: true
              },
            },
            dataLabels: {
              enabled: true,
              background: {
                borderWidth: 0,
                borderRadius: 5,
                foreColor: '#000',
              },
              formatter: function (val, opts) {
                if (val != null && val != undefined) {
                  return val.toLocaleString("en-US");
                }

                else {
                  return ''
                }
              },
            },
            stroke: {
              curve: 'smooth',
            },
            plotOptions: {
              bar: {
                columnWidth: '40%',
                borderRadius: 0,
              }
            }
          }}
          width={'600'}
          height={'350'}
        />
      </div>
    </div>
  )
}

export { SATScoreData }
