import { LoadingSpinner } from "../LoadingSpinner"
import { returnFullStateName, titleSpace } from "../dictionary"
import { countryMap, stateMap, returnParagraphsFromHyphenSeparatedString } from "../dictionary"

const ProfileInfo = ({ advisor }) => {

  if (!advisor) {
    return <LoadingSpinner title={'Profile Info'} />
  }

  const returnGender = (input) => {
    switch (input) {
      case '1':
      case 1:
        return 'Male'
      case '2':
      case 2:
        return 'Female'
      case '3':
      case 3:
        return 'Non-binary'
      case '4':
      case 4:
        return 'Other'
      default:
        return '-'
    }
  }

  const returnSchoolLocation = (input) => {

    if (!input) {
      return '-'
    }

    else if (input.hsLocCtry === 245 || input.hsLocCtry === '245') {
      return returnFullStateName(parseInt(input.hsLocState))
    }

    else {
      return input.hsLocCtry ? countryMap[parseInt(input.hsLocCtry)] : '-'
    }
  }

  const returnCurrentLocation = (input) => {
    let stateName = returnFullStateName(parseInt(input))

    if (stateName) {
      return stateName
    } else if (stateMap[parseInt(input)]) {
      return stateMap[parseInt(input)]
    }

    return '-'
  }

  const returnSchoolType = (input) => {
    switch (input) {
      case '1':
      case 1:
        return 'Public'
      case '2':
      case 2:
        return 'Private'
      default:
        return '-'
    }
  }

  const returnEthnicity = (input) => {
    switch (input) {
      case '1':
      case 1:
        return 'American Indian'
      case '2':
      case 2:
        return 'Asian'
      case '3':
      case 3:
        return 'African American'
      case '4':
      case 4:
        return 'Hispanic'
      case '5':
      case 5:
        return 'Pacific Islander'
      case '6':
      case 6:
        return 'White'
      case '7':
      case 7:
        return 'Other'
      default:
        return '-'
    }
  }

  const data = [
    { header: 'GENDER', value: returnGender(advisor.gender) },
    { header: 'ETHNICITY', value: returnEthnicity(advisor.ethnicity) },
    { header: 'HIGH SCHOOL LOCATION', value: returnSchoolLocation(advisor) },
    { header: 'HIGH SCHOOL TYPE', value: returnSchoolType(advisor.hsType) },
    { header: 'CURRENT LOCATION', value: returnCurrentLocation(advisor.currentLocState) }]

  return (
    <div className="" style={{ marginTop: '30px' }}>
      {/* <div className="dca-advisor-detail-content-title" style={{ fontSize: '23px' }}>Profile Info</div> */}
      <div style={{ fontSize: '23px' }}>Profile Info</div>

      <div className="card shadow" style={{ marginTop: '15px' }}>
        <div className="d-flex flex-row" style={{ marginBottom: '40px' }}>

          <div className="d-flex flex-column" style={{ width: '45%', paddingLeft: '40px', paddingTop: "40px" }}>
            <div className="dca-card-item-text" style={{ letterSpacing: titleSpace, marginBottom: '5px' }}>ABOUT ME</div>
            <div>{advisor.aboutMe ? returnParagraphsFromHyphenSeparatedString(advisor.aboutMe) : '-'}</div>
          </div>


          <div className="d-flex flex-column" style={{ width: '45%', paddingTop: '40px', paddingBottom: '20px', marginLeft: '85px' }}>
            {data.map((res, index) =>
              <div
                key={index}
                style={{ marginTop: index != 0 ? '40px' : '' }}
              >
                <div
                  className="dca-card-item-text" style={{ letterSpacing: titleSpace }}
                >{res.header}</div>
                <div>{res.value}</div>
              </div>
            )}
          </div>

        </div>
      </div>
    </div>
  )
}

export { ProfileInfo }