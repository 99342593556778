import axios from "axios"
import { URL, pageRoutes, updateTitleAndDescription } from "../components/dictionary"
import { useState, useEffect } from "react"
import { Card } from "../components/AdvisorSearch/Card"
import { Aside } from "../components/AdvisorSearch/Aside"
import { LoadingIndicator } from "../components/LoadingIndicator"
import { collegeMap } from "../components/collegeMap"

const AdvisorSearchPage = ({ user, cip, collegesId, ecActivities, setPrompt, setNavigateTo, setPurchaseID, setAdvisorID, advisorSearchLocation, setAdvisorSearchLocation }) => {

  //////// Set states of variables ////////
  const [advisors, setAdvisors] = useState<any>([])
  const [majors, setMajors] = useState<any>([])
  const [results, setResults] = useState<any>([])
  const [filteredResults, setFilteredResults] = useState(false)
  const [sortKey, setSortKey] = useState<any>('joinDate')
  const [sortOrder, setSortOrder] = useState<any>('Ascending')

  //Filter states
  const [filtersActive, setFiltersActive] = useState(0)
  const [collegeFilter, setCollegeFilter] = useState<any>((item) => {
    return item => item
  })
  const [majorFilter, setMajorFilter] = useState<any>((item) => {
    return item => item
  })
  const [ecFilter, setECFilter] = useState<any>((item) => {
    return item => item
  })

  //////// Functions and event handlers ////////
  const getMajors = async () => {
    let res = await axios.get(`${URL}/majors/`)
    setMajors(res.data)
  }

  const getData = async () => {
    let res = await axios.get(`${URL}/advisor_profiles/`);
    res = res.data.filter((item) => item.displayState == 1)
    setAdvisors(res)
    setResults(res)
    setFilteredResults(true)
  }
  //////// useEffects ////////
  useEffect(() => {
    // Sets default
    updateTitleAndDescription(pageRoutes.advisorSearch)

    getMajors();
    getData();
  }, [])

  useEffect(() => {
    const sortAndFilter = (collegeFilter, majorFilter, ecFilter, sortKey, sortOrder) => {
      let res: any = advisors

      res = res.filter(collegeFilter).filter(majorFilter).filter(ecFilter)

      if (res.length === 0) {
        return setFilteredResults(false)
      }

      switch (sortKey) {
        case 'joinDate':
          res.sort((a, b) => {
            let dateA: any = new Date(a.joinDate)
            let dateB: any = new Date(b.joinDate)

            return sortOrder === 'Descending' ? (dateB - dateA) : (dateA - dateB)
          })
          break;
        case 'college':
          res.sort((a, b) => {
            // return sortOrder === 'Descending' ?
            //   (collegesId.filter((item) => item.id == b.college_id)[0].name.localeCompare(collegesId.filter((item) => item.id == a.college_id)[0].name)) :
            //   (collegesId.filter((item) => item.id == a.college_id)[0].name.localeCompare(collegesId.filter((item) => item.id == b.college_id)[0].name))
            return sortOrder === 'Descending' ?
              (collegeMap.filter((item) => item.id == b.college_id)[0].name.localeCompare(collegeMap.filter((item) => item.id == a.college_id)[0].name)) :
              (collegeMap.filter((item) => item.id == a.college_id)[0].name.localeCompare(collegeMap.filter((item) => item.id == b.college_id)[0].name))
          })
          break;
        default:
          res = res
      }

      setResults(res)
      if (filteredResults === false) {
        setFilteredResults(true)
      }
    }

    sortAndFilter(collegeFilter, majorFilter, ecFilter, sortKey, sortOrder);

  }, [collegeFilter, majorFilter, ecFilter, sortKey, sortOrder])

  const returnResults = (filteredResults) => {
    switch (filteredResults) {
      case true:
        return <Card
          results={results}
          sortKey={sortKey}
          setSortKey={setSortKey}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          collegesId={collegesId}
          collegeMap={collegeMap}
          majors={majors}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          user={user}
          setPurchaseID={setPurchaseID}
          setAdvisorID={setAdvisorID}
          filtersActive={filtersActive}
        />
      case false:
        return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
          <div className='mt-5'>No results meet your criteria</div>
        </div>
    }
  }

  const advisorHeader = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>Advisors Network</span>

      </div>

      <span className="dca-search-description">Connect with recent high school graduates to benefit from their first-hand experience with your extracurricular activities, colleges, and majors of interest. Leverage advisors with specific expertise to help in your college planning and application process.</span>

    </div>
  )

  return results.length > 0 ? (
    <div>
      {advisorHeader}

      <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px' }}>
        <Aside
          advisors={advisors}
          cip={cip}
          collegesId={collegesId}
          collegeMap={collegeMap}
          ecActivities={ecActivities}
          setCollegeFilter={setCollegeFilter}
          setMajorFilter={setMajorFilter}
          setECFilter={setECFilter}
          sortKey={sortKey}
          setSortKey={setSortKey}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          advisorSearchLocation={advisorSearchLocation}
          setAdvisorSearchLocation={setAdvisorSearchLocation}
          filtersActive={filtersActive}
          setFiltersActive={setFiltersActive}
        />
        {returnResults(filteredResults)}
      </div>
    </div>
  ) : (
    <div>
      <div className='d-flex flex-column' style={{ marginTop: '30px' }}>
        <p className='fw-bold' style={{ fontSize: '30px', marginLeft: '35px' }}>Advisors Network</p>
        <p className="mt-3" style={{ fontSize: '21px', marginLeft: '35px', marginBottom: '250px' }}>Connect with recent high school graduates to benefit from their first-hand experience with your extracurricular activities, colleges, and majors of interest. Leverage advisors with specific expertise to help in your college planning and application process.</p>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '100px' }}>
        <LoadingIndicator text={"Getting Advisors ... "} />
      </div>
    </div>
  )
}

export { AdvisorSearchPage }
