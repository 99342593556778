import { closeModalIcon, numberConverter } from "../../../dictionary"
import { useState, useEffect } from "react"
import { RankIndicator } from "../../../RankIndicator"
import { LoadingIndicator } from "../../../LoadingIndicator"

const SafetyAggregateModal = ({ collegesSafetyIndex, dataLoaded }) => {

  const [tableData, setTableData] = useState<any>([])
  // scroll / scrollTo post modalTitle change

  useEffect(() => {

    const createTableData = (collegesSafetyIndex) => {
      let data = collegesSafetyIndex

      let tableData: any = []

      data.forEach((res) => {
        let rank = res.safetyIndex

        let name = res.name

        if (rank != null && name != null) {
          tableData.push({ name: name, rank: rank })
        }

      })

      tableData = tableData.sort((a, b) => {
        if (a.rank === '-' && b.rank === '-') {
          return a.name.localeCompare(b.name);
        } else if (a.rank === '-') {
          return 1;
        } else if (b.rank === '-') {
          return -1;
        } else {
          const rankA = parseFloat(a.rank);
          const rankB = parseFloat(b.rank);
          if (rankA === rankB) {
            return a.name.localeCompare(b.name);
          }
          return rankA - rankB;
        }
      });

      setTableData(tableData)

    }

    createTableData(collegesSafetyIndex)

  }, [collegesSafetyIndex])

  // Table Content
  const tableContent = (
    <table className="table table-borderless table-sm table-responsive ms-5 me-5" style={{ width: '85%' }}>
      <thead>
        <tr className="text-center">
          <th className='text-start dca-header-spacing-B' scope="col">COLLEGE</th>
          <th className='text-start dca-header-spacing-B' scope="col">RANK</th>
        </tr>
      </thead>
      <tbody>

        {tableData.map((res, index) => (
          <tr key={index} className="text-center align-middle">
            <th scope='row' className="text-start" style={{ minWidth: 'fit-content', whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{res.name}</th>
            <td style={{ verticalAlign: 'middle' }}><div style={{ marginLeft: '5px' }}><RankIndicator rank={res.rank} modal={true} /></div></td>
          </tr>
        ))}

      </tbody>
    </table>
  )

  return dataLoaded && collegesSafetyIndex.length > 0 ? (
    <div className="modal fade" id="safety_aggregate_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content dca-font-M" style={{ minWidth: '75vw', maxWidth: '75vw', minHeight: '85vh', maxHeight: '85vh', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto' }}>

          <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px' }}>

            <div className="d-flex" style={{ margin: '5px 0 40px 0', fontSize: 'calc(1.26rem + 0.12vw)' }}>Aggregate Safety Rank</div>

            {closeModalIcon('')}

            {tableContent}

          </div>

        </div>
      </div>
    </div>
  ) : (
    <div className="modal fade" id="safety_total_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content dca-font-M" style={{ minWidth: '75vw', maxWidth: '75vw', minHeight: '85vh', maxHeight: '85vh', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto' }}>

          <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px' }}>

            <div className="d-flex" style={{ margin: '5px 0 5px 0', fontSize: 'calc(1.26rem + 0.12vw)' }}>Aggregate Safety Rank</div>

            {closeModalIcon('')}

            <LoadingIndicator text={"Getting Colleges Data ... "} />
          </div>

        </div>
      </div>
    </div>
  )
}

export { SafetyAggregateModal }