import { useEffect, useState } from 'react'
import { College } from './components/College'
import { Major } from './components/Major'
import { Extracurriculars } from './components/Extracurriculars'
import { Keyword } from './components/Keyword'
import { returnParameterString, concatLocation } from '../searchPageURLManager'
import { activityFormat, pageRoutes } from '../dictionary'
import { useLocation } from 'react-router'

const Aside = ({ advisors, cip, collegesId, ecActivities, setCollegeFilter, setMajorFilter, setECFilter, advisorSearchLocation, setAdvisorSearchLocation, sortKey, setSortKey, sortOrder, setSortOrder, collegeMap, filtersActive, setFiltersActive }) => {

  const location = useLocation()

  //Filter select values
  const [collegeSelect, setCollegeSelect] = useState<any>([])
  const [majorSelect, setMajorSelect] = useState<any>([])
  const [ecSelect, setECSelect] = useState<any>([])

  //// URL and filter store states
  const [sortParams, setSortParams] = useState('')
  const [collegeParams, setCollegeParams] = useState('')
  const [majorParams, setMajorParams] = useState('')
  const [ecParams, setECParams] = useState('')

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortParam = params.get('sort');

    if (sortParam) {
      const sorts = sortParam.split(',').map(res => res);
      setSortKey(sorts[0])
      setSortOrder(sorts[1])
    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortDetails: any = [sortKey, sortOrder]

    sortDetails = sortDetails.map((res) => res).join(',')
    params.set('sort', sortDetails)

    setSortParams(`${params.toString()}`)

  }, [sortKey, sortOrder])

  // //Update filters when selected filter options are changed
  useEffect(() => {
    const returnCollegeFilter = (collegeSelect) => {
      if (collegeSelect.length !== 0) {
        setCollegeFilter((item) => {
          return item => collegeSelect.some((collegeID) => collegeID.value == parseInt(item.college_id))
        })
      }
      else {
        setCollegeFilter((item) => {
          return item => item
        })
      }
    };
    returnCollegeFilter(collegeSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (collegeSelect.length > 0) {
      const colleges = collegeSelect.map((collegeId) => collegeId.value).join(',')
      params.set('college', colleges)
    } else {
      params.delete('college')
    }

    setCollegeParams(`${params.toString()}`)

  }, [collegeSelect])

  useEffect(() => {
    // console.log(majorSelect)
    const returnMajorFilter = (majorSelect) => {
      if (majorSelect.length !== 0) {

        setMajorFilter((item) => {
          return item => (
            item.cipCodes !== null &&
            item.cipCodes.some((code) =>
              majorSelect.some((majorObj) => majorObj.value == (code.toString())))
          )
        })
      }

      else {
        setMajorFilter((item) => {
          return item => item
        })
      }
    };
    returnMajorFilter(majorSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (majorSelect.length !== 0) {
      const majors = majorSelect.map((name) => name.value).join(',')
      params.set('fieldOfStudy', majors)
    } else {
      params.delete('fieldOfStudy')
    }

    setMajorParams(`${params.toString()}`)
  }, [majorSelect])

  useEffect(() => {

    const returnECFilter = (ecSelect) => {
      if (ecSelect.length > 0) {
        setECFilter((item) => {
          return item => (item.ecActivities !== null && item.ecActivities.some((activityValue) => ecSelect.some(ecObj => ecObj.value == parseInt(activityValue))))
        })
      }
      else {
        setECFilter((item) => {
          return item => item
        })
      }
    };
    returnECFilter(ecSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (ecSelect.length > 0) {
      let selectedECActivities = ecSelect.map((ecObj) => ecObj?.value).join(',')
      params.set('extracurriculars', selectedECActivities)
    } else {
      params.delete('extracurriculars')
    }

    setECParams(`${params.toString()}`)
  }, [ecSelect])

  useEffect(() => {

    let activeFilters = 0

    if (collegeSelect.length !== 0) {
      activeFilters = activeFilters + 1
    }

    if (ecSelect.length > 0) {
      activeFilters = activeFilters + 1
    }

    if (majorSelect.length !== 0) {
      activeFilters = activeFilters + 1
    }

    setFiltersActive(activeFilters)

  }, [collegeSelect, majorSelect, ecSelect])

  useEffect(() => {

    //  Create an array of the parameter strings
    let params: string[] = []
    // Use the returnParameterString function to fix the string if needed
    params = [
      returnParameterString(collegeParams, 'college'),
      returnParameterString(majorParams, 'fieldOfStudy'),
      returnParameterString(ecParams, 'extracurriculars'),
      returnParameterString(sortParams, 'sort')
    ]

    // Set the location string using the base pageRoute and the return from the concat location function
    setAdvisorSearchLocation(`${pageRoutes.advisorSearch}${concatLocation(params)}`)

  }, [collegeParams, majorParams, ecParams, sortParams])

  useEffect(() => {

    window.history.replaceState({}, '', advisorSearchLocation)

  }, [advisorSearchLocation])

  return (
    <div style={{ width: '260px', maxWidth: '260px', whiteSpace: 'nowrap' }}>
      <College
        advisors={advisors}
        collegeMap={collegeMap}
        setCollegeSelect={setCollegeSelect}
      />
      <Major
        cip={cip}
        setMajorSelect={setMajorSelect}
      />
      <Extracurriculars
        ecActivities={ecActivities}
        setECSelect={setECSelect}
      />
      <Keyword />
    </div>
  )
}

export { Aside }