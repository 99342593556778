import { LoadingSpinner } from "../LoadingSpinner"
import { titleSpace, languagesMap, returnBulletListFromHyphenSeparatedString } from "../dictionary"
import { fullCollegeMap } from "../fullCollegeMap"

const AdvisorQualifications = ({ advisor }) => {

  if (!advisor) {
    return <LoadingSpinner title={'Profile Info'} />
  }

  const returnAcceptances = (acceptances) => {

    if (!acceptances) {
      return '-'
    }

    let res: any = []

    // Change acceptance return to map through fullCollegeMap list
    acceptances.map((collegeID, index) => {
      const returnName = (fullCollegeMap, collegeID) => {
        return `${fullCollegeMap.filter((collegeObj) => parseInt(collegeObj.id) == collegeID)[0]?.name}`
      }

      index === acceptances.length - 1 ? res.push(`${returnName(fullCollegeMap, collegeID)}`) :
        res.push(`${returnName(fullCollegeMap, collegeID)}, `)
    })

    return res
  }

  const returnLanguages = (languages) => {
    let res: any = []

    languages.map((lang, index) => {
      index == languages.length - 1 ? res.push(`${languagesMap[lang]}`) : res.push(`${languagesMap[lang]}, `)
    })

    return res
  }


  return (
    <div className="" style={{ marginTop: '30px' }}>
      <div style={{ fontSize: '23px' }}>Advisor Qualifications</div>

      <div className="card shadow" style={{ marginTop: '15px' }}>
        <div className="d-flex flex-row" style={{ marginBottom: '40px' }}>

          <div className="d-flex flex-column" style={{ width: '45%', paddingLeft: '40px', paddingTop: "40px" }}>
            <div className="dca-card-item-text" style={{ letterSpacing: titleSpace, marginBottom: '5px' }}>EXTRACURRICULAR EXPERIENCES</div>
            <div>{advisor.ecExperience ? returnBulletListFromHyphenSeparatedString(advisor.ecExperience) : '-'}</div>

            <div className="dca-card-item-text" style={{ letterSpacing: titleSpace, marginBottom: '5px', marginTop: '40px' }}>ACCEPTED COLLEGES/UNIVERSITIES</div>
            <div>{returnAcceptances(advisor.acceptances)}</div>
          </div>


          <div className="d-flex flex-column" style={{ width: '45%', paddingLeft: '40px', paddingTop: "40px" }}>
            <div className="dca-card-item-text" style={{ letterSpacing: titleSpace, marginBottom: '5px' }}>AWARDS/HONORS</div>
            <div>{advisor.awards ? returnBulletListFromHyphenSeparatedString(advisor.awards) : '-'}</div>

            <div className="dca-card-item-text" style={{ letterSpacing: titleSpace, marginBottom: '5px', marginTop: '40px' }}>LANGUAGES SPOKEN</div>
            <div>{returnLanguages(advisor.languages)}</div>
          </div>

        </div>
      </div>
    </div>
  )
}

export { AdvisorQualifications }