import { useState, useEffect } from 'react'
import { selectedTabGreen, unselectedTabGray } from '../dictionary'
import { TabContent } from './TabContent'

const Tabs = ({ result, cip, advisorECReports }) => {

  // const tabOptions = ['Admission Correlation', 'College Matriculation', 'Field of Study Selection', 'Location of Students', 'Distribution by Grade']
  const [tabOptions, setTabOptions] = useState<any>(['Admission Correlation', 'College Matriculation', 'Field of Study Selection', 'Location of Students', 'Distribution by Grade'])
  const [selectedTab, setSelectedTab] = useState(tabOptions[0])

  const handleSelectedTab = (val) => {
    setSelectedTab(val)
  }

  // #484 Disable Inside Scoop from appearing as a tab option
  // useEffect(() => {
  //   if (advisorECReports && advisorECReports.length > 0 && !tabOptions.includes('Inside Scoop')) {
  //     setTabOptions([...tabOptions, 'Inside Scoop'])
  //   }
  // }, [advisorECReports])

  return (
    <div className='mt-5'>

      <div className='d-flex flex-row'>

        {tabOptions.map((tab, index) => (
          <div
            key={tab}
            className="d-flex rounded rounded-3 fw-bold justify-content-start align-items-center"
            style={{
              backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
              height: '70px',
              cursor: 'pointer',
              border: '1px rgba(219, 223, 233, 0.4) dashed',
              borderRadius: '50%',
              display: 'inline-block',
              marginLeft: index !== 0 ? '20px' : ''
            }}
            onClick={() => handleSelectedTab(tabOptions[index])}
          >
            <span className='m-3 text-nowrap'>{tab}</span>
          </div>))}

      </div>

      <TabContent
        selectedTab={selectedTab}
        tabOptions={tabOptions}
        result={result}
        cip={cip}
        advisorECReports={advisorECReports}
      />

    </div>
  )
}

export { Tabs }