import Headline from '../../custom_assets/home/headline.jpg'
import College from '../../custom_assets/home/college.jpg'
import Overview from '../../custom_assets/home/overview.jpg'
import TestScore from '../../custom_assets/home/test_score.jpg'
import Extracurriculars from '../../custom_assets/home/extracurriculars.jpg'
import Advisors from '../../custom_assets/home/advisors.jpg'
import { useNavigate, useLocation } from 'react-router-dom'
import { getAuth } from '../modules/auth'
import { useEffect, useState } from 'react'
import { setTestScoreStrategyModal } from '../components/Auth/setPrompts'
import { AuthReactGAHandlers } from '../components/ReactGAHandlers'
import { pageRoutes, updateTitleAndDescription } from '../components/dictionary'
import { ValidateEmailModal } from '../components/Auth/ValidateEmailModal'
import { Modal } from 'bootstrap'

////////// Testimonial content //////////

// const returnTestimonial = (name, relation, text) => {
//   return <div className="d-flex flex-column align-items-center text-center" style={{ width: '40%' }}>
//     <div className='fw-bold'>{name},</div>
//     <div className='fw-bold'>{relation}</div>
//     <div style={{ marginTop: '25px' }}>{text}</div>
//   </div>
// }

// const testimonial0 = (
//   <div className='d-flex flex-row justify-content-evenly'>
//     {returnTestimonial('JULIET BARNES', 'STANFORD UNIVERSITY ADMIT', '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.”')}

//     {returnTestimonial('CECILIA LEE', 'PARENTS', '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.”')}
//   </div>
// )

// const testimonial1 = (
//   <div className='d-flex flex-row justify-content-evenly'>
//     {returnTestimonial('USER 2A', 'STANFORD UNIVERSITY ADMIT', '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.”')}

//     {returnTestimonial('USER 2B', 'PARENTS', '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.”')}
//   </div>
// )

// const testimonial2 = (
//   <div className='d-flex flex-row justify-content-evenly'>
//     {returnTestimonial('USER 3A', 'STANFORD UNIVERSITY ADMIT', '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.”')}

//     {returnTestimonial('USER 3B', 'PARENTS', '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.”')}
//   </div>
// )

// const testimonialsArr = [testimonial0, testimonial1, testimonial2]

// const returnTestimonialSwitch = (testimonialIndex) => {
//   if (testimonialsArr[testimonialIndex]) {
//     return testimonialsArr[testimonialIndex]
//   }

//   else {
//     return testimonial0
//   }
// }

// const testimonials = (
//   <div
//     className="d-flex flex-column"
//     style={{ height: '400px', marginTop: '10vh' }}
//   >

//     {returnTestimonialSwitch(testimonialIndex)}

//     <div style={{ marginTop: '8vh' }}>
//       <ul className="pagination">
//         <li
//           className={`page-item previous ${testimonialIndex === 0 ? 'disabled' : ''}`}
//           onClick={() => testimonialIndex > 0 ? setTestimonialIndex(testimonialIndex - 1) : null}>
//           <div className="page-link">
//             <i className="previous"></i>
//           </div>
//         </li>
//         <li className={`page-item ${testimonialIndex === 0 ? 'active' : ''}`} onClick={() => setTestimonialIndex(0)}>
//           <div className="page-link">
//             1
//           </div>
//         </li>
//         <li className={`page-item ${testimonialIndex === 1 ? 'active' : ''}`} onClick={() => setTestimonialIndex(1)}>
//           <div className="page-link">
//             2
//           </div>
//         </li>
//         <li className={`page-item ${testimonialIndex === 2 ? 'active' : ''}`} onClick={() => setTestimonialIndex(2)}>
//           <div className="page-link">
//             3
//           </div>
//         </li>
//         <li className={`page-item ${testimonialIndex === 3 ? 'active' : ''}`} onClick={() => setTestimonialIndex(3)}>
//           <div className="page-link">
//             4
//           </div>
//         </li>
//         <li className={`page-item ${testimonialIndex === 4 ? 'active' : ''}`} onClick={() => setTestimonialIndex(4)}>
//           <div className="page-link">
//             5
//           </div>
//         </li>
//         <li className={`page-item ${testimonialIndex === 5 ? 'active' : ''}`} onClick={() => setTestimonialIndex(5)}>
//           <div className="page-link">
//             6
//           </div>
//         </li>
//         <li className={`page-item next ${testimonialIndex === testimonialsArr.length + 1 ? 'disabled' : ''}`}
//           onClick={() => testimonialIndex === testimonialsArr.length + 1 ? null : setTestimonialIndex(testimonialIndex + 1)}>
//           <div className="page-link">
//             <i className="next"></i>
//           </div>
//         </li>
//       </ul>

//     </div>

//   </div>
// )

const Home = ({ setPrompt, setNavigateTo, setCurrentUser }) => {

  let apiToken: any = undefined
  let navigate = useNavigate()

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  const location = useLocation()

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  // const [testimonialIndex, setTestimonialIndex] = useState(0)
  const [validateEmailTrigger, setValidateEmailTrigger] = useState(false)
  const [validationComplete, setValidationComplete] = useState(false)
  const [emailAddress, setEmailAddress] = useState<any>(null)

  const headline = (
    <div className='dca-home-headline-container'>
      <img
        src={Headline} alt='headline'
        className='dca-headline-img'>
      </img>
      <div className='dca-home-header-wrapper'>
        <span className='dca-home-header-1'>Get an edge in your college admissions journey</span>
        <span className='dca-home-header-2'>Cutting-edge tools and insights to improve your chances of getting in to the most selective schools
        </span>
      </div>
    </div>
  )

  const returnLeftImageComponent = (data, positionA = '50%', positionB = '80%', scale = 1.0) => {
    return (
      <div className="dca-home-component-wrapper-L">

        <div className='dca-home-img-wrapper-L'>
          <img
            src={data.imageSrc}
            alt={data.altImageText}
            className='dca-home-img'
            style={{
              '--position-a': positionA,
              '--position-b': positionB,
              '--scale': scale,
            } as any}
          ></img>
        </div>

        <div className='dca-cta-text-wrapper-L'>
          <div style={{ lineHeight: '1.6em' }}>
            <div>{data.text}</div>
            <button
              onClick={() => navigate(data.navigateURL)}
              className='dca-home-cta-button'
            >{data.buttonText}
              <i
                className="ms-2 fa-solid fa-arrow-right"
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  paddingLeft: '5px',
                  fontSize: '1.2rem'
                }}></i></button>
          </div>
        </div>
      </div>
    )
  }

  const returnRightImageComponent = (data) => {

    return (
      <div className="dca-home-component-wrapper-R">
        <div className='dca-cta-text-wrapper-R'>
          <div style={{ lineHeight: '1.6em' }}>
            {data.text}
          </div>
          <div>
            <button
              onClick={() => navigate(data.navigateURL)}
              className='dca-home-cta-button'
            >
              {data.buttonText}
              <i className="ms-2 fa-solid fa-arrow-right" style={{
                color: 'black',
                fontWeight: 'bold',
                paddingLeft: '5px',
                fontSize: '1.2rem'
              }}></i>
            </button>
          </div>
        </div>

        <div className='dca-home-img-wrapper-R'>
          <img
            src={data.imageSrc}
            alt={data.altImageText}
            className='dca-home-img'
          />
        </div>
      </div>
    )
  }

  const noAuthTestScoreCTA = (data, positionA = '50%', positionB = '80%', scale = 1.0) => {
    return (<div className="dca-home-component-wrapper-L">

      <div className='dca-home-img-wrapper-L'>
        <img
          src={data.imageSrc}
          alt={data.altImageText}
          className='dca-home-img'
          style={{
            '--position-a': positionA,
            '--position-b': positionB,
            '--scale': scale,
          } as any}></img></div>

      <div className='dca-cta-text-wrapper-L'>
        <div style={{ lineHeight: '1.6em' }}>
          <div>{data.text}</div>

          <a
            data-bs-toggle="modal"
            href="#login_modal"
            onClick={() => {
              setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
              handleGAEventSignInModalOpen('forced')
            }}
            style={{ color: 'black' }}
            className='dca-home-noauth-testscore-cta-button'
          >{data.buttonText}
            <i
              className="ms-2 fa-solid fa-arrow-right"
              style={{
                color: 'black',
                fontWeight: 'bold',
                paddingLeft: '5px',
                fontSize: '1.2rem'
              }}></i>
          </a>
        </div>

      </div>
    </div>
    )
  }

  const returnTestScoreStrategyCTA = (apiToken) => {

    switch (true) {
      case apiToken !== undefined:
        return returnLeftImageComponent({ imageSrc: TestScore, altImageText: 'test-score', text: 'Get personalized recommendations for navigating the evolving test-optional landscape', navigateURL: pageRoutes.testScoreStrategy, buttonText: 'Advice On Test Scores' }, '50%', '60%')
      default:
        return noAuthTestScoreCTA({ imageSrc: TestScore, altImageText: 'test-score', text: 'Get personalized recommendations for navigating the evolving test-optional landscape', navigateURL: pageRoutes.testScoreStrategy, buttonText: 'Advice On Test Scores' }, '50%', '60%')
    }

  }

  const collegeCTA = (
    <div className="dca-home-component-wrapper-R">

      <div className='dca-cta-text-wrapper-R'>
        <div style={{ lineHeight: '1.6em' }}>
          Deep dive on the most selective U.S. colleges to identify the right schools and majors for your profile and interests
        </div>

        <div>
          <button
            onClick={() => navigate(pageRoutes.collegeSearch)}
            className='dca-home-cta-button'
          >Build A College List<i className="ms-2 fa-solid fa-arrow-right" style={{
            color: 'black',
            fontWeight: 'bold',
            paddingLeft: '5px',
            fontSize: '1.2rem'
          }}></i>
          </button>

          <button
            onClick={() => navigate(pageRoutes.majorSearch)}
            className='dca-home-cta-button'>Explore Potential Majors<i className="ms-2 fa-solid fa-arrow-right" style={{
              color: 'black',
              fontWeight: 'bold',
              paddingLeft: '5px',
              fontSize: '1.2rem'
            }}></i></button>
        </div>

      </div>

      <div className='dca-home-img-wrapper-R'>
        <img
          src={College}
          alt='college'
          className='dca-home-img'
        ></img>
      </div>
    </div>
  )

  const advisorsCTA = (
    <div className="dca-home-component-wrapper-L">

      <div className='dca-home-img-wrapper-L'>
        <img
          src={Advisors}
          alt={'advisors'}
          className='dca-home-img'
          style={{
            '--position-a': '50%',
            '--position-b': '80%',
            '--scale': 1.0,
          } as any}></img></div>

      <div className='dca-cta-text-wrapper-L'>
        <div style={{ lineHeight: '1.6em' }}>
          <div>Connect with college students to get an insider perspective on colleges, majors and extracurricular activities of interest</div>
          {/* <button
            disabled
            className='dca-home-cta-button'
            style={{ background: '#BFEFFE' }}
          >Coming Soon!
          </button> */}
          <button
            onClick={() => navigate(pageRoutes.advisorSearch)}
            className='dca-home-cta-button'>Meet Insiders<i className="ms-2 fa-solid fa-arrow-right" style={{
              color: 'black',
              fontWeight: 'bold',
              paddingLeft: '5px',
              fontSize: '1.2rem'
            }}></i></button>
        </div>
      </div>

    </div>
  )


  const returnTestimonial = (name, role, text) => {
    return (
      <div className='d-flex flex-column align-items-center' style={{ width: '40%', padding: '10px' }}>
        <div className='dca-testimonial-text'>{text}</div>
        <div className='dca-testimonial-name' style={{ marginTop: '30px' }}>
          <span>{name},</span>
          <span>{role}</span>
        </div>
      </div>
    )
  }

  const testimonials = (
    <div style={{ marginTop: '15vh' }}>
      {/* Row 1 */}
      <div className='dca-testimonial-container'>
        {returnTestimonial('Liana', 'Columbia University student', '“I love how data-driven the site is with so many insightful tools and cool visualizations. NextFour should be the definitive starting point for all high school students applying to the top colleges!”')}
        {returnTestimonial('Jonathan', 'Stanford University student', '“NextFour is truly amazing! I would have absolutely loved to have something like this when I applied, especially to help with extracurriculars to strengthen my applications.” ')}
      </div>

      {/* Row 2 */}
      <div className='dca-testimonial-container'>
        {returnTestimonial('Laasya', 'high school student', `“I've tried several alternative solutions before, but nothing compares to your service. Highly recommended!”`)}
        {returnTestimonial('Enerel', 'high school student', '“Very nicely curated set of resources for aspring college applicants, all consolidated into one easy-to-use product.”')}
      </div>

      {/* Row 3 */}
      <div className='dca-testimonial-container'>
        {returnTestimonial('Alex', 'parent', '“We know firsthand just how important it is to be strategic throughout the college application process - from picking majors, selecting EC activites, building a college list, etc. NextFour will be immensely helpful to families as they navigate all those critical decisions through high school.”')}
        {returnTestimonial('Manoj', 'parent', '“NextFour is like a breath of fresh air, bringing some much-needed technology and innovation to the world of college admissions. I have spent countless hours and thousands of dollars on college counseling for our son, but have already learned far more from NextFour!” ')}
      </div>
    </div>
  )

  useEffect(() => {

    updateTitleAndDescription()

    const params = new URLSearchParams(location.search);
    let modalIdParam = params.get('modal')
    let emailAddressParam = params.get('email')

    modalIdParam === 'validateEmail' && setValidateEmailTrigger(true)
    emailAddressParam && setEmailAddress(emailAddressParam)

    // console.log('params:', modalIdParam, emailAddressParam)
    // console.log('validateEmailTrigger', validateEmailTrigger)
  }, [])

  useEffect(() => {

    const modalElement: any = document.getElementById('validate_email_modal');
    const validateEmailModal = new Modal(modalElement);

    // For testing, show modal on load--
    // validateEmailModal.show()

    if (validateEmailTrigger) {
      validateEmailModal.show()
    }

    else {
      validateEmailModal.hide();
      const removeModalBackdrop = (modalElement) => {
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop && !document.body.contains(modalElement)) {
          backdrop.remove();
        }
      };
      removeModalBackdrop(modalElement);
    }

  }, [validateEmailTrigger]);

  useEffect(() => {
    if (validationComplete) {
      window.history.replaceState({}, '', `${pageRoutes.home}`)
    }
  }, [validationComplete])


  return (
    <div className='dca-home'>

      {headline}

      <ValidateEmailModal
        emailAddress={emailAddress}
        validationComplete={validationComplete}
        setValidationComplete={setValidationComplete}
        validateEmailTrigger={validateEmailTrigger}
        setValidateEmailTrigger={setValidateEmailTrigger}
        setCurrentUser={setCurrentUser} />

      {returnLeftImageComponent({ imageSrc: Overview, altImageText: 'overview', text: 'Know what to expect with the college application process and get free guidance to help you successfully navigate the key steps', navigateURL: pageRoutes.overviewIntroduction, buttonText: 'Get Started' })}

      {collegeCTA}

      {returnTestScoreStrategyCTA(apiToken)}

      {returnRightImageComponent({ imageSrc: Extracurriculars, altImageText: 'extracurriculars', text: 'Identify and achieve in extracurriculars that are impactful and aligned with your application profile', navigateURL: pageRoutes.ecSearch, buttonText: 'Discover Activities' })}

      {/* Replaced by advisorsCTA now, advisorsCTA updated to use enabled button component */}
      {/* {returnLeftImageComponent({ imageSrc: Advisors, altImageText: 'advisors', text: 'Connect with college students to get an insider perspective on colleges, majors and extracurricular activities of interest', navigateURL: pageRoutes.advisorSearch, buttonText: 'Meet insiders' })} */}

      {advisorsCTA}

      {testimonials}

    </div>
  )

}

export { Home }
