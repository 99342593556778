import Select from 'react-select'
import { titleSpace } from '../../dictionary'
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { AdvisorsReactGAHandlers } from '../../ReactGAHandlers';

const Extracurriculars = ({ ecActivities, setECSelect }) => {

  const location = useLocation()
  const [selectedECs, setSelectedECs] = useState<any>([]);
  const [options, setOptions] = useState<any>([])

  const { handleAdvisorGAEventECFilter } = AdvisorsReactGAHandlers()

  const handleECSelectChange = (ecSelect) => {
    setSelectedECs(ecSelect)
    setECSelect(ecSelect)
  }

  useEffect(() => {
    let optionsArr: any = []
    if (ecActivities && ecActivities.length > 0) {
      ecActivities.map((res) => {
        optionsArr.push({ value: res.id, label: res.name })
      })
      setOptions(optionsArr)
    }
  }, [ecActivities])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let ecParams = params.get('extracurriculars');
    let res: any[] = []

    if (ecParams) {
      const ecs = ecParams.split(',')
      ecs.map((val) => {
        let ecObj = options.find((obj => obj.value === (parseInt(val))))
        if (ecObj) {
          res.push(ecObj)
        }
      })
      handleECSelectChange(res)
    }

    else {
      setECSelect([])
    }

  }, [location.search, options]);

  if (!ecActivities) {
    return <div>Loading ...</div>
  }

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='fw-bold' style={{ letterSpacing: titleSpace }}>EXTRACURRICULARS</div>
      <div onClick={() => {
        handleAdvisorGAEventECFilter()
      }}>
        <Select
          options={options}
          isMulti
          value={selectedECs}
          onChange={handleECSelectChange}
        />
      </div>
    </div>
  )
}

export { Extracurriculars }