export const premiumecsMap = [
    "35",
    "195",
    "399",
    "476",
    "422",
    "414",
    "448",
    "471",
    "616",
    "437",
    "452",
    "465",
    "341",
    "383",
    "116",
    "144",
    "545",
    "395",
    "350",
    "108",
    "64",
    "279",
    "622",
    "469",
    "601",
    "404",
    "609",
    "115",
    "48",
    "343"
];
