import { useEffect } from "react"
import { updateTitleAndDescription, pageRoutes } from "../components/dictionary"

const About = () => {

  useEffect(() => {
    updateTitleAndDescription(pageRoutes.about)
  }, [])

  return (
    <div className="d-flex flex-column" style={{ margin: '6vh 4vw 2vh 4vw' }}>
      <div className="dca-overview-title">About</div>

      {/* Paragraph Content */}
      <div className="dca-overview-paragraph">NextFour is dedicated to helping high school families be better informed, and therefore more confident and effective in their decision-making, as they navigate the increasingly complex college admissions process.
      </div>

      <div className="dca-overview-paragraph">We believe the transition from high school to college has evolved into a major life-event that is commonly accompanied by high levels of stress and anxiety. In fact, 73% of students and 69% of parents indicated the stress level from the college application process as “High” or “Very High” in the Princeton Review College Hopes & Worries Survey.  The importance of this life event, coupled with the intensely competitive admissions environment, catalyzes families to often seek help throughout the college planning journey.  However, we know that families struggle to find assistance that is effective, data-driven, time-saving, up-to-date and accessible/affordable.  NextFour was created to directly address those challenging gaps.
      </div>

      <div className="dca-overview-paragraph">Informed by the real-world experiences of families who have successfully received admission to the top-ranked U.S. colleges, NextFour offers advice, data-driven insights, and powerful tools to maximize your chances of getting in to the most selective schools.
      </div>

      <div className="dca-overview-paragraph">We look forward to sharing the journey forward with you!
      </div>
      {/*  */}
    </div>
  )
}

export { About }
