import { closeModalIcon, numberConverter } from "../../../dictionary"
import { useState, useEffect } from "react"
import { RankIndicator } from "../../../RankIndicator"
import { LoadingIndicator } from "../../../LoadingIndicator"
import { collegeMap } from "../../../collegeMap"

const SafetyAnnualAveragePer1KModal = ({ allCrimeAvgPer1KData, allCrimeRankPer1KData, dataLoaded, modalTitle, modalCategory }) => {

  const [tableData, setTableData] = useState<any>([])
  // scroll / scrollTo post modalTitle change

  useEffect(() => {

    // console.log('crime rank', allCrimeRankPer1KData)
    // console.log('crime avg', allCrimeAvgPer1KData)

    const createTableData = (allCrimeAvgPer1KData, allCrimeRankPer1KData, field) => {
      let crimeRankPer1K = allCrimeRankPer1KData
      let crimeAvgPer1K = allCrimeAvgPer1KData
      let tableData: any = []

      crimeRankPer1K.forEach((res) => {
        let rank = res[field]

        let value: any = '-'
        const crimeAvgObject = crimeAvgPer1K.find((item) => item.college_id === res.college_id)

        if (crimeAvgObject) {
          value = crimeAvgObject[field]
        }

        let name = '-';
        // const collegeObject = collegesId ? collegesId.find(item => item.id === res.college_id) : null
        const collegeObject = collegeMap.find(item => parseInt(item.id) === res.college_id)

        if (collegeObject) {
          name = collegeObject.name;
        }

        tableData.push({ name: name, value: value.toFixed(3), rank: rank })

      })

      tableData = tableData.sort((a, b) => {
        if (a.rank === '-' && b.rank === '-') {
          return a.name.localeCompare(b.name);
        } else if (a.rank === '-') {
          return 1;
        } else if (b.rank === '-') {
          return -1;
        } else {
          const rankA = parseFloat(a.rank);
          const rankB = parseFloat(b.rank);
          if (rankA === rankB) {
            return a.name.localeCompare(b.name);
          }
          return rankA - rankB;
        }
      });

      return tableData

    }

    if (allCrimeAvgPer1KData.length > 0 && allCrimeRankPer1KData.length > 0) {

      if (modalCategory === 'Criminal Offenses') {

        switch (true) {

          case modalTitle === 'Murder/Non-negligent manslaughter':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'murder'))
            break;

          case modalTitle === 'Negligent manslaughter':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'negManslaughter'))
            break;

          case modalTitle === 'Rape':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'rape'))
            break;

          case modalTitle === 'Fondling':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'fondling'))
            break;

          case modalTitle === 'Incest':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'incest'))
            break;

          case modalTitle === 'Statutory Rape':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'statRape'))
            break;

          case modalTitle === 'Robbery':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'robbery'))
            break;

          case modalTitle === 'Aggravated assault':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'aggAssault'))
            break;

          case modalTitle === 'Burglary':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'burglary'))
            break;

          case modalTitle === 'Motor vehicle theft':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'motorVehicleTheft'))
            break;

          case modalTitle === 'Arson':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'arson'))
            break;

          case modalTitle === 'Total Criminal':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'criminalTotal'))
            break;

          default:
            setTableData(tableData)

        }
      }

      else if (modalCategory === 'Violence Against Women Act (VAWA) Offenses') {

        switch (true) {

          case modalTitle === 'Domestic violence':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'domesticViolence'))
            break;

          case modalTitle === 'Dating violence':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'datingViolence'))
            break;

          case modalTitle === 'Stalking':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'stalking'))
            break;

          case modalTitle === 'Total VAWA Offenses':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'vawaTotal'))
            break;

          default:
            setTableData(tableData)
        }
      }

      else if (modalCategory === 'Arrests') {

        switch (true) {

          case modalTitle === 'Drug Abuse':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'arrestsDrugAbuse'))
            break;

          case modalTitle === 'Liquor Abuse':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'arrestsLiquorAbuse'))
            break;

          case modalTitle === 'Weapons':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'arrestsWeapons'))
            break;

          case modalTitle === 'Total Arrests':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'arrestsTotal'))
            break;

          default:
            setTableData(tableData)
        }

      }

      else if (modalCategory === 'Disciplinary Actions') {

        switch (true) {

          case modalTitle === 'Drug Abuse':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'disciplinaryDrugAbuse'))
            break;

          case modalTitle === 'Liquor Abuse':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'disciplinaryLiquorAbuse'))
            break;

          case modalTitle === 'Weapons':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'disciplinaryWeapons'))
            break;

          case modalTitle === 'Total Disciplinary':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'disciplinaryTotal'))
            break;

          default:
            setTableData(tableData)
        }

      }

      else if (modalCategory === 'Hate Crimes') {

        switch (true) {

          case modalTitle === 'Aggravated assault':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateAggAssault'))
            break;

          case modalTitle === 'Arson':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateArson'))
            break;

          case modalTitle === 'Burglary':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateBurglary'))
            break;

          case modalTitle === 'Destruction':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateDestruction'))
            break;

          case modalTitle === 'Fondling':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateFondling'))
            break;

          case modalTitle === 'Incest':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateIncest'))
            break;

          case modalTitle === 'Intimidation':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateIntimidation'))
            break;

          case modalTitle === 'Larceny':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateLarceny'))
            break;

          case modalTitle === 'Motor Vehicle Theft':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateMotorVehicleTheft'))
            break;

          case modalTitle === 'Murder':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateMurder'))
            break;

          case modalTitle === 'Rape':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateRape'))
            break;

          case modalTitle === 'Simple Assault':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateSimpleAssault'))
            break;

          case modalTitle === 'Statutory Rape':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateStatRape'))
            break;

          case modalTitle === 'Total Hate':
            setTableData(createTableData(allCrimeAvgPer1KData, allCrimeRankPer1KData, 'hateTotal'))
            break;

          default:
            setTableData(tableData)
        }

      }

      else {
        setTableData(tableData)
      }

    }

  }, [allCrimeAvgPer1KData, allCrimeRankPer1KData, modalTitle, modalCategory])

  // Table Content
  const tableContent = (
    <table className="table table-borderless table-sm table-responsive ms-5 me-5" style={{ width: '85%' }}>
      <thead>
        <tr className="text-center">
          <th className='text-start dca-header-spacing-B' scope="col">COLLEGE</th>
          <th className="dca-header-spacing-B" scope="col">TOTAL # INCIDENTS</th>
          <th className='text-start dca-header-spacing-B' scope="col">RANK</th>
        </tr>
      </thead>
      <tbody>

        {tableData.map((res, index) => (
          <tr key={index} className="text-center align-middle">
            <th scope='row' className="text-start" style={{ minWidth: 'fit-content', whiteSpace: 'nowrap', verticalAlign: 'middle' }}>{res.name}</th>
            <td style={{ verticalAlign: 'middle' }}>{res.value}</td>
            <td style={{ verticalAlign: 'middle' }}><div style={{ marginLeft: '5px' }}><RankIndicator rank={res.rank} modal={true} /></div></td>
          </tr>
        ))}

      </tbody>
    </table>
  )



  return dataLoaded && allCrimeAvgPer1KData.length > 0 && allCrimeRankPer1KData.length > 0 ? (
    <div className="modal fade" id="safety_per_1k_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content dca-font-M" style={{ minWidth: '75vw', maxWidth: '75vw', minHeight: '85vh', maxHeight: '85vh', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto' }}>

          <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px' }}>

            <div className="d-flex" style={{ margin: '5px 0 5px 0', fontSize: 'calc(1.26rem + 0.12vw)' }}>Annual Average Number Of Incidents Per 1K Students:</div>

            <div className="d-flex" style={{ margin: '5px 0 40px 0', fontSize: 'calc(1.26rem + 0.12vw)' }}>{modalTitle}</div>
            {closeModalIcon('')}

            {tableContent}

          </div>

        </div>
      </div>
    </div>
  ) : (
    <div className="modal fade" id="safety_per_1k_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content dca-font-M" style={{ minWidth: '75vw', maxWidth: '75vw', minHeight: '85vh', maxHeight: '85vh', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto' }}>

          <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px' }}>

            <div className="d-flex" style={{ margin: '5px 0 5px 0', fontSize: 'calc(1.26rem + 0.12vw)' }}>Annual Average Number Of Incidents Per 1K Students</div>

            <div className="d-flex" style={{ margin: '5px 0 40px 0', fontSize: 'calc(1.26rem + 0.12vw)' }}>{modalTitle}</div>
            {closeModalIcon('')}


            <LoadingIndicator text={"Getting Colleges Data ... "} />
            {/* <img src={LoadingIndicator} alt='LoadingIndicator'/>
             <div className='mt-5'>Getting Colleges Data ... </div> */}
          </div>

        </div>
      </div>
    </div>
  )
}

export { SafetyAnnualAveragePer1KModal }