import { useEffect } from 'react';
import { useLocation } from 'react-router';
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"

const AppDate = ({ appDateSelect, setAppDateSelect, setAppDateParams }) => {

  const location = useLocation()

  const appDueDateOptions = [
    { value: 0, label: 'Any' },
    { value: 1, label: 'In the next week' },
    { value: 2, label: 'In the next 2 weeks' },
    { value: 3, label: 'In the next 4 weeks' }
  ]

  const handleAppDateSelectChange = (event) => {
    let res = event.target.value
    setAppDateSelect(parseInt(res))
  }

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const appDateParam = params.get('appDate');

    if (appDateParam) {
      setAppDateSelect(parseInt(appDateParam));
    }

    else {
      setAppDateSelect(0)
    }
  }, [location.search]);


  useEffect(() => {

    let params = new URLSearchParams(location.search);

    if (appDateSelect != 0) {
      params.set('appDate', appDateSelect)
    } else {
      params.delete('appDate')
    }

    setAppDateParams(`${params.toString()}`)

  }, [appDateSelect])

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>APPLICATION DATE</div>
      <select
        className='form-select form-select-solid'
        data-control="select2"
        value={appDateSelect}
        onChange={handleAppDateSelectChange}
        style={{ cursor: 'pointer' }}
      >
        {appDueDateOptions.map((res, index) => (
          <option
            key={index}
            value={res.value}>
            {res.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export { AppDate }