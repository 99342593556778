import { ProfileInfo } from "./ProfileInfo"
import { AdvisorQualifications } from "./AdvisorQualifications"
import { AdvisoryServices } from "./AdvisoryServices"

const AdvisorDetailTabContent = ({ advisor, selectedTab, tabOptions }) => {

  switch (selectedTab) {
    case tabOptions[0]:
      return <ProfileInfo advisor={advisor} />
    case tabOptions[1]:
      return <AdvisorQualifications advisor={advisor} />
    case tabOptions[2]:
      return <AdvisoryServices advisor={advisor} />
    default:
      return <ProfileInfo advisor={advisor} />
  }
}

export { AdvisorDetailTabContent }