import Chart from 'react-apexcharts'
import { BarChart } from './Charts/BarChart'
import { getAuth } from '../../../modules/auth'
import { AuthReactGAHandlers } from '../../ReactGAHandlers'
import { useNavigate } from 'react-router'
import { pageRoutes } from '../../dictionary'
import { setTestScoreStrategyModal } from '../../Auth/setPrompts'

const SATScoreData = ({ cds, collegeName, setPrompt, setNavigateTo }) => {

  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()
  const navigate = useNavigate()

  let latestYear = cds.length - 1
  let satCompositeScore = (<div></div>)
  let satCompositeScoreDistribution = (<div></div>)
  let satEvidenceScoreDistribution = (<div></div>)
  let satMathScoreDistribution = (<div></div>)

  //SAT Composite Score
  let satCompositeXAxis: any[] = []
  let satComposite25: any[] = []
  let satComposite50: any[] = []
  let satComposite75: any[] = []

  //SAT Composite Score Distribution
  const satScoreXAxis = ['1400-1600', '1200-1399', '1000-1199', '800-999', '600-799', '400-599']
  let [satComposite14001600, satComposite12001399, satComposite10001199, satComposite800999, satComposite600799, satComposite400599]: any = [null, null, null, null, null, null]
  let satCompositeScoreDistributionArray = [satComposite14001600, satComposite12001399, satComposite10001199, satComposite800999, satComposite600799, satComposite400599]

  //SAT Evidence Score Distribution
  let satEvidenceXAxis = ['700-800', '600-699', '500-599', '400-499', '300-399', '200-299']
  let [satEvidence700800, satEvidence600699, satEvidence500599, satEvidence400499, satEvidence300399, satEvidence200299
  ]: any = [null, null, null, null, null, null]
  let satEvidenceScoreDistributionArray = [satEvidence700800, satEvidence600699, satEvidence500599, satEvidence400499, satEvidence300399, satEvidence200299
  ]

  //SAT Math Score Distribution
  let [satMath700800, satMath600699, satMath500599, satMath400499, satMath300399, satMath200299
  ]: any = [null, null, null, null, null, null]
  let satMathScoreDistributionArray = [satMath700800, satMath600699, satMath500599, satMath400499, satMath300399, satMath200299
  ]

  const returnScore = (fieldVal) => {
    if (fieldVal != null || fieldVal != undefined) {
      return (fieldVal * 100).toFixed(1)
    }

    else {
      return null
    }
  }

  if (cds != null && cds[latestYear] != null) {
    cds.forEach((res) => {
      satCompositeXAxis.push(`${res.year} - ${res.year + 1}`);
      satComposite25.push(res.satComposite25)
      satComposite50.push(res.satComposite50)
      satComposite75.push(res.satComposite75)
    })

    satComposite14001600 = returnScore(cds[latestYear].satComposite14001600)
    satComposite12001399 = returnScore(cds[latestYear].satComposite12001399)
    satComposite10001199 = returnScore(cds[latestYear].satComposite10001199)
    satComposite800999 = returnScore(cds[latestYear].satComposite800999)
    satComposite600799 = returnScore(cds[latestYear].satComposite600799)
    satComposite400599 = returnScore(cds[latestYear].satComposite400599)
    satCompositeScoreDistributionArray = [satComposite14001600, satComposite12001399, satComposite10001199, satComposite800999, satComposite600799, satComposite400599]


    satEvidence700800 = returnScore(cds[latestYear].satEvidence700800)
    satEvidence600699 = returnScore(cds[latestYear].satEvidence600699)
    satEvidence500599 = returnScore(cds[latestYear].satEvidence500599)
    satEvidence400499 = returnScore(cds[latestYear].satEvidence400499)
    satEvidence300399 = returnScore(cds[latestYear].satEvidence300399)
    satEvidence200299 = returnScore(cds[latestYear].satEvidence200299)
    satEvidenceScoreDistributionArray = [satEvidence700800, satEvidence600699, satEvidence500599, satEvidence400499, satEvidence300399, satEvidence200299
    ]

    satMath700800 = returnScore(cds[latestYear].satMath700800)
    satMath600699 = returnScore(cds[latestYear].satMath600699)
    satMath500599 = returnScore(cds[latestYear].satMath500599)
    satMath400499 = returnScore(cds[latestYear].satMath400499)
    satMath300399 = returnScore(cds[latestYear].satMath300399)
    satMath200299 = returnScore(cds[latestYear].satMath200299)
    satMathScoreDistributionArray = [satMath700800, satMath600699, satMath500599, satMath400499, satMath300399, satMath200299
    ]

  }

  // Custom Line Chart
  satCompositeScore = (
    <div>
      <div className='fw-bold ms-5'>SAT Composite Score</div>
      <Chart
        series={[
          {
            name: '25th Percentile',
            type: 'line',
            data: satComposite25
          },
          {
            name: '50th Percentile',
            type: 'line',
            data: satComposite50
          },
          {
            name: '75th Percentile',
            type: 'line',
            data: satComposite75
          }
        ]}
        options={{
          chart: {
            id: "SAT Composite Score Distribution",
            type: 'line',
            stacked: false
          },
          xaxis: {
            categories: satCompositeXAxis,
            labels: {
              show: true,
              style: {
                colors: '#9AA2B6',
              },
            }
          },
          tooltip: {
            enabled: false,
          },
          yaxis: {
            seriesName: '75th Percentile',
            labels: {
              style: {
                colors: ['#9AA2B6'],
              },
              // formatter: function (val, opts) {
              //   return val.toLocaleString("en-US");
              // },
            },
            forceNiceScale: true,
            min: (Math.min(...satComposite25) - 100) ? (Math.min(...satComposite25) - 100) : 200,
            max: (Math.max(...satComposite75) + 100) < 1600 ? (Math.max(...satComposite75) + 100) : 1600
          },
          colors: ["#90F892", "#FFEF68", "#97E6FF"],
          dataLabels: {
            enabled: true,
            background: {
              borderWidth: 0,
              borderRadius: 5,
              foreColor: '#000',
            },
            // formatter: function (val, opts) {
            //   return val.toLocaleString("en-US");
            // },
          },
          legend: {
            show: true,
            onItemClick: {
              toggleDataSeries: false
            },
            onItemHover: {
              highlightDataSeries: true
            },
          },
          stroke: {
            curve: 'smooth',
          },
          plotOptions: {
            bar: {
              columnWidth: '40%',
              borderRadius: 0,
              dataLabels: {
                position: 'top',
              }
            }
          }
        }}
        // width={'400'}
        height={'300'}
      />
    </div>
  )

  // Bar Charts
  satCompositeScoreDistribution = (
    <div>
      <div className='fw-bold ms-5'>SAT Composite Score Distribution ({satCompositeXAxis[latestYear]})</div>
      <BarChart
        chartId={"SAT Composite Score Distribution"}
        xaxis={satScoreXAxis}
        seriesName={"Class Rank"}
        seriesData={satCompositeScoreDistributionArray} />
    </div>
  )

  satEvidenceScoreDistribution = (
    <div>
      <div className='fw-bold ms-5'>SAT Evidence Score Distribution ({satCompositeXAxis[latestYear]})</div>
      <BarChart
        chartId={"SAT Evidence Score Distribution"}
        xaxis={satEvidenceXAxis}
        seriesName={"Class Rank"}
        seriesData={satEvidenceScoreDistributionArray} />
    </div>
  )

  satMathScoreDistribution = (
    <div>
      <div className='fw-bold ms-5'>SAT Math Score Distribution ({satCompositeXAxis[latestYear]})</div>
      <BarChart
        chartId={"SAT Math Score Distribution"}
        xaxis={satEvidenceXAxis}
        seriesName={"Class Rank"}
        seriesData={satMathScoreDistributionArray} />
    </div>
  )

  const returnFootnote = (collegeName, apiToken) => {

    let testScoreGuidance = (
      !apiToken ? <a
        data-bs-toggle={apiToken != undefined ? "" : "modal"}
        href={apiToken != undefined ? "" : "#login_modal"}
        onClick={() => {
          setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
          apiToken == undefined && handleGAEventSignInModalOpen('forced')
        }}
        className='link-primary'
        style={{ cursor: 'pointer', color: 'rgb(7,20,55)' }}
      >Test Score Guidance</a> :
        <span
          onClick={() => navigate(pageRoutes.testScoreStrategy)}
          className='link-primary'
          style={{ cursor: 'pointer' }}
        >Test Score Guidance</span>
    )

    return (
      <div style={{ padding: '30px 60px 30px 60px' }}>
        Additional testing-related information {collegeName && `for ${collegeName}`} is available in the {testScoreGuidance} feature.
      </div>
    )
  }

  return cds.length > 0 ? (
    <div
      className='d-flex flex-column justify-content-between card shadow'
      style={{ marginTop: '40px' }}>
      <div
        className='d-flex flex-row justify-content-between'
        style={{ padding: '30px 60px 30px 60px' }}>
        <div style={{ width: '50%', marginRight: '30px' }}>{satCompositeScore}</div>

        <div style={{ width: '50%' }}>{satCompositeScoreDistribution}</div>
      </div>
      <div
        className='d-flex flex-row justify-content-between'
        style={{ padding: '30px 60px 30px 60px' }}>

        <div style={{ width: '50%', marginRight: '30px' }}>{satEvidenceScoreDistribution}</div>

        <div style={{ width: '50%' }}>{satMathScoreDistribution}</div>
      </div>

      {returnFootnote(collegeName, apiToken)}
    </div>
  ) : (<div>Loading SAT Data ... </div>)



}



export { SATScoreData }
