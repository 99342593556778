import { useEffect } from 'react';
import { useLocation } from 'react-router';
import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/primereact.min.css"

const ECAppDueDate = ({ appDueDateSelect, setAppDueDateSelect }) => {

  const location = useLocation()

  const appDueDateOptions = [
    { value: 0, label: 'Any' },
    { value: 1, label: 'In the next week' },
    { value: 2, label: 'In the next 2 weeks' },
    { value: 3, label: 'In the next 4 weeks' }
  ]

  const handleAppDueDateSelectChange = (event) => {
    let res = event.target.value
    setAppDueDateSelect(parseInt(res))
  }

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const appDueDateParam = params.get('appDueDate');

    if (appDueDateParam) {
      setAppDueDateSelect(parseInt(appDueDateParam));
    }

    else {
      setAppDueDateSelect(0)
    }
  }, [location.search]);

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>APP DUE DATE</div>
      <select
        className='form-select form-select-solid'
        data-control="select2"
        value={appDueDateSelect}
        onChange={handleAppDueDateSelectChange}
        style={{ cursor: 'pointer' }}
      >
        {appDueDateOptions.map((res, index) => (
          <option
            key={index}
            value={res.value}>
            {res.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export { ECAppDueDate }