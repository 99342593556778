type MarketingImageWrapperProps = {
  photo: any;
  altText: string;
  wrapper?: string;
};

const MarketingImageWrapper = ({ photo, altText, wrapper = undefined }: MarketingImageWrapperProps) => {
  return (
    <div className={`dca-marketing-image-wrapper${wrapper ? `-${wrapper}` : ''}`}>
      <img className="dca-marketing-image" alt={altText} src={photo} />
    </div>
  );
};

export { MarketingImageWrapper }