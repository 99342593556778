import { useEffect } from "react"
import { updateTitleAndDescription, pageRoutes } from "../components/dictionary"

const FAQ = () => {

      useEffect(() => {
            updateTitleAndDescription(pageRoutes.faq)
      }, [])

      return (
            <div className="d-flex flex-column" style={{ margin: '6vh 4vw 2vh 4vw' }}>
                  <div className="dca-overview-title">Frequently Asked Questions (FAQ)</div>

                  {/* Question */}
                  <div className="dca-overview-subheading">What does NextFour do?</div>

                  {/* Answer */}
                  <div className="dca-ordered-item">We help high school families be better informed, and therefore more confident and effective in their decision-making, as they navigate the college admissions process. We accomplish this by providing a Web-based application offering data, tools and insights that collectively help aspiring college applicants in the following areas:
                        1) Understanding the holistic college application journey, from preparing to apply/strengthening the student’s profile to picking prospective colleges to writing the actual applications;
                        2) Developing an application narrative and strategically selecting corresponding target schools, intended major(s), and extracurricular activities; and
                        3) Harnessing data and insider perspectives to guide decision-making and improve chances of future admission at target schools.
                  </div>

                  {/* Question */}
                  <div className="dca-overview-subheading">Who can benefit from using this service?</div>

                  {/* Answer */}
                  <div className="dca-ordered-item">Our service is primarily focused towards families that contemplate applying to competitive four-year colleges in the United States. Recognizing that the bar for “what it takes to get in” at selective colleges (well beyond just the most elite institutions) keeps being raised higher, we aspire to help students maximize their chances of admissions at the 100 top-ranked US universities. We encourage families to start planning for college much earlier than senior year, and our service is designed to help families throughout their high school years.</div>

                  {/* Question */}
                  <div className="dca-overview-subheading">Where does the underlying data come from?</div>

                  {/* Answer */}
                  <div className="dca-ordered-item">A core premise at NextFour is to utilize data to the fullest extent possible in helping users make decisions (i.e. building a college list, selecting intended fields of study, deciding between extracurricular activities, etc.) across their college planning journey. We believe this data-driven approach is far more effective than relying only on anecdotal information. Our service incorporates data from multiple sources, including: 1) information published by individual colleges (i.e. Common Data Set report); 2) information published by government agencies (i.e. College Scorecard, Integrated Postsecondary Education Data System, National Center for Education Statistics, etc.); and 3) information published by students. The data used in providing the service is updated on an ongoing basis.</div>

                  {/* Question */}
                  <div className="dca-overview-subheading">Do I need to create a user account to use the service?</div>

                  {/* Answer */}
                  <div className="dca-ordered-item">No, a user account is not required to use most aspects of the service. However, opting to create a (free) account does provide incremental benefits, such as the ability to receive personalized test strategy recommendations, build your college interest list, and more.</div>

                  {/* Question */}
                  <div className="dca-overview-subheading">Is NextFour free to use?</div>

                  {/* Answer */}
                  <div className="dca-ordered-item">Yes, most aspects of our service are currently free to use, which is an important component of our objective to provide an inclusive offering. Certain aspects of our service, such as access to premium functionality, require payment and will be clearly marked where applicable.</div>


                  {/* Question */}
                  <div className="dca-overview-subheading">Do I need NextFour if I already have a college counselor?</div>

                  {/* Answer */}
                  <div className="dca-ordered-item">Students often have access to college counseling resources from their high school, which we of course encourage students to fully leverage. Some families may also utilize a private consulting service for assistance. However, as families still ultimately carry the burden on owning decisions and assessing ideas/options suggested by counselor(s), they will benefit from data-driven resources that enable more efficient and confident execution, including access to specific subject matter experts.</div>

                  {/* Question */}
                  <div className="dca-overview-subheading">Who created NextFour?</div>

                  {/* Answer */}
                  <div className="dca-ordered-item">NextFour was created by a community of parents who have personally endured the experience of helping their children apply for college. Recognizing the inherent difficulties of the process, and frustrated by the expense and efficacy of the support resources currently available, we have developed a technology solution that provides a better option for families. The service is informed by our own collective experiences, as well as input from hundreds of families, and will continue to evolve based on ongoing feedback.</div>

                  {/* Question */}
                  <div className="dca-overview-subheading">How can I join the NextFour advisor network?</div>

                  {/* Answer */}
                  <div className="dca-ordered-item">If you are currently a college student, we invite you to join our platform where you can connect with current high school students to share your insider perspective on colleges, majors extracurricular activities, and more. This is a great opportunity to earn income and support students preparing for the college admissions process. Please email us at <a href="mailto:advisors@nextfour.ai">advisors@nextfour.ai</a> to get started.</div>

            </div>
      )
}

export { FAQ }
