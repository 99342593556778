import axios from 'axios'
import { getAuth } from './AuthHelpers'
import { FRONTEND_URL } from '../../../components/dictionary'
import { jwtDecode } from "jwt-decode";

const API_URL = process.env.REACT_APP_COLLEGE_API

export const REGISTER_URL = `${API_URL}/dj-rest-auth/registration/`
export const CREATE_USER_PROFILE = `${API_URL}/user_profiles/create/`
export const VALIDATE_USER_PROFILE_EMAIL = `${API_URL}/user_profiles/validate/`
export const SEND_EMAIL_VERIFICATION = `${API_URL}/send_email_verification/`
export const SEND_ADVISOR_CONSULTATION_PURCHASE_ALERT = `${API_URL}/send_advisor_consultation_purchase_alert/`
export const LOGIN_URL = `${API_URL}/login/`
export const GOOGLE_REST_AUTH_URL = `${API_URL}/dj-rest-auth/google/`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/`
export const REQUEST_PASSWORD_URL = `${API_URL}/dj-rest-auth/password/reset/`
export const PASSWORD_CHANGE = `${API_URL}/dj-rest-auth/password/change/`
export const LOGOUT_URL = `${API_URL}/logout/`
export const API_TOKEN = getAuth()

export const login = async (email: string, password: string) => {
  return await axios.post(LOGIN_URL, {
    "email": email,
    "password": password,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const validateUserEmail = async (email: string) => {
  return await axios.patch(VALIDATE_USER_PROFILE_EMAIL, {
    "emailAddress": email
  })
}

export const resendEmailVerificationRequest = async (firstName: string, email: string) => {
  await axios.post(SEND_EMAIL_VERIFICATION, {
    "firstName": firstName,
    "emailAddress": email,
    "homeLink": `${FRONTEND_URL}/home`,
    "emailConfirmationUrl": `${FRONTEND_URL}/home?modal=validateEmail&email=${email}`
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  console.log('Email verification request sent')
}
export const sendAdvisorConsultationPurchaseAlert = async (advisorNamePreview: string, advisorID: number, purchaseAmount: string, advisorText: string, userEmail: string) => {
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  let purchaseDate = `${month}/${day}/${year}`;

  let postData = {
    advisorNamePreview: advisorNamePreview,
    advisorID: advisorID,
    purchaseDate: purchaseDate,
    purchaseAmount: purchaseAmount,
    advisorText: advisorText,
    userEmail: userEmail
  }

  await axios.post(SEND_ADVISOR_CONSULTATION_PURCHASE_ALERT, postData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  console.log('Advisor Purchase Alert Email sent')
}

export const getGoogleToken = async (codeResponse) => {
  try {

    // IMPORTANT: Must send credential as BOTH access_token and id_token. Sending just one seems to throw a 400 Error.. view can't resolve using one or the other
    const res = await axios.post(GOOGLE_REST_AUTH_URL, {
      access_token: codeResponse.credential,
      id_token: codeResponse.credential
    });

    const { key, user } = res.data;

    // console.log('Auth Token:', key);

    return `${key}`

  } catch (error: any) {
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
    } else {
      console.error('Login failed:', error.message);
    }
  }
}

export const registerUser = async (email: string,
  password1: string,
  password2: string) => {
  await axios.post(REGISTER_URL, {
    "email": email,
    "password1": password1,
    "password2": password2
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  console.log(`User registered to Django Users`)
}

export const getUserByToken = async (token: string) => {
  return await axios.get(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json',
    },
  })
}

export const getUserByEmail = async (token: string, email: string) => {
  return await axios.get(`${API_URL}/user/email/${email}`, {
    headers: {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json',
    },
  })
}
// Note, these are not using pageRoutes from dictionary. May need to manually update the url strings if we make changes to home or validate email routes in pageRoutes
export const createNewUserProfile = async (user_id: number, firstName: string, lastName: string, email: string, social: boolean = false) => {
  await axios.post(CREATE_USER_PROFILE, {
    "user_id": user_id,
    "firstName": firstName,
    "lastName": lastName,
    "emailAddress": email,
    "homeLink": `${FRONTEND_URL}/home`,
    "emailConfirmationUrl": `${FRONTEND_URL}/home?modal=validateEmail&email=${email}`,
    "social": social
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  console.log(`User Profile for ${firstName} ${lastName} created`)
}

export const getUserProfile = async (user_id: number, token: string) => {
  return await axios.get(`${API_URL}/user_profiles/${user_id}`, {
    headers: {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json',
    },
  })
}

export const getUserProfileFromEmail = async (user_email: string, token: string) => {
  return await axios.get(`${API_URL}/user_profiles/email/${user_email}`, {
    headers: {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json',
    },
  })
}

export function register(
  firstName: string,
  lastName: string,
  email: string,
  password1: string,
  password2: string
) {
  try {
    // createNewUserProfile(firstName, lastName, email)

    return registerUser(email, password1, password2)
  }

  catch (error) {
    console.log(error, 'Error with registration, please try again')
    throw (error)
  }
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export const updatePassword = async (newPass1: string, newPass2: string, oldPass: string) => {
  return await axios.post(PASSWORD_CHANGE, {
    "new_password1": newPass1,
    "new_password2": newPass2,
    "old_password": oldPass
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const logout = async () => {
  return await axios.post(LOGOUT_URL), {
    headers: {
      'Authorization': `Token ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
  }
}