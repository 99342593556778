export const majorMap = [
    {
        "id": "1",
        "name": "Accounting",
        "hyphenated": "accounting"
    },
    {
        "id": "2",
        "name": "Aeronautics and Astronautics",
        "hyphenated": "aeronautics-and-astronautics"
    },
    {
        "id": "3",
        "name": "Aerospace Engineering",
        "hyphenated": "aerospace-engineering"
    },
    {
        "id": "4",
        "name": "African American and African Diaspora Studies",
        "hyphenated": "african-american-and-african-diaspora-studies"
    },
    {
        "id": "5",
        "name": "African American Studies",
        "hyphenated": "african-american-studies"
    },
    {
        "id": "6",
        "name": "African and African American Studies",
        "hyphenated": "african-and-african-american-studies"
    },
    {
        "id": "7",
        "name": "African Studies",
        "hyphenated": "african-studies"
    },
    {
        "id": "8",
        "name": "Afroamerican and African Studies",
        "hyphenated": "afroamerican-and-african-studies"
    },
    {
        "id": "9",
        "name": "American Culture",
        "hyphenated": "american-culture"
    },
    {
        "id": "10",
        "name": "American Musical Culture",
        "hyphenated": "american-musical-culture"
    },
    {
        "id": "11",
        "name": "American Studies",
        "hyphenated": "american-studies"
    },
    {
        "id": "12",
        "name": "Ancient Greek and Roman Studies",
        "hyphenated": "ancient-greek-and-roman-studies"
    },
    {
        "id": "13",
        "name": "Ancient Mediterranean Studies",
        "hyphenated": "ancient-mediterranean-studies"
    },
    {
        "id": "14",
        "name": "Ancient Studies",
        "hyphenated": "ancient-studies"
    },
    {
        "id": "15",
        "name": "Anthropology",
        "hyphenated": "anthropology"
    },
    {
        "id": "16",
        "name": "Anthropology and Human Biology",
        "hyphenated": "anthropology-and-human-biology"
    },
    {
        "id": "17",
        "name": "Applied Exercise Science",
        "hyphenated": "applied-exercise-science"
    },
    {
        "id": "18",
        "name": "Applied Mathematics",
        "hyphenated": "applied-mathematics"
    },
    {
        "id": "19",
        "name": "Applied Mathematics and Statistics",
        "hyphenated": "applied-mathematics-and-statistics"
    },
    {
        "id": "20",
        "name": "Applied Physics",
        "hyphenated": "applied-physics"
    },
    {
        "id": "21",
        "name": "Arabic",
        "hyphenated": "arabic"
    },
    {
        "id": "22",
        "name": "Archaeology",
        "hyphenated": "archaeology"
    },
    {
        "id": "23",
        "name": "Archaeology and Materials",
        "hyphenated": "archaeology-and-materials"
    },
    {
        "id": "24",
        "name": "Architectural Design",
        "hyphenated": "architectural-design"
    },
    {
        "id": "25",
        "name": "Architecture",
        "hyphenated": "architecture"
    },
    {
        "id": "26",
        "name": "Art",
        "hyphenated": "art"
    },
    {
        "id": "27",
        "name": "Art and Design",
        "hyphenated": "art-and-design"
    },
    {
        "id": "28",
        "name": "Art and Ideas in the Humanities",
        "hyphenated": "art-and-ideas-in-the-humanities"
    },
    {
        "id": "29",
        "name": "Art History",
        "hyphenated": "art-history"
    },
    {
        "id": "30",
        "name": "Art History and Visual Arts",
        "hyphenated": "art-history-and-visual-arts"
    },
    {
        "id": "31",
        "name": "Art Practice",
        "hyphenated": "art-practice"
    },
    {
        "id": "32",
        "name": "Art Theory and Practice",
        "hyphenated": "art-theory-and-practice"
    },
    {
        "id": "33",
        "name": "Artificial Intelligence and Decision Making",
        "hyphenated": "artificial-intelligence-and-decision-making"
    },
    {
        "id": "34",
        "name": "Asian American and Asian Diaspora Studies",
        "hyphenated": "asian-american-and-asian-diaspora-studies"
    },
    {
        "id": "35",
        "name": "Asian American Studies",
        "hyphenated": "asian-american-studies"
    },
    {
        "id": "36",
        "name": "Asian Languages and Cultures",
        "hyphenated": "asian-languages-and-cultures"
    },
    {
        "id": "37",
        "name": "Asian Studies",
        "hyphenated": "asian-studies"
    },
    {
        "id": "38",
        "name": "Astronomy",
        "hyphenated": "astronomy"
    },
    {
        "id": "39",
        "name": "Astronomy and Astrophysics",
        "hyphenated": "astronomy-and-astrophysics"
    },
    {
        "id": "40",
        "name": "Astrophysics",
        "hyphenated": "astrophysics"
    },
    {
        "id": "41",
        "name": "Atmosphere / Energy",
        "hyphenated": "atmosphere-/-energy"
    },
    {
        "id": "42",
        "name": "Atmospheric Science",
        "hyphenated": "atmospheric-science"
    },
    {
        "id": "43",
        "name": "Biochemistry",
        "hyphenated": "biochemistry"
    },
    {
        "id": "44",
        "name": "Bioengineering",
        "hyphenated": "bioengineering"
    },
    {
        "id": "45",
        "name": "Biological Engineering",
        "hyphenated": "biological-engineering"
    },
    {
        "id": "46",
        "name": "Biological Physics",
        "hyphenated": "biological-physics"
    },
    {
        "id": "47",
        "name": "Biological Sciences",
        "hyphenated": "biological-sciences"
    },
    {
        "id": "48",
        "name": "Biology",
        "hyphenated": "biology"
    },
    {
        "id": "49",
        "name": "Biology of Global Health",
        "hyphenated": "biology-of-global-health"
    },
    {
        "id": "50",
        "name": "Biology, Health, and Society",
        "hyphenated": "biology-health-and-society"
    },
    {
        "id": "51",
        "name": "Biomechanical Engineering",
        "hyphenated": "biomechanical-engineering"
    },
    {
        "id": "52",
        "name": "Biomedical Computation",
        "hyphenated": "biomedical-computation"
    },
    {
        "id": "53",
        "name": "Biomedical Engineering",
        "hyphenated": "biomedical-engineering"
    },
    {
        "id": "54",
        "name": "Biomolecular Science",
        "hyphenated": "biomolecular-science"
    },
    {
        "id": "55",
        "name": "Biophysics",
        "hyphenated": "biophysics"
    },
    {
        "id": "56",
        "name": "Biopsychology, Cognition, and Neuroscience",
        "hyphenated": "biopsychology-cognition-and-neuroscience"
    },
    {
        "id": "57",
        "name": "Black Studies",
        "hyphenated": "black-studies"
    },
    {
        "id": "58",
        "name": "Brain and Cognitive Sciences",
        "hyphenated": "brain-and-cognitive-sciences"
    },
    {
        "id": "59",
        "name": "Buddhist Studies",
        "hyphenated": "buddhist-studies"
    },
    {
        "id": "60",
        "name": "Business Administration",
        "hyphenated": "business-administration"
    },
    {
        "id": "61",
        "name": "Business Administration and Quantitative Sciences",
        "hyphenated": "business-administration-and-quantitative-sciences"
    },
    {
        "id": "62",
        "name": "Business Analytics",
        "hyphenated": "business-analytics"
    },
    {
        "id": "63",
        "name": "Cellular and Molecular Biomedical Science",
        "hyphenated": "cellular-and-molecular-biomedical-science"
    },
    {
        "id": "64",
        "name": "Celtic Studies",
        "hyphenated": "celtic-studies"
    },
    {
        "id": "65",
        "name": "Chemical Biology",
        "hyphenated": "chemical-biology"
    },
    {
        "id": "66",
        "name": "Chemical Engineering",
        "hyphenated": "chemical-engineering"
    },
    {
        "id": "67",
        "name": "Chemical Physics",
        "hyphenated": "chemical-physics"
    },
    {
        "id": "68",
        "name": "Chemical-Biological Engineering",
        "hyphenated": "chemical-biological-engineering"
    },
    {
        "id": "69",
        "name": "Chemistry",
        "hyphenated": "chemistry"
    },
    {
        "id": "70",
        "name": "Chemistry and Biology",
        "hyphenated": "chemistry-and-biology"
    },
    {
        "id": "71",
        "name": "Chicana/o - Latina/o Studies",
        "hyphenated": "chicana/o-latina/o-studies"
    },
    {
        "id": "72",
        "name": "Chinese",
        "hyphenated": "chinese"
    },
    {
        "id": "73",
        "name": "Chinese Language",
        "hyphenated": "chinese-language"
    },
    {
        "id": "74",
        "name": "Chinese Studies",
        "hyphenated": "chinese-studies"
    },
    {
        "id": "75",
        "name": "Choral Music Education",
        "hyphenated": "choral-music-education"
    },
    {
        "id": "76",
        "name": "Civil and Environmental Engineering",
        "hyphenated": "civil-and-environmental-engineering"
    },
    {
        "id": "77",
        "name": "Civil Engineering",
        "hyphenated": "civil-engineering"
    },
    {
        "id": "78",
        "name": "Classical Archaeology",
        "hyphenated": "classical-archaeology"
    },
    {
        "id": "79",
        "name": "Classical Civilization",
        "hyphenated": "classical-civilization"
    },
    {
        "id": "80",
        "name": "Classical Languages and Literatures",
        "hyphenated": "classical-languages-and-literatures"
    },
    {
        "id": "81",
        "name": "Classics",
        "hyphenated": "classics"
    },
    {
        "id": "82",
        "name": "Classics and English",
        "hyphenated": "classics-and-english"
    },
    {
        "id": "83",
        "name": "Classics and History",
        "hyphenated": "classics-and-history"
    },
    {
        "id": "84",
        "name": "Classics and Philosophy",
        "hyphenated": "classics-and-philosophy"
    },
    {
        "id": "85",
        "name": "Climate and Meteorology",
        "hyphenated": "climate-and-meteorology"
    },
    {
        "id": "86",
        "name": "Climate System Science",
        "hyphenated": "climate-system-science"
    },
    {
        "id": "87",
        "name": "Cognitive Science",
        "hyphenated": "cognitive-science"
    },
    {
        "id": "88",
        "name": "Communication and Media",
        "hyphenated": "communication-and-media"
    },
    {
        "id": "89",
        "name": "Communication Studies",
        "hyphenated": "communication-studies"
    },
    {
        "id": "90",
        "name": "Community and Global Public Health",
        "hyphenated": "community-and-global-public-health"
    },
    {
        "id": "91",
        "name": "Comparative Literary Studies",
        "hyphenated": "comparative-literary-studies"
    },
    {
        "id": "92",
        "name": "Comparative Literature",
        "hyphenated": "comparative-literature"
    },
    {
        "id": "93",
        "name": "Comparative Literature and Society",
        "hyphenated": "comparative-literature-and-society"
    },
    {
        "id": "94",
        "name": "Comparative Media Studies",
        "hyphenated": "comparative-media-studies"
    },
    {
        "id": "95",
        "name": "Comparative Studies in Race and Ethnicity",
        "hyphenated": "comparative-studies-in-race-and-ethnicity"
    },
    {
        "id": "96",
        "name": "Composition",
        "hyphenated": "composition"
    },
    {
        "id": "97",
        "name": "Computation and Cognition",
        "hyphenated": "computation-and-cognition"
    },
    {
        "id": "98",
        "name": "Computer Engineering",
        "hyphenated": "computer-engineering"
    },
    {
        "id": "99",
        "name": "Computer Science",
        "hyphenated": "computer-science"
    },
    {
        "id": "100",
        "name": "Computer Science (Eng)",
        "hyphenated": "computer-science-(eng)"
    },
    {
        "id": "101",
        "name": "Computer Science (LSA)",
        "hyphenated": "computer-science-(lsa)"
    },
    {
        "id": "102",
        "name": "Computer Science and Engineering",
        "hyphenated": "computer-science-and-engineering"
    },
    {
        "id": "103",
        "name": "Computer Science and Mathematics",
        "hyphenated": "computer-science-and-mathematics"
    },
    {
        "id": "104",
        "name": "Computer Science and Molecular Biology",
        "hyphenated": "computer-science-and-molecular-biology"
    },
    {
        "id": "105",
        "name": "Computer Science, Economics, and Data Science",
        "hyphenated": "computer-science-economics-and-data-science"
    },
    {
        "id": "106",
        "name": "Conservation and Resource Studies",
        "hyphenated": "conservation-and-resource-studies"
    },
    {
        "id": "107",
        "name": "Creative Writing",
        "hyphenated": "creative-writing"
    },
    {
        "id": "108",
        "name": "Culture and Politics",
        "hyphenated": "culture-and-politics"
    },
    {
        "id": "109",
        "name": "Dance",
        "hyphenated": "dance"
    },
    {
        "id": "110",
        "name": "Dance and Movement Studies",
        "hyphenated": "dance-and-movement-studies"
    },
    {
        "id": "111",
        "name": "Dance and Performance Studies",
        "hyphenated": "dance-and-performance-studies"
    },
    {
        "id": "112",
        "name": "Data Science",
        "hyphenated": "data-science"
    },
    {
        "id": "113",
        "name": "Data Science (Eng)",
        "hyphenated": "data-science-(eng)"
    },
    {
        "id": "114",
        "name": "Data Science (LSA)",
        "hyphenated": "data-science-(lsa)"
    },
    {
        "id": "115",
        "name": "Dental Hygiene",
        "hyphenated": "dental-hygiene"
    },
    {
        "id": "116",
        "name": "Drama ",
        "hyphenated": "drama"
    },
    {
        "id": "117",
        "name": "Drama and Theatre Arts",
        "hyphenated": "drama-and-theatre-arts"
    },
    {
        "id": "118",
        "name": "Dutch Studies",
        "hyphenated": "dutch-studies"
    },
    {
        "id": "119",
        "name": "Earth and Environmental Engineering",
        "hyphenated": "earth-and-environmental-engineering"
    },
    {
        "id": "120",
        "name": "Earth and Environmental Sciences",
        "hyphenated": "earth-and-environmental-sciences"
    },
    {
        "id": "121",
        "name": "Earth and Planetary Sciences",
        "hyphenated": "earth-and-planetary-sciences"
    },
    {
        "id": "122",
        "name": "Earth Science",
        "hyphenated": "earth-science"
    },
    {
        "id": "123",
        "name": "Earth Systems",
        "hyphenated": "earth-systems"
    },
    {
        "id": "124",
        "name": "Earth, Atmospheric, and Planetary Sciences",
        "hyphenated": "earth-atmospheric-and-planetary-sciences"
    },
    {
        "id": "125",
        "name": "East Asian Religion, Thought and Culture",
        "hyphenated": "east-asian-religion-thought-and-culture"
    },
    {
        "id": "126",
        "name": "East Asian Studies",
        "hyphenated": "east-asian-studies"
    },
    {
        "id": "127",
        "name": "Ecology, Evolution, and Biodiversity",
        "hyphenated": "ecology-evolution-and-biodiversity"
    },
    {
        "id": "128",
        "name": "Economics",
        "hyphenated": "economics"
    },
    {
        "id": "129",
        "name": "Economics and Human Health",
        "hyphenated": "economics-and-human-health"
    },
    {
        "id": "130",
        "name": "Economics-Mathematics",
        "hyphenated": "economics-mathematics"
    },
    {
        "id": "131",
        "name": "Economics-Philosophy",
        "hyphenated": "economics-philosophy"
    },
    {
        "id": "132",
        "name": "Economics-Statistics",
        "hyphenated": "economics-statistics"
    },
    {
        "id": "133",
        "name": "Economics\u2013Political Science",
        "hyphenated": "economics\u2013political-science"
    },
    {
        "id": "134",
        "name": "Ecosystem Management and Forestry",
        "hyphenated": "ecosystem-management-and-forestry"
    },
    {
        "id": "135",
        "name": "Educational Studies",
        "hyphenated": "educational-studies"
    },
    {
        "id": "136",
        "name": "Electrical Engineering",
        "hyphenated": "electrical-engineering"
    },
    {
        "id": "137",
        "name": "Electrical Engineering and Computer Science",
        "hyphenated": "electrical-engineering-and-computer-science"
    },
    {
        "id": "138",
        "name": "Electrical Science and Engineering",
        "hyphenated": "electrical-science-and-engineering"
    },
    {
        "id": "139",
        "name": "Elementary Teacher Education",
        "hyphenated": "elementary-teacher-education"
    },
    {
        "id": "140",
        "name": "Energy Engineering",
        "hyphenated": "energy-engineering"
    },
    {
        "id": "141",
        "name": "Energy Science & Engineering",
        "hyphenated": "energy-science-&-engineering"
    },
    {
        "id": "142",
        "name": "Engineering",
        "hyphenated": "engineering"
    },
    {
        "id": "143",
        "name": "Engineering (Aerospace)",
        "hyphenated": "engineering-(aerospace)"
    },
    {
        "id": "144",
        "name": "Engineering (Chemical)",
        "hyphenated": "engineering-(chemical)"
    },
    {
        "id": "145",
        "name": "Engineering (Mechanical)",
        "hyphenated": "engineering-(mechanical)"
    },
    {
        "id": "146",
        "name": "Engineering (Nuclear Science)",
        "hyphenated": "engineering-(nuclear-science)"
    },
    {
        "id": "147",
        "name": "Engineering Mathematics and Statistics",
        "hyphenated": "engineering-mathematics-and-statistics"
    },
    {
        "id": "148",
        "name": "Engineering Mechanics",
        "hyphenated": "engineering-mechanics"
    },
    {
        "id": "149",
        "name": "Engineering Physics",
        "hyphenated": "engineering-physics"
    },
    {
        "id": "150",
        "name": "Engineering Sciences",
        "hyphenated": "engineering-sciences"
    },
    {
        "id": "151",
        "name": "English",
        "hyphenated": "english"
    },
    {
        "id": "152",
        "name": "English and Creative Writing",
        "hyphenated": "english-and-creative-writing"
    },
    {
        "id": "153",
        "name": "English and History",
        "hyphenated": "english-and-history"
    },
    {
        "id": "154",
        "name": "English: Creative Writing",
        "hyphenated": "english:-creative-writing"
    },
    {
        "id": "155",
        "name": "English: Literature",
        "hyphenated": "english:-literature"
    },
    {
        "id": "156",
        "name": "Environment",
        "hyphenated": "environment"
    },
    {
        "id": "157",
        "name": "Environmental Biology",
        "hyphenated": "environmental-biology"
    },
    {
        "id": "158",
        "name": "Environmental Chemistry",
        "hyphenated": "environmental-chemistry"
    },
    {
        "id": "159",
        "name": "Environmental Earth Science",
        "hyphenated": "environmental-earth-science"
    },
    {
        "id": "160",
        "name": "Environmental Economics and Policy",
        "hyphenated": "environmental-economics-and-policy"
    },
    {
        "id": "161",
        "name": "Environmental Engineering",
        "hyphenated": "environmental-engineering"
    },
    {
        "id": "162",
        "name": "Environmental Engineering Science",
        "hyphenated": "environmental-engineering-science"
    },
    {
        "id": "163",
        "name": "Environmental Policy and Culture",
        "hyphenated": "environmental-policy-and-culture"
    },
    {
        "id": "164",
        "name": "Environmental Science",
        "hyphenated": "environmental-science"
    },
    {
        "id": "165",
        "name": "Environmental Sciences",
        "hyphenated": "environmental-sciences"
    },
    {
        "id": "166",
        "name": "Environmental Systems Engineering",
        "hyphenated": "environmental-systems-engineering"
    },
    {
        "id": "167",
        "name": "Ethnic Studies",
        "hyphenated": "ethnic-studies"
    },
    {
        "id": "168",
        "name": "Ethnicity and Race Studies",
        "hyphenated": "ethnicity-and-race-studies"
    },
    {
        "id": "169",
        "name": "Evolutionary Anthropology",
        "hyphenated": "evolutionary-anthropology"
    },
    {
        "id": "170",
        "name": "Evolutionary Biology of the Human Species",
        "hyphenated": "evolutionary-biology-of-the-human-species"
    },
    {
        "id": "171",
        "name": "Feminist, Gender, and Sexuality Studies",
        "hyphenated": "feminist-gender-and-sexuality-studies"
    },
    {
        "id": "172",
        "name": "Film and Media",
        "hyphenated": "film-and-media"
    },
    {
        "id": "173",
        "name": "Film and Media Studies",
        "hyphenated": "film-and-media-studies"
    },
    {
        "id": "174",
        "name": "Film, Television, and Media",
        "hyphenated": "film-television-and-media"
    },
    {
        "id": "175",
        "name": "Finance",
        "hyphenated": "finance"
    },
    {
        "id": "176",
        "name": "Financial Economics",
        "hyphenated": "financial-economics"
    },
    {
        "id": "177",
        "name": "French",
        "hyphenated": "french"
    },
    {
        "id": "178",
        "name": "French and Francophone Studies",
        "hyphenated": "french-and-francophone-studies"
    },
    {
        "id": "179",
        "name": "Gender and Health",
        "hyphenated": "gender-and-health"
    },
    {
        "id": "180",
        "name": "Gender and Sexuality Studies",
        "hyphenated": "gender-and-sexuality-studies"
    },
    {
        "id": "181",
        "name": "Gender and Women's Studies",
        "hyphenated": "gender-and-women's-studies"
    },
    {
        "id": "182",
        "name": "General Studies",
        "hyphenated": "general-studies"
    },
    {
        "id": "183",
        "name": "Genetics and Plant Biology",
        "hyphenated": "genetics-and-plant-biology"
    },
    {
        "id": "184",
        "name": "Geography",
        "hyphenated": "geography"
    },
    {
        "id": "185",
        "name": "Geological Sciences",
        "hyphenated": "geological-sciences"
    },
    {
        "id": "186",
        "name": "Geology",
        "hyphenated": "geology"
    },
    {
        "id": "187",
        "name": "Geophysics",
        "hyphenated": "geophysics"
    },
    {
        "id": "188",
        "name": "German",
        "hyphenated": "german"
    },
    {
        "id": "189",
        "name": "German Literature and Cultural History",
        "hyphenated": "german-literature-and-cultural-history"
    },
    {
        "id": "190",
        "name": "German Studies",
        "hyphenated": "german-studies"
    },
    {
        "id": "191",
        "name": "Global and Comparative Literature",
        "hyphenated": "global-and-comparative-literature"
    },
    {
        "id": "192",
        "name": "Global Business",
        "hyphenated": "global-business"
    },
    {
        "id": "193",
        "name": "Global Health  ",
        "hyphenated": "global-health"
    },
    {
        "id": "194",
        "name": "Global Health Studies",
        "hyphenated": "global-health-studies"
    },
    {
        "id": "195",
        "name": "Global Medieval Studies",
        "hyphenated": "global-medieval-studies"
    },
    {
        "id": "196",
        "name": "Global Studies and Languages",
        "hyphenated": "global-studies-and-languages"
    },
    {
        "id": "197",
        "name": "Government",
        "hyphenated": "government"
    },
    {
        "id": "198",
        "name": "Greek",
        "hyphenated": "greek"
    },
    {
        "id": "199",
        "name": "Greek (Ancient) Language and Literature",
        "hyphenated": "greek-(ancient)-language-and-literature"
    },
    {
        "id": "200",
        "name": "Greek (Modern) Language and Culture",
        "hyphenated": "greek-(modern)-language-and-culture"
    },
    {
        "id": "201",
        "name": "Greek and Latin",
        "hyphenated": "greek-and-latin"
    },
    {
        "id": "202",
        "name": "Health Care Management and Policy",
        "hyphenated": "health-care-management-and-policy"
    },
    {
        "id": "203",
        "name": "Hispanic Studies",
        "hyphenated": "hispanic-studies"
    },
    {
        "id": "204",
        "name": "History",
        "hyphenated": "history"
    },
    {
        "id": "205",
        "name": "History and Art History",
        "hyphenated": "history-and-art-history"
    },
    {
        "id": "206",
        "name": "History and Theory of Architecture",
        "hyphenated": "history-and-theory-of-architecture"
    },
    {
        "id": "207",
        "name": "History of Art",
        "hyphenated": "history-of-art"
    },
    {
        "id": "208",
        "name": "Human Biology",
        "hyphenated": "human-biology"
    },
    {
        "id": "209",
        "name": "Human Communication Sciences",
        "hyphenated": "human-communication-sciences"
    },
    {
        "id": "210",
        "name": "Human Development In Context",
        "hyphenated": "human-development-in-context"
    },
    {
        "id": "211",
        "name": "Human Health",
        "hyphenated": "human-health"
    },
    {
        "id": "212",
        "name": "Human Rights",
        "hyphenated": "human-rights"
    },
    {
        "id": "213",
        "name": "Human Science",
        "hyphenated": "human-science"
    },
    {
        "id": "214",
        "name": "Humanities",
        "hyphenated": "humanities"
    },
    {
        "id": "215",
        "name": "Humanities and Engineering",
        "hyphenated": "humanities-and-engineering"
    },
    {
        "id": "216",
        "name": "Humanities and Science",
        "hyphenated": "humanities-and-science"
    },
    {
        "id": "217",
        "name": "Iberian and Latin American Cultures",
        "hyphenated": "iberian-and-latin-american-cultures"
    },
    {
        "id": "218",
        "name": "Industrial and Operations Engineering",
        "hyphenated": "industrial-and-operations-engineering"
    },
    {
        "id": "219",
        "name": "Industrial Engineering",
        "hyphenated": "industrial-engineering"
    },
    {
        "id": "220",
        "name": "Industrial Engineering & Operations Research",
        "hyphenated": "industrial-engineering-&-operations-research"
    },
    {
        "id": "221",
        "name": "Information",
        "hyphenated": "information"
    },
    {
        "id": "222",
        "name": "Information Analysis",
        "hyphenated": "information-analysis"
    },
    {
        "id": "223",
        "name": "Information Science",
        "hyphenated": "information-science"
    },
    {
        "id": "224",
        "name": "Instrumental Music Education",
        "hyphenated": "instrumental-music-education"
    },
    {
        "id": "225",
        "name": "Integrated Engineering Studies",
        "hyphenated": "integrated-engineering-studies"
    },
    {
        "id": "226",
        "name": "Integrated Science Program",
        "hyphenated": "integrated-science-program"
    },
    {
        "id": "227",
        "name": "Integrative Biology",
        "hyphenated": "integrative-biology"
    },
    {
        "id": "228",
        "name": "Interarts Performance",
        "hyphenated": "interarts-performance"
    },
    {
        "id": "229",
        "name": "Interdisciplinary Astronomy",
        "hyphenated": "interdisciplinary-astronomy"
    },
    {
        "id": "230",
        "name": "Interdisciplinary Chemical Sciences",
        "hyphenated": "interdisciplinary-chemical-sciences"
    },
    {
        "id": "231",
        "name": "Interdisciplinary Physics",
        "hyphenated": "interdisciplinary-physics"
    },
    {
        "id": "232",
        "name": "Interdisciplinary Studies",
        "hyphenated": "interdisciplinary-studies"
    },
    {
        "id": "233",
        "name": "International Business Regional Studies",
        "hyphenated": "international-business-regional-studies"
    },
    {
        "id": "234",
        "name": "International Economics",
        "hyphenated": "international-economics"
    },
    {
        "id": "235",
        "name": "International History",
        "hyphenated": "international-history"
    },
    {
        "id": "236",
        "name": "International Political Economy",
        "hyphenated": "international-political-economy"
    },
    {
        "id": "237",
        "name": "International Political Economy and Business",
        "hyphenated": "international-political-economy-and-business"
    },
    {
        "id": "238",
        "name": "International Politics",
        "hyphenated": "international-politics"
    },
    {
        "id": "239",
        "name": "International Relations",
        "hyphenated": "international-relations"
    },
    {
        "id": "240",
        "name": "International Studies",
        "hyphenated": "international-studies"
    },
    {
        "id": "241",
        "name": "Italian",
        "hyphenated": "italian"
    },
    {
        "id": "242",
        "name": "Italian Studies",
        "hyphenated": "italian-studies"
    },
    {
        "id": "243",
        "name": "Japanese  ",
        "hyphenated": "japanese"
    },
    {
        "id": "244",
        "name": "Japanese Language",
        "hyphenated": "japanese-language"
    },
    {
        "id": "245",
        "name": "Jazz & Contemplative Studies",
        "hyphenated": "jazz-&-contemplative-studies"
    },
    {
        "id": "246",
        "name": "Jazz & Contemporary Improvisation",
        "hyphenated": "jazz-&-contemporary-improvisation"
    },
    {
        "id": "247",
        "name": "Jewish Studies",
        "hyphenated": "jewish-studies"
    },
    {
        "id": "248",
        "name": "Journalism",
        "hyphenated": "journalism"
    },
    {
        "id": "249",
        "name": "Judaic Studies",
        "hyphenated": "judaic-studies"
    },
    {
        "id": "250",
        "name": "Justice and Peace Studies",
        "hyphenated": "justice-and-peace-studies"
    },
    {
        "id": "251",
        "name": "Korean",
        "hyphenated": "korean"
    },
    {
        "id": "252",
        "name": "Landscape Architecture",
        "hyphenated": "landscape-architecture"
    },
    {
        "id": "253",
        "name": "Latin",
        "hyphenated": "latin"
    },
    {
        "id": "254",
        "name": "Latin American & Latino/a Studies",
        "hyphenated": "latin-american-&-latino/a-studies"
    },
    {
        "id": "255",
        "name": "Latin American and Caribbean Studies",
        "hyphenated": "latin-american-and-caribbean-studies"
    },
    {
        "id": "256",
        "name": "Latin Language and Literature",
        "hyphenated": "latin-language-and-literature"
    },
    {
        "id": "257",
        "name": "Latina and Latino Studies",
        "hyphenated": "latina-and-latino-studies"
    },
    {
        "id": "258",
        "name": "Latina/Latino Studies",
        "hyphenated": "latina/latino-studies"
    },
    {
        "id": "259",
        "name": "Learning and Organizational Change",
        "hyphenated": "learning-and-organizational-change"
    },
    {
        "id": "260",
        "name": "Learning Sciences",
        "hyphenated": "learning-sciences"
    },
    {
        "id": "261",
        "name": "Legal Studies",
        "hyphenated": "legal-studies"
    },
    {
        "id": "262",
        "name": "Linguistics",
        "hyphenated": "linguistics"
    },
    {
        "id": "263",
        "name": "Linguistics and Philosophy",
        "hyphenated": "linguistics-and-philosophy"
    },
    {
        "id": "264",
        "name": "Literature",
        "hyphenated": "literature"
    },
    {
        "id": "265",
        "name": "Management   ",
        "hyphenated": "management"
    },
    {
        "id": "266",
        "name": "Management Science and Engineering",
        "hyphenated": "management-science-and-engineering"
    },
    {
        "id": "267",
        "name": "Management, Leadership, and Innovation",
        "hyphenated": "management-leadership-and-innovation"
    },
    {
        "id": "268",
        "name": "Manufacturing & Design Engineering",
        "hyphenated": "manufacturing-&-design-engineering"
    },
    {
        "id": "269",
        "name": "Marine Science",
        "hyphenated": "marine-science"
    },
    {
        "id": "270",
        "name": "Marketing",
        "hyphenated": "marketing"
    },
    {
        "id": "271",
        "name": "Materials Science and Engineering",
        "hyphenated": "materials-science-and-engineering"
    },
    {
        "id": "272",
        "name": "Mathematical and Computational Science",
        "hyphenated": "mathematical-and-computational-science"
    },
    {
        "id": "273",
        "name": "Mathematical Economics",
        "hyphenated": "mathematical-economics"
    },
    {
        "id": "274",
        "name": "Mathematical Methods In the Social Sciences",
        "hyphenated": "mathematical-methods-in-the-social-sciences"
    },
    {
        "id": "275",
        "name": "Mathematical Sciences",
        "hyphenated": "mathematical-sciences"
    },
    {
        "id": "276",
        "name": "Mathematics",
        "hyphenated": "mathematics"
    },
    {
        "id": "277",
        "name": "Mathematics and Computer Science",
        "hyphenated": "mathematics-and-computer-science"
    },
    {
        "id": "278",
        "name": "Mathematics and Political Science",
        "hyphenated": "mathematics-and-political-science"
    },
    {
        "id": "279",
        "name": "Mathematics with Computer Science",
        "hyphenated": "mathematics-with-computer-science"
    },
    {
        "id": "280",
        "name": "Mathematics-Statistics",
        "hyphenated": "mathematics-statistics"
    },
    {
        "id": "281",
        "name": "Mechanical and Ocean Engineering",
        "hyphenated": "mechanical-and-ocean-engineering"
    },
    {
        "id": "282",
        "name": "Mechanical Engineering",
        "hyphenated": "mechanical-engineering"
    },
    {
        "id": "283",
        "name": "Media Studies",
        "hyphenated": "media-studies"
    },
    {
        "id": "284",
        "name": "Medical Humanities",
        "hyphenated": "medical-humanities"
    },
    {
        "id": "285",
        "name": "Microbial Biology",
        "hyphenated": "microbial-biology"
    },
    {
        "id": "286",
        "name": "Microbiology",
        "hyphenated": "microbiology"
    },
    {
        "id": "287",
        "name": "Middle East and North African Studies",
        "hyphenated": "middle-east-and-north-african-studies"
    },
    {
        "id": "288",
        "name": "Middle East Studies",
        "hyphenated": "middle-east-studies"
    },
    {
        "id": "289",
        "name": "Middle Eastern and North African Studies",
        "hyphenated": "middle-eastern-and-north-african-studies"
    },
    {
        "id": "290",
        "name": "Middle Eastern and South Asian Studies",
        "hyphenated": "middle-eastern-and-south-asian-studies"
    },
    {
        "id": "291",
        "name": "Middle Eastern Languages & Cultures",
        "hyphenated": "middle-eastern-languages-&-cultures"
    },
    {
        "id": "292",
        "name": "Middle Eastern, South Asian, and African Studies",
        "hyphenated": "middle-eastern-south-asian-and-african-studies"
    },
    {
        "id": "293",
        "name": "Molecular and Cell Biology",
        "hyphenated": "molecular-and-cell-biology"
    },
    {
        "id": "294",
        "name": "Molecular Environmental Biology",
        "hyphenated": "molecular-environmental-biology"
    },
    {
        "id": "295",
        "name": "Molecular, Cellular, and Developmental Biology",
        "hyphenated": "molecular-cellular-and-developmental-biology"
    },
    {
        "id": "296",
        "name": "Movement Science",
        "hyphenated": "movement-science"
    },
    {
        "id": "297",
        "name": "Music",
        "hyphenated": "music"
    },
    {
        "id": "298",
        "name": "Music & Technology",
        "hyphenated": "music-&-technology"
    },
    {
        "id": "299",
        "name": "Music Performance",
        "hyphenated": "music-performance"
    },
    {
        "id": "300",
        "name": "Music Performance: Brass",
        "hyphenated": "music-performance:-brass"
    },
    {
        "id": "301",
        "name": "Music Performance: Jazz Studies",
        "hyphenated": "music-performance:-jazz-studies"
    },
    {
        "id": "302",
        "name": "Music Performance: Percussion",
        "hyphenated": "music-performance:-percussion"
    },
    {
        "id": "303",
        "name": "Music Performance: Piano",
        "hyphenated": "music-performance:-piano"
    },
    {
        "id": "304",
        "name": "Music Performance: Strings",
        "hyphenated": "music-performance:-strings"
    },
    {
        "id": "305",
        "name": "Music Performance: Voice & Opera",
        "hyphenated": "music-performance:-voice-&-opera"
    },
    {
        "id": "306",
        "name": "Music Performance: Woodwinds",
        "hyphenated": "music-performance:-woodwinds"
    },
    {
        "id": "307",
        "name": "Music Studies: Cognition",
        "hyphenated": "music-studies:-cognition"
    },
    {
        "id": "308",
        "name": "Music Studies: Composition and Music Technology",
        "hyphenated": "music-studies:-composition-and-music-technology"
    },
    {
        "id": "309",
        "name": "Music Studies: Music Education",
        "hyphenated": "music-studies:-music-education"
    },
    {
        "id": "310",
        "name": "Music Studies: Music Theory",
        "hyphenated": "music-studies:-music-theory"
    },
    {
        "id": "311",
        "name": "Music Studies: Musicology",
        "hyphenated": "music-studies:-musicology"
    },
    {
        "id": "312",
        "name": "Music Theory",
        "hyphenated": "music-theory"
    },
    {
        "id": "313",
        "name": "Musical Theatre",
        "hyphenated": "musical-theatre"
    },
    {
        "id": "314",
        "name": "Musicology",
        "hyphenated": "musicology"
    },
    {
        "id": "315",
        "name": "Native American Studies",
        "hyphenated": "native-american-studies"
    },
    {
        "id": "316",
        "name": "Naval Architecture and Marine Engineering",
        "hyphenated": "naval-architecture-and-marine-engineering"
    },
    {
        "id": "317",
        "name": "Neurobiology",
        "hyphenated": "neurobiology"
    },
    {
        "id": "318",
        "name": "Neuroscience",
        "hyphenated": "neuroscience"
    },
    {
        "id": "319",
        "name": "Neuroscience and Behavior",
        "hyphenated": "neuroscience-and-behavior"
    },
    {
        "id": "320",
        "name": "Neuroscience and Behavioral Biology",
        "hyphenated": "neuroscience-and-behavioral-biology"
    },
    {
        "id": "321",
        "name": "Nuclear Engineering",
        "hyphenated": "nuclear-engineering"
    },
    {
        "id": "322",
        "name": "Nuclear Engineering and Radiological Sciences",
        "hyphenated": "nuclear-engineering-and-radiological-sciences"
    },
    {
        "id": "323",
        "name": "Nuclear Science and Engineering",
        "hyphenated": "nuclear-science-and-engineering"
    },
    {
        "id": "324",
        "name": "Nursing",
        "hyphenated": "nursing"
    },
    {
        "id": "325",
        "name": "Nutritional Sciences: Dietetics",
        "hyphenated": "nutritional-sciences:-dietetics"
    },
    {
        "id": "326",
        "name": "Nutritional Sciences: Physiology & Metabolism",
        "hyphenated": "nutritional-sciences:-physiology-&-metabolism"
    },
    {
        "id": "327",
        "name": "Nutritional Sciences: Toxicology",
        "hyphenated": "nutritional-sciences:-toxicology"
    },
    {
        "id": "328",
        "name": "Operations and Information Management",
        "hyphenated": "operations-and-information-management"
    },
    {
        "id": "329",
        "name": "Operations Research",
        "hyphenated": "operations-research"
    },
    {
        "id": "330",
        "name": "Operations Research & Management Science",
        "hyphenated": "operations-research-&-management-science"
    },
    {
        "id": "331",
        "name": "Operations Research: Engineering Management Systems",
        "hyphenated": "operations-research:-engineering-management-systems"
    },
    {
        "id": "332",
        "name": "Operations Research: Financial Engineering",
        "hyphenated": "operations-research:-financial-engineering"
    },
    {
        "id": "333",
        "name": "Organ Performance",
        "hyphenated": "organ-performance"
    },
    {
        "id": "334",
        "name": "Organ Performance and Sacred Music",
        "hyphenated": "organ-performance-and-sacred-music"
    },
    {
        "id": "335",
        "name": "Organizational Studies",
        "hyphenated": "organizational-studies"
    },
    {
        "id": "336",
        "name": "Performance Studies",
        "hyphenated": "performance-studies"
    },
    {
        "id": "337",
        "name": "Performing Arts Technology",
        "hyphenated": "performing-arts-technology"
    },
    {
        "id": "338",
        "name": "Pharmaceutical Sciences",
        "hyphenated": "pharmaceutical-sciences"
    },
    {
        "id": "339",
        "name": "Philosophy",
        "hyphenated": "philosophy"
    },
    {
        "id": "340",
        "name": "Philosophy and Religion",
        "hyphenated": "philosophy-and-religion"
    },
    {
        "id": "341",
        "name": "Philosophy and Religious Studies",
        "hyphenated": "philosophy-and-religious-studies"
    },
    {
        "id": "342",
        "name": "Philosophy, Politics & Law",
        "hyphenated": "philosophy-politics-&-law"
    },
    {
        "id": "343",
        "name": "Philosophy, Politics, and Economics",
        "hyphenated": "philosophy-politics-and-economics"
    },
    {
        "id": "344",
        "name": "Physics",
        "hyphenated": "physics"
    },
    {
        "id": "345",
        "name": "Physics and Astronomy",
        "hyphenated": "physics-and-astronomy"
    },
    {
        "id": "346",
        "name": "Piano",
        "hyphenated": "piano"
    },
    {
        "id": "347",
        "name": "Plant Biology",
        "hyphenated": "plant-biology"
    },
    {
        "id": "348",
        "name": "Planetary Science",
        "hyphenated": "planetary-science"
    },
    {
        "id": "349",
        "name": "Playwriting",
        "hyphenated": "playwriting"
    },
    {
        "id": "350",
        "name": "Polish",
        "hyphenated": "polish"
    },
    {
        "id": "351",
        "name": "Political Economy",
        "hyphenated": "political-economy"
    },
    {
        "id": "352",
        "name": "Political Science",
        "hyphenated": "political-science"
    },
    {
        "id": "353",
        "name": "Political Science - Statistics",
        "hyphenated": "political-science-statistics"
    },
    {
        "id": "354",
        "name": "Portuguese",
        "hyphenated": "portuguese"
    },
    {
        "id": "355",
        "name": "Product Design",
        "hyphenated": "product-design"
    },
    {
        "id": "356",
        "name": "Psychology",
        "hyphenated": "psychology"
    },
    {
        "id": "357",
        "name": "Psychology and Linguistics",
        "hyphenated": "psychology-and-linguistics"
    },
    {
        "id": "358",
        "name": "Public Health",
        "hyphenated": "public-health"
    },
    {
        "id": "359",
        "name": "Public Health Sciences",
        "hyphenated": "public-health-sciences"
    },
    {
        "id": "360",
        "name": "Public Policy",
        "hyphenated": "public-policy"
    },
    {
        "id": "361",
        "name": "Public Policy & Analysis",
        "hyphenated": "public-policy-&-analysis"
    },
    {
        "id": "362",
        "name": "Pure Mathematics",
        "hyphenated": "pure-mathematics"
    },
    {
        "id": "363",
        "name": "Quantitative Sciences",
        "hyphenated": "quantitative-sciences"
    },
    {
        "id": "364",
        "name": "Radio/Television/Film",
        "hyphenated": "radio/television/film"
    },
    {
        "id": "365",
        "name": "Regional and Comparative Studies",
        "hyphenated": "regional-and-comparative-studies"
    },
    {
        "id": "366",
        "name": "Regional Studies",
        "hyphenated": "regional-studies"
    },
    {
        "id": "367",
        "name": "Religion",
        "hyphenated": "religion"
    },
    {
        "id": "368",
        "name": "Religion and Anthropology",
        "hyphenated": "religion-and-anthropology"
    },
    {
        "id": "369",
        "name": "Religion and Classical Civilization",
        "hyphenated": "religion-and-classical-civilization"
    },
    {
        "id": "370",
        "name": "Religion and History",
        "hyphenated": "religion-and-history"
    },
    {
        "id": "371",
        "name": "Religion and Sociology",
        "hyphenated": "religion-and-sociology"
    },
    {
        "id": "372",
        "name": "Religious Studies",
        "hyphenated": "religious-studies"
    },
    {
        "id": "373",
        "name": "Rhetoric",
        "hyphenated": "rhetoric"
    },
    {
        "id": "374",
        "name": "Robotics",
        "hyphenated": "robotics"
    },
    {
        "id": "375",
        "name": "Romance Languages and Literatures",
        "hyphenated": "romance-languages-and-literatures"
    },
    {
        "id": "376",
        "name": "Russian",
        "hyphenated": "russian"
    },
    {
        "id": "377",
        "name": "Russian and East European Studies",
        "hyphenated": "russian-and-east-european-studies"
    },
    {
        "id": "378",
        "name": "Russian Language and Culture",
        "hyphenated": "russian-language-and-culture"
    },
    {
        "id": "379",
        "name": "Russian Language, Literature, and Culture",
        "hyphenated": "russian-language-literature-and-culture"
    },
    {
        "id": "380",
        "name": "Russian Literature and Culture",
        "hyphenated": "russian-literature-and-culture"
    },
    {
        "id": "381",
        "name": "Russian, East European, and Eurasian Studies",
        "hyphenated": "russian-east-european-and-eurasian-studies"
    },
    {
        "id": "382",
        "name": "Scandinavian",
        "hyphenated": "scandinavian"
    },
    {
        "id": "383",
        "name": "Science, Technology, and International Affairs",
        "hyphenated": "science-technology-and-international-affairs"
    },
    {
        "id": "384",
        "name": "Science, Technology, and Society",
        "hyphenated": "science-technology-and-society"
    },
    {
        "id": "385",
        "name": "Secondary Teacher Education",
        "hyphenated": "secondary-teacher-education"
    },
    {
        "id": "386",
        "name": "Secondary Teaching",
        "hyphenated": "secondary-teaching"
    },
    {
        "id": "387",
        "name": "Slavic Languages and Literatures",
        "hyphenated": "slavic-languages-and-literatures"
    },
    {
        "id": "388",
        "name": "Slavic Studies",
        "hyphenated": "slavic-studies"
    },
    {
        "id": "389",
        "name": "Social Policy",
        "hyphenated": "social-policy"
    },
    {
        "id": "390",
        "name": "Social Sciences",
        "hyphenated": "social-sciences"
    },
    {
        "id": "391",
        "name": "Social Theory and Practice",
        "hyphenated": "social-theory-and-practice"
    },
    {
        "id": "392",
        "name": "Social Welfare",
        "hyphenated": "social-welfare"
    },
    {
        "id": "393",
        "name": "Society and Environment",
        "hyphenated": "society-and-environment"
    },
    {
        "id": "394",
        "name": "Sociology",
        "hyphenated": "sociology"
    },
    {
        "id": "395",
        "name": "Sound Engineering",
        "hyphenated": "sound-engineering"
    },
    {
        "id": "396",
        "name": "South and Southeast Asian Studies",
        "hyphenated": "south-and-southeast-asian-studies"
    },
    {
        "id": "397",
        "name": "Space Sciences and Engineering",
        "hyphenated": "space-sciences-and-engineering"
    },
    {
        "id": "398",
        "name": "Spanish",
        "hyphenated": "spanish"
    },
    {
        "id": "399",
        "name": "Spanish and Linguistics",
        "hyphenated": "spanish-and-linguistics"
    },
    {
        "id": "400",
        "name": "Spanish and Portuguese",
        "hyphenated": "spanish-and-portuguese"
    },
    {
        "id": "401",
        "name": "Spanish and Portuguese Studies",
        "hyphenated": "spanish-and-portuguese-studies"
    },
    {
        "id": "402",
        "name": "Sport Management",
        "hyphenated": "sport-management"
    },
    {
        "id": "403",
        "name": "Statistics",
        "hyphenated": "statistics"
    },
    {
        "id": "404",
        "name": "Strings",
        "hyphenated": "strings"
    },
    {
        "id": "405",
        "name": "Sustainable Development",
        "hyphenated": "sustainable-development"
    },
    {
        "id": "406",
        "name": "Sustainable Environmental Design",
        "hyphenated": "sustainable-environmental-design"
    },
    {
        "id": "407",
        "name": "Symbolic Systems",
        "hyphenated": "symbolic-systems"
    },
    {
        "id": "408",
        "name": "Theater and Performance Studies",
        "hyphenated": "theater-and-performance-studies"
    },
    {
        "id": "409",
        "name": "Theater Arts",
        "hyphenated": "theater-arts"
    },
    {
        "id": "410",
        "name": "Theater Studies",
        "hyphenated": "theater-studies"
    },
    {
        "id": "411",
        "name": "Theatre",
        "hyphenated": "theatre"
    },
    {
        "id": "412",
        "name": "Theatre Arts",
        "hyphenated": "theatre-arts"
    },
    {
        "id": "413",
        "name": "Theatre Design & Production",
        "hyphenated": "theatre-design-&-production"
    },
    {
        "id": "414",
        "name": "Theatre Performance: Acting",
        "hyphenated": "theatre-performance:-acting"
    },
    {
        "id": "415",
        "name": "Theatre Performance: Directing",
        "hyphenated": "theatre-performance:-directing"
    },
    {
        "id": "416",
        "name": "Theology and Religious Studies",
        "hyphenated": "theology-and-religious-studies"
    },
    {
        "id": "417",
        "name": "Urban Studies",
        "hyphenated": "urban-studies"
    },
    {
        "id": "418",
        "name": "Urban Studies and Planning",
        "hyphenated": "urban-studies-and-planning"
    },
    {
        "id": "419",
        "name": "Urban Studies and Planning with CS",
        "hyphenated": "urban-studies-and-planning-with-cs"
    },
    {
        "id": "420",
        "name": "Urban Technology",
        "hyphenated": "urban-technology"
    },
    {
        "id": "421",
        "name": "Visual Arts",
        "hyphenated": "visual-arts"
    },
    {
        "id": "422",
        "name": "Voice",
        "hyphenated": "voice"
    },
    {
        "id": "423",
        "name": "Winds and Percussion",
        "hyphenated": "winds-and-percussion"
    },
    {
        "id": "424",
        "name": "Women's and Gender Studies",
        "hyphenated": "women's-and-gender-studies"
    },
    {
        "id": "425",
        "name": "Women\u2019s, Gender, and Sexuality Studies",
        "hyphenated": "women\u2019s-gender-and-sexuality-studies"
    },
    {
        "id": "426",
        "name": "Writing",
        "hyphenated": "writing"
    },
    {
        "id": "427",
        "name": "Yiddish Studies",
        "hyphenated": "yiddish-studies"
    },
    {
        "id": "428",
        "name": "African American and Diaspora Studies",
        "hyphenated": "african-american-and-diaspora-studies"
    },
    {
        "id": "429",
        "name": "African and Middle Eastern Studies",
        "hyphenated": "african-and-middle-eastern-studies"
    },
    {
        "id": "430",
        "name": "Africana Studies",
        "hyphenated": "africana-studies"
    },
    {
        "id": "431",
        "name": "Agricultural Sciences",
        "hyphenated": "agricultural-sciences"
    },
    {
        "id": "432",
        "name": "American Culture Studies",
        "hyphenated": "american-culture-studies"
    },
    {
        "id": "433",
        "name": "American Indian Studies",
        "hyphenated": "american-indian-studies"
    },
    {
        "id": "434",
        "name": "American Literature and Culture",
        "hyphenated": "american-literature-and-culture"
    },
    {
        "id": "435",
        "name": "Ancient and Modern Greek",
        "hyphenated": "ancient-and-modern-greek"
    },
    {
        "id": "436",
        "name": "Ancient History",
        "hyphenated": "ancient-history"
    },
    {
        "id": "437",
        "name": "Ancient Mediterranean Civilizations",
        "hyphenated": "ancient-mediterranean-civilizations"
    },
    {
        "id": "438",
        "name": "Ancient Near East and Egyptology",
        "hyphenated": "ancient-near-east-and-egyptology"
    },
    {
        "id": "439",
        "name": "Ancient Religion and Society",
        "hyphenated": "ancient-religion-and-society"
    },
    {
        "id": "440",
        "name": "Animal Science",
        "hyphenated": "animal-science"
    },
    {
        "id": "441",
        "name": "Anthropology: Global Health and Environment",
        "hyphenated": "anthropology:-global-health-and-environment"
    },
    {
        "id": "442",
        "name": "Applied and Computational Mathematics",
        "hyphenated": "applied-and-computational-mathematics"
    },
    {
        "id": "443",
        "name": "Applied Economics and Management",
        "hyphenated": "applied-economics-and-management"
    },
    {
        "id": "444",
        "name": "Applied Linguistics",
        "hyphenated": "applied-linguistics"
    },
    {
        "id": "445",
        "name": "Applied Mathematics - Biology",
        "hyphenated": "applied-mathematics-biology"
    },
    {
        "id": "446",
        "name": "Applied Mathematics - Computer Science",
        "hyphenated": "applied-mathematics-computer-science"
    },
    {
        "id": "447",
        "name": "Applied Mathematics - Economics",
        "hyphenated": "applied-mathematics-economics"
    },
    {
        "id": "448",
        "name": "Applied Science (Chemical Engineering)",
        "hyphenated": "applied-science-(chemical-engineering)"
    },
    {
        "id": "449",
        "name": "Applied Science (Electrical Engineering)",
        "hyphenated": "applied-science-(electrical-engineering)"
    },
    {
        "id": "450",
        "name": "Applied Science (Systems Science & Engineering)",
        "hyphenated": "applied-science-(systems-science-&-engineering)"
    },
    {
        "id": "451",
        "name": "Arabic Studies",
        "hyphenated": "arabic-studies"
    },
    {
        "id": "452",
        "name": "Archaeological Studies",
        "hyphenated": "archaeological-studies"
    },
    {
        "id": "453",
        "name": "Archaeology and the Ancient World",
        "hyphenated": "archaeology-and-the-ancient-world"
    },
    {
        "id": "454",
        "name": "Architectural Studies",
        "hyphenated": "architectural-studies"
    },
    {
        "id": "455",
        "name": "Architecture and the Built Environment",
        "hyphenated": "architecture-and-the-built-environment"
    },
    {
        "id": "456",
        "name": "Art History and Archaeology",
        "hyphenated": "art-history-and-archaeology"
    },
    {
        "id": "457",
        "name": "Art History and Studio Art",
        "hyphenated": "art-history-and-studio-art"
    },
    {
        "id": "458",
        "name": "Art, Film, and Visual Studies",
        "hyphenated": "art-film-and-visual-studies"
    },
    {
        "id": "459",
        "name": "Asian and Middle Eastern Studies",
        "hyphenated": "asian-and-middle-eastern-studies"
    },
    {
        "id": "460",
        "name": "Asian Humanities",
        "hyphenated": "asian-humanities"
    },
    {
        "id": "461",
        "name": "Asian Religions",
        "hyphenated": "asian-religions"
    },
    {
        "id": "462",
        "name": "Asian Societies, Cultures, and Languages",
        "hyphenated": "asian-societies-cultures-and-languages"
    },
    {
        "id": "463",
        "name": "Atmospheric and Oceanic Sciences",
        "hyphenated": "atmospheric-and-oceanic-sciences"
    },
    {
        "id": "464",
        "name": "Behavioral Biology",
        "hyphenated": "behavioral-biology"
    },
    {
        "id": "465",
        "name": "Behavioral Decision Sciences",
        "hyphenated": "behavioral-decision-sciences"
    },
    {
        "id": "466",
        "name": "Behavioral Economics",
        "hyphenated": "behavioral-economics"
    },
    {
        "id": "467",
        "name": "Biochemistry and Chemical Biology",
        "hyphenated": "biochemistry-and-chemical-biology"
    },
    {
        "id": "468",
        "name": "Biochemistry and Molecular Biology",
        "hyphenated": "biochemistry-and-molecular-biology"
    },
    {
        "id": "469",
        "name": "Biological Chemistry",
        "hyphenated": "biological-chemistry"
    },
    {
        "id": "470",
        "name": "Biology & Society",
        "hyphenated": "biology-&-society"
    },
    {
        "id": "471",
        "name": "Biomedical Engineering Sciences",
        "hyphenated": "biomedical-engineering-sciences"
    },
    {
        "id": "472",
        "name": "Biomedical Science",
        "hyphenated": "biomedical-science"
    },
    {
        "id": "473",
        "name": "Biometry & Statistics",
        "hyphenated": "biometry-&-statistics"
    },
    {
        "id": "474",
        "name": "Biophysical Chemistry",
        "hyphenated": "biophysical-chemistry"
    },
    {
        "id": "475",
        "name": "Biosciences",
        "hyphenated": "biosciences"
    },
    {
        "id": "476",
        "name": "Brazilian and Global Portuguese",
        "hyphenated": "brazilian-and-global-portuguese"
    },
    {
        "id": "477",
        "name": "Business",
        "hyphenated": "business"
    },
    {
        "id": "478",
        "name": "Business and Computer Science",
        "hyphenated": "business-and-computer-science"
    },
    {
        "id": "479",
        "name": "Business Economics",
        "hyphenated": "business-economics"
    },
    {
        "id": "480",
        "name": "Business Economics and Public Policy",
        "hyphenated": "business-economics-and-public-policy"
    },
    {
        "id": "481",
        "name": "Business, Economics, and Management",
        "hyphenated": "business-economics-and-management"
    },
    {
        "id": "482",
        "name": "Central and East European Languages and Cultures",
        "hyphenated": "central-and-east-european-languages-and-cultures"
    },
    {
        "id": "483",
        "name": "Chemical and Biological Engineering",
        "hyphenated": "chemical-and-biological-engineering"
    },
    {
        "id": "484",
        "name": "Chemical and Biomolecular Engineering",
        "hyphenated": "chemical-and-biomolecular-engineering"
    },
    {
        "id": "485",
        "name": "Chemical and Physical Biology",
        "hyphenated": "chemical-and-physical-biology"
    },
    {
        "id": "486",
        "name": "Chemistry and Physics",
        "hyphenated": "chemistry-and-physics"
    },
    {
        "id": "487",
        "name": "Chemistry-Materials Science",
        "hyphenated": "chemistry-materials-science"
    },
    {
        "id": "488",
        "name": "Chicana and Chicano Studies",
        "hyphenated": "chicana-and-chicano-studies"
    },
    {
        "id": "489",
        "name": "Child Development",
        "hyphenated": "child-development"
    },
    {
        "id": "490",
        "name": "Child Studies",
        "hyphenated": "child-studies"
    },
    {
        "id": "491",
        "name": "China & Asia-Pacific Studies",
        "hyphenated": "china-&-asia-pacific-studies"
    },
    {
        "id": "492",
        "name": "Cinema and Media Arts",
        "hyphenated": "cinema-and-media-arts"
    },
    {
        "id": "493",
        "name": "Cinema and Media Studies",
        "hyphenated": "cinema-and-media-studies"
    },
    {
        "id": "494",
        "name": "Classical and Mediterranean Studies",
        "hyphenated": "classical-and-mediterranean-studies"
    },
    {
        "id": "495",
        "name": "Classical Languages",
        "hyphenated": "classical-languages"
    },
    {
        "id": "496",
        "name": "Classical Studies",
        "hyphenated": "classical-studies"
    },
    {
        "id": "497",
        "name": "Climate Science",
        "hyphenated": "climate-science"
    },
    {
        "id": "498",
        "name": "Climate Studies",
        "hyphenated": "climate-studies"
    },
    {
        "id": "499",
        "name": "Climate System Science and Engineering",
        "hyphenated": "climate-system-science-and-engineering"
    },
    {
        "id": "500",
        "name": "Cognitive Neuroscience",
        "hyphenated": "cognitive-neuroscience"
    },
    {
        "id": "501",
        "name": "Cognitive Sciences",
        "hyphenated": "cognitive-sciences"
    },
    {
        "id": "502",
        "name": "Cognitive Studies",
        "hyphenated": "cognitive-studies"
    },
    {
        "id": "503",
        "name": "College Scholar",
        "hyphenated": "college-scholar"
    },
    {
        "id": "504",
        "name": "Communication",
        "hyphenated": "communication"
    },
    {
        "id": "505",
        "name": "Communication Design",
        "hyphenated": "communication-design"
    },
    {
        "id": "506",
        "name": "Communication of Science and Technology ",
        "hyphenated": "communication-of-science-and-technology"
    },
    {
        "id": "507",
        "name": "Comparative Arts",
        "hyphenated": "comparative-arts"
    },
    {
        "id": "508",
        "name": "Comparative Human Development",
        "hyphenated": "comparative-human-development"
    },
    {
        "id": "509",
        "name": "Comparative Study of Religion",
        "hyphenated": "comparative-study-of-religion"
    },
    {
        "id": "510",
        "name": "Computation and Neural Systems",
        "hyphenated": "computation-and-neural-systems"
    },
    {
        "id": "511",
        "name": "Computational and Applied Mathematics",
        "hyphenated": "computational-and-applied-mathematics"
    },
    {
        "id": "512",
        "name": "Computational and Systems Biology",
        "hyphenated": "computational-and-systems-biology"
    },
    {
        "id": "513",
        "name": "Computational Biology",
        "hyphenated": "computational-biology"
    },
    {
        "id": "514",
        "name": "Computational Linguistics",
        "hyphenated": "computational-linguistics"
    },
    {
        "id": "515",
        "name": "Computational Media",
        "hyphenated": "computational-media"
    },
    {
        "id": "516",
        "name": "Computer Science - Economics",
        "hyphenated": "computer-science-economics"
    },
    {
        "id": "517",
        "name": "Computer Science and Economics",
        "hyphenated": "computer-science-and-economics"
    },
    {
        "id": "518",
        "name": "Computer Science and Psychology",
        "hyphenated": "computer-science-and-psychology"
    },
    {
        "id": "519",
        "name": "Computing and Linguistics",
        "hyphenated": "computing-and-linguistics"
    },
    {
        "id": "520",
        "name": "Computing and the Arts",
        "hyphenated": "computing-and-the-arts"
    },
    {
        "id": "521",
        "name": "Contemplative Studies",
        "hyphenated": "contemplative-studies"
    },
    {
        "id": "522",
        "name": "Criminology",
        "hyphenated": "criminology"
    },
    {
        "id": "523",
        "name": "Critical Native American and Indigenous Studies",
        "hyphenated": "critical-native-american-and-indigenous-studies"
    },
    {
        "id": "524",
        "name": "Cultural Anthropology",
        "hyphenated": "cultural-anthropology"
    },
    {
        "id": "525",
        "name": "Cultures and Societies of the Middle East and North Africa",
        "hyphenated": "cultures-and-societies-of-the-middle-east-and-north-africa"
    },
    {
        "id": "526",
        "name": "Data Theory",
        "hyphenated": "data-theory"
    },
    {
        "id": "527",
        "name": "Design",
        "hyphenated": "design"
    },
    {
        "id": "528",
        "name": "Design Engineering",
        "hyphenated": "design-engineering"
    },
    {
        "id": "529",
        "name": "Design Media Arts",
        "hyphenated": "design-media-arts"
    },
    {
        "id": "530",
        "name": "Design+Environmental Analysis",
        "hyphenated": "design+environmental-analysis"
    },
    {
        "id": "531",
        "name": "Digital Media Design",
        "hyphenated": "digital-media-design"
    },
    {
        "id": "532",
        "name": "Disability Studies",
        "hyphenated": "disability-studies"
    },
    {
        "id": "533",
        "name": "Diversity, Equity & Inclusion",
        "hyphenated": "diversity-equity-&-inclusion"
    },
    {
        "id": "534",
        "name": "Early Modern World",
        "hyphenated": "early-modern-world"
    },
    {
        "id": "535",
        "name": "Earth & Climate Sciences",
        "hyphenated": "earth-&-climate-sciences"
    },
    {
        "id": "536",
        "name": "Earth and Atmospheric Sciences",
        "hyphenated": "earth-and-atmospheric-sciences"
    },
    {
        "id": "537",
        "name": "Earth and Environmental Science",
        "hyphenated": "earth-and-environmental-science"
    },
    {
        "id": "538",
        "name": "Earth, Environmental and Planetary Sciences",
        "hyphenated": "earth-environmental-and-planetary-sciences"
    },
    {
        "id": "539",
        "name": "East Asian Area Studies",
        "hyphenated": "east-asian-area-studies"
    },
    {
        "id": "540",
        "name": "East Asian Languages and Civilizations",
        "hyphenated": "east-asian-languages-and-civilizations"
    },
    {
        "id": "541",
        "name": "East Asian Languages and Cultures",
        "hyphenated": "east-asian-languages-and-cultures"
    },
    {
        "id": "542",
        "name": "East Asian Languages and Literatures",
        "hyphenated": "east-asian-languages-and-literatures"
    },
    {
        "id": "543",
        "name": "Ecology and Evolutionary Biology",
        "hyphenated": "ecology-and-evolutionary-biology"
    },
    {
        "id": "544",
        "name": "Ecology, Behavior, and Evolution",
        "hyphenated": "ecology-behavior-and-evolution"
    },
    {
        "id": "545",
        "name": "Ecology, Evolution & Organismal Biology",
        "hyphenated": "ecology-evolution-&-organismal-biology"
    },
    {
        "id": "546",
        "name": "Economics and Computer Science",
        "hyphenated": "economics-and-computer-science"
    },
    {
        "id": "547",
        "name": "Economics and Engineering",
        "hyphenated": "economics-and-engineering"
    },
    {
        "id": "548",
        "name": "Economics and History ",
        "hyphenated": "economics-and-history"
    },
    {
        "id": "549",
        "name": "Economics and Mathematics",
        "hyphenated": "economics-and-mathematics"
    },
    {
        "id": "550",
        "name": "Economics and Strategy",
        "hyphenated": "economics-and-strategy"
    },
    {
        "id": "551",
        "name": "Economics-Accounting",
        "hyphenated": "economics-accounting"
    },
    {
        "id": "552",
        "name": "Education and Social Transformation",
        "hyphenated": "education-and-social-transformation"
    },
    {
        "id": "553",
        "name": "Education Studies",
        "hyphenated": "education-studies"
    },
    {
        "id": "554",
        "name": "Egyptology and Assyriology",
        "hyphenated": "egyptology-and-assyriology"
    },
    {
        "id": "555",
        "name": "Electrical and Computer Engineering",
        "hyphenated": "electrical-and-computer-engineering"
    },
    {
        "id": "556",
        "name": "Elementary Education",
        "hyphenated": "elementary-education"
    },
    {
        "id": "557",
        "name": "Engineering (Civil and Environmental)",
        "hyphenated": "engineering-(civil-and-environmental)"
    },
    {
        "id": "558",
        "name": "Engineering (Materials Science)",
        "hyphenated": "engineering-(materials-science)"
    },
    {
        "id": "559",
        "name": "Engineering and Applied Science",
        "hyphenated": "engineering-and-applied-science"
    },
    {
        "id": "560",
        "name": "Engineering Geology",
        "hyphenated": "engineering-geology"
    },
    {
        "id": "561",
        "name": "Engineering Science",
        "hyphenated": "engineering-science"
    },
    {
        "id": "562",
        "name": "Engineering Sciences (Chemical)",
        "hyphenated": "engineering-sciences-(chemical)"
    },
    {
        "id": "563",
        "name": "Engineering Sciences (Electrical)",
        "hyphenated": "engineering-sciences-(electrical)"
    },
    {
        "id": "564",
        "name": "Engineering Sciences (Environmental)",
        "hyphenated": "engineering-sciences-(environmental)"
    },
    {
        "id": "565",
        "name": "Engineering Sciences (Mechanical)",
        "hyphenated": "engineering-sciences-(mechanical)"
    },
    {
        "id": "566",
        "name": "English Language and Literature",
        "hyphenated": "english-language-and-literature"
    },
    {
        "id": "567",
        "name": "English Literature",
        "hyphenated": "english-literature"
    },
    {
        "id": "568",
        "name": "Entomology",
        "hyphenated": "entomology"
    },
    {
        "id": "569",
        "name": "Entrepreneurship",
        "hyphenated": "entrepreneurship"
    },
    {
        "id": "570",
        "name": "Entrepreneurship and Innovation",
        "hyphenated": "entrepreneurship-and-innovation"
    },
    {
        "id": "571",
        "name": "Environment & Sustainability",
        "hyphenated": "environment-&-sustainability"
    },
    {
        "id": "572",
        "name": "Environment, Economics, and Politics",
        "hyphenated": "environment-economics-and-politics"
    },
    {
        "id": "573",
        "name": "Environment, Geography, and Urbanization",
        "hyphenated": "environment-geography-and-urbanization"
    },
    {
        "id": "574",
        "name": "Environmental Analysis",
        "hyphenated": "environmental-analysis"
    },
    {
        "id": "575",
        "name": "Environmental Science and Engineering",
        "hyphenated": "environmental-science-and-engineering"
    },
    {
        "id": "576",
        "name": "Environmental Science and Public Policy",
        "hyphenated": "environmental-science-and-public-policy"
    },
    {
        "id": "577",
        "name": "Environmental Sciences and Policy",
        "hyphenated": "environmental-sciences-and-policy"
    },
    {
        "id": "578",
        "name": "Environmental Sociology",
        "hyphenated": "environmental-sociology"
    },
    {
        "id": "579",
        "name": "Environmental Studies",
        "hyphenated": "environmental-studies"
    },
    {
        "id": "580",
        "name": "Environmental, Social and Governance Factors for Business",
        "hyphenated": "environmental-social-and-governance-factors-for-business"
    },
    {
        "id": "581",
        "name": "Ethics, Politics, and Economics",
        "hyphenated": "ethics-politics-and-economics"
    },
    {
        "id": "582",
        "name": "Ethnicity, Race, and Migration",
        "hyphenated": "ethnicity-race-and-migration"
    },
    {
        "id": "583",
        "name": "Ethnomusicology",
        "hyphenated": "ethnomusicology"
    },
    {
        "id": "584",
        "name": "European Languages and Transcultural Studies",
        "hyphenated": "european-languages-and-transcultural-studies"
    },
    {
        "id": "585",
        "name": "European Languages and Transcultural Studies with French and Francophone",
        "hyphenated": "european-languages-and-transcultural-studies-with-french-and-francophone"
    },
    {
        "id": "586",
        "name": "European Languages and Transcultural Studies with German",
        "hyphenated": "european-languages-and-transcultural-studies-with-german"
    },
    {
        "id": "587",
        "name": "European Languages and Transcultural Studies with Italian",
        "hyphenated": "european-languages-and-transcultural-studies-with-italian"
    },
    {
        "id": "588",
        "name": "European Languages and Transcultural Studies with Scandinavian",
        "hyphenated": "european-languages-and-transcultural-studies-with-scandinavian"
    },
    {
        "id": "589",
        "name": "European Studies",
        "hyphenated": "european-studies"
    },
    {
        "id": "590",
        "name": "Fashion Design",
        "hyphenated": "fashion-design"
    },
    {
        "id": "591",
        "name": "Fiber Science",
        "hyphenated": "fiber-science"
    },
    {
        "id": "592",
        "name": "Film and Television",
        "hyphenated": "film-and-television"
    },
    {
        "id": "593",
        "name": "Film Studies",
        "hyphenated": "film-studies"
    },
    {
        "id": "594",
        "name": "Financial Actuarial Mathematics",
        "hyphenated": "financial-actuarial-mathematics"
    },
    {
        "id": "595",
        "name": "Fine Arts",
        "hyphenated": "fine-arts"
    },
    {
        "id": "596",
        "name": "Folklore and Mythology",
        "hyphenated": "folklore-and-mythology"
    },
    {
        "id": "597",
        "name": "Food Science",
        "hyphenated": "food-science"
    },
    {
        "id": "598",
        "name": "French and European Studies",
        "hyphenated": "french-and-european-studies"
    },
    {
        "id": "599",
        "name": "French Language & Literature",
        "hyphenated": "french-language-&-literature"
    },
    {
        "id": "600",
        "name": "French Studies",
        "hyphenated": "french-studies"
    },
    {
        "id": "601",
        "name": "Fundamentals: Issues and Texts",
        "hyphenated": "fundamentals:-issues-and-texts"
    },
    {
        "id": "602",
        "name": "Gender Studies",
        "hyphenated": "gender-studies"
    },
    {
        "id": "603",
        "name": "Gender, Sexuality and Women's Studies",
        "hyphenated": "gender-sexuality-and-women's-studies"
    },
    {
        "id": "604",
        "name": "Gender, Sexuality, and Feminist Studies",
        "hyphenated": "gender-sexuality-and-feminist-studies"
    },
    {
        "id": "605",
        "name": "General Engineering",
        "hyphenated": "general-engineering"
    },
    {
        "id": "606",
        "name": "Geographical Sciences",
        "hyphenated": "geographical-sciences"
    },
    {
        "id": "607",
        "name": "Geography/Environmental Studies",
        "hyphenated": "geography/environmental-studies"
    },
    {
        "id": "608",
        "name": "Geological and Planetary Science",
        "hyphenated": "geological-and-planetary-science"
    },
    {
        "id": "609",
        "name": "Geology - Chemistry",
        "hyphenated": "geology-chemistry"
    },
    {
        "id": "610",
        "name": "Geology - Physics/Mathematics",
        "hyphenated": "geology-physics/mathematics"
    },
    {
        "id": "611",
        "name": "Geology-Biology",
        "hyphenated": "geology-biology"
    },
    {
        "id": "612",
        "name": "Geophysical Sciences",
        "hyphenated": "geophysical-sciences"
    },
    {
        "id": "613",
        "name": "Geosciences",
        "hyphenated": "geosciences"
    },
    {
        "id": "614",
        "name": "German and European Studies",
        "hyphenated": "german-and-european-studies"
    },
    {
        "id": "615",
        "name": "Germanic Languages and Literatures",
        "hyphenated": "germanic-languages-and-literatures"
    },
    {
        "id": "616",
        "name": "Germanic Studies",
        "hyphenated": "germanic-studies"
    },
    {
        "id": "617",
        "name": "Global Affairs",
        "hyphenated": "global-affairs"
    },
    {
        "id": "618",
        "name": "Global and Public Health Sciences",
        "hyphenated": "global-and-public-health-sciences"
    },
    {
        "id": "619",
        "name": "Global Cultural Studies",
        "hyphenated": "global-cultural-studies"
    },
    {
        "id": "620",
        "name": "Global Development",
        "hyphenated": "global-development"
    },
    {
        "id": "621",
        "name": "Global Jazz Studies",
        "hyphenated": "global-jazz-studies"
    },
    {
        "id": "622",
        "name": "Global Studies",
        "hyphenated": "global-studies"
    },
    {
        "id": "623",
        "name": "Health & Societies",
        "hyphenated": "health-&-societies"
    },
    {
        "id": "624",
        "name": "Health and Human Biology",
        "hyphenated": "health-and-human-biology"
    },
    {
        "id": "625",
        "name": "Health Care Policy",
        "hyphenated": "health-care-policy"
    },
    {
        "id": "626",
        "name": "Health Sciences",
        "hyphenated": "health-sciences"
    },
    {
        "id": "627",
        "name": "Healthcare Management",
        "hyphenated": "healthcare-management"
    },
    {
        "id": "628",
        "name": "Hebrew",
        "hyphenated": "hebrew"
    },
    {
        "id": "629",
        "name": "Hispanic Literatures and Cultures",
        "hyphenated": "hispanic-literatures-and-cultures"
    },
    {
        "id": "630",
        "name": "History and Literature",
        "hyphenated": "history-and-literature"
    },
    {
        "id": "631",
        "name": "History and Philosophy of Science",
        "hyphenated": "history-and-philosophy-of-science"
    },
    {
        "id": "632",
        "name": "History and Science",
        "hyphenated": "history-and-science"
    },
    {
        "id": "633",
        "name": "History of Art and Architecture",
        "hyphenated": "history-of-art-and-architecture"
    },
    {
        "id": "634",
        "name": "History of Science, Medicine, and Public Health",
        "hyphenated": "history-of-science-medicine-and-public-health"
    },
    {
        "id": "635",
        "name": "History of Science, Medicine, and Technology",
        "hyphenated": "history-of-science-medicine-and-technology"
    },
    {
        "id": "636",
        "name": "History, Philosophy, and Social Studies of Science and Medicine",
        "hyphenated": "history-philosophy-and-social-studies-of-science-and-medicine"
    },
    {
        "id": "637",
        "name": "Hotel Administration",
        "hyphenated": "hotel-administration"
    },
    {
        "id": "638",
        "name": "Human and Organizational Development",
        "hyphenated": "human-and-organizational-development"
    },
    {
        "id": "639",
        "name": "Human Biology and Society",
        "hyphenated": "human-biology-and-society"
    },
    {
        "id": "640",
        "name": "Human Biology, Health, and Society",
        "hyphenated": "human-biology-health-and-society"
    },
    {
        "id": "641",
        "name": "Human Development",
        "hyphenated": "human-development"
    },
    {
        "id": "642",
        "name": "Human Developmental and Regenerative Biology",
        "hyphenated": "human-developmental-and-regenerative-biology"
    },
    {
        "id": "643",
        "name": "Human Evolutionary Biology",
        "hyphenated": "human-evolutionary-biology"
    },
    {
        "id": "644",
        "name": "Independent Concentration",
        "hyphenated": "independent-concentration"
    },
    {
        "id": "645",
        "name": "Independent Major - Arts & Sciences",
        "hyphenated": "independent-major-arts-&-sciences"
    },
    {
        "id": "646",
        "name": "Independent Major - Engineering",
        "hyphenated": "independent-major-engineering"
    },
    {
        "id": "647",
        "name": "Individualized Major",
        "hyphenated": "individualized-major"
    },
    {
        "id": "648",
        "name": "Industrial and Labor Relations",
        "hyphenated": "industrial-and-labor-relations"
    },
    {
        "id": "649",
        "name": "Information and Data Sciences",
        "hyphenated": "information-and-data-sciences"
    },
    {
        "id": "650",
        "name": "Information Science, Systems, and Technology",
        "hyphenated": "information-science-systems-and-technology"
    },
    {
        "id": "651",
        "name": "Inquiry and Research in the Humanities",
        "hyphenated": "inquiry-and-research-in-the-humanities"
    },
    {
        "id": "652",
        "name": "Integrated Sciences",
        "hyphenated": "integrated-sciences"
    },
    {
        "id": "653",
        "name": "Interdepartmental Major",
        "hyphenated": "interdepartmental-major"
    },
    {
        "id": "654",
        "name": "Interdisciplinary Engineering & Applied Science",
        "hyphenated": "interdisciplinary-engineering-&-applied-science"
    },
    {
        "id": "655",
        "name": "Interdisciplinary Project in the Humanities",
        "hyphenated": "interdisciplinary-project-in-the-humanities"
    },
    {
        "id": "656",
        "name": "International and Public Affairs ",
        "hyphenated": "international-and-public-affairs"
    },
    {
        "id": "657",
        "name": "International Comparative Studies",
        "hyphenated": "international-comparative-studies"
    },
    {
        "id": "658",
        "name": "International Development Studies",
        "hyphenated": "international-development-studies"
    },
    {
        "id": "659",
        "name": "Iranian Studies",
        "hyphenated": "iranian-studies"
    },
    {
        "id": "660",
        "name": "Italian & European Studies",
        "hyphenated": "italian-&-european-studies"
    },
    {
        "id": "661",
        "name": "Jazz Studies",
        "hyphenated": "jazz-studies"
    },
    {
        "id": "662",
        "name": "Jewish, Islamic, and Middle Eastern Studies",
        "hyphenated": "jewish-islamic-and-middle-eastern-studies"
    },
    {
        "id": "663",
        "name": "K-12 Teacher Education",
        "hyphenated": "k-12-teacher-education"
    },
    {
        "id": "664",
        "name": "Labor Studies",
        "hyphenated": "labor-studies"
    },
    {
        "id": "665",
        "name": "Latin American and Iberian Languages, Literatures, and Cultures",
        "hyphenated": "latin-american-and-iberian-languages-literatures-and-cultures"
    },
    {
        "id": "666",
        "name": "Latin American and Latinx Studies",
        "hyphenated": "latin-american-and-latinx-studies"
    },
    {
        "id": "667",
        "name": "Latin American Studies",
        "hyphenated": "latin-american-studies"
    },
    {
        "id": "668",
        "name": "Latin American, Caribbean, and Latinx Studies",
        "hyphenated": "latin-american-caribbean-and-latinx-studies"
    },
    {
        "id": "669",
        "name": "Latin American, Latino, & Caribbean Studies",
        "hyphenated": "latin-american-latino-&-caribbean-studies"
    },
    {
        "id": "670",
        "name": "Latino and Latina Studies",
        "hyphenated": "latino-and-latina-studies"
    },
    {
        "id": "671",
        "name": "Law, History, and Society",
        "hyphenated": "law-history-and-society"
    },
    {
        "id": "672",
        "name": "Law, Letters, and Society",
        "hyphenated": "law-letters-and-society"
    },
    {
        "id": "673",
        "name": "Legal Studies and Business Ethics",
        "hyphenated": "legal-studies-and-business-ethics"
    },
    {
        "id": "674",
        "name": "Linguistics and Anthropology",
        "hyphenated": "linguistics-and-anthropology"
    },
    {
        "id": "675",
        "name": "Linguistics and Asian Languages and Cultures",
        "hyphenated": "linguistics-and-asian-languages-and-cultures"
    },
    {
        "id": "676",
        "name": "Linguistics and Computer Science",
        "hyphenated": "linguistics-and-computer-science"
    },
    {
        "id": "677",
        "name": "Linguistics and English",
        "hyphenated": "linguistics-and-english"
    },
    {
        "id": "678",
        "name": "Linguistics and Psychology",
        "hyphenated": "linguistics-and-psychology"
    },
    {
        "id": "679",
        "name": "Linguistics and Spanish",
        "hyphenated": "linguistics-and-spanish"
    },
    {
        "id": "680",
        "name": "Literary Arts ",
        "hyphenated": "literary-arts"
    },
    {
        "id": "681",
        "name": "Logic, Information, and Computation",
        "hyphenated": "logic-information-and-computation"
    },
    {
        "id": "682",
        "name": "Managerial Economics and Organizational Sciences",
        "hyphenated": "managerial-economics-and-organizational-sciences"
    },
    {
        "id": "683",
        "name": "Marine Biology",
        "hyphenated": "marine-biology"
    },
    {
        "id": "684",
        "name": "Marine Science and Conservation",
        "hyphenated": "marine-science-and-conservation"
    },
    {
        "id": "685",
        "name": "Marketing & Communication",
        "hyphenated": "marketing-&-communication"
    },
    {
        "id": "686",
        "name": "Marketing & Operations Management",
        "hyphenated": "marketing-&-operations-management"
    },
    {
        "id": "687",
        "name": "Materials Engineering",
        "hyphenated": "materials-engineering"
    },
    {
        "id": "688",
        "name": "Materials Science",
        "hyphenated": "materials-science"
    },
    {
        "id": "689",
        "name": "Materials Science and NanoEngineering",
        "hyphenated": "materials-science-and-nanoengineering"
    },
    {
        "id": "690",
        "name": "Math+CS on Data Science",
        "hyphenated": "math+cs-on-data-science"
    },
    {
        "id": "691",
        "name": "Mathematical Data Science",
        "hyphenated": "mathematical-data-science"
    },
    {
        "id": "692",
        "name": "Mathematical Economics Analysis",
        "hyphenated": "mathematical-economics-analysis"
    },
    {
        "id": "693",
        "name": "Mathematics - Computer Science",
        "hyphenated": "mathematics-computer-science"
    },
    {
        "id": "694",
        "name": "Mathematics - Economics",
        "hyphenated": "mathematics-economics"
    },
    {
        "id": "695",
        "name": "Mathematics and Economics",
        "hyphenated": "mathematics-and-economics"
    },
    {
        "id": "696",
        "name": "Mathematics and Philosophy",
        "hyphenated": "mathematics-and-philosophy"
    },
    {
        "id": "697",
        "name": "Mathematics and Physics",
        "hyphenated": "mathematics-and-physics"
    },
    {
        "id": "698",
        "name": "Mathematics for Teaching",
        "hyphenated": "mathematics-for-teaching"
    },
    {
        "id": "699",
        "name": "Mathematics of Computation",
        "hyphenated": "mathematics-of-computation"
    },
    {
        "id": "700",
        "name": "Mathematics/Applied Science",
        "hyphenated": "mathematics/applied-science"
    },
    {
        "id": "701",
        "name": "Mathematics/Atmospheric and Oceanic Sciences",
        "hyphenated": "mathematics/atmospheric-and-oceanic-sciences"
    },
    {
        "id": "702",
        "name": "Mechanical Engineering and Applied Mechanics",
        "hyphenated": "mechanical-engineering-and-applied-mechanics"
    },
    {
        "id": "703",
        "name": "Media Arts and Design",
        "hyphenated": "media-arts-and-design"
    },
    {
        "id": "704",
        "name": "Medicine, Health, and Society",
        "hyphenated": "medicine-health-and-society"
    },
    {
        "id": "705",
        "name": "Medicine, Science, and the Humanities",
        "hyphenated": "medicine-science-and-the-humanities"
    },
    {
        "id": "706",
        "name": "Medieval and Early Modern Studies",
        "hyphenated": "medieval-and-early-modern-studies"
    },
    {
        "id": "707",
        "name": "Medieval and Renaissance Studies",
        "hyphenated": "medieval-and-renaissance-studies"
    },
    {
        "id": "708",
        "name": "Medieval Cultures",
        "hyphenated": "medieval-cultures"
    },
    {
        "id": "709",
        "name": "Medieval Studies",
        "hyphenated": "medieval-studies"
    },
    {
        "id": "710",
        "name": "Microbiology, Immunology, and Molecular Genetics",
        "hyphenated": "microbiology-immunology-and-molecular-genetics"
    },
    {
        "id": "711",
        "name": "Middle Eastern Studies",
        "hyphenated": "middle-eastern-studies"
    },
    {
        "id": "712",
        "name": "Middle School Teacher Education",
        "hyphenated": "middle-school-teacher-education"
    },
    {
        "id": "713",
        "name": "Modern Culture and Media",
        "hyphenated": "modern-culture-and-media"
    },
    {
        "id": "714",
        "name": "Modern Middle East Studies",
        "hyphenated": "modern-middle-east-studies"
    },
    {
        "id": "715",
        "name": "Modern Middle Eastern Studies",
        "hyphenated": "modern-middle-eastern-studies"
    },
    {
        "id": "716",
        "name": "Molecular and Cellular Biology",
        "hyphenated": "molecular-and-cellular-biology"
    },
    {
        "id": "717",
        "name": "Molecular Biology",
        "hyphenated": "molecular-biology"
    },
    {
        "id": "718",
        "name": "Molecular Biophysics and Biochemistry",
        "hyphenated": "molecular-biophysics-and-biochemistry"
    },
    {
        "id": "719",
        "name": "Molecular Engineering",
        "hyphenated": "molecular-engineering"
    },
    {
        "id": "720",
        "name": "Molecular, Cell, and Developmental Biology",
        "hyphenated": "molecular-cell-and-developmental-biology"
    },
    {
        "id": "721",
        "name": "Music Composition",
        "hyphenated": "music-composition"
    },
    {
        "id": "722",
        "name": "Music Education ",
        "hyphenated": "music-education"
    },
    {
        "id": "723",
        "name": "Music Industry",
        "hyphenated": "music-industry"
    },
    {
        "id": "724",
        "name": "Music Integrated Studies",
        "hyphenated": "music-integrated-studies"
    },
    {
        "id": "725",
        "name": "Musical Arts",
        "hyphenated": "musical-arts"
    },
    {
        "id": "726",
        "name": "Native American and Indigenous Studies",
        "hyphenated": "native-american-and-indigenous-studies"
    },
    {
        "id": "727",
        "name": "Natural Sciences",
        "hyphenated": "natural-sciences"
    },
    {
        "id": "728",
        "name": "Near Eastern Languages and Civilizations",
        "hyphenated": "near-eastern-languages-and-civilizations"
    },
    {
        "id": "729",
        "name": "Near Eastern Studies",
        "hyphenated": "near-eastern-studies"
    },
    {
        "id": "730",
        "name": "Networked & Social Systems Engineering",
        "hyphenated": "networked-&-social-systems-engineering"
    },
    {
        "id": "731",
        "name": "Nordic Studies",
        "hyphenated": "nordic-studies"
    },
    {
        "id": "732",
        "name": "Nutrition Science",
        "hyphenated": "nutrition-science"
    },
    {
        "id": "733",
        "name": "Nutritional Sciences",
        "hyphenated": "nutritional-sciences"
    },
    {
        "id": "734",
        "name": "Operations and Supply Chain Management",
        "hyphenated": "operations-and-supply-chain-management"
    },
    {
        "id": "735",
        "name": "Operations Research and Engineering",
        "hyphenated": "operations-research-and-engineering"
    },
    {
        "id": "736",
        "name": "Operations Research and Financial Engineering",
        "hyphenated": "operations-research-and-financial-engineering"
    },
    {
        "id": "737",
        "name": "Operations, Information & Decisions",
        "hyphenated": "operations-information-&-decisions"
    },
    {
        "id": "738",
        "name": "Organismal Biology",
        "hyphenated": "organismal-biology"
    },
    {
        "id": "739",
        "name": "Organization and Strategic Management",
        "hyphenated": "organization-and-strategic-management"
    },
    {
        "id": "740",
        "name": "Performing and Media Arts",
        "hyphenated": "performing-and-media-arts"
    },
    {
        "id": "741",
        "name": "Philosophy and Public Affairs",
        "hyphenated": "philosophy-and-public-affairs"
    },
    {
        "id": "742",
        "name": "Philosophy-Neuroscience-Psychology",
        "hyphenated": "philosophy-neuroscience-psychology"
    },
    {
        "id": "743",
        "name": "Physics and Geosciences",
        "hyphenated": "physics-and-geosciences"
    },
    {
        "id": "744",
        "name": "Physics and Philosophy",
        "hyphenated": "physics-and-philosophy"
    },
    {
        "id": "745",
        "name": "Physiological Science",
        "hyphenated": "physiological-science"
    },
    {
        "id": "746",
        "name": "Plant Sciences",
        "hyphenated": "plant-sciences"
    },
    {
        "id": "747",
        "name": "Politics",
        "hyphenated": "politics"
    },
    {
        "id": "748",
        "name": "Portuguese and Brazilian Studies",
        "hyphenated": "portuguese-and-brazilian-studies"
    },
    {
        "id": "749",
        "name": "Practice of Art",
        "hyphenated": "practice-of-art"
    },
    {
        "id": "750",
        "name": "Psychobiology",
        "hyphenated": "psychobiology"
    },
    {
        "id": "751",
        "name": "Psychological & Brain Sciences",
        "hyphenated": "psychological-&-brain-sciences"
    },
    {
        "id": "752",
        "name": "Psychological & Brain Sciences: Cognitive Neuroscience",
        "hyphenated": "psychological-&-brain-sciences:-cognitive-neuroscience"
    },
    {
        "id": "753",
        "name": "Public Affairs",
        "hyphenated": "public-affairs"
    },
    {
        "id": "754",
        "name": "Public Health Studies",
        "hyphenated": "public-health-studies"
    },
    {
        "id": "755",
        "name": "Public Policy Studies",
        "hyphenated": "public-policy-studies"
    },
    {
        "id": "756",
        "name": "Quantitative Social Science",
        "hyphenated": "quantitative-social-science"
    },
    {
        "id": "757",
        "name": "Race, Diaspora, and Indigeneity",
        "hyphenated": "race-diaspora-and-indigeneity"
    },
    {
        "id": "758",
        "name": "Real Estate",
        "hyphenated": "real-estate"
    },
    {
        "id": "759",
        "name": "Retailing",
        "hyphenated": "retailing"
    },
    {
        "id": "760",
        "name": "Romance Languages",
        "hyphenated": "romance-languages"
    },
    {
        "id": "761",
        "name": "Romance Languages, Literatures, and Cultures",
        "hyphenated": "romance-languages-literatures-and-cultures"
    },
    {
        "id": "762",
        "name": "Romance Studies",
        "hyphenated": "romance-studies"
    },
    {
        "id": "763",
        "name": "Russian Area Studies",
        "hyphenated": "russian-area-studies"
    },
    {
        "id": "764",
        "name": "Russian Language and Literature",
        "hyphenated": "russian-language-and-literature"
    },
    {
        "id": "765",
        "name": "Russian Studies",
        "hyphenated": "russian-studies"
    },
    {
        "id": "766",
        "name": "Scandinavian Studies",
        "hyphenated": "scandinavian-studies"
    },
    {
        "id": "767",
        "name": "Science & Technology Studies",
        "hyphenated": "science-&-technology-studies"
    },
    {
        "id": "768",
        "name": "Science Management",
        "hyphenated": "science-management"
    },
    {
        "id": "769",
        "name": "Secondary Education",
        "hyphenated": "secondary-education"
    },
    {
        "id": "770",
        "name": "Slavic and Eurasian Studies",
        "hyphenated": "slavic-and-eurasian-studies"
    },
    {
        "id": "771",
        "name": "Social Analysis and Research",
        "hyphenated": "social-analysis-and-research"
    },
    {
        "id": "772",
        "name": "Social Policy Analysis",
        "hyphenated": "social-policy-analysis"
    },
    {
        "id": "773",
        "name": "Social Studies",
        "hyphenated": "social-studies"
    },
    {
        "id": "774",
        "name": "South Asia Studies",
        "hyphenated": "south-asia-studies"
    },
    {
        "id": "775",
        "name": "South Asian Languages and Civilizations",
        "hyphenated": "south-asian-languages-and-civilizations"
    },
    {
        "id": "776",
        "name": "South Asian Studies",
        "hyphenated": "south-asian-studies"
    },
    {
        "id": "777",
        "name": "Southeast Asian Studies",
        "hyphenated": "southeast-asian-studies"
    },
    {
        "id": "778",
        "name": "Spanish and Community and Culture",
        "hyphenated": "spanish-and-community-and-culture"
    },
    {
        "id": "779",
        "name": "Spanish and European Studies",
        "hyphenated": "spanish-and-european-studies"
    },
    {
        "id": "780",
        "name": "Spanish Language, Literature, and Culture",
        "hyphenated": "spanish-language-literature-and-culture"
    },
    {
        "id": "781",
        "name": "Spanish, Latin American, and Latino/a Studies",
        "hyphenated": "spanish-latin-american-and-latino/a-studies"
    },
    {
        "id": "782",
        "name": "Special Concentration",
        "hyphenated": "special-concentration"
    },
    {
        "id": "783",
        "name": "Special Divisional Major",
        "hyphenated": "special-divisional-major"
    },
    {
        "id": "784",
        "name": "Special Education",
        "hyphenated": "special-education"
    },
    {
        "id": "785",
        "name": "Sports Medicine and Exercise Physiology",
        "hyphenated": "sports-medicine-and-exercise-physiology"
    },
    {
        "id": "786",
        "name": "Statistical Science",
        "hyphenated": "statistical-science"
    },
    {
        "id": "787",
        "name": "Statistics and Data Science",
        "hyphenated": "statistics-and-data-science"
    },
    {
        "id": "788",
        "name": "Statistics+CS on Data Science",
        "hyphenated": "statistics+cs-on-data-science"
    },
    {
        "id": "789",
        "name": "Studio Art",
        "hyphenated": "studio-art"
    },
    {
        "id": "790",
        "name": "Study of Religion",
        "hyphenated": "study-of-religion"
    },
    {
        "id": "791",
        "name": "Sustainable Architecture + Engineering",
        "hyphenated": "sustainable-architecture-+-engineering"
    },
    {
        "id": "792",
        "name": "Systems Engineering",
        "hyphenated": "systems-engineering"
    },
    {
        "id": "793",
        "name": "Systems Science and Engineering",
        "hyphenated": "systems-science-and-engineering"
    },
    {
        "id": "794",
        "name": "Theater",
        "hyphenated": "theater"
    },
    {
        "id": "795",
        "name": "Theater, Dance & Media",
        "hyphenated": "theater-dance-&-media"
    },
    {
        "id": "796",
        "name": "Theatre Arts and Performance Studies",
        "hyphenated": "theatre-arts-and-performance-studies"
    },
    {
        "id": "797",
        "name": "Tutorial Studies",
        "hyphenated": "tutorial-studies"
    },
    {
        "id": "798",
        "name": "Urban and Regional Studies",
        "hyphenated": "urban-and-regional-studies"
    },
    {
        "id": "799",
        "name": "Visual & Media Studies",
        "hyphenated": "visual-&-media-studies"
    },
    {
        "id": "800",
        "name": "Viticulture and Enology",
        "hyphenated": "viticulture-and-enology"
    },
    {
        "id": "801",
        "name": "Women, Gender, and Sexuality",
        "hyphenated": "women-gender-and-sexuality"
    },
    {
        "id": "802",
        "name": "World Arts and Cultures",
        "hyphenated": "world-arts-and-cultures"
    },
    {
        "id": "803",
        "name": "Writing Seminars",
        "hyphenated": "writing-seminars"
    }
];
