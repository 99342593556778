import { useState, useEffect } from 'react'
import axios from "axios"
import { Link, useParams, useNavigate } from 'react-router-dom'
import { Aside } from '../components/TestScoreStrategy/Aside'
import { Cards } from '../components/TestScoreStrategy/Cards'
import { URL, pageRoutes, sortLogic, updateTitleAndDescription } from '../components/dictionary'
import { useAuth, getAuth } from '../modules/auth'
import { Error404 } from '../modules/errors/components/Error404'
import { LoadingIndicator } from '../components/LoadingIndicator'

let apiToken: any = undefined
let permissionGroups: any = undefined

if (getAuth()?.api_token && getAuth()?.groups) {
  apiToken = getAuth()?.api_token
  permissionGroups = getAuth()?.groups
}

const TestScoreStrategy = ({ user, setUser, userCollegeInterest, setUserCollegeInterest, collegesId, setPrompt, setNavigateTo }) => {

  const { currentUser, auth } = useAuth()
  let navigate = useNavigate()

  //////// useStates //////// 
  // Full college / collegeCDS result array
  const [collegesCDS, setCollegesCDS] = useState<any>([])

  // Full array of filtered results
  const [resultsFull, setResultsFull] = useState<any>([])

  // Filtered results after splice
  const [results, setResults] = useState<any>([])

  // Max items to display as results
  const [showLength, setShowLength] = useState(25)

  // Results state, false when sort and filter in progress or when filters return none. True when complete
  const [filteredResults, setFilteredResults] = useState(false)

  const [sortKey, setSortKey] = useState<any>('name')
  const [sortOrder, setSortOrder] = useState<any>('Ascending')
  const [collegeOptions, setCollegeOptions] = useState<any>([])
  const [collegeSetOptions, setCollegeSetOptions] = useState<any>([])
  const [userprofile, setUserprofile] = useState<any>({})
  const [loadingComplete, setLoadingComplete] = useState(false)

  const [collegeSelect, setCollegeSelect] = useState<any>([])
  const [collegeSetSelect, setCollegeSetSelect] = useState<any>([])
  const [requiredSelect, setRequiredSelect] = useState<any>(false)
  const [optionalSelect, setOptionalSelect] = useState<any>(false)
  const [blindSelect, setBlindSelect] = useState<any>(false)
  const [requiredCount, setRequiredCount] = useState(0)
  const [optionalCount, setOptionalCount] = useState(0)
  const [blindCount, setBlindCount] = useState(0)
  const [onlyInterestsSelect, setOnlyInterestsSelect] = useState(false)
  const [satMin, setSATMin] = useState<any>(0)
  const [satMax, setSATMax] = useState<any>(1600)
  const [actMin, setACTMin] = useState<any>(0)
  const [actMax, setACTMax] = useState<any>(36)

  const [userSATVerbal, setUserSATVerbal] = useState<any>(null)
  const [userSATMath, setUserSATMath] = useState<any>(null)
  const [userSATSuperScore, setUserSATSuperScore] = useState<any>(userSATVerbal + userSATMath)
  const [userActEnglish, setUserActEnglish] = useState<any>(null)
  const [userActMath, setUserActMath] = useState<any>(null)
  const [userActWriting, setUserActWriting] = useState<any>(null)
  const [userActScience, setUserActScience] = useState<any>(null)
  const [userActReading, setUserActReading] = useState<any>(null)
  const [userACTBestScore, setUserACTBestScore] = useState<any>(null)

  const [collegeFilter, setCollegeFilter] = useState<any>((item) => {
    return item => item
  })
  const [collegeSetFilter, setCollegeSetFilter] = useState<any>((item) => {
    return item => item
  })
  const [policyFilter, setPolicyFilter] = useState<any>((item) => {
    return item => item
  })
  const [satFilter, setSATFilter] = useState<any>((item) => {
    return item => item
  })
  const [actFilter, setACTFilter] = useState<any>((item) => {
    return item => item
  })
  const [showOnlyMyInterestsFilter, setShowOnlyMyInterestsFilter] = useState<any>((item) => {
    return item => item
  })

  const sortKeys = [{ 'Alphabetical': 'name' }, { 'Median SAT': 'satComposite50' }, { 'Median ACT': 'actComposite50' }]

  const handleSortKeyChange = (event) => {
    const selectedKey = event.target.value
    setSortKey(selectedKey)
  }

  const sortKeyReturn = (sortKey) => {
    switch (sortKey) {
      case 'name':
        return 'Alphabetical'
      case 'satComposite50':
        return 'Median SAT'
      case 'actComposite50':
        return 'Median ACT'
      default:
        return 'Alphabetical'
    }
  }

  const handleSortOrderChange = () => {
    sortOrder === 'Ascending' ? setSortOrder('Descending') : setSortOrder('Ascending')
  }

  const returnOptionBSATComposite25 = (cds) => {
    if (cds.satEvidence25 && cds.satMath25) {
      return cds.satEvidence25 + cds.satMath25
    }

    else {
      return null
    }
  }

  const returnOptionBSATComposite75 = (cds) => {
    if (cds.satEvidence75 + cds.satMath75) {
      return cds.satEvidence75 + cds.satMath75
    }

    else {
      return null
    }
  }

  const returnOptionBSATComposite50 = (cds) => {

    if (cds.satEvidence50 && cds.satMath50) {
      return cds.satEvidence50 + cds.satMath50
    }

    else if (cds.satEvidence25 && cds.satEvidence75 && cds.satMath25 && cds.satMath75) {
      return ((cds.satEvidence25 + cds.satEvidence75) / 2) + ((cds.satMath25 + cds.satMath75) / 2)
    }

    else {
      return null
    }
  }

  const returnUserSATScoreOutput = (userSATMath, userSATVerbal, res: any) => {

    if (res && res.college_cds) {

      const cds = res.college_cds[0]

      const caseA = (cds.satComposite25 == null || cds.satComposite25 == undefined) || (cds.satComposite75 == null || cds.satComposite75 == undefined)

      const caseB = [cds.satEvidence25, cds.satEvidence75, cds.satMath25, cds.satMath75].some((sat) => sat == null || sat == undefined)

      if (caseA && caseB) {
        return -2
      }

      else if ((userSATMath == null || userSATMath == undefined) || (userSATVerbal == null || userSATVerbal == undefined)) {
        return -1
      }

      else {

        let satSuperScore = userSATMath + userSATVerbal
        let satComposite50: any = cds.satComposite50 ? cds.satComposite50 : null
        let satComposite25: any = cds.satComposite25 ? cds.satComposite25 : null
        let satComposite75: any = cds.satComposite75 ? cds.satComposite75 : null

        let above25: boolean = false
        let above50: boolean = false
        let above75: boolean = false

        if (cds.satComposite25 !== null && cds.satComposite75 !== null) {
          satComposite25 = cds.satComposite25;
          satComposite75 = cds.satComposite75;
          cds.satComposite50 ? satComposite50 = cds.satComposite50 : satComposite50 = ((satComposite25 + satComposite75) / 2);
        }

        else if (cds.satComposite25 === null && cds.satComposite75 === null) {
          satComposite25 = returnOptionBSATComposite25(cds);
          satComposite50 = returnOptionBSATComposite50(cds);
          satComposite75 = returnOptionBSATComposite75(cds);
        }

        if (satSuperScore >= satComposite25) {
          above25 = true
        }

        if (satSuperScore >= satComposite75) {
          above75 = true
        }

        if (satSuperScore >= satComposite50) {
          above50 = true
        }

        switch (true) {
          case above25 == false:
            return (((satSuperScore / satComposite25)) * 25)
          case above25 == true && above50 == false:
            return (((satSuperScore - satComposite25) / (satComposite50 - satComposite25)) * 25) + 25
          case above50 == true && above75 == false:
            return (((satSuperScore - satComposite50) / (satComposite75 - satComposite50)) * 25) + 50
          default:
            return (((satSuperScore - satComposite75) / (1600 - satComposite75)) * 25) + 75
        }
      }
    }

    else {
      return -2
    }

  }

  const returnUserACTScoreOutput = (userACTBestScore, res: any) => {

    let actReference50: any = 0
    let above25: any = false
    let above50: any = false
    let above75: any = false

    if (res && res.college_cds) {

      const cds = res.college_cds[0]

      // If either actComposite25 or actComposite75 are null, set ACT score output to -2
      if ((cds.actComposite25 == null || cds.actComposite25 == undefined) || (cds.actComposite75 == null || cds.actComposite75 == undefined)) {
        return -2
      }

      else if (userACTBestScore == null || userACTBestScore == undefined) {
        return -1
      }

      // Set act reference 50. If it exists set it to actComposite50 otherwise set it to the average of actComposite25 and actComposite75
      actReference50 = cds.actComposite50 ? cds.actComposite50 : ((cds.actComposite25 + cds.actComposite75) / 2)

      const setBooleans = () => {
        if (userACTBestScore >= cds.actComposite25) {
          above25 = true
        }

        if (userACTBestScore >= actReference50) {
          above50 = true
        }

        if (userACTBestScore >= cds.actComposite75) {
          above75 = true
        }
      }
      setBooleans();


      if (above25 === false) {
        return ((((userACTBestScore / cds.actComposite25)) * 25))
      }

      else if (above25 === true && above50 === false) {
        return ((((userACTBestScore - cds.actComposite25) / (actReference50 - cds.actComposite25)) * 25) + 25)
      }

      else if (above50 === true && above75 === false) {

        if ((cds.actComposite25 == actReference50) && (userACTBestScore == cds.actComposite25)) {
          return ((50 + 25) / 2)
        }

        return ((((userACTBestScore - actReference50) / (cds.actComposite75 - actReference50)) * 25) + 50)
      }

      else {
        if (userACTBestScore == 36 && cds.actComposite75 == 36) {
          return 100
        }

        if ((cds.actComposite75 == actReference50) && (userACTBestScore == cds.actComposite75)) {
          return ((50 + 75) / 2)
        }

        return ((((userACTBestScore - cds.actComposite75) / (36 - cds.actComposite75)) * 25) + 75)
      }

    }

    else {
      // fallback, if college cds is not available
      return -2
    }


  }

  useEffect(() => {
    if (getAuth()?.api_token && getAuth()?.groups) {
      apiToken = getAuth()?.api_token
      permissionGroups = getAuth()?.groups
    }

    const fetchData = async () => {

      try {
        if (loadingComplete === true) {
          setLoadingComplete(false)
        }

        const userDataAxios = axios.get(`${URL}/user_profiles/${currentUser?.user_id}`)

        const [userDataRes] = await Promise.all([
          userDataAxios
        ])

        setUserprofile(userDataRes.data)
        setUserSATVerbal(userDataRes.data.satVerbal)
        setUserSATMath(userDataRes.data.satMath)
        setUserSATSuperScore(userDataRes.data.satVerbal + userDataRes.data.satMath)
        setUserActEnglish(userDataRes.data.actEnglish)
        setUserActMath(userDataRes.data.actMath)
        setUserActWriting(userDataRes.data.actWriting)
        setUserActScience(userDataRes.data.actScience)
        setUserActReading(userDataRes.data.actReading)
        setUserACTBestScore(userDataRes.data.actComposite)

        setLoadingComplete(true)


      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()

  }, [user])

  useEffect(() => {

    updateTitleAndDescription(pageRoutes.testScoreStrategy)

    const fetchData = async () => {

      try {
        if (loadingComplete === true) {
          setLoadingComplete(false)
        }

        // const collegesAxios = axios.get(`${URL}/colleges/`)
        const collegesAxios = await axios.get(`${URL}/colleges/${0}-to-${collegesId.length}?sortKey=${sortKey}`)
        const collegeSetAxios = axios.get(`${URL}/collegeset/`)
        const userDataAxios = axios.get(`${URL}/user_profiles/${currentUser?.user_id}`)

        const [collegeRes, collegesetRes, userDataRes] = await Promise.all([
          collegesAxios,
          collegeSetAxios,
          userDataAxios
        ])

        // collegeRes
        let filtered = collegeRes.data.filter((item) => item.college_cds !== null)
        let collegeOptArr: any = [];
        filtered.forEach(res => {
          collegeOptArr.push({ value: res.id, label: res.name })
        });

        let result: any = []
        filtered.map((res) => {
          if (res.college_cds !== null && res.college_cds.length > 0) {
            result.push(res)
          }
        })

        sortOrder === 'Descending' ?
          result = result.sort((a, b) => sortLogic(b, a, 'name', 'name')) :
          result = result.sort((a, b) => sortLogic(a, b, 'name', 'name'))


        //collegesetRes
        let collegeSetRes: any = []
        collegesetRes.data.forEach((res) => {
          collegeSetRes.push({ value: res.colleges, label: res.name })
        });

        // userDataRes
        // console.log('user data:', userDataRes.data)

        console.log('collegesCDS at fetchData', result)

        let splicedRes = result.slice()
        splicedRes = splicedRes.splice(0, showLength)

        await Promise.all([
          setCollegeOptions(collegeOptArr),
          setCollegeSetOptions(collegeSetRes),
          setUserprofile(userDataRes.data),
          setUserSATVerbal(userDataRes.data.satVerbal),
          setUserSATMath(userDataRes.data.satMath),
          setUserSATSuperScore(userDataRes.data.satVerbal + userDataRes.data.satMath),
          setUserActEnglish(userDataRes.data.actEnglish),
          setUserActMath(userDataRes.data.actMath),
          setUserActWriting(userDataRes.data.actWriting),
          setUserActScience(userDataRes.data.actScience),
          setUserActReading(userDataRes.data.actReading),
          setUserACTBestScore(userDataRes.data.actComposite),
          setCollegesCDS(result),
          setResultsFull(result),
          setResults(splicedRes)
        ])

        setFilteredResults(true)
        setLoadingComplete(true)

      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  // Update SAT super score when user updates either sat math or sat verbal
  useEffect(() => {

    let total = parseInt(userSATMath) + parseInt(userSATVerbal)
    setUserSATSuperScore(total)

  }, [userSATMath, userSATVerbal])

  useEffect(() => {

    const returnCollegeFilter = (collegeSelect) => {
      if (collegeSelect.length > 0) {
        setCollegeFilter((item) => {
          return item => collegeSelect.includes(item.id)
        })
      }

      else {
        setCollegeFilter((item) => {
          return item => item
        })
      }
    }

    returnCollegeFilter(collegeSelect)

  }, [collegeSelect])

  useEffect(() => {

    const returnCollegeSetFilter = (collegeSetSelect) => {

      let collegeSets: any = null

      if (collegeSetSelect == '') {
        setCollegeSetFilter((item) => {
          return item => item
        })
      }

      else {
        try {
          collegeSets = collegeSetSelect.split(',').map(res => parseInt(res))
        } catch (error) {
          console.log('Error:', error)
        } finally {
          if (collegeSets && Array.isArray(collegeSets) && collegeSets.length > 0) {
            setCollegeSetFilter((item) => {
              return item => collegeSets.includes(item.id)
            })
          }
        }
      }
    }

    returnCollegeSetFilter(collegeSetSelect)

  }, [collegeSetSelect])

  useEffect(() => {

    let policies: any = []

    if ((requiredSelect === true && optionalSelect === true && blindSelect === true) || (requiredSelect === false && optionalSelect === false && blindSelect === false)) {
      setPolicyFilter((item) => {
        return item => item
      })
    }

    else {
      if (requiredSelect === true) {
        policies.push(1)
      }

      if (optionalSelect === true) {
        policies.push(2)
      }

      if (blindSelect === true) {
        policies.push(3)
      }

      setPolicyFilter((item) => {
        return item => policies.includes(item.testPolicy)
      })
    }

  }, [requiredSelect, optionalSelect, blindSelect])

  useEffect(() => {

    const returnShowOnlyInterestFilter = (onlyInterestsSelect) => {

      if (onlyInterestsSelect === true && userCollegeInterest && userCollegeInterest.length > 0) {
        setShowOnlyMyInterestsFilter((item) => {
          return item => userCollegeInterest.some((obj) => obj.college_id == item.id
          )
        }
        )
      }

      else if (onlyInterestsSelect === true && (!userCollegeInterest || (userCollegeInterest && !(userCollegeInterest.length > 0)))) {
        setShowOnlyMyInterestsFilter((item) => {
          return item => false
        })
      }

      else {
        setShowOnlyMyInterestsFilter((item) => {
          return item => item
        })
      }

    }
    returnShowOnlyInterestFilter(onlyInterestsSelect)

  }, [onlyInterestsSelect])

  useEffect(() => {

    const returnSATFilter = (satMin, satMax) => {
      setSATFilter((item) => {
        return item => (item.college_cds[0].satComposite50 === null || ((item.college_cds[0].satComposite50 >= satMin) && item.college_cds[0].satComposite50 <= satMax))
      })
    }
    returnSATFilter(satMin, satMax)

  }, [satMin, satMax])

  useEffect(() => {

    const returnACTFilter = (actMin, actMax) => {
      setACTFilter((item) => {
        return item => (item.college_cds[0].actComposite50 === null || ((item.college_cds[0].actComposite50 >= actMin) && item.college_cds[0].actComposite50 <= actMax))
      })
    }
    returnACTFilter(actMin, actMax)

  }, [actMin, actMax])

  useEffect(() => {

    const sortAndFilter = (collegeFilter, collegeSetFilter, policyFilter, satFilter, actFilter, sortOrder, sortKey) => {
      let res: any = collegesCDS

      if (collegesCDS.filter(showOnlyMyInterestsFilter).length === 0) {
        return setFilteredResults(false)
      }

      res = res.filter(collegeFilter).filter(collegeSetFilter).filter(policyFilter).filter(satFilter).filter(actFilter).filter(showOnlyMyInterestsFilter)

      if (res.length === 0) {
        return setFilteredResults(false)
      }

      // Before splice, set full to res
      setResultsFull(res)

      if (sortKey === 'name') {
        sortOrder === 'Descending' ?
          res = res.sort((a, b) => sortLogic(b, a, sortKey, 'name')) :
          res = res.sort((a, b) => sortLogic(a, b, sortKey, 'name'))


        let requiredCount = res.filter((item) => item.testPolicy === 1).length
        let optionalCount = res.filter((item) => item.testPolicy === 2).length
        let blindCount = res.filter((item) => item.testPolicy === 3).length

        setRequiredCount(requiredCount)
        setOptionalCount(optionalCount)
        setBlindCount(blindCount)

        let splicedRes = res.slice()
        splicedRes = splicedRes.splice(0, showLength)
        // res = res.splice(0, 25)

        setResults(splicedRes)
        setFilteredResults(true)
      }

      else {
        const expandedSortLogic = (a, b, sortKey) => {
          if (typeof a.college_cds[0][sortKey] === 'string' && typeof b.college_cds[0][sortKey] === 'string') {
            return a.college_cds[0][sortKey].localeCompare(b.college_cds[0][sortKey])
          }

          else {
            return a.college_cds[0][sortKey] - b.college_cds[0][sortKey]
          }
        }
        sortOrder === 'Descending' ?
          res = res.sort((a, b) => expandedSortLogic(b, a, sortKey)) :
          res = res.sort((a, b) => expandedSortLogic(a, b, sortKey))

        let requiredCount = res.filter((item) => item.testPolicy === 1).length
        let optionalCount = res.filter((item) => item.testPolicy === 2).length
        let blindCount = res.filter((item) => item.testPolicy === 3).length

        setRequiredCount(requiredCount)
        setOptionalCount(optionalCount)
        setBlindCount(blindCount)

        // res = res.splice(0, 25)

        // setResults(res)

        let splicedRes = res.slice()
        splicedRes = splicedRes.splice(0, showLength)

        setResults(splicedRes)
        setFilteredResults(true)
      }
    }

    sortAndFilter(collegeFilter, collegeSetFilter, policyFilter, satFilter, actFilter, sortOrder, sortKey);

  }, [collegeFilter, collegeSetFilter, policyFilter, satFilter, actFilter, showOnlyMyInterestsFilter, sortOrder, sortKey, collegesCDS, showLength])

  if (!apiToken) {
    return <Error404 />
  }

  const returnResults = (filteredResults) => {

    if (loadingComplete == false) {
      return (<div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>

        <LoadingIndicator text={"Loading Test Score Recommendations..."} />

      </div>)
    }

    else if (loadingComplete == true && collegesCDS && collegesCDS.length > 0) {

      switch (true) {
        case filteredResults:
          return <Cards
            results={results}
            setResults={setResults}
            collegesCDS={collegesCDS}
            setCollegesCDS={setCollegesCDS}
            collegeListLength={collegesId.length}
            setCollegeOptions={setCollegeOptions}
            sortOrder={sortOrder}
            sortKey={sortKey}
            sortKeys={sortKeys}
            sortKeyReturn={sortKeyReturn}
            handleSortKeyChange={handleSortKeyChange}
            handleSortOrderChange={handleSortOrderChange}
            userSATVerbal={userSATVerbal}
            userSATMath={userSATMath}
            userSATSuperScore={userSATSuperScore}
            userActEnglish={userActEnglish}
            userActMath={userActMath}
            userActWriting={userActWriting}
            userActScience={userActScience}
            userActReading={userActReading}
            userACTBestScore={userACTBestScore}
            setUserSATVerbal={setUserSATVerbal}
            setUserSATMath={setUserSATMath}
            setUserSATSuperScore={setUserSATSuperScore}
            setUserACTBestScore={setUserACTBestScore}
            setUserActEnglish={setUserActEnglish}
            setUserActMath={setUserActMath}
            setUserActWriting={setUserActWriting}
            setUserActScience={setUserActScience}
            setUserActReading={setUserActReading}
            returnUserSATScoreOutput={returnUserSATScoreOutput}
            returnUserACTScoreOutput={returnUserACTScoreOutput}
            setUserprofile={setUserprofile}
            user={user}
            userCollegeInterest={userCollegeInterest}
            setUserCollegeInterest={setUserCollegeInterest}
            showOnlyMyInterestsFilter={showOnlyMyInterestsFilter}
            setFilteredResults={setFilteredResults}
            setRequiredCount={setRequiredCount}
            setBlindCount={setBlindCount}
            setOptionalCount={setOptionalCount}
            collegeFilter={collegeFilter}
            collegeSetFilter={collegeSetFilter}
            policyFilter={policyFilter}
            satFilter={satFilter}
            actFilter={actFilter}
            showLength={showLength}
            setShowLength={setShowLength}
            resultsFull={resultsFull}
          />
        case (onlyInterestsSelect === true && collegesCDS.filter(showOnlyMyInterestsFilter).length === 0 && !filteredResults):
          return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
            <div className='mt-5'>Please save colleges to your interest list in order for them to appear here.</div>
          </div>
        case filteredResults === false:
          return <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '1129px', maxWidth: '1129px' }}>
            <div className='mt-5'>No results meet your criteria</div>
          </div>
        default:
          return <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
            <LoadingIndicator text={"Loading Test Score Recommendations..."} />

          </div>
      }

    }

    else {
      return (<div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>

        <LoadingIndicator text={"Loading Test Score Recommendations..."} />

      </div>)
    }

  }

  const testScoreHeader = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>Test Score Guidance</span>

        <div className="dca-search-header-bttn-container">

          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(pageRoutes.overviewAdmissionsAdviceWithHash('determining-a-standardized-testing-strategy'))}
            className='dca-search-header-bttn'
          >Advice: SAT/ACT Testing Strategy</button>
        </div>
      </div>

      <span className="dca-search-description">Get personalized, data-driven recommendations on whether or not to submit your test score(s) to individual colleges.</span>

    </div>
  )

  return (
    <div>
      {testScoreHeader}

      <div className='mt-5 d-flex flex-column-fluid' style={{ marginLeft: '35px' }}>
        <Aside
          results={results}
          collegeOptions={collegeOptions}
          collegeSetOptions={collegeSetOptions}
          setCollegeSelect={setCollegeSelect}
          setCollegeSetSelect={setCollegeSetSelect}
          setRequiredSelect={setRequiredSelect}
          setOptionalSelect={setOptionalSelect}
          setBlindSelect={setBlindSelect}
          setSATMin={setSATMin}
          setSATMax={setSATMax}
          setACTMin={setACTMin}
          setACTMax={setACTMax}
          userSATVerbal={userSATVerbal}
          userSATMath={userSATMath}
          userSATSuperScore={userSATSuperScore}
          userActEnglish={userActEnglish}
          userActMath={userActMath}
          userActWriting={userActWriting}
          userActScience={userActScience}
          userActReading={userActReading}
          userACTBestScore={userACTBestScore}
          setUserSATVerbal={setUserSATVerbal}
          setUserSATMath={setUserSATMath}
          setUserACTBestScore={setUserACTBestScore}
          setUserActEnglish={setUserActEnglish}
          setUserActMath={setUserActMath}
          setUserActWriting={setUserActWriting}
          setUserActScience={setUserActScience}
          setUserActReading={setUserActReading}
          setUserprofile={setUserprofile}
          setUserSATSuperScore={setUserSATSuperScore}
          setOnlyInterestsSelect={setOnlyInterestsSelect}
          requiredCount={requiredCount}
          optionalCount={optionalCount}
          blindCount={blindCount}
        />
        {returnResults(filteredResults)}
      </div>

    </div>
  )
}

export { TestScoreStrategy }
