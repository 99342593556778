
import { useNavigate } from 'react-router'
import * as dictionary from '../dictionary'
import { SaveButtonCollegeCard, SaveButtonCardNoAuth } from '../SaveButton'
import { TooltipComponent } from '../Tooltip'
import { useState } from 'react'
import { collegeMap } from '../collegeMap'
import { ConnectWithAdvisorButton } from '../ConnectWithAdvisorButton'

const numberConverter = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const CollegeCard = (props) => {

  let navigate = useNavigate();

  const routeChange = (id) => {

    let college = collegeMap.find((col) => (col.id || parseInt(col.id)) == id)

    if (college) {
      navigate(dictionary.pageRoutes.collegeDetailWithId(college.hyphenated))
    }

    else if (!college) {
      console.log('No college details page found')
    }

  }

  const returnAdmissionRate = (college) => {

    if (college && college.college_cds && college.college_cds[0] && college.college_cds[0].totalAdmitRate) {
      let res = college.college_cds[0].totalAdmitRate * 100
      return `${res.toFixed(2)}%`
    }

    else {
      return 'N/A'
    }
  }

  const collegeLocationTypeStyle = {
    fontSize: '17px',
    color: '#585555',
    lineHeight: '20.4px',
    marginTop: '10px'
  }

  const [loading, setLoading] = useState(false)

  const handleShowMore = (e) => {
    e.preventDefault();

    let max = props.filteredCollegesFull.length
    let end = max

    if (props.showLength + 25 < max) {
      end = props.showLength + 25
    }

    else {
      end = end
    }

    try {
      setLoading(true)
      props.setShowLength(end)
    }

    catch (error) {
      throw (error)
    }

    finally {
      setLoading(false)
    }

  }

  const returnShowMoreButton = (loading) => {

    let loadingFalse = (

      <div className='d-flex justify-content-center'>
        <button
          onClick={handleShowMore}
          style={{
            cursor: 'pointer',
            marginTop: '20px',
            fontFamily: 'ColfaxWebMedium',
            border: '1px solid',
            borderRadius: '20px',
            borderColor: '#ededed',
            backgroundColor: '#F9F9F9',
            padding: '10px'
          }}>Show more</button></div>
    )

    let loadingTrue = (
      <div className='d-flex justify-content-center'>
        <button
          disabled
          style={{
            cursor: 'pointer',
            marginTop: '20px',
            fontFamily: 'ColfaxWebMedium',
            border: '1px solid',
            borderRadius: '20px',
            borderColor: '#ededed',
            backgroundColor: '#F9F9F9',
            padding: '10px'
          }}>Loading ...</button></div>
    )

    return loading ? loadingTrue : loadingFalse


  }

  const tooltipReturn = (college) => {

    let data = [
      { 'US News & World Report (National Universities)': college.usNewsNationalRank },
      { 'US News & World Report (Liberal Arts Colleges)': college.usNewsLiberalArtsRank },
      { 'Wall Street Journal': college.wsjRank },
      { 'Times Higher Education': college.timesHigherEdRank },
      { 'Washington Monthly (National Universities)': college.waMonthlyNationalRank },
      { 'Washington Monthly (Liberal Arts Colleges)': college.waMonthlyLiberalArtsRank },
      { 'Forbes': college.forbesRank },
      { 'Niche': college.nicheRank },
      { 'Academic Ranking of World Universities': college.arwuRank },
      { 'QS World University Rankings': college.qsRank },
    ]

    let metaRank = college.metaRank ? college.metaRank : null
    let name = college.name ? college.name : null

    if (data.some((x) => Object.values(x)[0] !== null)
    ) {

      data = data.filter((res) => Object.values(res)[0] != null)

      let worstRank = Object.values(data[0])[0]
      let worstRankSource = Object.keys(data[0])[0]

      let bestRank = Object.values(data[0])[0]
      let bestRankSource = Object.keys(data[0])[0]


      data.map((pair) => {
        let res = Object.values(pair)[0]

        if (res > worstRank) {
          worstRank = res
          worstRankSource = Object.keys(pair)[0]
        }

        if (res < bestRank) {
          bestRank = res
          bestRankSource = Object.keys(pair)[0]
        }

      })

      if (worstRank === bestRank) {

        let priorityList = [
          'US News & World Report (National Universities)',
          'US News & World Report (Liberal Arts Colleges)',
          'Forbes',
          'Wall Street Journal',
          'Times Higher Education',
          'Washington Monthly (National Universities)',
          'Washington Monthly (Liberal Arts Colleges)',
          'Niche',
          'Academic Ranking of World Universities',
          'QS World University Rankings'
        ]

        let priorityRankSource: any = undefined

        let indexWorst = priorityList.indexOf(worstRankSource)
        let indexBest = priorityList.indexOf(bestRankSource)

        switch (true) {
          case indexBest < indexWorst:
            priorityRankSource = bestRankSource
            break;
          case indexWorst < indexBest:
            priorityRankSource = worstRankSource
            break
          default:
            priorityRankSource = bestRankSource
        }

        return `Metarank of #${metaRank} is our overall rank for ${name} based on analysis of eight industry ranking sources. It's ranking is #${bestRank} from ${priorityRankSource}; click through to see all ranking data on the details page.`
      }

      return `Metarank of #${metaRank} is our overall rank for ${name} based on analysis of eight industry ranking sources. It's highest ranking is #${bestRank} from ${bestRankSource} and lowest-rank is #${worstRank} from ${worstRankSource}; click through to see all ranking data on the details page.`

    }

    else {

      return `Metarank of #${metaRank} is our overall rank for ${name} based on analysis of eight industry ranking sources. Click through to see all ranking data on the details page.`

    }
  }

  return (

    <div className='dca-card-container'>

      {/* COLLEGE SEARCH CARDS HEADER */}
      <div className='d-flex flex-row justify-content-between align-items-end'>

        <div className='d-flex flex-row'>
          <div className='me-3 align-bottom dca-card-items-found-text'>Displaying {props.filteredColleges.length} colleges</div>

          <div
            onClick={props.handleSortChange}
            style={{ cursor: 'pointer', fontSize: '15px', lineHeight: '18px', color: '#585555', marginBottom: '2px' }}
            className='text-muted fs-7 fw-bolder align-self-end'
          >by {props.sortOrder} {Object.keys(props.sortKeyOptions).find(key => props.sortKeyOptions[key] === props.sortKey)} {props.sortOrder === 'Ascending' && (<i className="bi bi-arrow-up"></i>)}
            {props.sortOrder === 'Descending' && (<i className="bi bi-arrow-down"></i>)}
          </div>
        </div>

        <select
          className='form-select w-25 align-bottom'
          data-control="select2"
          value={props.sortKey}
          onChange={props.handleSortKeyChange}
          style={{ cursor: 'pointer' }}
        >
          <option value={props.sortKeyOptions[Object.keys(props.sortKeyOptions)[0]]}>{Object.keys(props.sortKeyOptions)[0]}</option>
          <option value={props.sortKeyOptions[Object.keys(props.sortKeyOptions)[1]]}>{Object.keys(props.sortKeyOptions)[1]}</option>
          <option value={props.sortKeyOptions[Object.keys(props.sortKeyOptions)[2]]}>{Object.keys(props.sortKeyOptions)[2]}</option>
          <option value={props.sortKeyOptions[Object.keys(props.sortKeyOptions)[3]]}>{Object.keys(props.sortKeyOptions)[3]}</option>
          <option value={props.sortKeyOptions[Object.keys(props.sortKeyOptions)[4]]}>{Object.keys(props.sortKeyOptions)[4]}</option>
        </select>
      </div>
      {/* END COLLEGE SEARCH CARDS HEADER */}

      <div>{props.filteredColleges.map((college) => (
        <div
          key={college.id}
          className="mt-5 hover-elevate-up dca-card-item"
          onClick={() => {
            let matchingCollege = collegeMap.find(colObj => parseInt(colObj.id) == college.id)

            navigate(`${dictionary.pageRoutes.ecDetailWithId(matchingCollege?.hyphenated)}`)
          }}>

          {props.advisorCollegeIDs.some(collegeID => collegeID == college.id) && <ConnectWithAdvisorButton
            cardID={college.id}
            searchPage={dictionary.pageRoutes.collegeSearch}
            buttonTextParam={college.name} />}

          {/* <div style={dictionary.saveButtonCardPosition}> */}
          {props.user && props.userCollegeInterest ? <SaveButtonCollegeCard
            list={props.userCollegeInterest}
            user={props.user}
            collegeId={college.id}
            setUserCollegeInterest={props.setUserCollegeInterest}
          /> : <SaveButtonCardNoAuth
            setPrompt={props.setPrompt}
            setNavigateTo={props.setNavigateTo} />}
          {/* </div> */}

          {/* College Name, City, State, Type */}
          <div className="mb-5 d-flex flex-column justify-content-center align-items-center">
            <img
              alt="collegeLogo"
              src={dictionary.collegeLogos[college.id]}
              style={{ height: '75px', width: '75px', margin: props.advisorCollegeIDs.some(collegeID => collegeID == college.id) ? '70px 0 10px 0' : '10px 0 10px 0' }}
            />
            <div className='dca-card-item-nameField'>{college['name']}</div>

            <div className='dca-college-search-card-location-text'>
              {/* <span style={collegeLocationTypeStyle}> */}
              <span>
                {college['locationCity']}, {dictionary.stateMap[college['locationState']]}&nbsp;&nbsp;|&nbsp;&nbsp;{college['type'] ? dictionary.typeRender[college['type']] : 'N/A'}
              </span>
            </div>
          </div>


          {/* College Card Items */}
          <div className="d-flex flex-column justify-content-center" style={{ marginTop: '17px' }}>
            <div className="d-flex flex-row align-self-center" style={{ width: '100%', margin: '20px 0 20px 0', justifyContent: 'space-evenly' }}>

              <div className="d-flex flex-column" style={{ margin: '0 15px 0 15px' }}>
                <div className='dca-card-item-header' style={{ whiteSpace: 'nowrap' }}>METARANK
                  <span className='ms-2'>
                    <TooltipComponent content={tooltipReturn(college)} />
                  </span>
                </div>
                <div className='dca-card-item-text' >{college['metaRank'] ? college['metaRank'] : 'N/A'}</div>
              </div>

              <div className="d-flex flex-column" style={{ marginRight: '15px' }}>
                <div className='dca-card-item-header'>ADMISSION RATE</div>
                <div className='dca-card-item-text'>{returnAdmissionRate(college)}</div>
              </div>

              <div className="d-flex flex-column" style={{ marginRight: '15px' }}>
                <div className='dca-card-item-header'>UNDERGRADS</div>
                <div className='dca-card-item-text'>{college['numUndergrads'] ? numberConverter(college['numUndergrads']) : 'N/A'}</div>
              </div>

              <div className="d-flex flex-column" style={{ marginRight: '15px' }}>
                <div className='dca-card-item-header'>AVG GPA</div>
                <div className='dca-card-item-text'>{college.college_cds[0]?.averageGPA ?? 'N/A'}</div>
              </div>

              <div className="d-flex flex-column" style={{ marginRight: '15px' }}>
                <div className='dca-card-item-header'>MDN SAT</div>
                <div className='dca-card-item-text'>{college.college_cds[0]?.satComposite50 ?? 'N/A'}</div>
              </div>

              <div className="d-flex flex-column" style={{ marginRight: '15px' }}>
                <div className='dca-card-item-header'>MDN ACT</div>
                <div className='dca-card-item-text'>{college.college_cds[0]?.actComposite50 ?? 'N/A'}</div>
              </div>

              <div className="d-flex flex-column" style={{ marginRight: '15px' }}>
                <div className='dca-card-item-header'>MEDIAN EARNINGS</div>
                <div className='dca-card-item-text'>{college['median4yrEarnings'] ? `$${numberConverter(college['median4yrEarnings'])}` : 'N/A'}</div>
              </div>

            </div>
          </div>
        </div>
      ))}
      </div>

      {props.filteredCollegesFull.length > props.filteredColleges.length && returnShowMoreButton(loading)}

    </div>
  )
}
export { CollegeCard }
