import { closeModalIcon } from "../dictionary"
import { resendEmailVerificationRequest } from "../../modules/auth/core/_requests"
import { selectedTabGreen } from "../dictionary"
import { useState, useEffect } from "react"
import { AuthReactGAHandlers } from "../ReactGAHandlers"

const EmailVerificationRequestModal = ({ currentUser }) => {

  const { handleEmailVerificationResendRequest } = AuthReactGAHandlers()
  const [clicked, setClicked] = useState<any>(false)

  useEffect(() => {
    const modalElement: any = document.getElementById('email_verification_request_modal');

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'style') {
          const style = window.getComputedStyle(modalElement);
          if (style.display == 'none') {
            clicked ? setClicked(false) : setClicked(clicked)
          }
        }
      });
    });

    if (modalElement) {
      observer.observe(modalElement, { attributes: true, attributeFilter: ['style'] });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="modal fade" id={'email_verification_request_modal'}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content align-items-center" style={{ minWidth: '75vw', maxWidth: '75vw', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto', textAlign: 'center' }}>
          {closeModalIcon('email_verification_request_modal')}

          <span className="dca-font-M">The email address for this account ({currentUser?.emailAddress}) has not yet been verified. For account security purposes, email verification is required prior to making a purchase.</span>

          <button
            type="button"
            className="btn btn-sm fw-bold dca-save-button-inlin"
            style={{
              background: selectedTabGreen,
              width: 'fit-content',
              marginTop: '5%'
            }}
            disabled={clicked ? true : false}
            onClick={() => {
              if (!clicked) {
                setClicked(true)
              }
              resendEmailVerificationRequest(currentUser?.firstName, currentUser?.emailAddress);
              handleEmailVerificationResendRequest();
            }}
          >Resend Verification Request Email</button>

          {clicked && <span className="dca-font-M" style={{ color: 'rgb(192, 0, 0)', marginTop: '5%' }}>An email has been sent to you, please click on the button in the message to verify your email. Please check your spam folder if you don't receive the message in the next few minutes. Please contact us at <a href="mailto:support@nextfour.ai">support@nextfour.ai</a> if you require any assistance with your account.</span>}

        </div>
      </div>
    </div>
  )
}

export { EmailVerificationRequestModal }