import Select from 'react-select'

const CollegeSelectComponent = ({ options, value, onChange }) => {

  return <div className='dca-marketing-college-select'>
    <Select
      className="dca-marketing-select-dropdown"
      classNamePrefix="react-select"
      options={options}
      value={value}
      onChange={onChange}
      placeholder={"Select a college"}
    />
  </div>
}


export { CollegeSelectComponent }